import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import ReactDOM from 'react-dom';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import { Container } from './styles';
import api from '../../services/api';

 interface IScheduleProperties {
  id: number | null;
  schedule_room_id: number;
  attendees: string;
  schedule_subject: string;
  schedule_description: string;
  schedule_date: Date | undefined;
  schedule_start: Date | undefined;
  schedule_end: Date | undefined;
  schedule_status_id: number;
  schedule_priority_id: number;
  schedule_type_id: number;
}

interface IScheduleProgress {
  id: number | null;
  schedule_id: number;
  sp_date: Date;
  sp_description: string;
  user_id: string;
}

const Schedule: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;
  const { user } = useAuth();

  const emptySchedule = {
    id: null,
    schedule_room_id: 0,
    attendees: '',
    schedule_subject: '',
    schedule_description: '',
    schedule_date: new Date(),
    schedule_start: undefined,
    schedule_end: undefined,
    schedule_status_id: 1,
    schedule_priority_id: 2,
    schedule_type_id: 1,
  };

  const [schedules, setSchedules] = useState<IScheduleProperties[]>([]);
  const [schedule, setSchedule] = useState(emptySchedule);
  const [
    scheduleProgresses,
    setScheduleProgresses,
  ] = useState<IScheduleProgress[]>([]);
  const [deleteMeeting, setDeleteMeetingDialog] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState<IScheduleProperties[]>([]);
  const [scheduleId, setScheduleId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [timeStart, setTimeStart] = useState<Date | Date[] | undefined>(undefined);
  const [timeEnd, setTimeEnd] = useState<Date | Date[] | undefined>(undefined);
  const [meetingRoom, setMeetingRoom] = useState('');
  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  useEffect(() => {
    api.post('/schedules/search', state).then((response) => {
      setSchedules(response.data);
      setLoading(false);
    });
  }, [state]);

  const handleCreate = () => {
    history.push('/schedule/create');
  };

  const hidedeleteMeeting = () => {
    setDeleteMeetingDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editSchedule = (rowData:any) => {
    history.push(`/schedule/edit/${rowData.id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteMeeting = (schedule: React.SetStateAction<{
      id: null; schedule_room_id: number; attendees: string; schedule_subject: string;
      schedule_description: string; schedule_date: Date; schedule_start: undefined;
      schedule_end: undefined; schedule_status_id: number; schedule_priority_id: number;
      schedule_type_id: number; }>) => {
    setSchedule(schedule);
    setDeleteMeetingDialog(true);
  };

  const deleteGroup = () => {
    api.delete(`/schedules/delete/${schedule.id}`);

    setDeleteMeetingDialog(false);

    const updatedRecords = schedules
      .filter((val) => val.id !== schedule.id);
    setSchedules(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const handleScheduleProgresses = (rowData: {
    id: number;
  }) => {
    history.push(`/schedule/progress/?cod=${rowData.id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      {user.id === rowData.user_id ? (
        <>
          <Button
            type="button"
            icon="pi pi-pencil"
            tooltip="Alterar cadastro"
            tooltipOptions={{ position: 'left' }}
            className="p-button-rounded p-button-text p-button-plain p-mr-2"
            onClick={() => { editSchedule(rowData); }}
          />
          <Button
            type="button"
            icon="pi pi-trash"
            tooltip="Excluir cadastro"
            tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
            className="p-button-rounded p-button-text p-button-plain"
            onClick={() => { confirmDeleteMeeting(rowData); }}
          />
        </>
      ) : null}
      <Button
        type="button"
        icon="pi pi-link"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => {
          handleScheduleProgresses(rowData);
        }}
        tooltip="Gerenciar andamentos da atividade"
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteMeetingFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteMeeting} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGroup} />
    </>
  );

  const dateBodyTemplate = (rowData: {
    schedule_date: Date;
  }) => {
    if (rowData?.schedule_date !== null
        && rowData?.schedule_date.toString() !== '01/01/1970'
        && rowData?.schedule_date.toString() !== '31/12/1969') {
      const dateInput = rowData.schedule_date?.toString();

      const formattedDate = format(new Date(dateInput), 'dd/MM/yyyy');

      return formattedDate;
    }
    return '';
  };

  const statusBodyTemplate = (rowData: { schedule_status_id: number; }) => {
    if (schedules !== undefined) {
      let statusText = '';
      switch (rowData.schedule_status_id) {
        case 1:
          statusText = 'A iniciar';
          break;
        case 2:
          statusText = 'Em andamento';
          break;
        case 3:
          statusText = 'Concluída';
          break;
        case 4:
          statusText = 'Em espera';
          break;
        default:
          break;
      }

      return statusText;
    }
    return '';
  };

  const priorityBodyTemplate = (rowData: { schedule_priority_id: number; }) => {
    if (schedules !== undefined) {
      switch (rowData.schedule_priority_id) {
        case 1:
          return <span className="schedule-badge priority-high">Alta</span>;
        case 2:
          return <span className="schedule-badge priority-normal">Normal</span>;
        case 3:
          return <span className="schedule-badge priority-low">Baixa</span>;
        default:
          break;
      }
    }
    return '';
  };

  const scheduleTypeBodyTemplate = (rowData: { schedule_type_id: number; }) => {
    if (schedules !== undefined) {
      let scheduleText = '';
      switch (rowData.schedule_type_id) {
        case 1:
          scheduleText = 'Atividade';
          break;
        case 2:
          scheduleText = 'Reunião';
          break;
        default:
          break;
      }

      return scheduleText;
    }
    return '';
  };

  const progressDateBodyTemplate = (rowData: {
    sp_date: Date;
  }) => {
    if (rowData.sp_date !== null
        && rowData.sp_date?.toString() !== '01/01/1970'
        && rowData.sp_date?.toString() !== '31/12/1969') {
      const dateInput = rowData.sp_date?.toString();

      const formattedDate = format(new Date(dateInput), 'dd/MM/yyyy');

      return formattedDate;
    }
    return '';
  };

  let timerId = setTimeout(() => {
    setLoading(false);
  }, 200);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadSelectedSchedule = async (rowData: any) => {
    try {
      await api.get(`/schedules/show/${rowData.id}`).then((response) => {
        setSchedule(response.data);
        if (response.data.schedule_start !== undefined
          && response.data.schedule_start !== null) {
          const start = response.data.schedule_start.slice(11, 16);
          setTimeStart(start);
        }
        if (response.data.schedule_end !== undefined
          && response.data.schedule_end !== null) {
          const end = response.data.schedule_end.slice(11, 16);
          setTimeEnd(end);
        }
        if (response.data.meeting_room_id > 0) {
          api.get(`/schedules/meetings/rooms/show/${response.data.meeting_room_id}`).then((responseMeeting) => {
            setMeetingRoom(responseMeeting.data.room_description);
          });
        }
      });

      const response = await api.get(`/schedules/progresses/show/filtered/${rowData.id}`);
      setScheduleProgresses(response.data);

      ReactDOM.unstable_batchedUpdates(() => {
        setScheduleId(rowData.id);
      });
    } catch (e) {
      if (typeof e === 'string') {
        e.toUpperCase();
      } else if (e instanceof Error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
            `Não foi possível carregar as informações do cadastro selecionado.
            Erro: ${e.message}`,
        });
      }
    }
  };

  return (
    <Container>
      <header>
        <div>
          <h1>Agendamentos de atividades/reuniões</h1>
        </div>
      </header>

      <Sidebar
        className="sidebar p-sidebar-md"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h4>
          <div className="leftAlign" style={{ float: 'left', width: '49%' }}>
            Tipo do agendamento:
            { ' ' }
            {schedule.schedule_type_id === 1 ? 'Atividade' : 'Reunião'}
            { ' ' }
            {schedule.schedule_type_id === 1
              ? <i className="pi pi-list" />
              : <i className="pi pi-users" />}
          </div>
          <div className="rightAlign" style={{ float: 'right', width: '49%', textAlign: 'end' }}>
            <Button
              icon="pi pi-pencil"
              iconPos="left"
              className="p-button p-component p-button-info"
              type="button"
              onClick={() => history.push(`/schedule/edit/${scheduleId}`)}
              tooltip="Alterar processo"
              tooltipOptions={{ position: 'left' }}
            />
          </div>
        </h4>

        <div className="col-12 md:col-12">
          <table width="100%" id="schedule_summary" cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td width="200" style={{ padding: '5px' }}>
                  <i className="pi pi-tag" />
                  { ' ' }
                  <strong>Prioridade:</strong>
                </td>
                <td className="schedule-badge priority-normal" style={{ padding: '5px' }}>
                  {schedule.schedule_priority_id === 1
                    ? (<><span className="schedule-badge priority-high">Alta</span></>)
                    : null}
                  {schedule.schedule_priority_id === 2
                    ? (<><span className="schedule-badge priority-normal">Normal</span></>)
                    : null}
                  {schedule.schedule_priority_id === 3
                    ? (<><span className="schedule-badge priority-low">Baixa</span></>)
                    : null}
                </td>
              </tr>
              <tr>
                <td width="200" style={{ padding: '5px' }}>
                  <i className="pi pi-calendar" />
                  { ' ' }
                  <strong>Data acontecimento:</strong>
                </td>
                <td style={{ padding: '5px' }}>
                  {format(new Date(schedule.schedule_date), 'dd/MM/yyyy')}
                </td>
              </tr>
              <tr style={{ background: '#e2e2e2' }}>
                <td width="200" style={{ padding: '5px' }}>
                  <i className="pi pi-megaphone" />
                  { ' ' }
                  <strong>Assunto:</strong>
                </td>
                <td style={{ padding: '5px' }}>{schedule.schedule_subject}</td>
              </tr>
              <tr>
                <td width="200" style={{ padding: '5px' }}>
                  <i className="pi pi-comment" />
                  { ' ' }
                  <strong>Detalhamento:</strong>
                </td>
                <td style={{ padding: '5px' }}>{schedule.schedule_description}</td>
              </tr>
              {schedule.schedule_type_id === 2 ? (
                <>
                  <tr style={{ background: '#e2e2e2' }}>
                    <td width="200" style={{ padding: '5px' }}>
                      <i className="pi pi-clock" />
                      { ' ' }
                      <strong>Hora Início:</strong>
                    </td>
                    <td style={{ padding: '5px' }}>{timeStart}</td>
                  </tr>
                  <tr>
                    <td width="200" style={{ padding: '5px' }}>
                      <i className="pi pi-clock" />
                      { ' ' }
                      <strong>Hora término:</strong>
                    </td>
                    <td style={{ padding: '5px' }}>{timeEnd}</td>
                  </tr>
                  <tr style={{ background: '#e2e2e2' }}>
                    <td width="200" style={{ padding: '5px' }}>
                      <i className="pi pi-map-marker" />
                      { ' ' }
                      <strong>Local reunião:</strong>
                    </td>
                    <td style={{ padding: '5px' }}>{meetingRoom}</td>
                  </tr>
                </>
              ) : null }
            </tbody>
          </table>

          <h4>
            Informações adicionais para esta
            {schedule.schedule_type_id === 1 ? ' atividade' : ' reunião'}
          </h4>

          <DataTable
            tableClassName="table-lawsuitsprogresses table"
            id="table-lawsuitsprogresses"
            value={scheduleProgresses}
            dataKey="id"
            stripedRows
            className="p-datatable-sm"
            responsiveLayout="scroll"
            paginator
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={5}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            emptyMessage="Nenhum registro encontrado."
          >
            <Column key="sp_date" field="sp_date" header="Data" alignHeader="center" body={progressDateBodyTemplate} align="left" style={{ width: '100px' }} />
            <Column field="sp_description" header="Descrição" alignHeader="center" align="left" />
          </DataTable>
        </div>

      </Sidebar>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-schedules table"
            id="table-schedules"
            ref={dt}
            loading={loading}
            value={schedules}
            editMode="row"
            dataKey="id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedSchedules}
            onSelectionChange={(e) => {
              const selSchedules = e.value as IScheduleProperties[];
              setSelectedSchedules(selSchedules);
            }}
            onRowClick={(e) => {
              if (e.originalEvent.detail === 1) {
                timerId = setTimeout(() => {
                  loadSelectedSchedule(e.data);
                  setVisibleRight(true);
                }, 200);
              }
            }}
            onRowDoubleClick={(e) => {
              clearTimeout(timerId);
              editSchedule(e.data);
            }}
          >
            <Column key="schedule_date" field="schedule_date" header="Data acontecimento" body={dateBodyTemplate} alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="schedule_subject" header="Assunto" alignHeader="center" align="left" style={{ width: '200px' }} />
            <Column field="schedule_description" header="Detalhamento" alignHeader="center" align="left" />
            <Column key="schedule_status_id" field="schedule_status_id" header="Situação" body={statusBodyTemplate} alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column key="schedule_type_id" field="schedule_type_id" header="Tipo" body={scheduleTypeBodyTemplate} alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="schedule_priority_id" header="Prioridade" alignHeader="center" body={priorityBodyTemplate} align="left" style={{ width: '100px' }} />
            <Column body={actionBodyTemplate} align="right" style={{ width: '160px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteMeeting} style={{ width: '450px' }} header="Confirmação" modal footer={deleteMeetingFooter} onHide={hidedeleteMeeting}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {schedule && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{schedule.schedule_subject}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default Schedule;
