import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from '../styles';
import api from '../../../../services/api';

interface IFinancialRequests {
  pedido_id: number | null;
  pedido_proc_id: number;
  pedido_descricao: string;
  pedido_possibilidade: number;
  pedido_vl_demandado: number;
  pedido_vl_provavel: number;
  pedido_vl_possivel: number;
  pedido_vl_possivel2: number;
  cod_indexador: number;
  data_inicio_monetario: Date;
  data_inicio_juros: Date;
  correcao_monetaria: string;
  juros_simples: string;
  id_descricao_pedido: number;
  val_juros_atualizado: number;
  val_calculo_indice: number;
  val_juros_provavel: number;
  val_juros_possivel: number;
  val_juros_possivel2: number;
  val_calculo_provavel: number;
  val_calculo_possivel: number;
  val_calculo_possivel2: number;
  data_pedido: Date;
  cod_indexador2: number;
  data_inicio_monetario2: Date;
  cod_indexador3: number;
  data_inicio_monetario3: Date;
  cod_indexador4: number;
  data_inicio_monetario4: Date;
  cod_indexador5: number;
  data_inicio_monetario5: Date;
  negativo: number;
  calc_poss_remota: number;
  calc_vl_cont: number;
  pedido_vl_demandado_original: number;
  pedido_vl_provavel_original: number;
  pedido_vl_possivel_original: number;
  pedido_vl_possivel2_original: number;
  id_pedido_descricao: number;
}

const Requests: React.FC = () => {
  PrimeReact.ripple = true;

  const emptyFinancialRequest = {
    pedido_id: null,
    pedido_proc_id: 0,
    pedido_descricao: '',
    pedido_possibilidade: 0,
    pedido_vl_demandado: 0,
    pedido_vl_provavel: 0,
    pedido_vl_possivel: 0,
    pedido_vl_possivel2: 0,
    cod_indexador: 0,
    data_inicio_monetario: new Date(),
    data_inicio_juros: new Date(),
    correcao_monetaria: '',
    juros_simples: '',
    id_descricao_pedido: 0,
    val_juros_atualizado: 0,
    val_calculo_indice: 0,
    val_juros_provavel: 0,
    val_juros_possivel: 0,
    val_juros_possivel2: 0,
    val_calculo_provavel: 0,
    val_calculo_possivel: 0,
    val_calculo_possivel2: 0,
    data_pedido: new Date(),
    cod_indexador2: 0,
    data_inicio_monetario2: new Date(),
    cod_indexador3: 0,
    data_inicio_monetario3: new Date(),
    cod_indexador4: 0,
    data_inicio_monetario4: new Date(),
    cod_indexador5: 0,
    data_inicio_monetario5: new Date(),
    negativo: 0,
    calc_poss_remota: 0,
    calc_vl_cont: 0,
    pedido_vl_demandado_original: 0,
    pedido_vl_provavel_original: 0,
    pedido_vl_possivel_original: 0,
    pedido_vl_possivel2_original: 0,
    id_pedido_descricao: 0,
  };

  const [deleteFinancialRequest, setDeleteFinancialRequestDialog] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [financialItems, setFinancialItems] = useState([]);
  const [financialRequests, setFinancialRequests] = useState<IFinancialRequests[]>([]);
  const [financialRequest, setFinancialRequest] = useState(emptyFinancialRequest);
  const [gainOrLoss, setGainOrLoss] = useState(0);

  const dt = useRef(null);
  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  // const [updatedDate, setUpdatedDate] = useState<Date | Date[] | undefined>(undefined);
  const [updatedDate, setUpdatedDate] = useState<string | Date | Date[] | null>(null);
  const [value0, setValue0] = useState(0.00);
  const [value1, setValue1] = useState(0.00);
  const [value2, setValue2] = useState(0.00);

  useEffect(() => {
    (async () => {
      await api.get(`/lawsuits/financialrequests/show/filtered/${id}`).then((response) => {
        setFinancialRequests(response.data);
        // setValue0(response.data.proc_valor_causa);
      });
    })();
  }, [id, financialRequests]);

  useEffect(() => {
    (async () => {
      await api.get(`/lawsuits/financialitems/show/filtered/${id}`).then((response) => {
        setFinancialItems(response.data[0]);
        setGainOrLoss(response.data[0].proc_pedidos_possib_geral);

        if (response.data[0]?.proc_pedidos_data_atu) {
          const updatedDateBR = new Date(response.data[0]?.proc_pedidos_data_atu);

          if (response.data[0]?.proc_pedidos_data_atu !== null) {
            const updatedDateISO = new Date(updatedDateBR.toISOString().slice(0, -1));
            if (updatedDateISO.toString() === '01/01/1970') {
              setUpdatedDate(null);
            }
            setUpdatedDate(updatedDateISO);
          }
        } else {
          setUpdatedDate(null);
        }
        if (response.data[0]?.proc_valor_causa) {
          setValue0(response.data[0].proc_valor_causa);
        } else {
          setValue0(0.00);
        }
      });
    })();
  }, [id]);

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Descrição do pedido" rowSpan={2} align="center" />
        <Column header="Valor de cada pedido (R$)" rowSpan={2} align="center" />
        <Column header={`Possibilidade de ${gainOrLoss === 1 ? 'êxito' : 'perda'}`} colSpan={4} align="center" />
        <Column header="Data (*)" rowSpan={2} align="center" />
        <Column rowSpan={2} />
      </Row>
      <Row>
        <Column header="Provável (≥ 80%)" field="lastYearSale" align="center" />
        <Column header="Possível (> 50%)" field="thisYearSale" align="center" />
        <Column header="Possível (≤ 50%)" field="lastYearProfit" align="center" />
        <Column header="Remota (≤ 20%)" field="thisYearProfit" align="center" />
      </Row>
    </ColumnGroup>
  );

  const formatCurrency = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  const pedido_vl_demandado_Total = () => {
    const total = financialRequests
      .map((value) => Number(value.pedido_vl_demandado))
      .reduce((a, b) => a + b, 0);
    setValue2(Number(total));
    return formatCurrency(total);
  };

  const pedido_vl_provavel_Total = () => {
    const total = financialRequests
      .map((value) => Number(value.pedido_vl_provavel))
      .reduce((a, b) => a + b, 0);
    return formatCurrency(total);
  };

  const pedido_vl_possivel_Total = () => {
    const total = financialRequests
      .map((value) => Number(value.pedido_vl_possivel))
      .reduce((a, b) => a + b, 0);
    return formatCurrency(total);
  };

  const pedido_vl_possivel2_Total = () => {
    const total = financialRequests
      .map((value) => Number(value.pedido_vl_possivel2))
      .reduce((a, b) => a + b, 0);
    return formatCurrency(total);
  };

  const pedido_possibilidade_Total = () => {
    const total = financialRequests
      .map((value) => Number(value.calc_poss_remota))
      .reduce((a, b) => a + b, 0);
    return formatCurrency(total);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totais" footerStyle={{ textAlign: 'center' }} />
        <Column footer={pedido_vl_demandado_Total} footerStyle={{ textAlign: 'right' }} />
        <Column footer={pedido_vl_provavel_Total} footerStyle={{ textAlign: 'right' }} />
        <Column footer={pedido_vl_possivel_Total} footerStyle={{ textAlign: 'right' }} />
        <Column footer={pedido_vl_possivel2_Total} footerStyle={{ textAlign: 'right' }} />
        <Column footer={pedido_possibilidade_Total} footerStyle={{ textAlign: 'right' }} />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  const descriptionErrorBodyTemplate = (rowData: {
    pedido_descricao: string;
  }) => {
    if (financialRequests !== undefined) {
      const filteredDescription = financialRequests.filter((item) => (
        item.pedido_descricao === rowData.pedido_descricao
      ), []);

      if (filteredDescription[0]?.id_descricao_pedido === null) {
        return (
          <span className="errored-text">
            {filteredDescription[0]?.pedido_descricao}
          </span>
        );
      }
      return filteredDescription[0]?.pedido_descricao;
    }
    return '';
  };

  const dateBodyTemplate = (rowData: {
    data_pedido: Date;
  }) => {
    if (financialRequests !== undefined) {
      const filteredDate = financialRequests.filter((item) => (
        item.data_pedido === rowData.data_pedido
      ), []);

      if (filteredDate[0]?.data_pedido !== null
        && filteredDate[0]?.data_pedido.toString() !== '01/01/1970'
        && filteredDate[0]?.data_pedido.toString() !== '31/12/1969') {
        const dateInput = filteredDate[0].data_pedido?.toString();

        const dateBR = new Date(dateInput);
        const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

        return formattedDate;
      }
      return '';
    }
    return '';
  };

  const valueBodyTemplate = (rowData: {
    pedido_vl_demandado: number;
  }) => {
    if (financialRequests !== undefined) {
      const filteredValue = financialRequests.filter((item) => (
        item.pedido_vl_demandado === rowData.pedido_vl_demandado
      ), []);

      if (filteredValue[0].pedido_vl_demandado !== 0) {
        const pedido_vl_demandado = Number(filteredValue[0].pedido_vl_demandado)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        const pedido_vl_demandado_original = Number(filteredValue[0].pedido_vl_demandado_original)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        const val_calculo_indice = Number(filteredValue[0].val_calculo_indice)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        const val_juros_atualizado = Number(filteredValue[0].val_juros_atualizado)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        return (
          <>
            <Tooltip
              target=".dt-vl_demandado"
              mouseTrack
              mouseTrackLeft={10}
            />
            <span
              className="dt-vl_demandado"
              data-pr-tooltip={`Valor: R$${pedido_vl_demandado_original}
                                Calculado: ${val_calculo_indice}
                                Juros: R$${val_juros_atualizado}`}
            >
              {pedido_vl_demandado}
            </span>
          </>
        );
      }
      return '0,00';
    }
    return '-';
  };

  const value1BodyTemplate = (rowData: {
    pedido_vl_provavel: number;
  }) => {
    if (financialRequests !== undefined) {
      const filteredValue = financialRequests.filter((item) => (
        item.pedido_vl_provavel === rowData.pedido_vl_provavel
      ), []);

      if (filteredValue[0].pedido_vl_provavel !== 0) {
        const pedido_vl_provavel = Number(filteredValue[0].pedido_vl_provavel)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        const pedido_vl_provavel_original = Number(filteredValue[0].pedido_vl_provavel_original)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        const val_calculo_provavel = Number(filteredValue[0].val_calculo_provavel)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        const val_juros_provavel = Number(filteredValue[0].val_juros_provavel)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return (
          <>
            <Tooltip
              target=".dt-vl_provavel"
              mouseTrack
              mouseTrackLeft={10}
            />
            <span
              className="dt-vl_provavel"
              data-pr-tooltip={`Valor: R$${pedido_vl_provavel_original}
                                Calculado: ${val_calculo_provavel}
                                Juros: R$${val_juros_provavel}`}
            >
              {pedido_vl_provavel}
            </span>
          </>
        );
      }
      return '0,00';
    }
    return '-';
  };

  const value2BodyTemplate = (rowData: {
    pedido_vl_possivel: number;
  }) => {
    if (financialRequests !== undefined) {
      const filteredValue = financialRequests.filter((item) => (
        item.pedido_vl_possivel === rowData.pedido_vl_possivel
      ), []);

      if (filteredValue[0].pedido_vl_possivel !== 0) {
        const pedido_vl_possivel = Number(filteredValue[0].pedido_vl_possivel)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return pedido_vl_possivel;
      }
      return '0,00';
    }
    return '-';
  };

  const value3BodyTemplate = (rowData: {
    pedido_vl_possivel2: number;
  }) => {
    if (financialRequests !== undefined) {
      const filteredValue = financialRequests.filter((item) => (
        item.pedido_vl_possivel2 === rowData.pedido_vl_possivel2
      ), []);

      if (filteredValue[0].pedido_vl_possivel2 !== 0) {
        const pedido_vl_possivel2 = Number(filteredValue[0].pedido_vl_possivel2)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return pedido_vl_possivel2;
      }
      return '0,00';
    }
    return '-';
  };

  const value4BodyTemplate = (rowData: {
    calc_poss_remota: number;
  }) => {
    if (financialRequests !== undefined) {
      const filteredValue = financialRequests.filter((item) => (
        item.calc_poss_remota === rowData.calc_poss_remota
      ), []);

      if (filteredValue[0].calc_poss_remota !== 0) {
        const calc_poss_remota = Number(filteredValue[0].calc_poss_remota)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return calc_poss_remota;
      }
      return '0,00';
    }
    return '-';
  };

  const handleCreate = () => {
    history.push(`/lawsuit/financialitem/request/create?cod=${id}&active=2`);
  };

  const hidedeleteFinancialRequest = () => {
    setDeleteFinancialRequestDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editFinancialRequest = (rowData:any) => {
    history.push(`/lawsuit/financialitem/request/edit/${rowData.pedido_id}?cod=${id}&active=2`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteFinancialRequest = (financialRequest: React.SetStateAction<{
    pedido_id: null; pedido_proc_id: number; pedido_descricao: string;
    pedido_possibilidade: number; pedido_vl_demandado: number; pedido_vl_provavel: number;
    pedido_vl_possivel: number; pedido_vl_possivel2: number; cod_indexador: number;
    data_inicio_monetario: Date; data_inicio_juros: Date; correcao_monetaria: string;
    juros_simples: string; id_descricao_pedido: number; val_juros_atualizado: number;
    val_calculo_indice: number; val_juros_provavel: number; val_juros_possivel: number;
    val_juros_possivel2: number; val_calculo_provavel: number; val_calculo_possivel: number;
    val_calculo_possivel2: number; data_pedido: Date; cod_indexador2: number;
    data_inicio_monetario2: Date; cod_indexador3: number; data_inicio_monetario3: Date;
    cod_indexador4: number; data_inicio_monetario4: Date; cod_indexador5: number;
    data_inicio_monetario5: Date; negativo: number; calc_poss_remota: number;
    calc_vl_cont: number; pedido_vl_demandado_original: number;
    pedido_vl_provavel_original: number; pedido_vl_possivel_original: number;
    pedido_vl_possivel2_original: number; id_pedido_descricao: number; }>) => {
    setFinancialRequest(financialRequest);
    setDeleteFinancialRequestDialog(true);
  };

  const deleteFinancialRequestData = () => {
    api.delete(`/lawsuits/financialrequests/delete/request/${financialRequest.pedido_id}`);

    setDeleteFinancialRequestDialog(false);

    const updatedRecords = financialRequests
      .filter((val) => val.pedido_id !== financialRequest.pedido_id);
    setFinancialRequests(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const leftToolbarTemplate = () => (
    <>
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editFinancialRequest(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteFinancialRequest(rowData); }}
      />
    </>
  );

  const deleteFinancialRequestFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteFinancialRequest} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteFinancialRequestData} />
    </>
  );

  const handleDateUpdate = async () => {
    const formData = {
      proc_pedidos_data_atu: updatedDate,
    };
    await api.patch(`/lawsuits/financialitems/patch/dateupdate/${id}`, formData).then((response) => {
      if (response.data === true) {
        (async () => {
          await api.get(`/lawsuits/financialrequests/show/filtered/${id}`).then((responseRequest) => {
            setFinancialRequests(responseRequest.data);
            addToast({
              type: 'success',
              title: 'Cadastro atualizado!',
              description:
                'As informações do cadastro foram atualizadas com sucesso.',
            });
            toast.current?.show({
              severity: 'success',
              summary: 'Valores pedido atualizados',
              detail: 'Os valores do pedido foram atualizados com sucesso.',
              life: 3000,
            });
          });
        })();
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    });
  };

  const handleLawsuitValueUpdate = async () => {
    const formData = {
      proc_valor_causa: value1,
    };

    await api.patch(`/lawsuits/financialitems/patch/updatelawsuitvalue/${id}`, formData).then((response) => {
      if (response.data === true) {
        (async () => {
          await api.get(`/lawsuits/financialrequests/show/filtered/${id}`).then((responseRequest) => {
            setFinancialRequests(responseRequest.data);
          });
          await api.get(`/lawsuits/financialitems/show/filtered/${id}`).then((responseItems) => {
            setValue0(responseItems.data[0].proc_valor_causa);
          });
        })();
        setValue1(0.00);
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });
        toast.current?.show({
          severity: 'success',
          summary: 'Valores financeiros atualizados',
          detail: 'Os valores financeiros foram atualizados com sucesso.',
          life: 3000,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    });
  };

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <div className="datatable-responsive">
        <div className="col-12 p-mt-20">
          <header>
            <h4>Pedido</h4>
          </header>

          <div className="col-12 p-mt-20">

            <Toolbar className="p-mb-4" left={leftToolbarTemplate} />

            <DataTable
              tableClassName="table-financialrequests table"
              id="table-financialrequests"
              ref={dt}
              value={financialRequests}
              editMode="row"
              dataKey="pedido_id"
              headerColumnGroup={headerGroup}
              footerColumnGroup={footerGroup}
              stripedRows
              showGridlines
              className="p-datatable-sm"
              emptyMessage="Nenhum registro encontrado."
              onRowDoubleClick={(e) => editFinancialRequest(e.data)}
            >
              <Column field="pedido_descricao" body={descriptionErrorBodyTemplate} align="left" />
              <Column field="pedido_vl_demandado" body={valueBodyTemplate} align="right" />
              <Column field="pedido_vl_provavel" body={value1BodyTemplate} align="right" />
              <Column field="pedido_vl_possivel" body={value2BodyTemplate} align="right" />
              <Column field="pedido_vl_possivel2" body={value3BodyTemplate} align="right" />
              <Column field="pedido_possibilidade" body={value4BodyTemplate} align="right" />
              <Column field="data_pedido" body={dateBodyTemplate} align="right" />
              <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
            </DataTable>
            <span>
              <small>
                (*) Data estimada do possível pagamento ou recebimento pelo
                cliente, para o caso de perda ou êxito, respectivamente.

              </small>
            </span>

          </div>

          <div>
            <Dialog visible={deleteFinancialRequest} style={{ width: '450px' }} header="Confirmação" modal footer={deleteFinancialRequestFooter} onHide={hidedeleteFinancialRequest}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {financialRequests && (
                <span>
                  Você tem certeza que deseja excluir
                  <br />
                  <br />
                  <b>{financialRequest.pedido_descricao}</b>
                  ?
                </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>

        <div className="col-12 p-mt-20">
          <header>
            <h4>Datas e valores nominais/econômicos</h4>
          </header>
        </div>

        <div className="col-12">
          <div className="field grid">
            <div className="col-12 md:col-6 p-pl-zero">
              <div className="col-12 md:col-12">
                <label htmlFor="proc_pedidos_data_atu">
                  Data da última atualização dos valores
                </label>
              </div>
              <div className="col-12 md:col-12">
                <Calendar
                  inputId="proc_pedidos_data_atu"
                  name="proc_pedidos_data_atu"
                  className="inputfield"
                  value={updatedDate}
                  // onChange={(e) => setUpdatedDate(e.value)}
                  onChange={(e : CalendarChangeEvent) => {
                    if (e.value !== undefined && e.value !== null) {
                      setUpdatedDate(e.value);
                    }
                  }}
                  keepInvalid
                  showIcon
                  showButtonBar
                  showOnFocus={false}
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  yearRange="1980:2030"
                />
                <Button
                  icon="pi pi-check"
                  iconPos="left"
                  label="Atualizar data"
                  className="p-ml-2"
                  type="button"
                  onClick={handleDateUpdate}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="field grid">
            <div className="col-12 md:col-3 ">
              <label htmlFor="proc_valor_causa">
                Valor nominal do processo
              </label>
              <InputNumber
                id="proc_valor_causa"
                name="proc_valor_causa"
                className="inputfield w-full "
                value={value0}
                onValueChange={(e) => { setValue0(e.value || 0); }}
                placeholder="0,00"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                locale="pt-BR"
                readOnly
                disabled
              />
              <small id="proc_valor_causa-help" className="block">(valor dado à causa)</small>
            </div>
            <div className="col-12 md:col-3">
              <label htmlFor="proc_valor_causa_novo">
                Novo valor
              </label>
              <InputNumber
                id="proc_valor_causa_novo"
                name="proc_valor_causa_novo"
                className="inputfield w-full"
                value={value1}
                onValueChange={(e) => { setValue1(e.value || 0); }}
                placeholder="0,00"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                locale="pt-BR"
              />
            </div>
            <div className="col-12 md:col-3">
              <br />
              <Button
                icon="pi pi-check"
                iconPos="left"
                label="Atualizar valor"
                type="button"
                onClick={handleLawsuitValueUpdate}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="field grid">
            <div className="col-12 md:col-3">
              <label htmlFor="pedido_vl_demandado_total">
                Valor econômico do processo
              </label>
              <InputNumber
                id="pedido_vl_demandado_total"
                name="pedido_vl_demandado_total"
                className="inputfield w-full"
                value={value2}
                onValueChange={(e) => { setValue2(e.value || 0); }}
                placeholder="0,00"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                locale="pt-BR"
                readOnly
              />
              <small id="pedido_vl_demandado_total-help" className="block">(soma dos pedidos)</small>
            </div>
          </div>
        </div>

      </div>

    </Container>

  );
};

export default Requests;
