import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

interface IBusinessGroupProperties {
  id: number | null;
  parent_company_id: number;
  children_companies: string;
  report_columns: string;
}

interface ICustomerProperties {
  cliente_id: number | null;
  cliente_nome: string;
}

const BusinessGroup: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyResult = {
    id: null,
    parent_company_id: 0,
    children_companies: '',
  };

  const [businessGroups, setBusinessGroups] = useState<IBusinessGroupProperties[]>([]);
  const [businessGroup, setBusinessGroup] = useState(emptyResult);
  const [deleteBusinessGroup, setDeleteBusinessGroup] = useState(false);
  const [deleteBusinessGroupsDialog, setDeleteResultsDialog] = useState(false);
  const [
    selectedBusinessGroups, setSelectedBusinessGroups,
  ] = useState<IBusinessGroupProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [customers, setCustomers] = useState<ICustomerProperties[]>([]);

  useEffect(() => {
    (async () => {
      const response = await api.get('/businessgroups/show/all');
      setBusinessGroups(response.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await api.get('/customers/show/all').then((response) => {
        setCustomers(response.data);
      });
    })();
  }, []);

  const handleCreate = () => {
    history.push('/businessgroup/create');
  };

  const hidedeleteBusinessGroup = () => {
    setDeleteBusinessGroup(false);
  };

  const hidedeleteBusinessGroupsDialog = () => {
    setDeleteResultsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editResult = (rowData:any) => {
    history.push(`/businessgroup/edit/${rowData.id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteResult = (businessGroup: React.SetStateAction<{
    id: null; parent_company_id: number; children_companies: string;
  }>) => {
    setBusinessGroup(businessGroup);
    setDeleteBusinessGroup(true);
  };

  const deleteGroup = () => {
    api.delete(`/businessgroups/delete/${businessGroup.id}`);

    setDeleteBusinessGroup(false);

    const updatedRecords = businessGroups
      .filter((val) => val.id !== businessGroup.id);
    setBusinessGroups(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editResult(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteResult(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteBusinessGroupFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteBusinessGroup} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGroup} />
    </>
  );
  const deleteBusinessGroupsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteBusinessGroupsDialog} />
    </>
  );

  const parentCompanyBodyTemplate = (rowData: { parent_company_id: number; }) => {
    if (customers !== undefined) {
      const filteredCustomer = customers.filter((item) => (
        item.cliente_id === rowData.parent_company_id
      ), []);

      return filteredCustomer[0]?.cliente_nome;
    }
    return '';
  };

  const childrenCompanyBodyTemplate = (rowData: { children_companies: string; }) => {
    if (customers !== undefined) {
      if (rowData.children_companies.length > 0) {
        const childrenIds = rowData.children_companies.split(',');
        let chidrenNames = '';

        if (childrenIds.length > 0) {
          childrenIds.forEach((item) => {
            const filteredCustomer = customers.filter((stored) => (
              stored.cliente_id === Number(item)
            ), []);

            if (chidrenNames === '') {
              chidrenNames = `${filteredCustomer[0]?.cliente_nome}`;
            } else {
              chidrenNames += `, ${filteredCustomer[0]?.cliente_nome}`;
            }
          });
          return chidrenNames;
        }
      } else {
        return '';
      }
    }
    return '';
  };

  const reportColumnsBodyTemplate = (rowData: { report_columns: string; }) => {
    const reportColumns = rowData.report_columns.split(',');
    let columnNames = '';
    let friendlyColumnName = '';

    if (reportColumns.length > 0) {
      reportColumns.forEach((item) => {
        switch (item) {
          case 'proc_reg':
            friendlyColumnName = 'Número do processo';
            break;
          case 'proc_autor':
            friendlyColumnName = 'Autor';
            break;
          case 'proc_reu':
            friendlyColumnName = 'Réu';
            break;
          case 'proc_situacao':
            friendlyColumnName = 'Situação atual do processo';
            break;
          case 'proc_cod_natureza':
            friendlyColumnName = 'Natureza do processo';
            break;
          case 'acao_descricao':
            friendlyColumnName = 'Tipo de ação';
            break;
          case 'orgao_descricao':
            friendlyColumnName = 'Orgão julgador';
            break;
          case 'proc_orgao_cidade':
            friendlyColumnName = 'Cidade';
            break;
          case 'proc_orgao_uf':
            friendlyColumnName = 'Estado';
            break;
          case 'cliente_nome':
            friendlyColumnName = 'Cliente';
            break;
          case 'proc_data_ajuiz':
            friendlyColumnName = 'Data do ajuizamento';
            break;
          case 'proc_data_ingresso':
            friendlyColumnName = 'Data de ingresso no escritório';
            break;
          case 'proc_valor_causa':
            friendlyColumnName = 'Valor nominal';
            break;
          default:
            friendlyColumnName = '';
            break;
        }

        if (columnNames === '') {
          columnNames = `${friendlyColumnName}`;
        } else {
          columnNames += `, ${friendlyColumnName}`;
        }
      });
      return columnNames;
    }
    return '';
  };

  return (
    <Container>
      <header>
        <div>
          <h1>Grupo econômico</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-businessGroups table"
            id="table-businessGroups"
            ref={dt}
            value={businessGroups}
            editMode="row"
            dataKey="id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedBusinessGroups}
            onSelectionChange={(e) => {
              const selBusinessGroups = e.value as IBusinessGroupProperties[];
              setSelectedBusinessGroups(selBusinessGroups);
            }}
            onRowDoubleClick={(e) => editResult(e.data)}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column key="id" field="id" header="Código" alignHeader="center" align="left" style={{ width: '80px' }} />
            <Column field="parent_company_id" header="Empresa mãe do grupo" body={parentCompanyBodyTemplate} alignHeader="center" align="left" style={{ width: '200px' }} />
            <Column field="children_companies" header="Empresas do grupo" body={childrenCompanyBodyTemplate} alignHeader="center" align="left" style={{ width: '350px' }} />
            <Column field="report_columns" header="Campos vísiveis relatório" body={reportColumnsBodyTemplate} alignHeader="center" align="left" />
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>
        </div>

        <div>
          <Dialog visible={deleteBusinessGroup} style={{ width: '450px' }} header="Confirmação" modal footer={deleteBusinessGroupFooter} onHide={hidedeleteBusinessGroup}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {businessGroup && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{businessGroup.parent_company_id}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteBusinessGroupsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteBusinessGroupsDialogFooter} onHide={hidedeleteBusinessGroupsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {businessGroup
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default BusinessGroup;
