import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../services/api';

interface ILawsuit {
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
}

interface ILinkedLawsuit {
  proc_vinc_reg: string;
  proc_vinc_reg_outros: string;
  proc_vinc_principal_id: number;
  proc_vinc_num_pasta: string;
  proc_vinc_cod_tipo: number;
  proc_vinc_cod_orgao: number;
  proc_vinc_orgao_cidade: string;
  proc_vinc_orgao_uf: string;
  proc_vinc_url: string;
  proc_vinc_polo_ativo: string;
  proc_vinc_polo_passivo: string;
  proc_vinc_arquivado: boolean;
}

interface ILinkedLawsuitProgress {
  proc_vinc_and_id: number;
  proc_vinc_and_proc_id: number;
  proc_vinc_and_data: Date;
  proc_vinc_and_andamento: string;
  proc_vinc_and_arquivo: string;
  proc_vinc_and_obs: string;
  proc_vinc_and_enviado: boolean;
}

interface IAttorneyProperties {
  advogado_id: number | null;
  advogado_nome: string;
  advogado_login: string;
  advogado_senha: string;
  advogado_email: string;
  advogado_oab: string;
  advogado_categoria: number;
  advogado_ativo: boolean;
  advogado_nasc: Date;
  advogado_naturalidade: string;
  advogado_end_residencial: string;
  advogado_end_residencial_compl: string;
  advogado_fone_residencial: string;
  advogado_fone_celular: string;
  advogado_conjuge_nome: string;
  advogado_conjuge_nasc: Date;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adv: number;
  advogado_valor: string;
  advogado_setor: string;
}

interface ICustomerProperties {
  cliente_id: number | null;
  cliente_nome: string;
  cliente_login: string;
  cliente_senha: string;
  cliente_email: string;
  cliente_contato: string;
  cliente_endereco: string;
  cliente_cidade: string;
  cliente_bairro: string;
  cliente_cep: string;
  cliente_uf: string;
  cliente_fone: string;
  cliente_fax: string;
  cliente_url: string;
  cliente_classificacao: number;
  cliente_categoria: number;
  cliente_ativo: boolean;
  cliente_tratamento: string;
  cliente_cnpj_cpf: string;
  cliente_naocliente: boolean;
  cliente_informativo: boolean;
}

const LinkedLawsuitProgress: React.FC = () => {
  PrimeReact.ripple = true;

  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');
  const vinc = params.get('vinc');
  const activeTab = params.get('active');

  const returnLawsuitURL = `/lawsuit/main/edit/${cod}?active=${activeTab}`;

  const emptyLinkedLawsuitProgress = {
    proc_vinc_and_id: null,
    proc_vinc_and_proc_id: Number(cod),
    proc_vinc_and_data: new Date(),
    proc_vinc_and_andamento: '',
    proc_vinc_and_arquivo: '',
    proc_vinc_and_obs: '',
    proc_vinc_and_enviado: false,
  };

  const [lawsuit, setLawsuit] = useState<ILawsuit>();
  const [linkedLawsuit, setLinkedLawsuit] = useState<ILinkedLawsuit>();
  const [
    linkedLawsuitProgresses,
    setLinkedLawsuitProgresses,
  ] = useState<ILinkedLawsuitProgress[]>([]);
  const [linkedLawsuitProgress, setLinkedLawsuitProgress] = useState(emptyLinkedLawsuitProgress);

  const [deleteLinkedLawsuitDialog, setDeleteLinkedLawsuitDialog] = useState(false);
  const [deleteLinkedLawsuitsDialog, setDeleteLinkedLawsuitsDialog] = useState(false);
  const [
    selectedLinkedLawsuits,
    setSelectedLinkedLawsuits,
  ] = useState<ILinkedLawsuitProgress[]>([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentLawsuit, setCurrentLawsuit] = useState(0);
  const [currentLinkedLawsuit, setCurrentLinkedLawsuit] = useState(0);

  const [judgingBodyLinkedlawsuit, setJudgingBodyLinkedlawsuit] = useState('');
  const [linkedLawsuitType, setLinkedLawsuitType] = useState('');
  const [origin, setOrigin] = useState('');

  const lawsuitSummaryHeader = useRef(null);

  const [otherEmails, setOtherEmails] = useState('');
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);
  const [notifyAttorney, setNotifyAttorney] = useState<boolean>(false);
  const [customers, setCustomers] = useState<ICustomerProperties[]>([]);
  const [attorneys, setAttorneys] = useState<IAttorneyProperties[]>([]);

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        setCustomers(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await api.get('management/attorneys/show/all').then((response) => {
        setAttorneys(response.data);
      });
    })();
  }, []);

  // control tab changes when leaving/returning to page
  useEffect(() => {
    if (activeTab !== undefined || activeTab !== null) {
      setActiveIndex(Number(activeTab));
    }
    if (cod !== undefined || cod !== null) {
      setCurrentLawsuit(Number(cod));
    }
    if (vinc !== undefined || vinc !== null) {
      setCurrentLinkedLawsuit(Number(vinc));
    }
  }, [activeTab, cod, activeIndex, vinc]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/lawsuits/main/show/${cod}`);
      setLawsuit(response.data);

      const judgingBodyId = response.data.proc_cod_orgao;
      const city = response.data.proc_orgao_cidade;
      const county = response.data.proc_orgao_uf;
      let judgingBody = '';

      api.get(`/lawsuits/judgingbodies/show/${judgingBodyId}`).then((responsebodies) => {
        judgingBody = responsebodies.data.orgao_descricao;

        const originConcat = `${judgingBody} - ${city}/${county}`;
        setOrigin(originConcat);
      });
    })();
  }, [cod]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/lawsuits/linkedlawsuits/show/${vinc}`);
      setLinkedLawsuit(response.data);

      const judgingBodyId = response.data.proc_vinc_cod_orgao;
      const linkedLawsuitTypeId = response.data.proc_vinc_cod_tipo;

      await api.get(`/lawsuits/judgingbodies/show/${judgingBodyId}`).then((responsebodies) => {
        setJudgingBodyLinkedlawsuit(responsebodies.data.orgao_descricao);
      });

      await api.get(`/lawsuits/linkedlawsuitstypes/show/${linkedLawsuitTypeId}`).then((responseLinkedLawsuitType) => {
        setLinkedLawsuitType(responseLinkedLawsuitType.data.proc_vinc_tipo_descricao);
      });
    })();
  }, [vinc, linkedLawsuitType]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/lawsuits/linkedlawsuitsprogresses/show/filtered/${vinc}`);
      setLinkedLawsuitProgresses(response.data);
    })();
  }, [vinc]);

  const columns = [
    { field: 'proc_vinc_and_data', header: 'Data' },
    { field: 'proc_vinc_and_andamento', header: 'Andamento' },
    { field: 'proc_vinc_and_id', header: 'Enviar inf.' },
    { field: 'proc_vinc_and_enviado', header: 'Status' },
  ];

  const dateBodyTemplate = (rowData: { proc_vinc_and_data: Date; }) => {
    if (linkedLawsuitProgresses !== undefined) {
      const filteredDate = linkedLawsuitProgresses.filter((item) => (
        item.proc_vinc_and_data === rowData.proc_vinc_and_data
      ), []);

      const dateInput = filteredDate[0].proc_vinc_and_data?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }

    return '';
  };

  const checkboxBodyTemplate = (rowData: { and_id: number; }) => {
    if (linkedLawsuitProgresses !== undefined) {
      <Checkbox
        inputId={`${rowData.and_id.toString()}`}
        name={`cb${rowData.and_id.toString()}`}
        value={selectedLinkedLawsuits}
        checked={false}
      />;
    }
  };

  const statusBodyTemplate = (rowData: { proc_vinc_and_enviado: boolean; }) => {
    if (linkedLawsuitProgresses !== undefined) {
      const filteredStatus = linkedLawsuitProgresses.filter((item) => (
        item.proc_vinc_and_enviado === rowData.proc_vinc_and_enviado
      ), []);

      if (filteredStatus[0]?.proc_vinc_and_enviado === true) {
        return <Button type="button" icon="pi pi-bell" className="p-button-rounded p-button-text p-button-plain p-button-success" />;
      }
      return <Button type="button" icon="pi pi-bell" className="p-button-rounded p-button-text p-button-plain p-button-danger" />;
    }
    return false;
  };

  const dynamicColumnsLinkedLawsuitProgress = columns.map((col) => {
    if (col.field === 'proc_vinc_and_enviado') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={statusBodyTemplate}
          sortable={false}
          headerStyle={{ width: '60px' }}
        />
      );
    } if (col.field === 'proc_vinc_and_data') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={dateBodyTemplate}
          sortable={false}
          headerStyle={{ width: '100px' }}
          className="p-dt-tooltip"
        />
      );
    } if (col.field === 'proc_vinc_and_id') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={checkboxBodyTemplate}
          selectionMode="multiple"
          sortable={false}
          headerStyle={{ width: '60px' }}
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={false}
      />
    );
  });

  const handleCreate = () => {
    history.push(`/lawsuit/linkedlawsuit/progress/create?cod=${currentLawsuit}&vinc=${currentLinkedLawsuit}`);
  };

  const hidedeleteLinkedLawsuitDialog = () => {
    setDeleteLinkedLawsuitDialog(false);
  };

  const hidedeleteLinkedLawsuitsDialog = () => {
    setDeleteLinkedLawsuitsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLinkedLawsuitProgress = (rowData:any) => {
    history.push(`/lawsuit/linkedlawsuit/progress/edit/${rowData.proc_vinc_and_id}?cod=${currentLawsuit}&vinc=${currentLinkedLawsuit}&active=5`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteLinkedLawsuitProgress = (linkedLawsuitProgress: React.SetStateAction<{
    proc_vinc_and_id: null; proc_vinc_and_proc_id: number; proc_vinc_and_data: Date;
    proc_vinc_and_andamento: string; proc_vinc_and_arquivo: string; proc_vinc_and_obs: string;
    proc_vinc_and_enviado: boolean; }>) => {
    setLinkedLawsuitProgress(linkedLawsuitProgress);
    setDeleteLinkedLawsuitDialog(true);
  };

  const deleteSelectedLinkedLawsuitProgress = () => {
    api.delete(`/lawsuits/linkedlawsuitsprogresses/delete/${linkedLawsuitProgress.proc_vinc_and_id}`);

    setDeleteLinkedLawsuitDialog(false);

    const updatedRecords = linkedLawsuitProgresses
      .filter((val) => val.proc_vinc_and_id !== linkedLawsuitProgress.proc_vinc_and_id);
    setLinkedLawsuitProgresses(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const handleFileOpen = (rowData: { proc_vinc_and_arquivo: string; }) => {
    (async () => {
      window.open(`${rowData.proc_vinc_and_arquivo}`, '_blank');
    })();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editLinkedLawsuitProgress(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteLinkedLawsuitProgress(rowData); }}
      />
      {rowData.proc_vinc_and_arquivo.length > 2 ? (
        <Button
          type="button"
          icon="pi pi-file-pdf"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => { handleFileOpen(rowData); }}
          tooltip="Abrir arquivo remotamente"
          tooltipOptions={{ position: 'left' }}
        />
      ) : ('') }
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteLinkedLawsuitProgressFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLinkedLawsuitDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedLinkedLawsuitProgress} />
    </>
  );
  const deleteLinkedLawsuitsProgressDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLinkedLawsuitsDialog} />
    </>
  );

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  const handleNotificationEmails = () => {
    try {
      let customerName = '';
      let customerEmail = '';
      let attorneyName = '';
      let attorneyEmail = '';

      if (notifyCustomer) {
        const filteredCustomer = customers.filter((item) => (
          item.cliente_id === lawsuit?.proc_cod_cliente
        ), []);

        if (filteredCustomer.length < 0) {
          addToast({
            type: 'error',
            title: 'Ocorreu um problema',
            description:
                    'O cliente não possui e-mail válido ou e-mail é inexistente.',
          });
          setNotifyCustomer(false);
        }
        customerName = filteredCustomer[0]?.cliente_nome.length > 0 ? filteredCustomer[0]?.cliente_nome : '';
        customerEmail = filteredCustomer[0]?.cliente_email.length > 0 ? filteredCustomer[0]?.cliente_email : '';
      }

      if (notifyAttorney) {
        const filteredAttorney = attorneys.filter((item) => (
          item.advogado_id === lawsuit?.proc_cod_adv_resp
        ), []);

        attorneyName = filteredAttorney[0].advogado_nome;
        attorneyEmail = filteredAttorney[0].advogado_email;
      }

      const linkedLawsuitID = vinc;
      let progressID = '';

      if (selectedLinkedLawsuits) {
        selectedLinkedLawsuits.forEach((item: {proc_vinc_and_id: number}) => {
          if (progressID === '') {
            progressID = item.proc_vinc_and_id.toString();
          } else {
            progressID += `,${item.proc_vinc_and_id.toString()}`;
          }
        });
      }

      // TODO: adjust sending email routine and template
      api.post('/lawsuits/progresses/sendmail', {
        linkedLawsuitID,
        customerName,
        customerEmail,
        attorneyName,
        attorneyEmail,
        otherEmails,
        progressID,
      });

      addToast({
        type: 'success',
        title: 'E-mail(s) enviado(s) com sucesso',
        description: 'O(s) e-mails selecionados para envio foram processados com sucesso.',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar o email',
        description: 'Ocorreu um erro ao enviar o e-mail. Tente novamente.',
      });
    }
  };

  return (
    <Container>
      <div className="card">
        <div className="flex right field button-return">
          <Button
            icon="pi pi-times"
            iconPos="left"
            label="Voltar listagem principal processos vinculados"
            className="p-button-info"
            type="button"
            onClick={handleCancel}
            style={{ width: '400px' }}
          />
        </div>
        <header>
          <h3 style={{ width: '400px' }}>Dados do Processo</h3>
        </header>
        <div className="fluid formgrid grid">

          <div className="col-12">
            <div className="field grid">
              <div className="col-12 md:col-6">

                {/* commmon data shown through tabs with essential lawsuit information */}
                <table ref={lawsuitSummaryHeader} width="1000" id="lawsuit_summary">
                  <tbody>
                    <tr>
                      <td width="150"><strong>Pasta nº:</strong></td>
                      <td width="350">{lawsuit?.proc_num_pasta}</td>
                      <td width="20">&nbsp;</td>
                      <td width="150"><strong>Processo nº:</strong></td>
                      <td width="350">{lawsuit?.proc_reg}</td>
                    </tr>
                    <tr className="stripedRows">
                      <td width="150"><strong>Autor(es):</strong></td>
                      <td width="350">{lawsuit?.proc_autor}</td>
                      <td width="20">&nbsp;</td>
                      <td width="150"><strong>Réu(s):</strong></td>
                      <td width="350">{lawsuit?.proc_reu}</td>
                    </tr>
                    <tr>
                      <td width="150"><strong>Assunto:</strong></td>
                      <td width="350">{lawsuit?.proc_assunto}</td>
                      <td width="20">&nbsp;</td>
                      <td width="150"><strong>Origem:</strong></td>
                      <td width="350">{origin}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <header>
          <h3>Dados do Processo Vinculado</h3>
        </header>
        <div className="fluid formgrid grid">

          <div className="col-12">
            <div className="field grid">
              <div className="col-12 md:col-6">

                {/* commmon data shown through tabs with essential lawsuit information */}
                <table width="1000" id="linkedlawsuitprogress_summary">
                  <tbody>
                    <tr>
                      <td width="250"><strong>Processo vinculado nº:</strong></td>
                      <td width="250">{linkedLawsuit?.proc_vinc_reg}</td>
                      <td width="20">&nbsp;</td>
                      <td width="150"><strong>Pasta nº:</strong></td>
                      <td width="230">{linkedLawsuit?.proc_vinc_num_pasta}</td>
                    </tr>
                    <tr className="stripedRows">
                      <td width="250"><strong>Outros números do processo vinculado:</strong></td>
                      <td width="250">{linkedLawsuit?.proc_vinc_reg_outros}</td>
                      <td width="20">&nbsp;</td>
                      <td width="250"><strong>Tipo de processo vinculado:</strong></td>
                      <td width="230">{linkedLawsuitType}</td>
                    </tr>
                  </tbody>
                </table>
                <table width="1000" id="linkedlawsuitprogress_summary2">
                  <tbody>
                    <tr>
                      <td width="250"><strong>Órgão julgador:</strong></td>
                      <td width="250">{judgingBodyLinkedlawsuit}</td>
                      <td width="20">&nbsp;</td>
                      <td width="250">&nbsp;</td>
                      <td width="230">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="250"><strong>Cidade:</strong></td>
                      <td width="250">{linkedLawsuit?.proc_vinc_orgao_cidade}</td>
                      <td width="20">&nbsp;</td>
                      <td width="250"><strong>Estado:</strong></td>
                      <td width="230">{linkedLawsuit?.proc_vinc_orgao_uf}</td>
                    </tr>
                  </tbody>
                </table>
                <table width="1000" id="linkedlawsuitprogress_summary3">
                  <tbody>
                    <tr className="stripedRows">
                      <td width="250"><strong>Pólo ativo:</strong></td>
                      <td width="250">{linkedLawsuit?.proc_vinc_polo_ativo}</td>
                      <td width="20">&nbsp;</td>
                      <td width="250"><strong>Pólo passivo:</strong></td>
                      <td width="230">{linkedLawsuit?.proc_vinc_polo_passivo}</td>
                    </tr>
                  </tbody>
                </table>
                <table width="1000" id="linkedlawsuitprogress_summary4">
                  <tbody>
                    <tr>
                      <td width="250"><strong>Localização na internet:</strong></td>
                      <td width="750">
                        <Link
                          to={{ pathname: `${linkedLawsuit?.proc_vinc_url}` }}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none' }}
                          title="Acessar o site"
                        >
                          {linkedLawsuit?.proc_vinc_url}
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <header>
          <div>
            <h3>Andamento de processos (Processo vinculado)</h3>
          </div>
        </header>

        <div className="datatable-responsive">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-linkedlawsuitsprogresses table"
            id="table-linkedlawsuitsprogresses"
            ref={dt}
            value={linkedLawsuitProgresses}
            editMode="row"
            dataKey="proc_vinc_and_proc_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="single"
            selection={selectedLinkedLawsuits}
            onSelectionChange={(e) => {
              const selLinkedLawsuits = e.value as ILinkedLawsuitProgress[];
              setSelectedLinkedLawsuits(selLinkedLawsuits);
            }}
            onRowDoubleClick={(e) => editLinkedLawsuitProgress(e.data)}
          >
            {dynamicColumnsLinkedLawsuitProgress}
            <Column body={actionBodyTemplate} headerStyle={{ width: '160px' }} />
          </DataTable>
        </div>

        <div>
          <Dialog visible={deleteLinkedLawsuitDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLinkedLawsuitProgressFooter} onHide={hidedeleteLinkedLawsuitDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {linkedLawsuitProgress && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{linkedLawsuitProgress.proc_vinc_and_andamento}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteLinkedLawsuitsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLinkedLawsuitsProgressDialogFooter} onHide={hidedeleteLinkedLawsuitDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {linkedLawsuitProgress
                                && (
                                <span>
                                  Você tem certeza que deseja excluir os cadastros selecionados?
                                </span>
                                )}
            </div>
          </Dialog>
        </div>

        <div className="col-12 p-mt-20">

          <header>
            <h3>Enviar cópias de e-mail</h3>
          </header>

          <div className="field grid p-mt-20">
            <div className="col-12 md:col-6">
              <Checkbox
                inputId="avisar_cliente"
                name="avisar_cliente"
                // onChange={(e) => setNotifyCustomer(e.checked)}
                onChange={(e) => {
                  if (e.checked !== undefined) {
                    setNotifyCustomer(e.checked);
                  }
                }}
                checked={notifyCustomer}
              />
              <label htmlFor="avisar_cliente">
                {' '}
                Avisar cliente
              </label>
            </div>
            <div className="col-12 md:col-6">
              <Checkbox
                inputId="avisar_advogado"
                name="avisar_advogado"
                // onChange={(e) => setNotifyAttorney(e.checked)}
                onChange={(e) => {
                  if (e.checked !== undefined) {
                    setNotifyAttorney(e.checked);
                  }
                }}
                checked={notifyAttorney}
              />
              <label htmlFor="avisar_advogado">
                {' '}
                Avisar advogado
              </label>
            </div>
          </div>

          <div className="col-12 md:col-12">
            <label htmlFor="copia_emails_outros">
              Enviar cópia para e-mails adicionais (separe os emails por
              ponto-e-vírgula &quot; ; &quot;)
            </label>
            <InputText
              id="copia_emails_outros"
              name="copia_emails_outros"
              className="inputfield w-full"
              defaultValue={otherEmails}
              onChange={(e) => setOtherEmails(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="field grid">
            <div className="col-12 md:col-12">
              <div className="flex align-content-center justify-content-center field">
                <Button
                  icon="pi pi-check"
                  iconPos="left"
                  label="Enviar e-mail"
                  className="p-mr-2"
                  type="submit"
                  onClick={handleNotificationEmails}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="col-12 p-mt-20">
          <header />
          <div className="flex align-content-center justify-content-center field">
            <Button
              icon="pi pi-times"
              iconPos="left"
              label="Voltar listagem principal processos vinculados"
              className="p-button-info"
              type="button"
              onClick={handleCancel}
            />
          </div>
        </div>

      </div>

    </Container>

  );
};

export default LinkedLawsuitProgress;
