import React, { useEffect, useRef, useState } from 'react';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';

import { useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from '../styles';
import api from '../../../../services/api';

const Possibilities: React.FC = () => {
  PrimeReact.ripple = true;

  const possibilityOptions = [
    { name: 'Êxito', value: 1 },
    { name: 'Perda', value: 2 },
  ];

  const possibilityPercentageOptions = [
    { name: 'Provável (≥ 80%)', value: 1 },
    { name: 'Possível (> 50%)', value: 2 },
    { name: 'Possível (≤ 50%)', value: 4 },
    { name: 'Remota (≤ 20%)', value: 3 },
  ];

  const [checkedInitial, setCheckedInitial] = useState<boolean>(false);
  const [checkedIntermediate, setCheckedIntermediate] = useState<boolean>(false);
  const [checkedFinal, setCheckedFinal] = useState<boolean>(false);
  const [initialFeeName, setInitialFeeName] = useState(null);
  const [intermediateFeeName, setIntermediateFeeName] = useState(null);
  const [finalFeeName, setFinalFeeName] = useState(null);
  const [possibilityLevel, setPossibilityLevel] = useState(null);
  const [successOrLoss, setSuccessOrLoss] = useState(null);

  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      await api.get(`/lawsuits/financialitems/show/filtered/${id}`).then((response) => {
        if (response.data[0].honorarios_iniciais === '1') {
          setCheckedInitial(true);
          setInitialFeeName(response.data[0].honorarios_iniciais_nome);
        } else {
          setInitialFeeName(null);
        }
        if (response.data[0].honorarios_intermediarios === '1') {
          setCheckedIntermediate(true);
          setIntermediateFeeName(response.data[0].honorarios_intermediarios_nome);
        } else {
          setIntermediateFeeName(null);
        }
        if (response.data[0].honorarios_finais === '1') {
          setCheckedFinal(true);
          setFinalFeeName(response.data[0].honorarios_finais_nome);
        } else {
          setFinalFeeName(null);
        }

        setSuccessOrLoss(response.data[0].proc_pedidos_possib_geral);
        setPossibilityLevel(response.data[0].proc_pedidos_possib_geral_tp2);
      });
    })();
  }, [id]);

  const handleSaveLegalFees = async () => {
    let loggedUser = '';
    let userLevel = '';

    // const managementData = sessionStorage.getItem('@Jurisnet:access');
    const managementData = localStorage.getItem('@Jurisnet:access');

    if (typeof managementData === 'string') {
      const data = JSON.parse(managementData);

      loggedUser = data.usuario_logado;
      userLevel = data.nivelusuario;
    }

    const formData = {
      honorarios_iniciais: checkedInitial,
      honorarios_intermediarios: checkedIntermediate,
      honorarios_finais: checkedFinal,
      loggedUser,
      userLevel,
    };
    await api.patch(`/lawsuits/financialitems/patch/fees/${id}`, formData).then((response) => {
      if (response.data === true) {
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });
        toast.current?.show({
          severity: 'success',
          summary: 'Cadastro atualizado',
          detail: 'As informações do cadastro foram atualizadas com sucesso.',
          life: 3000,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    });

    await api.get(`/lawsuits/financialitems/show/filtered/${id}`).then((response) => {
      if (response.data[0].honorarios_iniciais === '1') {
        setCheckedInitial(true);
        setInitialFeeName(response.data[0].honorarios_iniciais_nome);
      } else {
        setInitialFeeName(null);
      }
      if (response.data[0].honorarios_intermediarios === '1') {
        setCheckedIntermediate(true);
        setIntermediateFeeName(response.data[0].honorarios_intermediarios_nome);
      } else {
        setIntermediateFeeName(null);
      }
      if (response.data[0].honorarios_finais === '1') {
        setCheckedFinal(true);
        setFinalFeeName(response.data[0].honorarios_finais_nome);
      } else {
        setFinalFeeName(null);
      }

      setSuccessOrLoss(response.data[0].proc_pedidos_possib_geral);
      setPossibilityLevel(response.data[0].proc_pedidos_possib_geral_tp2);
    });
  };

  const handlePossibility = async () => {
    const formData = {
      proc_pedidos_possib_geral: successOrLoss,
      proc_pedidos_possib_geral_tp2: possibilityLevel,
    };

    await api.patch(`/lawsuits/financialitems/patch/possibilities/${id}`, formData).then((response) => {
      if (response.data === true) {
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });
        toast.current?.show({
          severity: 'success',
          summary: 'Cadastro atualizado',
          detail: 'As informações do cadastro foram atualizadas com sucesso.',
          life: 3000,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    });
  };
  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <div className="datatable-responsive">
        <div className="col-12">
          <div className="field grid">
            <div className="col-12 md:col-12">
              <div className="field-checkbox">
                <Checkbox
                  inputId="honorarios_iniciais"
                  name="honorarios_iniciais"
                  checked={checkedInitial}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setCheckedInitial(e.checked);
                    }
                  }}
                />
                <label htmlFor="honorarios_iniciais">
                  Honorários iniciais ok / Não se aplica
                  {checkedInitial === true && initialFeeName !== null
                    ? ` - (Usuário responsável pela informação: ${initialFeeName})`
                    : ''}
                </label>
              </div>
            </div>
            <div className="col-12 md:col-12">
              <div className="field-checkbox">
                <Checkbox
                  inputId="honorarios_intermediarios"
                  name="honorarios_intermediarios"
                  checked={checkedIntermediate}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setCheckedIntermediate(e.checked);
                    }
                  }}
                />
                <label htmlFor="honorarios_intermediarios">
                  Honorários intermediários ok / Não se aplica
                  {checkedIntermediate === true && intermediateFeeName !== null
                    ? ` - (Usuário responsável pela informação: ${intermediateFeeName})`
                    : ''}
                </label>
              </div>
            </div>
            <div className="col-12 md:col-12">
              <div className="field-checkbox">
                <Checkbox
                  inputId="honorarios_finais"
                  name="honorarios_finais"
                  checked={checkedFinal}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setCheckedFinal(e.checked);
                    }
                  }}
                />
                <label htmlFor="honorarios_finais">
                  Honorários finais ok / Não se aplica
                  {checkedFinal === true && finalFeeName !== null
                    ? ` - (Usuário responsável pela informação: ${finalFeeName})`
                    : ''}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="flex field">
            <Button
              icon="pi pi-check"
              iconPos="left"
              label="Gravar honorários"
              className="p-mr-2"
              type="button"
              onClick={handleSaveLegalFees}
            />
          </div>
        </div>

        <header />

        <div className="col-12 p-mt-20">
          <div className="field grid">
            <div className="col-12 md:col-12">
              <span>
                <strong>Possibilidade geral do processo:</strong>
                { ' ' }
                {' '}
                (em relação ao cliente)
              </span>
            </div>

            <div className="col-12 md:col-12 possibilitiesButtons p-mt-10">
              <div className="col-12 md:col-3">
                <label htmlFor="proc_pedidos_possib_geral">
                  Possibilidade
                </label>
                <SelectButton
                  id="proc_pedidos_possib_geral"
                  value={successOrLoss}
                  className="p-mb-10"
                  options={possibilityOptions}
                  optionLabel="name"
                  onChange={(e) => setSuccessOrLoss(e.value)}
                />
              </div>
              <div className="col-12 md:col-9">
                <label htmlFor="proc_pedidos_possib_geral_tp2">
                  Possibilidade Geral com percentual ( % )
                </label>
                <SelectButton
                  id="proc_pedidos_possib_geral_tp2"
                  value={possibilityLevel}
                  className="p-mb-10"
                  options={possibilityPercentageOptions}
                  optionLabel="name"
                  onChange={(e) => {
                    setPossibilityLevel(e.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="flex field">
            <Button
              icon="pi pi-check"
              iconPos="left"
              label="Gravar possibilidade"
              className="p-mr-2"
              type="button"
              onClick={handlePossibility}
            />
          </div>
        </div>
      </div>

    </Container>

  );
};

export default Possibilities;
