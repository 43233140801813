import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from '../styles';
import api from '../../../../services/api';

interface IFinancialCostDeposit {
  dep_cus_id: number | null;
  dep_cus_proc_id: number;
  dep_cus_data: Date;
  dep_cus_cod_natureza: number;
  dep_cus_valor: number;
  dep_cus_classificacao: number;
}

interface ICostDepositProperties {
  natureza_id: number | null;
  natureza_descricao: string;
}

const LawsuitFees: React.FC = () => {
  PrimeReact.ripple = true;

  const emptyFinancialCostDeposit = {
    dep_cus_id: null,
    dep_cus_proc_id: 0,
    dep_cus_data: new Date(),
    dep_cus_cod_natureza: 0,
    dep_cus_valor: 0,
    dep_cus_classificacao: 0,
  };

  const [deleteLawsuitFees, setDeleteLawsuitFeesDialog] = useState(false);

  const [financialCostDeposits, setFinancialCostDeposits] = useState<IFinancialCostDeposit[]>([]);
  const [financialCostDeposit, setFinancialCostDeposit] = useState(emptyFinancialCostDeposit);

  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const typeofCost = 1;

  const [costDeposits, setCostDeposits] = useState<ICostDepositProperties[]>([]);

  useEffect(() => {
    (async () => {
      await api.get(`/lawsuits/financialcostdeposits/show/filtered/${id}/${typeofCost}`).then((response) => {
        setFinancialCostDeposits(response.data);
      });
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const response = await api.get('/lawsuits/costsdeposits/show/all');
      setCostDeposits(response.data);
    })();
  }, []);

  const formatCurrency = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  const dep_cus_valor_Total = () => {
    const total = financialCostDeposits
      .map((value) => Number(value.dep_cus_valor))
      .reduce((a, b) => a + b, 0);
    return formatCurrency(total);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totais" footerStyle={{ textAlign: 'center' }} />
        <Column />
        <Column footer={dep_cus_valor_Total} footerStyle={{ textAlign: 'right' }} />
        <Column />
      </Row>
    </ColumnGroup>
  );

  const dateBodyTemplate = (rowData: {
    dep_cus_data: Date;
  }) => {
    if (financialCostDeposits !== undefined) {
      const filteredDate = financialCostDeposits.filter((item) => (
        item.dep_cus_data === rowData.dep_cus_data
      ), []);

      if (filteredDate[0]?.dep_cus_data !== null
        && filteredDate[0]?.dep_cus_data.toString() !== '01/01/1970'
        && filteredDate[0]?.dep_cus_data.toString() !== '31/12/1969') {
        const dateInput = filteredDate[0].dep_cus_data?.toString();

        const dateBR = new Date(dateInput);
        const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

        return formattedDate;
      }
      return '';
    }
    return '';
  };

  const valueBodyTemplate = (rowData: {
    dep_cus_cod_natureza: number;
  }) => {
    if (financialCostDeposits !== undefined) {
      const filteredValue = financialCostDeposits.filter((item) => (
        item.dep_cus_cod_natureza === rowData.dep_cus_cod_natureza
      ), []);

      if (filteredValue[0].dep_cus_cod_natureza !== 0) {
        if (costDeposits !== undefined) {
          const natureDescription = costDeposits.filter((item) => (
            item.natureza_id === filteredValue[0].dep_cus_cod_natureza
          ), []);

          return natureDescription[0]?.natureza_descricao;
        }
      }
      return '';
    }
    return '';
  };

  const value1BodyTemplate = (rowData: {
    dep_cus_valor: number;
  }) => {
    if (financialCostDeposits !== undefined) {
      const filteredValue = financialCostDeposits.filter((item) => (
        item.dep_cus_valor === rowData.dep_cus_valor
      ), []);

      if (filteredValue[0].dep_cus_valor !== 0) {
        const dep_cus_valor = Number(filteredValue[0].dep_cus_valor)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return dep_cus_valor;
      }
      return '0,00';
    }
    return '0,00';
  };

  const handleCreate = () => {
    history.push(`/lawsuit/financialitem/lawsuitfee/create?cod=${id}&active=2`);
  };

  const hidedeleteLawsuitFees = () => {
    setDeleteLawsuitFeesDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLawsuitFees = (rowData:any) => {
    history.push(`/lawsuit/financialitem/lawsuitfee/edit/${rowData.dep_cus_id}?cod=${id}&active=2`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const confirmDeleteLawsuitFees = (costDeposit: React.SetStateAction<{
    dep_cus_id: null; dep_cus_proc_id: number; dep_cus_data: Date;
    dep_cus_cod_natureza: number; dep_cus_valor: number;
    dep_cus_classificacao: number; }>) => {
    setFinancialCostDeposit(costDeposit);
    setDeleteLawsuitFeesDialog(true);
  };

  const deleteLawsuitFeesData = () => {
    api.delete(`/lawsuits/financialcostdeposits/delete/${financialCostDeposit.dep_cus_id}`);

    setDeleteLawsuitFeesDialog(false);

    const updatedRecords = financialCostDeposits
      .filter((val) => val.dep_cus_id !== financialCostDeposit.dep_cus_id);
    setFinancialCostDeposits(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const leftToolbarTemplate = () => (
    <>
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editLawsuitFees(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteLawsuitFees(rowData); }}
      />
    </>
  );

  const deleteLawsuitFeesFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLawsuitFees} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteLawsuitFeesData} />
    </>
  );

  return (
    <Container>
      <div className="datatable-responsive" id="main-card">
        <div className="col-12 p-mt-20">
          <header>
            <h4>Custas Processuais pagas pelo Cliente</h4>
          </header>

          <div className="col-12 p-mt-20">

            <Toolbar className="p-mb-4" left={leftToolbarTemplate} />

            <DataTable
              tableClassName="table-financialcostdeposits table"
              id="table-financialcostdeposits"
              ref={dt}
              value={financialCostDeposits}
              editMode="row"
              dataKey="dep_cus_proc_id"
              footerColumnGroup={footerGroup}
              stripedRows
              showGridlines
              className="p-datatable-sm"
              emptyMessage="Nenhum registro encontrado."
              onRowDoubleClick={(e) => editLawsuitFees(e.data)}
            >
              <Column field="dep_cus_data" header="Data" alignHeader="center" body={dateBodyTemplate} align="center" style={{ width: '120px' }} />
              <Column field="dep_cus_cod_natureza" header="Natureza" alignHeader="center" body={valueBodyTemplate} align="left" />
              <Column field="dep_cus_valor" header="Valor (R$)" alignHeader="center" body={value1BodyTemplate} align="right" style={{ width: '250px' }} />
              <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
            </DataTable>

          </div>

          <div>
            <Dialog visible={deleteLawsuitFees} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLawsuitFeesFooter} onHide={hidedeleteLawsuitFees}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {financialCostDeposits && (
                <span>
                  Você tem certeza que deseja excluir natureza de valor
                  <br />
                  <br />
                  <b>
                    R$
                    {' '}
                    {financialCostDeposit.dep_cus_valor}
                  </b>
                  ?
                </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>

      </div>

    </Container>

  );
};

export default LawsuitFees;
