/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { Container } from './styles';

interface SearchData {
  proc_cod_cliente: number;
  proc_cod_adv_resp: number;
  description: string;
  proc_data_ajuiz_inicio: Date | undefined;
  proc_data_ajuiz_final: Date | undefined;
  proc_reg: string;
  proc_num_pasta: string;
  proc_autor: string;
  proc_reu: string;
}

const SearchManagement:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm:ss');
  // const endOfMonth = moment().endOf('month').format('YYYY-MM-DD hh:mm:ss');

  const [attorneysOptions, setAttorneysOptions] = useState([{}]);
  const [attorneyInChargeId, setAttorneyInChargeId] = useState(0);
  const [lawsuitRegistrationNumber, setLawsuitRegistrationNumber] = useState('');
  const [lawsuitFolderNumber, setLawsuitFolderNumber] = useState('');
  const [author, setAuthor] = useState('');
  const [defendant, setDefendant] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [description, setDescription] = useState('');

  const [
    initialRegistrationDate, setInitialRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    finalRegistrationDate, setFinalRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);

  const [customersOptions, setCustomersOptions] = useState([{}]);
  const [dateFilterType, setDateFilterType] = useState('data');

  const [showDate, setShowDate] = useState(true);
  const [showPeriod, setShowPeriod] = useState(false);

  const dateFilterTypeOptions = [
    { name: 'Data', value: 'data', style: 'font-size: small;' },
    { name: 'Período', value: 'periodo', style: 'font-size: small;' },
  ];

  const validationSchema = null;

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);

      setCustomersOptions(customersOptions);
      // setCustomersOptions((oldOptions) => [...oldOptions, customersOptions]);
    })();
  }, [customersOptions]);

  useEffect(() => {
    (async () => {
      attorneysOptions.push({
        name: 'Todos',
        value: 0,
      });
      await api.get('management/attorneys/show/all').then((response) => {
        response.data.map((attorney:
        {
          advogado_id: number,
          advogado_nome:string
        }) => (
          attorneysOptions.push({
            name: attorney.advogado_nome,
            value: attorney.advogado_id,
          })
        ));
      });
      attorneysOptions.splice(0, 1);
      setAttorneysOptions(attorneysOptions);
    })();
  }, [attorneysOptions]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      // const access = JSON.parse(sessionStorage.getItem('@Jurisnet:access') || '{}');
      const access = JSON.parse(localStorage.getItem('@Jurisnet:access') || '{}');

      let menu = '';
      let categoria = 0;
      if (access) {
        menu = access.menu;
        categoria = Number(access.categoria);
      }
      const searchFormData = {
        proc_cod_cliente: customerId,
        proc_cod_adv_resp: attorneyInChargeId,
        description,
        dateFilterType,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
        proc_reg: lawsuitRegistrationNumber,
        proc_num_pasta: lawsuitFolderNumber,
        proc_autor: author,
        proc_reu: defendant,
        menu,
        categoria,
      };

      history.push({
        pathname: '/management/list',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };

  const initialValues: SearchData = {
    proc_cod_cliente: 0,
    proc_cod_adv_resp: 0,
    description: '',
    proc_data_ajuiz_inicio: undefined,
    proc_data_ajuiz_final: undefined,
    proc_reg: '',
    proc_num_pasta: '',
    proc_autor: '',
    proc_reu: '',
  };

  return (
    <Container>
      <div className="card">
        <header>
          <h3>Consulta Gerencial</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,

          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-6 colum-spacer-right">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cliente">
                        Cliente
                      </label>
                      <Dropdown
                        id="proc_cliente"
                        name="proc_cliente"
                        options={customersOptions}
                        onChange={(e) => setCustomerId(e.target.value)}
                        value={customerId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        autoFocus
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cod_adv_resp">
                        Responsável
                      </label>
                      <Dropdown
                        id="proc_cod_adv_resp"
                        name="proc_cod_adv_resp"
                        options={attorneysOptions}
                        onChange={(e) => setAttorneyInChargeId(e.target.value)}
                        value={attorneyInChargeId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>

                    <div className="col-12 md:col-12">
                      <label htmlFor="description">
                        Descrição
                      </label>
                      <InputTextarea
                        id="description"
                        name="description"
                        className="inputfield w-full"
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={7}
                        cols={30}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-6 colum-spacer-left">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ajuiz_inicio">
                        Data de registro
                        { ' ' }
                        <Tooltip target=".infoText">
                          <strong>Selecione data específica ou período</strong>
                          <br />
                          <strong>Seleção data</strong>
                          <br />
                          <em>
                            Use para intervalos com seleção específica de datas
                          </em>
                          <br />
                          <br />
                          <strong>Seleção período</strong>
                          <br />
                          <em>
                            Para período inicial e final do mesmo mês,
                            <br />
                            selecione apenas o período inicial para que o intervalo
                            <br />
                            seja o primeiro
                            e último dia do mês selecionado
                          </em>
                        </Tooltip>
                        <i
                          className="infoText pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-12" style={{ marginBottom: '15px' }}>
                      <SelectButton
                        id="date_filter_type"
                        value={dateFilterType}
                        options={dateFilterTypeOptions}
                        optionLabel="name"
                        onChange={(e) => {
                          setDateFilterType(e.value);
                          if (e.value === 'data') {
                            setShowDate(true);
                            setShowPeriod(false);
                          } else {
                            setShowDate(false);
                            setShowPeriod(true);
                          }
                        }}
                        style={{ padding: '0.5rem 0.79rem 0 0' }}
                      />
                    </div>
                    {showPeriod ? (
                      <>
                        <div id="periodo_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio_mes_ano"
                            name="proc_data_ajuiz_inicio_mes_ano"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/9999"
                            dateFormat="mm/yy"
                            yearRange="1980:2030"
                            placeholder="Período inicial"
                            view="month"
                            yearNavigator
                          />
                        </div>
                        <div id="periodo_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final_mes_ano">
                            <Calendar
                              inputId="proc_data_ajuiz_final_mes_ano"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/9999"
                              dateFormat="mm/yy"
                              yearRange="1980:2030"
                              placeholder="Período final"
                              view="month"
                              yearNavigator
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    {showDate ? (
                      <>
                        <div id="data_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio"
                            name="proc_data_ajuiz_inicio"
                            className="inputfield w-full"
                            value={initialRegistrationDate}
                            onBlur={(e) => setInitialRegistrationDate(new Date(e.target.value))}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data inicial"
                          />
                        </div>
                        <div id="data_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final">
                            <Calendar
                              inputId="proc_data_ajuiz_final"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={finalRegistrationDate}
                              onBlur={(e) => setFinalRegistrationDate(new Date(e.target.value))}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/99/9999"
                              dateFormat="dd/mm/yy"
                              yearRange="1980:2030"
                              placeholder="Data final"
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_reg">
                        Nº do processo
                      </label>
                      <InputText
                        id="proc_reg"
                        name="proc_reg"
                        className="inputfield w-full"
                        onChange={(e) => {
                          setLawsuitRegistrationNumber(e.target.value);
                          handleChange(e);
                        }}
                        value={lawsuitRegistrationNumber}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_num_pasta">
                        Pasta nº
                      </label>
                      <InputText
                        id="proc_num_pasta"
                        name="proc_num_pasta"
                        className="inputfield w-full"
                        onChange={(e) => setLawsuitFolderNumber(e.target.value)}
                        onBlur={handleBlur}
                        value={lawsuitFolderNumber}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_autor">
                        Autor
                      </label>
                      <InputText
                        id="proc_autor"
                        name="proc_autor"
                        className="inputfield w-full"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_reg_outros">
                        Réu
                      </label>
                      <InputText
                        id="proc_reu"
                        name="proc_reu"
                        className="inputfield w-full"
                        value={defendant}
                        onChange={(e) => setDefendant(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchManagement;
