/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { ListBox } from 'primereact/listbox';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';

// import ReactDOM from 'react-dom';
import api from '../../../../../services/api';

import { useToast } from '../../../../../hooks/toast';

import {
  Container,
} from './styles';

interface SearchData {
  sector: string | string[];
  inCharge: string;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
}

interface IInChargeType {
  label?: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any;
}

interface IOptions {
  label: string;
  value: string;
  disabled: boolean;
  workingsector: string;
  listStyle?: string;
}

const SearchHourAttorneySimplified:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm:ss');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD hh:mm:ss');

  const [
    initialRegistrationDate, setInitialRegistrationDate,
  ] = useState<string | Date | Date[] | null>(new Date(startOfMonth));
  const [
    finalRegistrationDate, setFinalRegistrationDate,
  ] = useState<string | Date | Date[] | null>(new Date(endOfMonth));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attorneysOptions, setAttorneysOptions] = useState<IOptions[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [sectorOptions, setSectorOptions] = useState([{}]);
  const [inChargeOptions, setInChargeOptions] = useState<IInChargeType[]>([]);
  const [sectorValue, setSectorValue] = useState(['all']);
  const [inCharge, setInCharge] = useState(['global|all']);
  const [dateFilterType, setDateFilterType] = useState('data');

  const [showDate, setShowDate] = useState(true);
  const [showPeriod, setShowPeriod] = useState(false);

  const dateFilterTypeOptions = [
    { name: 'Data', value: 'data', style: 'font-size: small;' },
    { name: 'Período', value: 'periodo', style: 'font-size: small;' },
  ];

  const initialValues: SearchData = {
    sector: 'all',
    inCharge: 'global|0',
    proc_data_ajuiz_inicio: new Date(startOfMonth),
    proc_data_ajuiz_final: new Date(endOfMonth),
  };

  const validationSchema = null;

  useEffect(() => {
    (async () => {
      const sectorArray = [{ name: '', value: '' }];

      await api.get('/management/attorneys/show/inchargelawsuits/attorneys').then((response) => {
        if (response.data.length > 0) {
          sectorArray.push({
            name: 'Todos',
            value: 'all',
          });
          response.data.map((sector: {
            setor_adv: string
          }) => (
            sectorArray.push({
              name: sector.setor_adv,
              value: sector.setor_adv,
            })
          ));
        }
      });

      await api.get('/management/attorneys/show/inchargelawsuits/others').then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((sector: {
            setor_adm: string
          }) => {
            // if (sectorOptions.indexOf(sector.setor_adm) === -1) {
            sectorArray.push({
              name: sector.setor_adm,
              value: sector.setor_adm,
            });
            // }
          });
        }
      });

      sectorArray.splice(0, 1);
      setSectorOptions(sectorArray);
      // }
    })();
  }, []);

  const filterAttorneysInCharge = async (sector: string | string[]) => {
    let isDisabled = true;

    inChargeOptions.push(
      {
        label: 'Todos',
        items: [
          { label: 'Todos', value: 'global|all' },
        ],
      },
    );

    await api.get(`management/attorneys/show/all/active/lawsuit/${sessionStorage.getItem('setor')}/${sessionStorage.getItem('categoria')}`).then((response) => {
      const typeInCharge = 'adv|';

      attorneysOptions.splice(0, attorneysOptions.length);
      response.data.forEach((attorney:
      {
        advogado_id: number,
        advogado_nome:string,
        advogado_setor: string;
      }) => {
        if (sector.includes(attorney.advogado_setor)) {
          isDisabled = false;
        } else {
          isDisabled = true;
        }
        if (sector === 'all') {
          isDisabled = false;
        }
        attorneysOptions.push({
          label: `${attorney.advogado_nome} (Advogado)`,
          value: typeInCharge.concat(attorney.advogado_id.toString()),
          disabled: isDisabled,
          workingsector: attorney.advogado_setor,
          listStyle: 'padding-left: 10px;',
        });
      });
      attorneysOptions.splice(0, 1);

      inChargeOptions.push({
        label: 'Advogados',
        items: attorneysOptions,
      });
      // inChargeOptions.splice(0, 1);
    });

    // await api.get('management/assistants/show/all/active').then((response) => {
    //   const typeInCharge = 'adm|';
    //   response.data.forEach((assistant:
    //   {
    //     administrador_id: number,
    //     administrador_nome: string,
    //     administrador_setor: string,
    //   }) => {
    //     if (sector.includes(assistant.administrador_setor)) {
    //       isDisabled = false;
    //     } else {
    //       isDisabled = true;
    //     }
    //     if (sector === 'all') {
    //       isDisabled = false;
    //     }
    //     assistantsOptions.push({
    //       label: assistant.administrador_nome,
    //       value: typeInCharge.concat(assistant.administrador_id.toString()),
    //       disabled: isDisabled,
    //       workingsector: assistant.administrador_setor,
    //       style: 'visibility: hidden',
    //     });
    //   });
    //   assistantsOptions.splice(0, 1);
    //   inChargeOptions.push({
    //     label: 'Assistentes/Estagiários',
    //     items: assistantsOptions,
    //   });
    // });

    setInChargeOptions(inChargeOptions);
  };

  useEffect(() => {
    if (inChargeOptions.length < 1) {
      filterAttorneysInCharge('all');
    }
    // ReactDOM.unstable_batchedUpdates(() => {
    //   setSectorOptions(sectorOptions);
    //   setInChargeOptions(inChargeOptions);
    // });
  });

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      // const access = JSON.parse(sessionStorage.getItem('@Jurisnet:access') || '{}');
      const access = JSON.parse(localStorage.getItem('@Jurisnet:access') || '{}');

      let menu = '';
      if (access) {
        menu = access.menu;
      }
      const searchFormData = {
        sector: sectorValue,
        inCharge,
        dateFilterType,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
        menu,
      };

      history.push({
        pathname: '/report/hour/attorney/simplified',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };

  return (
    <Container>
      <div className="card">
        <header>
          <h3>Relatório de Horas por Profissional (Simplificado) - Modelo VIII</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-4 colum-spacer-right">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="sector">
                        Setor
                      </label>
                      <ListBox
                        id="sector"
                        name="sector"
                        options={sectorOptions}
                        onChange={(e) => {
                          setSectorValue(e.target.value);
                          filterAttorneysInCharge(e.target.value);
                        }}
                        value={sectorValue}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        multiple
                        filter
                        filterBy="name"
                        listStyle={{ maxHeight: '330px' }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 column-divider">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="inChargeListbox">
                        Responsável
                      </label>
                      <ListBox
                        id="inChargeListbox"
                        name="inChargeListbox"
                        options={inChargeOptions}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(e: {value: any}) => setInCharge(e.value)}
                        metaKeySelection={false}
                        value={inCharge}
                        className="inputfield w-full"
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        multiple
                        filter
                        filterBy="label"
                        listStyle={{ maxHeight: '330px' }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 colum-spacer-left">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ajuiz_inicio">
                        Data de registro
                        { ' ' }
                        <Tooltip target=".infoText">
                          <strong>Selecione data específica ou período</strong>
                          <br />
                          <strong>Seleção data</strong>
                          <br />
                          <em>
                            Use para intervalos com seleção específica de datas
                          </em>
                          <br />
                          <br />
                          <strong>Seleção período</strong>

                          <br />
                          <em>
                            Para período inicial e final do mesmo mês,
                            <br />
                            selecione apenas o período inicial para que o intervalo
                            <br />
                            seja o primeiro
                            e último dia do mês selecionado
                          </em>
                        </Tooltip>
                        <i
                          className="infoText pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-12" style={{ marginBottom: '15px' }}>
                      <SelectButton
                        id="date_filter_type"
                        value={dateFilterType}
                        options={dateFilterTypeOptions}
                        optionLabel="name"
                        onChange={(e) => {
                          setDateFilterType(e.value);
                          if (e.value === 'data') {
                            setShowDate(true);
                            setShowPeriod(false);
                            setInitialRegistrationDate(new Date(startOfMonth));
                            setFinalRegistrationDate(new Date(endOfMonth));
                          } else {
                            setShowDate(false);
                            setShowPeriod(true);
                            setInitialRegistrationDate(null);
                            setFinalRegistrationDate(null);
                          }
                        }}
                        style={{ padding: '0.5rem 0.79rem 0 0' }}
                      />
                    </div>
                    {showPeriod ? (
                      <>
                        <div id="periodo_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio_mes_ano"
                            name="proc_data_ajuiz_inicio_mes_ano"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/9999"
                            dateFormat="mm/yy"
                            yearRange="1980:2030"
                            placeholder="Período inicial"
                            view="month"
                            yearNavigator
                          />
                        </div>
                        <div id="periodo_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final_mes_ano">
                            <Calendar
                              inputId="proc_data_ajuiz_final_mes_ano"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/9999"
                              dateFormat="mm/yy"
                              yearRange="1980:2030"
                              placeholder="Período final"
                              view="month"
                              yearNavigator
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    {showDate ? (
                      <>
                        <div id="data_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio"
                            name="proc_data_ajuiz_inicio"
                            className="inputfield w-full"
                            value={initialRegistrationDate}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data inicial"
                          />
                        </div>
                        <div id="data_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final">
                            <Calendar
                              inputId="proc_data_ajuiz_final"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={finalRegistrationDate}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/99/9999"
                              dateFormat="dd/mm/yy"
                              yearRange="1980:2030"
                              placeholder="Data final"
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchHourAttorneySimplified;
