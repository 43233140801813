import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { format } from 'date-fns';
import moment from 'moment';
import { round } from 'lodash';

import robotoRegular from '../../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../services/api';

import CompanyLogo from '../../../../assets/logo.png';

interface IBilledHours {
  row_number: number;
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_descricao: string;
  horas_data: Date;
  horas_hora_inicial: Date;
  horas_hora_final: Date;
  horas_documento: String;
  horas_data_alteracao: Date;
  horas_hora_total: String;
  horas_tipo: String;
  advogado_nome: string;
  cliente_nome: string;
  administrador_nome: string;
  valor_ajuste_nao_faturado: number;
  horas_fatu_ajuste: number;
  horas_fatu_de: Date;
  horas_fatu_ate: Date;
}

const SelectedHoursReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let initialSearchDate = '';
  let finalSearchDate = '';
  // let clientName = '';
  if (state) {
    initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format('DD/MM/YYYY');
    finalSearchDate = moment(state?.proc_data_ajuiz_final).format('DD/MM/YYYY');
    if (state.dateFilterType === 'periodo' && state?.proc_data_ajuiz_final === undefined) {
      finalSearchDate = moment(state?.proc_data_ajuiz_inicio).clone().endOf('month').format('DD/MM/YYYY');
    }
  }

  const invoiceValueItem = localStorage.getItem('@Jurisnet:valorajuste');
  const invoiceStartItem = localStorage.getItem('@Jurisnet:invoicestart');
  const invoiceEndItem = localStorage.getItem('@Jurisnet:invoiceend');

  let invoiceValue = '';
  if (invoiceValueItem) {
    invoiceValue = JSON.parse(invoiceValueItem);
  }

  let invoiceStart = '';
  if (invoiceStartItem) {
    invoiceStart = JSON.parse(invoiceStartItem);
  }

  let invoiceEnd = '';
  if (invoiceEndItem) {
    invoiceEnd = JSON.parse(invoiceEndItem);
  }

  const [billedHours, setBilledHours] = useState<IBilledHours[]>([]);
  const [selectedHours] = useState<IBilledHours[]>([]);

  const [totalTime, setTotalTime] = useState('');
  const [totalDecimalTime, setTotalDecimalTime] = useState('');
  const [valuePerHour, setValuePerHour] = useState('0.00');
  const [totalValuePeriod, setTotalValuePeriod] = useState('0.00');
  const [totalAddedValuePeriod, setTotalAddedValuePeriod] = useState('0.00');
  const [documentNumber, setDocumentNumber] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [billedPeriod, setBilledPeriod] = useState('');

  const [loading, setLoading] = useState(true);
  const dt = useRef(null);
  const globalCategoryValue = useRef(0);
  const totalAdjustedDecimalTime = useRef(0.00);
  const adjustment = useRef('');
  const enteredAdjustmentValue = useRef(0.00);

  // TODO adjust later from management screen
  localStorage.setItem('@Jurisnet:a', '1');

  const convertDecimalToHour = useCallback((totalHour: string) => {
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!totalHour) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = totalHour.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = horas_total_tmp[0].toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);
        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    return total_de_horas;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const selected = localStorage.getItem('@Jurisnet:selectedhour');
        let searchHours: IBilledHours[] = [];
        if (selected) {
          searchHours = JSON.parse(selected);
        }

        setBilledHours(searchHours);

        const durations: string[] = [];
        let valor_categoria = 0.00;
        let total_do_periodo = 0.00;
        let nonBilledValue = 0.00;
        let horas_decimal_total = 0.0;
        let total_ajuste_decimal = 0.00;

        let ind = 0;
        let maximum = 0;
        let maximumString = '';
        let customerId = 0;
        let customerIdString = '';
        const currentYear = new Date().getFullYear().toString();
        let documentHourString = '';
        const invoice = localStorage.getItem('@Jurisnet:invoice');

        await api.get(`/management/workinghours/documentnumber/${searchHours[0].horas_cod_cliente}`).then((responseCustomer) => {
          maximum = Number(responseCustomer.data[0].max) + 1;
        });

        searchHours.forEach(async (data: IBilledHours) => {
          const referenceId = data.horas_cod_referencia ? data.horas_cod_referencia : 0;
          nonBilledValue = data.valor_ajuste_nao_faturado
            ? data.valor_ajuste_nao_faturado
            : 0;

          total_ajuste_decimal += Number(nonBilledValue);

          let horas_total = '';
          let total_de_horas = '';
          let horas_decimal = '0';
          let total_h1 = 0;
          let total_m1 = 0;

          if (!data.horas_hora_total) {
            horas_total = '0:00';
          } else {
            const horas_total_tmp = data.horas_hora_total.split(':');

            if (Number(horas_total_tmp[0]) >= 10) {
              horas_total = `${horas_total_tmp[0]}:`;
            } else {
              horas_total = `0${horas_total_tmp[0]}:`;
            }

            if (Number(horas_total_tmp[1]) > 0) {
              if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
              } else {
                horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              horas_total += '00';
            }

            // const total_de_tmp = data.horas_data_alteracao.split(':');
            total_h1 += Number(horas_total_tmp[0]);

            if (Number(horas_total_tmp[1]) > 0) {
              total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
            }

            if (Number(horas_total_tmp[0]) > 0) {
              horas_decimal = (Number(horas_total_tmp[0])).toString();
            } else {
              horas_decimal = '0';
            }

            const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

            if (minutos > 0) {
              let temp = ((minutos * 100) / 60).toFixed(0);
              if (Number(temp) < 10) {
                temp = `0${temp}`;
              }

              horas_decimal += `.${temp}`;
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              horas_decimal += '.00';
            }
          }

          if (total_m1 >= 60) {
            total_h1 += 1;
            total_m1 -= 60;
          }

          if (total_h1 >= 10) {
            total_de_horas = `${total_h1}`;
          } else {
            total_de_horas = `0${total_h1}`;
          }

          if (total_m1 >= 10) {
            total_de_horas += `:${total_m1}`;
          } else {
            total_de_horas += `:0${total_m1}`;

            if (Number(horas_decimal) === 0) {
              horas_decimal = '0.00';
            }
          }

          durations.push(total_de_horas);

          // general info and index adjustments
          // busca o valor individual cobrado pela HORA
          await api.post(`/reports/hour/general/referencevalues/false/${referenceId}`, state).then((responseRef) => {
            valor_categoria = Number(responseRef.data[0].horas_valor_hora);
            globalCategoryValue.current = Number(responseRef.data[0]?.horas_valor_hora);
          });

          total_do_periodo += ((Number(nonBilledValue)
                                + Number(horas_decimal))
                                * valor_categoria);

          horas_decimal_total += Number(horas_decimal);

          if (ind === searchHours.length - 1) {
            const newPeriodValue = Number(round(total_do_periodo, 2))
                + (Number(adjustment.current.replace(',', '.'))
                * Number(globalCategoryValue.current));
            const formattedPeriod = newPeriodValue?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const formattedCategoryValue = valor_categoria?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const formattedtotalDecimalTime = horas_decimal_total?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const formattedNonBilledValue = total_ajuste_decimal?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            const newAdjustedTime = Number(formattedtotalDecimalTime.replace(',', '.'))
              + Number(formattedNonBilledValue.replace(',', '.'));
            totalAdjustedDecimalTime.current = newAdjustedTime;

            setValuePerHour(formattedCategoryValue);
            setTotalValuePeriod(formattedPeriod);

            adjustment.current = formattedNonBilledValue;
            if (formattedNonBilledValue === '0,00') {
              adjustment.current = '';
            }

            setTotalDecimalTime(formattedtotalDecimalTime);

            if (searchHours[0].horas_fatu_ajuste || invoiceValue.length > 0) {
              const adjustmentValue = searchHours[0]?.horas_fatu_ajuste
                ? searchHours[0]?.horas_fatu_ajuste
                : invoiceValue;

              enteredAdjustmentValue.current = Number(adjustmentValue);
            }

            const newTotalValue = Number(round(newPeriodValue, 2))
                + Number(enteredAdjustmentValue.current.toString().replace(',', '.'));

            const formattedAddedTotalValue = newTotalValue?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            setTotalAddedValuePeriod(formattedAddedTotalValue);

            const iniDate = searchHours[0]?.horas_fatu_de !== null
              ? searchHours[0]?.horas_fatu_de
              : initialSearchDate;
            const endDate = searchHours[0]?.horas_fatu_ate !== null
              ? searchHours[0]?.horas_fatu_ate
              : finalSearchDate;
            const reportPeriod = `${iniDate} a ${endDate}`;
            setBilledPeriod(reportPeriod);

            // localStorage.removeItem('@Jurisnet:selectedhour');
          }
          ind += 1;

          if (invoice && invoice === 'true') {
            customerId = data.horas_cod_cliente;
            customerIdString = customerId.toString().padStart(5, '0');
            maximumString = maximum.toString().padStart(5, '0');
            documentHourString = `${customerIdString}.${maximumString}/${currentYear}`;

            setDocumentNumber(documentHourString);

            const formData = {
              horas_documento_seq: maximum,
              horas_documento: documentHourString,
              horas_fatu_de: initialSearchDate,
              horas_fatu_ate: finalSearchDate,
              horas_valor_hora: valor_categoria,
              horas_fatu_ajuste: searchHours[0].horas_fatu_ajuste,
              doc: '',
            };

            await api.put(`/management/workinghours/document/invoice/${data.horas_id}`, formData);
            localStorage.removeItem('@Jurisnet:selectedhour');
          }
        });

        // summary info displayed after datatable
        const ms = durations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
        const sum = ms.reduce((prev, cur) => prev + cur, 0);

        const d = moment.duration(sum, 'milliseconds');
        const hours = Math.floor(d.asHours());
        const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
        const time = `${hours}:${minutes}`;

        setTotalTime(time);

        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [
    addToast, finalSearchDate, initialSearchDate, invoiceEnd, invoiceStart, invoiceValue, state,
  ]);

  const exportPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', [297, 210]);

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular');

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: 'Relatório de Horas',
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    const totalPagesExp = '{total_pages_count_string}';

    autoTable(doc, {
      margin: [35, 0, 15, 15],
      html: '#table-billedhours table',
      includeHiddenHtml: false,
      theme: 'grid',
      tableWidth: 180,
      columnStyles: {
        0: { cellWidth: 11 },
        1: { cellWidth: 22 },
        2: { cellWidth: 22 },
        4: { cellWidth: 20, halign: 'center' },
        5: { cellWidth: 17, halign: 'center' },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      footStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      showFoot: 'lastPage',
      didDrawPage(data) {
        // Header
        doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

        const { pageSize } = doc.internal;
        const fontSize = doc.getFontSize();
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        const selected = localStorage.getItem('@Jurisnet:selectedhour');
        let searchHours: IBilledHours[] = [];
        if (selected) {
          searchHours = JSON.parse(selected);
        }

        const header1 = 'Relatório de Horas';
        const header2 = '';
        const header3 = documentNumber ? `Relatório Número: ${documentNumber}` : '';
        const header4 = `Cliente: ${billedHours[0]?.cliente_nome}`;

        const iniDate = searchHours[0]?.horas_fatu_de !== null
        && searchHours[0]?.horas_fatu_ate !== undefined
          ? searchHours[0]?.horas_fatu_de
          : initialSearchDate;
        const endDate = searchHours[0]?.horas_fatu_ate !== null
          && searchHours[0]?.horas_fatu_ate !== undefined
          ? searchHours[0]?.horas_fatu_ate
          : finalSearchDate;
        const reportPeriod = `${iniDate} a ${endDate}`;
        const header5 = `Período: ${reportPeriod}`;

        const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        const x1 = ((pageWidth - hWidth1) / 2) + 15;
        const x2 = ((pageWidth - hWidth2) / 2) + 15;

        doc.setFontSize(14);
        doc.setTextColor(20);
        doc.text(header1, x1, 14);

        doc.setTextColor(40);
        doc.text(header2, x2, 19);

        doc.setFontSize(9);
        doc.setTextColor(20);
        doc.text(header3, data.settings.margin.left, 23);
        doc.text(header4, data.settings.margin.left, 27);
        doc.text(header5, data.settings.margin.left, 31);
        doc.setFontSize(12);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left, pageHeight - 15,
          data.settings.margin.right, pageHeight - 15,
        );
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const posY = (doc as any).lastAutoTable.finalY + 10;
    autoTable(doc, {
      startY: posY,
      html: '#table_summary',
      theme: 'grid',
      margin: { left: 120 },
      tableWidth: 'auto',
      columnStyles: {
        1: { halign: 'right' },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
    });

    // Footer
    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const printedOn = new Date();
    const dateFormatted = format(
      printedOn,
      "dd/MM/yyyy 'às' HH:mm:ss'.'",
    );

    let str = `Página ${doc.getNumberOfPages()}`;
    if (typeof doc.putTotalPages === 'function') {
      str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    }

    doc.setFontSize(8);

    const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    const x3 = (pageWidth - pageCount) / 2;
    doc.text(str, x3 + 50, pageHeight - 8);

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`Relatorio-de-horas-${dateFormattedFile}.pdf`);
  };

  const handleGoBackToListing = () => {
    const invoice = localStorage.getItem('@Jurisnet:invoice');
    if (invoice && invoice === 'true') {
      localStorage.removeItem('@Jurisnet:selectedhour');
    }
    history.goBack();
  };

  const rightToolbarTemplate = () => (
    <>
      <Button
        icon="pi pi-arrow-circle-left"
        iconPos="left"
        label="Retornar à listagem de horas"
        className="p-button p-component p-button-outlined p-button-info"
        type="button"
        onClick={handleGoBackToListing}
      />
    </>
  );

  const header = (
    <>
      <div className="table-header">
        Relatório Nº:
        {' '}
        {billedHours[0]?.horas_documento ? billedHours[0]?.horas_documento : documentNumber}
        <br />
        Cliente:
        {' '}
        {billedHours[0]?.cliente_nome}
        <br />
        Período:
        {' '}
        {initialSearchDate}
        {' '}
        a
        {' '}
        {finalSearchDate}
      </div>
    </>
  );

  const hourBodyTemplate = (rowData: {
    horas_hora_total: string;
  }) => {
    const currentDuration: string[] = [];
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!rowData.horas_hora_total) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = rowData.horas_hora_total.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = (Number(horas_total_tmp[0])).toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);

        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    currentDuration.push(total_de_horas);

    const ms = currentDuration.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
    const sum = ms.reduce((prev, cur) => prev + cur, 0);

    const d = moment.duration(sum, 'milliseconds');
    const hours = Math.floor(d.asHours());
    const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;

    return time;
  };

  const decimalBodyTemplate = (rowData: { horas_hora_total: string; }) => {
    const cleanDuration = convertDecimalToHour(rowData.horas_hora_total);

    const totalHoursArray = cleanDuration.split(':');
    const cleanMinutes = (Number(totalHoursArray[0]) * 60) + Number(totalHoursArray[1]);

    return (cleanMinutes / 60).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 1,
    });
  };

  const totalDecimalBodyTemplate = (rowData: { valor_ajuste_nao_faturado: number; }) => {
    const newValue = rowData.valor_ajuste_nao_faturado
      ?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
      }).replace('.', ',');
    return newValue;
  };

  const personInChargeBodyTemplate = (rowData: {
    advogado_nome: string;
    administrador_nome: string
  }) => {
    if (billedHours !== undefined) {
      const filteredAttorney = billedHours.filter((item) => (
        item.advogado_nome === rowData.advogado_nome
      ), []);

      if (filteredAttorney.length > 0) {
        if (filteredAttorney[0].advogado_nome) {
          return filteredAttorney[0].advogado_nome;
        }
        const filteredAssistant = billedHours.filter((item) => (
          item.administrador_nome === rowData.administrador_nome
        ), []);

        return filteredAssistant[0].administrador_nome;
      }
    }

    return '';
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de horas:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
        <Column footer={totalTime} footerStyle={{ textAlign: 'center' }} />
        <Column footer={totalDecimalTime} footerStyle={{ textAlign: 'center' }} />
        {adjustment.current.length > 0 && adjustment.current !== '0,00' ? (
          <Column footer={adjustment.current} footerStyle={{ textAlign: 'center' }} />
        ) : null}
      </Row>
    </ColumnGroup>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Relatório de Horas</h1>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card">
          <Tooltip target=".export-buttons>button" position="bottom" />

          <Toolbar className="p-mb-4" right={rightToolbarTemplate} />

          <DataTable
            tableClassName="table-billedhours table"
            id="table-billedhours"
            ref={dt}
            loading={loading}
            value={billedHours}
            editMode="row"
            dataKey="horas_id"
            header={header}
            stripedRows
            className="p-datatable-sm table-pendinghours"
            rows={billedHours.length}
            emptyMessage="Nenhum registro encontrado."
            responsiveLayout="scroll"
            selection={selectedHours}
            footerColumnGroup={footerGroup}
          >
            <Column key="row_number" field="row_number" header="Núm." alignHeader="center" align="center" style={{ width: '50px' }} />
            <Column key="hora_data" field="hora_data" header="Data" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="advogado_nome" header="Responsável" alignHeader="center" body={personInChargeBodyTemplate} align="left" style={{ width: '250px' }} />
            <Column field="horas_descricao" header="Descrição" alignHeader="center" align="left" />
            <Column field="horas_tipo" header="Tipo" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="horas_hora_total" header="Horas" alignHeader="center" body={hourBodyTemplate} align="center" style={{ width: '80px' }} />
            <Column field="horas_decimal" header="Decimais" alignHeader="center" body={decimalBodyTemplate} align="center" style={{ width: '80px' }} />
            {adjustment.current.length > 0 && adjustment.current !== '0,00' ? (
              <Column field="valor_ajuste_nao_faturado" header="Ajustes Decimais" alignHeader="center" body={totalDecimalBodyTemplate} align="center" style={{ width: '80px' }} />
            ) : null}
            <Column field="horas_id" style={{ display: 'none' }} />
          </DataTable>

          <div className="grid summary-grid p-mt-10">
            <div className="col-3">
              <table id="table_summary">
                <tbody>
                  <tr>
                    <th colSpan={2} className="stripedRows">Resumo horas trabalhadas</th>
                  </tr>
                  <tr>
                    <td width="240">Total de horas</td>
                    <td width="100" align="right">{totalTime}</td>
                  </tr>
                  <tr className="stripedRows">
                    <td width="240">Total de horas (decimais)</td>
                    <td width="100" align="right">{totalDecimalTime}</td>
                  </tr>
                  {adjustment.current.length > 0 && adjustment.current !== '0,00' ? (
                    <>
                      <tr>
                        <td width="240">Ajuste (decimais)</td>
                        <td width="100" align="right">{adjustment.current}</td>
                      </tr>
                      <tr>
                        <td width="240">
                          Total horas consideradas
                          <br />
                          <small>(horas totais - ajuste de horas)</small>
                        </td>
                        <td width="100" align="right">
                          {totalAdjustedDecimalTime.current.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  <tr className={adjustment.current.length > 0 && adjustment.current !== '0,00' ? 'stripedRows' : ''}>
                    <td width="240">Valor cobrado por hora</td>
                    <td width="100" align="right">{valuePerHour}</td>
                  </tr>
                  <tr className={adjustment.current.length > 0 && adjustment.current !== '0,00' ? '' : 'stripedRows'}>
                    <td width="240">Total do período (R$)</td>
                    <td width="100" align="right"><span id="totalvalue">{totalValuePeriod}</span></td>
                  </tr>
                  <tr className={adjustment.current.length > 0 && adjustment.current !== '0,00' ? 'stripedRows' : ''}>
                    <td width="240">Ajuste (R$)</td>
                    <td width="100" align="right">
                      {enteredAdjustmentValue.current.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  <tr className={adjustment.current.length > 0 && adjustment.current !== '0,00' ? '' : 'stripedRows'}>
                    <td width="240">Valor Final (R$)</td>
                    <td width="100" align="right">{totalAddedValuePeriod}</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-12 md:col-12 reducedPadding">
                <Button
                  icon="pi pi-file-pdf"
                  iconPos="left"
                  label="Imprimir/Exportar"
                  className="p-button-info w-full"
                  type="button"
                  onClick={exportPdf}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </Container>
  );
};

export default SelectedHoursReport;
