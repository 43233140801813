import React, {
  useEffect, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useLocation } from 'react-router-dom';

import { decode } from 'html-entities';
import { Button } from 'primereact/button';

import { nanoid } from 'nanoid';

import { format } from 'date-fns';
import robotoRegular from '../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

import CompanyLogo from '../../../assets/logo.png';

 interface ILawsuitProperties {
  proc_id: number | null;
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
  cliente_nome: string;
  acao_descricao: string;
  orgao_descricao: string;
  proc_pedidos_data_atu: Date;
  proc_valor_causa: number;
  proc_pedidos_possib_geral: number;
  proc_pedidos_possib_geral_tp2: number;
  possibilidade_descricao: string;
  pedido_id: number;
  pedido_proc_id: number;
  pedido_descricao: string;
  pedido_possibilidade: number;
  pedido_vl_demandado: number;
  pedido_vl_provavel: number;
  pedido_vl_possivel: number;
  pedido_vl_possivel2: number;
  cod_indexador: number;
  data_inicio_monetario: Date | undefined;
  data_inicio_juros: Date | undefined;
  correcao_monetaria: string;
  juros_simples: string;
  id_descricao_pedido: number;
  val_juros_atualizado: number;
  val_calculo_indice: number;
  val_juros_provavel: number;
  val_juros_possivel: number;
  val_juros_possivel2: number;
  val_calculo_provavel: number;
  val_calculo_possivel: number;
  val_calculo_possivel2: number;
  data_pedido: Date;
  total_pedido_vl_demandado: number;
  total_pedido_vl_provavel: number;
  total_pedido_vl_possivel: number;
  total_pedido_vl_possivel2: number;
  total_calc_poss_remota: number;
  calc_poss_remota: number;
  pedido_vl_demandado_original: number;
  pedido_vl_provavel_original: number;
  pedido_vl_possivel_original: number;
  pedido_vl_possivel2_original: number;
}

interface IFinancialCostDeposit {
  dep_cus_id: number | null;
  dep_cus_proc_id: number;
  dep_cus_data: Date;
  dep_cus_cod_natureza: number;
  dep_cus_valor: number;
  dep_cus_classificacao: number;
}

interface ICostDepositProperties {
  natureza_id: number | null;
  natureza_descricao: string;
}

interface IPawnedGoods {
  dep_cus_id: number | null;
  dep_cus_proc_id: number;
  dep_cus_data: Date;
  dep_cus_cod_natureza: number;
  dep_cus_valor: number;
  dep_cus_classificacao: number;
  bens_penh_id: number | null;
  bens_penh_proc_id: number;
  bens_penh_descricao: string;
  bens_penh_parte: number;
  bens_penh_data: Date;
  bens_penh_valor: number;
  bens_penh_depositario: string;
}

const FinancialInformationReport: React.FC = () => {
  PrimeReact.ripple = true;

  const [lawsuits, setLawsuits] = useState<ILawsuitProperties[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true);
  // const [count, setCount] = useState(0);

  const { addToast } = useToast();
  const location = useLocation();

  const [financialRequests, setFinancialRequests] = useState<ILawsuitProperties[]>([]);
  const [financialCostDeposits, setFinancialCostDeposits] = useState<IFinancialCostDeposit[]>([]);
  const [costDeposits, setCostDeposits] = useState<ICostDepositProperties[]>([]);
  const [pawnedGoods, setPawnedGoods] = useState<IPawnedGoods[]>([]);

  useEffect(() => {
    (async () => {
      await api.get('/lawsuits/financialcostdeposits/show/all').then((response) => {
        setFinancialCostDeposits(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await api.get('/lawsuits/costsdeposits/show/all');
      setCostDeposits(response.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await api.get('/lawsuits/financialitems/pawnedgoods/show/all').then((response) => {
        setPawnedGoods(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const state = location.state as any;

        await api.post('/reports/financialinformation', state).then((response) => {
          const filtered: ILawsuitProperties[] = response.data
            .filter((
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value: { proc_id: number; }, index: number, a: any[],
            ) => a.findIndex((
              value2: { proc_id: number; },
            ) => (value2.proc_id === value.proc_id)) === index);
          setLawsuits(filtered);
          setFinancialRequests(response.data);
        });
        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, location]);

  const exportPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', [297, 210]);

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular');

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: lawsuits[0].cliente_nome,
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    const totalPagesExp = '{total_pages_count_string}';

    lawsuits.forEach((lawsuit, index) => {
      const { pageSize } = doc.internal;
      const fontSize = doc.getFontSize();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      autoTable(doc, {
        margin: [25, 0, 15, 15],
        html: `#results-table-${lawsuit.proc_id}`,
        includeHiddenHtml: true,
        theme: 'grid',
        tableWidth: 180,
        pageBreak: index === 0 ? 'avoid' : 'always',
        headStyles: {
          fillColor: [53, 73, 133],
          textColor: [255, 255, 255],
          fontSize: 12,
        },
        bodyStyles: {
          font: 'Roboto-Regular',
          fontStyle: 'normal',
        },
        didDrawPage(data) {
          // Header
          doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

          const header1 = 'Relatório de Informações Financeiras';
          const header2 = '';

          const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
          const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

          // Calculate text's x coordinate
          const x1 = ((pageWidth - hWidth1) / 2) + 15;
          const x2 = ((pageWidth - hWidth2) / 2) + 15;

          doc.setFontSize(14);
          doc.setTextColor(20);
          doc.text(header1, x1, 14);

          doc.setTextColor(40);
          doc.text(header2, x2, 19);

          doc.setLineWidth(0.5);
          doc.line(
            data.settings.margin.left, pageHeight - 15,
            data.settings.margin.right, pageHeight - 15,
          );

          // Footer
          const printedOn = new Date();
          const dateFormatted = format(
            printedOn,
            "dd/MM/yyyy 'às' HH:mm:ss'.'",
          );

          let str = `Página ${index + 1}`; // ${doc.getNumberOfPages()}
          if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
          }

          doc.setFontSize(8);

          const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
          const x3 = (pageWidth - pageCount) / 2;
          doc.text(str, x3 + 80, pageHeight - 8);
        },
      });

      if (findExistingRequest(Number(lawsuit.proc_id))) {
        doc.setFontSize(14);
        doc.setTextColor(40);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const posY = (doc as any).lastAutoTable.finalY + 10;
        doc.text('Discriminação e classificação dos pedidos:', 15, posY);

        autoTable(doc, {
          startY: posY + 5,
          html: `#requests-table-${lawsuit.proc_id}`,
          theme: 'grid',
          tableWidth: 180,
          headStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          bodyStyles: {
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          footStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          columnStyles: {
            0: { cellWidth: 30.5, halign: 'left' },
            1: { cellWidth: 25.5, halign: 'right' },
            2: { cellWidth: 25.5, halign: 'right' },
            3: { cellWidth: 25.5, halign: 'right' },
            4: { cellWidth: 25.5, halign: 'right' },
            5: { cellWidth: 25.5, halign: 'right' },
            6: { cellWidth: 22, halign: 'center' },
          },
        });
      }

      if (findExistingFees(Number(lawsuit.proc_id))) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const posY = (doc as any).lastAutoTable.finalY + 10;
        doc.text('Custas Processuais pagas pelo cliente:', 15, posY);
        autoTable(doc, {
          startY: posY + 5,
          html: `#lawsuitfees-table-${lawsuit.proc_id}`,
          theme: 'grid',
          tableWidth: 180,
          headStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            halign: 'center',
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          bodyStyles: {
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          footStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          columnStyles: {
            0: { cellWidth: 22, halign: 'center' },
            1: { cellWidth: 132, halign: 'left' },
            2: { cellWidth: 25, halign: 'right' },
          },
        });
      }

      if (findExistingDeposits(Number(lawsuit.proc_id))) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const posY = (doc as any).lastAutoTable.finalY + 10;
        doc.text('Depósitos Judiciais efetuados pelo cliente:', 15, posY);

        autoTable(doc, {
          startY: posY + 5,
          html: `#deposits-table-${lawsuit.proc_id}`,
          theme: 'grid',
          tableWidth: 180,
          headStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          bodyStyles: {
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          footStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          columnStyles: {
            0: { cellWidth: 22, halign: 'center' },
            1: { cellWidth: 132, halign: 'left' },
            2: { cellWidth: 25, halign: 'right' },
          },
        });
      }

      if (findExistingPawnedGoods(Number(lawsuit.proc_id))) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const posY = (doc as any).lastAutoTable.finalY + 10;
        doc.text('Bens penhorados ou à disposição da justiça:', 15, posY);
        autoTable(doc, {
          startY: posY + 5,
          html: `#pawned-table-${lawsuit.proc_id}`,
          theme: 'grid',
          // tableWidth: 190,
          tableWidth: 'auto',
          headStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          bodyStyles: {
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
        });
      }
    });

    // Footer
    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const printedOn = new Date();
    const dateFormatted = format(
      printedOn,
      "dd/MM/yyyy 'às' HH:mm:ss'.'",
    );

    let str = `Página ${doc.getNumberOfPages()}`;
    if (typeof doc.putTotalPages === 'function') {
      str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    }

    doc.setFontSize(8);

    const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    const x3 = (pageWidth - pageCount) / 2;
    doc.text(str, x3 + 50, pageHeight - 8);

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`Relatorio-${dateFormattedFile}.pdf`);
  };

  const filterNatureByCostId = (dep_cus_cod_natureza: number) => {
    const filteredNature = costDeposits.find(
      (costdeposit) => costdeposit.natureza_id === dep_cus_cod_natureza,
    );

    return filteredNature?.natureza_descricao;
  };

  const findExistingRequest = (proc_id: number) => {
    const found = financialRequests.find((obj) => obj.pedido_proc_id === proc_id);

    return found;
  };

  const findExistingFees = (proc_id: number) => {
    const found = financialCostDeposits
      .find((obj) => obj.dep_cus_proc_id === proc_id && obj.dep_cus_classificacao === 1);

    return found;
  };

  const findExistingDeposits = (proc_id: number) => {
    const found = financialCostDeposits
      .find((obj) => obj.dep_cus_proc_id === proc_id && obj.dep_cus_classificacao === 2);

    return found;
  };

  const findExistingPawnedGoods = (proc_id: number) => {
    const found = pawnedGoods.find((obj) => obj.bens_penh_proc_id === proc_id);

    return found;
  };

  return (
    <Container>
      <header>
        <div>
          <h3>PABST & HADLICH ADVOGADOS ASSOCIADOS</h3>
          <h4>Relatório de Informações Financeiras</h4>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <div className="p-toolbar p-component p-mb-20" role="toolbar">
            <div className="p-toolbar-group-left">
            &nbsp;
            </div>
            <div className="p-toolbar-group-right">
              <Button
                type="button"
                icon="pi pi-file-pdf"
                onClick={exportPdf}
                className="p-button-danger mr-2"
                tooltip="Exportar para PDF / Imprimir"
                tooltipOptions={{ position: 'left' }}
              />
            </div>
          </div>
          {lawsuits.map((lawsuit, index) => (
            <>
              <table key={nanoid()} className="lawsuitsummary-table" id={`results-table-${lawsuit.proc_id}`}>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <h3 className="left">
                        Cliente:
                        { ' ' }
                        {lawsuit.cliente_nome}
                      </h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lawsuit.proc_reg.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Processo nº</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                          {lawsuit.proc_reg}
                        </td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_reg_outros?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Outros nºs do processo</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.proc_reg_outros}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_autor?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Autor(es)</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.proc_autor}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_reu?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Réu(s)</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.proc_reu}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.acao_descricao?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Tipo de ação</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.acao_descricao}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_assunto?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Assunto</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.proc_assunto}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.orgao_descricao?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Órgão/Local</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.orgao_descricao}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_localizacao?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Localização atual do processo</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.proc_localizacao}</td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_data_ajuiz !== null
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Data de ajuizamento</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                          {lawsuit.proc_data_ajuiz !== null && lawsuit.proc_data_ajuiz?.toString() !== '01/01/1970'
                            ? new Date(lawsuit.proc_data_ajuiz).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                            : null}
                        </td>
                      </tr>
                    )
                    : null}

                  {lawsuit.proc_valor_causa !== null
                  && lawsuit.proc_valor_causa !== 0.00
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Valor nominal do processo</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                          R$
                          {' '}
                          {Number(lawsuit.proc_valor_causa)
                            ?.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}

                        </td>
                      </tr>
                    )
                    : null}
                  {financialRequests.filter(
                    (request) => request.pedido_proc_id === lawsuit.proc_id,
                  ).reduce((accum, item) => accum + Number(item.pedido_vl_demandado), 0) > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Valor econômico do processo</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                          R$
                          {' '}
                          {financialRequests.filter(
                            (request) => request.pedido_proc_id === lawsuit.proc_id,
                          )
                            .reduce((accum, item) => accum + Number(item.pedido_vl_demandado), 0)
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_pedidos_data_atu !== null
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Atualizados até</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                          {lawsuit.proc_pedidos_data_atu !== null && lawsuit.proc_pedidos_data_atu?.toString() !== '01/01/1970'
                            ? new Date(lawsuit.proc_pedidos_data_atu).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                            : null}
                        </td>
                      </tr>
                    )
                    : null}
                  {lawsuit.possibilidade_descricao?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Possibilidade geral do processo</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                          {lawsuit.possibilidade_descricao}
                        </td>
                      </tr>
                    )
                    : null}
                  {lawsuit.proc_situacao?.length > 0
                    ? (
                      <tr className="py-2 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                        <td className="text-500 w-6 md:w-4 font-medium">Situação do processo</td>
                        <td className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">{lawsuit.proc_situacao}</td>
                      </tr>
                    )
                    : null}
                </tbody>
              </table>

              {findExistingRequest(Number(lawsuit.proc_id)) ? (
                <div className="col-12 p-mt-20">
                  <h3>Discriminação e classificação dos pedidos:</h3>
                  <table key={nanoid()} className="requests-table" id={`requests-table-${lawsuit.proc_id}`}>
                    <thead>
                      <tr>
                        <th rowSpan={2}>Descrição do pedido</th>
                        <th rowSpan={2}>
                          Valor de cada
                          <br />
                          pedido (R$)
                        </th>
                        <th colSpan={4} align="center">
                          Possibilidade de
                          {lawsuit.proc_pedidos_possib_geral === 1 ? ' êxito' : ' perda'}
                        </th>
                        <th rowSpan={2}>Data (*)</th>
                      </tr>
                      <tr>
                        <th>
                          Provável
                          <br />
                          (≥ 80%)
                        </th>
                        <th>
                          Possível
                          <br />
                          (&gt; 50%)
                        </th>
                        <th>
                          Possível
                          <br />
                          (&le; 50%)
                        </th>
                        <th>
                          Remota
                          <br />
                          (&le; 20%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {financialRequests.filter(
                        (request) => request.pedido_proc_id === lawsuit.proc_id,
                      )
                        .map((request) => (
                          <tr>
                            <td>{request.pedido_descricao}</td>
                            <td align="right">
                              {lawsuit.total_pedido_vl_demandado !== null
                        && lawsuit.total_pedido_vl_demandado !== 0.00
                                ? Number(request.pedido_vl_demandado)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : '-'}
                            </td>
                            <td align="right">
                              {request.pedido_possibilidade?.toString() !== ''
                                ? Number(request.pedido_vl_provavel)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : '-'}

                            </td>
                            <td align="right">
                              {request.pedido_possibilidade?.toString() !== ''
                                ? Number(request.pedido_vl_possivel)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : '-'}

                            </td>
                            <td align="right">
                              {request.pedido_possibilidade?.toString() !== ''
                                ? Number(request.pedido_vl_possivel2)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : '-'}

                            </td>
                            <td align="right">
                              {request.pedido_possibilidade?.toString() !== ''
                                ? Number(request.calc_poss_remota)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : '-'}

                            </td>
                            <td align="center">
                              {request.data_pedido !== null && request.data_pedido?.toString() !== '01/01/1970'
                                ? new Date(request.data_pedido)?.toLocaleDateString('pt-br', { timeZone: 'UTC' })
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td align="center">Totais</td>
                        <td align="right">
                          {financialRequests.filter(
                            (request) => request.pedido_proc_id === lawsuit.proc_id,
                          )
                            .reduce((accum, item) => accum + Number(item.pedido_vl_demandado), 0)
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                        <td align="right">
                          {financialRequests.filter(
                            (request) => request.pedido_proc_id === lawsuit.proc_id,
                          )
                            .reduce((accum, item) => accum + Number(item.pedido_vl_provavel), 0)
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                        <td align="right">
                          {financialRequests.filter(
                            (request) => request.pedido_proc_id === lawsuit.proc_id,
                          )
                            .reduce((accum, item) => accum
                            + Number(item.pedido_vl_possivel),
                            0)
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                        <td align="right">
                          {financialRequests.filter(
                            (request) => request.pedido_proc_id === lawsuit.proc_id,
                          )
                            .reduce((accum, item) => accum + Number(item.pedido_vl_possivel2), 0)
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                        <td align="right">
                          {financialRequests.filter(
                            (request) => request.pedido_proc_id === lawsuit.proc_id,
                          )
                            .reduce((accum, item) => accum + Number(item.calc_poss_remota), 0)
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ) : null}

              {findExistingFees(Number(lawsuit.proc_id)) ? (
                <div className="col-12 p-mt-20">
                  <h3>Custas Processuais pagas pelo cliente:</h3>
                  <table key={nanoid()} className="lawsuitfees-table" id={`lawsuitfees-table-${lawsuit.proc_id}`}>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Natureza</th>
                        <th>Valor (R$)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {financialCostDeposits.filter(
                        (cost) => cost.dep_cus_proc_id === lawsuit.proc_id
                      && cost.dep_cus_classificacao === 1,
                      ).map((cost) => (
                        <tr>
                          <td align="center" width="150">
                            {cost.dep_cus_data !== null && cost.dep_cus_data?.toString() !== '01/01/1970'
                              ? new Date(cost.dep_cus_data).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                              : '-'}
                          </td>
                          <td>
                            {filterNatureByCostId(Number(cost.dep_cus_cod_natureza))}
                          </td>
                          <td align="right" width="150">
                            {cost.dep_cus_valor?.toString() !== ''
                              ? Number(cost.dep_cus_valor)?.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              {findExistingDeposits(Number(lawsuit.proc_id)) ? (
                <div className="col-12 p-mt-20">
                  <h3>Depósitos Judiciais efetuados pelo cliente:</h3>
                  <table key={nanoid()} className="deposits-table" id={`deposits-table-${lawsuit.proc_id}`}>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Natureza</th>
                        <th>Valor (R$)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {financialCostDeposits.filter(
                        (cost) => cost.dep_cus_proc_id === lawsuit.proc_id
                      && cost.dep_cus_classificacao === 2,
                      ).map((cost) => (
                        <tr>
                          <td align="center" width="150">
                            {cost.dep_cus_data !== null && cost.dep_cus_data?.toString() !== '01/01/1970'
                              ? new Date(cost.dep_cus_data).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                              : '-'}
                          </td>
                          <td>
                            {filterNatureByCostId(Number(cost.dep_cus_cod_natureza))}
                          </td>
                          <td align="right" width="150">
                            {cost.dep_cus_valor?.toString() !== ''
                              ? Number(cost.dep_cus_valor)?.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              {findExistingPawnedGoods(Number(lawsuit.proc_id)) ? (
                <div className="col-12 p-mt-20">
                  <h3>Bens penhorados ou à disposição da justiça:</h3>
                  <table key={nanoid()} className="pawned-table" id={`pawned-table-${lawsuit.proc_id}`}>
                    <thead>
                      <tr>
                        <th>Bens penhorados</th>
                        <th>Parte</th>
                        <th>Data</th>
                        <th>Valor (R$)</th>
                        <th>Depositário</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pawnedGoods.filter(
                        (pawnedgoods) => pawnedgoods.bens_penh_proc_id === lawsuit.proc_id,
                      ).map((pawnedgoods) => (
                        <tr>
                          <td>
                            111
                            {pawnedgoods.bens_penh_id}
                            {decode(pawnedgoods.bens_penh_descricao)}
                          </td>
                          <td width="100">
                            222
                            {pawnedgoods.bens_penh_parte === 1 ? 'Cliente' : 'Parte adversa'}
                          </td>
                          <td align="center" width="150">
                            {pawnedgoods.bens_penh_data !== null && pawnedgoods.bens_penh_data?.toString() !== '01/01/1970'
                              ? new Date(pawnedgoods.bens_penh_data).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                              : '-'}
                          </td>
                          <td align="right" width="150">
                            {pawnedgoods.bens_penh_valor?.toString() !== ''
                              ? Number(pawnedgoods.bens_penh_valor)?.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : '-'}
                          </td>
                          <td align="right" width="150">
                            {Number.isNaN(Number(pawnedgoods.bens_penh_depositario))
                              ? pawnedgoods.bens_penh_depositario
                              : Number(pawnedgoods.bens_penh_depositario)?.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              {index < lawsuits.length - 1
                ? <hr key={nanoid()} className="p-mt-20 p-mb-20" />
                : null }
            </>
          ))}
        </div>
      </div>

    </Container>
  );
};

export default FinancialInformationReport;
