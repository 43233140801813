/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useHistory, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface ILegalRequestFormData {
  // it_financeiros_pedidos_descricao_id: number;
  it_financeiros_pedidos_descricao: string;
  natureza_id: number | null;
}

const AddEditLegalRequest:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const initialValues: ILegalRequestFormData = {
    it_financeiros_pedidos_descricao: '',
    natureza_id: 0,
  };

  const [legalRequest, setLegalRequest] = useState(null);
  const [natureId, setNatureId] = useState(0);
  const [natureSuitOptions, setNatureSuitOptions] = useState([{}]);

  useEffect(() => {
    api.get('lawsuits/naturesuits/show/all').then((response) => {
      response.data.map((natureSuit: { natureza_descricao: string; natureza_id: number;}) => (
        natureSuitOptions.push({
          name: natureSuit.natureza_descricao,
          value: natureSuit.natureza_id,
        })
      ));
      natureSuitOptions.splice(0, 1);
      setNatureSuitOptions(natureSuitOptions);
    });
  }, [natureSuitOptions]);

  const validationSchema = Yup.object().shape({
    it_financeiros_pedidos_descricao: Yup.string().required('Descrição obrigatória'),
  });

  const handleOnSubmit = (
    values: ILegalRequestFormData,
    { setSubmitting }: FormikHelpers<ILegalRequestFormData>,
  ) => {
    if (isAddMode) {
      try {
        api.post('/lawsuits/legalrequests/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/lawsuit/legalrequest');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        api.put(`/lawsuits/legalrequests/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/lawsuit/legalrequest');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  useEffect(() => {
    try {
      if (!isAddMode) {
        api.get(`lawsuits/legalrequests/show/${id}`).then((response) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setLegalRequest(response.data[0]);
            setNatureId(response.data[0].natureza_id);
          });
        });
      }
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Não foi possível carregar as informações do cadastro selecionado.',
      });
    }
  }, [addToast, id, isAddMode]);

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            descricão do pedido
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={legalRequest || initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="natureza_id">
                          Natureza processo
                        </label>
                        <Dropdown
                          id="natureza_id"
                          name="natureza_id"
                          value={natureId}
                          options={natureSuitOptions}
                          onChange={(item) => {
                            setNatureId(item.value.natureza_id);
                            handleChange(item.value.natureza_id);
                          }}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          dataKey="natureza_id"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma natureza"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />

                      </div>

                      <div className="col-12 md:col-6">
                        <label htmlFor="it_financeiros_pedidos_descricao">
                          Descrição *
                        </label>
                        <InputText
                          id="it_financeiros_pedidos_descricao"
                          name="it_financeiros_pedidos_descricao"
                          className={` ${errors.it_financeiros_pedidos_descricao && touched.it_financeiros_pedidos_descricao ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.it_financeiros_pedidos_descricao}
                        />
                        <ErrorMessage name="it_financeiros_pedidos_descricao">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger p-mr-2"
                        type="button"
                        onClick={history.goBack}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditLegalRequest;
