/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from '../styles';

interface SearchData {
  proc_cod_cliente: number;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
}

const SearchLawsuitSummaryExcelGrouped:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm:ss');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD hh:mm:ss');

  sessionStorage;

  const initialValues: SearchData = {
    proc_cod_cliente: 0,
    proc_data_ajuiz_inicio: new Date(startOfMonth),
    proc_data_ajuiz_final: new Date(endOfMonth),
  };

  const [customerId, setCustomerId] = useState('0');
  const [customersOptions, setCustomersOptions] = useState([{}]);
  const [
    initialRegistrationDate, setInitialRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    finalRegistrationDate, setFinalRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [dateFilterType, setDateFilterType] = useState('data');
  const [showDate, setShowDate] = useState(true);
  const [showPeriod, setShowPeriod] = useState(false);

  const dateFilterTypeOptions = [
    { name: 'Data', value: 'data', style: 'font-size: small;' },
    { name: 'Ano', value: 'ano', style: 'font-size: small;' },
  ];

  const validationSchema = null;

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      const searchFormData = {
        proc_cod_cliente: customerId,
        dateFilterType,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
      };

      history.push({
        pathname: '/report/lawsuit/grouped/excel',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };

  return (
    <Container>
      <div className="card">
        <header>
          <h3>Relatório Total de Processos Agrupado por setor - Filtro Excel </h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-6">
                  <div className="col-12 md:col-6">
                    <label htmlFor="proc_cliente">
                      Cliente
                    </label>
                    <Dropdown
                      id="proc_cliente"
                      name="proc_cliente"
                      options={customersOptions}
                      onChange={(e) => setCustomerId(e.target.value)}
                      value={customerId}
                      className="inputfield w-full"
                      optionLabel="name"
                      optionValue="value"
                      filter
                      filterBy="name"
                      placeholder="Selecione uma opção"
                      emptyFilterMessage="Nenhum registro encontrado"
                    />
                  </div>
                  <div className="col-12 md:col-12">
                    <label htmlFor="proc_data_ajuiz_inicio">
                      Data de registro
                      { ' ' }
                      <Tooltip target=".infoText">
                        <strong>Selecione data específica ou período</strong>
                        <br />
                        <strong>Intervalo data</strong>
                        <br />
                        <em>
                          <strong>Intervalo</strong>
                          {' '}
                          datas escolhe o intervalo
                          <br />
                          <strong>Até</strong>
                          {' '}
                          escolhe todos processo até o final do ano selecionado no
                          período com o campo
                          {' '}
                          <strong>período final</strong>
                          {' '}
                        </em>
                        <br />
                        <br />
                        <strong>Seleção data</strong>
                        <br />
                        <em>
                          Use para intervalos com seleção específica de datas
                        </em>
                        <br />
                        <br />
                        <strong>Seleção ano</strong>

                        <br />
                        <em>
                          Para ano limite de pesquisa do ano preencha apenas o ano final,
                          <br />
                        </em>
                      </Tooltip>
                      <i
                        className="infoText pi pi-info-circle"
                        data-pr-position="bottom"
                      />
                    </label>
                  </div>
                  <div className="col-12 md:col-12" style={{ marginBottom: '15px' }}>
                    <SelectButton
                      id="date_filter_type"
                      value={dateFilterType}
                      options={dateFilterTypeOptions}
                      optionLabel="name"
                      onChange={(e) => {
                        setDateFilterType(e.value);
                        if (e.value === 'data') {
                          setShowDate(true);
                          setShowPeriod(false);
                          setInitialRegistrationDate(null);
                          setFinalRegistrationDate(null);
                        } else {
                          setShowDate(false);
                          setShowPeriod(true);
                          setInitialRegistrationDate(null);
                          setFinalRegistrationDate(null);
                        }
                      }}
                      style={{ padding: '0.5rem 0.79rem 0 0' }}
                    />
                  </div>
                  {showPeriod ? (
                    <>
                      <div id="periodo_ini" className="col-6 md:col-6">
                        <Calendar
                          inputId="proc_data_ajuiz_inicio_mes_ano"
                          name="proc_data_ajuiz_inicio_mes_ano"
                          className="inputfield w-full"
                          value={null}
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setInitialRegistrationDate(e.value);
                            }
                          }}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="9999"
                          dateFormat="yy"
                          yearRange="1980:2030"
                          placeholder="Ano inicial"
                          view="month"
                          yearNavigator
                        />
                      </div>
                      <div id="periodo_fim" className="col-6 md:col-6">
                        <label htmlFor="proc_data_ajuiz_final_mes_ano">
                          <Calendar
                            inputId="proc_data_ajuiz_final_mes_ano"
                            name="proc_data_ajuiz_final"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setFinalRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="9999"
                            dateFormat="yy"
                            yearRange="1980:2030"
                            placeholder="Ano final"
                            view="month"
                            yearNavigator
                          />
                        </label>
                      </div>
                    </>
                  ) : null }
                  {showDate ? (
                    <>
                      <div id="data_ini" className="col-6 md:col-6">
                        <Calendar
                          inputId="proc_data_ajuiz_inicio"
                          name="proc_data_ajuiz_inicio"
                          className="inputfield w-full"
                          value={null}
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setInitialRegistrationDate(e.value);
                            }
                          }}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                          placeholder="Data inicial"
                        />
                      </div>
                      <div id="data_fim" className="col-6 md:col-6">
                        <label htmlFor="proc_data_ajuiz_final">
                          <Calendar
                            inputId="proc_data_ajuiz_final"
                            name="proc_data_ajuiz_final"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setFinalRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data final"
                          />
                        </label>
                      </div>
                    </>
                  ) : null }
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchLawsuitSummaryExcelGrouped;
