/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';

import { useHistory, useParams } from 'react-router-dom';

import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
// import moment from 'moment';
import { format } from 'date-fns';
import {
  Container,
} from './styles';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

interface IScheduleData {
  meeting_room_id: number;
  attendees: string;
  schedule_subject: string;
  schedule_description: string;
  schedule_date: Date | undefined;
  schedule_start: Date | undefined;
  schedule_end: Date | undefined;
  schedule_status_id: number;
  schedule_priority_id: number;
  schedule_type_id: number;
  horas_hora_inicial_texto: string;
  horas_hora_final_texto: string;
  user_id: string;
}

const AddEditSchedule:React.FC = () => {
  const { user } = useAuth();

  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const [schedule, setSchedule] = useState(null);
  const [roomsOptions, setRoomsOptions] = useState([{}]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRoom, setSelectedRoom] = useState('0');
  const [scheduleDate, setScheduleDate] = useState<Date | Date[] | undefined>(undefined);
  const [timeStartText, setTimeStartText] = useState('');
  const [timeEndText, setTimeEndText] = useState('');
  const [attorneysOptions, setAttorneysOptions] = useState([{}]);
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]);
  const [selectedAttorneys, setSelectedAttorneys] = useState<string[]>([]);
  const [notifyAttorney, setNotifyAttorney] = useState<boolean>(false);
  const [notifyAssistant, setNotifyAssistant] = useState<boolean>(false);

  const optionsScheduleStatus = [
    { name: 'A iniciar', value: 1 },
    { name: 'Em andamento', value: 2 },
    { name: 'Em espera', value: 4 },
    { name: 'Concluída', value: 3 },
  ];

  const optionsPriority = [
    { name: 'Alta', value: 1 },
    { name: 'Normal', value: 2 },
    { name: 'Baixa', value: 3 },
  ];

  const optionsScheduleType = [
    { name: 'Atividade', value: 1 },
    { name: 'Reunião', value: 2 },
  ];

  const initialValues: IScheduleData = {
    meeting_room_id: 0,
    attendees: '',
    schedule_subject: '',
    schedule_description: '',
    schedule_date: new Date(),
    schedule_start: undefined,
    schedule_end: undefined,
    horas_hora_inicial_texto: '',
    horas_hora_final_texto: '',
    schedule_status_id: 1,
    schedule_priority_id: 2,
    schedule_type_id: 1,
    user_id: user.id,
  };

  const validationSchema = Yup.object().shape({
    schedule_subject: Yup.string().required('Assunto obrigatória'),
    schedule_date: Yup.date().required('Data de acontecimento obrigatória'),
    // schedule_start: Yup.string()
    //   .test(
    //     'not empty',
    //     'Hora de início não pode ser vazia',
    //     (value) => !!value,
    //   ),
    // schedule_end: Yup.string()
    //   .test(
    //     'end_time_test',
    //     'Hora final deve ser maior que hora de início',
    //     () => {
    //       const startingTime = timeStartText.split(':');
    //       const endingTime = timeEndText.split(':');
    //       const beginningTime = moment({
    //         hour: Number(startingTime[0]), minute: Number(startingTime[1]),
    //       });
    //       const finishingTime = moment({
    //         hour: Number(endingTime[0]), minute: Number(endingTime[1]),
    //       });

    //       return beginningTime.isBefore(finishingTime) !== false;
    //     },
    //   ),
  });

  useEffect(() => {
    (async () => {
      const roomsOptionsArray = [{ name: '', value: 0 }];
      await api.get('/schedules/meetings/rooms/show/all').then((response) => {
        response.data.map((room:
        {
          id: number,
          room_description:string
        }) => (
          roomsOptionsArray.push({
            name: room.room_description,
            value: room.id,
          })
        ));
      });
      // constroomsOptions.splice(0, 1);
      setRoomsOptions(roomsOptionsArray);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const attorneysArray = [{ name: '', value: '', key: 0 }];
      await api.get('/management/attorneys/show/all').then((response) => {
        response.data.map((attorney:
        {
          advogado_id: number,
          advogado_nome: string
        }) => (
          attorneysArray.push({
            name: attorney.advogado_nome,
            value: attorney.advogado_id.toString(),
            key: attorney.advogado_id,
          })
        ));
        attorneysArray.splice(0, 1);
        setAttorneysOptions(attorneysArray);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const assistantsArray = [{ name: '', value: '', key: 0 }];
      await api.get('/management/assistants/show/all').then((response) => {
        response.data.map((assistant:
        {
          administrador_id: number,
          administrador_nome: string
        }) => (
          assistantsArray.push({
            name: assistant.administrador_nome,
            value: assistant.administrador_id.toString(),
            key: assistant.administrador_id,
          })
        ));
      });
      assistantsArray.splice(0, 1);
      setAssistantsOptions(assistantsArray);
    })();
  }, []);

  const attorneyPanelFooterTemplate = () => {
    const selectedItems = selectedAttorneys;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b>
        {' '}
        {length > 1 ? 'itens selecionados' : 'item selecionado'}
        .
      </div>
    );
  };

  const assistantPanelFooterTemplate = () => {
    const selectedItems = selectedAssistants;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b>
        {' '}
        {length > 1 ? 'itens selecionados' : 'item selecionado'}
        .
      </div>
    );
  };

  const handleOnSubmit = async (
    values: IScheduleData,
    { setSubmitting }: FormikHelpers<IScheduleData>,
  ) => {
    if (isAddMode) {
      try {
        let attorneysIds = '';
        if (selectedAttorneys.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          selectedAttorneys.forEach((item: any) => {
            if (attorneysIds === '') {
              attorneysIds = `adv|${item}`;
            } else {
              attorneysIds += `,adv|${item}`;
            }
          });
        }

        if (selectedAssistants.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          selectedAssistants.forEach((item: any) => {
            if (attorneysIds === '') {
              attorneysIds = `adm|${item}`;
            } else {
              attorneysIds += `adm|,${item}`;
            }
          });
        }

        const formData = {
          meeting_room_id: values.meeting_room_id,
          attendees: attorneysIds,
          schedule_subject: values.schedule_subject,
          schedule_description: values.schedule_description,
          schedule_date: values.schedule_date,
          horas_hora_inicial_texto: timeStartText,
          horas_hora_final_texto: timeEndText,
          schedule_status_id: values.schedule_status_id,
          schedule_priority_id: values.schedule_priority_id,
          schedule_type_id: values.schedule_type_id,
          user_id: user.id,
        };

        await api.post('/schedules/create', formData);

        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/schedule/myschedule');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        let attorneysIds = '';
        if (selectedAttorneys.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          selectedAttorneys.forEach((item: any) => {
            if (attorneysIds === '') {
              attorneysIds = `adv|${item}`;
            } else {
              attorneysIds += `,adv|${item}`;
            }
          });
        }

        if (selectedAssistants.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          selectedAssistants.forEach((item: any) => {
            if (attorneysIds === '') {
              attorneysIds = `adm|${item}`;
            } else {
              attorneysIds += `,adm|${item}`;
            }
          });
        }

        const formData = {
          meeting_room_id: values.meeting_room_id,
          attendees: attorneysIds,
          schedule_subject: values.schedule_subject,
          schedule_description: values.schedule_description,
          schedule_date: values.schedule_date,
          horas_hora_inicial_texto: timeStartText,
          horas_hora_final_texto: timeEndText,
          schedule_status_id: values.schedule_status_id,
          schedule_priority_id: values.schedule_priority_id,
          schedule_type_id: values.schedule_type_id,
        };

        await api.put(`/schedules/edit/${id}`, formData);

        try {
          if (notifyAttorney || notifyAssistant) {
            const formEmailData = {
              scheduleId: `${id}`,
              attendees: attorneysIds,
            };

            api.post('/schedules/sendmail', formEmailData).then(() => {
              addToast({
                type: 'success',
                title: 'E-mail(s) enviado(s) com sucesso',
                description: 'O(s) e-mails selecionados para envio foram processados com sucesso.',
              });
              toast.current?.show({
                severity: 'success',
                summary: 'E-mail(s) enviado(s) com sucesso',
                detail: 'O(s) e-mails selecionados para envio foram processados com sucesso.',
                life: 3000,
              });
            });
          }
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Erro ao enviar o email',
            description: 'Ocorreu um erro ao enviar o e-mail. Tente novamente.',
          });
        }

        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/schedule/myschedule');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            agendamento de atividade/reunião
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={schedule || initialValues}
        >
          {({
            values,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            // setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              (async () => {
                try {
                  if (!isAddMode) {
                    await api.get(`/schedules/show/${id}`).then((response) => {
                      setValues(response.data);
                      setSchedule(response.data);

                      if (response.data.attendees.length > 0) {
                        const attendees = response.data.attendees?.toString().split(',');
                        const attorneysIds: React.SetStateAction<string[]> = [];
                        const assistantsIds: React.SetStateAction<string[]> = [];

                        attendees.forEach((attendee: string) => {
                          const attendeesArray = attendee.split('|');
                          if (attendeesArray[0] === 'adv') {
                            attorneysIds.push(attendeesArray[1]);
                          } else {
                            assistantsIds.push(attendeesArray[1]);
                          }
                        });

                        const filteredAssistants = assistantsIds.filter((el) => el !== null);

                        setSelectedAttorneys(attorneysIds);
                        setSelectedAssistants(filteredAssistants);
                      }

                      const scheduleDateBR = new Date(response.data.schedule_date);
                      if (response.data.horas_data !== null) {
                        const scheduleDateISO = new Date(scheduleDateBR.toISOString().slice(0, -1));
                        setScheduleDate(scheduleDateISO);
                      }

                      if (response.data.schedule_start !== null) {
                        const timeStartBR = new Date(response.data.schedule_start);
                        const formattedStartTime = timeStartBR.toLocaleString('pt-BR', { timeZone: 'UTC' });

                        if (formattedStartTime.toString() !== '00:00') {
                          const cleanInitialTime = format(new Date(formattedStartTime), 'HH:mm');
                          setTimeStartText(cleanInitialTime);
                        } else {
                          setTimeStartText('');
                        }
                      } else {
                        setTimeStartText('');
                      }

                      if (response.data.schedule_end !== null) {
                        const timeEndBR = new Date(response.data.schedule_end);
                        const formattedEndTime = timeEndBR.toLocaleString('pt-BR', { timeZone: 'UTC' });

                        if (formattedEndTime.toString() !== '00:00') {
                          const cleanEndTime = format(new Date(formattedEndTime), 'HH:mm');
                          setTimeEndText(cleanEndTime);
                        } else {
                          setTimeEndText('');
                        }
                      } else {
                        setTimeEndText('');
                      }
                    });
                  }
                } catch (e) {
                  if (typeof e === 'string') {
                    e.toUpperCase();
                  } else if (e instanceof Error) {
                    addToast({
                      type: 'error',
                      title: 'Ocorreu um problema',
                      description:
                    `Não foi possível carregar as informações do cadastro selecionado.
                    Erro: ${e.message}`,
                    });
                  }
                }
              })();
            }, [setValues]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="schedule_subject">
                          Assunto/atividade *
                        </label>
                        <InputText
                          id="schedule_subject"
                          name="schedule_subject"
                          className="inputfield w-full"
                          onChange={handleChange}
                          value={values.schedule_subject}
                        />
                        <ErrorMessage name="schedule_subject">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="schedule_date">
                          Data acontecimento *
                        </label>
                        <Calendar
                          inputId="schedule_date"
                          name="schedule_date"
                          className="inputfield w-full"
                          // value={scheduleDate}
                          value={
                            isAddMode
                              ? undefined
                              : scheduleDate
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                          locale="pt_BR"
                        />
                      </div>
                      <div className="col-12 md:col-3 mt-20">
                        <label htmlFor="schedule_start">
                          Horário inicial
                        </label>
                        <br />
                        <InputMask
                          id="schedule_start"
                          name="schedule_start"
                          className="inputfield w-full"
                          mask="99:99"
                          autoClear={false}
                          value={timeStartText}
                          onChange={(e) => {
                            if (e.value) {
                              setTimeStartText(e.value);
                            }
                          }}
                        />

                        <ErrorMessage name="schedule_start">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="schedule_end">
                          Horário término
                        </label>
                        <br />
                        <InputMask
                          id="schedule_end"
                          name="schedule_end"
                          className="inputfield w-full"
                          mask="99:99"
                          autoClear={false}
                          value={timeEndText}
                          onChange={(e) => {
                            if (e.value) {
                              setTimeEndText(e.value);
                            }
                          }}
                        />

                        <ErrorMessage name="schedule_end">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="schedule_type_id">Tipo da atividade</label>
                        <SelectButton
                          id="schedule_type_id"
                          value={values.schedule_type_id}
                          options={optionsScheduleType}
                          optionLabel="name"
                          optionValue="value"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="schedule_priority_id">Prioridade</label>
                        <SelectButton
                          id="schedule_priority_id"
                          value={values.schedule_priority_id}
                          options={optionsPriority}
                          optionLabel="name"
                          optionValue="value"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="schedule_status_id">Situação da atividade</label>
                        <SelectButton
                          id="schedule_status_id"
                          value={values.schedule_status_id}
                          options={optionsScheduleStatus}
                          optionLabel="name"
                          optionValue="value"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="horas_cod_cliente">
                          Local reunião
                        </label>
                        <Dropdown
                          id="meeting_room_id"
                          name="meeting_room_id"
                          value={values.meeting_room_id}
                          options={roomsOptions}
                          onChange={(e) => {
                            // setSelectedRoom(e.value);
                            handleChange(e);
                          }}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                          disabled={values.schedule_type_id === 1}
                        />
                        <ErrorMessage name="schedule_room_id">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="schedule_description">
                          Detalhamento (assunto a ser abordado)
                        </label>
                        <InputTextarea
                          id="schedule_description"
                          name="schedule_description"
                          className="inputfield w-full"
                          value={values?.schedule_description}
                          onChange={handleChange}
                          rows={5}
                          cols={30}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">

                    <header>
                      <h3>Convidar participantes e enviar cópias de e-mail</h3>
                    </header>

                    <div className="field grid p-mt-20">
                      <div className="col-12 md:col-6">
                        <Checkbox
                          inputId="avisar_cliente"
                          name="avisar_cliente"
                          onChange={(e) => {
                            if (e.checked !== undefined) {
                              setNotifyAttorney(e.checked);
                            }
                          }}
                          checked={notifyAttorney}
                        />
                        <label htmlFor="avisar_cliente">
                          {' '}
                          Notificar advogado(s) por e-mail
                        </label>
                      </div>
                      <div className="col-12 md:col-6">
                        <Checkbox
                          inputId="avisar_advogado"
                          name="avisar_advogado"
                          onChange={(e) => {
                            if (e.checked !== undefined) {
                              setNotifyAssistant(e.checked);
                            }
                          }}
                          checked={notifyAssistant}
                        />
                        <label htmlFor="avisar_advogado">
                          {' '}
                          Notificar assistente(s) por e-mail
                        </label>
                      </div>
                    </div>

                    <div className="field grid p-mt-20">
                      <div className="col-12 md:col-6">
                        <label htmlFor="attendees">
                          Advogados
                        </label>
                        <MultiSelect
                          id="attendees"
                          name="attendees"
                          value={selectedAttorneys}
                          options={attorneysOptions}
                          onChange={(e) => {
                            setSelectedAttorneys(e.value);
                          }}
                          optionLabel="name"
                          optionValue="value"
                          placeholder="Selecione o(s) advogado(s)"
                          filter
                          className="multiselect-custom w-full"
                          panelFooterTemplate={attorneyPanelFooterTemplate}
                        />
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="copia_emails_assistentes">
                          Assistentes
                        </label>
                        <MultiSelect
                          id="copia_emails_assistentes"
                          name="copia_emails_assistentes"
                          value={selectedAssistants}
                          options={assistantsOptions}
                          onChange={(e) => {
                            setSelectedAssistants(e.value);
                          }}
                          optionLabel="name"
                          optionValue="value"
                          placeholder="Selecione o(s) assistente(s)"
                          filter
                          className="multiselect-custom w-full"
                          panelFooterTemplate={assistantPanelFooterTemplate}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditSchedule;
