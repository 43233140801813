import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

 interface IAttorneyGroupProperties {
  grupo_id: number | null;
  grupo_nome: string;
  grupo_valor: string;
}

const AttorneyGroup: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyAttorneyGroup = {
    grupo_id: null,
    grupo_nome: '',
    grupo_valor: '',
  };

  const [groups, setGroups] = useState<IAttorneyGroupProperties[]>([]);
  const [group, setGroup] = useState(emptyAttorneyGroup);
  const [deleteGroupDialog, setDeleteGroupDialog] = useState(false);
  const [deleteGroupsDialog, setDeleteGroupsDialog] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<IAttorneyGroupProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await api.get('/management/groups/show/all');
      setGroups(response.data);
    })();
  }, []);

  const columns = [
    { field: 'grupo_id', header: 'Código', sortable: true },
    { field: 'grupo_nome', header: 'Nome', sortable: true },
    { field: 'grupo_valor', header: 'Valor', sortable: true },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable
    />
  ));

  const handleCreate = () => {
    history.push('/management/group/create');
  };

  const hidedeleteGroupDialog = () => {
    setDeleteGroupDialog(false);
  };

  const hidedeleteGroupsDialog = () => {
    setDeleteGroupsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editGroup = (rowData:any) => {
    history.push(`/management/group/edit/${rowData.grupo_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteGroup = (group: React.SetStateAction<{
    grupo_id: null; grupo_nome: string; grupo_valor: string;
  }>) => {
    setGroup(group);
    setDeleteGroupDialog(true);
  };

  const deleteGroup = () => {
    api.delete(`management/groups/delete/${group.grupo_id}`);

    setDeleteGroupDialog(false);

    const updatedRecords = groups
      .filter((val) => val.grupo_id !== group.grupo_id);
    setGroups(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editGroup(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteGroup(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteGroupDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteGroupDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGroup} />
    </>
  );
  const deleteGroupsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteGroupsDialog} />
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Grupos de Advogados</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-groups table"
            id="table-groups"
            ref={dt}
            value={groups}
            editMode="row"
            dataKey="grupo_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedGroups}
            onSelectionChange={(e) => {
              const selAttorneyGroups = e.value as IAttorneyGroupProperties[];
              setSelectedGroups(selAttorneyGroups);
            }}
            onRowDoubleClick={(e) => editGroup(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteGroupDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteGroupDialogFooter} onHide={hidedeleteGroupDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {group && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{group.grupo_nome}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteGroupsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteGroupsDialogFooter} onHide={hidedeleteGroupsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {group
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default AttorneyGroup;
