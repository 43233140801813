/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

import { NavLink } from 'react-router-dom';
import {
  FaTachometerAlt, FaAddressCard, FaIdCardAlt, FaBalanceScale, FaPlus,
  FaRegFileExcel, FaList, FaSearch, FaGavel, FaBook, FaDesktop, FaClock,
  FaCalendarAlt, FaRegCalendar, FaRegCalendarPlus,
} from 'react-icons/fa';

import {
  MdSupervisedUserCircle, MdSettingsSuggest, MdMoney, MdMoving,
  MdAccessTime, MdOutlineDocumentScanner, MdMeetingRoom,
} from 'react-icons/md';

import sidebarBg from '../../assets/bg-sidebar.jpg';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Aside = ({
  image, collapsed, toggled, handleToggleSidebar,
}) => {
  let loggedUserLevel = 0;
  let reportiv = 0;
  let reportv = 0;

  const user = localStorage.getItem('@Jurisnet:user');
  const userData = JSON.parse(user);
  const userAccessInfo = JSON.parse(userData.access_info);

  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem('@Jurisnet:access');

  if (typeof accessData === 'string' && !userAccessInfo) {
    const access = JSON.parse(accessData);
    loggedUserLevel = Number(access.menu);
    reportiv = Number(access.permissaoiv);
    reportv = Number(access.permissaov);
  }

  if (userAccessInfo) {
    loggedUserLevel = Number(userAccessInfo.menu);
    reportiv = Number(userAccessInfo.permissaoiv);
    reportv = Number(userAccessInfo.permissaov);
  }

  const adminId = 1;

  return (
    <>
      <ProSidebar
        image={image ? sidebarBg : false}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader icon={<FaBalanceScale />}>
          <div
            style={{
              padding: '24px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            JN 2.0
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {loggedUserLevel === 5 ? (
              <MenuItem icon={<FaDesktop />}>
                <NavLink to="/dashboard" exact activeClassName="active">
                  Dashboard
                </NavLink>
              </MenuItem>
            ) : null}
            <SubMenu
              title="Processos"
              icon={<FaGavel />}
            >
              <MenuItem icon={<FaSearch />}>
                <NavLink to="/lawsuit/main/search" exact activeClassName="active">
                  Consultar processos
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaPlus />}>
                <NavLink to="/lawsuit/main/create" exact activeClassName="active">
                  Novo processo
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaGavel />}>
                <NavLink to="/lawsuit/main" exact activeClassName="active">
                  Listar todos processos
                </NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Agenda"
              icon={<FaRegCalendar />}
            >
              <MenuItem icon={<FaSearch />}>
                <NavLink to="/schedule/search" exact activeClassName="active">
                  Consultar agendamentos
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaRegCalendarPlus />}>
                <NavLink to="/schedule/create" exact activeClassName="active">
                  Novo agendamento
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaRegCalendar />}>
                <NavLink to="/schedule/myschedule" exact activeClassName="active">
                  Listar
                  {loggedUserLevel === adminId ? ' ' : ' meus '}
                  {loggedUserLevel === adminId ? null : <br /> }
                  agendamentos
                </NavLink>
              </MenuItem>
              <MenuItem icon={<MdMeetingRoom />}>
                <NavLink to="/schedule/meeting/room" exact activeClassName="active">
                  Gerenciar locais/salas
                </NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Horas"
              icon={<FaClock />}
            >
              <MenuItem icon={<FaPlus />}>
                <NavLink to="/management/workinghour/create" exact activeClassName="active">
                  Cadastrar hora
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaSearch />}>
                <NavLink to="/management/workinghour/search" exact activeClassName="active">
                  Consultar
                  {loggedUserLevel === adminId ? ' ' : ' minhas '}
                  {loggedUserLevel === adminId ? null : <br /> }
                  horas
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaSearch />}>
                <NavLink to="/management/workinghour/pendingconclusion" exact activeClassName="active">
                  Listar horas
                  <br />
                  pendentes
                  <br />
                  de finalização
                </NavLink>
              </MenuItem>
              <MenuItem icon={<MdAccessTime />}>
                <NavLink to="/lawsuit/reference" exact activeClassName="active">
                  Referência de horas
                </NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Apoio Processos"
              icon={<FaBook />}
            >
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/legalrequest" exact activeClassName="active">
                  Descrição do pedido
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/costdeposit" exact activeClassName="active">
                  Natureza de custos
                  <br />
                  e depósitos
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/intervention" exact activeClassName="active">
                  Natureza da intervenção
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/naturesuit" exact activeClassName="active">
                  Natureza do processo
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/judgingbody" exact activeClassName="active">
                  Órgão julgador
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/procedure" exact activeClassName="active">
                  Procedimento
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/result" exact activeClassName="active">
                  Resultado
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/suittype" exact activeClassName="active">
                  Tipo de ação
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaBook />}>
                <NavLink to="/lawsuit/linkedlawsuittype" exact activeClassName="active">
                  Tipo de processo
                  <br />
                  vinculado
                </NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Financeiro"
              icon={<MdMoney />}
            >
              <MenuItem icon={<MdMoving />}>
                <NavLink to="/finance/financialindex" exact activeClassName="active">
                  Índices financeiros
                </NavLink>
              </MenuItem>
              <MenuItem icon={<MdMoving />}>
                <NavLink to="/finance/indexer" exact activeClassName="active">
                  Indexador
                </NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Cadastros gestão"
              icon={<FaIdCardAlt />}
            >
              {loggedUserLevel === adminId ? (
                <>
                  <MenuItem icon={<FaAddressCard />}>
                    <NavLink to="/management/attorney" exact activeClassName="active">
                      Advogados
                    </NavLink>
                  </MenuItem>
                  <MenuItem icon={<FaAddressCard />}>
                    <NavLink to="/management/administrator" exact activeClassName="active">
                      Administradores
                    </NavLink>
                  </MenuItem>
                  <MenuItem icon={<FaAddressCard />}>
                    <NavLink to="/management/assistant" exact activeClassName="active">
                      Assistentes /
                      <br />
                      estagiários
                    </NavLink>
                  </MenuItem>
                </>
              ) : null}
              <MenuItem icon={<FaAddressCard />}>
                <NavLink to="/customer" exact activeClassName="active">
                  Clientes
                </NavLink>
              </MenuItem>
              {loggedUserLevel === adminId ? (
                <>
                  <MenuItem icon={<FaAddressCard />}>
                    <NavLink to="/businessgroup" exact activeClassName="active">
                      Grupo econômico
                    </NavLink>
                  </MenuItem>
                  <MenuItem icon={<MdSupervisedUserCircle />}>
                    <NavLink to="/management/group" exact activeClassName="active">
                      Grupos
                    </NavLink>
                  </MenuItem>
                </>
              ) : null}
            </SubMenu>

            <SubMenu
              title="Relatórios"
              icon={<FaList />}
            >
              <MenuItem icon={<MdSettingsSuggest />}>
                <NavLink to="/management/reporthouremail/edit" exact activeClassName="active">
                  Config. Relatório horas
                  <br />
                  e-mail
                </NavLink>
              </MenuItem>
              <MenuItem icon={<MdOutlineDocumentScanner />}>
                <NavLink to="/report/lawsuit/summary/search" exact activeClassName="active">
                  Modelo I (Resumido)
                </NavLink>
              </MenuItem>
              {loggedUserLevel === adminId ? (
                <MenuItem icon={<MdOutlineDocumentScanner />}>
                  <NavLink to="/report/lawsuit/summary/customizable/search" exact activeClassName="active">
                    Modelo I (Grupo
                    <br />
                    econômico
                    <br />
                    customizável)
                  </NavLink>
                </MenuItem>
              ) : null}
              <MenuItem icon={<MdOutlineDocumentScanner />}>
                <NavLink to="/report/financialinformation/search" exact activeClassName="active">
                  Modelo II (Financeiro)
                </NavLink>
              </MenuItem>
              <MenuItem icon={<MdOutlineDocumentScanner />}>
                <NavLink to="/report/financialinformationmodIII/search" exact activeClassName="active">
                  Modelo III
                  <br />
                  (Andamentos)
                </NavLink>
              </MenuItem>
              {reportiv === 1 ? (
                <MenuItem icon={<MdOutlineDocumentScanner />}>
                  <NavLink to="/report/hour/general/search" exact activeClassName="active">
                    Modelo IV (Horas)
                  </NavLink>
                </MenuItem>
              ) : null}
              {reportv === 1 ? (
                <MenuItem icon={<MdOutlineDocumentScanner />}>
                  <NavLink to="/report/hour/customer/search" exact activeClassName="active">
                    Modelo V (Horas por
                    <br />
                    Cliente)
                  </NavLink>
                </MenuItem>
              ) : null}
              {loggedUserLevel === adminId ? (
                <>
                  <MenuItem icon={<MdOutlineDocumentScanner />}>
                    <NavLink to="/report/hour/billed/search" exact activeClassName="active">
                      Modelo VI (Horas
                      <br />
                      Faturadas)
                    </NavLink>
                  </MenuItem>
                  <MenuItem icon={<MdOutlineDocumentScanner />}>
                    <NavLink to="/report/hour/attorney/analytical/search" exact activeClassName="active">
                      Modelo VII (Horas por
                      <br />
                      Profissional)
                    </NavLink>
                  </MenuItem>
                  <MenuItem icon={<MdOutlineDocumentScanner />}>
                    <NavLink to="/report/hour/attorney/simplified/search" exact activeClassName="active">
                      Modelo VIII
                      <br />
                      (Horas Totais
                      <br />
                      por Profissionais)
                    </NavLink>
                  </MenuItem>
                  <MenuItem icon={<MdOutlineDocumentScanner />}>
                    <NavLink to="/report/hour/percentage/search" exact activeClassName="active">
                      Modelo IX
                      <br />
                      (Percentuais de Horas)
                    </NavLink>
                  </MenuItem>
                </>
              ) : null}
              <MenuItem icon={<FaRegFileExcel />}>
                <NavLink to="/report/lawsuit/simplified/excel/search" exact activeClassName="active">
                  Exportar para Excel
                </NavLink>
              </MenuItem>
              <MenuItem icon={<FaRegFileExcel />}>
                <NavLink to="/report/lawsuit/grouped/excel/search" exact activeClassName="active">
                  Exportar para Excel
                  <br />
                  (Soma processos por
                  <br />
                  setor)
                </NavLink>
              </MenuItem>

            </SubMenu>
            {loggedUserLevel === adminId ? (
              <MenuItem icon={<FaTachometerAlt />}>
                <NavLink to="/management/search" exact activeClassName="active">
                  Gerencial
                </NavLink>
              </MenuItem>
            ) : null }
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: 'center' }} icon={<FaCalendarAlt />}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 24px',
            }}
          >
            <a
              href="/dashboard"
              target="_blank"
              className="sidebar-btn"
              rel="noopener noreferrer"
            >
              <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                Agenda
              </span>
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};
export default Aside;
