import React, { useEffect, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';

import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from '../styles';

interface IFinancialCostDeposit {
  dep_cus_proc_id: number;
  dep_cus_data: Date | undefined;
  dep_cus_cod_natureza: number;
  dep_cus_valor: number;
  dep_cus_classificacao: number;
}

const AddEditDepositInCourt:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');

  const returnLawsuitURL = `/lawsuit/main/edit/${cod}?active=2`;

  const initialValues: IFinancialCostDeposit = {
    dep_cus_proc_id: Number(cod),
    dep_cus_data: undefined,
    dep_cus_cod_natureza: 0,
    dep_cus_valor: 0,
    dep_cus_classificacao: 2,
  };

  const [costsDepositsOptions, setCostsDepositsOptions] = useState([{}]);
  const [financialCostsDepositsData, setFinancialCostsDepositsData] = useState(null);
  const [costsDepositsId, setCostsDepositsId] = useState(0);
  const [lawsuitFeeDate, setLawsuitFeeDate] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        await api.get(`/lawsuits/financialcostdeposits/show/${id}`).then((response) => {
          setFinancialCostsDepositsData(response.data);

          const lawsuitFeeDateBR = new Date(response.data.dep_cus_data);

          if (response.data.dep_cus_data !== null) {
            const lawsuitFeeDateISO = new Date(lawsuitFeeDateBR
              .toISOString()
              .slice(0, -1));
            if (lawsuitFeeDateISO.toString() === '01/01/1970') {
              setLawsuitFeeDate(undefined);
            }
            setLawsuitFeeDate(lawsuitFeeDateISO);
            setCostsDepositsId(response.data.dep_cus_cod_natureza);
          }
        });
      }
    })();
  }, [id, costsDepositsId, isAddMode]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/costsdeposits/show/all').then((response) => {
        response.data.map(
          (costdeposit: {
            natureza_id: number,
            natureza_descricao:string
          }) => (
            costsDepositsOptions.push({
              name: costdeposit.natureza_descricao,
              value: costdeposit.natureza_id,
            })
          ),
        );
      });
      costsDepositsOptions.splice(0, 1);
      setCostsDepositsOptions(costsDepositsOptions);
    })();
  }, [costsDepositsOptions]);

  const handleOnSubmit = async (
    values: IFinancialCostDeposit,
    { setSubmitting }: FormikHelpers<IFinancialCostDeposit>,
  ) => {
    if (isAddMode) {
      try {
        const responseFinancialItems = await api.get(`/lawsuits/financialitems/show/filtered/${cod}`);

        if (responseFinancialItems.data.length === 0) {
          // add financial item to indicate requests exist
          await api.post('/lawsuits/financialitems/create', values);
        }

        await api.post('/lawsuits/financialcostdeposits/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        await api.put(`/lawsuits/financialcostdeposits/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
                'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            Depósitos Judiciais efetuados pelo Cliente
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          enableReinitialize
          initialValues={financialCostsDepositsData || initialValues}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid">

                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-3">
                      <label htmlFor="dep_cus_data">
                        Data
                        <Calendar
                          inputId="dep_cus_data"
                          name="dep_cus_data"
                          className="inputfield w-full"
                          value={
                                    isAddMode
                                      ? undefined
                                      : lawsuitFeeDate
                                  }
                          onChange={handleChange}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-6">
                      <label htmlFor="dep_cus_cod_natureza">
                        Natureza
                      </label>
                      <Dropdown
                        id="dep_cus_cod_natureza"
                        name="dep_cus_cod_natureza"
                        value={values.dep_cus_cod_natureza}
                        options={costsDepositsOptions}
                        onChange={(e) => setFieldValue('dep_cus_cod_natureza', e.value)}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                      <ErrorMessage name="dep_cus_cod_natureza">
                        { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                      </ErrorMessage>
                    </div>
                    <div className="col-12 md:col-3">
                      <label htmlFor="dep_cus_valor">
                        Valor
                      </label>
                      <InputNumber
                        id="dep_cus_valor"
                        name="dep_cus_valor"
                        className="inputfield w-full"
                        value={values.dep_cus_valor}
                        onValueChange={handleChange}
                        placeholder="0,00"
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        locale="pt-BR"
                      />
                    </div>

                  </div>
                </div>

                <div className="col-12 p-mt-20">
                  <div className="col-12">
                    Todos os campos marcados com * são de preenchimento obrigatório.
                  </div>
                  <header />
                  <div className="flex align-content-center justify-content-center field">

                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Enviar dados"
                      className="p-mr-2"
                      type="submit"
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                    <Button
                      icon="pi pi-times"
                      iconPos="left"
                      label="Cancelar e voltar"
                      className="p-button-danger"
                      type="button"
                      onClick={handleCancel}
                    />

                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditDepositInCourt;
