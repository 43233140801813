/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";

import { Link, useHistory, useParams } from "react-router-dom";

import { nanoid } from "nanoid";

import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { Container } from "./styles";

import { useAuth } from "../../../hooks/auth";
import FinancialItems from "../FinancialItem";

interface ILawsuitFormProperties {
  proc_reg: string;
  proc_num_pasta: string;
  proc_cod_acao: number;
  proc_cod_orgao: number;
  proc_cod_procedimento: number;
  proc_data_ajuiz: Date;
  proc_data_ingresso: Date;
  proc_cod_cliente: number;
  proc_cod_adv_resp: number;
  proc_adv_adversa: string;
  proc_url: string;
  proc_terceiros: string;
  proc_cod_nat_interv: number;
  proc_reg_outros: string;
  proc_orgao_cidade: string;
  proc_orgao_uf: string;
  proc_autor: string;
  proc_reu: string;
  proc_assunto: string;
  proc_ult_atu: Date;
  proc_localizacao: string;
  proc_resumo: string;
  proc_cod_natureza: number;
  proc_situacao: string;
  proc_ult_mov_rel: Date;
  proc_em_acordo: number;
  proc_cod_ass_resp: number;
  proc_sta_gerencial: string;
  proc_observacao: string;
  status_lib_morto: string;
  obs_final_financeiro: string;
  status_liberacao: string;
  proc_tipo_processo: string;
  proc_pgto_ato: string;
  proc_cobrar_por_hora: string | null;
}

interface ILawsuitProgressProperties {
  and_id: number | null;
  and_proc_id: number;
  and_data: Date;
  and_andamento: string;
  and_arquivo: string;
  and_obs: string;
  and_enviado: boolean;
  and_exibir: boolean;
  and_dat_alt: Date;
  id_adv_alt: number;
  nivelusuario: string;
}

interface IDeadlineProperties {
  prazo_id: number | null;
  prazo_proc_id: number;
  prazo_data: Date;
  prazo_ativo: boolean;
  prazo_hora: Date;
  prazo_cod_adv_resp: string;
  prazo_email: string;
  prazo_cod_ass_resp: string;
  prazo_providencia: string;
  prazo_descr_providencia: string;
}

interface ILinkedLawsuitProperties {
  proc_vinc_id: number | null;
  proc_vinc_reg: string;
  proc_vinc_reg_outros: string;
  proc_vinc_principal_id: number;
  proc_vinc_num_pasta: string;
  proc_vinc_cod_tipo: number;
  proc_vinc_cod_orgao: number;
  proc_vinc_orgao_cidade: string;
  proc_vinc_orgao_uf: string;
  proc_vinc_url: string;
  proc_vinc_polo_ativo: string;
  proc_vinc_polo_passivo: string;
  proc_vinc_arquivado: boolean;
}

interface ILinkedLawsuitTypesProperties {
  proc_vinc_tipo_id: number | null;
  proc_vinc_tipo_descricao: string;
}

interface IAttorneyProperties {
  advogado_id: number | null;
  advogado_nome: string;
  advogado_login: string;
  advogado_senha: string;
  advogado_email: string;
  advogado_oab: string;
  advogado_categoria: number;
  advogado_ativo: boolean;
  advogado_nasc: Date;
  advogado_naturalidade: string;
  advogado_end_residencial: string;
  advogado_end_residencial_compl: string;
  advogado_fone_residencial: string;
  advogado_fone_celular: string;
  advogado_conjuge_nome: string;
  advogado_conjuge_nasc: Date;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adv: number;
  advogado_valor: string;
  advogado_setor: string;
}

interface IAdministratorProperties {
  administrador_id?: number;
  administrador_nome: string;
  administrador_login: string;
  administrador_senha: string;
  administrador_email: string;
  administrador_categoria: number;
  administrador_ativo: boolean;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adm: number;
  valor_adm: string;
  administrador_setor: string;
}

interface ICustomerProperties {
  cliente_id: number | null;
  cliente_nome: string;
  cliente_login: string;
  cliente_senha: string;
  cliente_email: string;
  cliente_contato: string;
  cliente_endereco: string;
  cliente_cidade: string;
  cliente_bairro: string;
  cliente_cep: string;
  cliente_uf: string;
  cliente_fone: string;
  cliente_fax: string;
  cliente_url: string;
  cliente_classificacao: number;
  cliente_categoria: number;
  cliente_ativo: boolean;
  cliente_tratamento: string;
  cliente_cnpj_cpf: string;
  cliente_naocliente: boolean;
  cliente_informativo: boolean;
}

interface IOutcomeProperties {
  proc_desfecho_id: number | null;
  proc_desfecho_cod_result: number;
  proc_desfecho_data: Date;
  proc_desfecho_obs: string;
  proc_desfecho_arquivo_morto: boolean;
  proc_desfecho_data_baixa: string;
  proc_desfecho_caixa_arq_morto: string;
  proc_desfecho_data_email: Date;
  proc_desfecho_cod_proc: number;
  proc_desfecho_cod_cliente: number;
  proc_desfecho_notificar_adv: boolean;
}

const AddEditLawsuit: React.FC = () => {
  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { user } = useAuth();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const active = params.get("active");
  const [activeIndex, setActiveIndex] = useState(0);
  const [maxFolderNumber, setMaxFolderNumber] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [originalNotesValue, setOriginalNotesValue] = useState("");

  const lawsuitSummaryHeader = useRef(null);

  useEffect(() => {
    if (active !== undefined || active !== null) {
      setActiveIndex(Number(active));
    }
  }, [active]);

  useEffect(() => {
    (async () => {
      if (isAddMode) {
        await api.get("/lawsuits/main/foldernumber/max").then((response) => {
          setMaxFolderNumber(response.data + 1);
        });
      }
    })();
  }, [isAddMode]);

  const [lawsuit, setLawsuit] = useState<ILawsuitFormProperties>();
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);
  const [notifyAttorney, setNotifyAttorney] = useState<boolean>(false);

  const initialValues: ILawsuitFormProperties = {
    proc_reg: "",
    proc_num_pasta: maxFolderNumber > 1 ? maxFolderNumber.toString() : "",
    proc_cod_acao: 0,
    proc_cod_orgao: 0,
    proc_cod_procedimento: 0,
    proc_data_ajuiz: new Date(0),
    proc_data_ingresso: new Date(0),
    proc_cod_cliente: 0,
    proc_cod_adv_resp: 0,
    proc_adv_adversa: "",
    proc_url: "",
    proc_terceiros: "",
    proc_cod_nat_interv: 0,
    proc_reg_outros: "",
    proc_orgao_cidade: "",
    proc_orgao_uf: "",
    proc_autor: "",
    proc_reu: "",
    proc_assunto: "",
    proc_ult_atu: new Date(),
    proc_localizacao: "",
    proc_resumo: "",
    proc_cod_natureza: 0,
    proc_situacao: "",
    proc_ult_mov_rel: new Date(0),
    proc_em_acordo: 0,
    proc_cod_ass_resp: 0,
    proc_sta_gerencial: "",
    proc_observacao: "",
    status_lib_morto: "N",
    obs_final_financeiro: "",
    status_liberacao: "N",
    proc_tipo_processo: "",
    proc_pgto_ato: "N",
    proc_cobrar_por_hora: null,
  };

  const emptyLawsuitProgress: ILawsuitProgressProperties = {
    and_id: null,
    and_proc_id: Number(id),
    and_data: new Date(),
    and_andamento: "",
    and_arquivo: "",
    and_obs: "",
    and_enviado: false,
    and_exibir: false,
    and_dat_alt: new Date(0),
    id_adv_alt: 0,
    nivelusuario: "",
  };

  const emptyDeadline: IDeadlineProperties = {
    prazo_id: null,
    prazo_proc_id: Number(id),
    prazo_data: new Date(),
    prazo_ativo: false,
    prazo_hora: new Date(),
    prazo_cod_adv_resp: "",
    prazo_email: "",
    prazo_cod_ass_resp: "",
    prazo_providencia: "",
    prazo_descr_providencia: "",
  };

  const emptyLinkedLawsuit: ILinkedLawsuitProperties = {
    proc_vinc_id: null,
    proc_vinc_reg: "",
    proc_vinc_reg_outros: "",
    proc_vinc_principal_id: 0,
    proc_vinc_num_pasta: "",
    proc_vinc_cod_tipo: 0,
    proc_vinc_cod_orgao: 0,
    proc_vinc_orgao_cidade: "",
    proc_vinc_orgao_uf: "",
    proc_vinc_url: "",
    proc_vinc_polo_ativo: "",
    proc_vinc_polo_passivo: "",
    proc_vinc_arquivado: false,
  };

  const emptyOutcome: IOutcomeProperties = {
    proc_desfecho_id: null,
    proc_desfecho_cod_result: 0,
    proc_desfecho_data: new Date(),
    proc_desfecho_obs: "",
    proc_desfecho_arquivo_morto: false,
    proc_desfecho_data_baixa: "",
    proc_desfecho_caixa_arq_morto: "",
    proc_desfecho_data_email: new Date(),
    proc_desfecho_cod_proc: Number(id),
    proc_desfecho_cod_cliente: 0,
    proc_desfecho_notificar_adv: true,
  };

  const [lawsuitOutcomeResultsOptions, setLawsuitOutcomeResultsOptions] =
    useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/results/show/all").then((response) => {
        response.data.map(
          (lawsuitOutcomeResult: {
            resultado_id: number;
            resultado_descricao: string;
          }) =>
            lawsuitOutcomeResultsOptions.push({
              name: lawsuitOutcomeResult.resultado_descricao,
              value: lawsuitOutcomeResult.resultado_id,
            })
        );
      });
      lawsuitOutcomeResultsOptions.splice(0, 1);
      setLawsuitOutcomeResultsOptions(lawsuitOutcomeResultsOptions);
    })();
  }, [lawsuitOutcomeResultsOptions]);

  const options = [
    { name: "Sim", value: "S" },
    { name: "Não", value: "N" },
  ];

  const optionsManagementSTA = [
    { name: "Sim", value: "1" },
    { name: "Não", value: "0" },
  ];

  const lawsuitStatusOptions = [
    { name: "Ativo", value: "0" },
    { name: "Acordo", value: "1" },
    { name: "Arquivado", value: "2" },
  ];

  const optionsBoolean = [
    { name: "Sim", value: true },
    { name: "Não", value: false },
  ];

  const [natureSuitsOptions, setNatureSuitsOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/naturesuits/show/all").then((response) => {
        response.data.map(
          (natureSuit: { natureza_id: number; natureza_descricao: string }) =>
            natureSuitsOptions.push({
              name: natureSuit.natureza_descricao,
              value: natureSuit.natureza_id,
            })
        );
      });
      natureSuitsOptions.splice(0, 1);
      setNatureSuitsOptions(natureSuitsOptions);
    })();
  }, [natureSuitsOptions]);

  const [suitTypesOptions, setSuitTypesOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/suittypes/show/all").then((response) => {
        response.data.map(
          (natureSuit: { acao_id: number; acao_descricao: string }) =>
            suitTypesOptions.push({
              name: natureSuit.acao_descricao,
              value: natureSuit.acao_id,
            })
        );
      });
      suitTypesOptions.splice(0, 1);
      setSuitTypesOptions(suitTypesOptions);
    })();
  }, [suitTypesOptions]);

  const brazilianStatesOptions = [
    { name: "AC", value: "AC" },
    { name: "AL", value: "AL" },
    { name: "AM", value: "AM" },
    { name: "AP", value: "AP" },
    { name: "BA", value: "BA" },
    { name: "CE", value: "CE" },
    { name: "DF", value: "DF" },
    { name: "ES", value: "ES" },
    { name: "GO", value: "GO" },
    { name: "MA", value: "MA" },
    { name: "MG", value: "MG" },
    { name: "MS", value: "MS" },
    { name: "MT", value: "MT" },
    { name: "PA", value: "PA" },
    { name: "PB", value: "PB" },
    { name: "PE", value: "PE" },
    { name: "PI", value: "PI" },
    { name: "PR", value: "PR" },
    { name: "RJ", value: "RJ" },
    { name: "RN", value: "RN" },
    { name: "RO", value: "RO" },
    { name: "RR", value: "RR" },
    { name: "RS", value: "RS" },
    { name: "SC", value: "SC" },
    { name: "SE", value: "SE" },
    { name: "SP", value: "SP" },
    { name: "TO", value: "TO" },
  ];

  const [judgingBodiesOptions, setJudgingBodiesOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/judgingbodies/show/all").then((response) => {
        response.data.map(
          (judgingBody: { orgao_id: number; orgao_descricao: string }) =>
            judgingBodiesOptions.push({
              name: judgingBody.orgao_descricao,
              value: judgingBody.orgao_id,
            })
        );
      });
      judgingBodiesOptions.splice(0, 1);
      setJudgingBodiesOptions(judgingBodiesOptions);
    })();
  }, [judgingBodiesOptions]);

  const [interventionsOptions, setInterventionsOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/interventions/show/all").then((response) => {
        response.data.map(
          (intervention: {
            natureza_interv_id: number;
            natureza_interv_descricao: string;
          }) =>
            interventionsOptions.push({
              name: intervention.natureza_interv_descricao,
              value: intervention.natureza_interv_id,
            })
        );
      });
      interventionsOptions.splice(0, 1);
      setInterventionsOptions(interventionsOptions);
    })();
  }, [interventionsOptions]);

  const [customers, setCustomers] = useState<ICustomerProperties[]>([]);
  const [customersOptions, setCustomersOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("customers/show/all").then((response) => {
        setCustomers(response.data);
        response.data.map(
          (customer: { cliente_id: number; cliente_nome: string }) =>
            customersOptions.push({
              name: customer.cliente_nome,
              value: customer.cliente_id,
            })
        );
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  const [proceduresOptions, setProceduresOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/procedures/show/all").then((response) => {
        response.data.map(
          (procedure: { proced_id: number; proced_descricao: string }) =>
            proceduresOptions.push({
              name: procedure.proced_descricao,
              value: procedure.proced_id,
            })
        );
      });
      proceduresOptions.splice(0, 1);
      setProceduresOptions(proceduresOptions);
    })();
  }, [proceduresOptions]);

  const [attorneys, setAttorneys] = useState<IAttorneyProperties[]>([]);
  const [attorneysOptions, setAttorneysOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("management/attorneys/show/all").then((response) => {
        setAttorneys(response.data);
        response.data.map(
          (attorney: { advogado_id: number; advogado_nome: string }) =>
            attorneysOptions.push({
              name: attorney.advogado_nome,
              value: attorney.advogado_id,
            })
        );
      });
      attorneysOptions.splice(0, 1);
      setAttorneysOptions(attorneysOptions);
    })();
  }, [attorneysOptions]);

  const [assistants, setAssistants] = useState<IAdministratorProperties[]>([]);
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("management/assistants/show/all").then((response) => {
        setAssistants(response.data);
        response.data.map(
          (assistant: {
            administrador_id: number;
            administrador_nome: string;
          }) =>
            assistantsOptions.push({
              name: assistant.administrador_nome,
              value: assistant.administrador_id,
            })
        );
      });
      assistantsOptions.splice(0, 1);
      setAssistantsOptions(assistantsOptions);
    })();
  }, [assistantsOptions]);

  const [administrators, setAdministrators] = useState<
    IAdministratorProperties[]
  >([]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/management/administrators/show/all");
      setAdministrators(response.data);
    })();
  }, []);

  const validationSchema = Yup.object().shape({
    proc_assunto: Yup.string().required("Descrição obrigatória"),
    proc_cod_cliente: Yup.number()
      .min(1, "Selecione o cliente")
      .required("Selecione o cliente"),
    proc_cod_adv_resp: Yup.number()
      .min(1, "Selecione o advogado responsável")
      .required("Selecione o advogado responsável"),
    proc_cod_natureza: Yup.number()
      .min(1, "Selecione a natureza do processo")
      .required("Selecione a natureza do processo"),
    proc_cod_acao: Yup.number()
      .min(1, "Selecione o tipo de ação")
      .required("Selecione o tipo de ação"),
    proc_pgto_ato: Yup.string()
      .required()
      .oneOf(["S", "N"])
      .label("Selecione ao menos uma opção"),
  });

  const handleOnSubmit = async (
    values: ILawsuitFormProperties,
    { setSubmitting }: FormikHelpers<ILawsuitFormProperties>
  ) => {
    const management = localStorage.getItem("@Jurisnet:management");
    let isManagement = false;
    if (management) {
      isManagement = true;
    }

    if (isAddMode) {
      try {
        await api.post("/lawsuits/main/create", values).then((response) => {
          setSubmitting(false);

          let customerName = "";
          let attorneyName = "";

          const filteredCustomer = customers.filter(
            (item) => item.cliente_id === response.data.proc_cod_cliente,
            []
          );

          if (filteredCustomer.length < 0) {
            addToast({
              type: "error",
              title: "Ocorreu um problema",
              description:
                "O cliente não possui e-mail válido ou e-mail é inexistente.",
            });
            setNotifyCustomer(false);
          }
          customerName =
            filteredCustomer[0]?.cliente_nome.length > 0
              ? filteredCustomer[0]?.cliente_nome
              : "";

          const filteredAttorney = attorneys.filter(
            (item) => item.advogado_id === response.data.proc_cod_adv_resp,
            []
          );

          attorneyName = filteredAttorney[0].advogado_nome;

          const lawsuitID = response.data.proc_id;

          api.post("/lawsuits/main/sendmail", {
            lawsuitID,
            customerName,
            attorneyName,
          });

          addToast({
            type: "success",
            title: "E-mail(s) enviado(s) com sucesso",
            description:
              "O(s) e-mails selecionados para envio foram processados com sucesso.",
          });
        });

        addToast({
          type: "success",
          title: "Cadastro atualizado!",
          description:
            "As informações do cadastro foram atualizadas com sucesso.",
        });

        if (isManagement) {
          history.push("/management/list");
        } else {
          history.push("/lawsuit/main");
        }
      } catch (error) {
        addToast({
          type: "error",
          title: "Ocorreu um problema",
          description: "Tente repetir o cadastro ou contate o administrador.",
        });
      }
    } else {
      try {
        api.put(`/lawsuits/main/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: "success",
          title: "Cadastro atualizado!",
          description:
            "As informações do cadastro foram atualizadas com sucesso.",
        });
        toast.current?.show({
          severity: "success",
          summary: "Cadastro atualizado com sucesso",
          detail: "As informações do cadastro foram atualizadas com sucesso.",
          life: 3000,
        });
        if (isManagement) {
          history.push("/management/list");
        } else {
          history.push(`/lawsuit/main/edit/${id}?active=${activeIndex}`);
        }
      } catch (error) {
        addToast({
          type: "error",
          title: "Ocorreu um problema",
          description: "Tente repetir o cadastro ou contate o administrador.",
        });
      }
    }
  };

  const handleLawsuitDateUpdate = async () => {
    await api
      .patch(`/lawsuits/main/updatelawsuitdate/${id}`)
      .then((response) => {
        if (response.data !== false) {
          toast.current?.show({
            severity: "success",
            summary: "Data atualizada sucesso",
            detail: "A data de atualização foi alterada com sucesso.",
            life: 3000,
          });
        }
      });
  };

  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);

  const [lawsuitProgresses, setLawsuitProgresses] = useState<
    ILawsuitProgressProperties[]
  >([]);
  const [lawsuitProgress, setLawsuitProgress] = useState(emptyLawsuitProgress);
  const [deleteLawsuitProgress, setDeleteLawsuitProgress] = useState(false);
  const [deleteLawsuitProgresssDialog, setDeleteLawsuitProgresss] =
    useState(false);
  const [selectedLawsuitsProgresses, setSelectedLawsuitsProgresses] = useState<
    ILawsuitProgressProperties[]
  >([]);
  const [otherEmails, setOtherEmails] = useState("");
  const [extraInformation, setExtraInformation] = useState("");

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        const response = await api.get(
          `/lawsuits/progresses/show/filtered/${id}`
        );
        setLawsuitProgresses(response.data);
      }
    })();
  }, [id, isAddMode]);

  const columns = [
    { field: "and_data", header: "Data" },
    { field: "and_andamento", header: "Andamento" },
    { field: "and_id", header: "Enviar inf." },
    { field: "and_enviado", header: "Status" },
  ];

  const dateBodyTemplate = (rowData: { and_data: Date }) => {
    const randomID = nanoid();

    if (lawsuitProgresses !== undefined) {
      const filteredRecord = lawsuitProgresses.filter(
        (item) => item.and_data === rowData.and_data,
        []
      );

      const dateInput = filteredRecord[0].and_data?.toString();
      const dateInput2 = Date.parse(filteredRecord[0].and_dat_alt?.toString());

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      });

      let formattedUpdatedDate = "";
      if (!Number.isNaN(dateInput2)) {
        const updatedDateBR = new Date(dateInput2);
        formattedUpdatedDate = updatedDateBR.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });

        let attorneyName = "";
        const userLevel = filteredRecord[0].nivelusuario;

        if (userLevel === "Administrador") {
          const filteredAdministrator = administrators.filter(
            (item) => item.administrador_id === filteredRecord[0].id_adv_alt,
            []
          );

          if (filteredAdministrator.length > 0) {
            attorneyName = `por ${filteredAdministrator[0]?.administrador_nome}`;
          }
        }
        if (userLevel === "Advogado") {
          const filteredAttorney = attorneys.filter(
            (item) => item.advogado_id === filteredRecord[0].id_adv_alt,
            []
          );

          if (filteredAttorney.length > 0) {
            attorneyName = `por ${filteredAttorney[0]?.advogado_nome}`;
          }
        }
        if (userLevel === "Assistente") {
          const filteredAssistant = assistants.filter(
            (item) => item.administrador_id === filteredRecord[0].id_adv_alt,
            []
          );

          if (filteredAssistant.length > 0) {
            attorneyName = `por ${filteredAssistant[0]?.administrador_nome}`;
          }
        }

        return (
          <>
            <Tooltip
              id={`tooltip-updated-${randomID}`}
              target=".dt-updated-progress"
              mouseTrack
              mouseTrackLeft={10}
              position="top"
            />
            <span
              className="dt-updated-progress"
              data-for={`tooltip-updated-${randomID}`}
              data-pr-tooltip={`Modificado em ${formattedUpdatedDate}
                              ${attorneyName}`}
            >
              {formattedDate}
            </span>
          </>
        );
      }

      return (
        <>
          <Tooltip
            id={`tooltip-nochanges-${randomID}`}
            target=".dt-nochanges"
            mouseTrack
            mouseTrackLeft={10}
            position="top"
          />
          <span
            className="dt-nochanges"
            data-for={`tooltip-nochanges-${randomID}`}
            data-pr-tooltip="Não houveram alterações"
          >
            {formattedDate}
          </span>
        </>
      );
    }
    return "";
  };

  const checkboxBodyTemplate = (rowData: { and_id: number }) => {
    if (lawsuitProgresses !== undefined) {
      <Checkbox
        inputId={`${rowData.and_id.toString()}`}
        name={`cb${rowData.and_id.toString()}`}
        value={selectedLawsuitsProgresses}
        checked={false}
      />;
    }
  };

  const statusBodyTemplate = (rowData: { and_enviado: boolean }) => {
    if (lawsuitProgresses !== undefined) {
      const filteredStatus = lawsuitProgresses.filter(
        (item) => item.and_enviado === rowData.and_enviado,
        []
      );

      if (filteredStatus[0]?.and_enviado === true) {
        return (
          <Button
            type="button"
            icon="pi pi-bell"
            className="p-button-rounded p-button-text p-button-plain p-button-success"
            tooltip="Enviado ao cliente"
            tooltipOptions={{ position: "left" }}
          />
        );
      }
      return (
        <Button
          type="button"
          icon="pi pi-bell"
          className="p-button-rounded p-button-text p-button-plain p-button-danger"
          tooltip="Não enviado ao cliente"
          tooltipOptions={{ position: "left" }}
        />
      );
    }
    return false;
  };

  const dynamicColumnsLawsuitProgress = columns.map((col) => {
    if (col.field === "and_enviado") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={statusBodyTemplate}
          sortable={false}
          headerStyle={{ width: "60px" }}
        />
      );
    }
    if (col.field === "and_data") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={dateBodyTemplate}
          sortable={false}
          headerStyle={{ width: "100px" }}
        />
      );
    }
    if (col.field === "and_id") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={checkboxBodyTemplate}
          selectionMode="multiple"
          sortable={false}
          headerStyle={{ width: "60px" }}
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={false}
      />
    );
  });

  const handleCreate = () => {
    history.push(`/lawsuit/progress/create?cod=${id}&active=3`);
  };

  const hidedeleteLawsuitProgress = () => {
    setDeleteLawsuitProgress(false);
  };

  const hidedeleteLawsuitProgressDialog = () => {
    setDeleteLawsuitProgresss(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLawsuitProgress = (rowData: any) => {
    history.push(`/lawsuit/progress/edit/${rowData.and_id}?cod=${id}&active=3`);
  };

  const handleNotificationEmails = async () => {
    try {
      if (selectedLawsuitsProgresses && selectedLawsuitsProgresses.length > 0) {
        let customerName = "";
        let customerEmail = "";
        let attorneyName = "";
        let attorneyEmail = "";

        if (notifyCustomer) {
          const filteredCustomer = customers.filter(
            (item) => item.cliente_id === lawsuit?.proc_cod_cliente,
            []
          );

          if (filteredCustomer.length < 0) {
            addToast({
              type: "error",
              title: "Ocorreu um problema",
              description:
                "O cliente não possui e-mail válido ou e-mail é inexistente.",
            });
            setNotifyCustomer(false);
          }
          customerName =
            filteredCustomer[0]?.cliente_nome.length > 0
              ? filteredCustomer[0]?.cliente_nome
              : "";
          customerEmail =
            filteredCustomer[0]?.cliente_email.length > 0
              ? filteredCustomer[0]?.cliente_email
              : "";
        }

        if (notifyAttorney) {
          const filteredAttorney = attorneys.filter(
            (item) => item.advogado_id === lawsuit?.proc_cod_adv_resp,
            []
          );

          attorneyName = filteredAttorney[0].advogado_nome;
          attorneyEmail = filteredAttorney[0].advogado_email;
        }

        const lawsuitID = id;
        let progressID = "";

        selectedLawsuitsProgresses.forEach((item) => {
          if (item && item.and_id !== null) {
            if (progressID === "") {
              progressID = item.and_id.toString();
            } else {
              progressID += `,${item.and_id.toString()}`;
            }
          }
        });

        let loggedUser = "";
        // const managementData = sessionStorage.getItem('@Jurisnet:access');
        const managementData = localStorage.getItem("@Jurisnet:access");

        if (typeof managementData === "string") {
          const data = JSON.parse(managementData);

          loggedUser = data.usuario_logado;
        }

        const formData = {
          lawsuitID: `${lawsuitID}`,
          customerName: `${customerName}`,
          customerEmail: `${customerEmail}`,
          attorneyName: `${attorneyName}`,
          attorneyEmail: `${attorneyEmail}`,
          otherEmails: `${otherEmails}`,
          extraInformation: `${extraInformation}`,
          progressID: `${progressID}`,
          loggedUser: `${loggedUser}`,
        };

        await api.post("/lawsuits/progresses/sendmail", formData).then(() => {
          api
            .get(`/lawsuits/progresses/show/filtered/${id}`)
            .then((response) => {
              setLawsuitProgresses(response.data);
            });

          addToast({
            type: "success",
            title: "E-mail(s) enviado(s) com sucesso",
            description:
              "O(s) e-mails selecionados para envio foram processados com sucesso.",
          });
          toast.current?.show({
            severity: "success",
            summary: "E-mail(s) enviado(s) com sucesso",
            detail:
              "O(s) e-mails selecionados para envio foram processados com sucesso.",
            life: 3000,
          });
        });
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Nenhum andamento selecionado",
          detail:
            "Selecione ao menos um andamento de processo para ser enviado!",
          life: 3000,
        });
      }
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro ao enviar o email",
        description: "Ocorreu um erro ao enviar o e-mail. Tente novamente.",
      });
    }
  };

  const changeVisibilityLawsuitProgress = (rowData: {
    and_id: number;
    and_exibir: boolean;
  }) => {
    (async () => {
      await api.patch(
        `/lawsuits/progresses/patch/${rowData.and_id}/${rowData.and_exibir}`
      );

      const response = await api.get(
        `/lawsuits/progresses/show/filtered/${id}`
      );
      setLawsuitProgresses(response.data);
    })();
  };

  const handleFileOpen = (rowData: { and_arquivo: string }) => {
    (async () => {
      window.open(`${rowData.and_arquivo}`, "_blank");
    })();
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmdeleteLawsuitProgress = (
    lawsuitProgress: React.SetStateAction<ILawsuitProgressProperties>
  ) => {
    setLawsuitProgress(lawsuitProgress);
    setDeleteLawsuitProgress(true);
  };

  const deleteGroup = () => {
    api.delete(`/lawsuits/progresses/delete/${lawsuitProgress.and_id}`);

    setDeleteLawsuitProgress(false);

    const updatedRecords = lawsuitProgresses.filter(
      (val) => val.and_id !== lawsuitProgress.and_id
    );
    setLawsuitProgresses(updatedRecords);

    addToast({
      type: "success",
      title: "Cadastro excluído!",
      description: "O cadastro selecionado foi excluído com sucesso!",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => {
          editLawsuitProgress(rowData);
        }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: "left" }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => {
          confirmdeleteLawsuitProgress(rowData);
        }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: "red-tooltip", position: "left" }}
      />
      <Button
        type="button"
        icon={rowData.and_exibir === true ? "pi pi-eye" : "pi pi-eye-slash"}
        className={
          rowData.and_exibir === true
            ? "p-button-rounded p-button-text p-button-plain p-button-success p-mr-2"
            : "p-button-rounded p-button-text p-button-plain p-button-danger p-mr-2"
        }
        onClick={() => {
          changeVisibilityLawsuitProgress(rowData);
        }}
        tooltip={
          rowData.and_exibir === true
            ? "Tornar indisponível ao cliente"
            : "Tornar disponível ao cliente"
        }
        tooltipOptions={{ position: "left" }}
      />
      {rowData.and_arquivo.length > 2 ? (
        <Button
          type="button"
          icon="pi pi-file-pdf"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => {
            handleFileOpen(rowData);
          }}
          tooltip="Abrir arquivo remotamente"
          tooltipOptions={{ position: "left" }}
        />
      ) : (
        ""
      )}
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: "right" }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar na tabela"
        />
      </span>
    </div>
  );

  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );

  const deleteLawsuitProgressFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteLawsuitProgress}
      />
      <Button
        type="button"
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteGroup}
      />
    </>
  );
  const deleteLawsuitProgresssDialogFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteLawsuitProgressDialog}
      />
    </>
  );

  // functions added to table for deadlines
  const [globalFilterDeadline, setGlobalFilterDeadline] = useState("");
  const dtDeadline = useRef(null);

  const [deadlines, setDeadlines] = useState<IDeadlineProperties[]>([]);
  const [deadline, setDeadline] = useState(emptyDeadline);
  const [deleteDeadlineDialog, setDeleteDeadlineDialog] = useState(false);
  const [deleteDeadlinesDialog, setDeleteDeadlinesDialog] = useState(false);
  const [selectedDeadlines, setSelectedDeadlines] = useState<
    IDeadlineProperties[]
  >([]);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        const response = await api.get(
          `/lawsuits/deadlines/show/filtered/${id}`
        );
        setDeadlines(response.data);
      }
    })();
  }, [id, isAddMode]);

  const columnsDeadlines = [
    { field: "prazo_data", header: "Data" },
    { field: "prazo_hora", header: "Hora" },
    { field: "prazo_providencia", header: "Providência" },
  ];

  const dateBodyTemplateDeadlines = (rowData: { prazo_data: Date }) => {
    if (deadlines !== undefined) {
      const filteredDate = deadlines.filter(
        (item) => item.prazo_data === rowData.prazo_data,
        []
      );

      if (
        filteredDate[0]?.prazo_data !== null &&
        filteredDate[0]?.prazo_data.toString() !== "01/01/1970" &&
        filteredDate[0]?.prazo_data.toString() !== "31/12/1969"
      ) {
        const dateInput = filteredDate[0]?.prazo_data;

        const dateBR = new Date(dateInput);
        const formattedDate = dateBR.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });

        return formattedDate;
      }
      return "";
    }
    return "";
  };

  const timeBodyTemplateDeadlines = (rowData: { prazo_hora: Date }) => {
    if (deadlines !== undefined) {
      const filteredTime = deadlines.filter(
        (item) => item.prazo_hora === rowData.prazo_hora,
        []
      );
      if (filteredTime[0]?.prazo_hora !== null) {
        const timeInput = filteredTime[0]?.prazo_hora;

        const timeBR = new Date(timeInput);
        const formattedTime = timeBR.toLocaleTimeString("pt-BR", {
          timeZone: "UTC",
        });

        return formattedTime;
      }
      return "";
    }
    return "";
  };

  const dynamicColumnsDeadlines = columnsDeadlines.map((col) => {
    if (col.field === "prazo_data") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={dateBodyTemplateDeadlines}
          sortable={false}
          headerStyle={{ width: "100px" }}
        />
      );
    }
    if (col.field === "prazo_hora") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={timeBodyTemplateDeadlines}
          sortable={false}
          headerStyle={{ width: "100px" }}
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={false}
      />
    );
  });

  const handleCreateDeadline = () => {
    history.push(`/lawsuit/deadline/create?cod=${id}&active=4`);
  };

  const hidedeleteDeadline = () => {
    setDeleteDeadlineDialog(false);
  };

  const hidedeleteDeadlineDialog = () => {
    setDeleteDeadlinesDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editDeadline = (rowData: any) => {
    history.push(
      `/lawsuit/deadline/edit/${rowData.prazo_id}?cod=${id}&active=4`
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmdeleteDeadline = (
    deadline: React.SetStateAction<IDeadlineProperties>
  ) => {
    setDeadline(deadline);
    setDeleteDeadlineDialog(true);
  };

  const deleteDeadline = () => {
    api.delete(`/lawsuits/deadlines/delete/${deadline.prazo_id}`);

    setDeleteDeadlineDialog(false);

    const updatedRecords = deadlines.filter(
      (val) => val.prazo_id !== deadline.prazo_id
    );
    setDeadlines(updatedRecords);

    addToast({
      type: "success",
      title: "Cadastro excluído!",
      description: "O cadastro selecionado foi excluído com sucesso!",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyDeadlineTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => {
          editDeadline(rowData);
        }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: "left" }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => {
          confirmdeleteDeadline(rowData);
        }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: "red-tooltip", position: "left" }}
      />
    </>
  );

  const headerDeadline = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreateDeadline}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: "right" }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilterDeadline}
          onChange={(e) => setGlobalFilterDeadline(e.target.value)}
          placeholder="Pesquisar na tabela"
        />
      </span>
    </div>
  );

  const deleteDeadlineFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteDeadline}
      />
      <Button
        type="button"
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteDeadline}
      />
    </>
  );
  const deleteDeadlinesDialogFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteDeadlineDialog}
      />
    </>
  );

  // functions added to table for linked lawsuits
  const [globalFilterLinkedLawsuit, setGlobalFilterLinkedLawsuit] =
    useState("");
  const dtLinkedLawsuits = useRef(null);

  const [linkedLawsuits, setLinkedLawsuits] = useState<
    ILinkedLawsuitProperties[]
  >([]);
  const [linkedLawsuit, setLinkedLawsuit] = useState(emptyLinkedLawsuit);
  const [linkedLawsuitTypes, setLinkedLawsuitTypes] = useState<
    ILinkedLawsuitTypesProperties[]
  >([]);
  const [deleteLinkedLawsuitDialog, setDeleteLinkedLawsuitDialog] =
    useState(false);
  const [deleteLinkedLawsuitsDialog, setDeleteLinkedLawsuitsDialog] =
    useState(false);
  const [selectedLinkedLawsuits, setSelectedLinkedLawsuits] = useState<
    ILinkedLawsuitProperties[]
  >([]);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        const response = await api.get(
          `/lawsuits/linkedlawsuits/show/filtered/${id}`
        );
        setLinkedLawsuits(response.data);
      }
    })();
  }, [id, isAddMode]);

  useEffect(() => {
    (async () => {
      await api
        .get("/lawsuits/linkedlawsuitstypes/show/all")
        .then((response) => {
          setLinkedLawsuitTypes(response.data);
        });
    })();
  }, []);

  const linkedLawsuitTypeBodyTemplate = (rowData: {
    proc_vinc_cod_tipo: number;
  }) => {
    if (linkedLawsuitTypes !== undefined) {
      const filteredlinkedLawsuitType = linkedLawsuitTypes.filter(
        (item) => item.proc_vinc_tipo_id === rowData.proc_vinc_cod_tipo,
        [linkedLawsuitTypes]
      );

      return filteredlinkedLawsuitType[0]?.proc_vinc_tipo_descricao;
    }
    return "";
  };

  const columnsLinkedLawsuits = [
    { field: "proc_vinc_reg", header: "Processo vinc. nº" },
    { field: "proc_vinc_polo_ativo", header: "Pólo ativo" },
    { field: "proc_vinc_polo_passivo", header: "Pólo passivo" },
    { field: "proc_vinc_cod_tipo", header: "Tipo" },
  ];

  const dynamicColumnsLinkedLawsuits = columnsLinkedLawsuits.map((col) => {
    if (col.field === "proc_vinc_cod_tipo") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={linkedLawsuitTypeBodyTemplate}
          sortable
        />
      );
    }

    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={false}
      />
    );
  });

  const handleCreateLinkedLawsuit = () => {
    history.push(`/lawsuit/linkedlawsuit/create?cod=${id}&active=5`);
  };

  const hidedeleteLinkedLawsuit = () => {
    setDeleteLinkedLawsuitDialog(false);
  };

  const hidedeleteLinkedLawsuitDialog = () => {
    setDeleteLinkedLawsuitsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLinkedLawsuit = (rowData: any) => {
    history.push(
      `/lawsuit/linkedlawsuit/edit/${rowData.proc_vinc_id}?cod=${id}&active=5`
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmdeleteLinkedLawsuit = (
    linkedLawsuit: React.SetStateAction<ILinkedLawsuitProperties>
  ) => {
    setLinkedLawsuit(linkedLawsuit);
    setDeleteLinkedLawsuitDialog(true);
  };

  const deleteLinkedLawsuit = () => {
    api.delete(`/lawsuits/linkedLawsuits/delete/${linkedLawsuit.proc_vinc_id}`);

    setDeleteLinkedLawsuitDialog(false);

    const updatedRecords = linkedLawsuits.filter(
      (val) => val.proc_vinc_id !== linkedLawsuit.proc_vinc_id
    );
    setLinkedLawsuits(updatedRecords);

    addToast({
      type: "success",
      title: "Cadastro excluído!",
      description: "O cadastro selecionado foi excluído com sucesso!",
    });
  };

  const changeArchivedStatus = async (rowData: {
    proc_vinc_id: number;
    proc_vinc_arquivado: boolean;
  }) => {
    if (!isAddMode) {
      await api.patch(
        `lawsuits/linkedLawsuits/patch/${rowData.proc_vinc_id}/${rowData.proc_vinc_arquivado}`
      );
      const response = await api.get(
        `/lawsuits/linkedlawsuits/show/filtered/${id}`
      );
      setLinkedLawsuits(response.data);
    }
  };

  const handleLinkedlawsuitProgresses = (rowData: {
    proc_vinc_principal_id: number;
    proc_vinc_id: number;
  }) => {
    history.push(
      `/lawsuit/linkedlawsuit/progress/?cod=${rowData.proc_vinc_principal_id}&vinc=${rowData.proc_vinc_id}&active=5`
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyLinkedLawsuitTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => {
          editLinkedLawsuit(rowData);
        }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: "left" }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => {
          confirmdeleteLinkedLawsuit(rowData);
        }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: "red-tooltip", position: "left" }}
      />
      <Button
        type="button"
        icon={
          rowData.proc_vinc_arquivado === true ? "pi pi-inbox" : "pi pi-inbox"
        }
        className={
          rowData.proc_vinc_arquivado === true
            ? "p-button-rounded p-button-text p-button-plain p-button-danger"
            : "p-button-rounded p-button-text p-button-plain p-button-success"
        }
        onClick={() => {
          changeArchivedStatus(rowData);
        }}
        tooltip={`Processo vinculado ${
          rowData.proc_vinc_arquivado === true ? "arquivado" : "ativo"
        }`}
        tooltipOptions={{ position: "left" }}
      />
      {rowData.counter >= 0 ? (
        <Button
          type="button"
          icon="pi pi-link"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => {
            handleLinkedlawsuitProgresses(rowData);
          }}
          tooltip="Gerenciar andamento de processos vinculados"
          tooltipOptions={{ position: "left" }}
        />
      ) : (
        ""
      )}
    </>
  );

  const headerLinkedLawsuit = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreateLinkedLawsuit}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: "right" }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilterLinkedLawsuit}
          onChange={(e) => setGlobalFilterLinkedLawsuit(e.target.value)}
          placeholder="Pesquisar na tabela"
        />
      </span>
    </div>
  );

  const deleteLinkedLawsuitFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteLinkedLawsuit}
      />
      <Button
        type="button"
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteLinkedLawsuit}
      />
    </>
  );
  const deleteLinkedLawsuitsDialogFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteLinkedLawsuitDialog}
      />
    </>
  );

  const [origin, setOrigin] = useState("");

  const [outcomes, setOutcomes] = useState<IOutcomeProperties[]>([]);
  const [outcome, setOutcome] = useState<IOutcomeProperties>(emptyOutcome);
  const [deleteOutcomeDialog, setDeleteOutcomeDialog] = useState(false);
  const [dateOutcome, setDateOutcome] = useState<Date | Date[] | undefined>(
    undefined
  );
  const [dateArchived, setDateArchived] = useState<Date | Date[] | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        const response = await api.get(
          `/lawsuits/outcomes/show/filtered/${id}`
        );
        setOutcomes(response.data);
        setOutcome(response.data[0]);

        if (outcomes.length > 0) {
          const dateOutcomeBR = new Date(response.data[0].proc_desfecho_data);
          const formattedDateOutcome = new Date(
            dateOutcomeBR.toISOString().slice(0, -1)
          );

          const dateArchivedBR = new Date(
            response.data[0].proc_desfecho_data_baixa
          );
          const formattedDateArchived = new Date(
            dateArchivedBR.toISOString().slice(0, -1)
          );

          setDateOutcome(formattedDateOutcome);
          setDateArchived(formattedDateArchived);
        } else {
          setDateOutcome(new Date());
          setDateArchived(new Date());
        }
      }
    })();
  }, [id, isAddMode, outcomes.length]);

  const handleOutcome = () => {
    let outcomeId = 0;
    if (outcome && outcome.proc_desfecho_id !== null) {
      outcomeId = Number(outcome.proc_desfecho_id);
      history.push(
        `/lawsuit/outcome/edit/${outcomeId}?cod=${id}&clie=${lawsuit?.proc_cod_cliente}&active=6`
      );
    } else {
      history.push(
        `/lawsuit/outcome/create?cod=${id}&clie=${lawsuit?.proc_cod_cliente}&active=6`
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmdeleteOutcome = () => {
    setOutcome(outcomes[0]);
    setDeleteOutcomeDialog(true);
  };

  const deleteOutcome = async () => {
    await api.patch(`/lawsuits/main/updatelawsuitstatus/${Number(id)}`);
    api.delete(`/lawsuits/outcomes/delete/${outcome.proc_desfecho_id}`);

    setDeleteOutcomeDialog(false);

    const updatedRecords = outcomes.filter(
      (val) => val.proc_desfecho_id !== outcome.proc_desfecho_id
    );
    setOutcomes(updatedRecords);

    addToast({
      type: "success",
      title: "Cadastro excluído!",
      description: "O cadastro selecionado foi excluído com sucesso!",
    });
  };

  const hidedeleteOutcome = () => {
    setDeleteOutcomeDialog(false);
  };

  const deleteOutcomeFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteOutcome}
      />
      <Button
        type="button"
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteOutcome}
      />
    </>
  );

  const hidedeleteOutcomeDialog = () => {
    setDeleteOutcomeDialog(false);
  };

  const handleNotes = (originalValue: string) => {
    setOriginalNotesValue(originalValue);
    setIsReadOnly(false);
    document.getElementById("proc_observacao")?.focus();
    toast.current?.show({
      severity: "info",
      summary: "Edição habilitada",
      detail:
        "Você está gerenciando as informações do campo 'Observações' deste processo.",
      life: 3000,
    });
  };

  const handleNoteSave = async (values: ILawsuitFormProperties) => {
    setIsReadOnly(true);

    // chamada api para salvar o campo de observações
    try {
      const formData = {
        proc_observacao: JSON.stringify(values.proc_observacao),
        user_id: user.legacy_id,
        user_login: user.login_name,
        user_name: user.name,
        user_email: user.email,
      };
      await api
        .patch(`/lawsuits/main/updatelawsuitnotes/${id}`, formData)
        .then((response) => {
          if (response.data !== false) {
            toast.current?.show({
              severity: "success",
              summary: "Edição salva",
              detail:
                "As alterações no campo 'Observações' foram salvas com sucesso.",
              life: 3000,
            });
          }
        });
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Erro ao salvar",
        detail: "Não foi possível salvar as mudanças para as 'Observações'.",
        life: 3000,
      });
    }
  };

  const handleCancelNoteEdit = <TValues extends object>(
    setFieldValue: FormikHelpers<TValues>["setFieldValue"]
  ) => {
    setIsReadOnly(true);
    setFieldValue("proc_observacao", originalNotesValue);
    toast.current?.show({
      severity: "warn",
      summary: "Edição cancelada",
      detail:
        "Você cancelou a edição e todas as alterações no campo 'Observações' foram desfeitas.",
      life: 3000,
    });
  };

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>{isAddMode ? "Cadastrar " : "Editar "} processo</h2>
        </div>
      </header>

      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={lawsuit || initialValues}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setValues,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            (async () => {
              try {
                if (!isAddMode) {
                  api.get(`/lawsuits/main/show/${id}`).then((response) => {
                    setValues(response.data);
                    setLawsuit(response.data);

                    const judgingBodyId = response.data.proc_cod_orgao;
                    if (judgingBodyId > 0) {
                      const city = response.data.proc_orgao_cidade;
                      const county = response.data.proc_orgao_uf;
                      let judgingBody = "";

                      api
                        .get(`/lawsuits/judgingbodies/show/${judgingBodyId}`)
                        .then((responsebodies) => {
                          judgingBody = responsebodies.data.orgao_descricao;

                          const originConcat = `${judgingBody} - ${city}/${county}`;
                          setOrigin(originConcat);
                        });
                    } else {
                      setOrigin("");
                    }
                  });
                }
              } catch (e) {
                addToast({
                  type: "error",
                  title: "Ocorreu um problema",
                  description:
                    "Não foi possível carregar as informações do cadastro selecionado.",
                });
              }
            })();
          }, [setValues, setFieldValue]);
          return (
            <>
              {!isAddMode ? (
                <div className="card">
                  <header>
                    <h3>Dados do Processo</h3>
                  </header>
                  <div className="fluid formgrid grid">
                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-6">
                          <table
                            ref={lawsuitSummaryHeader}
                            width="1000"
                            id="lawsuit_summary"
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width="150">
                                  <strong>Pasta nº:</strong>
                                </td>
                                <td width="350">{values?.proc_num_pasta}</td>
                                <td width="20">&nbsp;</td>
                                <td width="150">
                                  <strong>Processo nº:</strong>
                                </td>
                                <td width="350">{values?.proc_reg}</td>
                              </tr>
                              <tr className="stripedRows">
                                <td width="150">
                                  <strong>Autor(es):</strong>
                                </td>
                                <td width="350">{values?.proc_autor}</td>
                                <td width="20">&nbsp;</td>
                                <td width="150">
                                  <strong>Réu(s):</strong>
                                </td>
                                <td width="350">{values?.proc_reu}</td>
                              </tr>
                              <tr>
                                <td width="150">
                                  <strong>Assunto:</strong>
                                </td>
                                <td width="350">{values?.proc_assunto}</td>
                                <td width="20">&nbsp;</td>
                                <td width="150">
                                  <strong>Origem:</strong>
                                </td>
                                <td width="350">{origin}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="card">
                <form onSubmit={handleSubmit}>
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    {/* main lawsuit data */}
                    <TabPanel header="Cadastro Geral">
                      <div className="fluid formgrid grid">
                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_num_pasta">Pasta nº</label>
                              <InputText
                                id="proc_num_pasta"
                                name="proc_num_pasta"
                                className="inputfield w-full"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.proc_num_pasta}
                              />
                            </div>
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_reg">Processo nº</label>
                              <InputText
                                id="proc_reg"
                                name="proc_reg"
                                className="inputfield w-full"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.proc_reg}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-12">
                              <label htmlFor="proc_reg_outros">
                                Outros números do processo
                              </label>
                              <InputTextarea
                                id="proc_reg_outros"
                                name="proc_reg_outros"
                                className="inputfield w-full"
                                value={values.proc_reg_outros || ""}
                                onChange={handleChange}
                                rows={5}
                                cols={30}
                              />
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_cod_natureza">
                                Natureza do processo *
                              </label>
                              <Dropdown
                                id="proc_cod_natureza"
                                name="proc_cod_natureza"
                                value={values.proc_cod_natureza}
                                options={natureSuitsOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                              <ErrorMessage name="proc_cod_natureza">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_cod_acao">
                                Tipo de ação *
                              </label>
                              <Dropdown
                                id="proc_cod_acao"
                                name="proc_cod_acao"
                                value={values.proc_cod_acao}
                                options={suitTypesOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                              <ErrorMessage name="proc_cod_acao">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-4">
                              <label htmlFor="proc_cod_orgao">
                                Órgão julgador
                              </label>
                              <Dropdown
                                id="proc_cod_orgao"
                                name="proc_cod_orgao"
                                value={values.proc_cod_orgao}
                                options={judgingBodiesOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                              <ErrorMessage name="proc_cod_orgao">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_orgao_cidade">Cidade</label>
                              <InputText
                                id="proc_orgao_cidade"
                                name="proc_orgao_cidade"
                                className="inputfield w-full"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.proc_orgao_cidade}
                              />
                            </div>
                            <div className="col-12 md:col-2">
                              <label htmlFor="proc_orgao_uf">Estado</label>
                              <Dropdown
                                id="proc_orgao_uf"
                                name="proc_orgao_uf"
                                value={values.proc_orgao_uf}
                                options={brazilianStatesOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                              <ErrorMessage name="proc_cod_orgao">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_autor">Autor(es)</label>
                              <InputTextarea
                                id="proc_autor"
                                name="proc_autor"
                                className="inputfield w-full"
                                value={values.proc_autor || ""}
                                onChange={handleChange}
                                rows={5}
                                cols={30}
                              />
                              <small id="proc_autor-help" className="block">
                                Separe os nomes por ponto-e-vírgula ( ; ).
                              </small>
                            </div>
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_reg_outros">Réu(s)</label>
                              <InputTextarea
                                id="proc_reu"
                                name="proc_reu"
                                className="inputfield w-full"
                                value={values.proc_reu || ""}
                                onChange={handleChange}
                                rows={5}
                                cols={30}
                              />
                              <small id="proc_reu-help" className="block">
                                Separe os nomes por ponto-e-vírgula ( ; ).
                              </small>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_terceiros">
                                Terceiros intervenientes
                              </label>
                              <InputText
                                id="proc_terceiros"
                                name="proc_terceiros"
                                className="inputfield w-full"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.proc_terceiros}
                              />
                            </div>
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_cod_nat_interv">
                                Natureza da intervenção
                              </label>
                              <Dropdown
                                id="proc_cod_nat_interv"
                                name="proc_cod_nat_interv"
                                value={values.proc_cod_nat_interv}
                                options={interventionsOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_cod_cliente">
                                Cliente *
                              </label>
                              <Dropdown
                                id="proc_cod_cliente"
                                name="proc_cod_cliente"
                                value={values.proc_cod_cliente}
                                options={customersOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                              <ErrorMessage name="proc_cod_cliente">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-12">
                              <label htmlFor="proc_assunto">Assunto *</label>
                              <InputTextarea
                                id="proc_assunto"
                                name="proc_assunto"
                                className="inputfield w-full"
                                value={values.proc_assunto || ""}
                                onChange={handleChange}
                                rows={5}
                                cols={30}
                              />
                              <ErrorMessage name="proc_assunto">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_data_ajuiz">
                                Data de ajuizamento
                                <Calendar
                                  inputId="proc_data_ajuiz"
                                  name="proc_data_ajuiz"
                                  className="inputfield w-full"
                                  value={
                                    isAddMode
                                      ? undefined
                                      : new Date(
                                          new Date(
                                            values.proc_data_ajuiz
                                          ).setHours(
                                            new Date(
                                              values.proc_data_ajuiz
                                            ).getHours() + 3
                                          )
                                        )
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  keepInvalid
                                  showIcon
                                  showButtonBar
                                  showOnFocus={false}
                                  mask="99/99/9999"
                                  dateFormat="dd/mm/yy"
                                  yearRange="1980:2030"
                                  locale="pt_BR"
                                />
                              </label>
                            </div>
                            <div className="col-12 md:col-6">
                              <label htmlFor="proc_data_ingresso">
                                Ingresso no escritório
                                <Calendar
                                  inputId="proc_data_ingresso"
                                  name="proc_data_ingresso"
                                  className="inputfield w-full"
                                  value={
                                    isAddMode
                                      ? undefined
                                      : new Date(
                                          new Date(
                                            values.proc_data_ingresso
                                          ).setHours(
                                            new Date(
                                              values.proc_data_ingresso
                                            ).getHours() + 3
                                          )
                                        )
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  keepInvalid
                                  showIcon
                                  showButtonBar
                                  showOnFocus={false}
                                  mask="99/99/9999"
                                  dateFormat="dd/mm/yy"
                                  yearRange="1980:2030"
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-3">
                              <label htmlFor="proc_cod_procedimento">
                                Procedimento
                              </label>
                              <Dropdown
                                id="proc_cod_procedimento"
                                name="proc_cod_procedimento"
                                value={values.proc_cod_procedimento}
                                options={proceduresOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                            </div>
                            <div className="col-12 md:col-3">
                              <label htmlFor="proc_cod_adv_resp">
                                Adv. Responsável *
                              </label>
                              <Dropdown
                                id="proc_cod_adv_resp"
                                name="proc_cod_adv_resp"
                                value={values.proc_cod_adv_resp}
                                options={attorneysOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                              <ErrorMessage name="proc_cod_adv_resp">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="col-12 md:col-3">
                              <label htmlFor="proc_cod_ass_resp">
                                Assistente
                              </label>
                              <Dropdown
                                id="proc_cod_ass_resp"
                                name="proc_cod_ass_resp"
                                value={values.proc_cod_ass_resp}
                                options={assistantsOptions}
                                onChange={handleChange}
                                className="inputfield w-full"
                                optionLabel="name"
                                optionValue="value"
                                filter
                                filterBy="name"
                                placeholder="Selecione uma opção"
                                emptyFilterMessage="Nenhum registro encontrado"
                              />
                            </div>

                            <div className="col-12 md:col-3">
                              <label htmlFor="proc_adv_adversa">
                                Adv. parte adversa
                              </label>
                              <InputText
                                id="proc_adv_adversa"
                                name="proc_adv_adversa"
                                className="inputfield w-full"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.proc_adv_adversa}
                              />
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="col-12">
                          <div className="field grid">
                            <div className="col-12 md:col-6 lg:col-2">
                              <label htmlFor="proc_pgto_ato">
                                Cobrança por ato? *
                              </label>
                              <SelectButton
                                id="proc_pgto_ato"
                                value={values.proc_pgto_ato}
                                options={options}
                                optionLabel="name"
                                optionValue="value"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="proc_pgto_ato">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            <div className="col-12 md:col-6 lg:col-2">
                              <label htmlFor="proc_cobrar_por_hora">
                                Cobrar por hora?
                              </label>
                              <SelectButton
                                id="proc_cobrar_por_hora"
                                value={values.proc_cobrar_por_hora}
                                options={options}
                                optionLabel="name"
                                optionValue="value"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>

                            <div className="col-12 md:col-6 lg:col-6">
                              <label htmlFor="proc_url">
                                Localização na internet (URL)
                              </label>
                              <div className="p-col-12 p-md-4">
                                <div className="p-inputgroup">
                                  <span className="p-inputgroup-addon">
                                    www
                                  </span>
                                  <InputText
                                    id="proc_url"
                                    name="proc_url"
                                    className="inputfield w-full"
                                    placeholder="https://www."
                                    value={values.proc_url}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <Link
                                    to={{ pathname: `${values.proc_url}` }}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}
                                    title="Acessar o site"
                                  >
                                    <span className="p-inputgroup-addon">
                                      {" "}
                                      <i className="pi pi-external-link" />{" "}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 md:col-6 lg:col-2">
                              <label htmlFor="proc_sta_gerencial">
                                Enviar p/ Gerencial
                              </label>
                              <SelectButton
                                id="proc_sta_gerencial"
                                value={values.proc_sta_gerencial}
                                options={optionsManagementSTA}
                                optionLabel="name"
                                optionValue="value"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 p-mt-20">
                          <div className="col-12">
                            Todos os campos marcados com * são de preenchimento
                            obrigatório.
                          </div>
                          <header />
                          <div className="flex align-content-center justify-content-center field">
                            <Button
                              icon="pi pi-check"
                              iconPos="left"
                              label="Enviar dados"
                              className="p-mr-2"
                              type="submit"
                            />
                            <Button
                              icon="pi pi-undo"
                              iconPos="left"
                              label="Limpar campos"
                              className="p-button-secondary p-mr-2"
                              type="button"
                              onClick={handleReset}
                            />
                            <Button
                              icon="pi pi-times"
                              iconPos="left"
                              label="Cancelar e voltar"
                              type="button"
                              className="p-button-danger"
                              onClick={history.goBack}
                            />
                          </div>
                        </div>
                      </div>
                      {/* </form> */}
                    </TabPanel>

                    <TabPanel header="Resumo" disabled={!isAddMode === false}>
                      <div className="fluid formgrid grid">
                        <Card className="col-12">
                          <div className="col-12">
                            <div className="field grid">
                              <div className="col-12 md:col-6">
                                <label htmlFor="proc_localizacao">
                                  Localização atual do processo
                                </label>
                                <InputText
                                  id="proc_localizacao"
                                  name="proc_localizacao"
                                  className="inputfield w-full readOnly"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.proc_localizacao}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="field grid">
                              <div className="col-12 md:col-12">
                                <label htmlFor="proc_resumo">Observações</label>
                                <InputTextarea
                                  id="proc_resumo"
                                  name="proc_resumo"
                                  className="inputfield w-full"
                                  value={values.proc_resumo || ""}
                                  onChange={handleChange}
                                  rows={5}
                                  cols={30}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-mt-20">
                            <div className="col-12">
                              Todos os campos marcados com * são de
                              preenchimento obrigatório.
                            </div>
                            <header />
                            <div className="flex align-content-center justify-content-center field">
                              <Button
                                icon="pi pi-check"
                                iconPos="left"
                                label="Enviar dados"
                                className="p-mr-2"
                                type="submit"
                                // disabled={!isValid || !dirty}
                              />
                              <Button
                                icon="pi pi-times"
                                iconPos="left"
                                label="Cancelar e voltar"
                                type="button"
                                className="p-button-danger"
                                onClick={history.goBack}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </TabPanel>
                    <TabPanel
                      header="Informações financeiras"
                      disabled={!isAddMode === false}
                    >
                      <FinancialItems />
                    </TabPanel>

                    <TabPanel
                      header="Andamentos"
                      disabled={!isAddMode === false}
                    >
                      <div className="fluid formgrid grid">
                        {/* generic lawsuit (laswuit table) information */}
                        <Card className="col-12">
                          <div className="col-12">
                            <div className="field grid">
                              <div className="col-12 md:col-12">
                                <div className="flex align-content-center justify-content-between field">
                                  <label htmlFor="proc_observacao">
                                    Observações
                                  </label>
                                  <Button
                                    icon="pi pi-check"
                                    iconPos="left"
                                    label="Gerenciar Observações"
                                    className="p-mr-2 sm"
                                    type="button"
                                    severity="secondary"
                                    size="small"
                                    onClick={() =>
                                      handleNotes(values.proc_observacao)
                                    }
                                    visible={isReadOnly}
                                  />
                                  <div
                                    style={{
                                      visibility: !isReadOnly
                                        ? "visible"
                                        : "hidden",
                                      display: !isReadOnly ? "block" : "none",
                                    }}
                                  >
                                    <Button
                                      icon="pi pi-check"
                                      iconPos="left"
                                      label="Salvar observações"
                                      className="p-mr-2"
                                      type="button"
                                      visible={!isReadOnly}
                                      onClick={() => handleNoteSave(values)}
                                    />
                                    <Button
                                      icon="pi pi-times"
                                      iconPos="left"
                                      label="Cancelar"
                                      type="button"
                                      className="p-button-danger"
                                      visible={!isReadOnly}
                                      onClick={() =>
                                        handleCancelNoteEdit(setFieldValue)
                                      }
                                    />
                                  </div>
                                </div>
                                <InputTextarea
                                  id="proc_observacao"
                                  name="proc_observacao"
                                  className="inputfield w-full"
                                  value={values.proc_observacao || ""}
                                  onChange={handleChange}
                                  rows={5}
                                  cols={30}
                                  readOnly={isReadOnly}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="field grid">
                              <div className="col-12 md:col-6">
                                <label htmlFor="proc_url">
                                  Localização atual do processo
                                </label>
                                <div className="p-col-12 p-md-6">
                                  <div className="p-inputgroup">
                                    <InputText
                                      id="proc_localizacao"
                                      name="proc_localizacao"
                                      className="inputfield w-full"
                                      placeholder="https://www."
                                      value={values.proc_localizacao}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <Link
                                      to={{ pathname: `${values.proc_url}` }}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{ textDecoration: "none" }}
                                      title="Acessar o site"
                                    >
                                      <span className="p-inputgroup-addon">
                                        &nbsp;
                                        <i className="pi pi-external-link" />
                                        &nbsp;
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="field grid">
                              <div className="col-12 md:col-12">
                                <label htmlFor="proc_situacao">
                                  Situação atual do processo
                                </label>
                                <InputTextarea
                                  id="proc_situacao"
                                  name="proc_situacao"
                                  className="inputfield w-full"
                                  value={values.proc_situacao || ""}
                                  onChange={handleChange}
                                  rows={5}
                                  cols={30}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="field grid">
                              <div className="col-12 md:col-6 lg:col-6">
                                <label htmlFor="proc_em_acordo">Status</label>
                                <SelectButton
                                  id="proc_em_acordo"
                                  value={values.proc_em_acordo}
                                  options={lawsuitStatusOptions}
                                  optionLabel="name"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 p-mt-20">
                            <div className="col-12">
                              Todos os campos marcados com * são de
                              preenchimento obrigatório.
                            </div>
                            <header />
                            <div className="flex align-content-center justify-content-center field">
                              <Button
                                icon="pi pi-check"
                                iconPos="left"
                                label="Enviar dados"
                                className="p-mr-2"
                                type="submit"
                                disabled={!isReadOnly}
                              />
                              <Button
                                icon="pi pi-times"
                                iconPos="left"
                                label="Cancelar e voltar"
                                className="p-button-danger p-mr-2"
                                type="button"
                                onClick={history.goBack}
                                disabled={!isReadOnly}
                              />
                              <Button
                                icon="pi pi-calendar"
                                iconPos="left"
                                label="Atualizar data"
                                className="p-button-warning"
                                type="button"
                                onClick={handleLawsuitDateUpdate}
                                disabled={!isReadOnly}
                              />
                            </div>
                          </div>
                        </Card>

                        <Card className="col-12 p-mt-10">
                          <div className="col-12">
                            <header>
                              <div>
                                <h1>Andamento de processos</h1>
                              </div>
                            </header>

                            <div className="datatable-responsive">
                              {/* <Tooltip target=".export-buttons>button" position="bottom" /> */}

                              <DataTable
                                tableClassName="table-lawsuitsprogresses table"
                                id="table-lawsuitsprogresses"
                                ref={dt}
                                value={lawsuitProgresses}
                                editMode="row"
                                dataKey="and_id"
                                header={header}
                                stripedRows
                                className="p-datatable-sm"
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
                                rows={50}
                                rowsPerPageOptions={[10, 20, 50]}
                                paginatorLeft={paginatorLeft}
                                paginatorRight={paginatorRight}
                                globalFilter={globalFilter}
                                emptyMessage="Nenhum registro encontrado."
                                selectionMode="checkbox"
                                selection={selectedLawsuitsProgresses}
                                onSelectionChange={(e) => {
                                  const selProgresses =
                                    e.value as ILawsuitProgressProperties[];
                                  setSelectedLawsuitsProgresses(selProgresses);
                                }}
                                onRowDoubleClick={(e) =>
                                  editLawsuitProgress(e.data)
                                }
                              >
                                {dynamicColumnsLawsuitProgress}
                                <Column
                                  body={actionBodyTemplate}
                                  align="left"
                                  style={{ width: "200px" }}
                                />
                              </DataTable>

                              <div>
                                <Dialog
                                  visible={deleteLawsuitProgress}
                                  style={{ width: "450px" }}
                                  header="Confirmação"
                                  modal
                                  footer={deleteLawsuitProgressFooter}
                                  onHide={hidedeleteLawsuitProgress}
                                >
                                  <div className="confirmation-content">
                                    <i
                                      className="pi pi-exclamation-triangle p-mr-3"
                                      style={{ fontSize: "2rem" }}
                                    />
                                    {lawsuitProgress && (
                                      <span>
                                        Você tem certeza que deseja excluir
                                        <br />
                                        <br />
                                        <b>{lawsuitProgress.and_andamento}</b>?
                                      </span>
                                    )}
                                  </div>
                                </Dialog>

                                <Dialog
                                  visible={deleteLawsuitProgresssDialog}
                                  style={{ width: "450px" }}
                                  header="Confirmação"
                                  modal
                                  footer={deleteLawsuitProgresssDialogFooter}
                                  onHide={hidedeleteLawsuitProgressDialog}
                                >
                                  <div className="confirmation-content">
                                    <i
                                      className="pi pi-exclamation-triangle p-mr-3"
                                      style={{ fontSize: "2rem" }}
                                    />
                                    {lawsuitProgress && (
                                      <span>
                                        Você tem certeza que deseja excluir os
                                        cadastros selecionados?
                                      </span>
                                    )}
                                  </div>
                                </Dialog>
                              </div>
                            </div>

                            <div className="col-12 p-mt-20">
                              <header>
                                <h3>Enviar cópias de e-mail</h3>
                              </header>

                              <div className="field grid p-mt-20">
                                <div className="col-12 md:col-6">
                                  <Checkbox
                                    inputId="avisar_cliente"
                                    name="avisar_cliente"
                                    onChange={(e) => {
                                      if (e.checked !== undefined) {
                                        setNotifyCustomer(e.checked);
                                      }
                                    }}
                                    checked={notifyCustomer}
                                  />
                                  <label htmlFor="avisar_cliente">
                                    {" "}
                                    Avisar cliente
                                  </label>
                                </div>
                                <div className="col-12 md:col-6">
                                  <Checkbox
                                    inputId="avisar_advogado"
                                    name="avisar_advogado"
                                    onChange={(e) => {
                                      if (e.checked !== undefined) {
                                        setNotifyAttorney(e.checked);
                                      }
                                    }}
                                    checked={notifyAttorney}
                                  />
                                  <label htmlFor="avisar_advogado">
                                    {" "}
                                    Avisar advogado
                                  </label>
                                </div>
                              </div>

                              <div className="col-12 md:col-12">
                                <label htmlFor="copia_emails_outros">
                                  Enviar cópia para e-mails adicionais (separe
                                  os emails por ponto-e-vírgula &quot; ; &quot;)
                                </label>
                                <InputText
                                  id="copia_emails_outros"
                                  name="copia_emails_outros"
                                  className="inputfield w-full"
                                  defaultValue={otherEmails}
                                  onChange={(e) =>
                                    setOtherEmails(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-12 md:col-12">
                                <label htmlFor="emails_observacoes">
                                  Observações
                                </label>
                                <InputTextarea
                                  id="emails_observacoes"
                                  name="emails_observacoes"
                                  className="inputfield w-full"
                                  value={extraInformation}
                                  onChange={(e) =>
                                    setExtraInformation(e.target.value)
                                  }
                                  rows={5}
                                  cols={30}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="field grid">
                                <div className="col-12 md:col-12">
                                  <div className="flex align-content-center justify-content-center field">
                                    <Button
                                      icon="pi pi-check"
                                      iconPos="left"
                                      label="Enviar e-mail"
                                      className="p-mr-2"
                                      type="button"
                                      onClick={handleNotificationEmails}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </TabPanel>
                    <TabPanel header="Prazos" disabled={!isAddMode === false}>
                      <div className="col-12">
                        <header>
                          <div>
                            <h1>Prazos de processos</h1>
                          </div>
                        </header>

                        <div className="datatable-responsive">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />

                          <DataTable
                            tableClassName="table-deadlines table"
                            id="table-deadlines"
                            ref={dtDeadline}
                            value={deadlines}
                            editMode="row"
                            dataKey="prazo_id"
                            header={headerDeadline}
                            stripedRows
                            className="p-datatable-sm"
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50]}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            globalFilter={globalFilterDeadline}
                            emptyMessage="Nenhum registro encontrado."
                            selectionMode="single"
                            selection={selectedDeadlines}
                            onSelectionChange={(e) => {
                              const selDeadlines =
                                e.value as IDeadlineProperties[];
                              setSelectedDeadlines(selDeadlines);
                            }}
                            onRowDoubleClick={(e) => editDeadline(e.data)}
                          >
                            {dynamicColumnsDeadlines}
                            <Column
                              body={actionBodyDeadlineTemplate}
                              align="right"
                              style={{ width: "120px" }}
                            />
                          </DataTable>

                          <div>
                            <Dialog
                              visible={deleteDeadlineDialog}
                              style={{ width: "450px" }}
                              header="Confirmação"
                              modal
                              footer={deleteDeadlineFooter}
                              onHide={hidedeleteDeadlineDialog}
                            >
                              <div className="confirmation-content">
                                <i
                                  className="pi pi-exclamation-triangle p-mr-3"
                                  style={{ fontSize: "2rem" }}
                                />
                                {deadline && (
                                  <span>
                                    Você tem certeza que deseja excluir
                                    <br />
                                    <br />
                                    <b>{deadline.prazo_providencia}</b>?
                                  </span>
                                )}
                              </div>
                            </Dialog>

                            <Dialog
                              visible={deleteDeadlinesDialog}
                              style={{ width: "450px" }}
                              header="Confirmação"
                              modal
                              footer={deleteDeadlinesDialogFooter}
                              onHide={hidedeleteDeadlineDialog}
                            >
                              <div className="confirmation-content">
                                <i
                                  className="pi pi-exclamation-triangle p-mr-3"
                                  style={{ fontSize: "2rem" }}
                                />
                                {deadline && (
                                  <span>
                                    Você tem certeza que deseja excluir os
                                    cadastros selecionados?
                                  </span>
                                )}
                              </div>
                            </Dialog>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      header="Processos vinculados"
                      disabled={!isAddMode === false}
                    >
                      <div className="col-12">
                        <header>
                          <div>
                            <h1>Processos vinculados</h1>
                          </div>
                        </header>

                        <div className="datatable-responsive">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />

                          <DataTable
                            tableClassName="table-linkedlawsuits table"
                            id="table-linkedlawsuits"
                            ref={dtLinkedLawsuits}
                            value={linkedLawsuits}
                            editMode="row"
                            dataKey="proc_vinc_id"
                            header={headerLinkedLawsuit}
                            stripedRows
                            className="p-datatable-sm"
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50]}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            globalFilter={globalFilterLinkedLawsuit}
                            emptyMessage="Nenhum registro encontrado."
                            selectionMode="single"
                            selection={selectedLinkedLawsuits}
                            onSelectionChange={(e) => {
                              const selLinkedLawsuits =
                                e.value as ILinkedLawsuitProperties[];
                              setSelectedLinkedLawsuits(selLinkedLawsuits);
                            }}
                            onRowDoubleClick={(e) => editLinkedLawsuit(e.data)}
                          >
                            {dynamicColumnsLinkedLawsuits}
                            <Column
                              body={actionBodyLinkedLawsuitTemplate}
                              align="left"
                              style={{ width: "200px" }}
                            />
                          </DataTable>

                          <div>
                            <Dialog
                              visible={deleteLinkedLawsuitDialog}
                              style={{ width: "450px" }}
                              header="Confirmação"
                              modal
                              footer={deleteLinkedLawsuitFooter}
                              onHide={hidedeleteLinkedLawsuitDialog}
                            >
                              <div className="confirmation-content">
                                <i
                                  className="pi pi-exclamation-triangle p-mr-3"
                                  style={{ fontSize: "2rem" }}
                                />
                                {linkedLawsuit && (
                                  <span>
                                    Você tem certeza que deseja excluir
                                    <br />
                                    <br />
                                    <b>{linkedLawsuit.proc_vinc_reg}</b>?
                                  </span>
                                )}
                              </div>
                            </Dialog>

                            <Dialog
                              visible={deleteLinkedLawsuitsDialog}
                              style={{ width: "450px" }}
                              header="Confirmação"
                              modal
                              footer={deleteLinkedLawsuitsDialogFooter}
                              onHide={hidedeleteLinkedLawsuitDialog}
                            >
                              <div className="confirmation-content">
                                <i
                                  className="pi pi-exclamation-triangle p-mr-3"
                                  style={{ fontSize: "2rem" }}
                                />
                                {linkedLawsuit && (
                                  <span>
                                    Você tem certeza que deseja excluir os
                                    cadastros selecionados?
                                  </span>
                                )}
                              </div>
                            </Dialog>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel header="Desfecho" disabled={!isAddMode === false}>
                      <Message
                        severity="info"
                        text="Para inserir ou alterar o desfecho,
                          clique no botão 'Gerenciar desfecho' no final da página."
                      />

                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-6">
                            <label htmlFor="proc_desfecho_data">
                              Data
                              <Calendar
                                inputId="proc_desfecho_data"
                                name="proc_desfecho_data"
                                className="inputfield w-full"
                                value={
                                  // eslint-disable-next-line no-nested-ternary
                                  isAddMode
                                    ? undefined
                                    : outcomes.length > 0
                                    ? dateOutcome
                                    : undefined
                                }
                                showIcon
                                dateFormat="dd/mm/yy"
                                yearRange="1980:2030"
                                disabled
                              />
                            </label>
                          </div>

                          <div className="col-12 md:col-6">
                            <label htmlFor="proc_desfecho_cod_result">
                              Resultado
                            </label>
                            <Dropdown
                              id="proc_desfecho_cod_result"
                              name="proc_desfecho_cod_result"
                              value={
                                outcomes.length > 0
                                  ? outcome.proc_desfecho_cod_result
                                  : null
                              }
                              options={lawsuitOutcomeResultsOptions}
                              className="inputfield w-full"
                              optionLabel="name"
                              optionValue="value"
                              filter
                              filterBy="name"
                              placeholder="Selecione uma opção"
                              emptyFilterMessage="Nenhum registro encontrado"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-12">
                            <label htmlFor="proc_desfecho_obs">
                              Observação
                            </label>
                            <InputTextarea
                              id="proc_desfecho_obs"
                              name="proc_desfecho_obs"
                              className="inputfield w-full"
                              value={
                                outcomes.length > 0
                                  ? outcome.proc_desfecho_obs
                                  : ""
                              }
                              rows={5}
                              cols={30}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-6 lg:col-4">
                            <label htmlFor="proc_desfecho_arquivo_morto">
                              Encaminhar para arquivo morto
                            </label>
                            <SelectButton
                              id="proc_desfecho_arquivo_morto"
                              value={
                                outcomes.length > 0
                                  ? outcome.proc_desfecho_arquivo_morto
                                  : false
                              }
                              options={optionsBoolean}
                              optionLabel="name"
                              optionValue="value"
                              disabled
                            />
                          </div>
                          <div className="col-12 md:col-6 lg:col-4">
                            <label htmlFor="proc_desfecho_data_baixa">
                              Data de baixa
                              <Calendar
                                inputId="proc_desfecho_data_baixa"
                                name="proc_desfecho_data_baixa"
                                className="inputfield w-full"
                                value={
                                  // eslint-disable-next-line no-nested-ternary
                                  isAddMode
                                    ? undefined
                                    : outcomes.length > 0
                                    ? dateArchived
                                    : undefined
                                }
                                showIcon
                                dateFormat="dd/mm/yy"
                                yearRange="1980:2030"
                                disabled
                              />
                            </label>
                          </div>
                          <div className="col-12 md:col-6 lg:col-4">
                            <label htmlFor="proc_desfecho_caixa_arq_morto">
                              Caixa do arquivo morto
                            </label>
                            <InputText
                              id="proc_desfecho_caixa_arq_morto"
                              name="proc_desfecho_caixa_arq_morto"
                              className="inputfield w-full"
                              value={
                                outcomes.length > 0
                                  ? outcome.proc_desfecho_caixa_arq_morto
                                  : ""
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-6 lg:col-6">
                            <label htmlFor="proc_pgto_ato">
                              Notificar advogado responsável?
                            </label>
                            <SelectButton
                              id="proc_pgto_ato"
                              value={
                                outcomes.length > 0
                                  ? outcome.proc_desfecho_notificar_adv
                                  : false
                              }
                              options={optionsBoolean}
                              optionLabel="name"
                              optionValue="value"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 p-mt-20">
                        <div className="col-12">
                          Todos os campos marcados com * são de preenchimento
                          obrigatório.
                        </div>
                        <header />
                        <div className="flex align-content-center justify-content-center field">
                          <Button
                            icon="pi pi-check"
                            iconPos="left"
                            label="Gerenciar desfecho"
                            className="p-mr-2"
                            type="button"
                            onClick={handleOutcome}
                          />
                          <Button
                            icon="pi pi-trash"
                            tooltip="Excluir cadastro"
                            tooltipOptions={{ position: "left" }}
                            iconPos="left"
                            label="Excluir desfecho"
                            className="p-button-danger"
                            type="button"
                            onClick={confirmdeleteOutcome}
                          />
                        </div>

                        <Dialog
                          visible={deleteOutcomeDialog}
                          style={{ width: "450px" }}
                          header="Confirmação"
                          modal
                          footer={deleteOutcomeFooter}
                          onHide={hidedeleteOutcomeDialog}
                        >
                          <div className="confirmation-content">
                            <i
                              className="pi pi-exclamation-triangle p-mr-3"
                              style={{ fontSize: "2rem" }}
                            />
                            {deadline && (
                              <span>
                                Você tem certeza que deseja excluir este
                                desfecho?
                              </span>
                            )}
                          </div>
                        </Dialog>
                      </div>
                    </TabPanel>
                  </TabView>
                </form>
              </div>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default AddEditLawsuit;
