import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';

import Customer from '../pages/Customer';
import AddEditCustomer from '../pages/Customer/addEdit';
import BusinessGroup from '../pages/BusinessGroup';
import AddEditBusinessGroup from '../pages/BusinessGroup/addEdit';
import Administrator from '../pages/Administrator';
import AddEditAdministrator from '../pages/Administrator/addEdit';
import Attorney from '../pages/Attorney';
import AddEditAttorney from '../pages/Attorney/addEdit';
import Assistant from '../pages/Assistant';
import AddEditAssistant from '../pages/Assistant/addEdit';
import Group from '../pages/Group';
import AddEditGroup from '../pages/Group/addEdit';

import Lawsuit from '../pages/Lawsuit/Main';
import AddEditLawsuit from '../pages/Lawsuit/Main/addEdit';
import SearchLawsuit from '../pages/Lawsuit/Main/search';
import AddEditLawsuitProgress from '../pages/Lawsuit/Progress/addEdit';
import NatureSuit from '../pages/Lawsuit/NatureSuit';
import AddEditNatureSuit from '../pages/Lawsuit/NatureSuit/addEdit';
import SuitType from '../pages/Lawsuit/SuitType';
import AddEditSuitType from '../pages/Lawsuit/SuitType/addEdit';
import CostDeposit from '../pages/Lawsuit/CostDeposit';
import AddEditCostDeposit from '../pages/Lawsuit/CostDeposit/addEdit';
import Intervention from '../pages/Lawsuit/Intervention';
import AddEditIntervention from '../pages/Lawsuit/Intervention/addEdit';
import JudgingBody from '../pages/Lawsuit/JudgingBody';
import AddEditJudgingBody from '../pages/Lawsuit/JudgingBody/addEdit';
import LinkedLawsuitType from '../pages/Lawsuit/LinkedLawsuitType';
import AddEditLinkedLawsuitType from '../pages/Lawsuit/LinkedLawsuitType/addEdit';
import Procedure from '../pages/Lawsuit/Procedure';
import AddEditProcedure from '../pages/Lawsuit/Procedure/addEdit';
import Result from '../pages/Lawsuit/Result';
import AddEditResult from '../pages/Lawsuit/Result/addEdit';
import Reference from '../pages/Lawsuit/Reference';
import AddEditReference from '../pages/Lawsuit/Reference/addEdit';
import LegalRequest from '../pages/Lawsuit/LegalRequest';
import AddEditLegalRequest from '../pages/Lawsuit/LegalRequest/addEdit';
import AddEditDeadline from '../pages/Lawsuit/Deadline/addEdit';
import AddEditLinkedLawsuit from '../pages/Lawsuit/LinkedLawsuit/addEdit';
import LinkedLawsuitProgress from '../pages/Lawsuit/LinkedLawsuit/Progress';
import AddEditLinkedLawsuitProgress from '../pages/Lawsuit/LinkedLawsuit/Progress/addEdit';
import AddEditOutcome from '../pages/Lawsuit/Outcome/addEdit';

import Indexer from '../pages/Finance/Indexer';
import AddEditIndexer from '../pages/Finance/Indexer/addEdit';
import FinancialIndex from '../pages/Finance/FinancialIndex';
import AddEditFinancialIndex from '../pages/Finance/FinancialIndex/addEdit';
import AddEditFinancialItem from '../pages/Lawsuit/FinancialItem/Request/addEdit';
import AddEditLawsuitFee from '../pages/Lawsuit/FinancialItem/LawsuitFee/addEdit';
import AddEditDepositInCourt from '../pages/Lawsuit/FinancialItem/DepositInCourt/addEdit';
import AddEditPawnedGoods from '../pages/Lawsuit/FinancialItem/PawnedGoods/addEdit';

import EditReportHourEmail from '../pages/Management/ReportHourEmail/edit';

import WorkingHour from '../pages/Management/WorkingHour';
import AddEditWorkingHour from '../pages/Management/WorkingHour/addEdit';
import SearchHour from '../pages/Management/WorkingHour/Search';
import PendingConclusionHour from '../pages/Management/WorkingHour/pendingconclusion';
import ManagementList from '../pages/Management';
import SearchManagement from '../pages/Management/search';
import EditManagement from '../pages/Management/edit';

import LawsuitSummaryReport from '../pages/Report/LawsuitSummary';
import SearchLawsuitSummary from '../pages/Report/LawsuitSummary/search';
import LawsuitSummaryCustomizableReport from '../pages/Report/LawsuitSummaryCustomizable';
import SearchLawsuitCustomizableSummary from '../pages/Report/LawsuitSummaryCustomizable/search';
import LawsuitSummaryReportExcel from '../pages/Report/LawsuitSummary/Simplified';
import SearchLawsuitSummaryExcel from '../pages/Report/LawsuitSummary/Simplified/search';
import LawsuitSummaryReportExcelGrouped from '../pages/Report/LawsuitSummary/Grouped';
import SearchLawsuitSummaryExcelGrouped from '../pages/Report/LawsuitSummary/Grouped/search';
import FinancialInformationReport from '../pages/Report/FinancialInformation';
import SearchFinancialInformation from '../pages/Report/FinancialInformation/search';
import FinancialInformationModIIIReport from '../pages/Report/FinancialInformationModIII';
import SearchFinancialModIIIInformation from '../pages/Report/FinancialInformationModIII/search';

import GeneralHourReport from '../pages/Report/Hour/General';
import SelectedHourReport from '../pages/Report/Hour/General/selectedHour';
import SearchGeneralHour from '../pages/Report/Hour/General/search';
import CustomerHourReport from '../pages/Report/Hour/Customer';
import SearchCustomerHour from '../pages/Report/Hour/Customer/search';
import BilledHourReport from '../pages/Report/Hour/Billed';
import SearchBilledHour from '../pages/Report/Hour/Billed/search';
import ExportBilledHourReport from '../pages/Report/Hour/Billed/export';
import AttorneyAnalyticalHourReport from '../pages/Report/Hour/Attorney/Analytical';
import SearchAttorneyAnalyticalHour from '../pages/Report/Hour/Attorney/Analytical/search';
import AttorneySimplifiedHourReport from '../pages/Report/Hour/Attorney/Simplified';
import SearchAttorneySimplifiedHour from '../pages/Report/Hour/Attorney/Simplified/search';
import HourPercentageByCustomerReport from '../pages/Report/Hour/Percentage';
import SearchHourPercentageByCustomer from '../pages/Report/Hour/Percentage/search';

import Schedule from '../pages/Schedule';
import SearchSchedule from '../pages/Schedule/search';
import MySchedule from '../pages/Schedule/myschedule';
import AddEditSchedule from '../pages/Schedule/addEdit';
// import SearchMeeting from '../pages/Meeting/search';
import ScheduleProgress from '../pages/Schedule/Progress';
import AddEditScheduleProgress from '../pages/Schedule/Progress/addEdit';

import MeetingRoom from '../pages/Schedule/Room';
import AddEditMeetingRoom from '../pages/Schedule/Room/addEdit';

const Routes: React.FC = () => {
  let loggedUserLevel = 0;
  let reportiv = 0;
  let reportv = 0;

  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem('@Jurisnet:access');

  if (typeof accessData === 'string') {
    const access = JSON.parse(accessData);
    loggedUserLevel = Number(access.menu);
    reportiv = Number(access.permissaoiv);
    reportv = Number(access.permissaov);
  }

  const adminId = 1;

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/profile" component={Profile} isPrivate />

      {loggedUserLevel === 5 ? (
        <Route path="/dashboard" component={Dashboard} isPrivate />
      ) : ('')}

      <Route path="/customer" exact component={Customer} isPrivate />
      <Route path="/customer/create" exact component={AddEditCustomer} isPrivate />
      <Route path="/customer/edit/:id" exact component={AddEditCustomer} isPrivate />

      {loggedUserLevel === adminId ? (
        <Route path="/businessgroup" exact component={BusinessGroup} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/businessgroup/create" exact component={AddEditBusinessGroup} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/businessgroup/edit/:id" exact component={AddEditBusinessGroup} isPrivate />
      ) : ('')}

      {loggedUserLevel === adminId ? (
        <Route path="/management/administrator" exact component={Administrator} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/administrator/create" exact component={AddEditAdministrator} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/administrator/edit/:id" exact component={AddEditAdministrator} isPrivate />
      ) : ('')}

      {loggedUserLevel === adminId ? (
        <Route path="/management/attorney" exact component={Attorney} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/attorney/create" exact component={AddEditAttorney} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/attorney/edit/:id" exact component={AddEditAttorney} isPrivate />
      ) : ('')}

      {loggedUserLevel === adminId ? (
        <Route path="/management/assistant" exact component={Assistant} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/assistant/create" exact component={AddEditAssistant} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/assistant/edit/:id" exact component={AddEditAssistant} isPrivate />
      ) : ('')}

      {loggedUserLevel === adminId ? (
        <Route path="/management/group" exact component={Group} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/group/create" exact component={AddEditGroup} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/group/edit/:id" exact component={AddEditGroup} isPrivate />
      ) : ('')}

      {loggedUserLevel === adminId ? (
        <Route path="/management/list" exact component={ManagementList} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/search" exact component={SearchManagement} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/management/edit/:id" exact component={EditManagement} isPrivate />
      ) : ('')}

      <Route path="/schedule" exact component={Schedule} isPrivate />
      <Route path="/schedule/search" exact component={SearchSchedule} isPrivate />
      <Route path="/schedule/myschedule" exact component={MySchedule} isPrivate />
      <Route path="/schedule/create" exact component={AddEditSchedule} isPrivate />
      <Route path="/schedule/edit/:id" exact component={AddEditSchedule} isPrivate />

      <Route path="/schedule/progress" exact component={ScheduleProgress} isPrivate />
      <Route path="/schedule/progress/create" exact component={AddEditScheduleProgress} isPrivate />
      <Route path="/schedule/progress/edit/:id" exact component={AddEditScheduleProgress} isPrivate />

      <Route path="/schedule/meeting/room" exact component={MeetingRoom} isPrivate />
      <Route path="/schedule/meeting/room/create" exact component={AddEditMeetingRoom} isPrivate />
      <Route path="/schedule/meeting/room/edit/:id" exact component={AddEditMeetingRoom} isPrivate />

      <Route path="/lawsuit/main" exact component={Lawsuit} isPrivate />
      <Route path="/lawsuit/main/search" exact component={SearchLawsuit} isPrivate />
      <Route path="/lawsuit/main/create" exact component={AddEditLawsuit} isPrivate />
      <Route path="/lawsuit/main/edit/:id" exact component={AddEditLawsuit} isPrivate />

      <Route path="/lawsuit/progress/create" exact component={AddEditLawsuitProgress} isPrivate />
      <Route path="/lawsuit/progress/edit/:id" exact component={AddEditLawsuitProgress} isPrivate />

      <Route path="/lawsuit/naturesuit" exact component={NatureSuit} isPrivate />
      <Route path="/lawsuit/naturesuit/create" exact component={AddEditNatureSuit} isPrivate />
      <Route path="/lawsuit/naturesuit/edit/:id" exact component={AddEditNatureSuit} isPrivate />

      <Route path="/lawsuit/suittype" exact component={SuitType} isPrivate />
      <Route path="/lawsuit/suittype/create" exact component={AddEditSuitType} isPrivate />
      <Route path="/lawsuit/suittype/edit/:id" exact component={AddEditSuitType} isPrivate />

      <Route path="/lawsuit/costdeposit" exact component={CostDeposit} isPrivate />
      <Route path="/lawsuit/costdeposit/create" exact component={AddEditCostDeposit} isPrivate />
      <Route path="/lawsuit/costdeposit/edit/:id" exact component={AddEditCostDeposit} isPrivate />

      <Route path="/lawsuit/intervention" exact component={Intervention} isPrivate />
      <Route path="/lawsuit/intervention/create" exact component={AddEditIntervention} isPrivate />
      <Route path="/lawsuit/intervention/edit/:id" exact component={AddEditIntervention} isPrivate />

      <Route path="/lawsuit/judgingbody" exact component={JudgingBody} isPrivate />
      <Route path="/lawsuit/judgingbody/create" exact component={AddEditJudgingBody} isPrivate />
      <Route path="/lawsuit/judgingbody/edit/:id" exact component={AddEditJudgingBody} isPrivate />

      <Route path="/lawsuit/deadline/create" exact component={AddEditDeadline} isPrivate />
      <Route path="/lawsuit/deadline/edit/:id" exact component={AddEditDeadline} isPrivate />

      <Route path="/lawsuit/financialitem/request/create" exact component={AddEditFinancialItem} isPrivate />
      <Route path="/lawsuit/financialitem/request/edit/:id" exact component={AddEditFinancialItem} isPrivate />

      <Route path="/lawsuit/financialitem/lawsuitfee/create" exact component={AddEditLawsuitFee} isPrivate />
      <Route path="/lawsuit/financialitem/lawsuitfee/edit/:id" exact component={AddEditLawsuitFee} isPrivate />

      <Route path="/lawsuit/financialitem/depositincourt/create" exact component={AddEditDepositInCourt} isPrivate />
      <Route path="/lawsuit/financialitem/depositincourt/edit/:id" exact component={AddEditDepositInCourt} isPrivate />

      <Route path="/lawsuit/financialitem/pawnedgoods/create" exact component={AddEditPawnedGoods} isPrivate />
      <Route path="/lawsuit/financialitem/pawnedgoods/edit/:id" exact component={AddEditPawnedGoods} isPrivate />

      <Route path="/lawsuit/linkedlawsuit/create" exact component={AddEditLinkedLawsuit} isPrivate />
      <Route path="/lawsuit/linkedlawsuit/edit/:id" exact component={AddEditLinkedLawsuit} isPrivate />

      <Route path="/lawsuit/linkedlawsuit/progress" exact component={LinkedLawsuitProgress} isPrivate />
      <Route path="/lawsuit/linkedlawsuit/progress/create" exact component={AddEditLinkedLawsuitProgress} isPrivate />
      <Route path="/lawsuit/linkedlawsuit/progress/edit/:id" exact component={AddEditLinkedLawsuitProgress} isPrivate />

      <Route path="/lawsuit/linkedlawsuittype" exact component={LinkedLawsuitType} isPrivate />
      <Route path="/lawsuit/linkedlawsuittype/create" exact component={AddEditLinkedLawsuitType} isPrivate />
      <Route path="/lawsuit/linkedlawsuittype/edit/:id" exact component={AddEditLinkedLawsuitType} isPrivate />

      <Route path="/lawsuit/outcome/create" exact component={AddEditOutcome} isPrivate />
      <Route path="/lawsuit/outcome/edit/:id" exact component={AddEditOutcome} isPrivate />

      <Route path="/lawsuit/procedure" exact component={Procedure} isPrivate />
      <Route path="/lawsuit/procedure/create" exact component={AddEditProcedure} isPrivate />
      <Route path="/lawsuit/procedure/edit/:id" exact component={AddEditProcedure} isPrivate />

      <Route path="/lawsuit/result" exact component={Result} isPrivate />
      <Route path="/lawsuit/result/create" exact component={AddEditResult} isPrivate />
      <Route path="/lawsuit/result/edit/:id" exact component={AddEditResult} isPrivate />

      <Route path="/lawsuit/reference" exact component={Reference} isPrivate />
      <Route path="/lawsuit/reference/create" exact component={AddEditReference} isPrivate />
      <Route path="/lawsuit/reference/edit/:id" exact component={AddEditReference} isPrivate />

      <Route path="/lawsuit/legalrequest" exact component={LegalRequest} isPrivate />
      <Route path="/lawsuit/legalrequest/create" exact component={AddEditLegalRequest} isPrivate />
      <Route path="/lawsuit/legalrequest/edit/:id" exact component={AddEditLegalRequest} isPrivate />

      <Route path="/lawsuit/deadline/create" exact component={AddEditLegalRequest} isPrivate />
      <Route path="/lawsuit/deadline/edit/:id" exact component={AddEditLegalRequest} isPrivate />

      <Route path="/finance/indexer" exact component={Indexer} isPrivate />
      <Route path="/finance/indexer/create" exact component={AddEditIndexer} isPrivate />
      <Route path="/finance/indexer/edit/:id" exact component={AddEditIndexer} isPrivate />

      <Route path="/finance/financialindex" exact component={FinancialIndex} isPrivate />
      <Route path="/finance/financialindex/create" exact component={AddEditFinancialIndex} isPrivate />
      <Route path="/finance/financialindex/edit/:id" exact component={AddEditFinancialIndex} isPrivate />

      {loggedUserLevel === adminId ? (
        <Route path="/management/reporthouremail/edit" exact component={EditReportHourEmail} isPrivate />
      ) : ('')}

      <Route path="/management/workinghour" exact component={WorkingHour} isPrivate />
      <Route path="/management/workinghour/create" exact component={AddEditWorkingHour} isPrivate />
      <Route path="/management/workinghour/duplicate" exact component={AddEditWorkingHour} isPrivate />
      <Route path="/management/workinghour/edit/:id" exact component={AddEditWorkingHour} isPrivate />
      <Route path="/management/workinghour/search" exact component={SearchHour} isPrivate />
      <Route path="/management/workinghour/pendingconclusion" exact component={PendingConclusionHour} isPrivate />

      <Route path="/report/lawsuit/summary" exact component={LawsuitSummaryReport} isPrivate />
      <Route path="/report/lawsuit/summary/search" exact component={SearchLawsuitSummary} isPrivate />

      {((reportiv === 1 || loggedUserLevel === adminId)) ? (
        <Route path="/report/lawsuit/summary/customizable" exact component={LawsuitSummaryCustomizableReport} isPrivate />
      ) : ('')}
      {((reportiv === 1 || loggedUserLevel === adminId)) ? (
        <Route path="/report/lawsuit/summary/customizable/search" exact component={SearchLawsuitCustomizableSummary} isPrivate />
      ) : ('')}

      <Route path="/report/lawsuit/simplified/excel" exact component={LawsuitSummaryReportExcel} isPrivate />
      <Route path="/report/lawsuit/simplified/excel/search" exact component={SearchLawsuitSummaryExcel} isPrivate />

      <Route path="/report/lawsuit/grouped/excel" exact component={LawsuitSummaryReportExcelGrouped} isPrivate />
      <Route path="/report/lawsuit/grouped/excel/search" exact component={SearchLawsuitSummaryExcelGrouped} isPrivate />

      <Route path="/report/financialinformation" exact component={FinancialInformationReport} isPrivate />
      <Route path="/report/financialinformation/search" exact component={SearchFinancialInformation} isPrivate />

      <Route path="/report/financialinformationmodIII" exact component={FinancialInformationModIIIReport} isPrivate />
      <Route path="/report/financialinformationmodIII/search" exact component={SearchFinancialModIIIInformation} isPrivate />

      {((reportiv === 1 || loggedUserLevel === adminId)) ? (
        <Route path="/report/hour/general" exact component={GeneralHourReport} isPrivate />
      ) : ('')}
      {((reportiv === 1 || loggedUserLevel === adminId)) ? (
        <Route path="/report/hour/general/selectedhour" exact component={SelectedHourReport} isPrivate />
      ) : ('')}
      {((reportiv === 1 || loggedUserLevel === adminId)) ? (
        <Route path="/report/hour/general/search" exact component={SearchGeneralHour} isPrivate />
      ) : ('')}

      {reportv === 1 ? (
        <Route path="/report/hour/customer" exact component={CustomerHourReport} isPrivate />
      ) : ('')}
      {reportv === 1 ? (
        <Route path="/report/hour/customer/search" exact component={SearchCustomerHour} isPrivate />
      ) : ('')}

      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/billed" exact component={BilledHourReport} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/billed/search" exact component={SearchBilledHour} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/billed/export" exact component={ExportBilledHourReport} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (

        <Route path="/report/hour/attorney/analytical" exact component={AttorneyAnalyticalHourReport} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/attorney/analytical/search" exact component={SearchAttorneyAnalyticalHour} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/attorney/simplified" exact component={AttorneySimplifiedHourReport} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/attorney/simplified/search" exact component={SearchAttorneySimplifiedHour} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/percentage" exact component={HourPercentageByCustomerReport} isPrivate />
      ) : ('')}
      {loggedUserLevel === adminId ? (
        <Route path="/report/hour/percentage/search" exact component={SearchHourPercentageByCustomer} isPrivate />
      ) : ('')}
    </Switch>
  );
};

export default Routes;
