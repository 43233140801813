/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from './styles';

interface ILinkedLawsuitProgress {
  proc_vinc_and_id: number;
  proc_vinc_and_proc_id: number;
  proc_vinc_and_data: Date;
  proc_vinc_and_andamento:string;
  proc_vinc_and_arquivo:string;
  proc_vinc_and_obs :string;
  proc_vinc_and_enviado:boolean;
}

const AddEditLinkedLawsuitProgress:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUploadRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataRef = useRef<any>(null);

  const [currentLawsuit, setCurrentLawsuit] = useState(0);
  const [currentLinkedLawsuitProgress, setCurrentLinkedLawsuitProgress] = useState(0);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');
  const vinc = params.get('vinc');
  const activeTab = params.get('active');

  const initialValues: ILinkedLawsuitProgress = {
    proc_vinc_and_id: Number(id),
    proc_vinc_and_proc_id: Number(vinc),
    proc_vinc_and_data: new Date(),
    proc_vinc_and_andamento: '',
    proc_vinc_and_arquivo: '',
    proc_vinc_and_obs: '',
    proc_vinc_and_enviado: false,
  };

  // control tab changes when leaving/returning to page
  useEffect(() => {
    // if (activeTab !== undefined || activeTab !== null) {
    //   setActiveIndex(Number(activeTab));
    // }
    if (cod !== undefined || cod !== null) {
      setCurrentLawsuit(Number(cod));
    }
    if (vinc !== undefined || vinc !== null) {
      setCurrentLinkedLawsuitProgress(Number(vinc));
    }
  }, [activeTab, cod, vinc]);

  // TODO: ajustar url retorno
  const returnLinkedLawsuitURL = `/lawsuit/linkedlawsuit/progress/?cod=${currentLawsuit}&vinc=${currentLinkedLawsuitProgress}&active=5`;

  const validationSchema = Yup.object().shape({
    proc_vinc_and_andamento: Yup.string().required('Descrição obrigatória'),
  });

  const handleCancel = () => {
    history.push(returnLinkedLawsuitURL);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleOnSubmit = async (
    values: ILinkedLawsuitProgress,
    { setSubmitting }: FormikHelpers<ILinkedLawsuitProgress>,
  ) => {
    if (isAddMode) {
      try {
        const formData = {
          proc_vinc_and_proc_id: Number(vinc),
          proc_vinc_and_data: progressDate,
          proc_vinc_and_andamento: values.proc_vinc_and_andamento,
          proc_vinc_and_arquivo: '',
          proc_vinc_and_obs: values.proc_vinc_and_obs,
          proc_vinc_and_enviado: false,
        };

        if (selectedFile && values.proc_vinc_and_arquivo === '') {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(selectedFile);
        }

        await api.post('/lawsuits/linkedlawsuitsprogresses/create', formData).then((response) => {
          setSubmitting(false);

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description:
              'As informações do cadastro foram atualizadas com sucesso.',
          });

          if (selectedFile && values.proc_vinc_and_arquivo === '') {
            const data = new FormData();

            data.append('proc_vinc_and_id', response.data.proc_vinc_and_id);
            if (selectedFile) {
              data.append('proc_vinc_and_arquivo', selectedFile);
            } else {
              data.append('arquivo', values.proc_vinc_and_arquivo);
            }

            api.patch('/lawsuits/linkedlawsuitsprogresses/file', data);

            addToast({
              type: 'info',
              title: 'Upload concluído!',
              description:
                  'Arquivo enviado com sucesso.',
            });
          }
        });

        history.push(returnLinkedLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        if (selectedFile && values.proc_vinc_and_arquivo === '') {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(selectedFile);
        }

        const formData = {
          proc_vinc_and_proc_id: Number(vinc),
          proc_vinc_and_data: progressDate,
          proc_vinc_and_andamento: values.proc_vinc_and_andamento,
          proc_vinc_and_arquivo: values.proc_vinc_and_arquivo === '' ? '' : values.proc_vinc_and_arquivo,
          proc_vinc_and_obs: values.proc_vinc_and_obs,
          proc_vinc_and_enviado: false,
        };

        await api.put(`/lawsuits/linkedlawsuitsprogresses/edit/${id}`, formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        if (selectedFile && values.proc_vinc_and_arquivo === '') {
          const data = new FormData();

          data.append('proc_vinc_and_id', id);
          if (selectedFile) {
            data.append('proc_vinc_and_arquivo', selectedFile);
          } else {
            data.append('arquivo', values.proc_vinc_and_arquivo);
          }

          api.patch('/lawsuits/linkedlawsuitsprogresses/file', data);

          addToast({
            type: 'info',
            title: 'Upload concluído!',
            description:
                'Arquivo enviado com sucesso.',
          });
        }

        history.push(returnLinkedLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                `Tente repetir o cadastro ou contate o administrador. ${error}`,
        });
      }
    }
  };

  const handleFileDelete = (values: ILinkedLawsuitProgress) => {
    try {
      const data = new FormData();

      data.append('proc_vinc_and_id', id);
      data.append('arquivo', values.proc_vinc_and_arquivo);

      api.patch('/lawsuits/linkedlawsuitsprogresses/file', data);

      addToast({
        type: 'info',
        title: 'Arquivo excluído!',
        description:
              'Arquivo excluído com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
          'O arquivo não foi encontrado para ser excluído.',
      });
    }
  };

  const handleSetFile = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: { files: any[]; }) => {
      setSelectedFile(e.files[0]);
    },
    [],
  );

  const handleFileChange = useCallback(
    (e) => {
      if (e.files.length > 0) {
        setSelectedFile(e.files[0]);
      }
    },
    [],
  );

  const [progress, setProgresses] = useState(null);
  // const [progressDate, setProgressDate] = useState<Date | Date[] | undefined>(new Date());
  const [progressDate, setProgressDate] = useState<string | Date | Date[] | null>(null);

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            andamento do processo vinculado
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          innerRef={dataRef}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={progress || initialValues}
        >
          {({
            values,
            dirty,
            isValid,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              (async () => {
                try {
                  if (!isAddMode) {
                    api.get(`/lawsuits/linkedlawsuitsprogresses/show/${id}`).then((response) => {
                      setValues(response.data);
                      setProgresses(response.data);

                      if (response.data.proc_vinc_and_data !== null) {
                        const current = new Date(response.data.proc_vinc_and_data);
                        const utc = new Date(
                          current.getTime()
                          + current.getTimezoneOffset()
                          * 60000,
                        );

                        // setFieldValue('proc_vinc_and_data', new Date(utc).toString());
                        setProgressDate(new Date(utc));
                      }
                    });
                  }
                } catch (e) {
                  addToast({
                    type: 'error',
                    title: 'Ocorreu um problema',
                    description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                  });
                }
              })();
            }, [setValues, setFieldValue]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="proc_vinc_and_data">
                          Data *
                        </label>
                        <Calendar
                          inputId="proc_vinc_and_data"
                          name="proc_vinc_and_data"
                          className="inputfield w-full"
                          value={progressDate}
                          // onChange={(e) => setProgressDate(e.value)}
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setProgressDate(e.value);
                            }
                          }}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                          showTime={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_vinc_and_andamento">
                          Descrição do andamento *
                        </label>
                        <InputTextarea
                          id="proc_vinc_and_andamento"
                          name="proc_vinc_and_andamento"
                          className="inputfield w-full"
                          value={values.proc_vinc_and_andamento}
                          onChange={handleChange}
                          rows={5}
                          cols={30}
                        />
                        <ErrorMessage name="proc_vinc_and_andamento">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>

                    { values.proc_vinc_and_arquivo === '' ? (
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_vinc_and_arquivo">
                          Arquivo para anexar com o andamento
                        </label>
                        <FileUpload
                          ref={fileUploadRef}
                          mode="basic"
                          name="proc_vinc_and_arquivo[]"
                          id="proc_vinc_and_arquivo"
                          accept=".doc,.docx,.pdf,.jpeg,.jpg"
                          maxFileSize={15000000}
                          chooseLabel="CLIQUE AQUI para selecionar arquivo para upload"
                          onSelect={(e) => handleSetFile(e)}
                          customUpload
                          uploadHandler={handleFileChange}
                        />
                      </div>
                    ) : (
                      <div className="col-12 md:col-12">
                        Arquivo anexo:
                        {' '}
                        <span className="p-mr-2 red">
                          {values.proc_vinc_and_arquivo}
                        </span>
                        <Button
                          icon="pi pi-trash"
                          tooltip="Excluir cadastro"
                          tooltipOptions={{ position: 'left' }}
                          className="p-button-rounded p-button-warning"
                          title="Excluir este arquivo"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleFileDelete(values);
                            setFieldValue('proc_vinc_and_arquivo', '');
                          }}
                        />
                      </div>
                    ) }
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        type="button"
                        className="p-button-danger"
                        onClick={handleCancel}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditLinkedLawsuitProgress;
