import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useRef } from "react";
import { FiLock, FiMail } from "react-icons/fi"; // FiLogIn
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import getValidationErrors from "../../utils/getValidationErrors";

import logoImg from "../../assets/logo.png";
import { AnimationContainer, Background, Container, Content } from "./styles";

import Button from "../../components/Button";
import Input from "../../components/Input";

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required("E-mail/nome de usuário obrigatório"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push("/lawsuit/main/search");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response.status === 412) {
          addToast({
            type: "error",
            title: "E-mail em duplicidade",
            description:
              "Informe o login ao invés do e-mail para acessar o sistema.",
          });
        } else {
          addToast({
            type: "error",
            title: "Erro na autenticação",
            description:
              "Ocorreu um erro ao fazer login, verifique as credenciais",
          });
        }
      }
    },
    [signIn, addToast, history]
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <hr />
          <img src={logoImg} alt="JurisNET 2.0" width="320" height="46" />
          <hr />
          <p className="systemText">
            Jurisnet <span className="versionText">2.0</span>
          </p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça o seu logon</h1>
            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail ou nome de usuário"
            />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />
            <Button type="submit">Entrar</Button>

            <Link to="forgot-password">Esqueci minha senha</Link>
          </Form>

          {/* <Link to="/signup">
            <FiLogIn />
            Criar conta
          </Link> */}
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};
export default SignIn;
