/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from '../styles';

interface IFinancialRequests {
  pedido_proc_id: number;
  pedido_descricao: string;
  pedido_possibilidade: number;
  pedido_vl_demandado: number;
  pedido_vl_provavel: number;
  pedido_vl_possivel: number;
  pedido_vl_possivel2: number;
  cod_indexador: number;
  data_inicio_monetario: Date | undefined;
  data_inicio_juros: Date | undefined;
  correcao_monetaria: string;
  juros_simples: string;
  id_descricao_pedido: number;
  val_juros_atualizado: number;
  val_calculo_indice: number;
  val_juros_provavel: number;
  val_juros_possivel: number;
  val_juros_possivel2: number;
  val_calculo_provavel: number;
  val_calculo_possivel: number;
  val_calculo_possivel2: number;
  data_pedido: Date | undefined;
  cod_indexador2: number;
  data_inicio_monetario2: Date | undefined;
  cod_indexador3: number;
  data_inicio_monetario3: Date | undefined;
  cod_indexador4: number;
  data_inicio_monetario4: Date | undefined;
  cod_indexador5: number;
  data_inicio_monetario5: Date | undefined;
  negativo: number;
}

interface ILegalRequestProperties {
  it_financeiros_pedidos_descricao_id: number | null;
  it_financeiros_pedidos_descricao: string;
}

const AddEditRequest:React.FC = () => {
  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');

  const returnLawsuitURL = `/lawsuit/main/edit/${cod}?active=2`;

  const initialValues: IFinancialRequests = {
    pedido_proc_id: Number(cod),
    pedido_descricao: '',
    pedido_possibilidade: 0,
    pedido_vl_demandado: 0,
    pedido_vl_provavel: 0,
    pedido_vl_possivel: 0,
    pedido_vl_possivel2: 0,
    cod_indexador: 0,
    data_inicio_monetario: undefined,
    data_inicio_juros: undefined,
    correcao_monetaria: '',
    juros_simples: '',
    id_descricao_pedido: 0,
    val_juros_atualizado: 0,
    val_calculo_indice: 0,
    val_juros_provavel: 0,
    val_juros_possivel: 0,
    val_juros_possivel2: 0,
    val_calculo_provavel: 0,
    val_calculo_possivel: 0,
    val_calculo_possivel2: 0,
    data_pedido: undefined,
    cod_indexador2: 0,
    data_inicio_monetario2: undefined,
    cod_indexador3: 0,
    data_inicio_monetario3: undefined,
    cod_indexador4: 0,
    data_inicio_monetario4: undefined,
    cod_indexador5: 0,
    data_inicio_monetario5: undefined,
    negativo: 0,

  };

  const possibilityOptions = [
    { name: 'Êxito', value: 1 },
    { name: 'Perda', value: 2 },
  ];

  const yesNoOptions = [
    { name: 'Sim', value: '1' },
    { name: 'Não', value: '0' },
  ];

  // const validationSchema = Yup.object().shape({
  //   natureza_interv_descricao: Yup.string().required('Descrição obrigatória'),
  // });

  const [legalRequestsOptions, setLegalRequestsOptions] = useState([{}]);
  const [legalRequests, setLegalRequests] = useState<ILegalRequestProperties[]>([]);
  const [indexersOptions, setIndexersOptions] = useState([{}]);
  const [monetaryDate, setMonetaryDate] = useState<Date | Date[] | undefined>(undefined);
  const [
    interestsStartingDate, setInterestsStartingDate,
  ] = useState<Date | Date[] | undefined>(undefined);
  const [requestDate, setRequestDate] = useState<Date | Date[] | undefined>(undefined);
  const [requestDescriptionId, setRequestDescriptionId] = useState(0);
  const [monetary, setMonetary] = useState(0);
  const [interests, setInterests] = useState(0);
  const [indexerId, setIndexerId] = useState(0);
  // const [value0, setValue0] = useState(0.00);
  // const [value1, setValue1] = useState(0.00);
  // const [value2, setValue2] = useState(0.00);
  // const [value3, setValue3] = useState(0.00);

  const [financialRequestData, setFinancialRequestData] = useState(null);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        api.get(`/lawsuits/financialrequests/show/${id}`).then((response) => {
          setFinancialRequestData(response.data[0]);

          setRequestDescriptionId(response.data[0].id_descricao_pedido);
          setIndexerId(response.data[0].cod_indexador);
          setMonetary(response.data[0].correcao_monetaria);
          setInterests(response.data[0].juros_simples);

          const monetaryDateBR = new Date(response.data[0].data_inicio_monetario);

          if (response.data[0].data_inicio_monetario !== null) {
            const monetaryDateISO = new Date(monetaryDateBR.toISOString().slice(0, -1));
            if (monetaryDateISO.toString() === '01/01/1970') {
              setInterestsStartingDate(undefined);
            }
            setMonetaryDate(monetaryDateISO);
          }

          const interestsStartingDateBR = new Date(response.data[0].data_inicio_juros);
          if (response.data[0].data_inicio_juros !== null) {
            const interestsStartingDateISO = new Date(interestsStartingDateBR
              .toISOString()
              .slice(0, -1));
            if (interestsStartingDateISO.toString() === '01/01/1970') {
              setInterestsStartingDate(undefined);
            }
            setInterestsStartingDate(interestsStartingDateISO);
          }

          const requestDateBR = new Date(response.data[0].data_pedido);
          if (response.data[0].data_pedido !== null) {
            const requestDateISO = new Date(requestDateBR.toISOString().slice(0, -1));
            if (requestDateISO.toString() === '01/01/1970') {
              setInterestsStartingDate(undefined);
            }
            setRequestDate(requestDateISO);
          }
        });
      }
    })();
  }, [id, isAddMode, requestDescriptionId, indexerId]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/legalrequests/show/all').then((response) => {
        setLegalRequests(response.data);
        response.data.map(
          (legalrequests: {
            it_financeiros_pedidos_descricao_id: number,
            it_financeiros_pedidos_descricao:string
          }) => (
            legalRequestsOptions.push({
              name: legalrequests.it_financeiros_pedidos_descricao,
              value: legalrequests.it_financeiros_pedidos_descricao_id,
            })
          ),
        );
      });
      legalRequestsOptions.splice(0, 1);
      setLegalRequestsOptions(legalRequestsOptions);
    })();
  }, [legalRequestsOptions]);

  useEffect(() => {
    (async () => {
      await api.get('finance/indexers/show/all').then((response) => {
        response.data.map(
          (indexers: {
            indice_id: number,
            indice_nome:string
          }) => (
            indexersOptions.push({
              name: indexers.indice_nome,
              value: indexers.indice_id,
            })
          ),
        );
      });
      indexersOptions.splice(0, 1);
      setIndexersOptions(indexersOptions);
    })();
  }, [indexersOptions]);

  const handleOnSubmit = async (
    values: IFinancialRequests,
    { setSubmitting }: FormikHelpers<IFinancialRequests>,
  ) => {
    if (isAddMode) {
      try {
        if (values.pedido_descricao.length < 1) {
          toast.current?.show({
            severity: 'error',
            summary: 'Descrição incompleta',
            detail: 'Selecione a descrição do pedido.',
            life: 5000,
          });
          // document.form_request.id_descricao_pedido.focus();
          // .getElementById('id_descricao_pedido')?.focus();
          return;
        }

        await api.post('/lawsuits/financialrequests/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        if (values.pedido_possibilidade !== null) {
          await api.put(`/lawsuits/financialrequests/edit/${id}`, values);
          setSubmitting(false);

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description:
                'As informações do cadastro foram atualizadas com sucesso.',
          });

          history.push(returnLawsuitURL);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um problema',
            description:
                  'Possibilidade geral do processo não cadastrado. Operação cancelada',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            pedido
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          // validationSchema={validationSchema}
          enableReinitialize
          initialValues={financialRequestData || initialValues}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <input type="hidden" id="pedido_descricao" name="pedido_descricao" value="" />
              <div className="fluid formgrid grid">
                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-3">
                      <label htmlFor="id_descricao_pedido">
                        Descrição do pedido *
                      </label>
                      <Dropdown
                        id="id_descricao_pedido"
                        name="id_descricao_pedido"
                        value={values.id_descricao_pedido}
                        options={legalRequestsOptions}
                        onChange={(e) => {
                          setFieldValue('id_descricao_pedido', e.value);
                          const filteredText = legalRequests.filter((item) => (
                            item.it_financeiros_pedidos_descricao_id === e.value
                          ), []);
                          setFieldValue('pedido_descricao', filteredText[0].it_financeiros_pedidos_descricao);
                        }}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                        autoFocus
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <label htmlFor="pedido_vl_demandado">
                        Valor do pedido
                      </label>
                      <InputNumber
                        id="pedido_vl_demandado"
                        name="pedido_vl_demandado"
                        className="inputfield w-full"
                        value={values.pedido_vl_demandado}
                        onValueChange={handleChange}
                        placeholder="0,00"
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        locale="pt-BR"
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <label htmlFor="pedido_possibilidade">
                        Possibilidade
                      </label>
                      <SelectButton
                        id="pedido_possibilidade"
                        value={values.pedido_possibilidade}
                        options={possibilityOptions}
                        optionLabel="name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 p-mt-20">
                  <div className="field grid">
                    <div className="col-12">
                      <h2>Possibilidade de Perda</h2>
                    </div>
                    <div className="col-12  md:col-12">
                      <div className="col-12 md:col-3">
                        <label htmlFor="pedido_vl_provavel">
                          Provável (≥ 80%)
                        </label>
                        <InputNumber
                          id="pedido_vl_provavel"
                          name="pedido_vl_provavel"
                          className="inputfield w-full"
                          value={values.pedido_vl_provavel}
                          onValueChange={handleChange}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          locale="pt-BR"
                        />
                      </div>
                    </div>
                    <div className="col-12  md:col-12">
                      <div className="col-12 md:col-3">
                        <label htmlFor="pedido_vl_possivel">
                          Possível (&gt; 50%)
                        </label>
                        <InputNumber
                          id="pedido_vl_possivel"
                          name="pedido_vl_possivel"
                          className="inputfield w-full"
                          value={values.pedido_vl_possivel}
                          onValueChange={handleChange}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          locale="pt-BR"
                        />
                      </div>
                    </div>
                    <div className="col-12  md:col-12">
                      <div className="col-12 md:col-3">
                        <label htmlFor="pedido_vl_possivel2">
                          Possível (≤ 50%)
                        </label>
                        <InputNumber
                          id="pedido_vl_possivel2"
                          name="pedido_vl_possivel2"
                          className="inputfield w-full"
                          value={values.pedido_vl_possivel2}
                          onValueChange={handleChange}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          locale="pt-BR"
                        />
                      </div>
                    </div>
                    <div className="col-12 md:col-12 p-mt-20">
                      <div className="col-12">
                        <h2>Correção monetária e Juros</h2>
                      </div>
                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-3">
                            <label htmlFor="correcao_monetaria">
                              Correção Monetária *
                            </label>
                            <SelectButton
                              id="correcao_monetaria"
                              value={values.correcao_monetaria}
                              options={yesNoOptions}
                              optionLabel="name"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-12 md:col-3">
                            <label htmlFor="data_inicio_monetario">
                              Data de início da C.M.
                              <Calendar
                                inputId="data_inicio_monetario"
                                name="data_inicio_monetario"
                                className="inputfield w-full"
                                value={
                                      isAddMode
                                        ? undefined
                                        : monetaryDate
                                    }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                keepInvalid
                                showIcon
                                showButtonBar
                                showOnFocus={false}
                                mask="99/99/9999"
                                dateFormat="dd/mm/yy"
                                yearRange="1980:2030"
                              />
                            </label>
                          </div>
                          <div className="col-12 md:col-3">
                            <label htmlFor="cod_indexador">
                              Indexador
                            </label>
                            <Dropdown
                              id="cod_indexador"
                              name="cod_indexador"
                              value={values.cod_indexador}
                              options={indexersOptions}
                              onChange={(e) => setFieldValue('cod_indexador', e.value)}
                              className="inputfield w-full"
                              optionLabel="name"
                              optionValue="value"
                              filter
                              filterBy="name"
                              placeholder="Selecione uma opção"
                              emptyFilterMessage="Nenhum registro encontrado"
                            />
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="juros_simples">
                          Juros *
                        </label>
                        <SelectButton
                          id="juros_simples"
                          value={values.juros_simples}
                          options={yesNoOptions}
                          optionLabel="name"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-12 md:col-3">
                        <label htmlFor="data_inicio_juros">
                          Data de início dos juros
                          <Calendar
                            inputId="data_inicio_juros"
                            name="data_inicio_juros"
                            className="inputfield w-full"
                            value={
                              isAddMode
                                ? undefined
                                : interestsStartingDate
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="data_pedido" className="left-padding">
                          Data do possível pagamento ou recebimento
                        </label>
                        <div className="col-12 md:col-6 left-padding">
                          <Calendar
                            inputId="data_pedido"
                            name="data_pedido"
                            className="inputfield w-full"
                            value={
                                    isAddMode
                                      ? undefined
                                      : requestDate
                                  }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={handleCancel}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditRequest;
