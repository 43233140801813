import React, {
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import moment from 'moment';
// import robotoRegular from '../../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../services/api';

// import CompanyLogo from '../../../../assets/logo.png';

interface IBilledHours {
  row_number: number;
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_descricao: string;
  horas_data: Date;
  horas_hora_inicial: Date;
  horas_hora_final: Date;
  horas_documento: String;
  horas_data_alteracao: Date;
  horas_hora_total: String;
  horas_tipo: String;
  advogado_nome: string;
  administrador_nome: string;
  cliente_nome: string;
  valor_ajuste_nao_faturado: number;
}

interface ICustomers {
  horas_cod_cliente: number;
  cliente_nome: string;
  horas_hora_total: string;
}

const HourByCustomerReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  const [groupedHours, setGroupedHours] = useState<ICustomers[]>([]);
  const [totalTime, setTotalTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/reports/hour/groupbycustomer', state).then((response) => {
          const customers: {
            horas_cod_cliente: number; cliente_nome: string; horas_hora_total: string;
          }[] = [];

          const filteredIds: number[] = [];
          const totalDurations: string[] = [];

          response.data.forEach(async (data: IBilledHours, index: number) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let horas_total = '';
            let total_de_horas = '';
            let total_h1 = 0;
            let total_m1 = 0;

            if (index === 0) {
              filteredIds.push(data.horas_cod_cliente);
            } else {
              const found = filteredIds.find((element) => element === data.horas_cod_cliente);
              if (!found) {
                filteredIds.push(data.horas_cod_cliente);
              } else {
                return;
              }
            }

            const filteredCustomer = response.data.filter((request:
                { horas_cod_cliente: number;
                }) => request.horas_cod_cliente === data.horas_cod_cliente);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filteredCustomer.forEach((filtered: any) => {
              if (!filtered.horas_hora_total) {
                horas_total = '0:00';
              } else {
                const horas_total_tmp = filtered.horas_hora_total.split(':');

                if (Number(horas_total_tmp[0]) >= 10) {
                  horas_total = `${horas_total_tmp[0]}:`;
                } else {
                  horas_total = `0${horas_total_tmp[0]}:`;
                }

                if (Number(horas_total_tmp[1]) > 0) {
                  if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                    horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
                  } else {
                    horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                  }
                } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  horas_total += '00';
                }

                total_h1 += Number(horas_total_tmp[0]);

                if (Number(horas_total_tmp[1]) > 0) {
                  total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
                }
              }

              if (total_m1 >= 60) {
                total_h1 += 1;
                total_m1 -= 60;
              }

              if (total_h1 >= 10) {
                total_de_horas = `${total_h1}`;
              } else {
                total_de_horas = `0${total_h1}`;
              }

              if (total_m1 >= 10) {
                total_de_horas += `:${total_m1}`;
              } else {
                total_de_horas += `:0${total_m1}`;
              }
            });

            totalDurations.push(total_de_horas);
            customers.push({ horas_cod_cliente: data.horas_cod_cliente, cliente_nome: data.cliente_nome, horas_hora_total: `${total_de_horas}` });
          });

          setGroupedHours(customers);

          const ms = totalDurations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
          const sum = ms.reduce((prev, cur) => prev + cur, 0);

          const d = moment.duration(sum, 'milliseconds');
          const hours = Math.floor(d.asHours());
          const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
          const time = `${hours}:${minutes}`;

          setTotalTime(time);
        });

        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, state]);

  const editHour = (rowData: DataTableValue) => {
    const searchFormData = {
      proc_cod_cliente: rowData.horas_cod_cliente,
      sector: state.sector,
      inCharge: state.inCharge,
      referenceId: state.referenceId,
      description: state.description,
      billedHour: state.billedHour,
      proc_tipo: state.proc_tipo,
      proc_data_ajuiz_inicio: state.proc_data_ajuiz_inicio,
      proc_data_ajuiz_final: state.proc_data_ajuiz_final,
      dateFilterType: state.dateFilterType,
      menu: state.menu,
      report_number: 'V',
    };
    history.push('/report/hour/general', searchFormData);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { editHour(rowData); }}
        tooltip="Gerenciar horas"
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(groupedHours);
      worksheet.A1.v = 'Num.';
      worksheet.B1.v = 'Nome do Cliente';
      worksheet.C1.v = 'Horas';
      const workbook = { Sheets: { 'Horas Agrupadas': worksheet }, Title: { 0: '1', 1: '2', 2: '3' }, SheetNames: ['Horas Agrupadas'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'RelatorioHorasAgrupadasPorCliente');
    });
  };

  const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
      }
    });
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        tooltip="Exportar para Excel"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de horas:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
        <Column footer={totalTime} colSpan={2} footerStyle={{ textAlign: 'left' }} />
      </Row>
    </ColumnGroup>
  );

  return (
    <Container>
      <header>
        <div>
          <h3>Relatório de Horas Agrupadas por Cliente - Modelo V</h3>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <div className="datatable-responsive">
            <Tooltip target=".export-buttons>button" position="bottom" />

            <DataTable
              tableClassName="table-groupedhours table"
              id="table-groupedhours"
              ref={dt}
              loading={loading}
              value={groupedHours}
              editMode="row"
              dataKey="horas_cod_cliente"
              header={header}
              stripedRows
              className="p-datatable-sm table-pendinghours"
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
              rows={groupedHours.length}
              rowsPerPageOptions={[10, 20, 50, groupedHours.length]}
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              responsiveLayout="scroll"
              footerColumnGroup={footerGroup}
              onRowDoubleClick={(e) => editHour(e.data)}
            >
              <Column key="row_number" field="row_number" header="Núm." alignHeader="center" body={counterBodyTemplate} align="center" style={{ width: '50px' }} />
              <Column field="cliente_nome" header="Cliente" alignHeader="center" align="left" />
              <Column field="horas_hora_total" header="Horas" alignHeader="center" align="center" style={{ width: '80px' }} />

              <Column body={actionBodyTemplate} headerStyle={{ width: '160px' }} />
            </DataTable>
          </div>
        </div>
      </div>

    </Container>
  );
};

export default HourByCustomerReport;
