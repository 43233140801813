import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

 interface IJudgingBodyProperties {
  orgao_id: number | null;
  orgao_descricao: string;
}

const JudgingBody: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyJudgingBody = {
    orgao_id: null,
    orgao_descricao: '',
  };

  const [judgingBodies, setJudgingBodies] = useState<IJudgingBodyProperties[]>([]);
  const [judgingBody, setJudgingBody] = useState(emptyJudgingBody);
  const [deleteJudgingBody, setDeleteGroupDialog] = useState(false);
  const [deleteJudgingBodiesDialog, setDeleteBodiesDialog] = useState(false);
  const [selectedJudgingBodies, setSelectedJudgingBodies] = useState<IJudgingBodyProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await api.get('/lawsuits/judgingbodies/show/all');
      setJudgingBodies(response.data);
    })();
  }, []);

  const columns = [
    { field: 'orgao_id', header: 'Código', sortable: true },
    { field: 'orgao_descricao', header: 'Descrição', sortable: true },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable
    />
  ));

  const handleCreate = () => {
    history.push('/lawsuit/judgingbody/create');
  };

  const hidedeleteJudgingBody = () => {
    setDeleteGroupDialog(false);
  };

  const hidedeleteJudgingBodiesDialog = () => {
    setDeleteBodiesDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editJudgingBody = (rowData:any) => {
    history.push(`/lawsuit/judgingbody/edit/${rowData.orgao_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteJudgingBody = (judgingBody: React.SetStateAction<{
    orgao_id: null; orgao_descricao: string;
  }>) => {
    setJudgingBody(judgingBody);
    setDeleteGroupDialog(true);
  };

  const deleteGroup = () => {
    api.delete(`/lawsuits/judgingbodies/delete/${judgingBody.orgao_id}`);

    setDeleteGroupDialog(false);

    const updatedRecords = judgingBodies
      .filter((val) => val.orgao_id !== judgingBody.orgao_id);
    setJudgingBodies(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editJudgingBody(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteJudgingBody(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteJudgingBodyFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteJudgingBody} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGroup} />
    </>
  );
  const deleteJudgingBodiesDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteJudgingBodiesDialog} />
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Orgãos julgadores</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-judgingBodies table"
            id="table-judgingBodies"
            ref={dt}
            value={judgingBodies}
            // autoLayout
            editMode="row"
            dataKey="orgao_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedJudgingBodies}
            onSelectionChange={(e) => {
              const selJudgingBodies = e.value as IJudgingBodyProperties[];
              setSelectedJudgingBodies(selJudgingBodies);
            }}
            onRowDoubleClick={(e) => editJudgingBody(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteJudgingBody} style={{ width: '450px' }} header="Confirmação" modal footer={deleteJudgingBodyFooter} onHide={hidedeleteJudgingBody}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {judgingBody && (
              <span>
                Você tem certeza que deseja excluir
                {' '}
                <b>{judgingBody.orgao_descricao}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteJudgingBodiesDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteJudgingBodiesDialogFooter} onHide={hidedeleteJudgingBodiesDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {judgingBody
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default JudgingBody;
