import React from 'react';

import PrimeReact from 'primereact/api';
import { Container } from './styles';

import Possibilities from './Possibility';
import Requests from './Request';
import LawsuitFees from './LawsuitFee';
import DepositsInCourt from './DepositInCourt';
import PawnedGoods from './PawnedGoods';

const FinancialItems: React.FC = () => {
  PrimeReact.ripple = true;

  return (
    <Container>
      <div className="datatable-responsive">
        <Possibilities />

        <Requests />

        <LawsuitFees />

        <DepositsInCourt />

        <PawnedGoods />
      </div>

    </Container>

  );
};

export default FinancialItems;
