/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
import React, { useCallback, useEffect, useRef, useState } from "react";

import PrimeReact from "primereact/api";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { useHistory, useLocation } from "react-router-dom";

import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";

import { format } from "date-fns";
import { round } from "lodash";
import moment from "moment";

import robotoRegular from "../../../../utils/fonts/roboto-regular-normal-jspdf";

import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import { Container } from "./styles";

import CompanyLogo from "../../../../assets/logo_pabst_e_hadlich.png";

interface IBilledHours {
  row_number: number;
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_descricao: string;
  hora_data: Date;
  horas_fatu_de: Date;
  horas_fatu_ate: Date;
  horas_hora_inicial: Date;
  horas_hora_final: Date;
  horas_documento: string;
  horas_data_alteracao: Date;
  horas_hora_total: String;
  horas_tipo: String;
  advogado_nome: string;
  cliente_nome: string;
  administrador_nome: string;
  valor_ajuste_nao_faturado: number;
}

interface IData {
  row_number: number;
  horas_data: string;
  advogado_nome: string;
  horas_descricao: string;
  horas_hora_total: string;
  horas_decimal: string;
  horas_fatu_de: string;
  horas_fatu_ate: string;
  valor_ajuste_nao_faturado: number;
}

const ExportBilledHourReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let initialSearchDate = "";
  let finalSearchDate = "";
  let documentNumber = "";
  // let clientName = '';
  const [clientName, setClienteName] = useState("");

  if (state) {
    initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format(
      "DD/MM/YYYY"
    );
    finalSearchDate = moment(state?.proc_data_ajuiz_final).format("DD/MM/YYYY");
    documentNumber = state?.doc;
    if (!state?.cliente_nome) {
      (async () => {
        await api
          .get(`/customers/show/${state?.proc_cod_cliente}`)
          .then((response) => {
            setClienteName(response.data.cliente_nome);
          });
      })();
    }
  }

  const [billedHours, setBilledHours] = useState<IBilledHours[]>([]);
  const [hoursReport, setHoursReport] = useState<IData[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedHours, setSelectedHours] = useState<IBilledHours[]>([]);
  const [billedPeriod, setBilledPeriod] = useState("");

  const [totalTime, setTotalTime] = useState("");
  const [totalDecimalTime, setTotalDecimalTime] = useState("");
  const [valuePerHour, setValuePerHour] = useState("0.00");
  const [totalValuePeriod, setTotalValuePeriod] = useState("0.00");
  const [totalAddedValuePeriod, setTotalAddedValuePeriod] = useState("0.00");

  const [loading, setLoading] = useState(true);
  const dt = useRef(null);
  const globalCategoryValue = useRef(0);
  const totalAdjustedDecimalTime = useRef(0.0);
  const adjustment = useRef("");
  const enteredAdjustmentValue = useRef(0.0);

  // TODO adjust later from management screen
  localStorage.setItem("@Jurisnet:a", "1");

  const convertDecimalToHour = useCallback((totalHour: string) => {
    let horas_total = "";
    let total_de_horas = "";
    let horas_decimal = "0";
    let total_h1 = 0;
    let total_m1 = 0;

    if (!totalHour) {
      horas_total = "0:00";
    } else {
      const horas_total_tmp = totalHour.split(":");

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
            .toFixed(0)
            .toString();
        } else {
          horas_total += `0${(Number(horas_total_tmp[1]) / 60) * 100}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += "00";
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(
          ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
        );
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = horas_total_tmp[0].toString();
      } else {
        horas_decimal = "0";
      }

      const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);
        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += ".00";
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = "0.00";
      }
    }

    return total_de_horas;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let maximum = 0;
        await api
          .get(
            `/management/workinghours/documentnumber/${state?.proc_cod_cliente}`
          )
          .then((responseCustomer) => {
            maximum = Number(responseCustomer.data[0].max) + 1;
          });

        await api.post("/reports/hour/general/asc", state).then((response) => {
          setBilledHours(response.data);

          const hoursArray: IData[] = [];

          const durations: string[] = [];
          let valor_categoria = 0.0;
          let total_do_periodo = 0.0;
          let nonBilledValue = 0.0;

          let ind = 0;

          let maximumString = "";
          let customerId = 0;
          let customerIdString = "";
          const currentYear = new Date().getFullYear().toString();
          let documentHourString = "";
          const invoice = localStorage.getItem("@Jurisnet:invoice");
          let horas_decimal_total = 0.0;
          let total_ajuste_decimal = 0.0;

          response.data.forEach(async (data: IBilledHours, index: number) => {
            const referenceId = data.horas_cod_referencia
              ? data.horas_cod_referencia
              : 0;
            nonBilledValue = data.valor_ajuste_nao_faturado
              ? data.valor_ajuste_nao_faturado
              : 0;

            total_ajuste_decimal += Number(nonBilledValue);

            let horas_total = "";
            let total_de_horas = "";
            let horas_decimal = "0";
            let total_h1 = 0;
            let total_m1 = 0;

            if (!data.horas_hora_total) {
              horas_total = "0:00";
            } else {
              const horas_total_tmp = data.horas_hora_total.split(":");

              if (Number(horas_total_tmp[0]) >= 10) {
                horas_total = `${horas_total_tmp[0]}:`;
              } else {
                horas_total = `0${horas_total_tmp[0]}:`;
              }

              if (Number(horas_total_tmp[1]) > 0) {
                if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
                  horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
                    .toFixed(0)
                    .toString();
                } else {
                  horas_total += `0${(Number(horas_total_tmp[1]) / 60) * 100}`;
                }
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                horas_total += "00";
              }

              total_h1 += Number(horas_total_tmp[0]);

              if (Number(horas_total_tmp[1]) > 0) {
                total_m1 += Number(
                  ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
                );
              }

              if (Number(horas_total_tmp[0]) > 0) {
                horas_decimal = Number(horas_total_tmp[0]).toString();
              } else {
                horas_decimal = "0";
              }

              const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

              if (minutos > 0) {
                let temp = ((minutos * 100) / 60).toFixed(0);
                // let temp = ((minutos * 100) / 60).toString().replace(',', '');
                if (Number(temp) < 10) {
                  temp = `0${temp}`;
                }

                horas_decimal += `.${temp}`;
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                horas_decimal += ".00";
              }
            }

            if (total_m1 >= 60) {
              total_h1 += 1;
              total_m1 -= 60;
            }

            if (total_h1 >= 10) {
              total_de_horas = `${total_h1}`;
            } else {
              total_de_horas = `0${total_h1}`;
            }

            if (total_m1 >= 10) {
              total_de_horas += `:${total_m1}`;
            } else {
              total_de_horas += `:0${total_m1}`;

              if (Number(horas_decimal) === 0) {
                horas_decimal = "0.00";
              }
            }

            durations.push(total_de_horas);

            // general info and index adjustments
            // busca o valor individual cobrado pela HORA
            await api
              .post(
                `/reports/hour/general/referencevalues/false/${referenceId}`,
                state
              )
              .then((responseRef) => {
                valor_categoria = Number(responseRef.data[0].horas_valor_hora);
                globalCategoryValue.current = Number(
                  responseRef.data[0]?.horas_valor_hora
                );
              });

            total_do_periodo += round(
              (nonBilledValue + Number(horas_decimal)) * valor_categoria,
              2
            );

            horas_decimal_total += Number(horas_decimal);

            if (ind === response.data.length - 1) {
              const newPeriodValue =
                Number(round(total_do_periodo, 2)) +
                Number(adjustment.current.replace(",", ".")) *
                  Number(globalCategoryValue.current);
              const formattedPeriod = newPeriodValue?.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const formattedCategoryValue = valor_categoria?.toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );

              const formattedtotalDecimalTime =
                horas_decimal_total?.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              const formattedNonBilledValue =
                total_ajuste_decimal?.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

              const newAdjustedTime =
                Number(formattedtotalDecimalTime.replace(",", ".")) +
                Number(formattedNonBilledValue.replace(",", "."));
              totalAdjustedDecimalTime.current = newAdjustedTime;

              adjustment.current = formattedNonBilledValue;
              if (formattedNonBilledValue === "0,00") {
                adjustment.current = "";
              }

              setValuePerHour(formattedCategoryValue);
              setTotalValuePeriod(formattedPeriod);
              setTotalDecimalTime(`${round(horas_decimal_total, 2)}`);

              if (response.data[0].horas_fatu_ajuste) {
                enteredAdjustmentValue.current = Number(
                  response.data[0].horas_fatu_ajuste
                );
              }

              const newTotalValue =
                Number(round(newPeriodValue, 2)) +
                Number(
                  enteredAdjustmentValue.current.toString().replace(",", ".")
                );

              const formattedAddedTotalValue = newTotalValue?.toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );

              setTotalAddedValuePeriod(formattedAddedTotalValue);

              const reportPeriod = `${response.data[0].horas_fatu_de} a ${response.data[0].horas_fatu_ate}`;
              setBilledPeriod(reportPeriod);
            }
            ind += 1;

            if (invoice && invoice === "true") {
              customerId = data.horas_cod_cliente;
              customerIdString = customerId.toString().padStart(5, "0");
              maximumString = maximum.toString().padStart(5, "0");
              documentHourString = `${customerIdString}.${maximumString}/${currentYear}`;

              const formData = {
                horas_documento_seq: maximumString,
                horas_documento: documentHourString,
                horas_fatu_de: initialSearchDate,
                horas_fatu_ate: finalSearchDate,
                horas_fatu_ajuste: response.data[0].horas_fatu_ajuste,
                doc: "",
              };
              await api.put(
                `/management/workinghours/document/invoice/${data.horas_id}`,
                formData
              );
            }

            hoursArray.push({
              row_number: index + 1,
              horas_data: data.hora_data?.toString(),
              advogado_nome: data.advogado_nome,
              horas_descricao: data.horas_descricao,
              horas_hora_total: total_de_horas,
              horas_decimal,
              horas_fatu_de: data.horas_fatu_de?.toString(),
              horas_fatu_ate: data.horas_fatu_ate?.toString(),
              valor_ajuste_nao_faturado: Number(data.valor_ajuste_nao_faturado),
            });
          });

          setHoursReport(hoursArray);

          // summary info displayed after datatable
          const ms = durations.map(
            (d) => moment.duration(`${d}:00`).asSeconds() * 1000
          );
          const sum = ms.reduce((prev, cur) => prev + cur, 0);

          const d = moment.duration(sum, "milliseconds");
          const hours = Math.floor(d.asHours());
          const minutes = (Math.floor(d.asMinutes()) - hours * 60)
            .toString()
            .padStart(2, "0");
          const time = `${hours}:${minutes}`;

          setTotalTime(time);
        });

        setLoading(false);
      } catch (err) {
        addToast({
          type: "info",
          title: "Sem resultados!",
          description:
            "A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.",
        });
        setLoading(false);
      }
    })();
  }, [addToast, finalSearchDate, initialSearchDate, state]);

  const exportPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF("p", "mm", [297, 210]);

    doc.addFileToVFS("Roboto-Regular-normal.ttf", robotoRegular);
    doc.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal");
    doc.setFont("Roboto-Regular");

    doc.setLanguage("pt-BR");
    doc.setDocumentProperties({
      title: "Relatório de Horas",
      author: "Pabst & Hadlich Advogados Associados",
      creator: "JurisNet 2.0",
    });

    const totalPagesExp = "{total_pages_count_string}";

    autoTable(doc, {
      margin: [35, 0, 15, 15],
      html: "#table-billedhours table",
      includeHiddenHtml: false,
      theme: "grid",
      tableWidth: 180,
      columnStyles: {
        0: { cellWidth: 11 },
        1: { cellWidth: 22 },
        2: { cellWidth: 22 },
        4: { cellWidth: 20, halign: "center" },
        5: { cellWidth: 17, halign: "center" },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 8,
        font: "Roboto-Regular",
        fontStyle: "normal",
        halign: "center",
      },
      bodyStyles: {
        fontSize: 8,
        font: "Roboto-Regular",
        fontStyle: "normal",
      },
      footStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 8,
        font: "Roboto-Regular",
        fontStyle: "normal",
        halign: "center",
      },
      showFoot: "lastPage",
      didDrawPage(data) {
        const { pageSize } = doc.internal;
        // Header
        const xPos = pageSize.width - 85;
        doc.addImage(
          CompanyLogo,
          "PNG",
          xPos,
          15,
          68.79,
          9.46,
          undefined,
          "NONE"
        );
        // const fontSize = doc.getFontSize();
        // const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

        const header1 = "Relatório de Horas";
        // const header2 = '';
        const header3 = `Relatório Número: ${documentNumber}`;
        const header4 = `Cliente: ${clientName}`;
        const header5 = `Período: ${hoursReport[0]?.horas_fatu_de} a ${hoursReport[0]?.horas_fatu_ate}`;

        // const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        // const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        // const x1 = ((pageWidth - hWidth1) / 2) + 15;
        // const x2 = ((pageWidth - hWidth2) / 2) + 15;

        doc.setFontSize(12);
        doc.setTextColor(20);
        doc.text(header1, data.settings.margin.left, 14);

        // doc.setTextColor(40);
        // doc.text(header2, x2, 19);

        doc.setFontSize(9);
        doc.setTextColor(20);
        doc.text(header3, data.settings.margin.left, 20);
        doc.text(header4, data.settings.margin.left, 24);
        doc.text(header5, data.settings.margin.left, 28);
        doc.setFontSize(12);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left,
          pageHeight - 15,
          data.settings.margin.right,
          pageHeight - 15
        );
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    autoTable(doc, {
      html: "#table_summary",
      theme: "grid",
      margin: { left: 120 },
      tableWidth: "auto",
      columnStyles: {
        1: { halign: "right" },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        font: "Roboto-Regular",
        fontStyle: "normal",
        halign: "center",
      },
      bodyStyles: {
        fontSize: 8,
        font: "Roboto-Regular",
        fontStyle: "normal",
      },
    });

    // Footer
    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const printedOn = new Date();
    const dateFormatted = format(printedOn, "dd/MM/yyyy 'às' HH:mm:ss'.'");

    let str = `Página ${doc.getNumberOfPages()}`;
    if (typeof doc.putTotalPages === "function") {
      str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    }

    doc.setFontSize(8);

    const pageCount =
      (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    const x3 = (pageWidth - pageCount) / 2;
    doc.text(str, x3 + 50, pageHeight - 8);

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    const dateFormattedFile = format(new Date(), "dd-MM-yyyy");
    const formattedDocNumber = documentNumber.replace("/", "_");
    const formattedIniDate = billedHours[0]?.horas_fatu_de
      ?.toString()
      .replaceAll("/", "-");
    const formattedFinalDate = billedHours[0]?.horas_fatu_ate
      ?.toString()
      .replaceAll("/", "-");
    const filename = `${formattedDocNumber}-${clientName}-${formattedIniDate}a${formattedFinalDate}`;
    doc.save(`${filename}-${dateFormattedFile}.pdf`);
  };

  const exportExcel = () => {
    import("excel4node").then((xlsx) => {
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet("Horas");

      const columnsHeaders = [
        "Seq.",
        "Data",
        "Responsável",
        "Descrição",
        "Horas",
        "Decimais",
      ];
      if (
        adjustment.current.length > 0 &&
        adjustment.current !== "0,00" &&
        adjustment.current !== ""
      ) {
        columnsHeaders.push("Ajustes Decimais");
      }

      worksheet.cell(1, 1).string("Pabst & Hadlich Advogados Associados");
      worksheet.cell(2, 1).string("Relatório de Horas");
      worksheet.cell(3, 1).string(`Relatório Número: ${documentNumber}`);
      worksheet.cell(4, 1).string(`Cliente: ${clientName}`);
      worksheet
        .cell(5, 1)
        .string(
          `Período: ${hoursReport[0]?.horas_fatu_de} a ${hoursReport[0]?.horas_fatu_ate}`
        );

      let headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet.cell(7, headerColumIndex++).string(heading);
      });

      let totalAdjustmentTime = 0.0;
      let rowIndex = 8;
      hoursReport.forEach((item, index) => {
        worksheet.cell(rowIndex, 1).number(index + 1);
        const dateArray = item.horas_data.split("/");
        const formattedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T00:00:00.0000Z`;
        worksheet
          .cell(rowIndex, 2)
          .date(new Date(formattedDate))
          .style({ numberFormat: "dd/mm/yyyy" });
        // .style({ numberFormat: 'DD/MM/YYYY' });
        worksheet.cell(rowIndex, 3).string(item.advogado_nome);
        worksheet.cell(rowIndex, 4).string(item.horas_descricao);
        worksheet
          .cell(rowIndex, 5)
          .string(item.horas_hora_total)
          .style({ numberFormat: "[h]:mm" });
        worksheet
          .cell(rowIndex, 6)
          .number(Number(item.horas_decimal))
          .style({ numberFormat: "#,##0.00; (#,##0.00); 0.00" });
        if (
          adjustment.current.length > 0 &&
          adjustment.current !== "0,00" &&
          adjustment.current !== ""
        ) {
          worksheet
            .cell(rowIndex, 7)
            .number(Number(item.valor_ajuste_nao_faturado))
            .style({ numberFormat: "#,##0.00; -#,##0.00; 0" });
          totalAdjustmentTime += Number(item.valor_ajuste_nao_faturado);
        }
        rowIndex++;
      });

      rowIndex += 2;
      worksheet.cell(rowIndex, 5).string("Total de horas");
      worksheet
        .cell(rowIndex, 6)
        .string(`${totalTime}`)
        .style({ numberFormat: "[h]:mm" });

      rowIndex += 1;
      worksheet.cell(rowIndex, 5).string("Total de horas (decimais)");
      worksheet
        .cell(rowIndex, 6)
        .number(Number(totalDecimalTime))
        .style({ numberFormat: "#,#0.00; -#,#0.00; 0.00" });
      // console.log(adjustment.current);
      if (
        adjustment.current.length > 0 &&
        adjustment.current !== "0,00" &&
        adjustment.current !== ""
      ) {
        rowIndex += 1;
        worksheet.cell(rowIndex, 5).string("Ajuste (decimais)");
        worksheet
          .cell(rowIndex, 6)
          .number(Number(totalAdjustmentTime))
          .style({ numberFormat: "#,#0.00; -#,#0.00; 0" });

        rowIndex += 1;
        worksheet.cell(rowIndex, 5).string("Total horas consideradas");
        worksheet
          .cell(rowIndex, 6)
          .number(Number(totalAdjustedDecimalTime.current))
          .style({ numberFormat: "#,#0.00; -#,#0.00; 0" });
      }

      rowIndex += 1;
      worksheet.cell(rowIndex, 5).string("Valor cobrado por hora (R$)");
      worksheet
        .cell(rowIndex, 6)
        .number(Number(valuePerHour.replace(",", ".")))
        .style({ numberFormat: "#,##0.00; -#,##.00; 0" });

      rowIndex += 1;
      worksheet.cell(rowIndex, 5).string("Total do Período (R$)");
      worksheet
        .cell(rowIndex, 6)
        .number(Number(totalValuePeriod.replace(".", "").replace(",", ".")))
        .style({ numberFormat: "#,##0.00; -#,##.00-; 0" });

      if (enteredAdjustmentValue.current !== 0.0) {
        rowIndex += 1;
        worksheet.cell(rowIndex, 5).string("Ajuste (R$)");
        worksheet
          .cell(rowIndex, 6)
          .number(Number(enteredAdjustmentValue.current))
          .style({ numberFormat: "#,#0.00; -#,#0.00; 0" });

        rowIndex += 1;
        worksheet.cell(rowIndex, 5).string("Valor final (R$)");
        worksheet
          .cell(rowIndex, 6)
          .number(
            Number(totalAddedValuePeriod.replace(".", "").replace(",", "."))
          )
          .style({ numberFormat: "#,#0.00; -#,#0.00; 0" });
      }

      const formattedDocNumber = documentNumber.replace("/", "_");
      const formattedIniDate = initialSearchDate.replaceAll("/", "-");
      const formattedFinalDate = finalSearchDate.replaceAll("/", "-");
      const filename = `${formattedDocNumber}-${clientName}-${formattedIniDate}a${formattedFinalDate}`;

      workbook.writeToBuffer().then((buffer: BlobPart) => {
        import("file-saver").then((module) => {
          if (module && module.default) {
            const EXCEL_TYPE =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
              type: EXCEL_TYPE,
            });

            module.default.saveAs(data, `${filename}${EXCEL_EXTENSION}`);
          }
        });
      });
    });
  };

  const leftToolbarTemplate = () => (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-danger mr-2"
        tooltip="Exportar para PDF / Imprimir"
        tooltipOptions={{ position: "right" }}
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        tooltip="Exportar para Excel"
        tooltipOptions={{ position: "right" }}
      />
    </div>
  );

  const rightToolbarTemplate = () => (
    <>
      <Button
        icon="pi pi-arrow-circle-left"
        iconPos="left"
        label="Retornar à listagem de horas"
        className="p-button p-component p-button-outlined p-button-info"
        type="button"
        onClick={history.goBack}
      />
    </>
  );

  const header = (
    <>
      <div className="table-header">
        Relatório Nº: {documentNumber}
        <br />
        Cliente: {clientName}
        <br />
        Período: {billedPeriod}
      </div>
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  const hourBodyTemplate = (rowData: { horas_hora_total: string }) => {
    const currentDuration: string[] = [];
    let horas_total = "";
    let total_de_horas = "";
    let horas_decimal = "0";
    let total_h1 = 0;
    let total_m1 = 0;

    if (!rowData.horas_hora_total) {
      horas_total = "0:00";
    } else {
      const horas_total_tmp = rowData.horas_hora_total.split(":");

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
            .toFixed(0)
            .toString();
        } else {
          horas_total += `0${(Number(horas_total_tmp[1]) / 60) * 100}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += "00";
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(
          ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
        );
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = Number(horas_total_tmp[0]).toString();
      } else {
        horas_decimal = "0";
      }

      const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);

        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += ".00";
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = "0.00";
      }
    }

    currentDuration.push(total_de_horas);

    const ms = currentDuration.map(
      (d) => moment.duration(`${d}:00`).asSeconds() * 1000
    );
    const sum = ms.reduce((prev, cur) => prev + cur, 0);

    const d = moment.duration(sum, "milliseconds");
    const hours = Math.floor(d.asHours());
    const minutes = (Math.floor(d.asMinutes()) - hours * 60)
      .toString()
      .padStart(2, "0");
    const time = `${hours}:${minutes}`;

    return time;
  };

  const decimalBodyTemplate = (rowData: { horas_hora_total: string }) => {
    const cleanDuration = convertDecimalToHour(rowData.horas_hora_total);

    const totalHoursArray = cleanDuration.split(":");
    const cleanMinutes =
      Number(totalHoursArray[0]) * 60 + Number(totalHoursArray[1]);

    return (cleanMinutes / 60)
      .toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
      })
      .replace(".", ",");
  };

  const totalDecimalBodyTemplate = (rowData: {
    valor_ajuste_nao_faturado: number;
  }) => {
    const newValue = rowData.valor_ajuste_nao_faturado
      ?.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
      })
      .replace(".", ",");
    return newValue;
  };

  const personInChargeBodyTemplate = (rowData: {
    advogado_nome: string;
    administrador_nome: string;
  }) => {
    if (billedHours !== undefined) {
      const filteredAttorney = billedHours.filter(
        (item) => item.advogado_nome === rowData.advogado_nome,
        []
      );

      if (filteredAttorney.length > 0) {
        return filteredAttorney[0].advogado_nome;
      }
      const filteredAssistant = billedHours.filter(
        (item) => item.administrador_nome === rowData.administrador_nome,
        []
      );
      return filteredAssistant[0].administrador_nome;
    }

    return "";
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total de horas:"
          colSpan={4}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={totalTime} footerStyle={{ textAlign: "center" }} />
        <Column
          footer={totalDecimalTime}
          footerStyle={{ textAlign: "center" }}
        />
        {adjustment.current.length > 0 && adjustment.current !== "0,00" ? (
          <Column
            footer={adjustment.current}
            footerStyle={{ textAlign: "center" }}
          />
        ) : null}
      </Row>
    </ColumnGroup>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Relatório de Horas Faturadas</h1>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card">
          <Tooltip target=".export-buttons>button" position="bottom" />

          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            tableClassName="table-billedhours table"
            id="table-billedhours"
            ref={dt}
            loading={loading}
            value={billedHours}
            editMode="row"
            dataKey="horas_id"
            header={header}
            stripedRows
            className="p-datatable-sm table-pendinghours"
            rows={billedHours.length}
            emptyMessage="Nenhum registro encontrado."
            responsiveLayout="scroll"
            selection={selectedHours}
            footerColumnGroup={footerGroup}
          >
            <Column
              key="row_number"
              field="row_number"
              header="Núm."
              alignHeader="center"
              body={counterBodyTemplate}
              align="center"
              style={{ width: "50px" }}
            />
            <Column
              key="hora_data"
              field="hora_data"
              header="Data"
              alignHeader="center"
              align="left"
              style={{ width: "100px" }}
            />
            <Column
              field="advogado_nome"
              header="Responsável"
              alignHeader="center"
              body={personInChargeBodyTemplate}
              align="left"
              style={{ width: "250px" }}
            />
            <Column
              field="horas_descricao"
              header="Descrição"
              alignHeader="center"
              align="left"
            />
            <Column
              field="horas_hora_total"
              header="Horas"
              alignHeader="center"
              body={hourBodyTemplate}
              align="center"
              style={{ width: "80px" }}
            />
            <Column
              field="horas_decimal"
              header="Decimais"
              alignHeader="center"
              body={decimalBodyTemplate}
              align="center"
              style={{ width: "80px" }}
            />
            {adjustment.current.length > 0 && adjustment.current !== "0,00" ? (
              <Column
                key="row_number"
                field="valor_ajuste_nao_faturado"
                header="Ajustes Decimais"
                alignHeader="center"
                body={totalDecimalBodyTemplate}
                align="center"
                style={{ width: "80px" }}
              />
            ) : null}
            <Column field="horas_id" style={{ display: "none" }} />
          </DataTable>

          <div className="grid summary-grid p-mt-10">
            <div className="col-3">
              <table id="table_summary">
                <tbody>
                  <tr>
                    <th colSpan={2} className="stripedRows">
                      Resumo horas trabalhadas
                    </th>
                  </tr>
                  <tr>
                    <td width="240">Total de horas</td>
                    <td width="100" align="right">
                      {totalTime}
                    </td>
                  </tr>
                  <tr className="stripedRows">
                    <td width="240">Total de horas (decimais)</td>
                    <td width="100" align="right">
                      {totalDecimalTime}
                    </td>
                  </tr>
                  {adjustment.current.length > 0 &&
                  adjustment.current !== "0,00" ? (
                    <>
                      <tr>
                        <td width="240">Ajuste (decimais)</td>
                        <td width="100" align="right">
                          {adjustment.current}
                        </td>
                      </tr>
                      <tr>
                        <td width="240">
                          Total horas consideradas
                          <br />
                          <small>(horas totais - ajuste de horas)</small>
                        </td>
                        <td width="100" align="right">
                          {totalAdjustedDecimalTime.current.toLocaleString(
                            "pt-BR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  <tr
                    className={
                      adjustment.current.length > 0 &&
                      adjustment.current !== "0,00"
                        ? "stripedRows"
                        : ""
                    }
                  >
                    <td width="240">Valor cobrado por hora</td>
                    <td width="100" align="right">
                      {valuePerHour}
                    </td>
                  </tr>
                  <tr
                    className={
                      adjustment.current.length > 0 &&
                      adjustment.current !== "0,00"
                        ? ""
                        : "stripedRows"
                    }
                  >
                    <td width="240">Total do período (R$)</td>
                    <td width="100" align="right">
                      <span id="totalvalue">{totalValuePeriod}</span>
                    </td>
                  </tr>
                  {enteredAdjustmentValue.current !== 0.0 ? (
                    <>
                      <tr
                        className={
                          adjustment.current.length > 0 &&
                          adjustment.current !== "0,00"
                            ? "stripedRows"
                            : ""
                        }
                      >
                        <td width="240">Ajuste (R$)</td>
                        <td width="100" align="right">
                          {enteredAdjustmentValue.current.toLocaleString(
                            "pt-BR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </td>
                      </tr>

                      <tr
                        className={
                          adjustment.current.length > 0 &&
                          adjustment.current !== "0,00" &&
                          enteredAdjustmentValue.current !== 0.0
                            ? ""
                            : "stripedRows"
                        }
                      >
                        <td width="240">Valor Final (R$)</td>
                        <td width="100" align="right">
                          {totalAddedValuePeriod}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  {/* {adjustment.current.length > 0
                      && adjustment.current !== '0,00'
                      && enteredAdjustmentValue.current !== 0.00 ? (
                        <>
                          <tr className={adjustment.current.length > 0
                            && adjustment.current !== '0,00' ? 'stripedRows' : ''}>
                            <td width="240">Ajuste (R$)</td>
                            <td width="100" align="right">
                              {enteredAdjustmentValue.current.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>

                          <tr className={adjustment.current.length > 0
                            && adjustment.current !== '0,00'
                  && enteredAdjustmentValue.current !== 0.00 ? '' : 'stripedRows'}
                          >
                            <td width="240">Valor Final (R$)</td>
                            <td width="100" align="right">{totalAddedValuePeriod}</td>
                          </tr>
                        </>
                    ) : null} */}
                </tbody>
              </table>
              <div className="col-12 md:col-12 reducedPadding">
                <Button
                  icon="pi pi-file-pdf"
                  iconPos="left"
                  label="Imprimir/Exportar"
                  className="p-button-info w-full"
                  type="button"
                  onClick={exportPdf}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ExportBilledHourReport;
