/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import moment from "moment";

import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Tooltip } from "primereact/tooltip";

import api from "../../../../services/api";

import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";

import { Container } from "../styles";

interface SearchData {
  proc_cod_cliente: number;
  inCharge: string;
  referenceId: number;
  description: string;
  hourType: string;
  proc_tipo: string;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
}

interface IInChargeType {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any;
}

interface IOptions {
  label: string;
  value: string;
  disabled: boolean;
  workingsector: string;
}

const SearchPendingHour: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useHistory();

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm:ss");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm:ss");

  const [customerId, setCustomerId] = useState("");
  const [referenceId, setReferenceId] = useState(0);
  const [hourType, setHourType] = useState("normais");
  const [description, setDescription] = useState("");

  const [initialRegistrationDate, setInitialRegistrationDate] = useState<
    string | Date | Date[] | null
  >(new Date(startOfMonth));
  const [finalRegistrationDate, setFinalRegistrationDate] = useState<
    string | Date | Date[] | null
  >(new Date(endOfMonth));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attorneysOptions, setAttorneysOptions] = useState<IOptions[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [customersOptions, setCustomersOptions] = useState([{}]);
  const [sectorOptions, setSectorOptions] = useState([{}]);
  const [referenceOptions, setReferenceOptions] = useState([{}]);
  const [inChargeOptions, setInChargeOptions] = useState<IInChargeType[]>([]);

  let loggedUserLevel = "";
  let loggedUserLevelNumber = 0;
  let loggedUserId = 0;
  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem("@Jurisnet:access");

  if (typeof accessData === "string") {
    const access = JSON.parse(accessData);
    loggedUserLevel = access.nivelusuario;
    loggedUserId = Number(access.usuario_logado);
  }

  if (user.user_level > 0) {
    loggedUserLevelNumber = user.user_level;
  }

  let userTypeDefinition = "";
  switch (loggedUserLevel) {
    case "Administrador":
      userTypeDefinition = "global|0";
      break;
    case "Advogado":
      userTypeDefinition = `adv|${loggedUserId}`;
      break;
    case "Assistente":
      userTypeDefinition = `adm|${loggedUserId}`;
      break;
    default:
      userTypeDefinition = "global|0";
      break;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [inCharge, setInCharge] = useState<any>(userTypeDefinition);
  const [dateFilterType, setDateFilterType] = useState("data");

  const [showDate, setShowDate] = useState(true);
  const [showPeriod, setShowPeriod] = useState(false);

  const dateFilterTypeOptions = [
    { name: "Data", value: "data", style: "font-size: small;" },
    { name: "Período", value: "periodo", style: "font-size: small;" },
  ];

  const validationSchema = null;

  const hourTypeOptions = [
    { name: "Normais", value: "normais", style: "font-size: small;" },
    { name: "Pendentes", value: "pendentes", style: "font-size: small;" },
  ];

  useEffect(() => {
    (async () => {
      await api.get("/lawsuits/references/show/all").then((response) => {
        referenceOptions.push({
          name: "Padrão",
          value: 0,
        });
        response.data.map(
          (reference: {
            horas_referencia_id: number;
            horas_referencia_nome: string;
          }) =>
            referenceOptions.push({
              name: reference.horas_referencia_nome,
              value: reference.horas_referencia_id,
            })
        );
      });
      referenceOptions.splice(0, 1);
      setReferenceOptions(referenceOptions);
    })();
  }, [referenceOptions]);

  useEffect(() => {
    (async () => {
      await api.get("customers/show/all").then((response) => {
        response.data.map(
          (customer: { cliente_id: number; cliente_nome: string }) =>
            customersOptions.push({
              name: customer.cliente_nome,
              value: customer.cliente_id,
            })
        );
      });
      customersOptions.splice(0, 1);

      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  useEffect(() => {
    (async () => {
      await api
        .get("/management/attorneys/show/inchargelawsuits/attorneys")
        .then((response) => {
          if (response.data.length > 0) {
            sectorOptions.push({
              name: "Todos",
              value: "all",
            });
            response.data.map((sector: { setor_adv: string }) =>
              sectorOptions.push({
                name: sector.setor_adv,
                value: sector.setor_adv,
              })
            );
          }
        });

      await api
        .get("/management/attorneys/show/inchargelawsuits/others")
        .then((response) => {
          if (response.data.length > 0) {
            response.data.forEach((sector: { setor_adm: string }) => {
              if (sectorOptions.indexOf(sector.setor_adm) === -1) {
                sectorOptions.push({
                  name: sector.setor_adm,
                  value: sector.setor_adm,
                });
              }
            });
          }
        });

      sectorOptions.splice(0, 1);
      setSectorOptions(sectorOptions);
    })();
  }, [sectorOptions]);

  const filterAttorneysInCharge = async (sector: string | string[]) => {
    let isDisabled = true;

    if (loggedUserLevel === "Administrador") {
      inChargeOptions.push({
        label: "Todos",
        items: [
          { label: "Todos", value: "global|0" },
          { label: "Todos - exceto estagiários", value: "global|99997" },
          { label: "Todos - exceto assistentes", value: "global|99998" },
          {
            label: "Todos - exceto estagiários e assistentes",
            value: "global|99999",
          },
        ],
      });

      await api
        .get(
          `management/attorneys/show/all/active/lawsuit/${sessionStorage.getItem(
            "categoria"
          )}/${sessionStorage.getItem("setor")}`
        )
        .then((response) => {
          const typeInCharge = "adv|";
          // attorneysOptions.splice(0, attorneysOptions.length);
          response.data.forEach(
            (attorney: {
              advogado_id: number;
              advogado_nome: string;
              advogado_setor: string;
            }) => {
              if (sector.includes(attorney.advogado_setor)) {
                isDisabled = false;
              } else {
                isDisabled = true;
              }
              if (sector === "all") {
                isDisabled = false;
              }
              attorneysOptions.push({
                label: `${attorney.advogado_nome} (Advogado)`,
                value: typeInCharge.concat(attorney.advogado_id.toString()),
                disabled: isDisabled,
                workingsector: attorney.advogado_setor,
              });
            }
          );
          // attorneysOptions.splice(0, 1);

          inChargeOptions.push({
            label: "Advogados",
            items: attorneysOptions,
          });
        });

      await api
        .get(
          `management/assistants/show/all/active/lawsuit/${sessionStorage.getItem(
            "setor"
          )}/${sessionStorage.getItem("categoria")}`
        )
        .then((response) => {
          const typeInCharge = "adm|";
          response.data.forEach(
            (assistant: {
              administrador_id: number;
              administrador_nome: string;
              administrador_setor: string;
            }) => {
              if (sector.includes(assistant.administrador_setor)) {
                isDisabled = false;
              } else {
                isDisabled = true;
              }
              if (sector === "all") {
                isDisabled = false;
              }
              assistantsOptions.push({
                label: `${assistant.administrador_nome} (Assistente)`,
                value: typeInCharge.concat(
                  assistant.administrador_id.toString()
                ),
                disabled: isDisabled,
                workingsector: assistant.administrador_setor,
                style: "visibility: hidden",
              });
            }
          );
          assistantsOptions.splice(0, 1);
          inChargeOptions.push({
            label: "Assistentes/Estagiários",
            items: assistantsOptions,
          });
        });
      setInChargeOptions(inChargeOptions);
    }

    if (loggedUserLevel === "Advogado" && loggedUserLevelNumber === 2) {
      await api
        .get(`/management/attorneys/show/${loggedUserId}`)
        .then((response) => {
          attorneysOptions.push({
            label: `${response.data.advogado_nome} (Advogado)`,
            value: "adv|".concat(response.data.advogado_id.toString()),
            disabled: false,
            workingsector: response.data.advogado_setor,
          });
          inChargeOptions.push({
            label: "Advogados",
            items: attorneysOptions,
          });

          setInChargeOptions(inChargeOptions);
          setInCharge(`adv|${loggedUserId}`);
        });
    }
    if (loggedUserLevel === "Assistente" && loggedUserLevelNumber === 2) {
      await api
        .get(`/management/administrators/show/${loggedUserId}`)
        .then((response) => {
          assistantsOptions.push({
            label: `${response.data.administrador_nome} (Assistente)`,
            value: "adm|".concat(response.data.administrador_id.toString()),
            disabled: false,
            workingsector: response.data.administrador_setor,
            style: "visibility: hidden",
          });
          inChargeOptions.push({
            label: "Assistentes/Estagiários",
            items: assistantsOptions,
          });
        });

      setInChargeOptions(inChargeOptions);
      setInCharge(`adm|${loggedUserId}`);
    }
  };

  useEffect(() => {
    if (inChargeOptions.length < 1) {
      filterAttorneysInCharge("all");
    }
  });

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>
  ) => {
    try {
      // const access = JSON.parse(sessionStorage.getItem('@Jurisnet:access') || '{}');
      const access = JSON.parse(
        localStorage.getItem("@Jurisnet:access") || "{}"
      );

      let menu = "";
      let categoria = 0;
      let setorTxt = "";

      if (access) {
        menu = access.menu;
        categoria = Number(access.categoria);
        setorTxt = access.setor;
      }

      if (user.user_level > 1 && !inCharge) {
        addToast({
          type: "error",
          title: "Selecione o responsável",
          description:
            "Você precisa selecionar o responsável para consultar, caso não esteja selecionado.",
        });
        return;
      }
      const searchFormData = {
        proc_cod_cliente: customerId,
        inCharge,
        referenceId,
        description,
        tipo_horas: hourType,
        proc_tipo: hourType,
        dateFilterType,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
        menu,
        categoria,
        setorTxt,
        doc: "",
      };

      history.push({
        pathname: "/management/workinghour",
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ocorreu um problema",
        description: "Tente repetir a pesquisa ou contate o administrador.",
      });
    }
  };

  const initialValues: SearchData = {
    proc_cod_cliente: 0,
    inCharge: userTypeDefinition,
    referenceId: 0,
    description: "",
    hourType: "normais",
    proc_tipo: "todos",
    proc_data_ajuiz_inicio: new Date(startOfMonth),
    proc_data_ajuiz_final: new Date(endOfMonth),
  };

  return (
    <Container>
      <div className="card">
        <header>
          <h3>
            Consulta de horas para
            {user.name}
          </h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({ isSubmitting, handleSubmit, handleReset }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-6 colum-spacer-right">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cliente">Cliente</label>
                      <Dropdown
                        id="proc_cliente"
                        name="proc_cliente"
                        options={customersOptions}
                        onChange={(e) => setCustomerId(e.target.value)}
                        value={customerId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        autoFocus
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="tipo_hora">Horas</label>
                      <SelectButton
                        id="tipo_hora"
                        value={hourType}
                        options={hourTypeOptions}
                        optionLabel="name"
                        onChange={(e) => setHourType(e.value)}
                        style={{ padding: "0.5rem 0.79rem 0 0" }}
                      />
                    </div>
                    {loggedUserLevel === "Administrador" ? (
                      <div className="col-12 md:col-12">
                        <label htmlFor="inChargeDropDown">Responsável</label>
                        <Dropdown
                          id="inChargeDropDown"
                          name="inChargeDropDown"
                          className="inputfield w-full"
                          value={inCharge}
                          options={inChargeOptions}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(e: { value: any }) => setInCharge(e.value)}
                          optionLabel="label"
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          filter
                          filterBy="label"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 md:col-12">
                    <label htmlFor="proc_cod_adv_resp">Referência</label>
                    <Dropdown
                      id="proc_cod_adv_resp"
                      name="proc_cod_adv_resp"
                      options={referenceOptions}
                      onChange={(e) => setReferenceId(e.target.value)}
                      value={referenceId}
                      className="inputfield w-full"
                      optionLabel="name"
                      optionValue="value"
                      filter
                      filterBy="name"
                      placeholder="Selecione uma opção"
                      emptyFilterMessage="Nenhum registro encontrado"
                    />
                  </div>
                </div>

                <div className="col-12 md:col-6 column-divider">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="description">Descrição</label>
                      <InputTextarea
                        id="description"
                        name="description"
                        className="inputfield w-full"
                        value={description || ""}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={6}
                        cols={30}
                      />
                    </div>

                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ajuiz_inicio">
                        Data de trabalho{" "}
                        <Tooltip target=".infoText">
                          <strong>Selecione data específica ou período</strong>
                          <br />
                          <strong>Seleção data</strong>
                          <br />
                          <em>
                            Use para intervalos com seleção específica de datas
                          </em>
                          <br />
                          <br />
                          <strong>Seleção período</strong>
                          <br />
                          <em>
                            Para período inicial e final do mesmo mês,
                            <br />
                            selecione apenas o período inicial para que o
                            intervalo
                            <br />
                            seja o primeiro e último dia do mês selecionado
                          </em>
                        </Tooltip>
                        <i
                          className="infoText pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                    <div
                      className="col-12 md:col-12"
                      style={{ marginBottom: "15px" }}
                    >
                      <SelectButton
                        id="date_filter_type"
                        value={dateFilterType}
                        options={dateFilterTypeOptions}
                        optionLabel="name"
                        onChange={(e) => {
                          setDateFilterType(e.value);
                          if (e.value === "data") {
                            setShowDate(true);
                            setShowPeriod(false);
                            setInitialRegistrationDate(new Date(startOfMonth));
                            setFinalRegistrationDate(new Date(endOfMonth));
                          } else {
                            setShowDate(false);
                            setShowPeriod(true);
                            setInitialRegistrationDate(null);
                            setFinalRegistrationDate(null);
                          }
                        }}
                        style={{ padding: "0.5rem 0.79rem 0 0" }}
                      />
                    </div>
                    {showPeriod ? (
                      <>
                        <div id="periodo_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio_mes_ano"
                            name="proc_data_ajuiz_inicio_mes_ano"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e: CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/9999"
                            dateFormat="mm/yy"
                            yearRange="1980:2030"
                            placeholder="Período inicial"
                            view="month"
                            yearNavigator
                          />
                        </div>
                        <div id="periodo_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final_mes_ano">
                            <Calendar
                              inputId="proc_data_ajuiz_final_mes_ano"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e: CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/9999"
                              dateFormat="mm/yy"
                              yearRange="1980:2030"
                              placeholder="Período final"
                              view="month"
                              yearNavigator
                            />
                          </label>
                        </div>
                      </>
                    ) : null}
                    {showDate ? (
                      <>
                        <div id="data_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio"
                            name="proc_data_ajuiz_inicio"
                            className="inputfield w-full"
                            value={initialRegistrationDate}
                            onChange={(e: CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data inicial"
                          />
                        </div>
                        <div id="data_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final">
                            <Calendar
                              inputId="proc_data_ajuiz_final"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={finalRegistrationDate}
                              onChange={(e: CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/99/9999"
                              dateFormat="dd/mm/yy"
                              yearRange="1980:2030"
                              placeholder="Data final"
                            />
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Consultar horas"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default SearchPendingHour;
