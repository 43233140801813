import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

 interface ILawsuitProperties {
  proc_id: number | null;
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
  cliente_nome: string;
  advogado_nome: string;
}

interface ILawsuitProgressProperties {
  and_id: number;
  and_proc_id: number;
  and_data: Date;
  and_andamento:string;
  and_arquivo:string;
  and_obs :string;
  and_enviado:boolean;
  and_exibir:boolean;
  and_dat_alt: Date;
  id_adv_alt: number;
  nivelusuario:string;
}

const Lawsuit: React.FC = () => {
  PrimeReact.ripple = true;

  const emptyLawsuit = {
    proc_id: null,
    proc_reg: '',
    proc_num_pasta: '',
    proc_cod_acao: 0,
    proc_cod_orgao: 0,
    proc_cod_procedimento: 0,
    proc_data_ajuiz: null,
    proc_data_ingresso: null,
    proc_cod_cliente: 0,
    proc_cod_adv_resp: 0,
    proc_adv_adversa: '',
    proc_url: '',
    proc_terceiros: '',
    proc_cod_nat_interv: 0,
    proc_reg_outros: '',
    proc_orgao_cidade: '',
    proc_orgao_uf: '',
    proc_autor: '',
    proc_reu: '',
    proc_assunto: '',
    proc_ult_atu: null,
    proc_localizacao: '',
    proc_resumo: '',
    proc_cod_natureza: 0,
    proc_situacao: '',
    proc_ult_mov_rel: null,
    proc_em_acordo: 0,
    proc_cod_ass_resp: 0,
    proc_sta_gerencial: '',
    proc_observacao: '',
    status_lib_morto: 'N',
    obs_final_financeiro: '',
    status_liberacao: 'N',
    proc_tipo_processo: '',
    proc_pgto_ato: 'N',
    cliente_nome: '',
    advogado_nome: '',
  };

  const [lawsuits, setLawsuits] = useState<ILawsuitProperties[]>([]);
  const [lawsuit, setLawsuit] = useState(emptyLawsuit);
  const [deleteLawsuit, setsetDeleteLawsuit] = useState(false);
  const [deleteLawsuitsDialog, setsetDeleteLawsuits] = useState(false);
  const [selectedLawsuits, setSelectedLawsuits] = useState<ILawsuitProperties[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');

  const cm = useRef<ContextMenu | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<ILawsuitProperties[] | null>(null);
  const menuModel = [
    { label: 'Abrir em nova aba', icon: 'pi pi-fw pi-external-link', command: () => openNewTab(selectedRecord) },
    { label: 'Alterar cadastro (mesma aba)', icon: 'pi pi-fw pi-pencil', command: () => editLawsuit(selectedRecord) },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openNewTab = (selectedLawsuit: any) => {
    if (selectedLawsuit) {
      window.open(`/lawsuit/main/edit/${selectedLawsuit.proc_id}?active=3`, '_blank');
    }
  };

  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const state = location.state as any;
      if (state.formValuesChanged.toString() === 'false') {
        api.post('/lawsuits/main/search', state).then((response) => {
          setLawsuits(response.data);
        });
      } else {
        api.get('/lawsuits/main/show/all').then((response) => {
          setLawsuits(response.data);
        });
      }
    } catch (err) {
      api.get('/lawsuits/main/show/all').then((response) => {
        setLawsuits(response.data);
      });

      // addToast({
      //   type: 'info',
      //   title: 'Sem resultados!',
      //   description:
      //           'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
      // });
    } finally {
      setLoading(false);
    }
  }, [addToast, location]);

  const columns = [
    { field: 'proc_num_pasta', header: 'Pasta', sortable: true },
    { field: 'proc_reg', header: 'Processo nº', sortable: true },
    { field: 'proc_autor', header: 'Autor(es)', sortable: true },
    { field: 'proc_reu', header: 'Réu(s)', sortable: true },
    { field: 'acao_descricao', header: 'Tipo Ação', sortable: true },
    { field: 'proc_ult_atu', header: 'Última Atualização', sortable: true },
  ];

  const lawsuitNumberBodyTemplate = (rowData:
    { proc_reg: string; advogado_nome: string; proc_adv_adversa: string;
    }) => {
    if (lawsuits !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const state = location.state as any;

      if (state?.proc_adv_aversa !== '' && rowData.proc_adv_adversa !== ''
      && rowData.advogado_nome !== undefined && rowData.proc_adv_adversa !== undefined) {
        return (
          <>
            <Tooltip
              target=".dt-adv_adversa"
              mouseTrack
              mouseTrackLeft={10}
              position="top"
            />
            <span
              className="dt-adv_adversa"
              data-pr-tooltip={`Advogado: ${rowData.advogado_nome}
                                Adv. parte adversa: ${rowData.proc_adv_adversa}`}
            >
              {rowData.proc_reg}
            </span>
          </>
        );
      }

      return rowData.proc_reg;
    }

    return '';
  };

  const dateBodyTemplate = (rowData: { proc_ult_atu: Date }) => {
    if (lawsuits !== undefined) {
      const filteredDate = lawsuits.filter((item) => (
        item.proc_ult_atu === rowData.proc_ult_atu
      ), []);

      const dateInput = filteredDate[0].proc_ult_atu?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }

    return '';
  };

  const dynamicColumns = columns.map((col) => {
    if (col.field === 'proc_ult_atu') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={dateBodyTemplate}
          sortable={false}
          headerStyle={{ width: '100px' }}
        />
      );
    }

    if (col.field === 'proc_reg') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={lawsuitNumberBodyTemplate}
          sortable={false}
          className="p-dt-tooltip"
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
      />
    );
  });

  const handleCreate = () => {
    history.push('/lawsuit/main/create');
  };

  const hidedeleteLawsuit = () => {
    setsetDeleteLawsuit(false);
  };

  const hidedeleteLawsuitsDialog = () => {
    setsetDeleteLawsuits(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLawsuit = (rowData:any) => {
    history.push(`/lawsuit/main/edit/${rowData.proc_id}?active=3`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteLawsuit = (lawsuit: React.SetStateAction<{
    proc_id: null; proc_reg: string; proc_num_pasta: string; proc_cod_acao: number;
    proc_cod_orgao: number; proc_cod_procedimento: number; proc_data_ajuiz: null;
    proc_data_ingresso: null; proc_cod_cliente: number; proc_cod_adv_resp: number;
    proc_adv_adversa: string; proc_url: string; proc_terceiros: string;
    proc_cod_nat_interv: number; proc_reg_outros: string; proc_orgao_cidade: string;
    proc_orgao_uf: string; proc_autor: string; proc_reu: string; proc_assunto: string;
    proc_ult_atu: null; proc_localizacao: string; proc_resumo: string; proc_cod_natureza: number;
    proc_situacao: string; proc_ult_mov_rel: null; proc_em_acordo: number;
    proc_cod_ass_resp: number; proc_sta_gerencial: string; proc_observacao: string;
    status_lib_morto: string; obs_final_financeiro: string; status_liberacao: string;
    proc_tipo_processo: string; proc_pgto_ato: string; cliente_nome:string; advogado_nome: string;
  }>) => {
    setLawsuit(lawsuit);
    setsetDeleteLawsuit(true);
  };

  const deleteGroup = () => {
    api.delete(`/lawsuits/main/delete/${lawsuit.proc_id}`);

    setsetDeleteLawsuit(false);

    const updatedRecords = lawsuits
      .filter((val) => val.proc_id !== lawsuit.proc_id);
    setLawsuits(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const searchLawsuits = () => {
    history.push('/lawsuit/main/search');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editLawsuit(rowData); }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteLawsuit(rowData); }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        icon="pi pi-search"
        className="p-button-secondary p-mr-2"
        type="button"
        onClick={searchLawsuits}
        label="Nova consulta"
        tooltip="Nova consulta de processos"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteLawsuitFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLawsuit} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGroup} />
    </>
  );
  const deleteLawsuitsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLawsuitsDialog} />
    </>
  );
  const [origin, setOrigin] = useState('');
  const [lawsuitId, setLawsuitId] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [attorneyName, setAttorneyName] = useState('');
  const [counterAttorney, setCounterAttorney] = useState('');
  const [lawsuitProgresses, setLawsuitProgresses] = useState<ILawsuitProgressProperties[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadSelectedLawsuit = async (rowData: any) => {
    try {
      await api.get(`/lawsuits/main/show/${rowData.proc_id}`).then((response) => {
        setLawsuit(response.data);
        const judgingBodyId = response.data.proc_cod_orgao;
        const city = response.data.proc_orgao_cidade;
        const county = response.data.proc_orgao_uf;
        let judgingBody = '';

        api.get(`/lawsuits/judgingbodies/show/${judgingBodyId}`).then((responsebodies) => {
          judgingBody = responsebodies.data.orgao_descricao;

          const originConcat = `${judgingBody} - ${city}/${county}`;
          setOrigin(originConcat);
        });
      });

      await api.get(`/lawsuits/progresses/show/filtered/${rowData.proc_id}`).then((response) => {
        setLawsuitProgresses(response.data);
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setCustomerName(rowData.cliente_nome);
        setAttorneyName(rowData.advogado_nome);
        setCounterAttorney(rowData.proc_adv_adversa);
        setLawsuitId(rowData.proc_id);
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
                  'Não foi possível carregar as informações do cadastro selecionado.',
      });
    }
  };

  const progressDateBodyTemplate = (rowData: { and_data: Date; }) => {
    if (lawsuitProgresses !== undefined) {
      const filteredDate = lawsuitProgresses.filter((item) => (
        item.and_data === rowData.and_data
      ), []);

      const dateInput = filteredDate[0].and_data?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }
    return '';
  };

  let timerId = setTimeout(() => {
    setLoading(false);
  }, 200);

  return (
    <Container>
      <header>
        <div>
          <h1>Consulta processual</h1>
        </div>
      </header>

      <Sidebar
        className="p-sidebar-md"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h4>
          <div className="leftAlign" style={{ float: 'left', width: '49%' }}>
            Resumo do processo
          </div>
          <div className="rightAlign" style={{ float: 'right', width: '49%', textAlign: 'end' }}>
            <Button
              icon="pi pi-pencil"
              iconPos="left"
              className="p-button p-component p-button-info"
              type="button"
              onClick={() => history.push(`/lawsuit/main/edit/${lawsuitId}?active=3`)}
              tooltip="Alterar processo"
              tooltipOptions={{ position: 'left' }}
            />
          </div>
        </h4>

        <div className="col-12 md:col-12">
          <table width="100%" id="lawsuit_summary" cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td width="120" style={{ padding: '5px' }}><strong>Pasta nº:</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_num_pasta}</td>
              </tr>
              <tr style={{ background: '#e2e2e2' }}>
                <td width="120" style={{ padding: '5px' }}><strong>Processo nº:</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_reg}</td>
              </tr>
              <tr>
                <td width="120" style={{ padding: '5px' }}><strong>Autor(es):</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_autor}</td>
              </tr>
              <tr style={{ background: '#e2e2e2' }}>
                <td width="120" style={{ padding: '5px' }}><strong>Réu(s):</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_reu}</td>
              </tr>
              <tr>
                <td width="120" style={{ padding: '5px' }}><strong>Assunto:</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_assunto}</td>
              </tr>
              <tr style={{ background: '#e2e2e2' }}>
                <td width="120" style={{ padding: '5px' }}><strong>Origem:</strong></td>
                <td style={{ padding: '5px' }}>{origin}</td>
              </tr>
            </tbody>
          </table>

          <h4>Informações gerais</h4>

          <table width="550" id="lawsuit_other_info" cellPadding={0} cellSpacing={0}>
            <tbody>
              {customerName ? (
                <tr>
                  <td width="120" style={{ padding: '5px' }}><strong>Cliente:</strong></td>
                  <td style={{ padding: '5px' }}>{customerName}</td>
                </tr>
              ) : null }
              {attorneyName ? (
                <tr>
                  <td width="120" style={{ padding: '5px' }}><strong>Advogado responsável:</strong></td>
                  <td style={{ padding: '5px' }}>{attorneyName}</td>
                </tr>
              ) : null }
              {counterAttorney ? (
                <tr>
                  <td width="120" style={{ padding: '5px' }}><strong>Advogado parte adversa:</strong></td>
                  <td style={{ padding: '5px' }}>{counterAttorney}</td>
                </tr>
              ) : null }
            </tbody>
          </table>

          <h4>Andamento do processo</h4>

          <DataTable
            tableClassName="table-lawsuitsprogresses table"
            id="table-lawsuitsprogresses"
            value={lawsuitProgresses}
            dataKey="and_id"
            stripedRows
            className="p-datatable-sm"
            responsiveLayout="scroll"
            paginator
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={5}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            emptyMessage="Nenhum registro encontrado."
          >
            <Column key="and_data" field="and_data" header="Data" alignHeader="center" body={progressDateBodyTemplate} align="left" style={{ width: '100px' }} />
            <Column field="and_andamento" header="Andamento" alignHeader="center" align="left" />
          </DataTable>
        </div>

      </Sidebar>
      <div className="datatable-responsive">
        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />
          <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRecord([])} />

          <DataTable
            tableClassName="table-lawsuits table"
            id="table-lawsuits"
            ref={dt}
            value={lawsuits}
            loading={loading}
            editMode="row"
            dataKey="proc_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedLawsuits}
            loadingIcon="pi pi-spin pi-spinner"
            onSelectionChange={(e) => {
              const selSelectedLawsuits = e.value as ILawsuitProperties[];
              setSelectedLawsuits(selSelectedLawsuits);
            }}
            onRowClick={(e) => {
              if (e.originalEvent.detail === 1) {
                timerId = setTimeout(() => {
                  loadSelectedLawsuit(e.data);
                  setVisibleRight(true);
                }, 200);
              }
            }}
            onRowDoubleClick={(e) => {
              clearTimeout(timerId);
              editLawsuit(e.data);
            }}
            onContextMenuSelectionChange={(e) => {
              const selContextSelection = e.value as ILawsuitProperties[];
              setSelectedRecord(selContextSelection);
              // eslint-disable-next-line no-console
              // console.log(selContextSelection);
            }}
            onContextMenu={(e) => cm.current?.show(e.originalEvent)}
          >
            <Column field="row_number" header="Nº" sortable headerStyle={{ width: '3rem' }} />
            {dynamicColumns}
            <Column field="proc_id" style={{ display: 'none' }} />
            <Column body={actionBodyTemplate} style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteLawsuit} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLawsuitFooter} onHide={hidedeleteLawsuit}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {lawsuit && (
                <span>
                  Você tem certeza que deseja excluir
                  <br />
                  <br />
                  <b>{lawsuit.proc_reg}</b>
                  ?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteLawsuitsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLawsuitsDialogFooter} onHide={hidedeleteLawsuitsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {lawsuit
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default Lawsuit;
