/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../services/api";

import { Container } from "./styles";

interface OutcomeData {
  proc_desfecho_cod_result: number;
  proc_desfecho_data: Date | null;
  proc_desfecho_obs: string;
  proc_desfecho_arquivo_morto: boolean;
  proc_desfecho_data_baixa: Date | null;
  proc_desfecho_caixa_arq_morto: string;
  proc_desfecho_data_email: Date | null;
  proc_desfecho_cod_proc: number;
  proc_desfecho_cod_cliente: number;
  proc_desfecho_notificar_adv: boolean;
}

interface FinancialItemsData {
  proc_pedidos_ativo: boolean;
  proc_pedidos_possib_geral: number;
  proc_pedidos_possib_geral_tp2: number;
  proc_indice: number;
  proc_valor_causa: number;
  proc_pedidos_data_atu: Date;
  honorarios_iniciais: string;
  honorarios_iniciais_nome: string;
  honorarios_intermediarios: string;
  honorarios_intermediarios_nome: string;
  honorarios_finais: string;
  honorarios_finais_nome: string;
}

const AddEditOutcome: React.FC = () => {
  const toast = useRef<Toast | null>(null);
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentLawsuit, setCurrentLawsuit] = useState(0);
  const [currentCustomer, setCurrentCustomer] = useState(0);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get("cod");
  const clie = params.get("clie");
  const activeTab = params.get("active");

  const [financialItems, setFinancialItems] = useState<FinancialItemsData[]>(
    []
  );
  /* const [dateOutcome, setDateOutcome] = useState<string | Date | Date[] | null>(
    new Date()
  );
  const [dateArchived, setDateArchived] = useState<
    string | Date | Date[] | null
  >(null);
*/
  // control tab changes when leaving/returning to page
  useEffect(() => {
    if (activeTab !== undefined || activeTab !== null) {
      setActiveIndex(Number(activeTab));
    }
    if (cod !== undefined || cod !== null) {
      setCurrentLawsuit(Number(cod));
    }
    if (clie !== undefined || clie !== null) {
      setCurrentCustomer(Number(clie));
    }
  }, [activeTab, cod, clie]);

  const initialValues: OutcomeData = {
    proc_desfecho_cod_result: 0,
    proc_desfecho_data: null,
    proc_desfecho_obs: "",
    proc_desfecho_arquivo_morto: false,
    proc_desfecho_data_baixa: null,
    proc_desfecho_caixa_arq_morto: "",
    proc_desfecho_data_email: null,
    proc_desfecho_cod_proc: Number(cod),
    proc_desfecho_cod_cliente: Number(currentCustomer),
    proc_desfecho_notificar_adv: true,
  };

  const returnLawsuitURL = `/lawsuit/main/edit/${currentLawsuit}?active=${activeIndex}`;

  const optionsBoolean = [
    { name: "Sim", value: true },
    { name: "Não", value: false },
  ];

  const [lawsuitOutcomeResultsOptions, setLawsuitOutcomeResultsOptions] =
    useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("lawsuits/results/show/all").then((response) => {
        response.data.map(
          (lawsuitOutcomeResult: {
            resultado_id: number;
            resultado_descricao: string;
          }) =>
            lawsuitOutcomeResultsOptions.push({
              name: lawsuitOutcomeResult.resultado_descricao,
              value: lawsuitOutcomeResult.resultado_id,
            })
        );
      });
      lawsuitOutcomeResultsOptions.splice(0, 1);
      setLawsuitOutcomeResultsOptions(lawsuitOutcomeResultsOptions);
    })();
  }, [lawsuitOutcomeResultsOptions]);

  useEffect(() => {
    (async () => {
      await api
        .get(`lawsuits/financialitems/show/filtered/${cod}`)
        .then((response) => {
          setFinancialItems(response.data);
        });
    })();
  }, [cod]);

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  const handleOnSubmit = async (
    values: OutcomeData,
    { setSubmitting }: FormikHelpers<OutcomeData>
  ) => {
    if (financialItems.length > 0) {
      if (
        Number(financialItems[0].honorarios_iniciais) === 1 &&
        Number(financialItems[0].honorarios_intermediarios) === 1 &&
        Number(financialItems[0].honorarios_finais) === 1
      ) {
        if (isAddMode) {
          try {
            const formData = {
              proc_desfecho_cod_result: values.proc_desfecho_cod_result,
              proc_desfecho_data: values.proc_desfecho_data,
              proc_desfecho_obs: values.proc_desfecho_obs,
              proc_desfecho_arquivo_morto: values.proc_desfecho_arquivo_morto,
              proc_desfecho_data_baixa: values.proc_desfecho_data_baixa,
              proc_desfecho_caixa_arq_morto:
                values.proc_desfecho_caixa_arq_morto,
              proc_desfecho_cod_proc: Number(cod),
              proc_desfecho_cod_cliente: Number(currentCustomer),
            };

            await api.post("/lawsuits/outcomes/create", formData);
            await api.patch(
              `/lawsuits/main/updatelawsuitstatus/${Number(cod)}`
            );

            toast.current?.show({
              severity: "success",
              summary: "Cadastro atualizado",
              detail:
                "As informações do cadastro foram atualizadas com sucesso.",
              life: 3000,
            });
            setSubmitting(false);

            history.push(returnLawsuitURL);
          } catch (error) {
            toast.current?.show({
              severity: "error",
              summary: "Ocorreu um problema",
              detail: "Tente repetir o cadastro ou contate o administrador.",
              life: 3000,
            });
          }
        } else {
          try {
            const formData = {
              proc_desfecho_id: id,
              proc_desfecho_cod_result: values.proc_desfecho_cod_result,
              proc_desfecho_data: values.proc_desfecho_data,
              proc_desfecho_obs: values.proc_desfecho_obs,
              proc_desfecho_arquivo_morto: values.proc_desfecho_arquivo_morto,
              proc_desfecho_data_baixa: values.proc_desfecho_data_baixa,
              proc_desfecho_caixa_arq_morto:
                values.proc_desfecho_caixa_arq_morto,
              proc_desfecho_cod_proc: Number(cod),
              proc_desfecho_cod_cliente: Number(currentCustomer),
            };

            await api.put(`/lawsuits/outcomes/edit/${id}`, formData);
            await api.patch(
              `/lawsuits/main/updatelawsuitstatus/${Number(cod)}`
            );

            toast.current?.show({
              severity: "success",
              summary: "Cadastro atualizado",
              detail:
                "As informações do cadastro foram atualizadas com sucesso.",
              life: 3000,
            });
            setSubmitting(false);

            history.push(returnLawsuitURL);
          } catch (error) {
            toast.current?.show({
              severity: "error",
              summary: "Ocorreu um problema",
              detail: "Tente repetir o cadastro ou contate o administrador.",
              life: 3000,
            });
          }
        }
        // remove financial information from lawsuit
        await api.put(`/lawsuits/financialitems/removefinancialinfo/${cod}`);

        if (values.proc_desfecho_notificar_adv) {
          try {
            const lawsuitID = cod;

            // dateArchived,
            api.post("/lawsuits/outcomes/sendmail", {
              lawsuitID,
              dateArchived: values.proc_desfecho_data_baixa,
            });

            toast.current?.show({
              severity: "success",
              summary: "E-mail(s) enviado(s) com sucesso",
              detail:
                "O(s) e-mails selecionados para envio foram processados com sucesso.",
              life: 3000,
            });
          } catch (err) {
            toast.current?.show({
              severity: "error",
              summary: "Erro ao enviar o email",
              detail: "Ocorreu um erro ao enviar o e-mail. Tente novamente.",
              life: 3000,
            });
          }
        }
      }
      toast.current?.show({
        severity: "error",
        summary: "Ocorreu um problema",
        detail:
          "Você precisa informar todas as opções de honorários para arquivar o processo.",
        life: 3000,
      });
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Ocorreu um problema",
        detail:
          "Você precisa informar todas as opções de honorários para arquivar o processo.",
        life: 3000,
      });
    }
  };

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>{isAddMode ? "Cadastrar " : "Editar "} desfecho do processo</h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          // validationSchema={validationSchema}
          // enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                if (!isAddMode) {
                  api.get(`/lawsuits/outcomes/show/${id}`).then((response) => {
                    setValues(response.data);

                    const dateOutcomeBR = new Date(
                      response.data.proc_desfecho_data
                    );
                    if (response.data.proc_desfecho_data !== null) {
                      const dateOutcomeISO = new Date(
                        dateOutcomeBR.toISOString().slice(0, -1)
                      );
                      setFieldValue("proc_desfecho_data", dateOutcomeISO);
                      // setDateOutcome(dateOutcomeISO);
                    }

                    const dateArchivedBR = new Date(
                      response.data.proc_desfecho_data_baixa
                    );
                    if (response.data.proc_desfecho_data_baixa !== null) {
                      const dateArchivedISO = new Date(
                        dateArchivedBR.toISOString().slice(0, -1)
                      );
                      // setDateArchived(dateArchivedISO);
                      setFieldValue(
                        "proc_desfecho_data_baixa",
                        dateArchivedISO
                      );
                    }
                  });
                }
              } catch (e) {
                toast.current?.show({
                  severity: "error",
                  summary: "Ocorreu um problema",
                  detail:
                    "Não foi possível carregar as informações do cadastro selecionado.",
                  life: 3000,
                });
              }
            }, [setValues, setFieldValue]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-3">
                      <label htmlFor="proc_desfecho_data">
                        Data
                        <Calendar
                          inputId="proc_desfecho_data"
                          name="proc_desfecho_data"
                          className="inputfield w-full"
                          value={isAddMode ? null : values.proc_desfecho_data}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </label>
                    </div>

                    <div className="col-12 md:col-4">
                      <label htmlFor="proc_desfecho_cod_result">
                        Resultado
                      </label>
                      <Dropdown
                        id="proc_desfecho_cod_result"
                        name="proc_desfecho_cod_result"
                        value={values.proc_desfecho_cod_result}
                        options={lawsuitOutcomeResultsOptions}
                        onChange={handleChange}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_desfecho_obs">Observação</label>
                      <InputTextarea
                        id="proc_desfecho_obs"
                        name="proc_desfecho_obs"
                        className="inputfield w-full"
                        value={values.proc_desfecho_obs || ""}
                        onChange={handleChange}
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-6 lg:col-3">
                      <label htmlFor="proc_desfecho_arquivo_morto">
                        Encaminhar para arquivo morto?
                      </label>
                      <SelectButton
                        id="proc_desfecho_arquivo_morto"
                        value={values.proc_desfecho_arquivo_morto}
                        options={optionsBoolean}
                        optionLabel="name"
                        optionValue="value"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                      <label htmlFor="proc_desfecho_data_baixa">
                        Data de baixa
                        <Calendar
                          inputId="proc_desfecho_data_baixa"
                          name="proc_desfecho_data_baixa"
                          className="inputfield w-full"
                          value={
                            isAddMode ? null : values.proc_desfecho_data_baixa
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                      <label htmlFor="proc_desfecho_caixa_arq_morto">
                        Caixa do arquivo morto
                      </label>
                      <InputText
                        id="proc_desfecho_caixa_arq_morto"
                        name="proc_desfecho_caixa_arq_morto"
                        className="inputfield w-full"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.proc_desfecho_caixa_arq_morto}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-6 lg:col-6">
                      <label htmlFor="proc_desfecho_notificar_adv">
                        Notificar advogado responsável?
                      </label>
                      <SelectButton
                        id="proc_desfecho_notificar_adv"
                        value={values.proc_desfecho_notificar_adv}
                        options={optionsBoolean}
                        optionLabel="name"
                        optionValue="value"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 p-mt-20">
                  <div className="col-12">
                    Todos os campos marcados com * são de preenchimento
                    obrigatório.
                  </div>
                  <header />
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Enviar dados"
                      className="p-mr-2"
                      type="submit"
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                    <Button
                      icon="pi pi-times"
                      iconPos="left"
                      label="Cancelar e voltar"
                      className="p-button-danger p-mr-2"
                      type="button"
                      onClick={handleCancel}
                    />
                    <Button
                      icon="pi pi-trash"
                      tooltip="Excluir cadastro"
                      tooltipOptions={{ position: "left" }}
                      iconPos="left"
                      label="Excluir desfecho"
                      className="p-button-warning"
                      type="button"
                      disabled={isAddMode}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default AddEditOutcome;
