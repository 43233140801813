/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';

import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface LawsuitProgressData {
  and_proc_id: number;
  and_data: Date;
  and_andamento:string;
  and_arquivo:string;
  and_obs :string;
  and_enviado:boolean;
  and_exibir:boolean;
  and_dat_alt: Date;
  id_adv_alt: number;
  nivelusuario:string;
}

const AddEditLawsuitProgress:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUploadRef = useRef<any>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentLawsuit, setCurrentLawsuit] = useState(0);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');
  const activeTab = params.get('active');

  let loggedUserLevel = '';
  let loggedUserId = 0;
  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem('@Jurisnet:access');

  if (typeof accessData === 'string') {
    const access = JSON.parse(accessData);
    loggedUserLevel = access.nivelusuario;
    loggedUserId = Number(access.usuario_logado);
  }

  // control tab changes when leaving/returning to page
  useEffect(() => {
    if (activeTab !== undefined || activeTab !== null) {
      setActiveIndex(Number(activeTab));
    }
    if (cod !== undefined || cod !== null) {
      setCurrentLawsuit(Number(cod));
    }
  }, [activeTab, cod]);

  const initialValues: LawsuitProgressData = {
    and_proc_id: Number(cod),
    and_data: new Date(),
    and_andamento: '',
    and_arquivo: '',
    and_obs: '',
    and_enviado: false,
    and_exibir: false,
    and_dat_alt: new Date(),
    id_adv_alt: loggedUserId,
    nivelusuario: loggedUserLevel,
  };

  const returnLawsuitURL = `/lawsuit/main/edit/${currentLawsuit}?active=${activeIndex}`;

  const validationSchema = Yup.object().shape({
    and_andamento: Yup.string().required('Descrição obrigatória'),
  });

  const options = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false },
  ];

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleOnSubmit = async (
    values: LawsuitProgressData,
    { setSubmitting }: FormikHelpers<LawsuitProgressData>,
  ) => {
    if (isAddMode) {
      try {
        const formData = {
          and_proc_id: Number(cod),
          and_data: progressDate,
          and_andamento: values.and_andamento,
          and_arquivo: '',
          and_obs: values.and_obs,
          and_enviado: false,
          and_exibir: values.and_exibir,
          and_dat_alt: new Date(),
          id_adv_alt: loggedUserId,
          nivelusuario: loggedUserLevel,
        };

        if (selectedFile && values.and_arquivo === '') {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(selectedFile);
        }

        await api.post('/lawsuits/progresses/create', formData).then((response) => {
          setSubmitting(false);

          if (selectedFile && values.and_arquivo === '') {
            const data = new FormData();

            data.append('and_id', response.data.and_id);
            if (selectedFile) {
              data.append('and_arquivo', selectedFile);
            } else {
              data.append('arquivo', values.and_arquivo);
            }

            api.patch('/lawsuits/progresses/file', data);

            addToast({
              type: 'info',
              title: 'Upload concluído!',
              description:
                  'Arquivo enviado com sucesso.',
            });
          }
        });

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        await api.patch(`/lawsuits/main/updatelawsuitdate/${cod}`);

        history.push(`/lawsuit/main/edit/${currentLawsuit}?active=${activeIndex}`);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        if (selectedFile && values.and_arquivo === '') {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(selectedFile);
        }

        const formData = {
          and_proc_id: Number(cod),
          and_data: progressDate,
          and_andamento: values.and_andamento,
          and_arquivo: values.and_arquivo === '' ? '' : values.and_arquivo,
          and_obs: values.and_obs,
          and_enviado: false,
          and_exibir: values.and_exibir,
          and_dat_alt: new Date(),
          id_adv_alt: loggedUserId,
          nivelusuario: loggedUserLevel,
        };

        await api.put(`/lawsuits/progresses/edit/${id}`, formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        if (selectedFile && values.and_arquivo === '') {
          const data = new FormData();

          data.append('and_id', id);
          if (selectedFile) {
            data.append('and_arquivo', selectedFile);
          } else {
            data.append('arquivo', values.and_arquivo);
          }

          api.patch('/lawsuits/progresses/file', data);

          addToast({
            type: 'info',
            title: 'Upload concluído!',
            description:
                'Arquivo enviado com sucesso.',
          });
        }

        await api.patch(`/lawsuits/main/updatelawsuitdate/${cod}`);

        history.push(`/lawsuit/main/edit/${currentLawsuit}?active=${activeIndex}`);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                `Tente repetir o cadastro ou contate o administrador. ${error}`,
        });
      }
    }
  };

  const handleFileDelete = (values: LawsuitProgressData) => {
    try {
      const data = new FormData();

      data.append('and_id', id);
      data.append('arquivo', values.and_arquivo);

      api.patch('/lawsuits/progresses/file', data);

      addToast({
        type: 'info',
        title: 'Arquivo excluído!',
        description:
              'Arquivo excluído com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
          'O arquivo não foi encontrado para ser excluído.',
      });
    }
  };

  const handleSetFile = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: { files: any[]; }) => {
      setSelectedFile(e.files[0]);
    },
    [],
  );

  const handleFileChange = useCallback(
    (e) => {
      if (e.files.length > 0) {
        setSelectedFile(e.files[0]);
      }
    },
    [],
  );

  const [progress, setProgresses] = useState(null);
  const [progressDate, setProgressDate] = useState<string | Date | Date[] | null>(new Date());

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            andamento do processo
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          // innerRef={dataRef}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={progress || initialValues}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setValues,
            setFieldValue,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                if (!isAddMode) {
                  api.get(`/lawsuits/progresses/show/${id}`).then((response) => {
                    setProgresses(response.data);
                    setValues(response.data);

                    if (response.data.and_data !== null) {
                      const current = new Date(response.data.and_data);
                      const utc = new Date(
                        current.getTime()
                        + current.getTimezoneOffset()
                        * 60000,
                      );

                      // setFieldValue('and_data', new Date(utc).toString());
                      setProgressDate(new Date(utc));
                    }
                  });
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              }
            }, [setValues]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="and_data">
                          Data *
                        </label>
                        <Calendar
                          inputId="and_data"
                          name="and_data"
                          className="inputfield w-full"
                          value={progressDate}
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setProgressDate(e.value);
                            }
                          }}
                          onBlur={handleBlur}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <div className="col-12 md:col-12">
                        <label htmlFor="and_andamento">
                          Descrição do andamento *
                        </label>
                        <InputTextarea
                          id="and_andamento"
                          name="and_andamento"
                          className="inputfield w-full"
                          value={values.and_andamento}
                          onChange={handleChange}
                          rows={5}
                          cols={30}
                        />
                        <ErrorMessage name="and_andamento">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      {isAddMode ? (
                        <div className="col-12 md:col-12">
                          <label htmlFor="and_exibir">
                            Disponível ao Cliente
                          </label>
                          <SelectButton
                            id="and_exibir"
                            value={values.and_exibir}
                            options={options}
                            optionLabel="name"
                            onChange={handleChange}
                          />
                        </div>
                      ) : ('') }
                    </div>
                    { values.and_arquivo === '' ? (
                      <div className="col-12 md:col-12">
                        <label htmlFor="and_arquivo">
                          Arquivo para anexar com o andamento
                        </label>
                        <FileUpload
                          ref={fileUploadRef}
                          multiple={false}
                          mode="basic"
                          name="and_arquivo[]"
                          id="and_arquivo"
                          accept=".doc,.docx,.pdf,.jpeg,.jpg"
                          maxFileSize={15000000}
                          chooseLabel="CLIQUE AQUI para selecionar arquivo para upload"
                          onSelect={(e) => handleSetFile(e)}
                          customUpload
                          uploadHandler={handleFileChange}
                        />
                      </div>
                    ) : (
                      <div className="col-12 md:col-12">
                        Arquivo anexo:
                        {' '}
                        <span className="p-mr-2 red">
                          {values.and_arquivo}
                        </span>
                        <Button
                          icon="pi pi-trash"
                          tooltip="Excluir cadastro"
                          tooltipOptions={{ position: 'left' }}
                          className="p-button-rounded p-button-warning"
                          title="Excluir este arquivo"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleFileDelete(values);
                            setFieldValue('and_arquivo', '');
                          }}
                        />
                      </div>
                    ) }
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={handleCancel}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditLawsuitProgress;
