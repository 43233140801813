import React, {
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useLocation } from 'react-router-dom';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Panel } from 'primereact/panel';
// import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import moment from 'moment';
import robotoRegular from '../../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../services/api';

import CompanyLogo from '../../../../assets/logo.png';

interface IBilledHours {
  row_number: number;
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_descricao: string;
  horas_data: Date;
  horas_hora_inicial: Date;
  horas_hora_final: Date;
  horas_documento: string;
  horas_data_alteracao: Date;
  horas_hora_total: string;
  horas_tipo: string;
  advogado_nome: string;
  administrador_nome: string;
  cliente_nome: string;
  valor_ajuste_nao_faturado: number;
}

interface ICustomers {
  horas_cod_cliente: number;
  cliente_nome: string;
  horas_hora_total: string;
}

interface ISectorLawsuits {
  lawsuitNumber: string;
  totalLawsuitDuration: string;
  totalLawsuitDurationDecimals: string;
  totalLawsuitDurationPercentage: string;
}

interface ISectorConsultancy {
  sector?: string;
  totalConsultancyDuration: string;
  totalConsultancyDurationDecimals: string;
  totalConsultancyDurationPercentage: string;
}

interface ISectors {
  sector: string;
  total_hours: string;
  total_hours_decimals: string;
  percentage?: string;
  lawsuitArray?: ISectorLawsuits[];
  consultancyArray?: ISectorConsultancy[];
}

const HourPercentageByCustomerReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  const [groupedHours, setGroupedHours] = useState<ICustomers[]>([]);
  const [sectorHours, setSectorHours] = useState<ISectors[]>([]);
  const [sectorConsultancyHours, setSectorConsultancyHours] = useState<ISectorConsultancy[]>([]);
  const [totalTime, setTotalTime] = useState('');
  const [totalLawsuitTime, setTotalLawsuitTime] = useState('');
  const [totalNonDefinedLawsuitTime, setTotalNonDefinedLawsuitTime] = useState('');
  const [totalConsultancyTime, setTotalConsultancyTime] = useState('');
  const [loading, setLoading] = useState(true);
  // const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/reports/hour/percentage/groupbycustomer', state).then((response) => {
          const startString = 'Autos: ';
          const endString = ' -';

          const customers: {
            horas_cod_cliente: number; cliente_nome: string; horas_hora_total: string;
          }[] = [];

          const filteredIds: number[] = [];
          const totalDurations: string[] = [];
          const totalLawsuitDurations: string[] = [];
          const totalNonDefinedLawsuitDurations: string[] = [];
          const totalConsultancyDurations: string[] = [];
          const filteredSectorLawsuits: string[] = [];
          const sectorHourArray: ISectors[] = [];
          let sectorLawsuitsHourArray: ISectorLawsuits[] = [];
          const sectorConsultancyHourArray: ISectorConsultancy[] = [];

          response.data.forEach(async (data: IBilledHours, index: number) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let horas_total = '';
            let total_de_horas = '';
            let total_h1 = 0;
            let total_m1 = 0;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let horas_totals = '';
            let total_de_horass = '';
            let total_h1s = 0;
            let total_m1s = 0;

            if (index === 0) {
              filteredIds.push(data.horas_cod_cliente);
            } else {
              const found = filteredIds.find((element) => element === data.horas_cod_cliente);
              if (!found) {
                filteredIds.push(data.horas_cod_cliente);
              } else {
                return;
              }
            }

            const filteredCustomer = response.data.filter((request:
                { horas_cod_cliente: number;
                }) => request.horas_cod_cliente === data.horas_cod_cliente);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filteredCustomer.forEach((filtered: any) => {
              if (!filtered.horas_hora_total) {
                horas_total = '0:00';
              } else {
                const horas_total_tmp = filtered.horas_hora_total.split(':');

                if (Number(horas_total_tmp[0]) >= 10) {
                  horas_total = `${horas_total_tmp[0]}:`;
                } else {
                  horas_total = `0${horas_total_tmp[0]}:`;
                }

                if (Number(horas_total_tmp[1]) > 0) {
                  if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                    horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
                  } else {
                    horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                  }
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  horas_total += '00';
                }

                total_h1 += Number(horas_total_tmp[0]);

                if (Number(horas_total_tmp[1]) > 0) {
                  total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
                }
              }

              if (total_m1 >= 60) {
                total_h1 += 1;
                total_m1 -= 60;
              }

              if (total_h1 >= 10) {
                total_de_horas = `${total_h1}`;
              } else {
                total_de_horas = `0${total_h1}`;
              }

              if (total_m1 >= 10) {
                total_de_horas += `:${total_m1}`;
              } else {
                total_de_horas += `:0${total_m1}`;
              }
            });

            totalDurations.push(total_de_horas);
            customers.push({ horas_cod_cliente: data.horas_cod_cliente, cliente_nome: data.cliente_nome, horas_hora_total: `${total_de_horas}` });

            const decimalHourTotal = total_de_horas.split(':')
              .map((val) => parseInt(val, 10))
              .reduce((
                previousValue, currentValue, currentIndex,
                // eslint-disable-next-line no-restricted-properties
              ) => previousValue + currentValue / Math.pow(60, currentIndex));

            // control hours per sector
            const sectorData = [...filteredCustomer];
            const sectors = sectorData.map((s: { advogado_setor: string; }) => s.advogado_setor);

            const sectorResults = sectors.filter((q, idx) => sectors.indexOf(q) === idx);

            sectorResults
              .sort((a, b) => ((a || '')
                .localeCompare((b || ''), 'pt', { ignorePunctuation: true })));

            sectorResults.forEach((item) => {
              horas_totals = '';
              total_de_horass = '';
              total_h1s = 0;
              total_m1s = 0;

              const allSectors = filteredCustomer
                .filter((val: { advogado_setor: string; }) => val.advogado_setor === item);

              allSectors.forEach(async (filteredSector: IBilledHours) => {
                if (!filteredSector.horas_hora_total) {
                  horas_totals = '0:00';
                } else {
                  const horas_total_tmp = filteredSector.horas_hora_total.split(':');

                  if (Number(horas_total_tmp[0]) >= 10) {
                    horas_totals = `${horas_total_tmp[0]}:`;
                  } else {
                    horas_totals = `0${horas_total_tmp[0]}:`;
                  }

                  if (Number(horas_total_tmp[1]) > 0) {
                    if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                      horas_totals += ((Number(horas_total_tmp[1]) / 60) * 100)
                        .toFixed(0).toString();
                    } else {
                      horas_totals += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                    }
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    horas_totals += '00';
                  }

                  total_h1s += Number(horas_total_tmp[0]);

                  if (Number(horas_total_tmp[1]) > 0) {
                    total_m1s += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
                  }
                }

                if (total_m1s >= 60) {
                  total_h1s += 1;
                  total_m1s -= 60;
                }

                if (total_h1s >= 10) {
                  total_de_horass = `${total_h1s}`;
                } else {
                  total_de_horass = `0${total_h1s}`;
                }

                if (total_m1s >= 10) {
                  total_de_horass += `:${total_m1s}`;
                } else {
                  total_de_horass += `:0${total_m1s}`;
                }
              });

              const decimalHourSector = total_de_horass.split(':')
                .map((val) => parseInt(val, 10))
                .reduce((
                  previousValue, currentValue, currentIndex,
                // eslint-disable-next-line no-restricted-properties
                ) => previousValue + currentValue / Math.pow(60, currentIndex));

              const formattedDecimalHourSector = decimalHourSector.toFixed(2).replace('.', ',');
              const percentageHoursSector = ((decimalHourSector / decimalHourTotal) * 100).toFixed(2).replace('.', ',');

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              allSectors.forEach(async (filteredSector: any, indexSector: number) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let horas_totalp = '';
                let total_de_horasp = '';
                let total_h1p = 0;
                let total_m1p = 0;

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let horas_totalpnd = '';
                let total_de_horaspnd = '';
                let total_h1pnd = 0;
                let total_m1pnd = 0;

                if (filteredSector.horas_tipo.toLowerCase() === 'processo') {
                  const descriptionField = filteredSector.horas_descricao;
                  if (descriptionField.toLowerCase().includes('autos:')) {
                    const position = descriptionField.indexOf(startString) + startString.length;
                    const lawsuitNumber = descriptionField
                      .substring(position, descriptionField
                        .indexOf(endString, position));

                    if (indexSector === 0) {
                      filteredSectorLawsuits.push(lawsuitNumber);
                    } else {
                      const found = filteredSectorLawsuits
                        .find((element) => element === lawsuitNumber);
                      if (!found) {
                        filteredSectorLawsuits.push(lawsuitNumber);
                      } else {
                        return;
                      }
                    }

                    const allLawsuits = allSectors
                      .filter((val: { horas_descricao: string; }) => val.horas_descricao
                        .includes(lawsuitNumber));

                    if (allLawsuits.length > 0) {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      allLawsuits.forEach((filteredLawsuit: any) => {
                        if (!filteredLawsuit.horas_hora_total) {
                          horas_totalp = '0:00';
                        } else {
                          const horas_total_tmp = filteredLawsuit.horas_hora_total.split(':');

                          if (Number(horas_total_tmp[0]) >= 10) {
                            horas_totalp = `${horas_total_tmp[0]}:`;
                          } else {
                            horas_totalp = `0${horas_total_tmp[0]}:`;
                          }

                          if (Number(horas_total_tmp[1]) > 0) {
                            if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                              horas_totalp += ((Number(horas_total_tmp[1]) / 60) * 100)
                                .toFixed(0).toString();
                            } else {
                              horas_totalp += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                            }
                          } else {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            horas_totalp += '00';
                          }

                          total_h1p += Number(horas_total_tmp[0]);

                          if (Number(horas_total_tmp[1]) > 0) {
                            total_m1p += Number(((Number(horas_total_tmp[1]) / 60) * 100)
                              .toFixed(0));
                          }
                        }

                        if (total_m1p >= 60) {
                          total_h1p += 1;
                          total_m1p -= 60;
                        }

                        if (total_h1p >= 10) {
                          total_de_horasp = `${total_h1p}`;
                        } else {
                          total_de_horasp = `0${total_h1p}`;
                        }

                        if (total_m1p >= 10) {
                          total_de_horasp += `:${total_m1p}`;
                        } else {
                          total_de_horasp += `:0${total_m1p}`;
                        }
                      });
                      const decimalHourSectorLawsuit = total_de_horasp.split(':')
                        .map((val) => parseInt(val, 10))
                        .reduce((
                          previousValue, currentValue, currentIndex,
                          // eslint-disable-next-line no-restricted-properties
                        ) => previousValue + currentValue / Math.pow(60, currentIndex));

                      const formattedDecimalHourSectorLawsuit = decimalHourSectorLawsuit.toFixed(2).replace('.', ',');
                      const percentageHoursSectorLawsuit = ((decimalHourSectorLawsuit / decimalHourTotal) * 100).toFixed(2).replace('.', ',');

                      sectorLawsuitsHourArray.push({
                        lawsuitNumber,
                        totalLawsuitDuration: total_de_horasp,
                        totalLawsuitDurationDecimals: formattedDecimalHourSectorLawsuit,
                        totalLawsuitDurationPercentage: percentageHoursSectorLawsuit,
                      });
                      totalLawsuitDurations.push(total_de_horasp);
                    }
                  } else {
                    if (!filteredSector.horas_hora_total) {
                      horas_totalpnd = '0:00';
                    } else {
                      const horas_total_tmp = filteredSector.horas_hora_total.split(':');

                      if (Number(horas_total_tmp[0]) >= 10) {
                        horas_totalpnd = `${horas_total_tmp[0]}:`;
                      } else {
                        horas_totalpnd = `0${horas_total_tmp[0]}:`;
                      }

                      if (Number(horas_total_tmp[1]) > 0) {
                        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                          horas_totalpnd += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                        }
                      } else {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        horas_totalpnd += '00';
                      }

                      total_h1pnd += Number(horas_total_tmp[0]);

                      if (Number(horas_total_tmp[1]) > 0) {
                        total_m1pnd += Number(((Number(horas_total_tmp[1]) / 60) * 100)
                          .toFixed(0));
                      }
                    }

                    if (total_m1p >= 60) {
                      total_h1pnd += 1;
                      total_m1pnd -= 60;
                    }

                    if (total_h1p >= 10) {
                      total_de_horaspnd = `${total_h1pnd}`;
                    } else {
                      total_de_horaspnd = `0${total_h1pnd}`;
                    }

                    if (total_m1pnd >= 10) {
                      total_de_horaspnd += `:${total_m1pnd}`;
                    } else {
                      total_de_horaspnd += `:0${total_m1pnd}`;
                    }

                    totalNonDefinedLawsuitDurations.push(total_de_horaspnd);
                  }

                  let ms = totalLawsuitDurations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
                  let sum = ms.reduce((prev, cur) => prev + cur, 0);

                  let d = moment.duration(sum, 'milliseconds');
                  let hours = Math.floor(d.asHours());
                  let minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
                  const lawsuitTime = `${hours}:${minutes}`;
                  setTotalLawsuitTime(lawsuitTime);

                  ms = totalNonDefinedLawsuitDurations.map((d2a) => moment
                    .duration(`${d2a}:00`).asSeconds() * 1000);
                  sum = ms.reduce((prev, cur) => prev + cur, 0);

                  d = moment.duration(sum, 'milliseconds');
                  hours = Math.floor(d.asHours());
                  minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
                  const nonDefinedLawsuitTime = `${hours}:${minutes}`;
                  setTotalNonDefinedLawsuitTime(nonDefinedLawsuitTime);
                }
              });

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              let horas_totala = '';
              let total_de_horasa = '';
              let total_h1a = 0;
              let total_m1a = 0;

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              allSectors.forEach(async (filteredSector: any) => {
                if (filteredSector.horas_tipo.toLowerCase() === 'assessoria') {
                  if (!filteredSector.horas_hora_total) {
                    horas_totala = '0:00';
                  } else {
                    const horas_total_tmp = filteredSector.horas_hora_total.split(':');

                    if (Number(horas_total_tmp[0]) >= 10) {
                      horas_totala = `${horas_total_tmp[0]}:`;
                    } else {
                      horas_totala = `0${horas_total_tmp[0]}:`;
                    }

                    if (Number(horas_total_tmp[1]) > 0) {
                      if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                        horas_totala += ((Number(horas_total_tmp[1]) / 60) * 100)
                          .toFixed(0).toString();
                      } else {
                        horas_totala += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                      }
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      horas_totala += '00';
                    }

                    total_h1a += Number(horas_total_tmp[0]);

                    if (Number(horas_total_tmp[1]) > 0) {
                      total_m1a += Number(((Number(horas_total_tmp[1]) / 60) * 100)
                        .toFixed(0));
                    }
                  }

                  if (total_m1a >= 60) {
                    total_h1a += 1;
                    total_m1a -= 60;
                  }

                  if (total_h1a >= 10) {
                    total_de_horasa = `${total_h1a}`;
                  } else {
                    total_de_horasa = `0${total_h1a}`;
                  }

                  if (total_m1a >= 10) {
                    total_de_horasa += `:${total_m1a}`;
                  } else {
                    total_de_horasa += `:0${total_m1a}`;
                  }
                }
              });

              let emptySector = '';
              if (item === '') {
                emptySector = 'Setor em branco';
              } else if (item === null) {
                emptySector = 'Setor nulo';
              } else {
                emptySector = 'Setor indefinido';
              }
              sectorHourArray.push({
                sector: item !== '' && item !== undefined && item !== null ? item : emptySector,
                total_hours: total_de_horass,
                total_hours_decimals: formattedDecimalHourSector,
                percentage: percentageHoursSector,
                lawsuitArray: sectorLawsuitsHourArray,
                consultancyArray: sectorConsultancyHourArray,
              });

              totalConsultancyDurations.push(total_de_horasa);

              const decimalHourSectorConsultancy = total_de_horasa.split(':')
                .map((val) => parseInt(val, 10))
                .reduce((
                  previousValue, currentValue, currentIndex,
                  // eslint-disable-next-line no-restricted-properties
                ) => previousValue + currentValue / Math.pow(60, currentIndex));

              const formattedDecimalHourSectorConsultancy = decimalHourSectorConsultancy.toFixed(2).replace('.', ',');
              const percentageHoursSectorConsultancy = ((decimalHourSectorConsultancy / decimalHourTotal) * 100).toFixed(2).replace('.', ',');

              sectorConsultancyHourArray.push({
                sector: item !== '' && item !== undefined && item !== null ? item : emptySector,
                totalConsultancyDuration: total_de_horasa !== 'NaN' ? total_de_horasa : '0:00',
                totalConsultancyDurationDecimals: formattedDecimalHourSectorConsultancy !== 'NaN' ? formattedDecimalHourSectorConsultancy : '0,00',
                totalConsultancyDurationPercentage: percentageHoursSectorConsultancy !== 'NaN' ? percentageHoursSectorConsultancy : '0,00',
              });

              sectorLawsuitsHourArray = [];
              // sectorConsultancyHourArray = [];
            });
          });

          setGroupedHours(customers);
          setSectorHours(sectorHourArray);
          setSectorConsultancyHours(sectorConsultancyHourArray);

          let ms = totalDurations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
          let sum = ms.reduce((prev, cur) => prev + cur, 0);

          let d = moment.duration(sum, 'milliseconds');
          let hours = Math.floor(d.asHours());
          let minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
          const time = `${hours}:${minutes}`;

          setTotalTime(time);

          ms = totalConsultancyDurations.map((d2) => moment.duration(`${d2}:00`).asSeconds() * 1000);
          sum = ms.reduce((prev, cur) => prev + cur, 0);

          d = moment.duration(sum, 'milliseconds');
          hours = Math.floor(d.asHours());
          minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
          const consultancyTime = `${hours}:${minutes}`;
          setTotalConsultancyTime(consultancyTime);
        });

        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, state]);

  const exportPdf = () => {
    setLoading(true);
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', [297, 210]);

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular');

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: groupedHours[0]?.cliente_nome,
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    const totalPagesExp = '{total_pages_count_string}';

    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    doc.setFontSize(12);
    doc.setTextColor(40);

    doc.text(`Cliente: ${groupedHours[0]?.cliente_nome}`, 15, 30);
    doc.text('Resumo de percentuais de hora trabalhadas (processos e assessoria)', 15, 35);

    autoTable(doc, {
      startY: 40,
      html: '#resumo-horas',
      includeHiddenHtml: true,
      theme: 'grid',
      tableWidth: 180,
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      footStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'right',
      },
      columnStyles: {
        0: { cellWidth: 80, halign: 'left' },
        1: { cellWidth: 25, halign: 'left' },
      },
      didDrawPage(data) {
        // Header
        doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

        const header1 = 'Percentuais de horas trabalhadas';
        const header2 = '(processos e assessoria)';

        const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        const x1 = ((pageWidth - hWidth1) / 2) + 15;
        const x2 = ((pageWidth - hWidth2) / 2) + 15;

        doc.setFontSize(12);
        doc.setTextColor(20);
        doc.text(header1, x1, 14);

        doc.setTextColor(40);
        doc.text(header2, x2, 19);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left, pageHeight - 15,
          data.settings.margin.right, pageHeight - 15,
        );

        // Footer
        const printedOn = new Date();
        const dateFormatted = format(
          printedOn,
          "dd/MM/yyyy 'às' HH:mm:ss'.'",
        );

        let str = `Página ${doc.getNumberOfPages()}`; // ${doc.getNumberOfPages()}
        if (typeof doc.putTotalPages === 'function') {
          str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
        }

        doc.setFontSize(8);

        const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
        const x3 = (pageWidth - pageCount) / 2;
        doc.text(str, x3 + 80, pageHeight - 8);

        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
      },
    });

    doc.setFontSize(12);
    doc.setTextColor(40);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let posY = (doc as any).lastAutoTable.finalY + 10;
    doc.text('Setores com horas e percentuais - processos e assessorias', 15, posY);

    autoTable(doc, {
      startY: posY + 5,
      html: '#table-groupedhourspercentage-all table',
      includeHiddenHtml: true,
      theme: 'grid',
      tableWidth: 180,
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      footStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'right',
      },
      columnStyles: {
        0: { cellWidth: 12, halign: 'center' },
        1: { cellWidth: 40, halign: 'left' },
        2: { cellWidth: 30, halign: 'right' },
        3: { cellWidth: 26, halign: 'right' },
      },
    });

    doc.setFontSize(12);
    doc.setTextColor(40);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    posY = (doc as any).lastAutoTable.finalY + 10;
    doc.text('Setores com horas e percentuais - somente assessorias', 15, posY);

    autoTable(doc, {
      startY: posY + 5,
      html: '#table-groupedhourspercentage-consultancy table',
      theme: 'grid',
      tableWidth: 180,
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      footStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'right',
      },
      columnStyles: {
        0: { cellWidth: 12, halign: 'center' },
        1: { cellWidth: 40, halign: 'left' },
        2: { cellWidth: 30, halign: 'right' },
        3: { cellWidth: 30, halign: 'right' },
        4: { cellWidth: 26, halign: 'right' },
      },
    });

    let lastPageNumber = 1;

    sectorHours.forEach((item, index) => {
      if (item.lawsuitArray && item.lawsuitArray.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        posY = (doc as any).lastAutoTable.finalY + 5;

        autoTable(doc, {
          margin: [30, 0, 15, 15],
          html: `#table-sectorhours-${item.sector
            .split(' ')
            .join('-')
            .toLowerCase()} table`,
          includeHiddenHtml: true,
          theme: 'grid',
          tableWidth: 180,
          pageBreak: index === 0 ? 'avoid' : 'always',
          headStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          bodyStyles: {
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          footStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'right',
          },
          didDrawPage(data) {
          // Header
            doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

            const header1 = 'Resumo de percentuais de hora trabalhadas';
            const header2 = '(processos e assessoria)';

            const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
            const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

            // Calculate text's x coordinate
            const x1 = ((pageWidth - hWidth1) / 2) + 15;
            const x2 = ((pageWidth - hWidth2) / 2) + 15;

            doc.setFontSize(12);
            doc.setTextColor(20);
            doc.text(header1, x1, 14);

            doc.setTextColor(40);
            doc.text(header2, x2, 19);

            doc.setLineWidth(0.5);
            doc.line(
              data.settings.margin.left, pageHeight - 15,
              data.settings.margin.right, pageHeight - 15,
            );

            if (data.table.startPageNumber && data.table.startPageNumber > lastPageNumber) {
              doc.setFontSize(12);
              doc.setTextColor(20);
              doc.text(` Horas setor: ${item.sector} (processos)`, 15, 25);
            }
            lastPageNumber = Number(data.table.startPageNumber);

            // Footer
            const printedOn = new Date();
            const dateFormatted = format(
              printedOn,
              "dd/MM/yyyy 'às' HH:mm:ss'.'",
            );

            let str = `Página ${index + 1}`;
            if (typeof doc.putTotalPages === 'function') {
              str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
            }

            doc.setFontSize(8);

            const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
            const x3 = (pageWidth - pageCount) / 2;
            doc.text(str, x3 + 80, pageHeight - 8);

            if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp);
            }
          },
        });
      }
    });

    // Footer
    // const printedOn = new Date();
    // const dateFormatted = format(
    //   printedOn,
    //   "dd/MM/yyyy 'às' HH:mm:ss'.'",
    // );

    // let str = `Página ${doc.getNumberOfPages()}`;
    // if (typeof doc.putTotalPages === 'function') {
    //   str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    // }

    // doc.setFontSize(8);

    // const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    // const x3 = (pageWidth - pageCount) / 2;
    // doc.text(str, x3 + 50, pageHeight - 8);

    // if (typeof doc.putTotalPages === 'function') {
    //   doc.putTotalPages(totalPagesExp);
    // }

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`Relatorio-${dateFormattedFile}.pdf`);
    setLoading(false);
  };

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de horas:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
        <Column footer={totalTime} colSpan={1} footerStyle={{ textAlign: 'right' }} />
        <Column colSpan={1} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupConsultancy = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de horas:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
        <Column footer={totalConsultancyTime} colSpan={1} footerStyle={{ textAlign: 'right' }} />
        <Column colSpan={2} />
      </Row>
    </ColumnGroup>
  );

  return (
    <Container>
      <header>
        <div>
          <h3>Relatório de Horas e Percentuais por Cliente - Modelo IX</h3>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <div className="p-toolbar p-component" role="toolbar">
            <div className="p-toolbar-group-left">
              Cliente:
              {' '}
              {groupedHours[0]?.cliente_nome}
            </div>
            <div className="p-toolbar-group-right">
              <Button
                type="button"
                icon="pi pi-file-pdf"
                onClick={exportPdf}
                className="p-button-danger mr-2"
                tooltip="Exportar para PDF / Imprimir"
                tooltipOptions={{ position: 'left' }}
                disabled={loading}
              />
            </div>
          </div>

          <Tooltip target=".export-buttons>button" position="bottom" />

          <Panel>
            <h3>Resumo de percentuais de hora trabalhadas (processos e assessoria)</h3>
            <br />
            <table id="resumo-horas" width="350">
              <tbody>
                <tr>
                  <td width="300"><strong>Total de horas trabalhadas:</strong></td>
                  <td width="50" align="right">{totalTime}</td>
                </tr>
                <tr>
                  <td width="300"><strong>Total de horas processos:</strong></td>
                  <td width="50" align="right">{totalLawsuitTime}</td>
                </tr>
                <tr>
                  <td width="300"><strong>Total de horas assessoria:</strong></td>
                  <td width="50" align="right">{totalConsultancyTime}</td>
                </tr>
                <tr>
                  <td width="300"><strong>Total de horas processos sem atribuição:</strong></td>
                  <td width="50" align="right">{totalNonDefinedLawsuitTime}</td>
                </tr>
                <tr>
                  <td width="300"><strong>Saldo de horas sem atribuição</strong></td>
                  <td width="50" align="right">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </Panel>

          <DataTable
            tableClassName="table-groupedhourspercentage table"
            id="table-groupedhourspercentage-all"
            ref={dt}
            loading={loading}
            value={sectorHours}
            editMode="row"
            dataKey="setor"
            header={(
              <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">
                  Setores com horas e percentuais (processos e assessorias)
                </span>
              </div>
              )}
            stripedRows
            className="p-datatable-sm table-pendinghours"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={sectorHours.length}
            rowsPerPageOptions={[10, 20, 50, sectorHours.length]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            // globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            footerColumnGroup={footerGroup}
          >
            <Column key="row_number" field="row_number" header="Núm." alignHeader="center" body={counterBodyTemplate} align="center" style={{ width: '50px' }} />
            <Column key="sector" field="sector" header="Setor" alignHeader="center" align="left" style={{ width: '150px' }} />
            <Column field="total_hours" header="Total (horas setor)" alignHeader="center" align="right" style={{ width: '150px' }} />
            <Column field="percentage" header="Percentual" alignHeader="center" align="right" style={{ width: '80px' }} />

          </DataTable>

          <h3>Assessorias com horas e percentuais agrupadas por setor</h3>

          <DataTable
            tableClassName="table-groupedhourspercentage table"
            id="table-groupedhourspercentage-consultancy"
            ref={dt}
            loading={loading}
            value={sectorConsultancyHours}
            editMode="row"
            dataKey="setor"
            header={(
              <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">
                  Setores com horas e percentuais (somente assessorias)
                </span>
              </div>
              )}
            stripedRows
            className="p-datatable-sm table-pendinghours"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={sectorHours.length}
            rowsPerPageOptions={[10, 20, 50, sectorHours.length]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            // globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            footerColumnGroup={footerGroupConsultancy}
          >
            <Column key="row_number" field="row_number" header="Núm." alignHeader="center" body={counterBodyTemplate} align="center" style={{ width: '50px' }} />
            <Column key="sector" field="sector" header="Setor" alignHeader="center" align="left" style={{ width: '150px' }} />
            <Column field="totalConsultancyDuration" header="Total (horas)" alignHeader="center" align="right" style={{ width: '150px' }} />
            <Column field="totalConsultancyDurationDecimals" header="Total (decimais)" alignHeader="center" align="right" style={{ width: '150px' }} />
            <Column field="totalConsultancyDurationPercentage" header="Percentual" alignHeader="center" align="right" style={{ width: '80px' }} />
          </DataTable>

          {/* <Toolbar key={nanoid()} className="p-mb-4"
                left={leftToolbarTemplate} right={rightToolbarTemplate} /> */}

          <h3>Processos com horas e percentuais agrupadas por setor</h3>

          <Tooltip key={nanoid()} target=".export-buttons>button" position="bottom" />
          {sectorHours.map((item) => (
            <>
              {item.lawsuitArray && item.lawsuitArray.length > 0 ? (
                <>
                  <DataTable
                    tableClassName="table-sectorhours table"
                    id={`table-sectorhours-${item.sector
                      .split(' ')
                      .join('-')
                      .toLowerCase()}`}
                    ref={dt}
                    header={(
                      <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <span className="text-xl text-900 font-bold">
                          Horas setor:
                          {item.sector}
                          {' '}
                          (processos)
                        </span>
                      </div>
                    )}
                    loading={loading}
                    value={item.lawsuitArray}
                    dataKey={item.sector}
                    stripedRows
                    className="p-datatable-sm table-pendinghours"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
                    rows={item.lawsuitArray?.length}
                    rowsPerPageOptions={[10, 20, 50, item.lawsuitArray?.length]}
                    // globalFilter={globalFilter}
                    emptyMessage="Nenhum registro encontrado."
                    footerColumnGroup={(
                      <ColumnGroup>
                        <Row>
                          <Column footer="Total de horas:" colSpan={1} footerStyle={{ textAlign: 'right' }} />
                          <Column footer={item.total_hours} colSpan={1} footerStyle={{ textAlign: 'right' }} />
                          <Column footer={item.total_hours_decimals} colSpan={1} footerStyle={{ textAlign: 'right' }} />
                          <Column colSpan={1} />
                        </Row>
                      </ColumnGroup>
)}
                  >
                    <Column key="lawsuitNumber" field="lawsuitNumber" header="Número processo" alignHeader="center" align="left" style={{ width: '200px' }} />
                    <Column field="totalLawsuitDuration" header="Horas (soma)" alignHeader="right" align="right" style={{ width: '50px' }} />
                    <Column field="totalLawsuitDurationDecimals" header="Horas (decimal)" alignHeader="right" align="right" style={{ width: '50px' }} />
                    <Column field="totalLawsuitDurationPercentage" header="Hora (percentual)" alignHeader="right" align="right" style={{ width: '50px' }} />
                  </DataTable>
                </>
              ) : null }
            </>
          ))}
        </div>
      </div>

    </Container>
  );
};

export default HourPercentageByCustomerReport;
