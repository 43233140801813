import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
/*
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
*/

import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

 interface IAdministratorProperties {
  administrador_id:number | null;
  administrador_nome: string;
  administrador_login: string;
  administrador_senha: string;
  administrador_email: string;
  administrador_categoria: number;
  administrador_ativo: boolean;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adm: number;
  valor_adm: string;
  administrador_setor: string;
}

const Administrator: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyAdministrator = {
    administrador_id: null,
    administrador_nome: '',
    administrador_login: '',
    administrador_senha: '',
    administrador_email: '',
    administrador_categoria: 0,
    administrador_ativo: true,
    permissao_relatoriov: 0,
    permissao_relatorioiv: 0,
    sta_new_senha: false,
    cat_adm: 0,
    valor_adm: '',
    administrador_setor: '',
  };

  const [administrators, setAdministrators] = useState<IAdministratorProperties[]>([]);
  const [administrator, setAdministrator] = useState(emptyAdministrator);
  const [deleteAdministratorDialog, setDeleteAdministratorDialog] = useState(false);
  const [deleteAdministratorsDialog, setDeleteAdministratorsDialog] = useState(false);
  const [
    selectedAdministrators, setSelectedAdministrators,
  ] = useState<IAdministratorProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await api.get('/management/administrators/show/all');
      setAdministrators(response.data);
    })();
  }, []);

  const columns = [
    { field: 'administrador_id', header: 'Código', sortable: true },
    { field: 'administrador_nome', header: 'Nome', sortable: true },
    { field: 'administrador_login', header: 'Login', sortable: true },
    { field: 'administrador_email', header: 'E-mail', sortable: true },
    { field: 'permissao_relatorioiv', header: 'Relatório IV', sortable: true },
    { field: 'permissao_relatoriov', header: 'Relatório V', sortable: true },
  ];

  const reportIVBodyTemplate = (rowData: { permissao_relatorioiv: number; }) => {
    if (administrators !== undefined) {
      const filteredReport = administrators.filter((item) => (
        item.permissao_relatorioiv === rowData.permissao_relatorioiv
      ), []);

      if (filteredReport[0]?.permissao_relatorioiv === 1) {
        return (
          <Button
            type="button"
            icon="pi pi-check-square"
            className="p-button-rounded p-button-text p-button-plain p-button-success"
            tooltip="Visualização permitida"
            tooltipOptions={{ position: 'left' }}
          />
        );
      }
      return (
        <Button
          type="button"
          icon="pi pi-ban"
          className="p-button-rounded p-button-text p-button-plain p-button-danger"
          tooltip="Visualização não permitida"
          tooltipOptions={{ position: 'left' }}
        />
      );
    }
    return 0;
  };

  const reportVBodyTemplate = (rowData: { permissao_relatoriov: number; }) => {
    if (administrators !== undefined) {
      const filteredReport = administrators.filter((item) => (
        item.permissao_relatoriov === rowData.permissao_relatoriov
      ), []);

      if (filteredReport[0]?.permissao_relatoriov === 1) {
        return (
          <Button
            type="button"
            icon="pi pi-check-square"
            className="p-button-rounded p-button-text p-button-plain p-button-success"
            tooltip="Visualização permitida"
            tooltipOptions={{ position: 'left' }}
          />
        );
      }
      return (
        <Button
          type="button"
          icon="pi pi-ban"
          className="p-button-rounded p-button-text p-button-plain p-button-danger"
          tooltip="Visualização não permitida"
          tooltipOptions={{ position: 'left' }}
        />
      );
    }
    return 0;
  };

  const dynamicColumns = columns.map((col) => {
    if (col.field === 'permissao_relatorioiv') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={reportVBodyTemplate}
          sortable={false}
          headerStyle={{ textAlign: 'center', width: '105px' }}
          style={{ textAlign: 'center' }}
        />
      );
    }
    if (col.field === 'permissao_relatoriov') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={reportIVBodyTemplate}
          sortable={false}
          headerStyle={{ textAlign: 'center', width: '105px' }}
          style={{ textAlign: 'center' }}
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
      />
    );
  });

  const handleCreate = () => {
    history.push('/management/administrator/create');
  };

  const hidedeleteAdministratorDialog = () => {
    setDeleteAdministratorDialog(false);
  };

  const hidedeleteAdministratorsDialog = () => {
    setDeleteAdministratorsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editAdministrator = (rowData:any) => {
    history.push(`/management/administrator/edit/${rowData.administrador_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteAdministrator = (administrator: React.SetStateAction<{
    administrador_id: null; administrador_nome: string; administrador_login: string;
    administrador_senha: string; administrador_email: string; administrador_categoria: number;
    administrador_ativo: boolean; permissao_relatoriov: number; permissao_relatorioiv: number;
    sta_new_senha: boolean; cat_adm: number; valor_adm: string; administrador_setor: string;
  }>) => {
    setAdministrator(administrator);
    setDeleteAdministratorDialog(true);
  };

  const deleteAdministrator = () => {
    api.delete(`management/administrators/delete/${administrator.administrador_id}`);

    setDeleteAdministratorDialog(false);

    const updatedRecords = administrators
      .filter((val) => val.administrador_id !== administrator.administrador_id);
    setAdministrators(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const changeVisibilityAdministrator = async (rowData: {
    administrador_id: number; administrador_ativo: boolean;
    administrador_email: string; administrador_login: string;
  }) => {
    await api.patch(`management/administrators/patch/${rowData.administrador_id}/${rowData.administrador_ativo}`);
    await api.get('/management/administrators/show/all').then((response) => {
      setAdministrators(response.data);
    });
    const formData = {
      active: rowData.administrador_ativo,
      login_name: rowData.administrador_login,
    };
    await api.patch('/users/patch/access', formData);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editAdministrator(rowData); }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { confirmDeleteAdministrator(rowData); }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
      />
      <Button
        type="button"
        icon={rowData.administrador_ativo === true ? 'pi pi-eye' : 'pi pi-eye-slash'}
        className={rowData.administrador_ativo === true ? 'p-button-rounded p-button-text p-button-plain p-button-success' : 'p-button-rounded p-button-text p-button-plain p-button-danger'}
        onClick={() => { changeVisibilityAdministrator(rowData); }}
        tooltip={`Tornar cadastro administrador ${rowData.administrador_ativo === true ? 'inativo' : 'ativo'} no sistema`}
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  // const handleCreateUsers = () => {
  //   (async () => {
  //     await api.get('/management/administrators/show/all').then((response) => {
  //       response.data.forEach((item: {
  //         administrador_email: string; administrador_nome: string;
  //         administrador_senha: string; administrador_login: string;
  //       }) => {
  //         // if (item.administrador_email) {
  //         const userFormData = {
  //           name: item.administrador_nome,
  //           login_name: item.administrador_login,
  //           password: '12345678', // item.administrador_senha,
  //           email: item.administrador_email.includes('@') ? item.administrador_email
  //           : `${item.administrador_login}@naoexiste.email`,
  //           active: true,
  //           user_level: 1,
  //         };

  //         api.post('/users', userFormData);
  //         // }
  //       });
  //     });
  //   })();
  //   addToast({
  //     type: 'success',
  //     title: 'Sincronização concluída!',
  //     description:
  //       'Importação foi realizada com sucesso!',
  //   });
  // };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      {/* <Button
        type="button"
        label="Importar acessos para JN 2"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreateUsers}
        tooltip="Importa somente cadastros com e-mails"
        tooltipOptions={{ position: 'right' }}
      /> */}
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteAdministratorDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteAdministratorDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteAdministrator} />
    </>
  );
  const deleteAdministratorsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteAdministratorsDialog} />
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Administradores</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-customers table"
            id="table-customers"
            ref={dt}
            value={administrators}
            editMode="row"
            dataKey="administrador_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedAdministrators}
            onSelectionChange={(e) => {
              const selAdministrators = e.value as IAdministratorProperties[];
              setSelectedAdministrators(selAdministrators);
            }}
            onRowDoubleClick={(e) => editAdministrator(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '150px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteAdministratorDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAdministratorDialogFooter} onHide={hidedeleteAdministratorDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {administrator && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{administrator.administrador_nome}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteAdministratorsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAdministratorsDialogFooter} onHide={hidedeleteAdministratorsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {administrator
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default Administrator;
