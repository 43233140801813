import React, {
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

import moment from 'moment';
// import robotoRegular from '../../../../utils/fonts/roboto-regular-normal-jspdf';
import ReactDOM from 'react-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../services/api';

// import CompanyLogo from '../../../../assets/logo.png';

interface ICustomers {
  // row_number: number;
  horas_documento: string;
  horas_documento_seq: number;
  horas_cod_cliente: number;
  cliente_nome: string;
  horas_referencia_nome: string;
  horas_data: string;
  horas_fatu_de: string;
  horas_fatu_ate: string;
}

type dataOptions = {
  [key: string]: string;
}

const HourBilledReport: React.FC = () => {
  PrimeReact.ripple = true;

  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let initialSearchDate = '';
  let finalSearchDate = '';
  if (state) {
    initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format('DD/MM/YYYY');
    finalSearchDate = moment(state?.proc_data_ajuiz_final).format('DD/MM/YYYY');
  }

  const [groupedHours, setGroupedHours] = useState<ICustomers[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/reports/hour/billed', state).then((response) => {
          const customers: {
            horas_documento: string; horas_documento_seq: number; horas_cod_cliente: number;
            cliente_nome: string; horas_referencia_nome: string; horas_data: string;
            horas_fatu_de: string; horas_fatu_ate: string;
          }[] = [];

          response.data.forEach(async (data: ICustomers) => {
            const initialPeriod = moment(data.horas_fatu_de).utc().format('DD/MM/YYYY');
            const finalPeriod = moment(data.horas_fatu_ate).utc().format('DD/MM/YYYY');
            const reportPeriod = `${initialPeriod} a ${finalPeriod}`;
            customers.push({
              horas_documento: data.horas_documento,
              horas_documento_seq: data.horas_documento_seq,
              horas_cod_cliente: data.horas_cod_cliente,
              cliente_nome: data.cliente_nome,
              horas_referencia_nome: data.horas_referencia_nome,
              horas_data: reportPeriod,
              horas_fatu_de: data.horas_fatu_de,
              horas_fatu_ate: data.horas_fatu_ate,
            });
          });

          ReactDOM.unstable_batchedUpdates(() => {
            setGroupedHours(customers);
            setLoading(false);
          });
        });
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, state]);

  const editHour = (rowData: { horas_cod_cliente: number; horas_documento: string; }) => {
    const searchFormData = {
      proc_cod_cliente: rowData.horas_cod_cliente,
      sector: state.sector,
      inCharge: state.inCharge,
      referenceId: state.referenceId,
      description: state.description,
      billedHour: state.billedHour,
      proc_tipo: state.proc_tipo,
      proc_data_ajuiz_inicio: state.proc_data_ajuiz_inicio,
      proc_data_ajuiz_final: state.proc_data_ajuiz_final,
      menu: state.menu,
      doc: rowData.horas_documento,
    };

    history.push('/report/hour/billed/export', searchFormData);
  };

  const openSelectedPeriodBilledHours = (rowData: DataTableValue) => {
    /*
        {
      horas_cod_cliente: number; horas_documento: string;
      cliente_nome: string;
    }
        */
    const searchFormData = {
      proc_cod_cliente: rowData.horas_cod_cliente,
      sector: state.sector,
      inCharge: state.inCharge,
      referenceId: state.referenceId,
      description: state.description,
      billedHour: state.billedHour,
      proc_tipo: state.proc_tipo,
      proc_data_ajuiz_inicio: state.proc_data_ajuiz_inicio,
      proc_data_ajuiz_final: state.proc_data_ajuiz_final,
      menu: state.menu,
      doc: rowData.horas_documento,
      // cliente_nome: rowData.cliente_nome,
    };
    history.push('/report/hour/billed/export', searchFormData);
  };

  const cancelInvoice = async (rowData: {
    horas_documento_seq: string;
  }) => {
    await api.put(`/management/workinghours/document/invoice/cancel/${rowData.horas_documento_seq}`).then((response) => {
      if (response.data === true) {
        const updatedRecords = groupedHours
          .filter((val) => val.horas_documento_seq !== Number(rowData.horas_documento_seq));
        setGroupedHours(updatedRecords);

        addToast({
          type: 'success',
          title: 'Fatura cancelada',
          description:
            'A fatura selecionada foi cancelada com sucesso.',
        });
        toast.current?.show({
          severity: 'success',
          summary: 'Fatura cancelada',
          detail: 'A fatura selecionada foi cancelada com sucesso.',
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Fatura não cancelada',
          detail: 'Ocorreu um erro ao cancelar esta fatura.',
          life: 3000,
        });
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { editHour(rowData); }}
        tooltip="Gerenciar horas"
        tooltipOptions={{ position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-undo"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { cancelInvoice(rowData); }}
        tooltip="Cancelar horas faturadas"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-file"
        className="p-button-rounded p-button-text p-button-plain p-button-info"
        onClick={() => { openSelectedPeriodBilledHours(rowData); }}
        tooltip="Exportar relatório (Visualizar)"
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  const exportExcel = () => {
    import('excel4node').then((xlsx) => {
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('Horas Faturadas');

      const columnsHeaders = [
        'Índice.',
        'Num.',
        'Cliente',
      ];

      const columnsContent: dataOptions[] = [{}];
      let index = 1;
      groupedHours.forEach((item) => {
        columnsContent.push({
          indice: index.toString(),
          num: item.horas_documento,
          cliente: item.cliente_nome,
        });
        index++;
      });

      worksheet.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet.cell(2, 1).string('Relatório de Horas Agrupadas por Cliente');
      worksheet.cell(3, 1).string(`Período: ${initialSearchDate} até ${finalSearchDate}`);

      let headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet.cell(5, headerColumIndex++).string(heading);
      });

      let rowIndex = 5;
      columnsContent.forEach((item) => {
        let columnIndex = 1;
        Object.keys(item).forEach((columnName) => {
          worksheet.cell(rowIndex, columnIndex++).string(item[columnName]);
        });
        rowIndex++;
      });

      const formattedIniDate = initialSearchDate.replaceAll('/', '-');
      const formattedFinalDate = finalSearchDate.replaceAll('/', '-');
      const filename = `Relatorio-de-horas-faturadas-${formattedIniDate}a${formattedFinalDate}.xlxs`;

      workbook.writeToBuffer().then((buffer: BlobPart) => {
        import('file-saver').then((module) => {
          if (module && module.default) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
              type: EXCEL_TYPE,
            });

            module.default.saveAs(data,
              `${filename}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
          }
        });
      });
    });
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        tooltip="Exportar para Excel"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column colSpan={7} />
      </Row>
    </ColumnGroup>
  );

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <header>
        <div>
          <h3>Relatório de horas faturadas - Modelo VI</h3>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <div className="datatable-responsive">
            <Tooltip target=".export-buttons>button" position="bottom" />

            <DataTable
              tableClassName="table-groupedhours table"
              id="table-groupedhours"
              ref={dt}
              loading={loading}
              value={groupedHours}
              editMode="row"
              dataKey="horas_documento"
              header={header}
              stripedRows
              className="p-datatable-sm table-pendinghours"
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
              rows={groupedHours.length}
              rowsPerPageOptions={[10, 20, 50, groupedHours.length]}
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              responsiveLayout="scroll"
              footerColumnGroup={footerGroup}
              onRowDoubleClick={(e) => openSelectedPeriodBilledHours(e.data)}
            >
              <Column key="row_number" field="row_number" header="Núm." alignHeader="center" body={counterBodyTemplate} align="center" style={{ width: '50px' }} />
              <Column key="horas_documento" field="horas_documento" header="Documento" alignHeader="center" align="left" style={{ width: '100px' }} />
              <Column key="horas_data" field="horas_data" header="Período Selecionado" alignHeader="center" align="left" style={{ width: '200px' }} />
              <Column field="cliente_nome" header="Cliente" alignHeader="center" align="left" />
              <Column field="horas_referencia_nome" header="Referência" alignHeader="center" align="left" style={{ width: '120px' }} />

              <Column body={actionBodyTemplate} headerStyle={{ width: '200px' }} />
            </DataTable>
          </div>
        </div>
      </div>

    </Container>
  );
};

export default HourBilledReport;
