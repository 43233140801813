import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { Container } from './styles';
import api from '../../../services/api';

interface IScheduleProperties {
  id: number | null;
  schedule_room_id: number;
  attendees: string;
  schedule_subject: string;
  schedule_description: string;
  schedule_date: Date;
  schedule_start: Date | undefined;
  schedule_end: Date | undefined;
  schedule_status_id: number;
  schedule_priority_id: number;
  schedule_type_id: number;
}

interface IScheduleProgress {
  id: number | null;
  schedule_id: number;
  sp_date: Date;
  sp_description: string;
  user_id: string;
}

const ScheduleProgress: React.FC = () => {
  PrimeReact.ripple = true;

  const { user } = useAuth();

  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const id = params.get('cod');

  const emptyScheduleProgress = {
    id: null,
    schedule_id: Number(id),
    sp_date: new Date(),
    sp_description: '',
    user_id: user.id,
  };

  const [
    scheduleProgresses,
    setScheduleProgresses,
  ] = useState<IScheduleProgress[]>([]);
  const [scheduleProgress, setScheduleProgress] = useState(emptyScheduleProgress);

  const [deleteLinkedLawsuitDialog, setDeleteLinkedLawsuitDialog] = useState(false);
  const [deleteLinkedLawsuitsDialog, setDeleteLinkedLawsuitsDialog] = useState(false);
  const [
    selectedScheduleProgresses, setSelectedScheduleProgresses,
  ] = useState<IScheduleProgress[]>([]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/schedules/progresses/show/filtered/${id}`);
      setScheduleProgresses(response.data);
    })();
  }, [id]);

  const dateBodyTemplate = (rowData: { sp_date: Date; }) => {
    if (scheduleProgresses !== undefined) {
      const filteredDate = scheduleProgresses.filter((item) => (
        item.sp_date === rowData.sp_date
      ), []);

      const dateInput = filteredDate[0].sp_date?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }

    return '';
  };

  const handleCreate = () => {
    history.push(`/schedule/progress/create?cod=${id}`);
  };

  const hidedeleteLinkedLawsuitDialog = () => {
    setDeleteLinkedLawsuitDialog(false);
  };

  const hidedeleteLinkedLawsuitsDialog = () => {
    setDeleteLinkedLawsuitsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editScheduleProgress = (rowData:any) => {
    history.push(`/schedule/progress/edit/${rowData.id}?cod=${id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteScheduleProgress = (scheduleProgress:
    React.SetStateAction<{ id: null; schedule_id: number; sp_date: Date;
      sp_description: string; user_id: string;
    }>) => {
    setScheduleProgress(scheduleProgress);
    setDeleteLinkedLawsuitDialog(true);
  };

  const deleteSelectedScheduleProgress = () => {
    api.delete(`/schedules/progresses/delete/${scheduleProgress.id}`);

    setDeleteLinkedLawsuitDialog(false);

    const updatedRecords = scheduleProgresses
      .filter((val) => val.id !== scheduleProgress.id);
    setScheduleProgresses(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editScheduleProgress(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteScheduleProgress(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteScheduleProgressFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLinkedLawsuitDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedScheduleProgress} />
    </>
  );
  const deleteLinkedLawsuitsProgressDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLinkedLawsuitsDialog} />
    </>
  );

  const handleCancel = () => {
    history.push('/schedule/myschedule');
  };

  const [schedule, setSchedule] = useState<IScheduleProperties | null>(null);
  const [timeStart, setTimeStart] = useState<Date | Date[] | undefined>(undefined);
  const [timeEnd, setTimeEnd] = useState<Date | Date[] | undefined>(undefined);
  const [meetingRoom, setMeetingRoom] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect(() => {
    (async () => {
      try {
        await api.get(`/schedules/show/${id}`).then((response) => {
          setSchedule(response.data);

          if (response.data.schedule_start !== undefined
          && response.data.schedule_start !== null) {
            const start = response.data.schedule_start.slice(11, 16);
            setTimeStart(start);
          }
          if (response.data.schedule_end !== undefined
          && response.data.schedule_end !== null) {
            const end = response.data.schedule_end.slice(11, 16);
            setTimeEnd(end);
          }
          if (response.data.meeting_room_id > 0) {
            api.get(`/schedules/meetings/rooms/show/${response.data.meeting_room_id}`).then((responseMeeting) => {
              setMeetingRoom(responseMeeting.data.room_description);
            });
          }
        });
      } catch (e) {
        if (typeof e === 'string') {
          e.toUpperCase();
        } else if (e instanceof Error) {
          addToast({
            type: 'error',
            title: 'Ocorreu um problema',
            description:
            `Não foi possível carregar as informações do cadastro selecionado.
            Erro: ${e.message}`,
          });
        }
      }
    })();
  }, [addToast, id]);

  return (
    <Container>
      <div className="card">
        <div className="flex right field button-return">
          <Button
            icon="pi pi-times"
            iconPos="left"
            label="Retornar listagem da agenda"
            className="p-button-info"
            type="button"
            onClick={handleCancel}
            style={{ width: '400px' }}
          />
        </div>
        <header>
          <h4>
            Tipo do agendamento:
            { ' ' }
            {schedule?.schedule_type_id === 1 ? 'Atividade' : 'Reunião'}
            { ' ' }
            {schedule?.schedule_type_id === 1
              ? <i className="pi pi-list" />
              : <i className="pi pi-users" />}
          </h4>
        </header>
        <div className="fluid formgrid grid">

          <div className="col-12">
            <div className="field grid">
              <div className="col-12 md:col-12">
                <table width="100%" id="schedule_summary" cellPadding={0} cellSpacing={0}>
                  <tbody>
                    <tr>
                      <td width="200" style={{ padding: '5px' }}>
                        <i className="pi pi-calendar" />
                        { ' ' }
                        <strong>Data acontecimento:</strong>
                      </td>
                      <td width="120" style={{ padding: '5px' }}>
                        {schedule?.schedule_date
                          ? format(new Date(schedule?.schedule_date), 'dd/MM/yyyy')
                          : null}
                      </td>
                      <td width="140" style={{ padding: '5px' }}>
                        <i className="pi pi-tag" />
                        { ' ' }
                        <strong>Prioridade:</strong>
                      </td>
                      <td width="120" className="schedule-badge priority-normal" style={{ padding: '5px' }}>
                        {schedule?.schedule_priority_id === 1
                          ? (<><span className="schedule-badge priority-high">Alta</span></>)
                          : null}
                        {schedule?.schedule_priority_id === 3
                          ? (<><span className="schedule-badge priority-low">Baixa</span></>)
                          : null}
                      </td>
                    </tr>
                    {schedule?.schedule_type_id === 2 ? (
                      <>
                        <tr style={{ background: '#e2e2e2' }}>
                          <td width="140" style={{ padding: '5px' }}>
                            <i className="pi pi-clock" />
                            { ' ' }
                            <strong>Hora Início:</strong>
                          </td>
                          <td width="120" style={{ padding: '5px' }}>{timeStart}</td>
                          <td width="140" style={{ padding: '5px' }}>
                            <i className="pi pi-clock" />
                            { ' ' }
                            <strong>Hora término:</strong>
                          </td>
                          <td width="120" style={{ padding: '5px' }}>{timeEnd}</td>
                          <td width="140" style={{ padding: '5px' }}>
                            <i className="pi pi-map-marker" />
                            { ' ' }
                            <strong>Local reunião:</strong>
                          </td>
                          <td width="120" style={{ padding: '5px' }}>{meetingRoom}</td>
                        </tr>
                      </>
                    ) : null }
                    <tr style={{ background: schedule?.schedule_type_id === 1 ? '#e2e2e2' : '#ffffff' }}>
                      <td width="200" style={{ padding: '5px' }}>
                        <i className="pi pi-megaphone" />
                        { ' ' }
                        <strong>Assunto:</strong>
                      </td>
                      <td
                        colSpan={schedule?.schedule_type_id === 1 ? 5 : 1}
                        style={{ padding: '5px' }}
                      >
                        {schedule?.schedule_subject}
                      </td>
                    </tr>
                    <tr style={{ background: schedule?.schedule_type_id === 1 ? '#ffffff' : '#e2e2e2' }}>
                      <td style={{ padding: '5px' }}>
                        <i className="pi pi-comment" />
                        { ' ' }
                        <strong>Detalhamento:</strong>
                      </td>
                      <td colSpan={5} style={{ padding: '5px' }}>{schedule?.schedule_description}</td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <header>
          <div>
            <h3>
              Andamento da
              {schedule?.schedule_type_id === 1 ? ' atividade' : ' reunião'}
            </h3>
          </div>
        </header>

        <div className="datatable-responsive">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-linkedlawsuitsprogresses table"
            id="table-linkedlawsuitsprogresses"
            ref={dt}
            value={scheduleProgresses}
            editMode="row"
            dataKey="id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="single"
            selection={selectedScheduleProgresses}
            onSelectionChange={(e) => {
              const selScheduleProgresses = e.value as IScheduleProgress[];
              setSelectedScheduleProgresses(selScheduleProgresses);
            }}
            onRowDoubleClick={(e) => editScheduleProgress(e.data)}
          >
            <Column field="sp_date" header="Data" alignHeader="center" body={dateBodyTemplate} align="center" style={{ width: '120px' }} />
            <Column field="sp_description" header="Descrição" alignHeader="center" align="left" />
            <Column body={actionBodyTemplate} headerStyle={{ width: '160px' }} />
          </DataTable>
        </div>

        <div>
          <Dialog visible={deleteLinkedLawsuitDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteScheduleProgressFooter} onHide={hidedeleteLinkedLawsuitDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {scheduleProgress && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{scheduleProgress.sp_description}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteLinkedLawsuitsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLinkedLawsuitsProgressDialogFooter} onHide={hidedeleteLinkedLawsuitDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {scheduleProgress
                                && (
                                <span>
                                  Você tem certeza que deseja excluir os cadastros selecionados?
                                </span>
                                )}
            </div>
          </Dialog>
        </div>

        <div className="col-12 p-mt-20">
          <header />
          <div className="flex align-content-center justify-content-center field">
            <Button
              icon="pi pi-times"
              iconPos="left"
              label="Retornar listagem da agenda"
              className="p-button-info"
              type="button"
              onClick={handleCancel}
            />
          </div>
        </div>

      </div>

    </Container>

  );
};

export default ScheduleProgress;
