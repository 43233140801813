/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import {
  Container,
} from './styles';

interface GroupFormData {
  grupo_nome: string,
  grupo_valor: string,
}

const AddEditGroup:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const initialValues: GroupFormData = {
    grupo_nome: '',
    grupo_valor: '',
  };

  const validationSchema = Yup.object().shape({
    grupo_nome: Yup.string().required('Nome obrigatório'),
    grupo_valor: Yup.string().required('Valor obrigatório'),
  });

  const handleOnSubmit = async (
    values: GroupFormData,
    { setSubmitting }: FormikHelpers<GroupFormData>,
  ) => {
    if (isAddMode) {
      try {
        await api.post('/management/groups/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/management/group');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        await api.put(`/management/groups/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/management/group');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            grupo de advogados
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            // setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                if (!isAddMode) {
                  api.get(`management/groups/show/${id}`).then((response) => {
                    setValues(response.data);
                  });
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              }
            }, [setValues]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="grupo_nome">
                          Nome  *
                        </label>
                        <InputText
                          id="grupo_nome"
                          name="grupo_nome"
                          className={` ${errors.grupo_nome && touched.grupo_nome ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.grupo_nome}
                        />
                        <ErrorMessage name="grupo_nome">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-6 lg:col-6">
                        <label htmlFor="grupo_valor">
                          Valor por hora
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-money-bill" />
                          </span>
                          <InputText
                            id="grupo_valor"
                            name="grupo_valor"
                            className="inputfield w-full"
                            value={values.grupo_valor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditGroup;
