/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Link, useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface ILinkedLawsuit {
  proc_vinc_reg: string;
  proc_vinc_reg_outros: string;
  proc_vinc_principal_id: number;
  proc_vinc_num_pasta: string;
  proc_vinc_cod_tipo: number;
  proc_vinc_cod_orgao: number;
  proc_vinc_orgao_cidade: string;
  proc_vinc_orgao_uf: string;
  proc_vinc_url: string;
  proc_vinc_polo_ativo: string;
  proc_vinc_polo_passivo: string;
  proc_vinc_arquivado: boolean;
}

const AddEditLinkedLawsuit:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentLawsuit, setCurrentLawsuit] = useState(0);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');
  const activeTab = params.get('active');

  const initialValues: ILinkedLawsuit = {
    proc_vinc_reg: '',
    proc_vinc_reg_outros: '',
    proc_vinc_principal_id: Number(cod),
    proc_vinc_num_pasta: '',
    proc_vinc_cod_tipo: 0,
    proc_vinc_cod_orgao: 0,
    proc_vinc_orgao_cidade: '',
    proc_vinc_orgao_uf: '',
    proc_vinc_url: '',
    proc_vinc_polo_ativo: '',
    proc_vinc_polo_passivo: '',
    proc_vinc_arquivado: false,
  };

  // control tab changes when leaving/returning to page
  useEffect(() => {
    if (activeTab !== undefined || activeTab !== null) {
      setActiveIndex(Number(activeTab));
    }
    if (cod !== undefined || cod !== null) {
      setCurrentLawsuit(Number(cod));
    }
  }, [activeTab, cod]);

  const [linkedLawsuit, setLinkedLawsuit] = useState(null);

  const returnLawsuitURL = `/lawsuit/main/edit/${currentLawsuit}?active=${activeIndex}`;

  const validationSchema = Yup.object().shape({
    proc_vinc_reg: Yup.string().required('Número do processo vinculado obrigatório'),
    proc_vinc_cod_tipo: Yup.number().min(1, 'Selecione o processo vinculado').required('Selecione o processo vinculado'),
  });

  const brazilianStatesOptions = [
    { name: 'AC', value: 'AC' },
    { name: 'AL', value: 'AL' },
    { name: 'AM', value: 'AM' },
    { name: 'AP', value: 'AP' },
    { name: 'BA', value: 'BA' },
    { name: 'CE', value: 'CE' },
    { name: 'DF', value: 'DF' },
    { name: 'ES', value: 'ES' },
    { name: 'GO', value: 'GO' },
    { name: 'MA', value: 'MA' },
    { name: 'MG', value: 'MG' },
    { name: 'MS', value: 'MS' },
    { name: 'MT', value: 'MT' },
    { name: 'PA', value: 'PA' },
    { name: 'PB', value: 'PB' },
    { name: 'PE', value: 'PE' },
    { name: 'PI', value: 'PI' },
    { name: 'PR', value: 'PR' },
    { name: 'RJ', value: 'RJ' },
    { name: 'RN', value: 'RN' },
    { name: 'RO', value: 'RO' },
    { name: 'RR', value: 'RR' },
    { name: 'RS', value: 'RS' },
    { name: 'SC', value: 'SC' },
    { name: 'SE', value: 'SE' },
    { name: 'SP', value: 'SP' },
    { name: 'TO', value: 'TO' },
  ];

  const [judgingBodiesOptions, setJudgingBodiesOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/judgingbodies/show/all').then((response) => {
        response.data.map((judgingBody: { orgao_id: number, orgao_descricao:string}) => (
          judgingBodiesOptions.push({
            name: judgingBody.orgao_descricao,
            value: judgingBody.orgao_id,
          })
        ));
      });
      judgingBodiesOptions.splice(0, 1);
      setJudgingBodiesOptions(judgingBodiesOptions);
    })();
  }, [judgingBodiesOptions]);

  const [linkedLawsuitsOptions, setLinkedLawsuitsOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get('/lawsuits/linkedlawsuitstypes/show/all').then((response) => {
        response.data.map((linkedLawsuitType: {
          proc_vinc_tipo_id: number, proc_vinc_tipo_descricao:string}) => (
          linkedLawsuitsOptions.push({
            name: linkedLawsuitType.proc_vinc_tipo_descricao,
            value: linkedLawsuitType.proc_vinc_tipo_id,
          })
        ));
        linkedLawsuitsOptions.splice(0, 1);
        setLinkedLawsuitsOptions(linkedLawsuitsOptions);
      });
    })();
  }, [linkedLawsuitsOptions]);

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  const handleOnSubmit = async (
    values: ILinkedLawsuit,
    { setSubmitting }: FormikHelpers<ILinkedLawsuit>,
  ) => {
    if (isAddMode) {
      try {
        await api.post('/lawsuits/linkedlawsuits/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        await api.put(`/lawsuits/linkedlawsuits/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!isAddMode) {
          await api.get(`/lawsuits/linkedlawsuits/show/${id}`).then((response) => {
            setLinkedLawsuit(response.data);
          });
        }
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Não foi possível carregar as informações do cadastro selecionado.',
        });
      }
    })();
  }, [addToast, id, isAddMode]);

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            processo vinculado
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={linkedLawsuit || initialValues}
        >
          {({
            values,
            // touched,
            // errors,
            dirty,
            isValid,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            // setFieldValue,
            // setValues,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="proc_vinc_reg">
                          Processo vinculado nº *
                        </label>
                        <InputText
                          id="proc_vinc_reg"
                          name="proc_vinc_reg"
                          className="inputfield w-full"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.proc_vinc_reg}
                        />
                        <ErrorMessage name="proc_vinc_reg">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="proc_vinc_num_pasta">
                          Pasta nº
                        </label>
                        <InputText
                          id="proc_vinc_num_pasta"
                          name="proc_vinc_num_pasta"
                          className="inputfield w-full"
                          onChange={handleChange}
                          value={values.proc_vinc_num_pasta}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_vinc_reg_outros">
                          Outros números do processo vinculado
                        </label>
                        <InputTextarea
                          id="proc_vinc_reg_outros"
                          name="proc_vinc_reg_outros"
                          className="inputfield w-full"
                          value={values.proc_vinc_reg_outros}
                          onChange={handleChange}
                          rows={5}
                          cols={30}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-3">
                            <label htmlFor="proc_vinc_cod_tipo">
                              Tipo de processo vinculado *
                            </label>
                            <Dropdown
                              id="proc_vinc_cod_tipo"
                              name="proc_vinc_cod_tipo"
                              value={values.proc_vinc_cod_tipo}
                              options={linkedLawsuitsOptions}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="inputfield w-full"
                              optionLabel="name"
                              optionValue="value"
                              filter
                              filterBy="name"
                              placeholder="Selecione uma opção"
                              emptyFilterMessage="Nenhum registro encontrado"
                            />
                            <ErrorMessage name="proc_vinc_cod_tipo">
                              { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                            </ErrorMessage>
                          </div>
                          <div className="col-12 md:col-3">
                            <label htmlFor="proc_vinc_cod_orgao">
                              Órgão julgador
                            </label>
                            <Dropdown
                              id="proc_vinc_cod_orgao"
                              name="proc_vinc_cod_orgao"
                              value={values.proc_vinc_cod_orgao}
                              options={judgingBodiesOptions}
                              onChange={handleChange}
                              className="inputfield w-full"
                              optionLabel="name"
                              optionValue="value"
                              filter
                              filterBy="name"
                              placeholder="Selecione uma opção"
                              emptyFilterMessage="Nenhum registro encontrado"
                            />
                          </div>
                          <div className="col-12 md:col-3">
                            <label htmlFor="proc_vinc_orgao_cidade">
                              Cidade
                            </label>
                            <InputText
                              id="proc_vinc_orgao_cidade"
                              name="proc_vinc_orgao_cidade"
                              className="inputfield w-full"
                              onChange={handleChange}
                              value={values.proc_vinc_orgao_cidade}
                            />
                          </div>
                          <div className="col-12 md:col-3">
                            <label htmlFor="proc_vinc_orgao_uf">
                              Estado
                            </label>
                            <Dropdown
                              id="proc_vinc_orgao_uf"
                              name="proc_vinc_orgao_uf"
                              value={values.proc_vinc_orgao_uf}
                              options={brazilianStatesOptions}
                              onChange={handleChange}
                              className="inputfield w-full"
                              optionLabel="name"
                              optionValue="value"
                              filter
                              filterBy="name"
                              placeholder="Selecione uma opção"
                              emptyFilterMessage="Nenhum registro encontrado"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="field grid">
                          <div className="col-12 md:col-6">
                            <label htmlFor="proc_vinc_polo_ativo">
                              Pólo ativo
                            </label>
                            <InputText
                              id="proc_vinc_polo_ativo"
                              name="proc_vinc_polo_ativo"
                              className="inputfield w-full"
                              onChange={handleChange}
                              value={values.proc_vinc_polo_ativo}
                            />
                          </div>

                          <div className="col-12 md:col-6">
                            <label htmlFor="proc_vinc_polo_passivo">
                              Pólo passivo
                            </label>
                            <InputText
                              id="proc_vinc_polo_passivo"
                              name="proc_vinc_polo_passivo"
                              className="inputfield w-full"
                              onChange={handleChange}
                              value={values.proc_vinc_polo_passivo}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-6 lg:col-12">
                          <label htmlFor="proc_vinc_url">
                            Localização na internet (URL)
                          </label>
                          <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">www</span>
                              <InputText
                                id="proc_vinc_url"
                                name="proc_vinc_url"
                                className="inputfield w-full"
                                placeholder="https://www."
                                value={values.proc_vinc_url}
                                onChange={handleChange}
                              />
                              <Link
                                to={{ pathname: `${values.proc_vinc_url}` }}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                                title="Acessar o site"
                              >
                                <span className="p-inputgroup-addon">
                                      &nbsp;
                                  <i className="pi pi-external-link" />
                                      &nbsp;
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={handleCancel}
                      />

                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditLinkedLawsuit;
