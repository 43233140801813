import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import {
  // useHistory,
  useLocation,
} from 'react-router-dom';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

import CompanyLogo from '../../../assets/logo.png';

 interface ILawsuitProperties {
  proc_id: number | null;
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
  cliente_nome: string;
  proc_valor_causa: number;
  parent_company_id: number;
  children_companies: string;
  report_columns: string;
}

interface ColumnMeta {
  field: string;
  header: string;
  align: 'left' | 'right' | 'center';
  body?: string;
  key: string;
}

interface INatureSuitProperties {
  natureza_id: number | null;
  natureza_descricao: string;
}

interface INatureLawsuitsCombinedProperties {
  nature_suit: INatureSuitProperties;
  lawsuits: ILawsuitProperties[];
}

interface IBusinessGroup{
  customerName: string,
  customerId: number,
  natureLawsuitCombined: INatureLawsuitsCombinedProperties[],
}

const LawsuitSummaryReport: React.FC = () => {
  PrimeReact.ripple = true;

  // const [groupedBusinessGroup, setGroupedBusinessGroup] = useState<IBusinessGroup>([]);
  const [lawsuits, setLawsuits] = useState<ILawsuitProperties[]>([]);
  // const [lawsuits2, setLawsuits2] = useState([]);
  const [loading, setLoading] = useState(true);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  // const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let business_group_id = 0;
  if (state) {
    business_group_id = state?.grupo_economico_id;
  }

  const [lawsuitsData, setLawsuitsData] = useState<IBusinessGroup[]>([]);
  const [natureSuits, setNatureSuits] = useState<INatureSuitProperties[]>([]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/naturesuits/show/all').then((response) => {
        setNatureSuits(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/reports/lawsuit/summary', state).then((response) => {
          setLawsuits(response.data);
          // setLawsuits2(response.data);
          setLoading(false);

          const customersCombined: IBusinessGroup[] = [];
          const filteredIds: number[] = [];
          let filteredCustomerIds: number[] = [];
          let natureSuitArray: INatureLawsuitsCombinedProperties[] = [];

          response.data.forEach(async (data: ILawsuitProperties, index: number) => {
            if (index === 0) {
              filteredIds.push(data.proc_cod_cliente);
            } else {
              const found = filteredIds.find((element) => element === data.proc_cod_cliente);
              if (!found) {
                filteredIds.push(data.proc_cod_cliente);
              } else {
                return;
              }
            }

            natureSuitArray = [];

            const filteredCustomer = response.data.filter((filtered:
              { proc_cod_cliente: number;
              }) => filtered.proc_cod_cliente === data.proc_cod_cliente);

            filteredCustomerIds = [];

            filteredCustomer.forEach((customer: ILawsuitProperties, indexCustomer: number) => {
              const filteredNatureLawsuits: ILawsuitProperties[] = filteredCustomer
                .filter((filtered:
                { proc_cod_natureza: number;
                }) => filtered.proc_cod_natureza === customer.proc_cod_natureza);

              const filteredDescription = natureSuits.filter(
                (
                  natureFilter: INatureSuitProperties,
                ) => natureFilter.natureza_id === customer.proc_cod_natureza,
              );

              if (indexCustomer === 0) {
                filteredCustomerIds.push(customer.proc_cod_natureza);
                natureSuitArray.push({
                  nature_suit: {
                    natureza_id: customer.proc_cod_natureza,
                    natureza_descricao: filteredDescription[0]?.natureza_descricao,
                  },
                  lawsuits: filteredNatureLawsuits,
                });
              } else {
                const found = filteredCustomerIds.find(
                  (element) => element === customer.proc_cod_natureza,
                );
                if (!found) {
                  filteredCustomerIds.push(customer.proc_cod_natureza);
                  natureSuitArray.push({
                    nature_suit: {
                      natureza_id: customer.proc_cod_natureza,
                      natureza_descricao: filteredDescription[0]?.natureza_descricao,
                    },
                    lawsuits: filteredNatureLawsuits,
                  });
                }
              }
            });

            const sortedNatureArray = natureSuitArray.sort(
              (a, b) => a.nature_suit.natureza_descricao
                ?.localeCompare(b.nature_suit.natureza_descricao, 'pt', { ignorePunctuation: true }),
            );

            customersCombined.push({
              customerName: data.cliente_nome,
              customerId: data.proc_cod_cliente,
              natureLawsuitCombined: sortedNatureArray,
            });
          });
          setLawsuitsData(customersCombined);
        });
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, location, natureSuits, state]);

  useEffect(() => {
    (async () => {
      try {
        await api.get(`/businessgroups/show/${business_group_id}`).then((response) => {
          const reportColumns: string[] = response.data.report_columns.toString().split(',');
          const defaultVisibleColumns: ColumnMeta[] = [];

          if (reportColumns.length > 0) {
            reportColumns.forEach((item) => {
              switch (item) {
                case 'proc_reg':
                  defaultVisibleColumns.push({
                    field: 'proc_reg', key: 'proc_reg', header: 'Processo N°', align: 'left',
                  });
                  break;
                case 'acao_descricao':
                  defaultVisibleColumns.push({
                    field: 'acao_descricao', key: 'acao_descricao', header: 'Tipo de processo', align: 'left',
                  });
                  break;
                case 'proc_autor':
                  defaultVisibleColumns.push({
                    field: 'proc_autor', key: 'proc_autor', header: 'Autor(es)', align: 'left',
                  });
                  break;
                case 'proc_reu':
                  defaultVisibleColumns.push({
                    field: 'proc_reu', key: 'proc_reu', header: 'Réu(s)', align: 'left',
                  });
                  break;
                case 'proc_situacao':
                  defaultVisibleColumns.push({
                    field: 'proc_situacao', key: 'proc_situacao', header: 'Situação Atual Processo', align: 'left',
                  });
                  break;
                case 'natureza_descricao':
                  defaultVisibleColumns.push({
                    field: 'natureza_descricao', key: 'natureza_descricao', header: 'Natureza processo', align: 'left',
                  });
                  break;
                case 'orgao_descricao':
                  defaultVisibleColumns.push({
                    field: 'orgao_descricao', key: 'orgao_descricao', header: 'Orgão julgador (Local)', align: 'left',
                  });
                  break;
                case 'proc_orgao_cidade':
                  defaultVisibleColumns.push({
                    field: 'proc_orgao_cidade', key: 'proc_orgao_cidade', header: 'Cidade', align: 'left',
                  });
                  break;
                case 'proc_orgao_uf':
                  defaultVisibleColumns.push({
                    field: 'proc_orgao_uf', key: 'proc_orgao_uf', header: 'Estado', align: 'center',
                  });
                  break;
                case 'proc_data_ajuiz':
                  defaultVisibleColumns.push({
                    field: 'proc_data_ajuiz', key: 'proc_data_ajuiz', header: 'Data ajuizamento', align: 'center',
                  });
                  break;
                case 'proc_data_ingresso':
                  defaultVisibleColumns.push({
                    field: 'proc_data_ingresso', key: 'proc_data_ingresso', header: 'Data ingresso', align: 'center',
                  });
                  break;
                case 'proc_valor_causa':
                  defaultVisibleColumns.push({
                    field: 'proc_valor_causa', key: 'proc_valor_causa', header: 'Valor nominal', align: 'right',
                  });
                  break;
                default:
                  break;
              }
              setVisibleColumns(defaultVisibleColumns);
            });
          }
        });
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, business_group_id, state]);

  const [visibleColumns, setVisibleColumns] = useState<ColumnMeta[]>([]);

  const columns: ColumnMeta[] = [
    {
      field: 'proc_reg', key: 'proc_reg', header: 'Processo N°', align: 'left',
    },
    {
      field: 'orgao_descricao', key: 'orgao_descricao', header: 'Órgão Julgador  (Local)', align: 'left',
    },
    {
      field: 'proc_autor', key: 'proc_autor', header: 'Autor(es)', align: 'left',
    },
    {
      field: 'proc_reu', key: 'proc_reu', header: 'Réu(s)', align: 'left',
    },
    {
      field: 'acao_descricao', key: 'acao_descricao', header: 'Tipo de processo', align: 'left',
    },
    {
      field: 'proc_assunto', key: 'proc_assunto', header: 'Assunto', align: 'left',
    },
    {
      field: 'proc_situacao', key: 'proc_situacao', header: 'Situação Atual do Processo', align: 'left',
    },

    {
      field: 'natureza_descricao', key: 'natureza_descricao', header: 'Natureza do processo', align: 'left',
    },
    {
      field: 'proc_orgao_cidade', key: 'proc_orgao_cidade', header: 'Cidade', align: 'left',
    },
    {
      field: 'proc_orgao_uf', key: 'proc_orgao_uf', header: 'Estado', align: 'center',
    },
    {
      field: 'proc_data_ajuiz', key: 'proc_data_ajuiz', header: 'Data ajuizamento', align: 'center',
    },
    {
      field: 'proc_data_ingresso', key: 'proc_data_ingresso', header: 'Data ingresso', align: 'center',
    },
    {
      field: 'proc_valor_causa', key: 'proc_valor_causa', header: 'Valor nominal', align: 'right',
    },
  ];

  const valueBodyTemplate = (rowData: {
      proc_valor_causa: number;
    }) => {
    if (lawsuits !== undefined) {
      const filteredValue = lawsuits.filter((item) => (
        item.proc_valor_causa === rowData.proc_valor_causa
      ), []);

      if (filteredValue[0].proc_valor_causa !== 0 && filteredValue[0]
        .proc_valor_causa !== null) {
        return Number(filteredValue[0].proc_valor_causa)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
    }
    return '0,00';
  };

  const exportPdf = (tableName: string, customerName: string) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('l', 'mm', [297, 210]);
    const totalPagesExp = '{total_pages_count_string}';

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: customerName,
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    doc.setFont('colibri', 'normal');

    autoTable(doc, {
      columns: visibleColumns,
      html: `#${tableName} table`,
      theme: 'grid',
      includeHiddenHtml: true,
      // columnStyles: {
      //   0: { cellWidth: 34 },
      //   1: { cellWidth: 37.04 },
      //   4: { cellWidth: 26.46 },
      //   6: { cellWidth: 30 },
      // },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 10,
      },
      alternateRowStyles: {
        fillColor: [226, 226, 226],
      },
      tableLineColor: [204, 204, 204],
      tableLineWidth: 0.1,
      didDrawPage(data) {
        // Header
        doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 15, 47.66, 6.09);

        const { pageSize } = doc.internal;
        const fontSize = doc.getFontSize();
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        const header1 = 'Relatório Resumido de Informações Processuais';
        const header2 = '';
        const header3 = `Cliente: ${customerName}`;
        const txtWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        const txtWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        const x1 = (pageWidth - txtWidth1) / 2;
        const x2 = (pageWidth - txtWidth2) / 2;

        doc.setFontSize(14);
        doc.setTextColor(20);
        doc.text(header1, x1, 18);

        doc.setTextColor(40);
        doc.text(header2, x2, 24);

        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text(header3, data.settings.margin.left, 27);
        doc.setFontSize(12);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left, pageHeight - 12,
          data.settings.margin.right, pageHeight - 12,
        );

        // Footer
        const printedOn = new Date();
        const dateFormatted = format(
          printedOn,
          "dd/MM/yyyy 'às' HH:mm:ss'.'",
        );

        let str = `Página ${doc.getNumberOfPages()}`;
        if (typeof doc.putTotalPages === 'function') {
          str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
        }

        doc.setFontSize(8);
        const footerPageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
        doc.text(str, footerPageCount, pageHeight - 8, { align: 'center' });
      },
      margin: { top: 30 },
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.setFontSize(10);

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`RelatorioResumido-${dateFormattedFile}.pdf`);
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const selectedColumns = event.value;
    const orderedSelectedColumns = columns
      .filter((col) => selectedColumns
        .some((sCol: { field: string; }) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };

  return (
    <Container>
      <header>
        <div>
          <h3>PABST & HADLICH ADVOGADOS ASSOCIADOS</h3>
          <h5>Relatório CUSTOMIZÁVEL de Informações Processuais</h5>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <Tooltip target=".export-buttons>button" position="bottom" />
          {
            lawsuitsData.map((item) => (
              <>
                <div className="attorney-header p-mt-20">
                  <span>
                    <h3>
                      Cliente:
                      {' '}
                      {item.customerName}
                    </h3>
                  </span>

                </div>
                <Toolbar
                  id={`custom-fields-toolbar-${item.customerId}`}
                  key={`custom-fields-${item.customerId}`}
                  className="p-mb-4 p-mt-20"
                  left={(
                    <>
                      <span className="p-input-icon-left p-mr-2">Campos disponíveis</span>
                      <MultiSelect
                        id={`multiselect-${item.customerId}`}
                        key={`multiselect-${item.customerId}`}
                        value={visibleColumns}
                        options={columns}
                        optionLabel="header"
                        onChange={onColumnToggle}
                        className="w-full sm:w-20rem"
                        display="chip"
                      />
                    </>
                      )}
                />
                {item.natureLawsuitCombined.map((itemNature, indexNature) => (
                  <>
                    <Toolbar
                      id={`natureLawsuit-toolbar-${item.customerId}-
                      ${itemNature.nature_suit.natureza_id}`}
                      key={`natureLawsuit-toolbar-${item.customerId}-
                      ${itemNature.nature_suit.natureza_id}`}
                      className="p-mb-4 p-mt-20"
                      left={(
                        <span>
                          <strong>Cliente: </strong>
                          {item.customerName}
                          <br />
                          <strong>Natureza do processo: </strong>
                          {item
                            .natureLawsuitCombined[indexNature]
                            .nature_suit.natureza_descricao}
                        </span>
                      )}
                      right={(
                        <Button
                          key={nanoid()}
                          type="button"
                          icon="pi pi-file-pdf"
                          onClick={() => exportPdf(`table-groupedlawsuits-${item.customerId}-${itemNature.nature_suit.natureza_id}`,
                            item.customerName)}
                          className="p-button-danger mr-2"
                          tooltip="Exportar para PDF / Imprimir"
                          tooltipOptions={{ position: 'left' }}
                        />
                      )}
                    />
                    <DataTable
                      key={`table-groupedlawsuits-${item.customerId}-${itemNature.nature_suit.natureza_id}`}
                      tableClassName="table-groupedhours table"
                      id={`table-groupedlawsuits-${item.customerId}-${itemNature.nature_suit.natureza_id}`}
                      // datakey={item.natureLawsuitCombined[indexNature]
                      // .lawsuits[indexNature].proc_id}
                      ref={dt}
                      loading={loading}
                      value={item.natureLawsuitCombined[indexNature].lawsuits}
                      header={(
                        <>
                          <div className="flex align-items-center export-buttons">
                            <span className="p-input-icon-left p-ml-auto">
                              <i className="pi pi-search" />
                              <InputText
                                id={`input-search-${item.customerId}-${itemNature.nature_suit.natureza_id}`}
                                key={`input-search-${item.customerId}-${itemNature.nature_suit.natureza_id}`}
                                type="search"
                                value={globalFilter}
                                onChange={(e) => setGlobalFilter(e.target.value)}
                                placeholder="Pesquisar na tabela"
                              />
                            </span>
                          </div>
                        </>
                      )}
                      stripedRows
                      className="p-datatable-sm table-pendinghours"
                      rows={item.natureLawsuitCombined[indexNature].lawsuits.length}
                      globalFilter={globalFilter}
                      emptyMessage="Nenhum registro encontrado."
                      responsiveLayout="scroll"
                    >
                      {visibleColumns.map((col) => {
                        if (col.field === 'proc_valor_causa') {
                          return (
                            <Column
                              key={`${col.key}-${item.customerId}-${itemNature.nature_suit.natureza_id}`}
                              field={col.field}
                              header={col.header}
                              body={valueBodyTemplate}
                              align={col.align}
                              sortable
                            />
                          );
                        }
                        return (
                          <Column
                            key={`${col.key}-${item.customerId}-${itemNature.nature_suit.natureza_id}`}
                            field={col.field}
                            header={col.header}
                            align={col.align}
                            sortable
                          />
                        );
                      })}
                    </DataTable>
                  </>
                ))}
                <hr className="p-mt-20" />
              </>
            ))
        }
        </div>
      </div>
    </Container>
  );
};

export default LawsuitSummaryReport;
