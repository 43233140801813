import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

 interface INatureSuitProperties {
  natureza_id: number | null;
  natureza_descricao: string;
}

const NatureSuit: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyNatureSuit = {
    natureza_id: null,
    natureza_descricao: '',
  };

  const [natureSuits, setNatureSuits] = useState<INatureSuitProperties[]>([]);
  const [natureSuit, setNatureSuit] = useState(emptyNatureSuit);
  const [deleteNatureSuit, setDeleteGroupDialog] = useState(false);
  const [deleteNatureSuitsDialog, setDeleteGroupsDialog] = useState(false);
  const [selectedNatureSuits, setSelectedNatureSuits] = useState<INatureSuitProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await api.get('/lawsuits/naturesuits/show/all');
      setNatureSuits(response.data);
    })();
  }, []);

  const columns = [
    { field: 'natureza_id', header: 'Código', sortable: true },
    { field: 'natureza_descricao', header: 'Descrição', sortable: true },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable
    />
  ));

  const handleCreate = () => {
    history.push('/lawsuit/naturesuit/create');
  };

  const hidedeleteNatureSuit = () => {
    setDeleteGroupDialog(false);
  };

  const hidedeleteNatureSuitsDialog = () => {
    setDeleteGroupsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editNatureSuit = (rowData:any) => {
    history.push(`/lawsuit/naturesuit/edit/${rowData.natureza_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteNatureSuit = (natureSuit: React.SetStateAction<{
    natureza_id: null; natureza_descricao: string;
  }>) => {
    setNatureSuit(natureSuit);
    setDeleteGroupDialog(true);
  };

  const deleteGroup = () => {
    api.delete(`/lawsuits/naturesuits/delete/${natureSuit.natureza_id}`);

    setDeleteGroupDialog(false);

    const updatedRecords = natureSuits
      .filter((val) => val.natureza_id !== natureSuit.natureza_id);
    setNatureSuits(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editNatureSuit(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteNatureSuit(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteNatureSuitFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteNatureSuit} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGroup} />
    </>
  );
  const deleteNatureSuitsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteNatureSuitsDialog} />
      {/* <Button label="Sim" icon="pi pi-check" className="p-button-text"
      onClick={deleteselectedNatureSuits} /> */}
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Naturezas do Processo</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-natureSuits table"
            id="table-natureSuits"
            ref={dt}
            value={natureSuits}
            editMode="row"
            dataKey="natureza_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedNatureSuits}
            onSelectionChange={(e) => {
              const selNatureSuits = e.value as INatureSuitProperties[];
              setSelectedNatureSuits(selNatureSuits);
            }}
            onRowDoubleClick={(e) => editNatureSuit(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteNatureSuit} style={{ width: '450px' }} header="Confirmação" modal footer={deleteNatureSuitFooter} onHide={hidedeleteNatureSuit}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {natureSuit && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{natureSuit.natureza_descricao}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteNatureSuitsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteNatureSuitsDialogFooter} onHide={hidedeleteNatureSuitsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {natureSuit
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default NatureSuit;
