import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useLocation, useParams } from 'react-router-dom';

import PrimeReact from 'primereact/api';

import { TabView, TabPanel } from 'primereact/tabview';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface SearchData {
  proc_reg: string;
  proc_num_pasta: string;
  proc_reg_outros : string;
  // proc_cod_acao: number;
  proc_cod_orgao: number;
  proc_cod_natureza: number;
  proc_orgao_cidade: string;
  proc_orgao_uf: string;
  proc_autor: string;
  proc_reu: string;
  proc_cod_cliente: number;
  proc_cod_adv_resp: number;
  proc_cod_ass_resp: number;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
  proc_data_ingresso_inicio: Date;
  proc_data_ingresso_final: Date;
  proc_em_acordo: string;
  updatedin: number;
  nochanges: number;
  formValuesChanged: boolean;
}

interface ILawsuitProperties {
  proc_id: number | null;
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
}

const Lawsuit: React.FC = () => {
  PrimeReact.ripple = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);

  const { addToast } = useToast();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;
  const folderNumber = localStorage.getItem('@Jurisnet:foldernumber');
  let folder = '';
  if (folderNumber) {
    folder = JSON.parse(folderNumber);
  }

  const initialValues: SearchData = {
    proc_reg: '',
    proc_num_pasta: folder,
    proc_reg_outros: '',
    proc_cod_orgao: 0,
    proc_cod_natureza: 0,
    proc_orgao_cidade: '',
    proc_orgao_uf: '',
    proc_autor: '',
    proc_reu: '',
    proc_cod_cliente: 0,
    proc_cod_adv_resp: 0,
    proc_cod_ass_resp: 0,
    proc_data_ajuiz_inicio: new Date(),
    proc_data_ajuiz_final: new Date(),
    proc_data_ingresso_inicio: new Date(),
    proc_data_ingresso_final: new Date(),
    proc_em_acordo: '4',
    updatedin: 0,
    nochanges: 0,
    formValuesChanged: false,
  };

  const [lawsuitRegistrationNumber, setLawsuitRegistrationNumber] = useState('');
  const [lawsuitFolderNumber, setLawsuitFolderNumber] = useState('');
  const [otherLawsuitNumbers, setOtherLawsuitNumbers] = useState('');
  const [natureSuitsOptions, setNatureSuitsOptions] = useState([{}]);
  const [suitTypesOptions, setSuitTypesOptions] = useState([{}]);
  const [author, setAuthor] = useState('');
  const [defendant, setDefendant] = useState('');
  const [lawsuitId, setLawsuitId] = useState('');
  const [natureId, setNatureId] = useState('');
  const [judgingBodyCityId, setJudgingBodyCityId] = useState('');
  const [judgingBodyStateId, setJudgingBodyStateId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [attorneyInChargeId, setAttorneyInChargeId] = useState('');
  const [assistantInChargeId, setAssistantInChargeId] = useState('');
  const [lawsuitStatus, setLawsuitStatus] = useState('4');

  const [updatedInDay, setUpdatedInDay] = useState(0);
  const [noChangesDay, setNoChangesDay] = useState(0);

  const [
    initialRegistrationDate, setInitialRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    finalRegistrationDate, setFinalRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    initialEntranceDate, setInitialEntranceDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [finalEntranceDate, setFinalEntranceDate] = useState<string | Date | Date[] | null>(null);

  const [attorneysOptions, setAttorneysOptions] = useState([{}]);
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [customersOptions, setCustomersOptions] = useState([{}]);

  const [lawsuits, setLawsuits] = useState<ILawsuitProperties[]>([]);
  // const [lawsuit, setLawsuit] = useState(emptyLawsuit);
  const [selectedLawsuits, setSelectedLawsuits] = useState<ILawsuitProperties[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  const validationSchema = null;

  const lawsuitStatusOptions = [
    { name: 'ativos', value: '0', style: 'font-size: small;' },
    { name: 'em acordo', value: '1', style: 'font-size: small;' },
    { name: 'arquivados', value: '2', style: 'font-size: small;' },
    { name: 'ativos+em acordo', value: '4', style: 'font-size: small;' },
    { name: 'todos', value: '5', style: 'font-size: small;' },
  ];

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/naturesuits/show/all').then((response) => {
        response.data.map((natureSuit: { natureza_id: number, natureza_descricao:string}) => (
          natureSuitsOptions.push({
            name: natureSuit.natureza_descricao,
            value: natureSuit.natureza_id,
          })
        ));
      });
      natureSuitsOptions.splice(0, 1);
      setNatureSuitsOptions(natureSuitsOptions);
    })();
  }, [natureSuitsOptions]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/suittypes/show/all').then((response) => {
        response.data.map((natureSuit: { acao_id: number, acao_descricao:string}) => (
          suitTypesOptions.push({
            name: natureSuit.acao_descricao,
            value: natureSuit.acao_id,
          })
        ));
      });
      suitTypesOptions.splice(0, 1);
      setSuitTypesOptions(suitTypesOptions);
    })();
  }, [suitTypesOptions]);

  const brazilianStatesOptions = [
    { name: 'AC', value: 'AC' },
    { name: 'AL', value: 'AL' },
    { name: 'AM', value: 'AM' },
    { name: 'AP', value: 'AP' },
    { name: 'BA', value: 'BA' },
    { name: 'CE', value: 'CE' },
    { name: 'DF', value: 'DF' },
    { name: 'ES', value: 'ES' },
    { name: 'GO', value: 'GO' },
    { name: 'MA', value: 'MA' },
    { name: 'MG', value: 'MG' },
    { name: 'MS', value: 'MS' },
    { name: 'MT', value: 'MT' },
    { name: 'PA', value: 'PA' },
    { name: 'PB', value: 'PB' },
    { name: 'PE', value: 'PE' },
    { name: 'PI', value: 'PI' },
    { name: 'PR', value: 'PR' },
    { name: 'RJ', value: 'RJ' },
    { name: 'RN', value: 'RN' },
    { name: 'RO', value: 'RO' },
    { name: 'RR', value: 'RR' },
    { name: 'RS', value: 'RS' },
    { name: 'SC', value: 'SC' },
    { name: 'SE', value: 'SE' },
    { name: 'SP', value: 'SP' },
    { name: 'TO', value: 'TO' },
  ];

  useEffect(() => {
    (async () => {
      await api.get('management/attorneys/show/all').then((response) => {
        response.data.map((attorney:
        {
          advogado_id: number,
          advogado_nome:string
        }) => (
          attorneysOptions.push({
            name: attorney.advogado_nome,
            value: attorney.advogado_id,
          })
        ));
      });
      attorneysOptions.splice(0, 1);
      setAttorneysOptions(attorneysOptions);
    })();
  }, [attorneysOptions]);

  useEffect(() => {
    (async () => {
      await api.get('management/assistants/show/all').then((response) => {
        response.data.map((assistant:
        {
          administrador_id: number,
          administrador_nome:string
        }) => (
          assistantsOptions.push({
            name: assistant.administrador_nome,
            value: assistant.administrador_id,
          })
        ));
      });
      assistantsOptions.splice(0, 1);
      setAssistantsOptions(assistantsOptions);
    })();
  }, [assistantsOptions]);

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      const searchFormData = {
        proc_reg: lawsuitRegistrationNumber,
        proc_num_pasta: folderNumber ? JSON.parse(folderNumber) : lawsuitFolderNumber,
        proc_reg_outros: otherLawsuitNumbers,
        proc_cod_acao: lawsuitId,
        // proc_cod_orgao: judgingBodyId,
        proc_cod_natureza: natureId,
        proc_orgao_cidade: judgingBodyCityId,
        proc_orgao_uf: judgingBodyStateId,
        proc_autor: author,
        proc_reu: defendant,
        proc_cod_cliente: customerId,
        proc_cod_adv_resp: attorneyInChargeId,
        proc_cod_ass_resp: assistantInChargeId,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
        proc_data_ingresso_inicio: initialEntranceDate,
        proc_data_ingresso_final: finalEntranceDate,
        proc_em_acordo: lawsuitStatus,
        updatedin: updatedInDay,
        nochanges: noChangesDay,
        formValuesChanged: false,
      };

      api.post('/lawsuits/main/search', searchFormData).then((response) => {
        setLawsuits(response.data);
      });

      setActiveIndex(1);

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const state = location.state as any;
      api.post('/lawsuits/main/search', state).then((response) => {
        setLawsuits(response.data);
      });
    } catch (err) {
      addToast({
        type: 'info',
        title: 'Sem resultados!',
        description:
                'A pesquisa não apresentou resultados.',
      });
    }
  }, [addToast, location]);

  useEffect(() => {
    try {
      if (folderNumber) {
        const searchParam = JSON.parse(folderNumber);
        setLawsuitFolderNumber(searchParam);
        document.getElementById('submitBtn')?.click();
      }
    } catch (err) {
      addToast({
        type: 'info',
        title: 'Sem parâmetros!',
        description:
                'A pesquisa não apresentou parâmetros de pesquisa.',
      });
    }
  }, [addToast, folderNumber]);

  const columns = [
    { field: 'proc_num_pasta', header: 'Pasta', sortable: true },
    { field: 'proc_reg', header: 'Processo nº', sortable: true },
    { field: 'proc_autor', header: 'Autor(es)', sortable: true },
    { field: 'proc_reu', header: 'Réu(s)', sortable: true },
    { field: 'acao_descricao', header: 'Tipo Ação', sortable: true },
    { field: 'proc_ult_atu', header: 'Última Atualização', sortable: true },
  ];

  const dateBodyTemplate = (rowData: { proc_ult_atu: Date; }) => {
    if (lawsuits !== undefined) {
      const filteredDate = lawsuits.filter((item) => (
        item.proc_ult_atu === rowData.proc_ult_atu
      ), []);

      const dateInput = filteredDate[0].proc_ult_atu?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }

    return '';
  };

  const dynamicColumns = columns.map((col) => {
    if (col.field === 'proc_ult_atu') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={dateBodyTemplate}
          sortable={false}
          headerStyle={{ width: '100px' }}
          className="p-dt-tooltip"
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
      />
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLawsuit = (rowData:any) => {
    localStorage.setItem('@Jurisnet:lawsuit', JSON.stringify(rowData));
    api.get(`/lawsuits/judgingbodies/show/${rowData.proc_cod_orgao}`).then((responsebodies) => {
      localStorage.setItem('@Jurisnet:judgingbody', JSON.stringify(responsebodies.data.orgao_descricao));
    });
    document.getElementById('horas_hora_final_texto')?.click();
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  return (
    <Container>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Pesquisa">
          <Formik
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={initialValues}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form id="searchForm" onSubmit={handleSubmit}>
                <div className="fluid formgrid grid p-mt-10">
                  <div className="col-12 md:col-4 colum-spacer-right">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_reg">
                          Nº do processo
                        </label>
                        <InputText
                          id="proc_reg"
                          name="proc_reg"
                          className="inputfield w-full"
                          onChange={(e) => {
                            setLawsuitRegistrationNumber(e.target.value);
                            handleChange(e);
                          }}
                        // onBlur={handleBlur}
                          value={lawsuitRegistrationNumber}
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_num_pasta">
                          Pasta nº
                        </label>
                        <InputText
                          id="proc_num_pasta"
                          name="proc_num_pasta"
                          className="inputfield w-full"
                          onChange={(e) => setLawsuitFolderNumber(e.target.value)}
                          onBlur={handleBlur}
                          value={lawsuitFolderNumber}
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_num_pasta">
                          Outros nrs. do processo
                        </label>
                        <InputText
                          id="proc_reg_outros"
                          name="proc_reg_outros"
                          className="inputfield w-full"
                          onChange={(e) => setOtherLawsuitNumbers(e.target.value)}
                          onBlur={handleBlur}
                          value={otherLawsuitNumbers}
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_cod_acao">
                          Tipo de ação
                        </label>
                        <Dropdown
                          id="proc_cod_acao"
                          name="proc_cod_acao"
                          options={suitTypesOptions}
                          onChange={(e) => setLawsuitId(e.target.value)}
                          value={lawsuitId}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Todas"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_cod_natureza">
                          Natureza do processo
                        </label>
                        <Dropdown
                          id="proc_cod_natureza"
                          name="proc_cod_natureza"
                          options={natureSuitsOptions}
                          onChange={(e) => setNatureId(e.target.value)}
                          value={natureId}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Todas"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>
                      <div className="col-12 md:col-9">
                        <label htmlFor="proc_orgao_cidade">
                          Cidade
                        </label>
                        <InputText
                          id="proc_orgao_cidade"
                          name="proc_orgao_cidade"
                          className="inputfield w-full"
                          onChange={(e) => setJudgingBodyCityId(e.target.value)}
                          value={judgingBodyCityId}
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="proc_orgao_uf">
                          Estado
                        </label>
                        <Dropdown
                          id="proc_orgao_uf"
                          name="proc_orgao_uf"
                          options={brazilianStatesOptions}
                          onChange={(e) => setJudgingBodyStateId(e.target.value)}
                          value={judgingBodyStateId}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-4 column-divider">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_autor">
                          Autor
                        </label>
                        <InputText
                          id="proc_autor"
                          name="proc_autor"
                          className="inputfield w-full"
                          value={author}
                          onChange={(e) => setAuthor(e.target.value)}
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_reg_outros">
                          Réu
                        </label>
                        <InputText
                          id="proc_reu"
                          name="proc_reu"
                          className="inputfield w-full"
                          value={defendant}
                          onChange={(e) => setDefendant(e.target.value)}
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_cliente">
                          Cliente
                        </label>
                        <Dropdown
                          id="proc_cliente"
                          name="proc_cliente"
                          options={customersOptions}
                          onChange={(e) => setCustomerId(e.target.value)}
                          value={customerId}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>

                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_cod_adv_resp">
                          Adv. Responsável
                        </label>
                        <Dropdown
                          id="proc_cod_adv_resp"
                          name="proc_cod_adv_resp"
                          options={attorneysOptions}
                          onChange={(e) => setAttorneyInChargeId(e.target.value)}
                          value={attorneyInChargeId}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_cod_ass_resp">
                          Assistente
                        </label>
                        <Dropdown
                          id="proc_cod_ass_resp"
                          name="proc_cod_ass_resp"
                          options={assistantsOptions}
                          onChange={(e) => setAssistantInChargeId(e.target.value)}
                          value={assistantInChargeId}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-4 colum-spacer-left">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_data_ajuiz_inicio">
                          Data de ajuizamento
                        </label>
                      </div>
                      <div className="col-12 md:col-6">
                        <Calendar
                          inputId="proc_data_ajuiz_inicio"
                          name="proc_data_ajuiz_inicio"
                          className="inputfield w-full"
                          value={
                            isAddMode
                              ? null
                              : new Date()
                          }
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setInitialRegistrationDate(e.value);
                            }
                          }}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                          placeholder="Data inicial"
                        />
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="proc_data_ajuiz_final">
                          <Calendar
                            inputId="proc_data_ajuiz_final"
                            name="proc_data_ajuiz_final"
                            className="inputfield w-full"
                            value={
                              isAddMode
                                ? null
                                : new Date()
                            }
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setFinalRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data final"
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_data_ingresso_inicio">
                          Ingresso no escritório
                        </label>
                      </div>
                      <div className="col-12 md:col-6">
                        <Calendar
                          inputId="proc_data_ingresso_inicio"
                          name="proc_data_ingresso_inicio"
                          className="inputfield w-full"
                          value={
                            isAddMode
                              ? null
                              : new Date()
                          }
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setInitialEntranceDate(e.value);
                            }
                          }}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                          placeholder="Data inicial"
                        />
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="proc_data_ingresso_final">
                          <Calendar
                            inputId="proc_data_ingresso_final"
                            name="proc_data_ingresso_final"
                            className="inputfield w-full"
                            value={
                              isAddMode
                                ? null
                                : new Date()
                            }
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setFinalEntranceDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data final"
                          />
                        </label>
                      </div>

                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_em_acordo">
                          Incluir processos
                        </label>
                        <SelectButton
                          id="proc_em_acordo"
                        // className="narrowed-option-button"
                          value={lawsuitStatus}
                          options={lawsuitStatusOptions}
                          optionLabel="name"
                          onChange={(e) => setLawsuitStatus(e.value)}
                          style={{ padding: '0.5rem 0.79rem' }}
                        />
                      </div>

                      <div className="col-12 md:col-12 p-mt-10">
                        <div className="col-12 md:col-12">
                          <label htmlFor="updatedin">
                            Processos sem atualização nos últimos
                            {' '}
                          </label>
                          <InputNumber
                            id="updatedin"
                            name="updatedin"
                            className="inputfield"
                            placeholder="X"
                            value={updatedInDay}
                            onValueChange={(e) => setUpdatedInDay(Number(e.value))}
                            style={{ width: '3.5rem', alignItems: 'center' }}
                            min={0}
                          />
                          {' '}
                          dias
                        </div>
                      </div>
                      <div className="col-12 md:col-12 p-mt-10">
                        <div className="col-12 md:col-12">
                          <label htmlFor="nochanges">
                            Processos sem mov. relevante nos últimos
                            {' '}
                          </label>
                          <InputNumber
                            id="nochanges"
                            name="nochanges"
                            className="inputfield"
                            placeholder="X"
                            value={noChangesDay}
                            onValueChange={(e) => setNoChangesDay(Number(e.value))}
                            style={{ width: '3.5rem', alignItems: 'center' }}
                            min={0}
                          />
                          {' '}
                          dias
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-5">
                    <div className="flex align-content-center justify-content-center field">
                      <Button
                        id="submitBtn"
                        icon="pi pi-check"
                        iconPos="left"
                        label="Pesquisar processos"
                        className="p-mr-2"
                        type="submit"
                        disabled={isSubmitting}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </TabPanel>
        <TabPanel header="Resultados">
          <div className="datatable-responsive">
            <DataTable
              tableClassName="table-lawsuits table"
              id="table-lawsuits"
              ref={dt}
              value={lawsuits}
              loading={loading}
              editMode="row"
              dataKey="proc_id"
              header={header}
              stripedRows
              className="p-datatable-sm"
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
              rows={5}
              rowsPerPageOptions={[10, 20, 50]}
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              selectionMode="single"
              selection={selectedLawsuits}
              loadingIcon="pi pi-spin pi-spinner"
              onSelectionChange={(e) => {
                const selLawsuits = e.value as ILawsuitProperties[];
                setSelectedLawsuits(selLawsuits);
              }}
              onRowDoubleClick={(e) => editLawsuit(e.data)}
            >
              <Column field="row_number" header="Nº" sortable headerStyle={{ width: '3rem' }} />
              {dynamicColumns}
            </DataTable>

          </div>
        </TabPanel>
      </TabView>
    </Container>
  );
};

export default Lawsuit;
