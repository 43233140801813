/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
// import { SelectButton } from 'primereact/selectbutton';

import { useHistory, useParams } from 'react-router-dom';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  Container,
} from './styles';

interface AdministratorFormData {
  administrador_id?: number;
  administrador_nome: string;
  administrador_login: string;
  administrador_senha: string;
  administrador_email: string;
  administrador_categoria: number;
  administrador_ativo: boolean;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adm: number;
  valor_adm: string;
  administrador_setor: string;
}

const AddEditAdmnistrator:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const { updateUser } = useAuth();

  const [assistant, setAssistant] = useState<AdministratorFormData>();
  const [moveAssistantDialog, setMoveAssistantDialog] = useState(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [oldStatus, setOldStatus] = useState<boolean>();

  const initialValues: AdministratorFormData = {
    administrador_nome: '',
    administrador_login: '',
    administrador_senha: '',
    administrador_email: '',
    administrador_categoria: 2,
    administrador_ativo: true,
    permissao_relatoriov: 0,
    permissao_relatorioiv: 0,
    sta_new_senha: false,
    cat_adm: 0,
    valor_adm: '',
    administrador_setor: '',
  };

  // const options = [
  //   { name: 'Sim', value: 1 },
  //   { name: 'Não', value: 0 },
  // ];

  const groupOptions = [
    { name: 'Assistente', value: 16 },
    { name: 'Estagiário', value: 17 },
  ];

  const validationSchema = Yup.object().shape({
    administrador_nome: Yup.string().required('Nome obrigatório'),
    administrador_setor: Yup.string().required('Setor obrigatório'),
    administrador_email: Yup.string().required()
      // eslint-disable-next-line no-useless-escape
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .email('Digite um e-mail válido'),
    administrador_login: Yup.string().required('Nome de login obrigatório'),
    administrador_senha: Yup.string()
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .required('Senha é obrigatória'),
  });

  const handleOnSubmit = (
    values: AdministratorFormData,
    { setSubmitting }: FormikHelpers<AdministratorFormData>,
  ) => {
    if (isAddMode) {
      try {
        api.post('/management/assistants/create', values);
        setSubmitting(false);

        try {
          const userFormData = {
            name: values.administrador_nome,
            login_name: values.administrador_login,
            legacy_id: values.administrador_id,
            password: values.administrador_senha,
            email: values.administrador_email,
            active: true,
            user_level: 2,
          };

          api.post('/users', userFormData);

          addToast({
            type: 'success',
            title: 'Acesso liberado!',
            description:
              'O acesso ao sistema para o advogado foi criado com sucesso!',
          });
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Ocorreu um problema',
            description:
                `Tente repetir apenas a liberação de acesso ao sistema ou contate o administrador. (${error})`,
          });
        }

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/management/assistant');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        api.put(`/management/assistants/edit/${id}`, values);

        if (oldPassword !== values.administrador_senha
          || oldStatus !== values.administrador_ativo) {
          const formData = {
            name: values.administrador_nome,
            login_name: values.administrador_login,
            legacy_id: values.administrador_id,
            email: values.administrador_email,
            old_password: oldPassword,
            password: values.administrador_senha,
            password_confirmation: values.administrador_senha,
            active: values.administrador_ativo,
            user_level: 2,
          };

          api.put('/profile', formData).then((response) => {
            updateUser(response.data);
          });
        }

        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/management/assistant');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  const hideMoveAssistantDialog = () => {
    setMoveAssistantDialog(false);
  };

  const moveAssistantDialogFooter = () => (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hideMoveAssistantDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={handleAssistantToAttorney} />
    </>
  );

  const confirmMoveAssistant = (values: AdministratorFormData) => {
    setAssistant(values);
    setMoveAssistantDialog(true);
  };

  const handleAssistantToAttorney = () => {
    try {
      const formData = {
        administrador_id: assistant?.administrador_id,
        administrador_nome: assistant?.administrador_nome,
        administrador_login: assistant?.administrador_login,
        administrador_senha: assistant?.administrador_senha,
        administrador_email: assistant?.administrador_email,
      };

      api.post('/management/attorneys/upgradeassistant', formData);

      setMoveAssistantDialog(false);

      addToast({
        type: 'success',
        title: 'Cadastro atualizado!',
        description:
          'As informações do cadastro foram inseridas com sucesso!',
      });
    } catch (error) {
      setMoveAssistantDialog(false);

      addToast({
        type: 'error',
        title: 'Ocorreu um problema ao cadastrar advogado!',
        description:
            `Tente repetir o cadastro ou contate o administrador. (${error})`,
      });
      return;
    }

    try {
      api.delete(`/management/assistants/delete/${id}`);

      setMoveAssistantDialog(false);

      addToast({
        type: 'success',
        title: 'Cadastro excluído!',
        description:
          'O cadastro do assistente foi excluído com sucesso!',
      });
    } catch (error) {
      setMoveAssistantDialog(false);

      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
            `Tente repetir o cadastro ou contate o administrador. (${error})`,
      });
    }

    history.push('/management/assistant');
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            assistente/estagiário
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              let isMounted = true;
              try {
                if (!isAddMode) {
                  if (isMounted) {
                    api.get(`management/assistants/show/${id}`).then((response) => {
                      setValues(response.data);
                      setOldPassword(response.data.administrador_senha);
                      setOldStatus(response.data.administrador_ativo);
                    });
                  }
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              } finally {
                isMounted = false;
              }
            }, [setValues]);
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="fluid formgrid grid">

                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-6">
                          <label htmlFor="administrador_nome">
                            Nome *
                          </label>
                          <InputText
                            id="administrador_nome"
                            name="administrador_nome"
                            className={` ${errors.administrador_nome && touched.administrador_nome ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.administrador_nome}
                          />
                          <ErrorMessage name="administrador_nome">
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6">
                          <label htmlFor="administrador_email">
                            Endereço de e-mail *
                          </label>
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="pi pi-envelope" />
                            </span>
                            <InputText
                              id="administrador_email"
                              name="administrador_email"
                              className="inputfield w-full"
                              value={values.administrador_email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage name="administrador_email">
                              {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-6">
                          <label htmlFor="administrador_login">
                            Login *
                          </label>
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="pi pi-user" />
                            </span>
                            <InputText
                              id="administrador_login"
                              name="administrador_login"
                              className={` ${errors.administrador_login && touched.administrador_login ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.administrador_login}
                              placeholder="nome de login"
                            />
                          </div>
                          <ErrorMessage name="administrador_login">
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="col-12 md:col-6">
                          <label htmlFor="administrador_senha">
                            Senha de acesso *
                          </label>
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="pi pi-lock" />
                            </span>
                            <Password
                              id="administrador_senha"
                              name="administrador_senha"
                              className={` ${errors.administrador_senha && touched.administrador_senha ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.administrador_senha}
                              toggleMask
                            />
                          </div>
                          <ErrorMessage name="administrador_senha">
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <header>
                        <h3>Informações administrativas</h3>
                      </header>
                    </div>

                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-4">
                          <label htmlFor="administrador_setor">
                            Setor *
                          </label>
                          <InputText
                            id="administrador_setor"
                            name="administrador_setor"
                            className={` ${errors.administrador_setor && touched.administrador_setor ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.administrador_setor}
                          />
                          <ErrorMessage name="administrador_setor">
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="col-12 md:col-4">
                          <label htmlFor="cat_adm">
                            Grupo *
                          </label>
                          <Dropdown
                            id="cat_adm"
                            name="cat_adm"
                            value={values.cat_adm}
                            options={groupOptions}
                            onChange={handleChange}
                            className="inputfield w-full"
                            optionLabel="name"
                            optionValue="value"
                            filter
                            filterBy="name"
                            placeholder="Selecione um grupo"
                            emptyFilterMessage="Nenhum registro encontrado"
                          />
                          <ErrorMessage name="cat_adm">
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="col-12 md:col-4">
                          <label htmlFor="valor_adm">
                            Valor hora
                          </label>
                          <InputText
                            id="valor_adm"
                            name="valor_adm"
                            className={` ${errors.valor_adm && touched.valor_adm ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.valor_adm}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12">
                      <header>
                        <h3>Acesso relatórios</h3>
                      </header>
                    </div>
                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-6 lg:col-3">
                          <label htmlFor="permissao_relatorioiv">Visualiza relatório IV?</label>
                          <SelectButton
                            id="permissao_relatorioiv"
                            value={values.permissao_relatorioiv}
                            options={options}
                            optionLabel="name"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-12 md:col-6 lg:col-3">
                          <label htmlFor="permissao_relatoriov">Visualiza relatório V?</label>
                          <SelectButton
                            id="permissao_relatoriov"
                            value={values.permissao_relatoriov}
                            options={options}
                            optionLabel="name"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-12 p-mt-20">
                      <div className="col-12">
                        Todos os campos marcados com * são de preenchimento obrigatório.
                      </div>
                      <header />
                      <div className="flex align-content-center justify-content-center field">

                        <Button
                          icon="pi pi-check"
                          iconPos="left"
                          label="Enviar dados"
                          className="p-mr-2"
                          type="submit"
                          disabled={!isValid || !dirty}
                        />
                        <Button
                          icon="pi pi-undo"
                          iconPos="left"
                          label="Limpar campos"
                          className="p-button-secondary p-mr-2"
                          type="submit"
                          onClick={handleReset}
                        />
                        <Button
                          icon="pi pi-times"
                          iconPos="left"
                          label="Cancelar e voltar"
                          className="p-button-danger p-mr-2"
                          type="submit"
                          onClick={history.goBack}
                        />
                        <Button
                          icon="pi pi-user"
                          iconPos="left"
                          label="Alterar Usuário para Advogado"
                          className="p-button p-component p-button-outlined p-button-info"
                          type="button"
                          onClick={(e) => { e.preventDefault(); confirmMoveAssistant(values); }}
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <Dialog visible={moveAssistantDialog} style={{ width: '450px' }} header="Confirmação" modal footer={moveAssistantDialogFooter} onHide={hideMoveAssistantDialog}>
                  <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {values && (
                    <span>
                      O cadastro para
                      {' '}
                      <b>{values.administrador_nome}</b>
                      {' '}
                      será alterado de assistente para advogado e o cadastro atual será removido.
                      <br />
                      Este processo não poderá ser desfeito.
                      <br />
                      <br />
                      <b>Você deseja continuar?</b>
                    </span>
                    )}
                  </div>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditAdmnistrator;
