/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";

import PrimeReact from "primereact/api";

import { useHistory, useLocation } from "react-router-dom";

import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";

import { round } from "lodash";
import moment from "moment";

import { nanoid } from "nanoid";
import { InputNumber } from "primereact/inputnumber";

import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import { Container } from "./styles";

interface IBilledHours {
  row_number: number;
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_descricao: string;
  horas_data: Date;
  horas_hora_inicial: Date;
  horas_hora_final: Date;
  horas_documento: string;
  horas_data_alteracao: Date;
  horas_hora_total: string;
  horas_tipo: string;
  advogado_nome: string;
  administrador_nome: string;
  valor_ajuste_nao_faturado: number;
  horas_fatu_ajuste: number;
  proc_cobrar_por_hora: string;
}

interface IReferenceValues {
  horas_cod_referencia: number;
  horas_referencia_nome: string;
  horas_valor_hora: number;
}

const HourManagementReport: React.FC = () => {
  PrimeReact.ripple = true;

  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let initialSearchDate = "";
  let finalSearchDate = "";
  if (state) {
    initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format(
      "DD/MM/YYYY"
    );
    finalSearchDate = moment(state?.proc_data_ajuiz_final).format("DD/MM/YYYY");
    if (
      state.dateFilterType === "periodo" &&
      state?.proc_data_ajuiz_final === undefined
    ) {
      finalSearchDate = moment(state?.proc_data_ajuiz_inicio)
        .clone()
        .endOf("month")
        .format("DD/MM/YYYY");
    }
  }

  const emptyBilledHour = {
    row_number: 0,
    horas_id: 0,
    horas_cod_cliente: 0,
    horas_cod_referencia: 0,
    horas_descricao: "",
    horas_data: new Date(),
    horas_hora_inicial: new Date(),
    horas_hora_final: new Date(),
    horas_documento: "",
    horas_data_alteracao: new Date(),
    horas_hora_total: "",
    horas_tipo: "",
  };

  localStorage.removeItem("@Jurisnet:ajustevalores");
  localStorage.removeItem("@Jurisnet:valorajuste");
  localStorage.removeItem("@Jurisnet:invoicestart");
  localStorage.removeItem("@Jurisnet:invoiceend");
  localStorage.removeItem("@Jurisnet:invoice");
  const tempSelectedHour = localStorage.getItem("@Jurisnet:selectedhour");

  const [billedHours, setBilledHours] = useState<IBilledHours[]>([]);
  const [billedHour, setBilledHour] = useState(emptyBilledHour);
  const [selectedHours, setSelectedHours] = useState<IBilledHours[]>([]);
  const [deleteHourDialog, setDeleteHourDialog] = useState(false);

  const [totalTime, setTotalTime] = useState("");
  const [totalDecimalTime, setTotalDecimalTime] = useState("");

  const [valuePerHour, setValuePerHour] = useState("0.00");
  const [totalValuePeriod, setTotalValuePeriod] = useState("0.00");
  const [totalAddedValuePeriod, setTotalAddedValuePeriod] = useState("0.00");

  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);
  const globalReferenceId = useRef(0);
  const globalCategoryValue = useRef(0);
  const adjustment = useRef("");
  const enteredAdjustmentValue = useRef(0.0);

  const convertDecimalToHour = useCallback((totalHour: string) => {
    let horas_total = "";
    let total_de_horas = "";
    let horas_decimal = "0";
    let total_h1 = 0;
    let total_m1 = 0;

    if (!totalHour) {
      horas_total = "0:00";
    } else {
      const horas_total_tmp = totalHour.split(":");

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
            .toFixed(0)
            .toString();
        } else {
          horas_total += `0${(Number(horas_total_tmp[1]) / 60) * 100}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += "00";
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(
          ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
        );
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = horas_total_tmp[0].toString();
      } else {
        horas_decimal = "0";
      }

      const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);
        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += ".00";
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = "0.00";
      }
    }

    return total_de_horas;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        localStorage.setItem("@Jurisnet:invoice", "false");
        setLoading(true);
        await api.post("/reports/hour/general/desc", state).then((response) => {
          setBilledHours(response.data);

          const durations: string[] = [];
          let valor_categoria = 0.0;
          let total_do_periodo = 0.0;
          let nonBilledValue = 0.0;
          let horas_decimal_total = 0.0;
          let total_ajuste_decimal = 0.0;

          let ind = 0;
          response.data.forEach(async (data: IBilledHours) => {
            const referenceId = data.horas_cod_referencia
              ? data.horas_cod_referencia
              : 0;
            globalReferenceId.current = referenceId;
            nonBilledValue = data.valor_ajuste_nao_faturado
              ? data.valor_ajuste_nao_faturado
              : 0;

            total_ajuste_decimal += Number(nonBilledValue);

            let horas_total = "";
            let total_de_horas = "";
            let horas_decimal = "0";
            let total_h1 = 0;
            let total_m1 = 0;

            if (!data.horas_hora_total) {
              horas_total = "0:00";
            } else {
              const horas_total_tmp = data.horas_hora_total.split(":");

              if (Number(horas_total_tmp[0]) >= 10) {
                horas_total = `${horas_total_tmp[0]}:`;
              } else {
                horas_total = `0${horas_total_tmp[0]}:`;
              }

              if (Number(horas_total_tmp[1]) > 0) {
                if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
                  horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
                    .toFixed(0)
                    .toString();
                } else {
                  horas_total += `0${(Number(horas_total_tmp[1]) / 60) * 100}`;
                }
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                horas_total += "00";
              }

              total_h1 += Number(horas_total_tmp[0]);

              if (Number(horas_total_tmp[1]) > 0) {
                total_m1 += Number(
                  ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
                );
              }

              if (Number(horas_total_tmp[0]) > 0) {
                horas_decimal = Number(horas_total_tmp[0]).toString();
              } else {
                horas_decimal = "0";
              }

              const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

              if (minutos > 0) {
                let temp = ((minutos * 100) / 60).toFixed(0);
                if (Number(temp) < 10) {
                  temp = `0${temp}`;
                }

                horas_decimal += `.${temp}`;
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                horas_decimal += ".00";
              }
            }

            if (total_m1 >= 60) {
              total_h1 += 1;
              total_m1 -= 60;
            }

            if (total_h1 >= 10) {
              total_de_horas = `${total_h1}`;
            } else {
              total_de_horas = `0${total_h1}`;
            }

            if (total_m1 >= 10) {
              total_de_horas += `:${total_m1}`;
            } else {
              total_de_horas += `:0${total_m1}`;

              if (Number(horas_decimal) === 0) {
                horas_decimal = "0.00";
              }
            }

            durations.push(total_de_horas);

            // general info and index adjustments
            // busca o valor individual cobrado pela HORA
            await api
              .post(
                `/reports/hour/general/referencevalues/false/${referenceId}`,
                state
              )
              .then((responseRef) => {
                valor_categoria = Number(responseRef.data[0]?.horas_valor_hora);
                globalCategoryValue.current = Number(
                  responseRef.data[0]?.horas_valor_hora
                );
              });

            total_do_periodo += round(
              (nonBilledValue + Number(horas_decimal)) * valor_categoria,
              2
            );

            horas_decimal_total += Number(horas_decimal);

            if (ind === response.data.length - 1) {
              const newPeriodValue =
                Number(round(total_do_periodo, 2)) +
                Number(adjustment.current.replace(",", ".")) *
                  Number(globalCategoryValue.current);
              const formattedPeriod = newPeriodValue?.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const formattedCategoryValue = valor_categoria?.toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );
              const formattedtotalDecimalTime =
                horas_decimal_total?.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              const formattedNonBilledValue =
                total_ajuste_decimal?.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

              const newTotalValue =
                Number(round(newPeriodValue, 2)) +
                Number(enteredAdjustmentValue.current);

              const formattedAddedTotalValue = newTotalValue?.toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );

              adjustment.current = formattedNonBilledValue;
              if (formattedNonBilledValue === "0,00") {
                adjustment.current = "";
              }

              setValuePerHour(formattedCategoryValue);
              setTotalValuePeriod(formattedPeriod);
              setTotalDecimalTime(formattedtotalDecimalTime);
              setTotalAddedValuePeriod(formattedAddedTotalValue);
            }
            ind += 1;
          });
          if (response.data[0].horas_fatu_ajuste) {
            const newValue =
              Number(total_do_periodo) +
              Number(response.data[0].horas_fatu_ajuste);
            enteredAdjustmentValue.current = newValue;
          }

          // summary info displayed after datatable
          const ms = durations.map(
            (d) => moment.duration(`${d}:00`).asSeconds() * 1000
          );
          const sum = ms.reduce((prev, cur) => prev + cur, 0);

          const d = moment.duration(sum, "milliseconds");
          const hours = Math.floor(d.asHours());
          const minutes = (Math.floor(d.asMinutes()) - hours * 60)
            .toString()
            .padStart(2, "0");
          const time = `${hours}:${minutes}`;

          setTotalTime(time);
        });

        if (tempSelectedHour) {
          let searchHours: IBilledHours[] = [];
          searchHours = JSON.parse(tempSelectedHour);
          setSelectedHours(searchHours);
        }

        setLoading(false);
      } catch (err) {
        addToast({
          type: "info",
          title: "Sem resultados!",
          description:
            "A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.",
        });
        setLoading(false);
      }
    })();
  }, [addToast, state, tempSelectedHour]);

  const hidedeleteHourDialog = () => {
    setDeleteHourDialog(false);
  };

  const editHour = (rowData: { horas_id: number }) => {
    history.push(`/management/workinghour/edit/${rowData.horas_id}`);
    localStorage.setItem("@Jurisnet:hourreporttab", "1");
    localStorage.setItem("@Jurisnet:ajustevalores", "true");
  };

  const deleteHour = async () => {
    api.delete(`/management/workinghours/delete/${billedHour.horas_id}`);

    setDeleteHourDialog(false);

    const updatedRecords = billedHours.filter(
      (val) => val.horas_id !== billedHour.horas_id
    );
    setBilledHours(updatedRecords);

    addToast({
      type: "success",
      title: "Cadastro excluído!",
      description: "O cadastro selecionado foi excluído com sucesso!",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteHour = (
    billedHour: React.SetStateAction<{
      row_number: number;
      horas_id: number;
      horas_cod_cliente: number;
      horas_cod_referencia: number;
      horas_descricao: string;
      horas_data: Date;
      horas_hora_inicial: Date;
      horas_hora_final: Date;
      horas_documento: string;
      horas_data_alteracao: Date;
      horas_hora_total: string;
      horas_tipo: string;
    }>
  ) => {
    setBilledHour(billedHour);
    setDeleteHourDialog(true);
  };

  const deleteHourFooter = (
    <>
      <Button
        type="button"
        label="Não"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteHourDialog}
      />
      <Button
        type="button"
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteHour}
      />
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => {
    if (rowData.proc_cobrar_por_hora === "N") {
      return (
        <span style={{ color: "red" }}>Cobrança por hora não autorizada</span>
      );
    }
    return (
      <>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => {
            editHour(rowData);
          }}
          tooltip="Alterar cadastro"
          tooltipOptions={{ position: "left" }}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => {
            confirmDeleteHour(rowData);
          }}
          tooltip="Excluir cadastro"
          tooltipOptions={{ className: "red-tooltip", position: "left" }}
        />
        <Button
          type="button"
          icon="pi pi-check-circle"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => editHour(rowData)}
          tooltip="Ajustar valores"
          tooltipOptions={{ position: "left" }}
          label={rowData.billed}
        />
        {state.doc ? (
          <Button
            type="button"
            icon="pi pi-money-bill"
            className="p-button-rounded p-button-text p-button-plain p-button-success"
            tooltip="Hora já faturada"
            tooltipOptions={{ position: "left" }}
          />
        ) : (
          <Button
            type="button"
            icon="pi pi-money-bill"
            className="p-button-rounded p-button-text p-button-plain p-button-danger"
            tooltip="Hora NÃO faturada"
            tooltipOptions={{ position: "left" }}
          />
        )}
      </>
    );
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar na tabela"
        />
      </span>
    </div>
  );

  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  const hourBodyTemplate = (rowData: { horas_hora_total: string }) => {
    const currentDuration: string[] = [];
    let horas_total = "";
    let total_de_horas = "";
    let horas_decimal = "0";
    let total_h1 = 0;
    let total_m1 = 0;

    if (!rowData.horas_hora_total) {
      horas_total = "0:00";
    } else {
      const horas_total_tmp = rowData.horas_hora_total.split(":");

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
            .toFixed(0)
            .toString();
        } else {
          horas_total += `0${(Number(horas_total_tmp[1]) / 60) * 100}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += "00";
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(
          ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
        );
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = Number(horas_total_tmp[0]).toString();
      } else {
        horas_decimal = "0";
      }

      const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);

        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += ".00";
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = "0.00";
      }
    }

    currentDuration.push(total_de_horas);

    const ms = currentDuration.map(
      (d) => moment.duration(`${d}:00`).asSeconds() * 1000
    );
    const sum = ms.reduce((prev, cur) => prev + cur, 0);

    const d = moment.duration(sum, "milliseconds");
    const hours = Math.floor(d.asHours());
    const minutes = (Math.floor(d.asMinutes()) - hours * 60)
      .toString()
      .padStart(2, "0");
    const time = `${hours}:${minutes}`;

    return time;
  };

  const decimalBodyTemplate = (rowData: { horas_hora_total: string }) => {
    const cleanDuration = convertDecimalToHour(rowData.horas_hora_total);

    const totalHoursArray = cleanDuration.split(":");
    const cleanMinutes =
      Number(totalHoursArray[0]) * 60 + Number(totalHoursArray[1]);

    return (cleanMinutes / 60)?.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 1,
    });
  };

  const totalDecimalBodyTemplate = (rowData: {
    valor_ajuste_nao_faturado: number;
  }) => {
    const newValue = rowData.valor_ajuste_nao_faturado
      ?.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
      })
      .replace(".", ",");
    return newValue;
  };

  const checkboxBodyTemplate = (rowData: {
    horas_id: number;
    proc_cobrar_por_hora: string;
  }) => {
    if (billedHours !== undefined) {
      if (rowData.proc_cobrar_por_hora === "N") {
        return "Nao";
      } else {
        return (
          <Checkbox
            inputId={`${rowData.horas_id.toString()}`}
            name={`cb${rowData.horas_id.toString()}`}
            value={selectedHours}
            checked={false}
          />
        );
      }
    }
  };

  const personInChargeBodyTemplate = (rowData: {
    advogado_nome: string;
    administrador_nome: string;
  }) => {
    if (billedHours !== undefined) {
      const filteredAttorney = billedHours.filter(
        (item) => item.advogado_nome === rowData.advogado_nome,
        []
      );

      if (filteredAttorney.length > 0) {
        if (filteredAttorney[0].advogado_nome) {
          return filteredAttorney[0].advogado_nome;
        }
        const filteredAssistant = billedHours.filter(
          (item) => item.administrador_nome === rowData.administrador_nome,
          []
        );

        return filteredAssistant[0].administrador_nome;
      }
    }

    return "";
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total de horas:"
          colSpan={7}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={totalTime} footerStyle={{ textAlign: "center" }} />
        <Column
          footer={totalDecimalTime}
          footerStyle={{ textAlign: "center" }}
        />
        {adjustment.current.length > 0 && adjustment.current !== "0,00" ? (
          <Column
            footer={adjustment.current}
            footerStyle={{ textAlign: "center" }}
          />
        ) : null}
        <Column colSpan={2} />
      </Row>
    </ColumnGroup>
  );

  const printSelectedHours = () => {
    if (!selectedHours) {
      addToast({
        type: "error",
        title: "Nenhuma hora selecionada!",
        description:
          "Por favor, selecione pelo menos 1 registro para enviar as informações!.",
      });
    } else {
      localStorage.setItem(
        "@Jurisnet:selectedhour",
        JSON.stringify(selectedHours)
      );
      localStorage.setItem(
        "@Jurisnet:valorajuste",
        JSON.stringify(enteredAdjustmentValue.current.toString())
      );
      localStorage.setItem("@Jurisnet:hourreporttab", "1");
      history.push({
        pathname: "/report/hour/general/selectedhour",
        state,
      });
    }
  };

  const invoiceHours = () => {
    if (!selectedHours) {
      addToast({
        type: "error",
        title: "Nenhuma hora selecionada!",
        description:
          "Por favor, selecione pelo menos 1 registro para enviar as informações!.",
      });
    } else {
      localStorage.setItem("@Jurisnet:invoice", "true");
      localStorage.setItem(
        "@Jurisnet:valorajuste",
        JSON.stringify(enteredAdjustmentValue.current.toString())
      );
      localStorage.setItem(
        "@Jurisnet:invoicestart",
        JSON.stringify(initialSearchDate)
      );
      localStorage.setItem(
        "@Jurisnet:invoiceend",
        JSON.stringify(finalSearchDate)
      );
      localStorage.setItem(
        "@Jurisnet:selectedhour",
        JSON.stringify(selectedHours)
      );

      applyAdjustmentValue();

      history.push({
        pathname: "/report/hour/general/selectedhour",
        state,
      });
    }
  };

  const applyAdjustmentValue = async () => {
    if (enteredAdjustmentValue.current !== 0) {
      let billedHoursIds = "";

      if (billedHours.length > 0) {
        billedHours.forEach((item: IBilledHours) => {
          if (billedHoursIds === "") {
            billedHoursIds = `(${item.horas_id})`;
          } else {
            billedHoursIds += `,(${item.horas_id})`;
          }
        });
      }

      const formData = {
        enteredAdjustmentValue: enteredAdjustmentValue.current,
        apply: true,
        selectedHours: billedHoursIds,
      };

      await api
        .put("/management/workinghours/patch/adjustmentvalue", formData)
        .then(() => {
          const newTotalValue =
            Number(round(Number(totalValuePeriod), 2)) +
            Number(
              enteredAdjustmentValue.current.toString().replace(",", ".")
            ) +
            Number(adjustment.current.replace(",", ".")) *
              Number(globalCategoryValue.current);

          const newTotalValueString = newTotalValue?.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            minimumIntegerDigits: 1,
          });
          setTotalAddedValuePeriod(newTotalValueString);

          addToast({
            type: "success",
            title: "Valor aplicado",
            description: "O valor do ajuste financeiro foi aplicado.",
          });
          toast.current?.show({
            severity: "success",
            summary: "Valor aplicado",
            detail: "O valor do ajuste financeiro foi aplicado.",
            life: 3000,
          });
        });
    } else {
      addToast({
        type: "error",
        title: "Nenhum valor informado",
        description: "Informe um valor para aplicar o ajuste financeiro.",
      });
      toast.current?.show({
        severity: "error",
        summary: "Nenhum valor informado",
        detail: "Informe um valor para aplicar o ajuste financeiro.",
        life: 3000,
      });
    }
  };

  const cancelAdjustmentValue = async () => {
    const formData = {
      adjustment: null,
      apply: false,
      selectedHours: "",
    };

    await api
      .put("/management/workinghours/patch/adjustmentvalue", formData)
      .then(() => {
        addToast({
          type: "success",
          title: "Valor removido",
          description: "O valor do ajuste financeiro foi removido.",
        });
        toast.current?.show({
          severity: "success",
          summary: "Valor removido",
          detail: "O valor do ajuste financeiro foi removido.",
          life: 3000,
        });
      });
  };

  const [referenceValues, setReferenceValues] = useState<IReferenceValues[]>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [referenceValue, setReferenceValue] = useState(0.0);

  useEffect(() => {
    (async () => {
      try {
        await api
          .post("/reports/hour/general/referencevalues/false/0", state)
          .then((response) => {
            setReferenceValues(response.data);
            localStorage.setItem(
              "@Jurisnet:valorhora",
              JSON.stringify(response.data[0]?.horas_valor_hora)
            );
          });
      } catch (err) {
        addToast({
          type: "info",
          title: "Sem resultados!",
          description:
            "A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.",
        });
      }
    })();
  }, [addToast, location, state]);

  let documentNumber = "";
  if (state) {
    documentNumber = state?.doc;
  }

  const updateHourCurrencyValue = async (horas_cod_referencia: number) => {
    let billedHoursIds = "";

    if (billedHours.length > 0) {
      billedHours.forEach((item: IBilledHours) => {
        if (billedHoursIds === "") {
          billedHoursIds = `(${item.horas_id})`;
        } else {
          billedHoursIds += `,(${item.horas_id})`;
        }
      });
    }

    const newValue = localStorage.getItem("@Jurisnet:referenceValue");
    let hourValue = 0;
    if (newValue) {
      hourValue = Number(newValue);
    }

    const formData = {
      horas_valor_hora: hourValue,
      doc: documentNumber,
      selectedHours: billedHoursIds,
    };

    let hourReference = 0;
    if (horas_cod_referencia !== undefined) {
      hourReference = horas_cod_referencia;
    }
    await api
      .patch(`/management/workinghours/patch/${hourReference}`, formData)
      .then(() => {
        localStorage.removeItem("@Jurisnet:referenceValue");
        localStorage.setItem("@Jurisnet:hourreporttab", "1");
        history.go(0);
      });
  };

  const rowClass = (data: IBilledHours) => {
    return {
      "bg-bluegray-300": data.proc_cobrar_por_hora === "N",
    };
  };

  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <div className="datatable-responsive">
        <div className="p-toolbar p-component p-mb-20" role="toolbar">
          <div className="p-toolbar-group-left">
            <h3>Referência de valores</h3>
          </div>
        </div>
        <table
          key={nanoid()}
          width="500"
          className="referencevalues-table"
          id="referencevalues-table"
        >
          <thead>
            <tr key={nanoid()}>
              <th>Referência</th>
              <th>Valor (R$)</th>
            </tr>
          </thead>
          <tbody>
            {referenceValues.map((value) => (
              <tr
                key={nanoid()}
                className="py-2 px-2 border-top-1 border-300 flex-wrap"
              >
                <td width="250" className="text-500 w-full font-medium">
                  {value.horas_referencia_nome
                    ? value.horas_referencia_nome
                    : " "}
                </td>
                <td
                  width="80"
                  className="text-900 w-full md:flex-order-0 flex-order-1 line-height-3"
                >
                  <InputNumber
                    id="horas_valor_hora"
                    name="horas_valor_hora"
                    className="inputfield w-full"
                    value={referenceValue || value.horas_valor_hora}
                    onValueChange={(e) => {
                      if (e.value) {
                        setReferenceValue(e.value);
                        localStorage.setItem(
                          "@Jurisnet:referenceValue",
                          e.value.toString()
                        );
                      }
                    }}
                    placeholder="0,00"
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    locale="pt-BR"
                  />
                </td>
                <td width="40">
                  <Button
                    type="button"
                    icon="pi pi-check-circle"
                    className="p-button-rounded p-button-text p-button-plain btn-value-update"
                    onClick={() => {
                      updateHourCurrencyValue(value.horas_cod_referencia);
                    }}
                    tooltip="Atualizar valores de referência"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <hr />

        <div className="p-toolbar p-component p-mb-20" role="toolbar">
          <div className="p-toolbar-group-left">
            <h3>
              Relação de horas para o período selecionado ({initialSearchDate}{" "}
              até {finalSearchDate})
            </h3>
          </div>
          <div className="p-toolbar-group-right">
            <Button
              type="button"
              icon="pi pi-chevron-circle-down"
              onClick={() => {
                const element = document.querySelector("#table_summary");
                if (element) {
                  element.scrollIntoView();
                }
              }}
              label="Visualizar resumo horas"
              className="p-button-primary mr-2"
              disabled={loading}
            />
          </div>
        </div>

        <Tooltip target=".export-buttons>button" position="bottom" />
        <DataTable
          tableClassName="table-billedhours table"
          id="table-billedhours"
          ref={dt}
          loading={loading}
          value={billedHours}
          editMode="row"
          dataKey="horas_id"
          header={header}
          stripedRows
          rowClassName={rowClass}
          className="p-datatable-sm table-pendinghours"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
          rows={billedHours.length}
          rowsPerPageOptions={[10, 20, 50, billedHours.length]}
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          globalFilter={globalFilter}
          emptyMessage="Nenhum registro encontrado."
          responsiveLayout="scroll"
          selectionMode="checkbox"
          selection={selectedHours}
          onSelectionChange={(e) => {
            const selHours = e.value as IBilledHours[];
            setSelectedHours(selHours);
          }}
          footerColumnGroup={footerGroup}
        >
          <Column
            key="row_number"
            field="row_number"
            header="Núm."
            alignHeader="center"
            body={counterBodyTemplate}
            align="center"
            style={{ width: "50px" }}
          />
          <Column
            key="hora_data"
            field="hora_data"
            header="Data"
            alignHeader="center"
            align="left"
            style={{ width: "100px" }}
          />
          <Column
            field="advogado_nome"
            header="Responsável"
            alignHeader="center"
            body={personInChargeBodyTemplate}
            align="left"
            style={{ width: "140px" }}
          />
          <Column
            field="cliente_nome"
            header="Cliente"
            alignHeader="center"
            align="left"
            style={{ width: "140px" }}
          />
          <Column
            field="horas_referencia_nome"
            header="Referência"
            alignHeader="center"
            align="left"
            style={{ width: "80px" }}
          />
          <Column
            field="horas_descricao"
            header="Descrição"
            alignHeader="center"
            align="left"
          />
          <Column
            field="horas_tipo"
            header="Tipo"
            alignHeader="center"
            align="left"
            style={{ width: "100px" }}
          />
          <Column
            field="horas_hora_total"
            header="Horas"
            alignHeader="center"
            body={hourBodyTemplate}
            align="center"
            style={{ width: "80px" }}
          />
          <Column
            field="horas_decimal"
            header="Decimais"
            alignHeader="center"
            body={decimalBodyTemplate}
            align="center"
            style={{ width: "80px" }}
          />
          {adjustment.current.length > 0 && adjustment.current !== "0,00" ? (
            <Column
              field="valor_ajuste_nao_faturado"
              header="Ajustes Decimais"
              alignHeader="center"
              body={totalDecimalBodyTemplate}
              align="center"
              style={{ width: "80px" }}
            />
          ) : null}
          <Column
            key="horas_id"
            field="horas_id"
            header="Enviar Inf."
            alignHeader="center"
            headerClassName="checkflex"
            body={checkboxBodyTemplate}
            selectionMode="multiple"
            align="center"
            style={{ width: "90px" }}
          />
          <Column body={actionBodyTemplate} headerStyle={{ width: "200px" }} />
        </DataTable>

        <div className="grid summary-grid p-mt-10">
          <div className="col-3">
            <table id="table_summary">
              <tbody>
                <tr>
                  <th colSpan={2} className="stripedRows">
                    Resumo horas trabalhadas
                  </th>
                </tr>
                <tr>
                  <td width="240">Total de horas</td>
                  <td width="100" align="right">
                    {totalTime}
                  </td>
                </tr>
                <tr className="stripedRows">
                  <td width="240">Total de horas (decimais)</td>
                  <td width="100" align="right">
                    {totalDecimalTime}
                  </td>
                </tr>
                {adjustment.current.length > 0 &&
                adjustment.current !== "0,00" ? (
                  <>
                    <tr>
                      <td width="240">Ajuste (decimais)</td>
                      <td width="100" align="right">
                        {adjustment.current}
                      </td>
                    </tr>
                  </>
                ) : null}
                <tr
                  className={
                    adjustment.current.length > 0 &&
                    adjustment.current !== "0,00"
                      ? "stripedRows"
                      : ""
                  }
                >
                  <td width="240">Valor cobrado por hora</td>
                  <td width="100" align="right">
                    {valuePerHour}
                  </td>
                </tr>
                <tr
                  className={
                    adjustment.current.length > 0 &&
                    adjustment.current !== "0,00"
                      ? ""
                      : "stripedRows"
                  }
                >
                  <td width="240">Total do período (R$)</td>
                  <td width="100" align="right">
                    <span id="totalvalue">{totalValuePeriod}</span>
                  </td>
                </tr>
                <tr
                  className={
                    adjustment.current.length > 0 &&
                    adjustment.current !== "0,00"
                      ? "stripedRows"
                      : ""
                  }
                >
                  <td width="240">Ajuste (R$)</td>
                  <td width="100" align="right">
                    <InputNumber
                      id="adjustment_value"
                      name="adjustment_value"
                      className="inputfield input-adjustment"
                      value={enteredAdjustmentValue.current}
                      onValueChange={(e) => {
                        if (e.value !== null && e.value !== undefined) {
                          enteredAdjustmentValue.current = e.value;
                        }
                        const formattedValue = totalValuePeriod
                          .replaceAll(".", "")
                          .replaceAll(",", ".");
                        const newTotalValue =
                          Number(round(Number(formattedValue), 2)) +
                          Number(
                            enteredAdjustmentValue.current
                              .toString()
                              .replace(",", ".")
                          ) +
                          Number(adjustment.current.replace(",", ".")) *
                            Number(globalCategoryValue.current);
                        const newTotalValueString =
                          newTotalValue?.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          });
                        setTotalAddedValuePeriod(newTotalValueString);
                      }}
                      placeholder="0,00"
                      mode="decimal"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      locale="pt-BR"
                      style={{ textAlign: "right" }}
                    />
                  </td>
                </tr>
                <tr
                  className={
                    adjustment.current.length > 0 &&
                    adjustment.current !== "0,00"
                      ? ""
                      : "stripedRows"
                  }
                >
                  <td width="240">Valor Final (R$)</td>
                  <td width="100" align="right">
                    {totalAddedValuePeriod}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col-12 md:col-12 reducedPadding">
              <small id="adjustment-help" className="block">
                Digite o valor monetário para simular o ajuste e em{" "}
                <strong>Aplicar ajuste</strong> para salvar.
              </small>
            </div>
            <div className="col-12 md:col-12 reducedPadding">
              <Button
                icon="pi pi-upload"
                iconPos="left"
                label="Imprimir/Exportar Selecionados"
                className="p-button-info w-full"
                type="button"
                onClick={printSelectedHours}
              />
            </div>
            <div className="col-12 md:col-12 reducedPadding equal-spacing">
              <Button
                icon="pi pi-check"
                iconPos="left"
                label="Aplicar ajuste"
                className="p-button-secondary"
                type="button"
                onClick={applyAdjustmentValue}
              />
              <Button
                icon="pi pi-times"
                iconPos="left"
                label="Cancelar ajuste"
                className="p-button-secondary"
                type="button"
                onClick={cancelAdjustmentValue}
              />
            </div>

            <div className="col-12 md:col-12 reducedPadding">
              <Button
                icon="pi pi-money-bill"
                iconPos="left"
                label="Faturar horas"
                className="p-button-info w-full"
                type="button"
                onClick={invoiceHours}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Dialog
          visible={deleteHourDialog}
          style={{ width: "450px" }}
          header="Confirmação"
          modal
          footer={deleteHourFooter}
          onHide={hidedeleteHourDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {billedHours && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{billedHour.horas_descricao}</b>?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    </Container>
  );
};

export default HourManagementReport;
