import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

 interface ICustomerProperties {
  cliente_id: number | null;
  cliente_nome: string;
  cliente_login: string;
  cliente_senha: string;
  cliente_email: string;
  cliente_contato: string;
  cliente_endereco: string;
  cliente_cidade: string;
  cliente_bairro: string;
  cliente_cep: string;
  cliente_uf: string;
  cliente_fone: string;
  cliente_fax: string;
  cliente_url: string;
  cliente_classificacao: number;
  cliente_categoria: number;
  cliente_ativo: boolean;
  cliente_tratamento: string;
  cliente_cnpj_cpf: string;
  cliente_naocliente: boolean;
  cliente_informativo: boolean;
}

const Customer: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyCustomer = {
    cliente_id: null,
    cliente_nome: '',
    cliente_login: '',
    cliente_senha: '',
    cliente_email: '',
    cliente_contato: '',
    cliente_endereco: '',
    cliente_cidade: '',
    cliente_bairro: '',
    cliente_cep: '',
    cliente_uf: '',
    cliente_fone: '',
    cliente_fax: '',
    cliente_url: '',
    cliente_classificacao: 0,
    cliente_categoria: 0,
    cliente_ativo: true,
    cliente_tratamento: '',
    cliente_cnpj_cpf: '',
    cliente_naocliente: false,
    cliente_informativo: false,
  };

  const [customers, setCustomers] = useState<ICustomerProperties[]>([]);
  const [customer, setCustomer] = useState(emptyCustomer);
  const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
  const [deleteCustomersDialog, setDeleteCustomersDialog] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState<ICustomerProperties[]>([]);
  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await api.get('/customers/show/all').then((response) => {
        setCustomers(response.data);
      });
    })();
  }, []);

  const columns = [
    { field: 'cliente_id', header: 'Código', sortable: true },
    { field: 'cliente_nome', header: 'Nome', sortable: true },
    { field: 'cliente_contato', header: 'Pessoa contato', sortable: true },
    { field: 'cliente_login', header: 'Login', sortable: true },
    { field: 'cliente_email', header: 'E-mail', sortable: true },
    { field: 'cliente_fone', header: 'Telefone', sortable: true },

  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable
    />
  ));

  const handleCreate = () => {
    history.push('/customer/create');
  };

  const hideDeleteCustomerDialog = () => {
    setDeleteCustomerDialog(false);
  };

  const hideDeleteCustomersDialog = () => {
    setDeleteCustomersDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editCustomer = (rowData:any) => {
    history.push(`/customer/edit/${rowData.cliente_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteCustomer = (customer: React.SetStateAction<{
    cliente_id: null; cliente_nome: string;
    cliente_login: string; cliente_senha: string; cliente_email: string; cliente_contato: string;
    cliente_endereco: string; cliente_cidade: string; cliente_bairro: string; cliente_cep: string;
    cliente_uf: string; cliente_fone: string; cliente_fax: string; cliente_url: string;
    cliente_classificacao: number; cliente_categoria: number; cliente_ativo: boolean;
    cliente_tratamento: string; cliente_cnpj_cpf: string; cliente_naocliente: boolean;
    cliente_informativo: boolean;
  }>) => {
    setCustomer(customer);
    setDeleteCustomerDialog(true);
  };

  const deleteCustomer = () => {
    api.delete(`customers/delete/${customer.cliente_id}`);

    setDeleteCustomerDialog(false);

    const updatedRecords = customers
      .filter((val) => val.cliente_id !== customer.cliente_id);
    setCustomers(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editCustomer(rowData); }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { confirmDeleteCustomer(rowData); }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
      />
      <Button
        type="submit"
        icon={rowData.cliente_ativo === true ? 'pi pi-eye' : 'pi pi-eye-slash'}
        className={rowData.cliente_ativo === true ? 'p-button-rounded p-button-text p-button-plain p-button-success' : 'p-button-rounded p-button-text p-button-plain p-button-danger'}
        onClick={() => { changeVisibilityCustomer(rowData); }}
        tooltip={`Tornar cadastro assistente/estagiário ${rowData.cliente_ativo === true ? 'inativo' : 'ativo'} no sistema`}
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  // const handleCreateUsers = () => {
  //   (async () => {
  //     await api.get('/customers/show/all').then((response) => {
  //       response.data.forEach((item: {
  //         cliente_email: string; cliente_nome: string;
  //         cliente_senha: string; cliente_login: string;
  //       }) => {
  //         // if (item.cliente_email) {
  //         const userFormData = {
  //           name: item.cliente_nome,
  //           login_name: item.cliente_login,
  //           password: '12345678', // item.cliente_senha,
  //           email: item.cliente_email.includes('@') ? item.cliente_email
  // : `${item.cliente_login}@naoexiste.email`,
  //           active: true,
  //           user_level: 3,
  //         };

  //         api.post('/users', userFormData);
  //         // }
  //       });
  //     });
  //   })();
  //   addToast({
  //     type: 'success',
  //     title: 'Sincronização concluída!',
  //     description:
  //       'Importação foi realizada com sucesso!',
  //   });
  // };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      {/* <Button
        type="button"
        label="Importar acessos para JN 2"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreateUsers}
        tooltip="Importa somente cadastros com e-mails"
        tooltipOptions={{ position: 'right' }}
      /> */}
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteCustomerDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomerDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteCustomer} />
    </>
  );
  const deleteCustomersDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomersDialog} />
      {/* <Button label="Sim" icon="pi pi-check" className="p-button-text"
      onClick={deleteSelectedCustomers} /> */}
    </>
  );

  const changeVisibilityCustomer = async (rowData: {
    cliente_id: number; cliente_ativo: boolean;
    cliente_email: string; cliente_login: string;
  }) => {
    await api.patch(`customers/patch/${rowData.cliente_id}/${rowData.cliente_ativo}`);

    (async () => {
      await api.get('/customers/show/all').then((response) => {
        setCustomers(response.data);
      });
    })();

    const formData = {
      active: rowData.cliente_ativo,
      login_name: rowData.cliente_login,
    };
    await api.patch('/users/patch/access', formData);
  };

  return (
    <Container>
      <header>
        <div>
          <h1>Clientes</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-customers table"
            id="table-customers"
            ref={dt}
            value={customers}
            editMode="row"
            dataKey="cliente_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedCustomers}
            onSelectionChange={(e) => {
              const selCustomers = e.value as ICustomerProperties[];
              setSelectedCustomers(selCustomers);
            }}
            onRowDoubleClick={(e) => editCustomer(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '150px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteCustomerDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteCustomerDialogFooter} onHide={hideDeleteCustomerDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {customer && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{customer.cliente_nome}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteCustomersDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteCustomersDialogFooter} onHide={hideDeleteCustomersDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {customer
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default Customer;
