import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

 interface ILawsuitProperties {
  gerencial_id: number;
  proc_id: number;
  proc_reg: string;
  proc_num_pasta: string;
  proc_orgao_cidade: string;
  proc_orgao_uf: string;
  proc_autor: string;
  proc_reu: string;
  proc_assunto: string;
  proc_cod_cliente: number;
  proc_cod_adv_resp: number;
  proc_data_ajuiz: Date;
  orgao_id: number;
  orgao_descricao: string;
  origem: string;
}

const ManagementList: React.FC = () => {
  PrimeReact.ripple = true;

  const emptyManagement = {
    gerencial_id: 0,
    proc_id: 0,
    proc_reg: '',
    proc_num_pasta: '',
    proc_orgao_cidade: '',
    proc_orgao_uf: '',
    proc_autor: '',
    proc_reu: '',
    proc_assunto: '',
    proc_cod_cliente: 0,
    proc_cod_adv_resp: 0,
    proc_data_ajuiz: null,
    orgao_id: 0,
    orgao_descricao: '',
    origem: '',
  };

  const [management, setManagement] = useState<ILawsuitProperties[]>([]);
  const [singleManagement, setSingleManagement] = useState(emptyManagement);
  const [loading, setLoading] = useState(true);
  // const [deleteManagement, setDeleteManagement] = useState(false);
  const [deleteManagementDialog, setDeleteManagementDialog] = useState(false);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  useEffect(() => {
    (async () => {
      try {
        localStorage.removeItem('@Jurisnet:managementcriteria');
        const returnMagement = localStorage.getItem('@Jurisnet:management');
        let searchCriteria = state;
        let isManagement = false;
        if (returnMagement) {
          isManagement = true;
          localStorage.removeItem('@Jurisnet:management');
        }
        if (isManagement) {
          const managementCriteria = localStorage.getItem('@Jurisnet:managementcriteria');
          if (managementCriteria) {
            searchCriteria = JSON.parse(managementCriteria);
          }
        }

        await api.post('/management/list/search', searchCriteria).then((response) => {
          setManagement(response.data);
        });
        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados.',
        });
      }
    })();
  }, [addToast, location, state]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editManagement = (rowData:any) => {
    localStorage.setItem('@Jurisnet:selectedmanagement', JSON.stringify(rowData));
    history.push(`/management/edit/${rowData.gerencial_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLawsuit = (rowData:any) => {
    localStorage.setItem('@Jurisnet:management', 'true');
    localStorage.setItem('@Jurisnet:managementcriteria', JSON.stringify(state));
    history.push(`/lawsuit/main/edit/${rowData.proc_id}`);
  };

  const hideDeleteManagement = () => {
    setDeleteManagementDialog(false);
  };

  const hideDeleteManagementDialog = () => {
    setDeleteManagementDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteManagement = (management: React.SetStateAction<{
    gerencial_id: number; proc_id: number; proc_reg: string; proc_num_pasta: string;
    proc_orgao_cidade: string; proc_orgao_uf: string; proc_autor: string; proc_reu: string;
    proc_assunto: string; proc_cod_cliente: number; proc_cod_adv_resp: number;
    proc_data_ajuiz: null; orgao_id: number; orgao_descricao: string; origem: string; }>) => {
    setSingleManagement(management);
    setDeleteManagementDialog(true);
  };

  const deleteManagementRecord = () => {
    api.delete(`/management/delete/${singleManagement.gerencial_id}`);

    setDeleteManagementDialog(false);

    const updatedRecords = management
      .filter((val) => val.gerencial_id !== singleManagement.gerencial_id);
    setManagement(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const deleteManagementFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteManagement} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteManagementRecord} />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editManagement(rowData); }}
        tooltip="Alterar cadastro gerencial"
        tooltipOptions={{ position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteManagement(rowData); }}
        tooltip="Excluir cadastro gerencial"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-briefcase"
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { editLawsuit(rowData); }}
        tooltip="Alterar processo"
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  return (
    <Container>
      <header>
        <div>
          <h3>PABST & HADLICH ADVOGADOS ASSOCIADOS</h3>
          <h4>Pesquisa Gerencial</h4>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-lawsuits table"
            id="table-lawsuits"
            ref={dt}
            loading={loading}
            value={management}
            editMode="row"
            dataKey="proc_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={management.length}
            rowsPerPageOptions={[10, 20, 50, management.length]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            onRowDoubleClick={(e) => editManagement(e.data)}
            responsiveLayout="scroll"
          >
            <Column key="row_number" field="row_number" header="Núm." alignHeader="center" body={counterBodyTemplate} align="center" style={{ width: '50px' }} />
            <Column field="proc_num_pasta" header="Pasta nº" alignHeader="center" align="left" style={{ width: '80px' }} />
            <Column field="proc_autor" header="Autor(es)" alignHeader="center" align="left" style={{ width: '150px' }} />
            <Column field="proc_reu" header="Réu(s)" alignHeader="center" align="left" style={{ width: '200px' }} />
            <Column field="proc_reg" header="Processo n°" alignHeader="center" align="left" style={{ width: '200px' }} />
            <Column field="proc_assunto" header="Assunto" alignHeader="center" align="left" />
            <Column field="origem" header="Origem" alignHeader="center" align="left" style={{ width: '140px' }} />
            <Column body={actionBodyTemplate} style={{ width: '160px' }} />
          </DataTable>
        </div>

        <div>
          <Dialog visible={deleteManagementDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteManagementFooter} onHide={hideDeleteManagementDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {singleManagement && (
                <span>
                  Você tem certeza que deseja excluir
                  <br />
                  <br />
                  <b>{singleManagement.proc_reg}</b>
                  ?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

    </Container>

  );
};

export default ManagementList;
