/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from '../styles';

interface SearchData {
  proc_cod_cliente: number;
  proc_em_acordo: string;
}

const SearchLawsuitSummaryExcel:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  sessionStorage;

  const initialValues: SearchData = {
    proc_cod_cliente: 0,
    proc_em_acordo: '0',

  };

  const [customerId, setCustomerId] = useState('0');
  const [lawsuitStatus, setLawsuitStatus] = useState('0');
  const [customersOptions, setCustomersOptions] = useState([{}]);

  const validationSchema = null;

  const lawsuitStatusOptions = [
    { name: 'ativos', value: '0', style: 'font-size: small;' },
    { name: 'arquivados', value: '2', style: 'font-size: small;' },
    { name: 'todos', value: '5', style: 'font-size: small;' },
  ];

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      const searchFormData = {
        proc_cod_cliente: customerId,
        proc_em_acordo: lawsuitStatus,
      };

      history.push({
        pathname: '/report/lawsuit/simplified/excel',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };

  return (
    <Container>
      <div className="card">
        <header>
          <h3>Relatório Resumido de Informações Processuais - Filtro Excel</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-6 colum-spacer-right">
                  <div className="col-12 md:col-12">
                    <label htmlFor="proc_cliente">
                      Cliente
                    </label>
                    <Dropdown
                      id="proc_cliente"
                      name="proc_cliente"
                      options={customersOptions}
                      onChange={(e) => setCustomerId(e.target.value)}
                      value={customerId}
                      className="inputfield w-full"
                      optionLabel="name"
                      optionValue="value"
                      filter
                      filterBy="name"
                      placeholder="Selecione uma opção"
                      emptyFilterMessage="Nenhum registro encontrado"
                    />
                  </div>
                  <div className="col-12 md:col-12">
                    <label htmlFor="proc_em_acordo">
                      Incluir processos
                    </label>
                    <SelectButton
                      id="proc_em_acordo"
                      value={lawsuitStatus}
                      options={lawsuitStatusOptions}
                      optionLabel="name"
                      onChange={(e) => setLawsuitStatus(e.value)}
                      style={{ padding: '0.5rem 0.79rem' }}
                    />
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchLawsuitSummaryExcel;
