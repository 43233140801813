import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from '../styles';
import api from '../../../../services/api';

interface IPawnedGoods {
  dep_cus_id: number | null;
  dep_cus_proc_id: number;
  dep_cus_data: Date;
  dep_cus_cod_natureza: number;
  dep_cus_valor: number;
  dep_cus_classificacao: number;
  bens_penh_id: number | null;
  bens_penh_proc_id: number;
  bens_penh_descricao: string;
  bens_penh_parte: number;
  bens_penh_data: Date;
  bens_penh_valor: number;
  bens_penh_depositario: string;
}

const PawnedGoods: React.FC = () => {
  PrimeReact.ripple = true;

  const emptyPawnedGood = {
    dep_cus_id: 0,
    dep_cus_proc_id: 0,
    dep_cus_data: new Date(),
    dep_cus_cod_natureza: 0,
    dep_cus_valor: 0,
    dep_cus_classificacao: 0,
    bens_penh_id: 0,
    bens_penh_proc_id: 0,
    bens_penh_descricao: '',
    bens_penh_parte: 0,
    bens_penh_data: new Date(),
    bens_penh_valor: 0,
    bens_penh_depositario: '',
  };

  const [deletePawnedGood, setDeletePawnedGoodDialogs] = useState(false);

  const [pawnedGoods, setPawnedGoods] = useState<IPawnedGoods[]>([]);
  const [pawnedGood, setPawnedGood] = useState(emptyPawnedGood);

  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      await api.get(`/lawsuits/financialitems/pawnedgoods/show/filtered/${id}`).then((response) => {
        setPawnedGoods(response.data);
      });
    })();
  }, [id]);

  const formatCurrency = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  const bens_penh_valor_Total = () => {
    const total = pawnedGoods
      .map((value) => Number(value.bens_penh_valor))
      .reduce((a, b) => a + b, 0);
    return formatCurrency(total);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totais" footerStyle={{ textAlign: 'center' }} />
        <Column />
        <Column />
        <Column footer={bens_penh_valor_Total} footerStyle={{ textAlign: 'right' }} />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  const dateBodyTemplate = (rowData: {
    bens_penh_data: Date;
  }) => {
    if (pawnedGoods !== undefined) {
      const filteredDate = pawnedGoods.filter((item) => (
        item.bens_penh_data === rowData.bens_penh_data
      ), []);

      if (filteredDate[0]?.bens_penh_data !== null
        && filteredDate[0]?.bens_penh_data.toString() !== '01/01/1970'
        && filteredDate[0]?.bens_penh_data.toString() !== '31/12/1969') {
        const dateInput = filteredDate[0].bens_penh_data?.toString();

        const dateBR = new Date(dateInput);
        const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

        return formattedDate;
      }
      return '';
    }
    return '';
  };

  const partyBodyTemplate = (rowData: {
    bens_penh_parte: number;
  }) => {
    if (pawnedGoods !== undefined) {
      const filteredValue = pawnedGoods.filter((item) => (
        item.bens_penh_parte === rowData.bens_penh_parte
      ), []);

      if (filteredValue[0].bens_penh_parte !== 0) {
        switch (filteredValue[0].bens_penh_parte) {
          case 1:
            return 'Cliente';
            break;

          case 2:
            return 'Parte adversa';
            break;
          default:
            return '';
        }
      }
    }
    return '';
  };

  const value1BodyTemplate = (rowData: {
    bens_penh_valor: number;
  }) => {
    if (pawnedGoods !== undefined) {
      const filteredValue = pawnedGoods.filter((item) => (
        item.bens_penh_valor === rowData.bens_penh_valor
      ), []);

      if (filteredValue[0].bens_penh_valor !== 0) {
        const bens_penh_valor = Number(filteredValue[0].bens_penh_valor)
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        return bens_penh_valor;
      }
      return '0,00';
    }
    return '0,00';
  };

  const handleCreate = () => {
    history.push(`/lawsuit/financialitem/pawnedgoods/create?cod=${id}&active=2`);
  };

  const hidedeletePawnedGood = () => {
    setDeletePawnedGoodDialogs(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editPawnedGoods = (rowData:any) => {
    history.push(`/lawsuit/financialitem/pawnedgoods/edit/${rowData.bens_penh_id}?cod=${id}&active=2`);
  };

  const confirmDeletePawnedGoods = (good: React.SetStateAction<{
    dep_cus_id: number; dep_cus_proc_id: number; dep_cus_data: Date;
    dep_cus_cod_natureza: number; dep_cus_valor: number; dep_cus_classificacao: number;
    bens_penh_id: number; bens_penh_proc_id: number; bens_penh_descricao: string;
    bens_penh_parte: number; bens_penh_data: Date; bens_penh_valor: number;
    bens_penh_depositario: string; }>) => {
    setPawnedGood(good);
    setDeletePawnedGoodDialogs(true);
  };

  const deletePawnedGoods = () => {
    api.delete(`/lawsuits/financialitems/pawnedgoods/delete/${pawnedGood.bens_penh_id}`);

    setDeletePawnedGoodDialogs(false);

    const updatedRecords = pawnedGoods
      .filter((val) => val.bens_penh_id !== pawnedGood.bens_penh_id);
    setPawnedGoods(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const leftToolbarTemplate = () => (
    <>
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editPawnedGoods(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeletePawnedGoods(rowData); }}
      />
    </>
  );

  const deletePawnedGoodFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeletePawnedGood} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deletePawnedGoods} />
    </>
  );

  return (
    <Container>
      <div className="datatable-responsive" id="main-card">
        <div className="col-12 p-mt-20">
          <header>
            <h4>Bens Penhorados ou à Disposição da Justiça</h4>
          </header>

          <div className="col-12 p-mt-20">

            <Toolbar className="p-mb-4" left={leftToolbarTemplate} />

            <DataTable
              tableClassName="table-pawnedgoods table"
              id="table-pawnedgoods"
              ref={dt}
              value={pawnedGoods}
              editMode="row"
              dataKey="dep_cus_proc_id"
              footerColumnGroup={footerGroup}
              stripedRows
              showGridlines
              className="p-datatable-sm"
              emptyMessage="Nenhum registro encontrado."
              onRowDoubleClick={(e) => editPawnedGoods(e.data)}
            >
              <Column field="bens_penh_descricao" header="Bens penhorados" alignHeader="center" align="left" />
              <Column field="bens_penh_parte" header="Parte" alignHeader="center" body={partyBodyTemplate} align="left" style={{ width: '120px' }} />
              <Column field="bens_penh_data" header="Data" alignHeader="center" body={dateBodyTemplate} align="center" style={{ width: '120px' }} />
              <Column field="bens_penh_valor" header="Valor (R$)" alignHeader="center" body={value1BodyTemplate} align="right" style={{ width: '250px' }} />
              <Column field="bens_penh_depositario" header="Depositário" alignHeader="center" align="left" style={{ width: '350px' }} />
              <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
            </DataTable>

          </div>

          <div>
            <Dialog visible={deletePawnedGood} style={{ width: '450px' }} header="Confirmação" modal footer={deletePawnedGoodFooter} onHide={hidedeletePawnedGood}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {pawnedGoods && (
                <span>
                  Você tem certeza que deseja excluir
                  <br />
                  <br />
                  <b>{pawnedGood.bens_penh_descricao}</b>
                  ?
                </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>

      </div>

    </Container>

  );
};

export default PawnedGoods;
