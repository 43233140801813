import React, {
  useEffect, useRef, useState,
} from 'react';

import { DataTable, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
/*
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
*/

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

interface IWorkingHours {
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_cod_responsavel: number;
  horas_descricao: string;
  horas_data: Date | undefined;
  horas_hora_inicial: Date | undefined;
  horas_hora_final: Date | undefined;
  horas_valor_hora: number;
  horas_documento: string;
  horas_data_alteracao: Date | undefined;
  horas_hora_total: string;
  horas_documento_seq: number;
  horas_processo_id: number;
  horas_reg: string;
  horas_num_pasta: string;
  horas_autor: string;
  horas_reu: string;
  horas_fatu_de: Date | undefined;
  horas_fatu_ate: Date | undefined;
  horas_fatu_ajuste: number;
  num_seq: number;
  valor_ajuste_nao_faturado: number;
  tipo_usuario: string;
  tipo_horas: string;
  horas_hora_inicial_texto: String;
  horas_hora_final_texto: String;
  usuario_logado: number;
}

const WorkingHourPendingConclusion: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const loggedUser: number = sessionStorage.getItem('usuario_logado') ? Number(sessionStorage.getItem('usuario_logado')) : 0;

  const initialValues: IWorkingHours = {
    horas_id: 0,
    horas_cod_cliente: 0,
    horas_cod_referencia: 0,
    horas_cod_responsavel: 0,
    horas_descricao: '',
    horas_data: new Date(),
    horas_hora_inicial: new Date(),
    horas_hora_final: undefined,
    horas_valor_hora: 0,
    horas_documento: '',
    horas_data_alteracao: undefined,
    horas_hora_total: '00:00',
    horas_documento_seq: 0,
    horas_processo_id: 0,
    horas_reg: '',
    horas_num_pasta: '',
    horas_autor: '',
    horas_reu: '',
    horas_fatu_de: undefined,
    horas_fatu_ate: undefined,
    horas_fatu_ajuste: 0,
    num_seq: 0,
    valor_ajuste_nao_faturado: 0,
    tipo_usuario: 'adv',
    tipo_horas: 'normais',
    horas_hora_inicial_texto: '',
    horas_hora_final_texto: '',
    usuario_logado: loggedUser,
  };

  const [workingHours, setWorkingHours] = useState<IWorkingHours[]>([]);
  const [workingHour, setWorkingHour] = useState(initialValues);
  const [deleteHourDialog, setDeleteHourDialog] = useState(false);
  const [selectedHours, setSelectedHours] = useState<IWorkingHours[]>([]);

  // para tabela
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      // const access = JSON.parse(sessionStorage.getItem('@Jurisnet:access') || '{}');
      const access = JSON.parse(localStorage.getItem('@Jurisnet:access') || '{}');

      let loggedUserId = 0;
      let loggedUserLevel = '';

      if (access) {
        loggedUserId = Number(access.usuario_logado);
        loggedUserLevel = access.nivelusuario;
      }

      if (loggedUserLevel === 'Administrador') {
        await api.get(`/management/administrators/show/${loggedUserId}`).then((response) => {
          (async () => {
            await api.get(`/management/attorneys/findbyemail/${response.data.administrador_email}`).then((responseAdm) => {
              loggedUserId = Number(responseAdm.data.advogado_id);
              const formData = {
                loggedUserId,
              };
              (async () => {
                await api.post('/management/workinghours/pendingconclusion', formData).then((responseConclusion) => {
                  setWorkingHours(responseConclusion.data);
                });
              })();
            });
          })();
        });
      }
      if (loggedUserLevel === 'Advogado') {
        await api.get(`/management/attorneys/show/${loggedUserId}`).then((responseAtt) => {
          loggedUserId = Number(responseAtt.data.advogado_id);
          const formData = {
            loggedUserId,
          };
          (async () => {
            await api.post('/management/workinghours/pendingconclusion', formData).then((responseConclusion) => {
              setWorkingHours(responseConclusion.data);
            });
          })();
        });
      }
      if (loggedUserLevel === 'Assistente') {
        await api.get(`/management/administrators/show/${loggedUserId}`).then((response) => {
          loggedUserId = Number(response.data.administrador_id);
          const formData = {
            loggedUserId,
          };
          (async () => {
            await api.post('/management/workinghours/pendingconclusion', formData).then((responseConclusion) => {
              setWorkingHours(responseConclusion.data);
            });
          })();
        });
      }

      setLoading(false);
    })();
  }, []);

  const startHourBodyTemplate = (rowData: {
    horas_hora_total: string;
  }) => {
    let horas_total = '';
    if (!rowData.horas_hora_total) {
      horas_total = '0:00';
    } else {
      horas_total = rowData.horas_hora_total;
    }

    return horas_total;
  };

  const endHourBodyTemplate = (rowData: {
    horas_hora_total: string;
  }) => {
    let horas_total = '';
    if (!rowData.horas_hora_total) {
      horas_total = '0:00';
    } else {
      horas_total = rowData.horas_hora_total;
    }

    return horas_total;
  };

  const hidedeleteHourDialog = () => {
    setDeleteHourDialog(false);
  };

  const editHour = (rowData: DataTableValue) => {
    history.push(`/management/workinghour/edit/${rowData.horas_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteHour = (workingHour: React.SetStateAction<IWorkingHours>) => {
    setWorkingHour(workingHour);
    setDeleteHourDialog(true);
  };

  const deleteHour = () => {
    api.delete(`/management/workinghours/delete/${workingHour.horas_id}/false`);
    setDeleteHourDialog(false);

    const updatedRecords = workingHours
      .filter((val) => val.horas_id !== workingHour.horas_id);
    setWorkingHours(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editHour(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { confirmDeleteHour(rowData); }}
      />
    </>
  );

  const searchHours = () => {
    history.push('/management/workinghour/search');
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        icon="pi pi-search"
        iconPos="left"
        label="Consultar horas"
        className="p-button p-component p-button-secondary p-mr-2"
        type="button"
        onClick={searchHours}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteHourDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteHourDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteHour} />
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>
            Horas pendentes de finalização
          </h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-workinghours table"
            id="table-workinghours"
            ref={dt}
            loading={loading}
            value={workingHours}
            editMode="row"
            dataKey="horas_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={workingHours.length}
            rowsPerPageOptions={[10, 20, 50, workingHours.length]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="checkbox"
            selection={selectedHours}
            onSelectionChange={(e) => {
              const selHours = e.value as IWorkingHours[];
              setSelectedHours(selHours);
            }}
            onRowDoubleClick={(e) => editHour(e.data)}
          >
            <Column key="hora_data" field="hora_data" header="Data" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="cliente_nome" header="Cliente" alignHeader="center" align="left" style={{ width: '140px' }} />
            <Column field="horas_referencia_nome" header="Referência" alignHeader="center" align="left" style={{ width: '80px' }} />
            <Column field="advogado_nome" header="Responsável" alignHeader="center" align="left" style={{ width: '120px' }} />
            <Column field="horas_descricao" header="Descrição" alignHeader="center" align="left" />
            <Column field="horas_tipo" header="Tipo" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="horas_hora_inicial" header="Hora inicial" alignHeader="center" body={startHourBodyTemplate} align="center" style={{ width: '80px' }} />
            <Column field="horas_hora_final" header="Hora final" alignHeader="center" body={endHourBodyTemplate} align="center" style={{ width: '80px' }} />
            <Column body={actionBodyTemplate} align="right" style={{ width: '180px' }} />
          </DataTable>
        </div>

        <div>
          <Dialog visible={deleteHourDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteHourDialogFooter} onHide={hidedeleteHourDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {workingHour && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{workingHour.horas_descricao}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default WorkingHourPendingConclusion;
