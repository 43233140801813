import React, {
  useEffect, useRef, useState,
} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

interface INatureSuitProperties {
  natureza_id: number | null;
  natureza_descricao: string;
}

interface ILegalRequestProperties {
  it_financeiros_pedidos_descricao_id: number | null;
  it_financeiros_pedidos_descricao: string;
  natureza_id: number | null;
}

const LegalRequest: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyLegalRequest = {
    it_financeiros_pedidos_descricao_id: null,
    it_financeiros_pedidos_descricao: '',
    natureza_id: null,
  };

  const [legalRequests, setLegalRequests] = useState<ILegalRequestProperties[]>([]);
  const [legalRequest, setLegalRequest] = useState(emptyLegalRequest);
  const [deleteLegalRequestDialog, setDeleteLegalRequestDialog] = useState(false);
  const [deleteLegalRequestsDialog, setDeleteLegalRequestsDialog] = useState(false);
  const [selectedLegalRequests, setSelectedLegalRequests] = useState<ILegalRequestProperties[]>([]);
  const [natureSuits, setNatureSuits] = useState<INatureSuitProperties[]>([]);
  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await api.get('/lawsuits/legalrequests/show/all').then((response) => {
        setLegalRequests(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await api.get('/lawsuits/naturesuits/show/all').then((response) => {
        setNatureSuits(response.data);
      });
    })();
  }, []);

  const columns = [
    { field: 'it_financeiros_pedidos_descricao_id', header: 'Código', sortable: true },
    { field: 'natureza_id', header: 'Natureza Processo', sortable: true },
    { field: 'it_financeiros_pedidos_descricao', header: 'Descrição', sortable: true },
  ];

  const groupBodyTemplate = (rowData: { natureza_id: number; }) => {
    if (natureSuits !== undefined) {
      const filteredNatureSuit = natureSuits.filter((item) => (
        item.natureza_id === rowData.natureza_id
      ), [natureSuits]);
      return filteredNatureSuit[0]?.natureza_descricao;
    }
    return '';
  };

  const dynamicColumns = columns.map((col) => {
    if (col.field === 'natureza_id') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={groupBodyTemplate}
          sortable
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
      />
    );
  });

  const handleCreate = () => {
    history.push('/lawsuit/legalrequest/create');
  };

  const hidedeleteLegalRequestDialog = () => {
    setDeleteLegalRequestDialog(false);
  };

  const hidedeleteLegalRequestsDialog = () => {
    setDeleteLegalRequestsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLegalRequest = (rowData:any) => {
    history.push(`/lawsuit/legalrequest/edit/${rowData.it_financeiros_pedidos_descricao_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteLegalRequest = (legalRequest: React.SetStateAction<{
    it_financeiros_pedidos_descricao_id: null; it_financeiros_pedidos_descricao: string;
    natureza_id: null;
  }>) => {
    setLegalRequest(legalRequest);
    setDeleteLegalRequestDialog(true);
  };

  const deleteLegalRequest = () => {
    api.delete(`lawsuits/legalrequests/delete/${legalRequest.it_financeiros_pedidos_descricao_id}`);

    setDeleteLegalRequestDialog(false);

    const updatedRecords = legalRequests
      .filter(
        (val) => val.it_financeiros_pedidos_descricao_id
        !== legalRequest.it_financeiros_pedidos_descricao_id,
      );
    setLegalRequests(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editLegalRequest(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteLegalRequest(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteLegalRequestDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLegalRequestDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteLegalRequest} />
    </>
  );
  const deleteLegalRequestsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteLegalRequestsDialog} />
      {/* <Button label="Sim" icon="pi pi-check" className="p-button-text"
      onClick={deleteselectedLegalRequests} /> */}
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Descrição de Pedidos</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-legalRequests table"
            id="table-legalRequests"
            ref={dt}
            value={legalRequests}
            editMode="row"
            dataKey="it_financeiros_pedidos_descricao_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedLegalRequests}
            onSelectionChange={(e) => {
              const selLegalRequests = e.value as ILegalRequestProperties[];
              setSelectedLegalRequests(selLegalRequests);
            }}
            onRowDoubleClick={(e) => editLegalRequest(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteLegalRequestDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLegalRequestDialogFooter} onHide={hidedeleteLegalRequestDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {legalRequest && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{legalRequest.it_financeiros_pedidos_descricao}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteLegalRequestsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteLegalRequestsDialogFooter} onHide={hidedeleteLegalRequestsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {legalRequest
              && (
              <span>
                Você tem certeza que deseja excluir os cadastros selecionados?
              </span>
              )}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default LegalRequest;
