import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';

import { format } from 'date-fns';
import { nanoid } from 'nanoid';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import moment from 'moment';
import 'moment/locale/pt-br';

import robotoRegular from '../../../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../../services/api';

import CompanyLogo from '../../../../../assets/logo.png';

interface IAttorneys {
  index: number;
  advogado_id: number;
  advogado_nome: string;
  horas_data: Date;
  dia_mes: string;
  dia_semana: string;
  horas_hora_total: string;
  horas_hora_total_decimal: string;
}

interface IAttorneyHours {
  attorneyName: string,
  workedHours: IAttorneys[],
  hoursPerWeek: string,
  decimalsPerWeek: string,
}

type dataOptions = {
    [key: string]: string;
}

const HourAttorneyAnalyticalReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const location = useLocation();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  const [groupedHours, setGroupedHours] = useState<IAttorneyHours[]>([]);
  const [selectedHours, setSelectedHours] = useState<IAttorneys[]>([]);
  const [totalTime, setTotalTime] = useState('');
  const [totalDecimalTime, setTotalDecimalTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector('.loader-container');
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!loading);
      }
    });
  });

  function someRequest() { // Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise<void>((resolve) => setTimeout(() => resolve(), 2500));
  }

  let initialSearchDate = '';
  let finalSearchDate = '';
  if (state) {
    initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format('DD/MM/YYYY');
    finalSearchDate = moment(state?.proc_data_ajuiz_final).format('DD/MM/YYYY');
    if (state.dateFilterType === 'periodo' && state?.proc_data_ajuiz_final === undefined) {
      finalSearchDate = moment(state?.proc_data_ajuiz_inicio).clone().endOf('month').format('DD/MM/YYYY');
    }
  }

  const convertDecimalToHour = useCallback((totalHour: string) => {
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!totalHour) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = totalHour.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = horas_total_tmp[0].toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);
        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    return total_de_horas;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/reports/hour/analytical/simplified', state).then((response) => {
          let attorneys: IAttorneys[] = [];
          let attorneysCombined: IAttorneys[] = [];
          const groupedAttorneyHours: IAttorneyHours[] = [];

          const filteredIds: number[] = [];
          let filteredHoursArray: Date[] = [];
          let totalDurations: string[] = [];
          let weekDurations: string[] = [];
          let weekDurationsConsolidation: string[] = [];
          let total_acumulado = '';
          let horas_decimal_acumulado = '0';
          let total_acumulado_semana = '';

          const startDate = moment(state?.proc_data_ajuiz_inicio);
          const finalDate = moment(state?.proc_data_ajuiz_final);
          const diff = finalDate.diff(startDate, 'days');

          const range: string[] = [];
          for (let i = 0; i < diff; i++) {
            range.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
          }

          response.data.forEach(async (data: IAttorneys, index: number) => {
            if (index === 0) {
              filteredIds.push(data.advogado_id);
            } else {
              const found = filteredIds.find((element) => element === data.advogado_id);
              if (!found) {
                filteredIds.push(data.advogado_id);
                weekDurations = [];
              } else {
                return;
              }
            }

            // TODO UNDO THESE TWO LINES
            // attorneys = [];
            // attorneysCombined = [];

            let total_de_horas = '';
            let currentWeek = 0;
            let previousWeek = 0;

            const filteredAttorney = response.data.filter((request:
              { advogado_id: number;
              }) => request.advogado_id === data.advogado_id);

            // TODO UNDO THESE TWO LINES
            attorneys = [];
            attorneysCombined = [];

            filteredHoursArray = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filteredAttorney.forEach((filtered: any, indexhours: number) => {
              if (indexhours === 0) {
                filteredHoursArray.push(filtered.horas_data);
              } else {
                const found = filteredHoursArray.find((element) => element === filtered.horas_data);
                if (!found) {
                  filteredHoursArray.push(filtered.horas_data);
                } else {
                  return;
                }
              }

              const filteredAttorneyHours = filteredAttorney.filter((requestHour:
                { horas_data: Date; advogado_id: number;
                }) => requestHour.horas_data === filtered.horas_data);

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              let horas_total = '';
              total_de_horas = '';
              let horas_decimal = '0';
              let total_h1 = 0;
              let total_m1 = 0;

              filteredAttorneyHours.forEach((filteredHours: {
                horas_hora_total: string; horas_data: Date;
              }) => {
                if (!filteredHours.horas_hora_total) {
                  horas_total = '0:00';
                } else {
                  const horas_total_tmp = filteredHours.horas_hora_total.split(':');

                  if (Number(horas_total_tmp[0]) >= 10) {
                    horas_total = `${horas_total_tmp[0]}:`;
                  } else {
                    horas_total = `0${horas_total_tmp[0]}:`;
                  }

                  if (Number(horas_total_tmp[1]) > 0) {
                    if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                      horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
                        .toFixed(0).toString();
                    } else {
                      horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                    }
                  } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    horas_total += '00';
                  }

                  total_h1 += Number(horas_total_tmp[0]);

                  if (Number(horas_total_tmp[1]) > 0) {
                    total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
                  }

                  if (Number(horas_total_tmp[0]) > 0) {
                    horas_decimal = (Number(horas_total_tmp[0])).toString();
                  } else {
                    horas_decimal = '0';
                  }

                  const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

                  if (minutos > 0) {
                    let temp = ((minutos * 100) / 60).toFixed(0);

                    if (Number(temp) < 10) {
                      temp = `0${temp}`;
                    }

                    horas_decimal += `.${temp}`;
                  } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    horas_decimal += '.00';
                  }
                }

                if (total_m1 >= 60) {
                  total_h1 += 1;
                  total_m1 -= 60;
                }

                if (total_h1 >= 10) {
                  total_de_horas = `${total_h1}`;
                } else {
                  total_de_horas = `0${total_h1}`;
                }

                if (total_m1 >= 10) {
                  total_de_horas += `:${total_m1}`;
                } else {
                  total_de_horas += `:0${total_m1}`;

                  if (Number(horas_decimal) === 0) {
                    horas_decimal = '0.00';
                  }
                }
              });

              if (indexhours === 0) {
                currentWeek = moment(filtered.horas_data).isoWeek();
                previousWeek = currentWeek;
                weekDurations.push(total_de_horas);
              } else {
                currentWeek = moment(filtered.horas_data).isoWeek();

                if (currentWeek !== previousWeek) {
                  previousWeek = currentWeek;
                  weekDurations = [];
                }

                weekDurations.push(total_de_horas);
              }

              const dayOfTheWeek = moment(filtered.horas_data).locale('pt-br').format('dddd');
              const dayOfTheWeekCapitalized = `${dayOfTheWeek[0].toUpperCase()}${dayOfTheWeek.slice(1)}`;

              attorneys.push({
                index: -1,
                advogado_id: filtered.advogado_id,
                advogado_nome: filtered.advogado_nome,
                horas_data: filtered.horas_data,
                dia_mes: moment(filtered.horas_data).format('DD/MM'),
                dia_semana: dayOfTheWeekCapitalized,
                horas_hora_total: `${total_de_horas}`,
                horas_hora_total_decimal: `${horas_decimal}`,
              });
            });

            range.forEach((date) => {
              // const found = attorneys.find((element) => element.horas_data.toString() === date);
              const found = attorneys.find((element) => format(new Date(element.horas_data), 'yyyy-MM-dd') === date);

              if (!found) {
                const dayOfTheWeek = moment(date).locale('pt-br').format('dddd');
                const dayOfTheWeekCapitalized = `${dayOfTheWeek[0].toUpperCase()}${dayOfTheWeek.slice(1)}`;
                attorneys.push({
                  index: -1,
                  advogado_id: attorneys[0].advogado_id,
                  advogado_nome: attorneys[0].advogado_nome,
                  horas_data: moment(date).toDate(),
                  dia_mes: moment(date).format('DD/MM'),
                  dia_semana: dayOfTheWeekCapitalized,
                  horas_hora_total: '00:00',
                  horas_hora_total_decimal: '0.00',
                });
              }
            });

            const sortedAttorneys = attorneys.sort((a, b) => a.dia_mes.split('/').reverse().join()
              .localeCompare(b.dia_mes.split('/').reverse().join()));

            let indexWeekDurations = 0;
            totalDurations = [];
            sortedAttorneys.forEach((item, indexattorney: number) => {
              if (moment(item.horas_data).isoWeekday() === 7
              || indexattorney === attorneys.length - 1) {
                weekDurationsConsolidation.push(item.horas_hora_total);
                attorneysCombined.push({
                  index: -1,
                  advogado_id: item.advogado_id,
                  advogado_nome: item.advogado_nome,
                  horas_data: item.horas_data,
                  dia_mes: item.dia_mes,
                  dia_semana: item.dia_semana,
                  horas_hora_total: item.horas_hora_total,
                  horas_hora_total_decimal: item.horas_hora_total_decimal,
                });

                const weekms = weekDurationsConsolidation.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
                const weeksum = weekms.reduce((prev, cur) => prev + cur, 0);

                const weekd = moment.duration(weeksum, 'milliseconds');
                const weekhours = Math.floor(weekd.asHours());
                const weekminutes = (Math.floor(weekd.asMinutes()) - weekhours * 60).toString().padStart(2, '0');
                const weektime = `${weekhours}:${weekminutes}`;
                total_acumulado_semana = weektime;

                const weektotalDecimalsArray = total_acumulado_semana.split(':');
                const weektotalDecimal = `${weektotalDecimalsArray[0]}.${Math.round((Number(weektotalDecimalsArray[1]) / 60) * 100)}`;

                const formattedHours = weekhours.toString().padStart(2, '0');
                total_acumulado_semana = `${formattedHours}:${weekminutes}`;

                const total_decimal_acumulado_semana = Number(weektotalDecimal).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  minimumIntegerDigits: 1,
                });

                attorneysCombined.push({
                  index: indexWeekDurations,
                  advogado_id: item.advogado_id,
                  advogado_nome: item.advogado_nome,
                  horas_data: item.horas_data,
                  dia_mes: '',
                  dia_semana: 'Soma das horas da semana',
                  horas_hora_total: total_acumulado_semana,
                  horas_hora_total_decimal: total_decimal_acumulado_semana,
                });
                totalDurations.push(total_acumulado_semana);
                weekDurationsConsolidation = [];
                indexWeekDurations++;
              } else {
                attorneysCombined.push({
                  index: -1,
                  advogado_id: item.advogado_id,
                  advogado_nome: item.advogado_nome,
                  horas_data: item.horas_data,
                  dia_mes: item.dia_mes,
                  dia_semana: item.dia_semana,
                  horas_hora_total: item.horas_hora_total,
                  horas_hora_total_decimal: item.horas_hora_total_decimal,
                });
                weekDurationsConsolidation.push(item.horas_hora_total);
              }
            });

            const ms = totalDurations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
            const sum = ms.reduce((prev, cur) => prev + cur, 0);

            const d = moment.duration(sum, 'milliseconds');
            const hours = Math.floor(d.asHours());
            const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
            const time = `${hours}:${minutes}`;
            total_acumulado = time;

            const formattedHours = hours.toString().padStart(2, '0');
            total_acumulado = `${formattedHours}:${minutes}`;

            const totalDecimalsArray = total_acumulado.split(':');
            const totalDecimal = `${totalDecimalsArray[0]}.${Math.round((Number(totalDecimalsArray[1]) / 60) * 100)}`;

            horas_decimal_acumulado = Number(totalDecimal).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
            });

            groupedAttorneyHours.push({
              attorneyName: data.advogado_nome,
              workedHours: attorneysCombined,
              hoursPerWeek: total_acumulado,
              decimalsPerWeek: horas_decimal_acumulado,
            });

            totalDurations = [];
          });

          setGroupedHours(groupedAttorneyHours);
        });

        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, state]);

  const exportPdf = (attorneyIndex: number) => {
  // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', [297, 210]);

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular');

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: 'Anotação de Horas Analítica',
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    const totalPagesExp = '{total_pages_count_string}';

    if (attorneyIndex > -1) {
      autoTable(doc, {
        margin: [25, 0, 15, 15],
        html: `#table-groupedhours-${attorneyIndex} table`,
        includeHiddenHtml: false,
        theme: 'grid',
        tableWidth: 180,
        columnStyles: {
          0: { cellWidth: 15, halign: 'center' },
          2: { cellWidth: 20, halign: 'center' },
          3: { cellWidth: 20, halign: 'center' },
        },
        headStyles: {
          fillColor: [53, 73, 133],
          textColor: [255, 255, 255],
          fontSize: 9,
          font: 'Roboto-Regular',
          fontStyle: 'normal',
          halign: 'center',
        },
        bodyStyles: {
          fontSize: 9,
          font: 'Roboto-Regular',
          fontStyle: 'normal',
        },
        footStyles: {
          fillColor: [53, 73, 133],
          textColor: [255, 255, 255],
          fontSize: 9,
          font: 'Roboto-Regular',
          fontStyle: 'normal',
          halign: 'center',
        },
        showFoot: 'lastPage',
        didDrawPage(data) {
        // Header
          doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

          const { pageSize } = doc.internal;
          const fontSize = doc.getFontSize();
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

          const header1 = 'Anotação de Horas Simplificada';
          const header2 = `Nome: ${groupedHours[attorneyIndex]?.attorneyName} - Período de ${initialSearchDate} até ${finalSearchDate}`;

          const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;

          // Calculate text's x coordinate
          const x1 = ((pageWidth - hWidth1) / 2) + 15;
          // const x2 = ((pageWidth - hWidth2) / 2) + 15;

          doc.setFontSize(14);
          doc.setTextColor(20);
          doc.text(header1, x1, 14);

          doc.setTextColor(10);
          doc.setFontSize(9);
          doc.text(header2, data.settings.margin.left, 21);

          doc.setLineWidth(0.5);
          doc.line(
            data.settings.margin.left, pageHeight - 15,
            data.settings.margin.right, pageHeight - 15,
          );
        },
      });
    } else {
      for (let index = 0; index < groupedHours.length; index++) {
        autoTable(doc, {
          margin: [25, 0, 15, 15],
          html: `#table-groupedhours-${index} table`,
          includeHiddenHtml: false,
          theme: 'grid',
          pageBreak: index === 0 ? 'auto' : 'always',
          tableWidth: 180,
          columnStyles: {
            0: { cellWidth: 15, halign: 'center' },
            2: { cellWidth: 20, halign: 'center' },
            3: { cellWidth: 20, halign: 'center' },
          },
          headStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          bodyStyles: {
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
          },
          footStyles: {
            fillColor: [53, 73, 133],
            textColor: [255, 255, 255],
            fontSize: 9,
            font: 'Roboto-Regular',
            fontStyle: 'normal',
            halign: 'center',
          },
          showFoot: 'lastPage',
          didDrawPage(data) {
          // Header
            doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

            const { pageSize } = doc.internal;
            const fontSize = doc.getFontSize();
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

            const header1 = 'Anotação de Horas Simplificada';
            const header2 = `Nome: ${groupedHours[index]?.attorneyName} - Período de ${initialSearchDate} até ${finalSearchDate}`;

            const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;

            // Calculate text's x coordinate
            const x1 = ((pageWidth - hWidth1) / 2) + 15;

            doc.setFontSize(14);
            doc.setTextColor(20);
            doc.text(header1, x1, 14);

            doc.setTextColor(10);
            doc.setFontSize(9);
            doc.text(header2, data.settings.margin.left, 21);

            doc.setLineWidth(0.5);
            doc.line(
              data.settings.margin.left, pageHeight - 15,
              data.settings.margin.right, pageHeight - 15,
            );
          },
        });
      }
    }
    // Footer
    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const printedOn = new Date();
    const dateFormatted = format(
      printedOn,
      "dd/MM/yyyy 'às' HH:mm:ss'.'",
    );

    let str = `Página ${doc.getNumberOfPages()}`;
    if (typeof doc.putTotalPages === 'function') {
      str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    }

    doc.setFontSize(8);

    const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    const x3 = (pageWidth - pageCount) / 2;
    doc.text(str, x3 + 50, pageHeight - 8);

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    let filename = `Anotacao-de-horas-analitica${dateFormattedFile}`;
    const name = `${groupedHours[attorneyIndex]?.attorneyName}`;
    if (attorneyIndex > -1) {
      filename += `-${name}`;
    }
    doc.save(`${filename}.pdf`);
  };

  const exportExcel = (attorneyIndex: number) => {
    import('excel4node').then((xlsx) => {
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('Anotação Horas');

      const columnsHeaders = [
        'Dia',
        'Semana',
        'Horas',
        'Decimais',
      ];

      const columnsContent: dataOptions[] = [{}];

      const name = `${groupedHours[attorneyIndex].attorneyName}`;
      groupedHours[attorneyIndex].workedHours.forEach((item) => {
        columnsContent.push({
          dia: item.dia_mes,
          semana: item.dia_semana,
          horas: item.horas_hora_total,
          decimal: item.horas_hora_total_decimal,
        });
      });

      columnsContent.push({
        dia: '',
        semana: 'Total de horas',
        horas: groupedHours[attorneyIndex].hoursPerWeek,
        decimal: groupedHours[attorneyIndex].decimalsPerWeek,
      });

      worksheet.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet.cell(2, 1).string('Anotação de Horas Simplificada');
      worksheet.cell(3, 1).string(`Período: ${initialSearchDate} até ${finalSearchDate}`);
      worksheet.cell(5, 1).string(`Nome: ${name}`);

      let headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet.cell(7, headerColumIndex++).string(heading);
      });

      let rowIndex = 7;
      columnsContent.forEach((item) => {
        let columnIndex = 1;
        Object.keys(item).forEach((columnName) => {
          worksheet.cell(rowIndex, columnIndex++).string(item[columnName]);
        });
        rowIndex++;
      });

      const formattedIniDate = initialSearchDate.replaceAll('/', '-');
      const formattedFinalDate = finalSearchDate.replaceAll('/', '-');
      const filename = `Anotacao-de-horas-analitica-${name}-${formattedIniDate}a${formattedFinalDate}`;

      workbook.writeToBuffer().then((buffer: BlobPart) => {
        import('file-saver').then((module) => {
          if (module && module.default) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
              type: EXCEL_TYPE,
            });

            module.default.saveAs(data,
              `${filename}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
          }
        });
      });
    });
  };

  const searchHours = () => {
    history.push('/report/hour/attorney/analytical/search');
  };

  const header = (attorneyindex: number) => (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={() => exportPdf(attorneyindex)}
        className="p-button-danger mr-2"
        tooltip="Exportar para PDF / Imprimir"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={() => exportExcel(attorneyindex)}
        className="p-button-success mr-2"
        tooltip="Exportar para Excel"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        icon="pi pi-search"
        className="p-button-secondary p-mr-2"
        type="button"
        onClick={searchHours}
        tooltip="Nova consulta de horas"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const formatWeekSumText = (rowData: { dia_mes: string; dia_semana: string; }) => {
    if (!rowData.dia_mes) {
      return (
        <span className="endofline">
          {rowData.dia_semana}
        </span>
      );
    }
    return rowData.dia_semana;
  };

  const formatWeekSumHour = (rowData: { dia_mes: string; horas_hora_total: string; }) => {
    if (!rowData.dia_mes) {
      return (
        <span className="bold">
          {rowData.horas_hora_total}
        </span>
      );
    }
    return rowData.horas_hora_total;
  };

  const formatWeekSumDecimal = (rowData: {
    dia_mes: string; horas_hora_total_decimal: string;
  }) => {
    if (!rowData.dia_mes) {
      return (
        <span className="bold">
          {rowData.horas_hora_total_decimal}
        </span>
      );
    }
    return rowData.horas_hora_total_decimal;
  };

  const leftToolbarTemplate = () => (
    <div className="flex align-items-center export-buttons">
      <h3>Anotação de Horas Analíticas</h3>
    </div>
  );

  const rightToolbarTemplate = () => (
    <Button
      type="button"
      icon="pi pi-file-pdf"
      onClick={() => exportPdf(-1)}
      className="p-button-info p-ml-auto"
      label="Imprimir todos"
      tooltip="Exportar todos para PDF / Imprimir"
      tooltipOptions={{ position: 'left' }}
    />
  );

  const loadSelectedDay = async (rowData: DataTableValue) => {
    try {
      /*
       {
        horas_data: moment.MomentInput | Date;
        advogado_id: number;
        horas_hora_total: string;
      }
      */
      const selectedDate = moment(rowData.horas_data).format('YYYY-MM-DD');

      await api.get(`/reports/hour/analytical/dayonly/${rowData.advogado_id}/${selectedDate}`).then((response) => {
        setSelectedHours(response.data);

        const decimalHour = rowData.horas_hora_total.split(':')
          .map((val: string) => parseInt(val, 10))
          .reduce((
            previousValue: number, currentValue: number, index: number,
            // eslint-disable-next-line no-restricted-properties
          ) => previousValue + currentValue / Math.pow(60, index));

        setTotalTime(rowData.horas_hora_total);
        setTotalDecimalTime(decimalHour.toFixed(2).replace('.', ','));
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
                  'Não foi possível carregar as informações do cadastro selecionado.',
      });
    }
  };

  const selectedDateBodyTemplate = (rowData: { horas_data: Date; }) => {
    if (selectedHours !== undefined) {
      const filteredDate = selectedHours.filter((item) => (
        item.horas_data === rowData.horas_data
      ), []);

      const dateInput = filteredDate[0].horas_data?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }
    return '';
  };

  const hourBodyTemplate = (rowData: {
    horas_hora_total: string;
  }) => {
    const currentDuration: string[] = [];
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!rowData.horas_hora_total) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = rowData.horas_hora_total.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = (Number(horas_total_tmp[0])).toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);

        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    currentDuration.push(total_de_horas);

    const ms = currentDuration.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
    const sum = ms.reduce((prev, cur) => prev + cur, 0);

    const d = moment.duration(sum, 'milliseconds');
    const hours = Math.floor(d.asHours());
    const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;

    return time;
  };

  const decimalBodyTemplate = (rowData: { horas_hora_total: string; }) => {
    const cleanDuration = convertDecimalToHour(rowData.horas_hora_total);

    const totalHoursArray = cleanDuration.split(':');
    const cleanMinutes = (Number(totalHoursArray[0]) * 60) + Number(totalHoursArray[1]);

    return (cleanMinutes / 60).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 1,
    });
  };

  return (
    <Container>
      <header>
        <div>
          <h3>Relatório de horas por profissional (Analítico) - Modelo VII</h3>
        </div>
      </header>

      <div className="loader-container">
        <div className="loader" />
      </div>

      <Sidebar
        className="p-sidebar-lg"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <div className="col-12 md:col-12">
          <h3 key={nanoid()}>
            Resumo de horas trabalhadas para
            { ' ' }
            {selectedHours[0]?.advogado_nome}
            (Data:
            { ' ' }
            {new Date(selectedHours[0]?.horas_data).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}
            )
          </h3>
        </div>
        <div className="col-12 md:col-12">
          <DataTable
            tableClassName="table-selectedhours table"
            id={`table-selectedhours-${nanoid()}`}
            value={selectedHours}
            dataKey="horas_id"
            stripedRows
            className="p-datatable-sm"
            responsiveLayout="scroll"
            rows={selectedHours.length}
            emptyMessage="Nenhum registro encontrado."
            footerColumnGroup={(
              <ColumnGroup>
                <Row>
                  <Column footer="Total de horas:" colSpan={4} footerStyle={{ textAlign: 'right' }} />
                  <Column footer={totalTime} footerStyle={{ textAlign: 'center' }} />
                  <Column footer={totalDecimalTime} footerStyle={{ textAlign: 'center' }} />
                </Row>
              </ColumnGroup>
            )}
          >
            <Column key="horas_data" field="horas_data" header="Data" alignHeader="center" body={selectedDateBodyTemplate} align="center" style={{ width: '100px' }} />
            <Column field="cliente_nome" header="Cliente" alignHeader="center" align="left" />
            <Column field="horas_descricao" header="Descrição" alignHeader="center" align="left" />
            <Column field="horas_tipo" header="Tipo" alignHeader="center" align="left" />
            <Column field="horas_hora_total" header="Horas" alignHeader="center" body={hourBodyTemplate} align="center" style={{ width: '80px' }} />
            <Column field="horas_decimal" header="Decimais" alignHeader="center" body={decimalBodyTemplate} align="center" style={{ width: '80px' }} />
          </DataTable>
        </div>

      </Sidebar>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <Toolbar key={nanoid()} className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />

          <Tooltip key={nanoid()} target=".export-buttons>button" position="bottom" />
          {
            groupedHours.map((item, index) => (
              <>
                <div key={nanoid()} className="attorney-header p-mt-20">
                  <span>
                    <h3>
                      Nome:
                      {' '}
                      {item.attorneyName}
                    </h3>
                  </span>
                  <span>
                    Período de
                    {' '}
                    <strong>
                      {initialSearchDate}
                      {' '}
                      até
                      {' '}
                      {finalSearchDate}
                      {' '}
                    </strong>
                  </span>
                </div>
                <DataTable
                  tableClassName="table-groupedhours table"
                  id={`table-groupedhours-${index}`}
                  ref={dt}
                  loading={loading}
                  value={item.workedHours}
                  dataKey={item.attorneyName}
                  // dataKey={item.workedHours[item.workedHours.length - 1]
                  // .horas_hora_total_decimal}
                  header={header(index)}
                  stripedRows
                  className="p-datatable-sm table-pendinghours"
                  rows={item.workedHours.length}
                  globalFilter={globalFilter}
                  emptyMessage="Nenhum registro encontrado."
                  responsiveLayout="scroll"
                  footerColumnGroup={(
                    <ColumnGroup>
                      <Row>
                        <Column footer="Total de horas:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
                        <Column footer={item.hoursPerWeek} footerStyle={{ textAlign: 'center' }} />
                        <Column footer={item.decimalsPerWeek} footerStyle={{ textAlign: 'center' }} />
                      </Row>
                    </ColumnGroup>
                  )}
                  onRowClick={(e) => {
                    if (e.originalEvent.detail === 1) {
                      if (e.data.horas_hora_total.toString() === '00:00'
                      || e.data.dia_semana.toString().includes('Soma')) {
                        setVisibleRight(false);
                        addToast({
                          type: 'info',
                          title: 'Não há horas trabalhadas!',
                          description:
                                  'Não existem registros para a data selecionada.',
                        });
                      } else {
                        setTimeout(() => {
                          loadSelectedDay(e.data);
                          setVisibleRight(true);
                        }, 200);
                      }
                    }
                  }}
                >
                  <Column key="dia_mes" field="dia_mes" header="Dia" alignHeader="center" align="center" style={{ width: '10px' }} />
                  <Column key="dia_semana" field="dia_semana" header="Semana" alignHeader="left" align="left" body={formatWeekSumText} />
                  <Column field="horas_hora_total" header="Horas" alignHeader="center" align="center" body={formatWeekSumHour} style={{ width: '50px' }} />
                  <Column field="horas_hora_total_decimal" header="Decimais" alignHeader="center" body={formatWeekSumDecimal} align="center" style={{ width: '50px' }} />
                </DataTable>
              </>
            ))
        }
        </div>
      </div>

    </Container>
  );
};

export default HourAttorneyAnalyticalReport;
