/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface SearchData {
  proc_reg: string;
  proc_num_pasta: string;
  proc_reg_outros : string;
  // proc_cod_acao: number;
  proc_cod_orgao: number;
  proc_cod_natureza: number;
  proc_orgao_cidade: string;
  proc_orgao_uf: string;
  proc_autor: string;
  proc_reu: string;
  proc_cod_cliente: number;
  grupo_economico_id: number;
  proc_cod_adv_resp: number;
  proc_cod_ass_resp: number;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
  proc_data_ingresso_inicio: Date;
  proc_data_ingresso_final: Date;
  proc_em_acordo: string;
  updatedin: number;
  nochanges: number;
  formValuesChanged: boolean;
}

interface IBusinessGroupProperties {
  id: number | null;
  parent_company_id: number;
  children_companies: string;
  report_columns: string;
}

interface ICustomerProperties {
  cliente_id: number | null;
  cliente_nome: string;
}

const SearchLawsuitSummary:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const initialValues: SearchData = {
    proc_reg: '',
    proc_num_pasta: '',
    proc_reg_outros: '',
    // proc_cod_acao: 0,
    proc_cod_orgao: 0,
    proc_cod_natureza: 0,
    proc_orgao_cidade: '',
    proc_orgao_uf: '',
    proc_autor: '',
    proc_reu: '',
    proc_cod_cliente: 0,
    grupo_economico_id: 0,
    proc_cod_adv_resp: 0,
    proc_cod_ass_resp: 0,
    proc_data_ajuiz_inicio: new Date(),
    proc_data_ajuiz_final: new Date(),
    proc_data_ingresso_inicio: new Date(),
    proc_data_ingresso_final: new Date(),
    proc_em_acordo: '4',
    updatedin: 0,
    nochanges: 0,
    formValuesChanged: false,
  };

  const [lawsuitRegistrationNumber, setLawsuitRegistrationNumber] = useState('');
  const [lawsuitFolderNumber, setLawsuitFolderNumber] = useState('');
  const [otherLawsuitNumbers, setOtherLawsuitNumbers] = useState('');
  const [natureSuitsOptions, setNatureSuitsOptions] = useState([{}]);
  const [suitTypesOptions, setSuitTypesOptions] = useState([{}]);
  const [author, setAuthor] = useState('');
  const [defendant, setDefendant] = useState('');
  const [lawsuitId, setLawsuitId] = useState('');
  const [natureId, setNatureId] = useState('');
  const [judgingBodyCityId, setJudgingBodyCityId] = useState('');
  const [judgingBodyStateId, setJudgingBodyStateId] = useState('');
  const [customerId, setCustomerId] = useState('0');
  const [businessGroupId, setBusinessGroupId] = useState('0');
  const [attorneyInChargeId, setAttorneyInChargeId] = useState('');
  const [assistantInChargeId, setAssistantInChargeId] = useState('');
  const [lawsuitStatus, setLawsuitStatus] = useState('4');

  const [updatedInDay, setUpdatedInDay] = useState(0);
  const [noChangesDay, setNoChangesDay] = useState(0);

  const [
    initialRegistrationDate, setInitialRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    finalRegistrationDate, setFinalRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    initialEntranceDate, setInitialEntranceDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [finalEntranceDate, setFinalEntranceDate] = useState<string | Date | Date[] | null>(null);

  const [attorneysOptions, setAttorneysOptions] = useState([{}]);
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [customersOptions, setCustomersOptions] = useState([{}]);
  const [businessGroupOptions, setBusinessGroupOptions] = useState([{}]);
  const [dateFilterType, setDateFilterType] = useState('data');
  const [registeredFilterType, setRegisteredFilterType] = useState('data');

  const [customers, setCustomers] = useState<ICustomerProperties[]>([]);

  const [showDate, setShowDate] = useState(true);
  const [showPeriod, setShowPeriod] = useState(false);
  const [showRegisteredDate, setShowRegisteredDate] = useState(true);
  const [showRegisteredPeriod, setShowRegisteredPeriod] = useState(false);

  const dateFilterTypeOptions = [
    { name: 'Data', value: 'data', style: 'font-size: small;' },
    { name: 'Período', value: 'periodo', style: 'font-size: small;' },
  ];

  const validationSchema = null;

  const lawsuitStatusOptions = [
    { name: 'ativos', value: '0', style: 'font-size: small;' },
    { name: 'em acordo', value: '1', style: 'font-size: small;' },
    { name: 'arquivados', value: '2', style: 'font-size: small;' },
    { name: 'ativos+em acordo', value: '4', style: 'font-size: small;' },
    { name: 'todos', value: '5', style: 'font-size: small;' },
  ];

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/naturesuits/show/all').then((response) => {
        response.data.map((natureSuit: { natureza_id: number, natureza_descricao:string}) => (
          natureSuitsOptions.push({
            name: natureSuit.natureza_descricao,
            value: natureSuit.natureza_id,
          })
        ));
      });
      natureSuitsOptions.splice(0, 1);
      setNatureSuitsOptions(natureSuitsOptions);
    })();
  }, [natureSuitsOptions]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/suittypes/show/all').then((response) => {
        response.data.map((natureSuit: { acao_id: number, acao_descricao:string}) => (
          suitTypesOptions.push({
            name: natureSuit.acao_descricao,
            value: natureSuit.acao_id,
          })
        ));
      });
      suitTypesOptions.splice(0, 1);
      setSuitTypesOptions(suitTypesOptions);
    })();
  }, [suitTypesOptions]);

  const brazilianStatesOptions = [
    { name: 'AC', value: 'AC' },
    { name: 'AL', value: 'AL' },
    { name: 'AM', value: 'AM' },
    { name: 'AP', value: 'AP' },
    { name: 'BA', value: 'BA' },
    { name: 'CE', value: 'CE' },
    { name: 'DF', value: 'DF' },
    { name: 'ES', value: 'ES' },
    { name: 'GO', value: 'GO' },
    { name: 'MA', value: 'MA' },
    { name: 'MG', value: 'MG' },
    { name: 'MS', value: 'MS' },
    { name: 'MT', value: 'MT' },
    { name: 'PA', value: 'PA' },
    { name: 'PB', value: 'PB' },
    { name: 'PE', value: 'PE' },
    { name: 'PI', value: 'PI' },
    { name: 'PR', value: 'PR' },
    { name: 'RJ', value: 'RJ' },
    { name: 'RN', value: 'RN' },
    { name: 'RO', value: 'RO' },
    { name: 'RR', value: 'RR' },
    { name: 'RS', value: 'RS' },
    { name: 'SC', value: 'SC' },
    { name: 'SE', value: 'SE' },
    { name: 'SP', value: 'SP' },
    { name: 'TO', value: 'TO' },
  ];

  useEffect(() => {
    (async () => {
      await api.get('management/attorneys/show/all').then((response) => {
        response.data.map((attorney:
        {
          advogado_id: number,
          advogado_nome:string
        }) => (
          attorneysOptions.push({
            name: attorney.advogado_nome,
            value: attorney.advogado_id,
          })
        ));
      });
      attorneysOptions.splice(0, 1);
      setAttorneysOptions(attorneysOptions);
    })();
  }, [attorneysOptions]);

  useEffect(() => {
    (async () => {
      await api.get('management/assistants/show/all').then((response) => {
        response.data.map((assistant:
        {
          administrador_id: number,
          administrador_nome:string
        }) => (
          assistantsOptions.push({
            name: assistant.administrador_nome,
            value: assistant.administrador_id,
          })
        ));
      });
      assistantsOptions.splice(0, 1);
      setAssistantsOptions(assistantsOptions);
    })();
  }, [assistantsOptions]);

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        setCustomers(response.data);
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  useEffect(() => {
    (async () => {
      await api.get('businessGroups/show/all').then((response) => {
        response.data.forEach((item: IBusinessGroupProperties) => {
          const filteredCustomer = customers.filter((stored) => (
            stored.cliente_id === Number(item.parent_company_id)
          ), []);

          businessGroupOptions.push({
            name: filteredCustomer[0]?.cliente_nome,
            value: item.id,
          });
        });
      });
      businessGroupOptions.splice(0, 1);
      setBusinessGroupOptions(businessGroupOptions);
    })();
  }, [businessGroupOptions, customers]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      const searchFormData = {
        proc_reg: lawsuitRegistrationNumber,
        proc_num_pasta: lawsuitFolderNumber,
        proc_reg_outros: otherLawsuitNumbers,
        proc_cod_acao: lawsuitId,
        // proc_cod_orgao: judgingBodyId,
        proc_cod_natureza: natureId,
        proc_orgao_cidade: judgingBodyCityId,
        proc_orgao_uf: judgingBodyStateId,
        proc_autor: author,
        proc_reu: defendant,
        proc_cod_cliente: customerId,
        grupo_economico_id: businessGroupId,
        proc_cod_adv_resp: attorneyInChargeId,
        proc_cod_ass_resp: assistantInChargeId,
        dateFilterType,
        registeredFilterType,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
        proc_data_ingresso_inicio: initialEntranceDate,
        proc_data_ingresso_final: finalEntranceDate,
        proc_em_acordo: lawsuitStatus,
        updatedin: updatedInDay,
        nochanges: noChangesDay,
        formValuesChanged: false,
      };

      history.push({
        pathname: '/report/lawsuit/summary/customizable',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };
  // TODO: define set form changed
  return (
    <Container>
      <div className="card">
        <header>
          <h3>Relatório CUSTOMIZÁVEL de Informações Processuais - Modelo I</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-4 colum-spacer-right">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_reg">
                        Nº do processo
                      </label>
                      <InputText
                        id="proc_reg"
                        name="proc_reg"
                        className="inputfield w-full"
                        onChange={(e) => {
                          setLawsuitRegistrationNumber(e.target.value);
                          handleChange(e);
                        }}
                        // onBlur={handleBlur}
                        value={lawsuitRegistrationNumber}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_num_pasta">
                        Pasta nº
                      </label>
                      <InputText
                        id="proc_num_pasta"
                        name="proc_num_pasta"
                        className="inputfield w-full"
                        onChange={(e) => setLawsuitFolderNumber(e.target.value)}
                        onBlur={handleBlur}
                        value={lawsuitFolderNumber}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_num_pasta">
                        Outros nrs. do processo
                      </label>
                      <InputText
                        id="proc_reg_outros"
                        name="proc_reg_outros"
                        className="inputfield w-full"
                        onChange={(e) => setOtherLawsuitNumbers(e.target.value)}
                        onBlur={handleBlur}
                        value={otherLawsuitNumbers}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cod_acao">
                        Tipo de ação
                      </label>
                      <Dropdown
                        id="proc_cod_acao"
                        name="proc_cod_acao"
                        options={suitTypesOptions}
                        onChange={(e) => setLawsuitId(e.target.value)}
                        value={lawsuitId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Todas"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cod_natureza">
                        Natureza do processo
                      </label>
                      <Dropdown
                        id="proc_cod_natureza"
                        name="proc_cod_natureza"
                        options={natureSuitsOptions}
                        onChange={(e) => setNatureId(e.target.value)}
                        value={natureId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Todas"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-9">
                      <label htmlFor="proc_orgao_cidade">
                        Cidade
                      </label>
                      <InputText
                        id="proc_orgao_cidade"
                        name="proc_orgao_cidade"
                        className="inputfield w-full"
                        onChange={(e) => setJudgingBodyCityId(e.target.value)}
                        value={judgingBodyCityId}
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <label htmlFor="proc_orgao_uf">
                        Estado
                      </label>
                      <Dropdown
                        id="proc_orgao_uf"
                        name="proc_orgao_uf"
                        options={brazilianStatesOptions}
                        onChange={(e) => setJudgingBodyStateId(e.target.value)}
                        value={judgingBodyStateId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 column-divider">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_autor">
                        Autor
                      </label>
                      <InputText
                        id="proc_autor"
                        name="proc_autor"
                        className="inputfield w-full"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        autoFocus
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_reg_outros">
                        Réu
                      </label>
                      <InputText
                        id="proc_reu"
                        name="proc_reu"
                        className="inputfield w-full"
                        value={defendant}
                        onChange={(e) => setDefendant(e.target.value)}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cliente">
                        Cliente
                      </label>
                      <Dropdown
                        id="proc_cliente"
                        name="proc_cliente"
                        options={customersOptions}
                        onChange={(e) => setCustomerId(e.target.value)}
                        value={customerId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cliente">
                        Grupo econômico
                      </label>
                      <Dropdown
                        id="grupo_economico_id"
                        name="grupo_economico_id"
                        options={businessGroupOptions}
                        onChange={(e) => setBusinessGroupId(e.target.value)}
                        value={businessGroupId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>

                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cod_adv_resp">
                        Adv. Responsável
                      </label>
                      <Dropdown
                        id="proc_cod_adv_resp"
                        name="proc_cod_adv_resp"
                        options={attorneysOptions}
                        onChange={(e) => setAttorneyInChargeId(e.target.value)}
                        value={attorneyInChargeId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cod_ass_resp">
                        Assistente
                      </label>
                      <Dropdown
                        id="proc_cod_ass_resp"
                        name="proc_cod_ass_resp"
                        options={assistantsOptions}
                        onChange={(e) => setAssistantInChargeId(e.target.value)}
                        value={assistantInChargeId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 colum-spacer-left">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ajuiz_inicio">
                        Data de ajuizamento
                        { ' ' }
                        <Tooltip target=".infoText">
                          <strong>Selecione data específica ou período</strong>
                          <br />
                          <strong>Seleção data</strong>
                          <br />
                          <em>
                            Use para intervalos com seleção específica de datas
                          </em>
                          <br />
                          <br />
                          <strong>Seleção período</strong>
                          <br />
                          <em>
                            Para período inicial e final do mesmo mês,
                            <br />
                            selecione apenas o período inicial para que o intervalo
                            <br />
                            seja o primeiro
                            e último dia do mês selecionado
                          </em>
                        </Tooltip>
                        <i
                          className="infoText pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-12" style={{ marginBottom: '15px' }}>
                      <SelectButton
                        id="date_filter_type"
                        value={dateFilterType}
                        options={dateFilterTypeOptions}
                        optionLabel="name"
                        onChange={(e) => {
                          setDateFilterType(e.value);
                          if (e.value === 'data') {
                            setShowDate(true);
                            setShowPeriod(false);
                          } else {
                            setShowDate(false);
                            setShowPeriod(true);
                          }
                        }}
                        style={{ padding: '0.5rem 0.79rem 0 0' }}
                      />
                    </div>
                    {showPeriod ? (
                      <>
                        <div id="periodo_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio_mes_ano"
                            name="proc_data_ajuiz_inicio_mes_ano"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/9999"
                            dateFormat="mm/yy"
                            yearRange="1980:2030"
                            placeholder="Período inicial"
                            view="month"
                            yearNavigator
                          />
                        </div>
                        <div id="periodo_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final_mes_ano">
                            <Calendar
                              inputId="proc_data_ajuiz_final_mes_ano"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/9999"
                              dateFormat="mm/yy"
                              yearRange="1980:2030"
                              placeholder="Período final"
                              view="month"
                              yearNavigator
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    {showDate ? (
                      <>
                        <div id="data_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio"
                            name="proc_data_ajuiz_inicio"
                            className="inputfield w-full"
                            value={
                              isAddMode
                                ? null
                                : new Date()
                            }
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data inicial"
                          />
                        </div>
                        <div id="data_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final">
                            <Calendar
                              inputId="proc_data_ajuiz_final"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={
                                isAddMode
                                  ? null
                                  : new Date()
                              }
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/99/9999"
                              dateFormat="dd/mm/yy"
                              yearRange="1980:2030"
                              placeholder="Data final"
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ingresso_inicio">
                        Ingresso no escritório
                        { ' ' }
                        <Tooltip target=".infoRegisteredText">
                          <strong>Selecione data específica ou período</strong>
                          <br />
                          <strong>Seleção data</strong>
                          <br />
                          <em>
                            Use para intervalos com seleção específica de datas
                          </em>
                          <br />
                          <br />
                          <strong>Seleção período</strong>
                          <br />
                          <em>
                            Para período inicial e final do mesmo mês,
                            <br />
                            selecione apenas o período inicial para que o intervalo
                            <br />
                            seja o primeiro
                            e último dia do mês selecionado
                          </em>
                        </Tooltip>
                        <i
                          className="infoRegisteredText pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-12" style={{ marginBottom: '15px' }}>
                      <SelectButton
                        id="date_registered_filter_type"
                        value={registeredFilterType}
                        options={dateFilterTypeOptions}
                        optionLabel="name"
                        onChange={(e) => {
                          setRegisteredFilterType(e.value);
                          if (e.value === 'data') {
                            setShowRegisteredDate(true);
                            setShowRegisteredPeriod(false);
                          } else {
                            setShowRegisteredDate(false);
                            setShowRegisteredPeriod(true);
                          }
                        }}
                        style={{ padding: '0.5rem 0.79rem 0 0' }}
                      />
                    </div>
                    {showRegisteredPeriod ? (
                      <>
                        <div id="periodo_ingresso_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ingresso_inicio_mes_ano"
                            name="proc_data_ingresso_inicio_mes_ano"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialEntranceDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/9999"
                            dateFormat="mm/yy"
                            yearRange="1980:2030"
                            placeholder="Período inicial"
                            view="month"
                            yearNavigator
                          />
                        </div>
                        <div id="periodo_ingresso_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ingresso_final_mes_ano">
                            <Calendar
                              inputId="proc_data_ingresso_final_mes_ano"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalEntranceDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/9999"
                              dateFormat="mm/yy"
                              yearRange="1980:2030"
                              placeholder="Período final"
                              view="month"
                              yearNavigator
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    {showRegisteredDate ? (
                      <>
                        <div id="ingresso_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ingresso_inicio"
                            name="proc_data_ingresso_inicio"
                            className="inputfield w-full"
                            value={
                              isAddMode
                                ? null
                                : new Date()
                            }
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialEntranceDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data inicial"
                          />
                        </div>
                        <div id="ingresso_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ingresso_final">
                            <Calendar
                              inputId="proc_data_ingresso_final"
                              name="proc_data_ingresso_final"
                              className="inputfield w-full"
                              value={
                                isAddMode
                                  ? null
                                  : new Date()
                              }
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalEntranceDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/99/9999"
                              dateFormat="dd/mm/yy"
                              yearRange="1980:2030"
                              placeholder="Data final"
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_em_acordo">
                        Incluir processos
                      </label>
                      <SelectButton
                        id="proc_em_acordo"
                        // className="narrowed-option-button"
                        value={lawsuitStatus}
                        options={lawsuitStatusOptions}
                        optionLabel="name"
                        onChange={(e) => setLawsuitStatus(e.value)}
                        style={{ padding: '0.5rem 0.79rem' }}
                      />
                    </div>

                    <div className="col-12 md:col-12 p-mt-10">
                      <div className="col-12 md:col-12">
                        <label htmlFor="updatedin">
                          Processos sem atualização nos últimos
                          {' '}
                        </label>
                        <InputNumber
                          id="updatedin"
                          name="updatedin"
                          className="inputfield"
                          placeholder="X"
                          value={updatedInDay}
                          onValueChange={(e) => setUpdatedInDay(Number(e.value))}
                          style={{ width: '3.5rem', alignItems: 'center' }}
                          min={0}
                        />
                        {' '}
                        dias
                      </div>
                    </div>
                    <div className="col-12 md:col-12 p-mt-10">
                      <div className="col-12 md:col-12">
                        <label htmlFor="nochanges">
                          Processos sem mov. relevante nos últimos
                          {' '}
                        </label>
                        <InputNumber
                          id="nochanges"
                          name="nochanges"
                          className="inputfield"
                          placeholder="X"
                          value={noChangesDay}
                          onValueChange={(e) => setNoChangesDay(Number(e.value))}
                          style={{ width: '3.5rem', alignItems: 'center' }}
                          min={0}
                        />
                        {' '}
                        dias
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchLawsuitSummary;
