/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useCallback, useState, useRef,
} from 'react';
import Switch from 'react-switch';
import { FaHeart, FaBars } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi'; // FiClock
import { ScrollTop } from 'primereact/scrolltop';

import { Button } from 'primereact/button';
import { Link, useHistory } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Main = ({
  collapsed,
  handleToggleSidebar,
  handleCollapsedChange,
  props,
}) => {
  const history = useHistory();

  const user = localStorage.getItem('@Jurisnet:user');
  const token = localStorage.getItem('@Jurisnet:token');

  const userData = JSON.parse(user);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Jurisnet:token');
    localStorage.removeItem('@Jurisnet:user');
    localStorage.removeItem('@Jurisnet:lawsuit');
    localStorage.removeItem('@Jurisnet:judginbody');
    localStorage.removeItem('@Jurisnet:counter');
    sessionStorage.removeItem('@Jurisnet:access');
    localStorage.removeItem('@Jurisnet:access');

    history.push('/');
  }, [history]);

  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPaused, setIsPaused] = useState(false);
  const increment = useRef(null);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    handleWorkingHourRegistration();
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const formatTime = () => {
    const getSeconds = `0${(timer % 60)}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  const handleWorkingHourRegistration = () => {
    history.push({
      pathname: '/management/workinghour/create',
      state: {
        data: `${formatTime()}`,
      },
    });
    localStorage.setItem('@Jurisnet:counter', JSON.stringify(formatTime()));
    handleReset();
  };

  const openWorkingHourRegistration = () => {
    history.push({
      pathname: '/management/workinghour/create',
    });
    localStorage.setItem('@Jurisnet:counter', JSON.stringify('00:00'));
    handleReset();
  };

  if (token) {
    return (
      <main>
        <div
          className="btn-toggle"
          onClick={() => handleToggleSidebar(true)}
          role="none"
        >
          <FaBars />
        </div>
        <header>
          <section className="header-content">
            <div className="header-columns">
              <main className="header-main-left">
                <div className="stopwatch-card">
                  <div className="timer">
                    <p>{formatTime()}</p>
                  </div>
                  <div className="buttons">
                    {
                      !isActive
                        ? (
                          <Button
                            icon="pi pi-play"
                            label="Iniciar"
                            className="p-button-sm p-button-primary p-mr-2"
                            onClick={handleStart}
                          />
                        )
                        : (
                          <Button
                            icon="pi pi-pause"
                            iconPos="left"
                            label="Parar"
                            className="p-button-secondary p-button-sm p-mr-2"
                            onClick={handlePause}
                          />
                        )
                    }
                    <Button
                      icon="pi pi-times"
                      iconPos="left"
                      label="Zerar"
                      className="p-button-secondary p-button-sm p-mr-2"
                      onClick={handleReset}
                      disabled={isActive}
                    />
                    <Button
                      icon="pi pi-clock"
                      iconPos="left"
                      label="Cadastrar Hora"
                      className="p-button-primary p-button-sm p-mr-2"
                      onClick={openWorkingHourRegistration}
                      disabled={isActive}
                      style={{ width: '11rem' }}
                    />
                  </div>
                </div>
              </main>
              <main className="header-main-right">
                <span>
                  Olá,
                  {' '}
                  <Link to="/profile">
                    <strong>{userData.name.split(' ').slice(0, -1).join(' ')}</strong>
                    !
                  </Link>
                </span>
              </main>
              <aside className="header-sidebar-first">
                <div className="block top-toggle section-left">
                  <Switch
                    height={16}
                    width={30}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={handleCollapsedChange}
                    checked={collapsed}
                    onColor="#219de9"
                    offColor="#bbbbbb"
                  />
                </div>
              </aside>
              <aside className="header-sidebar-second">
                <button
                  className="logout-button"
                  type="button"
                  onClick={signOut}
                >
                  <FiLogOut />
                  <p><small>Sair</small></p>
                </button>
              </aside>
            </div>
          </section>
        </header>

        <div style={{ maxWidth: '100%' }}>
          {props.children}
          <ScrollTop />
        </div>

        <footer>
          <small>
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            made with
            {' '}
            <FaHeart style={{ color: 'red' }} />
            {' '}
            by
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://pedn.com.br">
              Perspectiva Estratégias Digitais
            </a>
          </small>
        </footer>
      </main>
    );
  }
  return (
    <main>
      <div style={{ maxWidth: '100%' }}>
        {props.children}
      </div>
    </main>
  );
};

export default Main;
