import React, { useEffect, useState } from 'react';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';

import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

interface IFinancialRequests {
  pedido_id: number | null;
  pedido_proc_id: number;
  pedido_descricao: string;
  pedido_possibilidade: number;
  pedido_vl_demandado: number;
  pedido_vl_provavel: number;
  pedido_vl_possivel: number;
  pedido_vl_possivel2: number;
  cod_indexador: number;
  data_inicio_monetario: Date;
  data_inicio_juros: Date;
  correcao_monetaria: string;
  juros_simples: string;
  id_descricao_pedido: number;
  val_juros_atualizado: number;
  val_calculo_indice: number;
  val_juros_provavel: number;
  val_juros_possivel: number;
  val_juros_possivel2: number;
  val_calculo_provavel: number;
  val_calculo_possivel: number;
  val_calculo_possivel2: number;
  data_pedido: Date;
  cod_indexador2: number;
  data_inicio_monetario2: Date;
  cod_indexador3: number;
  data_inicio_monetario3: Date;
  cod_indexador4: number;
  data_inicio_monetario4: Date;
  cod_indexador5: number;
  data_inicio_monetario5: Date;
  negativo: number;
  calc_poss_remota: number;
  calc_vl_cont: number;
  pedido_vl_demandado_original: number;
  pedido_vl_provavel_original: number;
  pedido_vl_possivel_original: number;
  pedido_vl_possivel2_original: number;
  id_pedido_descricao: number;
}

const EditManagement: React.FC = () => {
  PrimeReact.ripple = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [financialItems, setFinancialItems] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [financialRequests, setFinancialRequests] = useState<IFinancialRequests[]>([]);

  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const [lawsuitId, setLawsuitId] = useState(0);

  const [updatedDate, setUpdatedDate] = useState<string | Date | Date[] | null>(null);
  const [value0, setValue0] = useState(0.00);
  const [value1, setValue1] = useState(0.00);
  const [value2, setValue2] = useState(0.00);
  const selected = localStorage.getItem('@Jurisnet:selectedmanagement');
  // let folderNumber = useRef('');
  const [folderNumber, setFolderNumber] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [author, setAuthor] = useState('');
  const [defendant, setDefendant] = useState('');
  const [subject, setSubject] = useState('');
  const [origin, setOrigin] = useState('');

  useEffect(() => {
    if (selected) {
      const selectedManagement = JSON.parse(selected);
      setLawsuitId(selectedManagement.proc_id);
      setFolderNumber(selectedManagement.proc_num_pasta);
      setRegistrationNumber(selectedManagement.proc_reg);
      setAuthor(selectedManagement.proc_autor);
      setDefendant(selectedManagement.proc_reu);
      setSubject(selectedManagement.proc_assunto);
      setOrigin(selectedManagement.origem);
    }
  }, [id, selected]);

  const handleDateUpdate = async () => {
    const formData = {
      proc_pedidos_data_atu: updatedDate,
    };
    await api.patch(`/lawsuits/financialitems/patch/dateupdate/${lawsuitId}`, formData).then((response: { data: boolean; }) => {
      if (response.data === true) {
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    });
  };

  const handleLawsuitValueUpdate = async () => {
    const formData = {
      proc_valor_causa: value1,
    };
    await api.patch(`/lawsuits/financialitems/patch/updatelawsuitvalue/${lawsuitId}`, formData).then((response: { data: boolean; }) => {
      if (response.data === true) {
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    });
  };

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await api.get(`/lawsuits/financialrequests/show/filtered/${lawsuitId}`).then((response: any) => {
        setFinancialRequests(response.data);
        setValue0(response.data.proc_valor_causa);
      });
    })();
  }, [lawsuitId]);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await api.get(`/lawsuits/financialitems/show/filtered/${lawsuitId}`).then((response: any) => {
        setFinancialItems(response.data[0]);

        if (response.data[0]?.proc_pedidos_data_atu) {
          const updatedDateBR = new Date(response.data[0]?.proc_pedidos_data_atu);

          if (response.data[0]?.proc_pedidos_data_atu !== null) {
            const updatedDateISO = new Date(updatedDateBR.toISOString().slice(0, -1));
            if (updatedDateISO.toString() === '01/01/1970') {
              setUpdatedDate(null);
            }
            setUpdatedDate(updatedDateISO);
          }
        } else {
          setUpdatedDate(null);
        }
        if (response.data[0]?.proc_pedidos_data_atu) {
          setValue0(response.data[0].proc_valor_causa);
        } else {
          setValue0(0.00);
        }
      });
    })();
  }, [lawsuitId]);

  return (
    <Container>
      <div className="datatable-responsive">
        <div className="col-12 p-mt-20">
          <header>
            <h2>Editar gerencial</h2>
          </header>

          <div className="card">
            <div className="col-12">
              <div className="field grid">
                <div className="col-12 md:col-6">

                  <table width="1000" id="lawsuit_summary" cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width="150"><strong>Pasta nº:</strong></td>
                        <td width="350">{folderNumber}</td>
                        <td width="20">&nbsp;</td>
                        <td width="150"><strong>Processo nº:</strong></td>
                        <td width="350">{registrationNumber}</td>
                      </tr>
                      <tr className="stripedRows">
                        <td width="150"><strong>Autor(es):</strong></td>
                        <td width="350">{author}</td>
                        <td width="20">&nbsp;</td>
                        <td width="150"><strong>Réu(s):</strong></td>
                        <td width="350">{defendant}</td>
                      </tr>
                      <tr>
                        <td width="150"><strong>Assunto:</strong></td>
                        <td width="350">{subject}</td>
                        <td width="20">&nbsp;</td>
                        <td width="150"><strong>Origem:</strong></td>
                        <td width="350">{origin}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="col-12">
              <header>
                <h4>Datas e valores nominais/econômicos</h4>
              </header>
            </div>
            <div className="col-12 md:col-12 rightDiv btn-list">
              <span className="p-input-icon-left">
                <Button
                  icon="pi pi-arrow-circle-left"
                  iconPos="left"
                  label="Retornar à listagem gerencial"
                  className="p-button p-component p-button-outlined p-button-info p-ml-auto"
                  type="button"
                  onClick={history.goBack}
                />
              </span>
            </div>
            <div className="col-12">
              <div className="field grid">

                <div className="col-12 md:col-12">
                  <label htmlFor="proc_pedidos_data_atu">
                    Data da última atualização dos valores
                  </label>
                </div>
                <div className="col-12 md:col-12">
                  <Calendar
                    inputId="proc_pedidos_data_atu"
                    name="proc_pedidos_data_atu"
                    className="inputfield"
                    value={updatedDate}
                    onChange={(e : CalendarChangeEvent) => {
                      if (e.value !== undefined && e.value !== null) {
                        setUpdatedDate(e.value);
                      }
                    }}
                    keepInvalid
                    showIcon
                    showButtonBar
                    showOnFocus={false}
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    yearRange="1980:2030"
                  />
                  <Button
                    icon="pi pi-check"
                    iconPos="left"
                    label="Atualizar data"
                    className="p-ml-2"
                    type="button"
                    onClick={handleDateUpdate}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="field grid">
                <div className="col-12 md:col-3 ">
                  <label htmlFor="proc_valor_causa">
                    Valor nominal do processo
                  </label>
                  <InputNumber
                    id="proc_valor_causa"
                    name="proc_valor_causa"
                    className="inputfield w-full"
                    value={value0}
                    onValueChange={(e) => { setValue0(e.value || 0); }}
                    placeholder="0,00"
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    locale="pt-BR"
                    readOnly
                  />
                  <small id="proc_valor_causa-help" className="block">(valor dado à causa)</small>
                </div>
                <div className="col-12 md:col-3">
                  <label htmlFor="pedido_vl_demandado">
                    Novo valor
                  </label>
                  <InputNumber
                    id="proc_valor_causa_novo"
                    name="proc_valor_causa_novo"
                    className="inputfield w-full"
                    value={value1}
                    onValueChange={(e) => { setValue1(e.value || 0); }}
                    placeholder="0,00"
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    locale="pt-BR"
                  />
                </div>
                <div className="col-12 md:col-3">
                  <br />
                  <Button
                    icon="pi pi-check"
                    iconPos="left"
                    label="Atualizar valor"
                    type="button"
                    onClick={handleLawsuitValueUpdate}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="field grid">
                <div className="col-12 md:col-3">
                  <label htmlFor="pedido_vl_demandado_total">
                    Valor econômico do processo
                  </label>
                  <InputNumber
                    id="pedido_vl_demandado_total"
                    name="pedido_vl_demandado_total"
                    className="inputfield w-full"
                    value={value2}
                    onValueChange={(e) => { setValue2(e.value || 0); }}
                    placeholder="0,00"
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    locale="pt-BR"
                    readOnly
                  />
                  <small id="pedido_vl_demandado_total-help" className="block">(soma dos pedidos)</small>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </Container>

  );
};

export default EditManagement;
