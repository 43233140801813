/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import React, { useCallback, useEffect, useState } from "react";

import * as Yup from "yup";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { SelectButton } from "primereact/selectbutton";

import ReactDOM from "react-dom";
import { useHistory, useParams } from "react-router-dom";
import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";

import { Container } from "./styles";

interface AttorneyFormData {
  advogado_id?: number;
  advogado_nome: string;
  advogado_login: string;
  advogado_senha: string;
  advogado_email: string;
  advogado_oab: string;
  advogado_categoria: number;
  advogado_ativo: boolean;
  advogado_nasc: Date;
  advogado_naturalidade: string;
  advogado_naturalidade_uf: string;
  advogado_end_residencial: string;
  advogado_end_residencial_compl: string;
  advogado_endereco_cep: string;
  advogado_endereco_bairro: string;
  advogado_endereco_cidade: string;
  advogado_endereco_uf: string;
  advogado_fone_residencial: string;
  advogado_fone_celular: string;
  advogado_conjuge_nome: string;
  advogado_conjuge_nasc: Date;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adv: number;
  advogado_valor: string;
  advogado_setor: string;
}

const AddEditAttorney: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const [oldPassword, setOldPassword] = useState<string>("");
  const [oldStatus, setOldStatus] = useState<boolean>();
  const [groupId, setGroupId] = useState(0);

  const { updateUser } = useAuth();

  const initialValues: AttorneyFormData = {
    advogado_nome: "",
    advogado_login: "",
    advogado_senha: "",
    advogado_email: "",
    advogado_oab: "",
    advogado_categoria: 3,
    advogado_ativo: true,
    advogado_nasc: new Date(0),
    advogado_naturalidade: "",
    advogado_naturalidade_uf: "",
    advogado_end_residencial: "",
    advogado_end_residencial_compl: "",
    advogado_endereco_cep: "",
    advogado_endereco_bairro: "",
    advogado_endereco_cidade: "",
    advogado_endereco_uf: "",
    advogado_fone_residencial: "",
    advogado_fone_celular: "",
    advogado_conjuge_nome: "",
    advogado_conjuge_nasc: new Date(0),
    permissao_relatoriov: 0,
    permissao_relatorioiv: 0,
    sta_new_senha: false,
    cat_adv: 0,
    advogado_valor: "0",
    advogado_setor: "",
  };

  const options = [
    { name: "Sim", value: 1 },
    { name: "Não", value: 0 },
  ];

  const attorneyActiveOptions = [
    { name: "Sim", value: true },
    { name: "Não", value: false },
  ];

  const [groupOptions, setGroupOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get("management/groups/show/all").then((response) => {
        response.data.map((group: { grupo_id: number; grupo_nome: string }) =>
          groupOptions.push({ name: group.grupo_nome, value: group.grupo_id })
        );
      });
      groupOptions.splice(0, 1);
      setGroupOptions(groupOptions);
    })();
  }, [groupOptions]);

  const validationSchema = Yup.object().shape({
    advogado_nome: Yup.string().required("Nome obrigatório"),
    advogado_setor: Yup.string().required("Setor obrigatório"),
    advogado_email: Yup.string()
      .required()
      // eslint-disable-next-line no-useless-escape
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .email("Digite um e-mail válido"),
    advogado_login: Yup.string().required("Nome de login obrigatório"),
    advogado_senha: Yup.string()
      .min(8, "A senha deve ter pelo menos 8 caracteres")
      .required("Senha é obrigatória"),
    advogado_oab: Yup.string().required("Nr. OAB obrigatório"),
  });

  const handleOnSubmit = (
    values: AttorneyFormData,
    { setSubmitting }: FormikHelpers<AttorneyFormData>
  ) => {
    if (isAddMode) {
      try {
        api.post("/management/attorneys/create", values);
        setSubmitting(false);

        addToast({
          type: "success",
          title: "Cadastro atualizado!",
          description:
            "As informações do cadastro foram atualizadas com sucesso.",
        });

        try {
          const userFormData = {
            name: values.advogado_nome,
            login_name: values.advogado_login,
            legacy_id: values.advogado_id,
            password: values.advogado_senha,
            email: values.advogado_email,
            active: true,
            user_level: 2,
          };

          api.post("/users", userFormData);

          addToast({
            type: "success",
            title: "Acesso liberado!",
            description:
              "O acesso ao sistema para o advogado foi criado com sucesso!",
          });
        } catch (error) {
          addToast({
            type: "error",
            title: "Ocorreu um problema",
            description: `Tente repetir apenas a liberação de acesso ao sistema ou contate o administrador. (${error})`,
          });
        }

        history.push("/management/attorney");
      } catch (error) {
        addToast({
          type: "error",
          title: "Ocorreu um problema",
          description: "Tente repetir o cadastro ou contate o administrador.",
        });
      }
    } else {
      try {
        api.put(`/management/attorneys/edit/${id}`, values);

        if (
          oldPassword !== values.advogado_senha ||
          oldStatus !== values.advogado_ativo
        ) {
          const userFormData = {
            name: values.advogado_nome,
            login_name: values.advogado_login,
            legacy_id: values.advogado_id,
            email: values.advogado_email,
            old_password: oldPassword,
            password: values.advogado_senha,
            password_confirmation: values.advogado_senha,
            active: values.advogado_ativo,
            user_level: 2,
          };

          api.put("/profile", userFormData).then((response) => {
            updateUser(response.data);
          });
        }

        setSubmitting(false);

        addToast({
          type: "success",
          title: "Cadastro atualizado!",
          description:
            "As informações do cadastro foram atualizadas com sucesso.",
        });

        history.push("/management/attorney");
      } catch (error) {
        addToast({
          type: "error",
          title: "Ocorreu um problema",
          description: "Tente repetir o cadastro ou contate o administrador.",
        });
      }
    }
  };

  const handleCEP = useCallback((ev, setFieldValue) => {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setFieldValue("advogado_endereco_cep", data.cep);
        setFieldValue("advogado_end_residencial", data.logradouro);
        setFieldValue("advogado_endereco_bairro", data.bairro);
        setFieldValue("advogado_endereco_cidade", data.localidade);
        setFieldValue("advogado_endereco_uf", data.uf);
      });
  }, []);

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>{isAddMode ? "Cadastrar " : "Editar "} advogado</h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues,
            resetForm,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              (async () => {
                try {
                  if (!isAddMode) {
                    await api
                      .get(`management/attorneys/show/${id}`)
                      .then((response) => {
                        setValues(response.data);

                        // validate and split data from API
                        const advogado_end_residencial = response.data
                          .advogado_end_residencial
                          ? response.data.advogado_end_residencial.split("|")
                          : "";
                        const advogado_end_residencial_compl = response.data
                          .advogado_end_residencial_compl
                          ? response.data.advogado_end_residencial_compl.split(
                              "|"
                            )
                          : "";
                        const advogado_naturalidade_vetor =
                          response.data.advogado_naturalidade !== ""
                            ? response.data.advogado_naturalidade.split("|")
                            : "";

                        if (advogado_naturalidade_vetor.length > 0) {
                          setFieldValue(
                            "advogado_naturalidade",
                            advogado_naturalidade_vetor[0],
                            false
                          );
                          setFieldValue(
                            "advogado_naturalidade_uf",
                            advogado_naturalidade_vetor[1],
                            false
                          );
                        }

                        if (advogado_end_residencial_compl !== "") {
                          setFieldValue(
                            "advogado_endereco_cep",
                            advogado_end_residencial_compl[0]
                          );
                          setFieldValue(
                            "advogado_endereco_cidade",
                            advogado_end_residencial_compl[1]
                          );
                          setFieldValue(
                            "advogado_endereco_uf",
                            advogado_end_residencial_compl[2]
                          );
                        }

                        if (advogado_end_residencial !== "") {
                          setFieldValue(
                            "advogado_end_residencial",
                            advogado_end_residencial[0]
                          );
                          setFieldValue(
                            "advogado_endereco_bairro",
                            advogado_end_residencial[1]
                          );
                        }

                        let dateAttorney =
                          response.data.advogado_nasc !== null
                            ? new Date(
                                response.data.advogado_nasc
                              ).toLocaleDateString("pt-br", { timeZone: "UTC" })
                            : new Date(0).toLocaleDateString("pt-br", {
                                timeZone: "UTC",
                              });

                        let datePartner =
                          response.data.advogado_conjuge_nasc !== null
                            ? new Date(
                                response.data.advogado_conjuge_nasc
                              ).toLocaleDateString("pt-br", { timeZone: "UTC" })
                            : new Date(0).toLocaleDateString("pt-br", {
                                timeZone: "UTC",
                              });

                        if (
                          dateAttorney === "31/12/1969" ||
                          response.data.advogado_nasc === null
                        ) {
                          dateAttorney = "";
                        }
                        if (
                          datePartner === "31/12/1969" ||
                          response.data.advogado_conjuge_nasc === null
                        ) {
                          datePartner = "";
                        }

                        ReactDOM.unstable_batchedUpdates(() => {
                          // setValues(response.data);
                          setFieldValue("advogado_nasc", dateAttorney);
                          setFieldValue("advogado_conjuge_nasc", datePartner);
                          setOldPassword(response.data.advogado_senha);
                          setOldStatus(response.data.advogado_ativo);
                          setGroupId(response.data.cat_adv);
                        });
                      });
                  }
                } catch (e) {
                  addToast({
                    type: "error",
                    title: "Ocorreu um problema",
                    description:
                      "Não foi possível carregar as informações do cadastro selecionado.",
                  });
                }
              })();
            }, [setValues, setFieldValue]); //
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_nome">Nome *</label>
                        <InputText
                          id="advogado_nome"
                          name="advogado_nome"
                          className={` ${
                            errors.advogado_nome && touched.advogado_nome
                              ? "inputfield w-full input-error"
                              : "inputfield w-full"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.advogado_nome}
                        />
                        <ErrorMessage name="advogado_nome">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-6 lg:col-6">
                        <label htmlFor="advogado_email">
                          Endereço de e-mail *
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-envelope" />
                          </span>
                          <InputText
                            id="advogado_email"
                            name="advogado_email"
                            className="inputfield w-full"
                            value={values.advogado_email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_login">Login *</label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-user" />
                          </span>
                          <InputText
                            id="advogado_login"
                            name="advogado_login"
                            className={` ${
                              errors.advogado_login && touched.advogado_login
                                ? "inputfield w-full input-error"
                                : "inputfield w-full"
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.advogado_login}
                            placeholder="nome de login"
                          />
                        </div>
                        <ErrorMessage name="advogado_login">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_senha">
                          Senha de acesso *
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-lock" />
                          </span>
                          <Password
                            id="advogado_senha"
                            name="advogado_senha"
                            className={` ${
                              errors.advogado_senha && touched.advogado_senha
                                ? "inputfield w-full input-error"
                                : "inputfield w-full"
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.advogado_senha}
                            toggleMask
                          />
                        </div>
                        <ErrorMessage name="advogado_senha">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <header>
                      <h3>Informações administrativas</h3>
                    </header>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="advogado_oab">Nº da OAB *</label>
                        <InputText
                          id="advogado_oab"
                          name="advogado_oab"
                          className={` ${
                            errors.advogado_oab && touched.advogado_oab
                              ? "inputfield w-full input-error"
                              : "inputfield w-full"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.advogado_oab}
                        />
                        <ErrorMessage name="advogado_oab">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="advogado_setor">Setor *</label>
                        <InputText
                          id="advogado_setor"
                          name="advogado_setor"
                          className={` ${
                            errors.advogado_setor && touched.advogado_setor
                              ? "inputfield w-full input-error"
                              : "inputfield w-full"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.advogado_setor}
                        />
                        <ErrorMessage name="advogado_setor">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="cat_adm">Grupo</label>
                        <Dropdown
                          id="cat_adv"
                          name="cat_adv"
                          value={groupId}
                          options={groupOptions}
                          onChange={(item) => {
                            setGroupId(item.value.cat_adv);
                            handleChange(item);
                          }}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                        <ErrorMessage name="cat_adv">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="advogado_valor">Valor hora</label>
                        <InputText
                          id="advogado_valor"
                          name="advogado_valor"
                          className={` ${
                            errors.advogado_valor && touched.advogado_valor
                              ? "inputfield w-full input-error"
                              : "inputfield w-full"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.advogado_valor}
                        />
                        <ErrorMessage name="advogado_valor">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <header>
                      <h3>Acesso relatórios</h3>
                    </header>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="permissao_relatorioiv">
                          Visualiza relatório IV? *
                        </label>
                        <SelectButton
                          id="permissao_relatorioiv"
                          value={values.permissao_relatorioiv}
                          options={options}
                          optionLabel="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="permissao_relatorioiv">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="permissao_relatoriov">
                          Visualiza relatório V? *
                        </label>
                        <SelectButton
                          id="permissao_relatoriov"
                          value={values.permissao_relatoriov}
                          options={options}
                          optionLabel="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="permissao_relatoriov">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="advogado_ativo">Cadastro ativo?</label>
                        <SelectButton
                          id="advogado_ativo"
                          value={values.advogado_ativo}
                          options={attorneyActiveOptions}
                          optionLabel="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <header>
                      <h3>Endereço</h3>
                    </header>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="advogado_endereco_cep">
                          CEP
                          <InputMask
                            id="advogado_endereco_cep"
                            name="advogado_endereco_cep"
                            className="inputfield w-full"
                            mask="99.999-999"
                            value={values.advogado_endereco_cep}
                            onChange={handleChange}
                            onBlur={(ev) => handleCEP(ev, setFieldValue)}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-9">
                        <label htmlFor="advogado_end_residencial">
                          Logradouro
                          <InputText
                            id="advogado_end_residencial"
                            name="advogado_end_residencial"
                            className="inputfield w-full"
                            placeholder="Rua, Avenida, etc"
                            value={values.advogado_end_residencial}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="advogado_endereco_bairro">
                          Bairro
                          <InputText
                            id="advogado_endereco_bairro"
                            name="advogado_endereco_bairro"
                            className="inputfield w-full"
                            value={values.advogado_endereco_bairro}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="advogado_endereco_cidade">
                          Cidade
                          <InputText
                            id="advogado_endereco_cidade"
                            name="advogado_endereco_cidade"
                            className="inputfield w-full"
                            value={values.advogado_endereco_cidade}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="advogado_endereco_uf">
                          UF (Estado)
                          <InputText
                            id="advogado_endereco_uf"
                            name="advogado_endereco_uf"
                            className="inputfield w-full"
                            placeholder="SC,PR,RS..."
                            value={values.advogado_endereco_uf}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <header>
                      <h3>Informações adicionais</h3>
                    </header>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_fone_residencial">
                          Telefone residencial
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-phone" />
                          </span>
                          <InputMask
                            id="advogado_fone_residencial"
                            name="advogado_fone_residencial"
                            className={` ${
                              errors.advogado_fone_residencial &&
                              touched.advogado_fone_residencial
                                ? "inputfield w-full input-error"
                                : "inputfield w-full"
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.advogado_fone_residencial}
                            mask="(99) 9999-9999"
                          />
                        </div>
                        <ErrorMessage name="advogado_fone_residencial">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_fone_celular">
                          Telefone celular
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-mobile" />
                          </span>
                          <InputMask
                            id="advogado_fone_celular"
                            name="advogado_fone_celular"
                            className={` ${
                              errors.advogado_fone_celular &&
                              touched.advogado_fone_celular
                                ? "inputfield w-full input-error"
                                : "inputfield w-full"
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.advogado_fone_celular}
                            mask="(99) 99999-9999"
                          />
                        </div>
                        <ErrorMessage name="advogado_fone_celular">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="advogado_nasc">
                          Data nascimento advogado
                          <Calendar
                            inputId="advogado_nasc"
                            name="advogado_nasc"
                            className="inputfield w-full"
                            value={isAddMode ? undefined : values.advogado_nasc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="advogado_naturalidade">
                          Naturalidade
                          <InputText
                            id="advogado_naturalidade"
                            name="advogado_naturalidade"
                            className="inputfield w-full"
                            value={values.advogado_naturalidade}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="advogado_naturalidade_uf">
                          UF (Estado)
                          <InputText
                            id="advogado_naturalidade_uf"
                            name="advogado_naturalidade_uf"
                            className="inputfield w-full"
                            placeholder="SC,PR,RS..."
                            value={values.advogado_naturalidade_uf}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_conjuge_nome">
                          Nome cônjuge
                          <InputText
                            id="advogado_conjuge_nome"
                            name="advogado_conjuge_nome"
                            className="inputfield w-full"
                            value={values.advogado_conjuge_nome}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="advogado_conjuge_nasc">
                          Data nascimento cônjuge
                          <Calendar
                            id="advogado_conjuge_nasc"
                            name="advogado_conjuge_nasc"
                            className="inputfield w-full"
                            value={
                              isAddMode
                                ? undefined
                                : values.advogado_conjuge_nasc
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento
                      obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">
                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={history.goBack}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default AddEditAttorney;
