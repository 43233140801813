import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  > header {
    height: 100px;
    /* background: #003B6D; */

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;

      svg {
        color: #A9A9A9;
        width: 24px;
        height: 24px;
      }

      h1 {
        display: inline-flex;
        align-self: center;
        margin-left: 35px;
      }
    }
  }

  .schedule-badge, .p-sidebar span.schedule-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }

  .schedule-badge.priority-normal {
      background-color: #C8E6C9;
      color: #256029;
  }

  .schedule-badge.priority-high {
      background-color: #FFCDD2;
      color: #C63737;
  }

  .schedule-badge.priority-low {
      background-color: #B3E5FC;
      color: #23547B;
  }

  .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

.attorney-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -176px auto 0;

  width: 100%;

  form {
    margin: 60px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 24px;
      font-size: 20px;
      text-align: left;
    }
  }

  a {
    color: #f4ede8;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    color: #354985;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #312e38;
    }

    &:hover {
      background: ${shade(0.2, '#354985')};
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #f4f0ec;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #f4f0ec;
  }
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  span {
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #f4ede8;
      width: 70px;

      svg {
        color: #354985;
        margin-right: 8px;
      }
    }

    div {
      flex: 1;
      background: #3e3b47;
      display: flex;
      align-items: center;
      padding: 16px 24px;
      border-radius: 10px;
      margin-left: 24px;

      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }

      strong {
        margin-left: 24px;
        color: #fff;
        font-size: 18px;
      }
    }
`;
