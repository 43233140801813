import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import moment from 'moment';

import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '../styles';
import api from '../../../../services/api';

interface IExportData {
  advogado_setor: string;
  soma: string;
  cliente_nome: string;
}

const LawsuitSummaryReportExcelGrouped: React.FC = () => {
  PrimeReact.ripple = true;

  const [activeLawsuits, setActiveLawsuits] = useState<IExportData[]>([]);
  const [archivedLawsuits, setArchivedLawsuits] = useState<IExportData[]>([]);
  const [allLawsuits, setAllLawsuits] = useState<IExportData[]>([]);
  const [loading, setLoading] = useState(true);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let initialSearchDate = '';
  let finalSearchDate = '';
  let isInterval = '';
  let searchPeriod = '';
  if (state) {
    isInterval = state?.dateFilterType;
    if (isInterval === 'data') {
      initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format('DD/MM/YYYY');
      finalSearchDate = moment(state?.proc_data_ajuiz_final).format('DD/MM/YYYY');
      searchPeriod = `Período: ${initialSearchDate} até ${finalSearchDate}`;
    } else {
      finalSearchDate = state?.proc_data_ajuiz_final;
      searchPeriod = `Período: ${finalSearchDate}`;
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api.post('/reports/lawsuit/grouped/excel/count/0', state).then((responseActive) => {
        setActiveLawsuits(responseActive.data);
      });
      await api.post('/reports/lawsuit/grouped/excel/count/2', state).then((responseArchived) => {
        setArchivedLawsuits(responseArchived.data);
      });
      await api.post('/reports/lawsuit/grouped/excel/count/5', state).then((responseAll) => {
        setAllLawsuits(responseAll.data);
      });
      setLoading(false);
    })();
  }, [state]);

  const exportExcel = () => {
    import('excel4node').then((xlsx) => {
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('Processos Ativos');

      let columnsHeaders = [];

      columnsHeaders = [
        'Setor',
        'Total',
      ];

      worksheet.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet.cell(2, 1).string('Relatório Total de Processos Agrupado por Setor');
      worksheet.cell(3, 1).string(`${searchPeriod}`);

      let headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet.cell(5, headerColumIndex++).string(heading);
      });

      let totalActive = 0;
      let rowIndex = 6;
      activeLawsuits.forEach((item) => {
        worksheet.cell(rowIndex, 1).string(item.advogado_setor);
        worksheet.cell(rowIndex, 2).number(Number(item.soma));
        rowIndex++;
        totalActive += Number(item.soma);
      });

      worksheet.cell(rowIndex, 1).string('Total');
      worksheet.cell(rowIndex, 2).number(totalActive);

      const worksheet2 = workbook.addWorksheet('Processos Arquivados');
      worksheet2.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet2.cell(2, 1).string('Relatório Total de Processos Agrupado por Setor');
      worksheet2.cell(3, 1).string(`${searchPeriod}`);

      headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet2.cell(5, headerColumIndex++).string(heading);
      });

      totalActive = 0;
      rowIndex = 6;
      archivedLawsuits.forEach((item) => {
        worksheet2.cell(rowIndex, 1).string(item.advogado_setor);
        worksheet2.cell(rowIndex, 2).number(Number(item.soma));
        rowIndex++;
        totalActive += Number(item.soma);
      });

      worksheet2.cell(rowIndex, 1).string('Total');
      worksheet2.cell(rowIndex, 2).number(totalActive);

      const worksheet3 = workbook.addWorksheet('Processos Ativos e Arquivados');
      worksheet3.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet3.cell(2, 1).string('Relatório Total de Processos Agrupado por Setor');
      worksheet3.cell(3, 1).string(`${searchPeriod}`);

      headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet3.cell(5, headerColumIndex++).string(heading);
      });

      totalActive = 0;
      rowIndex = 6;
      allLawsuits.forEach((item) => {
        worksheet3.cell(rowIndex, 1).string(item.advogado_setor);
        worksheet3.cell(rowIndex, 2).number(Number(item.soma));
        rowIndex++;
        totalActive += Number(item.soma);
      });

      worksheet3.cell(rowIndex, 1).string('Total');
      worksheet3.cell(rowIndex, 2).number(totalActive);

      const filename = 'Relatorio-soma-processos-agrupado-por-setor';

      workbook.writeToBuffer().then((buffer: BlobPart) => {
        import('file-saver').then((module) => {
          if (module && module.default) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
              type: EXCEL_TYPE,
            });

            module.default.saveAs(data,
              `${filename}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
          }
        });
      });
    });
  };

  const searchLawsuitsSimplified = () => {
    history.push('/report/lawsuit/grouped/excel/search');
  };

  const headerActive = (
    <div className="flex align-items-center export-buttons">
      <span>
        Processos Ativos
        {' '}
        (
        {activeLawsuits[0]?.cliente_nome}
        )
      </span>
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const headerArchived = (
    <div className="flex align-items-center export-buttons">
      <span>
        Processos Arquivados
        {' '}
        (
        {activeLawsuits[0]?.cliente_nome}
        )
      </span>
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const headerAll = (
    <div className="flex align-items-center export-buttons">
      <span>
        Processos Ativos e Arquivados
        {' '}
        (
        {activeLawsuits[0]?.cliente_nome}
        )
      </span>
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const activeLawsuitTotals = () => {
    const total = activeLawsuits
      .map((value) => Number(value.soma))
      .reduce((a, b) => a + b, 0);
    return total;
  };

  const archivedLawsuitTotals = () => {
    const total = archivedLawsuits
      .map((value) => Number(value.soma))
      .reduce((a, b) => a + b, 0);
    return total;
  };

  const allLawsuitTotals = () => {
    const total = allLawsuits
      .map((value) => Number(value.soma))
      .reduce((a, b) => a + b, 0);
    return total;
  };

  const footerGroupActive = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de processos ativos" footerStyle={{ textAlign: 'right' }} />
        <Column footer={activeLawsuitTotals} footerStyle={{ textAlign: 'center' }} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupArchived = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de processos arquivados" footerStyle={{ textAlign: 'right' }} />
        <Column footer={archivedLawsuitTotals} footerStyle={{ textAlign: 'center' }} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupAll = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de processos (ativos e arquivados)" colSpan={1} footerStyle={{ textAlign: 'right' }} />
        <Column footer={allLawsuitTotals} colSpan={1} footerStyle={{ textAlign: 'center' }} />
      </Row>
    </ColumnGroup>
  );

  return (
    <Container>
      <header>
        <div>
          <h3>PABST & HADLICH ADVOGADOS ASSOCIADOS</h3>
          <h5>Relatório Total de Processos Agrupado por Setor - Filtro Excel</h5>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />
          <div className="p-toolbar p-component p-mb-20" role="toolbar">
            <div className="p-toolbar-group-left">
            &nbsp;
            </div>
            <div className="p-toolbar-group-right">
              <Button
                icon="pi pi-search"
                className="p-button-secondary p-mr-2"
                type="button"
                onClick={searchLawsuitsSimplified}
                tooltip="Nova consulta de processos"
                tooltipOptions={{ position: 'right' }}
              />
              <Button
                type="button"
                icon="pi pi pi-file-excel"
                onClick={exportExcel}
                className="p-button-success mr-2"
                tooltip="Exportar todas tabelas para Excel"
                tooltipOptions={{ position: 'left' }}
              />
            </div>
          </div>
          <DataTable
            tableClassName="table-active-lawsuits table"
            id="table-active-lawsuits"
            ref={dt}
            loading={loading}
            value={activeLawsuits}
            dataKey="advogado_setor"
            header={headerActive}
            footerColumnGroup={footerGroupActive}
            stripedRows
            className="p-datatable-sm"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
          >
            <Column field="advogado_setor" header="Setor" alignHeader="center" align="left" style={{ width: '160px' }} />
            <Column field="soma" header="Soma" alignHeader="center" align="center" style={{ width: '160px' }} />
          </DataTable>

          <DataTable
            tableClassName="table-archived-lawsuits table"
            id="table-archived-lawsuits"
            ref={dt}
            loading={loading}
            value={archivedLawsuits}
            editMode="row"
            dataKey="advogado_setor"
            header={headerArchived}
            footerColumnGroup={footerGroupArchived}
            stripedRows
            className="p-datatable-sm"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
          >
            <Column field="advogado_setor" header="Setor" alignHeader="center" align="left" style={{ width: '160px' }} />
            <Column field="soma" header="Soma" alignHeader="center" align="center" style={{ width: '160px' }} />
          </DataTable>

          <DataTable
            tableClassName="table-all-lawsuits table"
            id="table-all-lawsuits"
            ref={dt}
            loading={loading}
            value={allLawsuits}
            editMode="row"
            dataKey="advogado_setor"
            header={headerAll}
            footerColumnGroup={footerGroupAll}
            stripedRows
            className="p-datatable-sm"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
          >
            <Column field="advogado_setor" header="Setor" alignHeader="center" align="left" style={{ width: '160px' }} />
            <Column field="soma" header="Soma" alignHeader="center" align="center" style={{ width: '160px' }} />
          </DataTable>
        </div>
      </div>

    </Container>

  );
};

export default LawsuitSummaryReportExcelGrouped;
