import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

import CompanyLogo from '../../../assets/logo.png';

 interface ILawsuitProperties {
  proc_id: number | null;
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
  cliente_nome: string;
}

const LawsuitSummaryReport: React.FC = () => {
  PrimeReact.ripple = true;

  const [lawsuits, setLawsuits] = useState<ILawsuitProperties[]>([]);
  const [lawsuits2, setLawsuits2] = useState([]);
  const [loading, setLoading] = useState(true);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const state = location.state as any;
        await api.post('/reports/lawsuit/summary', state).then((response) => {
          setLawsuits(response.data);
          setLawsuits2(response.data);
          setLoading(false);
        });
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, location]);

  const cols = [
    { field: 'proc_reg', header: 'Processo N°' },
    { field: 'orgao_descricao', header: 'Órgão Julgador  (Local)' },
    { field: 'proc_autor', header: 'Autor(es)' },
    { field: 'proc_reu', header: 'Réu(s)' },
    { field: 'acao_descricao', header: 'Tipo de processo' },
    { field: 'proc_assunto', header: 'Assunto' },
    { field: 'proc_situacao', header: 'Situação Atual do Processo' },
  ];

  const exportColumns = cols.map((col) => (
    {
      title: col.header,
      dataKey: col.field,
    }));

  const exportPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('l', 'mm', [297, 210]);
    const totalPagesExp = '{total_pages_count_string}';

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: lawsuits[0].cliente_nome,
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    doc.setFont('colibri', 'normal');

    autoTable(doc, {
      columns: exportColumns,
      body: lawsuits2,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: 34 },
        1: { cellWidth: 37.04 },
        4: { cellWidth: 26.46 },
        6: { cellWidth: 30 },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 10,
      },
      alternateRowStyles: {
        fillColor: [226, 226, 226],
      },
      tableLineColor: [204, 204, 204],
      tableLineWidth: 0.1,
      didDrawPage(data) {
        // Header
        doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 15, 47.66, 6.09);

        const { pageSize } = doc.internal;
        const fontSize = doc.getFontSize();
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        const header1 = 'Relatório Resumido de Informações Processuais';
        const header2 = '';
        const header3 = lawsuits && lawsuits.length > 0
          ? `Cliente: ${lawsuits[0].cliente_nome}`
          : '';
        const txtWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        const txtWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        const x1 = (pageWidth - txtWidth1) / 2;
        const x2 = (pageWidth - txtWidth2) / 2;

        doc.setFontSize(14);
        doc.setTextColor(20);
        doc.text(header1, x1, 18);

        doc.setTextColor(40);
        doc.text(header2, x2, 24);

        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text(header3, data.settings.margin.left, 27);
        doc.setFontSize(12);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left, pageHeight - 12,
          data.settings.margin.right, pageHeight - 12,
        );

        // Footer
        const printedOn = new Date();
        const dateFormatted = format(
          printedOn,
          "dd/MM/yyyy 'às' HH:mm:ss'.'",
        );

        let str = `Página ${doc.getNumberOfPages()}`;
        if (typeof doc.putTotalPages === 'function') {
          str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
        }

        doc.setFontSize(8);
        const footerPageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
        doc.text(str, footerPageCount, pageHeight - 8, { align: 'center' });
      },
      margin: { top: 30 },
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.setFontSize(10);

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`RelatorioResumido-${dateFormattedFile}.pdf`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editLawsuit = (rowData:any) => {
    history.push(`/lawsuit/main/edit/${rowData.proc_id}?active=3`);
  };

  const leftToolbarTemplate = () => (
    <>
      {lawsuits && lawsuits.length > 0
        ? (
          <span>
            <strong>Cliente: </strong>
            {lawsuits[0].cliente_nome}
          </span>
        )
        : null}
    </>
  );

  const rightToolbarTemplate = () => (
    <>
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-danger mr-2"
        tooltip="Exportar para PDF / Imprimir"
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  return (
    <Container>
      <header>
        <div>
          <h3>PABST & HADLICH ADVOGADOS ASSOCIADOS</h3>
          <h5>Relatório Resumido de Informações Processuais</h5>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />

          <DataTable
            tableClassName="table-lawsuits table"
            id="table-lawsuits"
            ref={dt}
            loading={loading}
            value={lawsuits}
            editMode="row"
            dataKey="proc_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            onRowDoubleClick={(e) => editLawsuit(e.data)}
            responsiveLayout="scroll"
          >
            <Column field="proc_reg" header="Processo N°" alignHeader="center" align="left" style={{ width: '160px' }} />
            <Column field="orgao_descricao" header="Órgão Julgador (Local)" alignHeader="center" align="left" style={{ width: '140px' }} />
            <Column field="proc_autor" header="Autor(es)" alignHeader="center" align="left" />
            <Column field="proc_reu" header="Réu(s)" alignHeader="center" align="left" />
            <Column field="acao_descricao" header="Tipo de processo" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="proc_assunto" header="Assunto" alignHeader="center" align="left" />
            <Column field="proc_situacao" header="Situação Atual do Processo" alignHeader="center" align="left" style={{ width: '250px' }} />
          </DataTable>
        </div>
      </div>

    </Container>

  );
};

export default LawsuitSummaryReport;
