import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { Container } from '../styles';
import api from '../../../../services/api';

type dataOptions = {
  [key: string]: string;
}

interface IExportData {
  proc_reg: string;
  proc_data_ajuiz: string;
  proc_em_acordo: string;
  proc_desfecho_data: string;
  proc_autor: string;
  proc_reu: string;
  advogado_nome: string;
  proc_localizacao: string;
  acao_descricao: string;
  proc_assunto: string;
  proc_situacao: string;
  pedido_vl_provavel: string;
  pedido_vl_possivel1: string;
  pedido_vl_possivel2: string;
  calc_poss_remota: string;
  valor_causa: string;
  possib_texto: string;
  proc_id: number;
}

interface IFinancialItems {
  proc_id: number;
  proc_pedidos_possib_geral: number;
  proc_valor_causa: number;
}

interface IFinancialRequests {
  pedido_id: number | null;
  pedido_proc_id: number;
  pedido_descricao: string;
  pedido_possibilidade: number;
  pedido_vl_demandado: number;
  pedido_vl_provavel: number;
  pedido_vl_possivel: number;
  pedido_vl_possivel2: number;
  cod_indexador: number;
  data_inicio_monetario: Date;
  data_inicio_juros: Date;
  correcao_monetaria: string;
  juros_simples: string;
  id_descricao_pedido: number;
  val_juros_atualizado: number;
  val_calculo_indice: number;
  val_juros_provavel: number;
  val_juros_possivel: number;
  val_juros_possivel2: number;
  val_calculo_provavel: number;
  val_calculo_possivel: number;
  val_calculo_possivel2: number;
  data_pedido: Date;
  cod_indexador2: number;
  data_inicio_monetario2: Date;
  cod_indexador3: number;
  data_inicio_monetario3: Date;
  cod_indexador4: number;
  data_inicio_monetario4: Date;
  cod_indexador5: number;
  data_inicio_monetario5: Date;
  negativo: number;
  calc_poss_remota: number;
  calc_vl_cont: number;
  pedido_vl_demandado_original: number;
  pedido_vl_provavel_original: number;
  pedido_vl_possivel_original: number;
  pedido_vl_possivel2_original: number;
  id_pedido_descricao: number;
}

const LawsuitSummaryReportExcel: React.FC = () => {
  PrimeReact.ripple = true;

  const [lawsuits, setLawsuits] = useState<IExportData[]>([]);
  const [exportData, setExportData] = useState<IExportData[]>([]);
  const [financialItems, setFinancialItems] = useState<IFinancialItems[]>([]);
  const [financialRequests, setFinancialRequests] = useState<IFinancialRequests[]>([]);
  const [loading, setLoading] = useState(true);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  let status = '0';
  if (state) {
    status = state?.proc_em_acordo;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api.post('/reports/lawsuit/simplified/excel', state).then((response) => {
        setLawsuits(response.data);
      });
      setLoading(false);
    })();
  }, [state]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api.get('/lawsuits/financialitems/show/all').then((response) => {
        setFinancialItems(response.data);
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api.get('/lawsuits/financialrequests/filterallrequests').then((response) => {
        setFinancialRequests(response.data);
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lawsuitExportData: IExportData[] = [];
        lawsuits.forEach((lawsuit) => {
          let pedido_vl_provavel = 0.0;
          let pedido_vl_possivel = 0.0;
          let pedido_vl_possivel2 = 0.0;
          let calc_poss_remota = 0.0;
          let possib_texto = '';
          let proc_valor_causa = '0';

          const filteredGainLoss = financialItems.filter((item) => (
            item.proc_id === lawsuit.proc_id
          ), []);
          const possibility = filteredGainLoss[0]?.proc_pedidos_possib_geral
            ? filteredGainLoss[0]?.proc_pedidos_possib_geral
            : 0;
          switch (possibility) {
            case 1:
              possib_texto = 'Êxito';
              break;
            case 2:
              possib_texto = 'Perda';
              break;
            default:
              possib_texto = '';
          }
          proc_valor_causa = filteredGainLoss[0].proc_valor_causa
            ? `${filteredGainLoss[0].proc_valor_causa}`
            : '0.00';

          const filteredFinancialRequests = financialRequests.filter((item) => (
            item.pedido_proc_id === lawsuit.proc_id
          ), []);

          pedido_vl_provavel = filteredFinancialRequests
            .map((value) => Number(value.pedido_vl_provavel))
            .reduce((a, b) => a + b, 0);

          pedido_vl_possivel = filteredFinancialRequests
            .map((value) => Number(value.pedido_vl_possivel))
            .reduce((a, b) => a + b, 0);

          pedido_vl_possivel2 = filteredFinancialRequests
            .map((value) => Number(value.pedido_vl_possivel2))
            .reduce((a, b) => a + b, 0);

          calc_poss_remota = filteredFinancialRequests
            .map((value) => Number(value.calc_poss_remota))
            .reduce((a, b) => a + b, 0);

          const formattedLawsuitValue = Number(proc_valor_causa)
            .toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          const formattedProbable = Number(pedido_vl_provavel)
            .toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          const formattedPossible1 = Number(pedido_vl_possivel)
            .toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

          const formattedPossible2 = Number(pedido_vl_possivel2)
            .toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

          const formattedRemoteCalc = Number(calc_poss_remota)
            .toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

          lawsuitExportData.push({
            proc_id: lawsuit.proc_id,
            proc_reg: lawsuit.proc_reg,
            proc_data_ajuiz: lawsuit.proc_data_ajuiz,
            proc_em_acordo: lawsuit.proc_em_acordo,
            proc_desfecho_data: lawsuit.proc_desfecho_data,
            proc_autor: lawsuit.proc_autor,
            proc_reu: lawsuit.proc_reu,
            advogado_nome: lawsuit.advogado_nome,
            proc_localizacao: lawsuit.proc_localizacao,
            acao_descricao: lawsuit.acao_descricao,
            proc_assunto: lawsuit.proc_assunto,
            proc_situacao: lawsuit.proc_situacao,
            pedido_vl_provavel: formattedProbable,
            pedido_vl_possivel1: formattedPossible1,
            pedido_vl_possivel2: formattedPossible2,
            calc_poss_remota: formattedRemoteCalc,
            possib_texto,
            valor_causa: formattedLawsuitValue,
          });
        });

        setExportData(lawsuitExportData);
      } catch (e) {
        if (typeof e === 'string') {
          e.toUpperCase();
        } else if (e instanceof Error) {
          e.message;
        }
      }
    };

    setLoading(true);
    fetchData();
    setLoading(false);
  }, [addToast, financialItems, financialRequests, lawsuits, location.state]);

  const exportExcel = () => {
    import('excel4node').then((xlsx) => {
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('Horas');

      let columnsHeaders = [];
      const columnsContent: dataOptions[] = [{}];

      columnsHeaders = [
        'Número do processo',
        'Data de Ajuizamento',
        'Situação',
        'Data desfecho',
        'Pólo ativo',
        'Pólo passivo',
        'Adv. Responsável',
        'Localização',
        'Natureza',
        'Objeto',
        'Posição atual',
        'Provável (maior ou igual 80%)',
        'Possível (maior 50%)',
        'Possível (menor ou igual 50%)',
        'Remota (menor ou igual 20%)',
        'Probabilidade',
        'Valor nominal do processo',
      ];

      if (Number(status) === 0) {
        columnsHeaders = [
          'Número do processo',
          'Data de Ajuizamento',
          'Pólo ativo',
          'Pólo passivo',
          'Adv. Responsável',
          'Localização',
          'Natureza',
          'Objeto',
          'Posição atual',
          'Provável (maior ou igual 80%)',
          'Possível (maior 50%)',
          'Possível (menor ou igual 50%)',
          'Remota (menor ou igual 20%)',
          'Probabilidade',
          'Valor nominal do processo',
        ];

        exportData.forEach((item) => {
          columnsContent.push({
            proc_reg: item.proc_reg,
            proc_data_ajuiz: item.proc_data_ajuiz,
            proc_autor: item.proc_autor,
            proc_reu: item.proc_reu,
            advogado_nome: item.advogado_nome,
            proc_localizacao: item.proc_localizacao,
            acao_descricao: item.acao_descricao,
            proc_assunto: item.proc_assunto,
            proc_situacao: item.proc_situacao,
            pedido_vl_provavel: item.pedido_vl_provavel,
            pedido_vl_possivel1: item.pedido_vl_possivel1,
            pedido_vl_possivel2: item.pedido_vl_possivel2,
            calc_poss_remota: item.calc_poss_remota,
            possib_texto: item.possib_texto,
            valor_causa: item.valor_causa,
          });
        });
      } else {
        exportData.forEach((item) => {
          let statusText = 'Ativo';
          if (item.proc_desfecho_data && item.proc_desfecho_data.length > 0) {
            statusText = 'Arquivado';
          }
          columnsContent.push({
            proc_reg: item.proc_reg,
            proc_data_ajuiz: item.proc_data_ajuiz,
            proc_em_acordo: statusText,
            proc_desfecho_data: item.proc_desfecho_data ? item.proc_desfecho_data : '',
            proc_autor: item.proc_autor,
            proc_reu: item.proc_reu,
            advogado_nome: item.advogado_nome,
            proc_localizacao: item.proc_localizacao,
            acao_descricao: item.acao_descricao,
            proc_assunto: item.proc_assunto,
            proc_situacao: item.proc_situacao,
            pedido_vl_provavel: item.pedido_vl_provavel,
            pedido_vl_possivel1: item.pedido_vl_possivel1,
            pedido_vl_possivel2: item.pedido_vl_possivel2,
            calc_poss_remota: item.calc_poss_remota,
            possib_texto: item.possib_texto,
            valor_causa: item.valor_causa,
          });
        });
      }

      worksheet.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet.cell(2, 1).string('Relatório Resumido de Informações Processuais');

      let headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet.cell(4, headerColumIndex++).string(heading);
      });

      let rowIndex = 4;
      columnsContent.forEach((item) => {
        let columnIndex = 1;
        Object.keys(item).forEach((columnName) => {
          worksheet.cell(rowIndex, columnIndex++).string(item[columnName]);
        });
        rowIndex++;
      });

      const filename = 'Relatorio-resumido-informacoes-processuais';

      workbook.writeToBuffer().then((buffer: BlobPart) => {
        import('file-saver').then((module) => {
          if (module && module.default) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
              type: EXCEL_TYPE,
            });

            module.default.saveAs(data,
              `${filename}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
          }
        });
      });
    });
  };

  const searchLawsuitsSimplified = () => {
    history.push('/report/lawsuit/simplified/excel/search');
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        tooltip="Exportar para Excel"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        icon="pi pi-search"
        className="p-button-secondary p-mr-2"
        type="button"
        onClick={searchLawsuitsSimplified}
        tooltip="Nova consulta de processos"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const statusBodyTemplate = (rowData: { proc_desfecho_data: string; }) => {
    if (lawsuits !== undefined) {
      let statusText = 'Ativo';
      if (rowData.proc_desfecho_data) {
        statusText = 'Arquivado';
      }

      return statusText;
    }
    return '';
  };

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  return (
    <Container>
      <header>
        <div>
          <h3>PABST & HADLICH ADVOGADOS ASSOCIADOS</h3>
          <h5>Relatório Resumido de Informações Processuais - Filtro Excel</h5>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-lawsuits table"
            id="table-lawsuits"
            ref={dt}
            loading={loading}
            value={exportData}
            editMode="row"
            dataKey="proc_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50, lawsuits.length]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            responsiveLayout="scroll"
          >
            <Column field="proc_reg" header="Número do processo" alignHeader="center" align="left" style={{ width: '160px' }} />
            <Column field="proc_data_ajuiz" header="Data de Ajuizamento" alignHeader="center" align="left" style={{ width: '160px' }} />
            {Number(status) !== 0 ? (
              <Column field="proc_em_acordo" header="Situação" alignHeader="center" body={statusBodyTemplate} align="left" style={{ width: '160px' }} />
            ) : null}
            {Number(status) !== 0 ? (
              <Column field="proc_desfecho_data" header="Data Desfecho" alignHeader="center" align="left" style={{ width: '160px' }} />
            ) : null}
            <Column field="proc_autor" header="Pólo ativo" alignHeader="center" align="left" />
            <Column field="proc_reu" header="Pólo passivo" alignHeader="center" align="left" />
            <Column field="advogado_nome" header="Adv. Responsável" alignHeader="center" align="left" />
            <Column field="proc_localizacao" header="Localização" alignHeader="center" align="left" style={{ width: '140px' }} />
            <Column field="acao_descricao" header="Natureza" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="proc_assunto" header="Objeto" alignHeader="center" align="left" />
            <Column field="proc_situacao" header="Posição atual" alignHeader="center" align="left" style={{ width: '250px' }} />
            <Column field="pedido_vl_provavel" header="Provável (maior ou igual 80%)" alignHeader="center" align="left" />
            <Column field="pedido_vl_possivel1" header="Possível (maior 50%)" alignHeader="center" align="left" />
            <Column field="pedido_vl_possivel2" header="Possível (menor ou igual 50%)" alignHeader="center" align="left" />
            <Column field="calc_poss_remota" header="Remota (menor ou igual 20%)" alignHeader="center" align="left" />
            <Column field="possib_texto" header="Probabilidade" alignHeader="center" align="left" />
            <Column field="valor_causa" header="Valor nominal do processo" alignHeader="center" align="left" />
          </DataTable>
        </div>
      </div>

    </Container>

  );
};

export default LawsuitSummaryReportExcel;
