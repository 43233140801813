import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';

import { format } from 'date-fns';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import moment from 'moment';
import robotoRegular from '../../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../services/api';

import CompanyLogo from '../../../../assets/logo.png';

interface IPendingHours {
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_descricao: string;
  horas_data: Date;
  horas_hora_inicial: Date;
  horas_hora_final: Date;
  horas_documento: String;
  horas_data_alteracao: Date;
  horas_hora_total: String;
  horas_tipo: String;
  advogado_nome: string;
  administrador_nome: string;
}

const HourPendingReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  const [pendingHours, setPendingHours] = useState<IPendingHours[]>([]);
  const [latestUpdateDate, setLatestUpdateDate] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  const [totalTime, setTotalTime] = useState('');
  const [totalDecimalTime, setTotalDecimalTime] = useState('');

  const convertDecimalToHour = useCallback((totalHour: string) => {
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!totalHour) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = totalHour.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = horas_total_tmp[0].toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);
        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    return total_de_horas;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/reports/hour/general/pending', state).then((response) => {
          setPendingHours(response.data);

          const maxUpdatedDate = new Date(
            Math.max(
              ...response.data.map((element:
                { horas_data_alteracao_original: string | number | Date;
                }) => new Date(element.horas_data_alteracao_original)),
            ),
          );
          setLatestUpdateDate(maxUpdatedDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' }));

          const durations: string[] = [];

          response.data.forEach((data: {
            horas_hora_total: string;
          }) => {
            let horas_total = '';
            let total_de_horas = '';
            let horas_decimal = '0';
            let total_h1 = 0;
            let total_m1 = 0;

            if (!data.horas_hora_total) {
              horas_total = '0:00';
            } else {
              const horas_total_tmp = data.horas_hora_total.split(':');

              if (Number(horas_total_tmp[0]) >= 10) {
                horas_total = `${horas_total_tmp[0]}:`;
              } else {
                horas_total = `0${horas_total_tmp[0]}:`;
              }

              if (Number(horas_total_tmp[1]) > 0) {
                if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                  horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
                } else {
                  horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                }
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                horas_total += '00';
              }

              // const total_de_tmp = data.horas_data_alteracao.split(':');
              total_h1 += Number(horas_total_tmp[0]);

              if (Number(horas_total_tmp[1]) > 0) {
                total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
              }

              if (Number(horas_total_tmp[0]) > 0) {
                horas_decimal = (Number(horas_total_tmp[0])).toString();
              } else {
                horas_decimal = '0';
              }

              const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

              if (minutos > 0) {
                let temp = ((minutos * 100) / 60).toFixed(0);
                // let temp = ((minutos * 100) / 60).toString().replace(',', '');
                if (Number(temp) < 10) {
                  temp = `0${temp}`;
                }

                horas_decimal += `.${temp}`;
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                horas_decimal += '.00';
              }
            }

            if (total_m1 >= 60) {
              total_h1 += 1;
              total_m1 -= 60;
            }

            if (total_h1 >= 10) {
              total_de_horas = `${total_h1}`;
            } else {
              total_de_horas = `0${total_h1}`;
            }

            if (total_m1 >= 10) {
              total_de_horas += `:${total_m1}`;
            } else {
              total_de_horas += `:0${total_m1}`;

              if (Number(horas_decimal) === 0) {
                horas_decimal = '0.00';
              }
            }

            durations.push(total_de_horas);
          });

          const ms = durations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
          const sum = ms.reduce((prev, cur) => prev + cur, 0);

          const d = moment.duration(sum, 'milliseconds');
          const hours = Math.floor(d.asHours());
          const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
          const time = `${hours}:${minutes}`;

          setTotalTime(time);

          const decimalHour = time.split(':')
            .map((val) => parseInt(val, 10))
            .reduce((
              previousValue, currentValue, index,
            // eslint-disable-next-line no-restricted-properties
            ) => previousValue + currentValue / Math.pow(60, index));

          setTotalDecimalTime(decimalHour.toFixed(2).replace('.', ','));
        });

        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, state]);

  const hourBodyTemplate = (rowData: {
    horas_hora_total: string;
  }) => {
    const currentDuration: string[] = [];
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!rowData.horas_hora_total) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = rowData.horas_hora_total.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = (Number(horas_total_tmp[0])).toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);

        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    currentDuration.push(total_de_horas);

    const ms = currentDuration.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
    const sum = ms.reduce((prev, cur) => prev + cur, 0);

    const d = moment.duration(sum, 'milliseconds');
    const hours = Math.floor(d.asHours());
    const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;

    return time;
  };

  const decimalBodyTemplate = (rowData: { horas_hora_total: string; }) => {
    const cleanDuration = convertDecimalToHour(rowData.horas_hora_total);

    const totalHoursArray = cleanDuration.split(':');
    const cleanMinutes = (Number(totalHoursArray[0]) * 60) + Number(totalHoursArray[1]);

    return (cleanMinutes / 60).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 1,
    });
  };

  const exportPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('l', 'mm', [297, 210]);

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular');

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: 'Relatório de Horas Pendentes para Aprovação',
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    const totalPagesExp = '{total_pages_count_string}';

    autoTable(doc, {
      margin: [25, 0, 15, 15],
      html: '#table-pendinghours table',
      includeHiddenHtml: false,
      theme: 'grid',
      tableWidth: 'wrap',
      columnStyles: {
        0: { cellWidth: 21 },
        1: { cellWidth: 40 },
        2: { cellWidth: 45 },
        3: { cellWidth: 19 },
        4: { cellWidth: 55 },
        5: { cellWidth: 20 },
        6: { cellWidth: 12, halign: 'center' },
        7: { cellWidth: 17, halign: 'center' },
        8: { cellWidth: 24 },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 8,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 8,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      footStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 8,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      didDrawPage(data) {
        // Header
        doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

        const { pageSize } = doc.internal;
        const fontSize = doc.getFontSize();
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        const header1 = 'Relatório de Horas';
        const header2 = '';

        const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        const x1 = ((pageWidth - hWidth1) / 2) + 15;
        const x2 = ((pageWidth - hWidth2) / 2) + 15;

        doc.setFontSize(14);
        doc.setTextColor(20);
        doc.text(header1, x1, 14);

        doc.setTextColor(40);
        doc.text(header2, x2, 19);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left, pageHeight - 15,
          data.settings.margin.right, pageHeight - 15,
        );

        // Footer
        const printedOn = new Date();
        const dateFormatted = format(
          printedOn,
          "dd/MM/yyyy 'às' HH:mm:ss'.'",
        );

        let str = `Página ${doc.getNumberOfPages()}`;
        if (typeof doc.putTotalPages === 'function') {
          str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
        }

        doc.setFontSize(8);

        const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
        const x3 = (pageWidth - pageCount) / 2;
        doc.text(str, x3 + 80, pageHeight - 8);
      },
    });

    // Footer
    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const printedOn = new Date();
    const dateFormatted = format(
      printedOn,
      "dd/MM/yyyy 'às' HH:mm:ss'.'",
    );

    let str = `Página ${doc.getNumberOfPages()}`;
    if (typeof doc.putTotalPages === 'function') {
      str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    }

    doc.setFontSize(8);

    const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    const x3 = (pageWidth - pageCount) / 2;
    doc.text(str, x3 + 50, pageHeight - 8);

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`Relatorio-de-horas-${dateFormattedFile}.pdf`);
  };

  const approveAllPendingHours = () => {
    (async () => {
      pendingHours.forEach(async (hour) => {
        await api.patch(`management/pendingworkinghours/patch/hourstatus/${hour.horas_id}`);
      });

      await api.post('/reports/hour/general/pending', state).then((response) => {
        setPendingHours(response.data);
      });
    })();
  };

  const changeHourStatus = async (rowData: { horas_id: number; status_hora: number; }) => {
    await api.patch(`management/pendingworkinghours/patch/hourstatus/${rowData.horas_id}`);
    await api.post('/reports/hour/general/pending', state).then((response) => {
      setPendingHours(response.data);
    });
  };

  const personInChargeBodyTemplate = (rowData: {
    advogado_nome: string;
    administrador_nome: string
  }) => {
    if (pendingHours !== undefined) {
      const filteredAttorney = pendingHours.filter((item) => (
        item.advogado_nome === rowData.advogado_nome
      ), []);

      if (filteredAttorney.length > 0) {
        if (filteredAttorney[0].advogado_nome) {
          return filteredAttorney[0].advogado_nome;
        }
        const filteredAssistant = pendingHours.filter((item) => (
          item.administrador_nome === rowData.administrador_nome
        ), []);

        return filteredAssistant[0].administrador_nome;
      }
    }

    return '';
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-danger mr-2"
        tooltip="Exportar para PDF / Imprimir"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        type="button"
        icon="pi pi-thumbs-up"
        onClick={approveAllPendingHours}
        className="p-button-info mr-2"
        tooltip="Aprovar todas as Horas em Aprovação abaixo"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const editHour = (rowData: DataTableValue) => {
    history.push(`/management/workinghour/edit/${rowData.horas_id}`);
    localStorage.setItem('@Jurisnet:hourreporttab', '1');
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de horas:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
        <Column footer={totalTime} footerStyle={{ textAlign: 'center' }} />
        <Column footer={totalDecimalTime} footerStyle={{ textAlign: 'center' }} />
        <Column colSpan={2} />
      </Row>
    </ColumnGroup>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editHour(rowData); }}
      />
      <Button
        type="button"
        icon={rowData.status_hora === 0 ? 'pi pi-stop-circle' : 'pi pi-thumbs-down'}
        className={rowData.status_hora === 0 ? 'p-button-rounded p-button-text p-button-plain p-button-danger' : 'p-button-rounded p-button-text p-button-plain p-button-info'}
        onClick={() => { changeHourStatus(rowData); }}
        title={`Clique para ${rowData.status_hora === 0 ? 'aprovar' : 'reprovar'} o cadastro`}
      />
    </>
  );

  return (
    <Container>
      <div className="datatable-responsive">
        <Tooltip target=".export-buttons>button" position="bottom" />

        <DataTable
          tableClassName="table-pendinghours table"
          id="table-pendinghours"
          ref={dt}
          loading={loading}
          value={pendingHours}
          editMode="row"
          dataKey="horas_id"
          header={header}
          stripedRows
          className="p-datatable-sm table-pendinghours"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
          rows={pendingHours.length}
          rowsPerPageOptions={[10, 20, 50, pendingHours.length]}
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          globalFilter={globalFilter}
          emptyMessage="Nenhum registro encontrado."
          responsiveLayout="scroll"
          onRowDoubleClick={(e) => editHour(e.data)}
          footerColumnGroup={footerGroup}
        >
          <Column key="hora_data" field="hora_data" header="Data" alignHeader="center" align="left" style={{ width: '100px' }} />
          <Column field="advogado_nome" header="Responsável" alignHeader="center" body={personInChargeBodyTemplate} align="left" style={{ width: '140px' }} />
          <Column field="cliente_nome" header="Cliente" alignHeader="center" align="left" style={{ width: '140px' }} />
          <Column field="horas_referencia_nome" header="Referência" alignHeader="center" align="left" style={{ width: '80px' }} />
          <Column field="horas_descricao" header="Descrição" alignHeader="center" align="left" />
          <Column field="horas_tipo" header="Tipo" alignHeader="center" align="left" style={{ width: '100px' }} />
          <Column field="horas_hora_total" header="Horas" alignHeader="center" body={hourBodyTemplate} align="center" style={{ width: '80px' }} />
          <Column field="horas_decimal" header="Decimais" alignHeader="center" body={decimalBodyTemplate} align="center" style={{ width: '80px' }} />
          <Column field="status" header="Status" alignHeader="center" align="left" style={{ width: '120px' }} />
          <Column body={actionBodyTemplate} align="right" style={{ width: '180px' }} />
        </DataTable>
        <div className="rigthDiv">
          <strong>Data última atualização:&nbsp;</strong>
          {latestUpdateDate}
        </div>
      </div>

    </Container>
  );
};

export default HourPendingReport;
