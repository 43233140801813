import React, { useEffect, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

// import * as Yup from 'yup';

import { nanoid } from 'nanoid';

import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { PickList } from 'primereact/picklist';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import {
  Container,
} from './styles';

interface IBusinessGroupProperties {
  id?: number;
  parent_company_id: number;
  children_companies: string;
  report_columns: string;
}

interface ICustomerProperties {
  cliente_id: number | null;
  cliente_nome: string;
}

const AddEditBusinessGroup:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const customFieldsArray = [
    'proc_reg', 'proc_autor', 'proc_reu', 'proc_situacao', 'proc_cod_natureza',
    'acao_descricao', 'orgao_descricao', 'proc_orgao_cidade', 'proc_orgao_uf',
    'cliente_nome', 'proc_data_ajuiz', 'proc_data_ingresso', 'proc_valor_causa',
  ];

  const [toggleChecked, setToggleChecked] = useState(false);
  const [customFields, setCustomFields] = useState<string[]>([]);

  const onCustomFieldsChange = (e: CheckboxChangeEvent) => {
    const customFieldsList: string[] = [...customFields];

    if (e.checked) {
      customFieldsList.push(e.value);
    } else {
      customFieldsList.splice(customFieldsList.indexOf(e.value), 1);
    }

    setCustomFields(customFieldsList);
  };

  const toggleCheckBoxes = () => {
    if (toggleChecked) {
      setCustomFields(customFieldsArray);
    } else {
      setCustomFields([]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customers, setCustomers] = useState<ICustomerProperties[]>([]);
  const [customersOptions, setCustomersOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get('/customers/show/all').then((response) => {
        setCustomers(response.data);
        setSource(response.data);
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  const [source, setSource] = useState<ICustomerProperties[]>([]);
  const [target, setTarget] = useState<ICustomerProperties[]>([]);

  const sourceItemTemplate = (item: ICustomerProperties) => (
    <span
      onDoubleClick={() => {
        const content = document.getElementsByClassName(('p-picklist-transfer-buttons'))[0];
        const kbButtons = content.getElementsByTagName('button');
        kbButtons[0].click();
      }}
      style={{ display: 'block' }}
    >
      {item.cliente_nome}
    </span>
  );

  const targetItemTemplate = (item: ICustomerProperties) => (
    <span
      onDoubleClick={() => {
        const content = document.getElementsByClassName(('p-picklist-transfer-buttons'))[0];
        const kbButtons = content.getElementsByTagName('button');
        kbButtons[2].click();
      }}
      style={{ display: 'block' }}
    >
      {item.cliente_nome}
    </span>
  );

  const onChange = (event: {
    source: React.SetStateAction<ICustomerProperties[]>;
    target: React.SetStateAction<ICustomerProperties[]>;
  }) => {
    setSource(event.source);
    setTarget(event.target);
  };

  const initialValues = {
    parent_company_id: 0,
    children_companies: '',
    report_columns: customFieldsArray.toString(),
  };

  // const validationSchema = Yup.object().shape({
  //   resultado_descricao: Yup.string().required('Descrição obrigatória'),
  // });

  const handleOnSubmit = async (
    values: IBusinessGroupProperties,
    { setSubmitting }: FormikHelpers<IBusinessGroupProperties>,
  ) => {
    if (isAddMode) {
      try {
        let childrenCompaniesIds = '';

        if (target.length > 0) {
          target.forEach((item: ICustomerProperties) => {
            if (childrenCompaniesIds === '') {
              childrenCompaniesIds = `${item.cliente_id}`;
            } else {
              childrenCompaniesIds += `,${item.cliente_id}`;
            }
          });
        }

        const formData = {
          parent_company_id: values.parent_company_id,
          children_companies: childrenCompaniesIds,
          report_columns: customFields.toString(),
        };

        await api.post('/businessgroups/create', formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/businessgroup');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        await api.put(`/businessgroups/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/businessgroup');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            grupo econômico
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          // validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            dirty,
            isValid,
            handleChange,
            handleSubmit,
            handleReset,
            // setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                if (!isAddMode) {
                  api.get(`/businessgroups/show/${id}`).then((response) => {
                    setValues(response.data);
                  });
                } else {
                  setCustomFields(customFieldsArray);
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              }
            }, [setValues]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="proc_cod_cliente">
                          Empresa mãe do grupo econômico *
                        </label>
                        <Dropdown
                          id="parent_company_id"
                          name="parent_company_id"
                          value={values.parent_company_id}
                          options={customersOptions}
                          onChange={handleChange}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                        <ErrorMessage name="proc_cod_cliente">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="proc_data_ajuiz_inicio">
                          Empresa(s) filha(s) do grupo econômico
                          { ' ' }
                          <Tooltip target=".infoTextChildrenCompanies">
                            <strong>Selecione a(s) empresa(s) filhas do grupo econômico</strong>
                            <br />
                            <strong>Seleção data</strong>
                            <br />
                            <em>
                              Pesquise pela empresa e clique no botão ou dê um
                              duplo clique no nome da empresa para incluir a
                              empresa no grupo econômico desejado
                            </em>
                            <br />
                          </Tooltip>
                          <i
                            className="infoTextChildrenCompanies pi pi-info-circle"
                            data-pr-position="bottom"
                          />
                        </label>
                        <PickList
                          id="selected_companies_id"
                          source={source}
                          target={target}
                          onChange={onChange}
                          sourceItemTemplate={sourceItemTemplate}
                          targetItemTemplate={targetItemTemplate}
                          className="p-mt-10"
                          filter
                          filterBy="cliente_nome"
                          breakpoint="800px"
                          sourceHeader="Todas empresas ativas no sistema"
                          targetHeader="Empresas parte do grupo econômico"
                          sourceStyle={{ height: '30rem' }}
                          targetStyle={{ height: '30rem' }}
                          sourceFilterPlaceholder="Localizar pelo nome"
                          targetFilterPlaceholder="Localizar pelo nome"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-12 p-mb-10">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ajuiz_inicio">
                        Campos vísíveis no relatório customizável
                        { ' ' }
                        <Tooltip target=".infoTextCustomFields">
                          <strong>
                            Selecione todos os campos a serem disponibilizados no
                            relatório inicial por padrão ao gerar as informações
                          </strong>
                          <br />
                          <strong>Seleção de campos</strong>
                          <br />
                          <em>
                            No relatório personalizável gerado, você poderá exibir
                            ou ocultar todos os campos listados abaixo.
                          </em>
                          <br />
                        </Tooltip>
                        <i
                          className="infoTextCustomFields pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-12 md:col-12 flex flex-column gap-1">
                    <ToggleButton
                      onLabel="Marcar todos os campos"
                      offLabel="Desmarcar todos os campos"
                      onIcon="pi pi-check"
                      offIcon="pi pi-times"
                      checked={toggleChecked}
                      onChange={(e) => {
                        setToggleChecked(e.value);
                        toggleCheckBoxes();
                      }}
                      className="w-4"
                    />
                  </div>

                  <div className="col-12 md:col-4 colum-spacer-right">
                    <div className="field grid">
                      <div className="col-12 md:col-12 flex flex-column gap-1">
                        <div className="flex align-items-center p-mt-10">
                          <Checkbox inputId="numero_processo" name="children_companies" value="proc_reg" onChange={onCustomFieldsChange} checked={customFields.includes('proc_reg')} />
                          <label htmlFor="numero_processo" className="ml-2">Número do processo</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="autor" name="children_companies" value="proc_autor" onChange={onCustomFieldsChange} checked={customFields.includes('proc_autor')} />
                          <label htmlFor="autor" className="ml-2">Autor</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="reu" name="children_companies" value="proc_reu" onChange={onCustomFieldsChange} checked={customFields.includes('proc_reu')} />
                          <label htmlFor="reu" className="ml-2">Réu</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="situacao_processo" name="children_companies" value="proc_situacao" onChange={onCustomFieldsChange} checked={customFields.includes('proc_situacao')} />
                          <label htmlFor="situacao_processo" className="ml-2">Situação atual do processo</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="natureza_processo" name="children_companies" value="proc_cod_natureza" onChange={onCustomFieldsChange} checked={customFields.includes('proc_cod_natureza')} />
                          <label htmlFor="natureza_processo" className="ml-2">Natureza do processo</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-4 column-divider">
                    <div className="field grid">
                      <div className="col-12 md:col-12 flex flex-column gap-1">
                        <div key={nanoid()} className="flex align-items-center p-mt-10">
                          <Checkbox inputId="tipo_acao" name="children_companies" value="acao_descricao" onChange={onCustomFieldsChange} checked={customFields.includes('acao_descricao')} />
                          <label htmlFor="tipo_acao" className="ml-2">Tipo de ação</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="orgao_julgador" name="children_companies" value="orgao_descricao" onChange={onCustomFieldsChange} checked={customFields.includes('orgao_descricao')} />
                          <label htmlFor="orgao_julgador" className="ml-2">Órgão julgador</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="cidade" name="children_companies" value="proc_orgao_cidade" onChange={onCustomFieldsChange} checked={customFields.includes('proc_orgao_cidade')} />
                          <label htmlFor="cidade" className="ml-2">Cidade</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="estado" name="children_companies" value="proc_orgao_uf" onChange={onCustomFieldsChange} checked={customFields.includes('proc_orgao_uf')} />
                          <label htmlFor="estado" className="ml-2">Estado</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:col-4 colum-spacer-left">
                    <div className="field grid">
                      <div className="col-12 md:col-12 flex flex-column gap-1">
                        <div key={nanoid()} className="flex align-items-center p-mt-10">
                          <Checkbox inputId="cliente_id" name="children_companies" value="cliente_nome" onChange={onCustomFieldsChange} checked={customFields.includes('cliente_nome')} />
                          <label htmlFor="cliente_id" className="ml-2">Cliente</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="data_ajuizamento" name="children_companies" value="proc_data_ajuiz" onChange={onCustomFieldsChange} checked={customFields.includes('proc_data_ajuiz')} />
                          <label htmlFor="data_ajuizamento" className="ml-2">Data do ajuizamento</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="data_ingresso" name="children_companies" value="proc_data_ingresso" onChange={onCustomFieldsChange} checked={customFields.includes('proc_data_ingresso')} />
                          <label htmlFor="data_ingresso" className="ml-2">Ingresso no escritório</label>
                        </div>
                        <div key={nanoid()} className="flex align-items-center">
                          <Checkbox inputId="valor_nominal" name="children_companies" value="proc_valor_causa" onChange={onCustomFieldsChange} checked={customFields.includes('proc_valor_causa')} />
                          <label htmlFor="valor_nominal" className="ml-2">Valor nominal</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditBusinessGroup;
