import React, {
  useEffect, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';

import { useHistory, useLocation } from 'react-router-dom';
import { Container } from './styles';

import PendingHours from './pending';
import Management from './management';

const HourGeneralReport: React.FC = () => {
  PrimeReact.ripple = true;

  const history = useHistory();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  const active = params.get('active');
  const [activeIndex, setActiveIndex] = useState(0);

  let mod = 'IV';
  if (state) {
    if (state?.report_number) {
      mod = state.report_number;
    }
  }

  useEffect(() => {
    if (active !== undefined || active !== null) {
      setActiveIndex(Number(active));
    }
    const hourreporttab = localStorage.getItem('@Jurisnet:hourreporttab');
    if (hourreporttab) {
      const activeTab = JSON.parse(hourreporttab);
      setActiveIndex(Number(activeTab));
      localStorage.removeItem('@Jurisnet:hourreporttab');
    }
  }, [active, history]);

  const searchHours = () => {
    history.push('/report/hour/general/search');
  };

  const tabHeaderIVTemplate = () => (
    <>
      <div className="rightAlign">
        <Button
          icon="pi pi-search"
          iconPos="left"
          className="p-button p-component p-button-secondary"
          type="button"
          onClick={searchHours}
          tooltip="Consultar horas"
          tooltipOptions={{ position: 'left' }}
        />
      </div>
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h3>
            Relatório de Horas - Modelo
            {' '}
            {mod}
          </h3>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">

          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Horas pendentes de aprovação">

              <PendingHours />

            </TabPanel>
            <TabPanel header="Horas faturadas/a faturar">

              <Management />

            </TabPanel>

            <TabPanel header="Conultar horas" headerTemplate={tabHeaderIVTemplate} headerClassName="pushRight" />
          </TabView>

        </div>
      </div>

    </Container>
  );
};

export default HourGeneralReport;
