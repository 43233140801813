import React, { useEffect, useState } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import {
  Container,
} from './styles';

interface IScheduleProgress {
  schedule_id: number;
  sp_date: Date;
  sp_description: string;
  user_id: string;
}

const AddEditScheduleProgress:React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');

  const returnScheduleProgressURL = `/schedule/progress/?cod=${cod}`;

  const initialValues = {
    schedule_id: Number(id),
    sp_date: new Date(),
    sp_description: '',
    user_id: user.id,
  };

  const validationSchema = Yup.object().shape({
    sp_description: Yup.string().required('Descrição obrigatória'),
  });

  const handleCancel = () => {
    history.push(returnScheduleProgressURL);
  };

  const handleOnSubmit = async (
    values: IScheduleProgress,
    { setSubmitting }: FormikHelpers<IScheduleProgress>,
  ) => {
    if (isAddMode) {
      try {
        const formData = {
          schedule_id: Number(cod),
          sp_date: progressDate,
          sp_description: values.sp_description,
          user_id: user.id,
        };

        await api.post('/schedules/progresses/create', formData).then(() => {
          setSubmitting(false);

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description:
              'As informações do cadastro foram atualizadas com sucesso.',
          });
        });

        history.push(returnScheduleProgressURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        const formData = {
          schedule_id: Number(cod),
          sp_date: progressDate,
          sp_description: values.sp_description,
          user_id: user.id,
        };

        await api.put(`/schedules/progresses/edit/${id}`, formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnScheduleProgressURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                `Tente repetir o cadastro ou contate o administrador. ${error}`,
        });
      }
    }
  };

  const [progress, setProgresses] = useState(null);
  const [progressDate, setProgressDate] = useState<string | Date | Date[] | null>(new Date());

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            andamento da atividade
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={progress || initialValues}
        >
          {({
            values,
            dirty,
            isValid,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              (async () => {
                try {
                  if (!isAddMode) {
                    api.get(`/schedules/progresses/show/${id}`).then((response) => {
                      setValues(response.data);
                      setProgresses(response.data);

                      if (response.data.sp_date !== null) {
                        const current = new Date(response.data.sp_date);
                        const utc = new Date(
                          current.getTime()
                          + current.getTimezoneOffset()
                          * 60000,
                        );

                        setProgressDate(new Date(utc));
                      }
                    });
                  }
                } catch (e) {
                  addToast({
                    type: 'error',
                    title: 'Ocorreu um problema',
                    description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                  });
                }
              })();
            }, [setValues, setFieldValue]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="sp_date">
                          Data *
                        </label>
                        <Calendar
                          inputId="sp_date"
                          name="sp_date"
                          className="inputfield w-full"
                          value={progressDate}
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setProgressDate(e.value);
                            }
                          }}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                          showTime={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <div className="col-12 md:col-12">
                        <label htmlFor="sp_description">
                          Descrição do andamento da atividade/reunião *
                        </label>
                        <InputTextarea
                          id="sp_description"
                          name="sp_description"
                          className="inputfield w-full"
                          value={values.sp_description}
                          onChange={handleChange}
                          rows={5}
                          cols={30}
                        />
                        <ErrorMessage name="sp_description">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        type="button"
                        className="p-button-danger"
                        onClick={handleCancel}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditScheduleProgress;
