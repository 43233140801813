/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  Container,
} from './styles';

interface CustomerFormData {
  cliente_id?: number;
  cliente_nome:string;
  cliente_login: string;
  cliente_senha: string;
  cliente_email: string;
  cliente_contato: string;
  cliente_endereco: string;
  cliente_cidade: string;
  cliente_bairro: string;
  cliente_cep : string;
  cliente_uf: string;
  cliente_fone: string;
  cliente_fax: string;
  cliente_url: string;
  cliente_classificacao: number;
  cliente_categoria: number;
  cliente_ativo:boolean;
  cliente_tratamento: string;
  cliente_cnpj_cpf: string;
  cliente_naocliente:boolean;
  cliente_informativo:boolean;
}

const AddEditCustomer:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const [oldPassword, setOldPassword] = useState<string>('');
  const [oldStatus, setOldStatus] = useState<boolean>();

  const { updateUser } = useAuth();

  const initialValues: CustomerFormData = {
    cliente_nome: '',
    cliente_login: '',
    cliente_senha: '',
    cliente_email: '',
    cliente_cep: '',
    cliente_endereco: '',
    cliente_bairro: '',
    cliente_cidade: '',
    cliente_uf: '',
    cliente_contato: '',
    cliente_fone: '',
    cliente_fax: '',
    cliente_url: '',
    cliente_classificacao: 0,
    cliente_categoria: 4,
    cliente_ativo: false,
    cliente_tratamento: '',
    cliente_cnpj_cpf: '',
    cliente_naocliente: false,
    cliente_informativo: false,
  };

  const [name, setName] = useState('Nome/Razão Social *');
  const [cpfCnpj, setCpfCnpj] = useState('CPF/CNPJ');
  const options = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false },
  ];
  const optionsCompanyType = [
    { name: 'Pessoa jurídica', value: 1 },
    { name: 'Pessoa física', value: 2 },
  ];

  const validationSchema = Yup.object().shape({
    cliente_nome: Yup.string().required('Nome obrigatório'),
    cliente_email: Yup.string().required()
      // eslint-disable-next-line no-useless-escape
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .email('Digite um e-mail válido'),
    cliente_login: Yup.string().required('Nome de login obrigatório'),
    cliente_senha: Yup.string()
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .max(10, 'Limite de 10 caracteres')
      .required('Senha é obrigatória'),
  });

  const handleOnSubmit = (
    values: CustomerFormData,
    { setSubmitting }: FormikHelpers<CustomerFormData>,
  ) => {
    if (isAddMode) {
      try {
        api.post('/customers/create', values);

        setSubmitting(false);

        try {
          const userFormData = {
            name: values.cliente_nome,
            login_name: values.cliente_login,
            legacy_id: values.cliente_id,
            password: values.cliente_senha,
            email: values.cliente_email,
            active: true,
            user_level: 3,
          };

          api.post('/users', userFormData);

          addToast({
            type: 'success',
            title: 'Acesso liberado!',
            description:
              'O acesso ao sistema para o advogado foi criado com sucesso!',
          });
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Ocorreu um problema',
            description:
                `Tente repetir apenas a liberação de acesso ao sistema ou contate o administrador. (${error})`,
          });
        }

        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/customer');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        api.put(`/customers/edit/${id}`, values);

        if (oldPassword !== values.cliente_senha
           || oldStatus !== values.cliente_ativo) {
          const userFormData = {
            name: values.cliente_nome,
            login_name: values.cliente_login,
            legacy_id: values.cliente_id,
            email: values.cliente_email,
            old_password: oldPassword,
            password: values.cliente_senha,
            password_confirmation: values.cliente_senha,
            active: values.cliente_ativo,
            user_level: 3,
          };

          api.put('/profile', userFormData).then((response) => {
            updateUser(response.data);
          });
        }

        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/customer');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  const handleCEP = useCallback(
    (ev, setFieldValue) => {
      const { value } = ev.target;

      const cep = value?.replace(/[^0-9]/g, '');

      if (cep?.length !== 8) {
        return;
      }

      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          setFieldValue('cliente_cep', data.cep);
          setFieldValue('cliente_endereco', data.logradouro);
          setFieldValue('cliente_bairro', data.bairro);
          setFieldValue('cliente_cidade', data.localidade);
          setFieldValue('cliente_uf', data.uf);
        });
    }, [],
  );

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            cliente
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              let isMounted = true;
              try {
                if (!isAddMode) {
                  if (isMounted) {
                    api.get(`customers/show/${id}`).then((response) => {
                      setValues(response.data);
                      setOldPassword(response.data.cliente_senha);
                      setOldStatus(response.data.cliente_ativo);
                    });
                  }
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              } finally {
                isMounted = false;
              }
            }, [setValues]);
            return (
              <form onSubmit={handleSubmit}>
                <h4>Classificação</h4>
                <div className="fluid formgrid grid">
                  <div className="col-12 md:col-12">
                    <label htmlFor="cliente_classificacao">Classificação do cliente</label>
                    <SelectButton
                      id="cliente_classificacao"
                      value={values.cliente_classificacao}
                      options={optionsCompanyType}
                      optionLabel="name"
                      onChange={(e) => {
                        handleChange(e);
                        if (e.value === 1) {
                          setName('Razão Social *');
                          setCpfCnpj('CNPJ');
                        } else {
                          setName('Nome *');
                          setCpfCnpj('CPF');
                        }
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-9">
                        <label htmlFor="cliente_nome">
                          {name}
                        </label>
                        <InputText
                          id="cliente_nome"
                          name="cliente_nome"
                          className={` ${errors.cliente_nome && touched.cliente_nome ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cliente_nome}
                        />
                        <ErrorMessage name="cliente_nome">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="cliente_cnpj_cpf">
                          {cpfCnpj}
                        </label>
                        <InputText
                          id="cliente_cnpj_cpf"
                          name="cliente_cnpj_cpf"
                          className={` ${errors.cliente_cnpj_cpf && touched.cliente_cnpj_cpf ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cliente_cnpj_cpf}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4 lg:col-4">
                        <label htmlFor="cliente_email">
                          Endereço de e-mail *
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-envelope" />
                          </span>
                          <InputText
                            id="cliente_email"
                            name="cliente_email"
                            className="inputfield w-full"
                            value={values.cliente_email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="cliente_email">
                            { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="cliente_login">
                          Login *
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-user" />
                          </span>
                          <InputText
                            id="cliente_login"
                            name="cliente_login"
                            className={` ${errors.cliente_login && touched.cliente_login ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_login}
                            placeholder="nome de login"
                          />
                        </div>
                        <ErrorMessage name="cliente_login">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="cliente_senha">
                          Senha de acesso *
                        </label>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-lock" />
                          </span>
                          <Password
                            id="cliente_senha"
                            name="cliente_senha"
                            className={` ${errors.cliente_senha && touched.cliente_senha ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_senha}
                            toggleMask
                          />
                        </div>
                        <ErrorMessage name="cliente_senha">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>

                    </div>
                  </div>

                  <div className="col-12">
                    <header>
                      <h3>Endereço</h3>
                    </header>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="cliente_cep">
                          CEP
                          <InputMask
                            id="cliente_cep"
                            name="cliente_cep"
                            className="inputfield w-full"
                            mask="99.999-999"
                            value={values.cliente_cep}
                            onChange={handleChange}
                            onBlur={(ev) => handleCEP(ev, setFieldValue)}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-9">
                        <label htmlFor="cliente_endereco">
                          Logradouro
                          <InputText
                            id="cliente_endereco"
                            name="cliente_endereco"
                            className="inputfield w-full"
                            placeholder="Rua, Avenida, etc"
                            value={values.cliente_endereco}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="cliente_bairro">
                          Bairro
                          <InputText
                            id="cliente_bairro"
                            name="cliente_bairro"
                            className="inputfield w-full"
                            value={values.cliente_bairro}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="cliente_cidade">
                          Cidade
                          <InputText
                            id="cliente_cidade"
                            name="cliente_cidade"
                            className="inputfield w-full"
                            value={values.cliente_cidade}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                        </label>
                      </div>
                      <div className="col-12 md:col-4">
                        <label htmlFor="cliente_estado">
                          UF (Estado)
                          <InputText
                            id="cliente_uf"
                            name="cliente_uf"
                            className="inputfield w-full"
                            placeholder="SC,PR,RS..."
                            value={values.cliente_uf}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <header>
                      <h3>Dados para contato</h3>
                    </header>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_contato">
                          Pessoa de contato
                          <InputText
                            id="cliente_contato"
                            name="cliente_contato"
                            className="inputfield w-full"
                            value={values.cliente_contato}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_tratamento">
                          Tratamento
                          <InputText
                            id="cliente_tratamento"
                            name="cliente_tratamento"
                            className="inputfield w-full"
                            placeholder="ex. Sr., Sra, Dr. etc"
                            value={values.cliente_tratamento}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_fone">
                          Telefone
                          <InputMask
                            id="cliente_fone"
                            name="cliente_fone"
                            className="inputfield w-full"
                            value={values.cliente_fone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            mask="(99) 9999-9999"
                          />
                        </label>
                      </div>
                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_fax">
                          Fax
                          <InputText
                            id="cliente_fax"
                            name="cliente_fax"
                            className="inputfield w-full"
                            value={values.cliente_fax}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6 lg:col-6">
                        <label htmlFor="cliente_url">
                          Website (URL)
                        </label>
                        <div className="p-col-12 p-md-4">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">www</span>
                            <InputText
                              id="cliente_url"
                              name="cliente_url"
                              className="inputfield w-full"
                              placeholder="https://www."
                              value={values.cliente_url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <header>
                      <h3>Informações internas</h3>
                    </header>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_naocliente">É cliente do escritório?</label>
                        <SelectButton
                          id="cliente_naocliente"
                          value={values.cliente_naocliente}
                          options={options}
                          optionLabel="name"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_ativo">Cliente ativo?</label>
                        <SelectButton
                          id="cliente_ativo"
                          value={values.cliente_ativo}
                          options={options}
                          optionLabel="name"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-12 md:col-6 lg:col-3">
                        <label htmlFor="cliente_informativo">Recebe informativo?</label>
                        <SelectButton
                          id="cliente_informativo"
                          value={values.cliente_informativo}
                          options={options}
                          optionLabel="name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        type="button"
                        className="p-button-danger"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditCustomer;
