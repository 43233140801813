import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

interface IFinancialIndexProperties {
  id_indice_finaceito: number | null;
  cod_it_financeiros_indices: string;
  per_indice_finaceito: Date;
  val_indice_finaceito: number;
  indice_nome: string;
}

 interface IIndexerProperties {
  indice_id: number | null;
  indice_nome: string;
}

const FinancialIndex: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyFinancialIndex = {
    id_indice_finaceito: null,
    cod_it_financeiros_indices: '',
    per_indice_finaceito: null,
    val_indice_finaceito: 0,
    indice_nome: '',
  };

  const [financialIndexes, setFinancialIndexes] = useState<IFinancialIndexProperties[]>([]);
  const [financialIndex, setFinancialIndex] = useState(emptyFinancialIndex);
  // eslint-disable-next-line
  const [indexers, setIndexers] = useState<IIndexerProperties[]>([]);
  const [deleteFinancialIndex, setDeleteFinancialIndexDialog] = useState(false);
  const [deleteFinancialIndexsDialog, setDeleteFinancialIndexsDialog] = useState(false);
  const [
    selectedFinancialIndexes, setSelectedFinancialIndexes,
  ] = useState<IFinancialIndexProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await api.get('/finance/financialindexes/show/ordereddata').then((response) => {
        setFinancialIndexes(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await api.get('/finance/indexers/show/all').then((response) => {
        setIndexers(response.data);
      });
    })();
  }, []);

  const columns = [
    { field: 'per_indice_finaceito', header: 'Período', sortable: false },
    { field: 'igpdi', header: 'IGP-DI', sortable: false },
    { field: 'igpm', header: 'IGP-M', sortable: false },
    { field: 'inpc', header: 'INPC', sortable: false },
    { field: 'ipca', header: 'IPCA', sortable: false },
    { field: 'ipcae', header: 'IPCA-E', sortable: false },
    { field: 'poup', header: 'POUP.', sortable: false },
    { field: 'selic', header: 'SELIC', sortable: false },
    { field: 'tr', header: 'TR', sortable: false },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable={col.sortable}
    />
  ));

  const reset = () => {
    setGlobalFilter('');
  // dt.current.reset();
  };

  const handleCreate = () => {
    history.push('/finance/financialindex/create');
  };

  const hidedeleteFinancialIndex = () => {
    setDeleteFinancialIndexDialog(false);
  };

  const hidedeleteFinancialIndexsDialog = () => {
    setDeleteFinancialIndexsDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editFinancialIndex = (rowData:any) => {
    history.push(`/finance/financialindex/edit/${rowData.per_indice_finaceito}-01`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteFinancialIndex = (financialIndex: React.SetStateAction<{
    id_indice_finaceito: null;
    cod_it_financeiros_indices: string;
    per_indice_finaceito: null;
    val_indice_finaceito: number;
    indice_nome: string;
  }>) => {
    setFinancialIndex(financialIndex);
    setDeleteFinancialIndexDialog(true);
  };

  const deleteFinancialIndexDialog = () => {
    api.delete(`/finance/financialindexes/delete/${financialIndex.per_indice_finaceito}`);

    setDeleteFinancialIndexDialog(false);
    reset();

    const updatedRecords = financialIndexes
      .filter((val) => val.per_indice_finaceito !== financialIndex.per_indice_finaceito);
    setFinancialIndexes(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editFinancialIndex(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteFinancialIndex(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteFinancialIndexFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteFinancialIndex} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteFinancialIndexDialog} />
    </>
  );
  const deleteFinancialIndexsDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteFinancialIndexsDialog} />
      {/* <Button label="Sim" icon="pi pi-check" className="p-button-text"
      onClick={deleteselectedFinancialIndexes} /> */}
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Índices Financeiros</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-financialIndexes table"
            id="table-financialIndexes"
            ref={dt}
            value={financialIndexes}
            editMode="row"
            dataKey="indice_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedFinancialIndexes}
            onSelectionChange={(e) => {
              const selFinancialIndexes = e.value as IFinancialIndexProperties[];
              setSelectedFinancialIndexes(selFinancialIndexes);
            }}
            onRowDoubleClick={(e) => editFinancialIndex(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteFinancialIndex} style={{ width: '450px' }} header="Confirmação" modal footer={deleteFinancialIndexFooter} onHide={hidedeleteFinancialIndex}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {financialIndex && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{financialIndex.per_indice_finaceito}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteFinancialIndexsDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteFinancialIndexsDialogFooter} onHide={hidedeleteFinancialIndexsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {financialIndex
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default FinancialIndex;
