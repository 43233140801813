/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface InterventionFormData {
  natureza_interv_descricao: string,
}

const AddEditIntervention:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const initialValues: InterventionFormData = {
    natureza_interv_descricao: '',
  };

  const validationSchema = Yup.object().shape({
    natureza_interv_descricao: Yup.string().required('Descrição obrigatória'),
  });

  const handleOnSubmit = async (
    values: InterventionFormData,
    { setSubmitting }: FormikHelpers<InterventionFormData>,
  ) => {
    if (isAddMode) {
      try {
        await api.post('/lawsuits/interventions/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/lawsuit/intervention/');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        await api.put(`/lawsuits/interventions/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/lawsuit/intervention');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            natureza da intervenção
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            // setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                if (!isAddMode) {
                  api.get(`/lawsuits/interventions/show/${id}`).then((response) => {
                    setValues(response.data);
                  });
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              }
            }, [setValues]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="natureza_interv_descricao">
                          Descrição *
                        </label>
                        <InputText
                          id="natureza_interv_descricao"
                          name="natureza_interv_descricao"
                          className={` ${errors.natureza_interv_descricao && touched.natureza_interv_descricao ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.natureza_interv_descricao}
                        />
                        <ErrorMessage name="natureza_interv_descricao">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditIntervention;
