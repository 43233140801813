/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import ReactDOM from "react-dom";
import * as Yup from "yup";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { OverlayPanel } from "primereact/overlaypanel";
import { RadioButton } from "primereact/radiobutton";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";

import { format } from "date-fns";
import moment from "moment";

import { decode } from "html-entities";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import OverlaySearch from "../../Lawsuit/OverlaySearch";

import { Container } from "./styles";

interface IWorkingHours {
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_cod_responsavel: number;
  horas_descricao: string;
  horas_data: Date;
  horas_hora_inicial: Date | undefined;
  horas_hora_final: Date | undefined;
  horas_valor_hora: number;
  horas_documento: string;
  horas_data_alteracao: Date | undefined;
  horas_hora_total: string;
  horas_documento_seq: number;
  horas_processo_id: number;
  horas_reg: string;
  horas_num_pasta: string;
  horas_autor: string;
  horas_reu: string;
  horas_fatu_de: Date | undefined;
  horas_fatu_ate: Date | undefined;
  horas_fatu_ajuste: number;
  num_seq: number;
  valor_ajuste_nao_faturado: number;
  horas_tipo: string;
  tipo_usuario: string;
  tipo_hora: number;
  horas_hora_inicial_texto: string;
  horas_hora_final_texto: string;
}

const AddEditWorkingHours: React.FC = () => {
  const toast = useRef<Toast | null>(null);
  const { addToast } = useToast();
  const history = useHistory();
  // const location = useLocation();
  // const state = location.state as CustomizedState;

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const op = useRef<OverlayPanel>(null);
  const isMounted = useRef(false);
  let fullSearch = false;
  const hourDefinition = useRef("1");

  const adjustmentManagement = localStorage.getItem("@Jurisnet:ajustevalores");
  let adjustment = "";
  if (adjustmentManagement) {
    adjustment = JSON.parse(adjustmentManagement);
  }

  const cloneWorkingHour = JSON.parse(
    localStorage.getItem("@Jurisnet:workinghour") || "{}"
  );

  let isDuplicate = false;
  if (window.location.href.indexOf("duplicate") > -1) {
    isDuplicate = true;
  }

  let loggedUserLevel = "";
  let loggedUserId = 0;
  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem("@Jurisnet:access");

  if (typeof accessData === "string") {
    const access = JSON.parse(accessData);
    loggedUserLevel = access.nivelusuario;
    loggedUserId = Number(access.usuario_logado);
  }

  let clientId = 0;
  let inChargeId = 0;
  let descriptionText = "";
  let typeText = "";
  if (cloneWorkingHour && isDuplicate) {
    clientId = Number(cloneWorkingHour.horas_cod_cliente);
    inChargeId = Number(cloneWorkingHour.horas_cod_responsavel);
    descriptionText = decode(cloneWorkingHour.horas_descricao);
    typeText = cloneWorkingHour.horas_tipo?.toLowerCase();
  }

  let userTypeDefinition = "adv";
  if (isAddMode) {
    inChargeId = loggedUserId;
    if (loggedUserLevel === "Assistente") {
      userTypeDefinition = "adm";
    }
  }

  const counter = localStorage.getItem("@Jurisnet:counter");
  let timeCounter = "00:00";
  let hoursCounter = "00";
  let minutesCounter = "00";

  if (counter) {
    timeCounter = JSON.parse(counter);

    if (timeCounter !== "00:00") {
      const cleanTime = timeCounter.replaceAll(" ", "").split(":");
      [hoursCounter, minutesCounter] = cleanTime;
    }
  }

  const [customersOptions, setCustomersOptions] = useState([{}]);
  const [hourReferencesOptions, setHourReferencesOptions] = useState([{}]);
  const [attorneysOptions, setAttorneysOptions] = useState([{}]);
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [workingHoursData, setWorkingHoursData] = useState(null);

  const validCustomerValues = useRef<string[]>([]);

  const hourTypesOptions = [
    { name: "Assessoria", value: "assessoria" },
    { name: "Processo", value: "processo" },
  ];

  const userTypesOptions = [
    { name: "Advogado", value: "adv" },
    { name: "Assistente/Estagiário", value: "adm" },
  ];

  useEffect(() => {
    if (isMounted.current) {
      op.current?.hide();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const customersArray = [{ name: "", value: 0 }];
      await api.get("customers/show/all").then((response) => {
        response.data.map(
          (customer: { cliente_id: number; cliente_nome: string }) =>
            customersArray.push({
              name: customer.cliente_nome,
              value: customer.cliente_id,
            })
        );
      });
      customersArray.splice(0, 1);
      setCustomersOptions(customersArray);
      validCustomerValues.current = customersArray.map(({ value }) =>
        value.toString()
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const hoursReferencesArray = [{ name: "", value: 0 }];
      await api.get("lawsuits/references/show/all").then((response) => {
        response.data.map(
          (reference: {
            horas_referencia_id: number;
            horas_referencia_nome: string;
          }) =>
            hoursReferencesArray.push({
              name: reference.horas_referencia_nome,
              value: reference.horas_referencia_id,
            })
        );
      });
      hoursReferencesArray.splice(0, 1);
      setHourReferencesOptions(hoursReferencesArray);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const attorneysArray = [{ name: "", value: 0 }];
      await api.get("management/attorneys/show/all/active").then((response) => {
        response.data.map(
          (attorney: { advogado_id: number; advogado_nome: string }) =>
            attorneysArray.push({
              name: `${attorney.advogado_nome}`,
              value: attorney.advogado_id,
            })
        );
      });
      attorneysArray.splice(0, 1);
      setAttorneysOptions(attorneysArray);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // if (loggedUserLevel === 'Administrador') {
      const assistantsArray = [{ name: "", value: 0 }];
      await api
        .get("management/assistants/show/all/active")
        .then((response) => {
          response.data.map(
            (assistant: {
              administrador_id: number;
              administrador_nome: string;
            }) =>
              assistantsArray.push({
                name: `${assistant.administrador_nome} (Assistente/estagiário)`,
                value: assistant.administrador_id,
              })
          );
        });
      assistantsArray.splice(0, 1);
      setAssistantsOptions(assistantsArray);
    })();
  }, []);

  const [workDate, setWorkDate] = useState<string | Date | Date[] | null>(
    new Date()
  );

  const timeFormatted = format(new Date(), "HH:mm");
  const [timeStartText, setTimeStartText] = useState(timeFormatted);
  const [timeEndText, setTimeEndText] = useState("");
  const [totalTime, setTotalTime] = useState<string | undefined>(
    `${hoursCounter}:${minutesCounter}`
  );
  const [hoursType, setHoursType] = useState(
    cloneWorkingHour && isDuplicate ? typeText : ""
  );
  const [folderNumber, setFolderNumber] = useState("");
  const [type, setType] = useState(
    isAddMode && timeCounter !== "00:00" ? "2" : "1"
  );
  const [selectedDescription, setSelectedDescription] = useState(
    cloneWorkingHour && isDuplicate ? descriptionText : ""
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    cloneWorkingHour && isDuplicate ? clientId : null
  );

  const [personInCharge, setPersonInCharge] = useState(
    isAddMode ? inChargeId : null
  );
  const [userType, setUserType] = useState(userTypeDefinition);
  const [hourReference, setHourReference] = useState(0);

  const data = "00:00";

  useEffect(() => {
    (async () => {
      try {
        localStorage.setItem("@Jurisnet:lawsuit", "");
        localStorage.setItem("@Jurisnet:judgingbody", "");
        localStorage.removeItem("@Jurisnet:foldernumber");

        if (!isAddMode) {
          await api
            .get(`/management/workinghours/show/${id}`)
            .then((response) => {
              setWorkingHoursData(response.data);
              ReactDOM.unstable_batchedUpdates(async () => {
                if (!response.data.horas_hora_inicial) {
                  hourDefinition.current = "2";
                }

                setSelectedCustomer(Number(response.data.horas_cod_cliente));
                setPersonInCharge(Number(response.data.horas_cod_responsavel));
                setHourReference(Number(response.data.horas_cod_referencia));
                setHoursType(response.data.horas_tipo.toString());
                setType(hourDefinition.current);
                setUserType(response.data.tipo_usuario.toString());

                const workDateBR = new Date(response.data.horas_data);
                if (response.data.horas_data !== null) {
                  const workDateISO = new Date(
                    workDateBR.toISOString().slice(0, -1)
                  );
                  setWorkDate(workDateISO);
                }

                const timeStartBR = new Date(response.data.horas_hora_inicial);
                const formattedStartTime = timeStartBR.toLocaleString("pt-BR", {
                  timeZone: "UTC",
                });

                let startTime = "";
                let endTime = "";

                if (formattedStartTime.toString() !== "00:00") {
                  const cleanInitialTime = format(
                    new Date(formattedStartTime),
                    "HH:mm"
                  );
                  startTime = cleanInitialTime;
                  setTimeStartText(cleanInitialTime);
                } else {
                  setTimeStartText("00:00");
                }

                const timeEndBR = new Date(response.data.horas_hora_final);
                const formattedEndTime = timeEndBR.toLocaleString("pt-BR", {
                  timeZone: "UTC",
                });

                if (formattedEndTime.toString() !== "00:00") {
                  const cleanEndTime = format(
                    new Date(formattedEndTime),
                    "HH:mm"
                  );
                  endTime = cleanEndTime;
                  setTimeEndText(cleanEndTime);
                } else {
                  setTimeEndText("00:00");
                }

                if (
                  !response.data.horas_hora_total &&
                  startTime.length > 0 &&
                  endTime.length > 0
                ) {
                  const todayDate = format(new Date(), "yyyy-MM-dd");
                  const startDate = new Date(`${todayDate} ${startTime}:00`);
                  const endDate = new Date(`${todayDate} ${endTime}:00`);
                  let timeDiff = Math.abs(
                    startDate.getTime() - endDate.getTime()
                  );

                  let hh = Math.floor(timeDiff / 1000 / 60 / 60).toString();
                  hh = `0${hh}`.slice(-2);

                  timeDiff -= Number(hh) * 1000 * 60 * 60;
                  let mm = Math.floor(timeDiff / 1000 / 60).toString();
                  mm = `0${mm}`.slice(-2);

                  setTotalTime(`${hh}:${mm}`);
                } else {
                  const currentDuration: string[] = [];
                  const horas_total_tmp =
                    response.data.horas_hora_total.split(":");
                  let horas_total = "";
                  let total_de_horas = "";
                  let horas_decimal = "0";
                  let total_h1 = 0;
                  let total_m1 = 0;

                  if (Number(horas_total_tmp[0]) >= 10) {
                    horas_total = `${horas_total_tmp[0]}:`;
                  } else {
                    horas_total = `0${horas_total_tmp[0]}:`;
                  }

                  if (Number(horas_total_tmp[1]) > 0) {
                    if ((Number(horas_total_tmp[1]) / 60) * 100 >= 10) {
                      horas_total += ((Number(horas_total_tmp[1]) / 60) * 100)
                        .toFixed(0)
                        .toString();
                    } else {
                      horas_total += `0${
                        (Number(horas_total_tmp[1]) / 60) * 100
                      }`;
                    }
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    horas_total += "00";
                  }

                  total_h1 += Number(horas_total_tmp[0]);

                  if (Number(horas_total_tmp[1]) > 0) {
                    total_m1 += Number(
                      ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0)
                    );
                  }

                  if (Number(horas_total_tmp[0]) > 0) {
                    horas_decimal = Number(horas_total_tmp[0]).toString();
                  } else {
                    horas_decimal = "0";
                  }

                  const minutos = (Number(horas_total_tmp[1]) / 60) * 100;

                  if (minutos > 0) {
                    let temp = ((minutos * 100) / 60).toFixed(0);

                    if (Number(temp) < 10) {
                      temp = `0${temp}`;
                    }

                    horas_decimal += `.${temp}`;
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    horas_decimal += ".00";
                  }

                  if (total_m1 >= 60) {
                    total_h1 += 1;
                    total_m1 -= 60;
                  }

                  if (total_h1 >= 10) {
                    total_de_horas = `${total_h1}`;
                  } else {
                    total_de_horas = `0${total_h1}`;
                  }

                  if (total_m1 >= 10) {
                    total_de_horas += `:${total_m1}`;
                  } else {
                    total_de_horas += `:0${total_m1}`;

                    if (Number(horas_decimal) === 0) {
                      horas_decimal = "0.00";
                    }
                  }

                  currentDuration.push(total_de_horas);

                  const ms = currentDuration.map(
                    (d) => moment.duration(`${d}:00`).asSeconds() * 1000
                  );
                  const sum = ms.reduce((prev, cur) => prev + cur, 0);

                  const d = moment.duration(sum, "milliseconds");
                  const hours = Math.floor(d.asHours());
                  const minutes = (Math.floor(d.asMinutes()) - hours * 60)
                    .toString()
                    .padStart(2, "0");
                  const hoursStr = hours.toString().padStart(2, "0");
                  const time = `${hoursStr}:${minutes}`;

                  setTotalTime(time);
                }
              });
            });
        } else {
          ReactDOM.unstable_batchedUpdates(async () => {
            if (loggedUserLevel === "Administrador") {
              await api
                .get(`/management/administrators/show/${loggedUserId}`)
                .then((response) => {
                  (async () => {
                    await api
                      .get(
                        `/management/attorneys/findbyemail/${response.data.administrador_email}`
                      )
                      .then((responseAdm) => {
                        setPersonInCharge(responseAdm.data.advogado_id);
                      });
                  })();
                  setUserType("adv");
                });
            }
            if (loggedUserLevel === "Advogado") {
              await api
                .get(`/management/attorneys/show/${loggedUserId}`)
                .then((responseAtt) => {
                  setPersonInCharge(responseAtt.data.advogado_id);
                });
              setUserType("adv");
            }
            if (loggedUserLevel === "Assistente") {
              await api
                .get(`/management/administrators/show/${loggedUserId}`)
                .then((response) => {
                  setPersonInCharge(response.data.administrador_id);
                });
              setUserType("adm");
            }
          });
        }
      } catch (e) {
        // addToast({
        //   type: 'error',
        //   title: 'Ocorreu um problema',
        //   description:
        //         'Não foi possível carregar as informações do cadastro selecionado.',
        // });
      }
    })();
  }, [hourReferencesOptions, id, isAddMode, loggedUserId, loggedUserLevel]);

  const validationSchema = Yup.object().shape({
    // horas_cod_cliente: Yup
    //   .string()
    //   .oneOf(validCustomerValues.current, 'Selecione o cliente')
    //   .required('Selecione o cliente'),
    // horas_cod_responsavel: Yup
    //   .string()
    //   .oneOf(userType === 'adv' ? validAttorneyValues.current :
    // validAssistantValues.current, 'Selecione o responsável')
    //   .required('Selecione o responsável'),
    horas_hora_inicial_texto: Yup.string()
      .required("Hora de início não pode ser vazia")
      .test("is-invalid", "A hora informada é inválida", () => {
        const horas_hora_inicial_texto = (
          document.getElementById(
            "horas_hora_inicial_texto"
          ) as HTMLInputElement
        ).value;
        if (!moment(horas_hora_inicial_texto, "HH:mm").isValid()) {
          if (horas_hora_inicial_texto.length === 0) {
            return true;
          }
          return false;
        }
        return true;
      })
      .test("is-invalid", "Hora de início não pode ser vazia", () => {
        const horas_hora_inicial_texto = (
          document.getElementById(
            "horas_hora_inicial_texto"
          ) as HTMLInputElement
        ).value;
        if (!moment(horas_hora_inicial_texto, "HH:mm").isValid()) {
          if (horas_hora_inicial_texto.length === 0) {
            return false;
          }
        }
        return true;
      }),
    horas_hora_final_texto: Yup.string()
      .test(
        "is-greater",
        "Hora final não pode ser igual ou menor a hora de início",
        () => {
          const horas_hora_inicial = (
            document.getElementById(
              "horas_hora_inicial_texto"
            ) as HTMLInputElement
          ).value;
          const horas_hora_final = (
            document.getElementById(
              "horas_hora_final_texto"
            ) as HTMLInputElement
          ).value;
          if (
            moment(horas_hora_final, "HH:mm").isSameOrAfter(
              moment(horas_hora_inicial, "HH:mm")
            )
          ) {
            return true;
          }
          if (!moment(horas_hora_final, "HH:mm").isValid()) {
            if (horas_hora_final.length === 0) {
              return true;
            }
            return true;
          }
          return moment(horas_hora_final, "HH:mm").isSameOrAfter(
            moment(horas_hora_inicial, "HH:mm")
          );
        }
      )
      .test("is-invalid", "A hora informada é inválida", () => {
        const horas_hora_final = (
          document.getElementById("horas_hora_final_texto") as HTMLInputElement
        ).value;
        if (!moment(horas_hora_final, "HH:mm").isValid()) {
          if (horas_hora_final.length === 0) {
            return true;
          }
          return false;
        }
        return true;
      }),
  });

  const validationSchema2 = Yup.object().shape({});

  const initialValues: IWorkingHours = {
    horas_cod_cliente:
      cloneWorkingHour && isDuplicate ? clientId : selectedCustomer || 0,
    horas_cod_referencia: hourReference || 0,
    horas_cod_responsavel: inChargeId,
    horas_descricao:
      cloneWorkingHour && isDuplicate ? descriptionText : selectedDescription,
    horas_data: new Date(),
    horas_hora_inicial: new Date(),
    horas_hora_final: undefined,
    horas_valor_hora: 0,
    horas_documento: "",
    horas_data_alteracao: undefined,
    horas_hora_total: data !== "00:00" ? data : "14:32",
    horas_documento_seq: 0,
    horas_processo_id: 0,
    horas_reg: "",
    horas_num_pasta: "",
    horas_autor: "",
    horas_reu: "",
    horas_fatu_de: undefined,
    horas_fatu_ate: undefined,
    horas_fatu_ajuste: 0,
    num_seq: 0,
    valor_ajuste_nao_faturado: 0.0,
    horas_tipo: cloneWorkingHour && isDuplicate ? typeText : hoursType,
    tipo_usuario: userType,
    tipo_hora: Number(type),
    horas_hora_inicial_texto: timeStartText,
    horas_hora_final_texto: timeEndText,
  };

  const handleOnSubmit = (
    values: IWorkingHours,
    { setSubmitting }: FormikHelpers<IWorkingHours>
  ) => {
    (async () => {
      if (isAddMode) {
        try {
          if (workDate && workDate instanceof Date) {
            const dpStart = workDate;
            const dpLimit = new Date();
            const diffTime = Math.abs(dpLimit.getTime() - dpStart.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays > 30) {
              addToast({
                type: "error",
                title: "Cadastro não autorizado",
                description:
                  "Você está tentando cadastrar uma data mais antiga que 30 dias.",
              });
              return;
            }
          }

          if (!personInCharge) {
            addToast({
              type: "error",
              title: "Responsável não definido",
              description: "Selecione a pessoa responsável por esta atividade.",
            });
            return;
          }

          if (timeEndText === "" && type === "1") {
            setTimeEndText("00:00");
          }

          const descriptionTextContent = (
            document.getElementById("horas_descricao") as HTMLInputElement
          ).value;
          if (
            descriptionTextContent.length > 0 &&
            descriptionTextContent
              .trim()
              .toLocaleLowerCase()
              .endsWith("descrição:")
          ) {
            toast.current?.show({
              severity: "error",
              summary: "Descrição incompleta",
              detail:
                'Preenchas as informações do campo Descrição com o detalhamento da descrição no final do campo, após o texto "Descrição: ".',
              life: 5000,
            });
            document.getElementById("horas_descricao")?.focus();
          }

          if (descriptionTextContent.length === 0) {
            toast.current?.show({
              severity: "error",
              summary: "Descrição não preenchida",
              detail: "Preenchas as informações do campo Descrição.",
              life: 5000,
            });
            document.getElementById("horas_descricao")?.focus();
          }

          const formData = {
            horas_cod_cliente: values.horas_cod_cliente || selectedCustomer,
            horas_cod_referencia: values.horas_cod_referencia,
            horas_cod_responsavel: values.horas_cod_responsavel
              ? values.horas_cod_responsavel
              : personInCharge,
            horas_descricao: values.horas_descricao || selectedDescription,
            horas_data: values.horas_data,
            horas_valor_hora: "",
            horas_documento: "",
            horas_hora_total: totalTime,
            valor_ajuste_nao_faturado: values.valor_ajuste_nao_faturado,
            horas_reg: values.horas_reg,
            horas_num_pasta: values.horas_num_pasta,
            horas_autor: values.horas_autor,
            horas_reu: values.horas_reu,
            horas_tipo: hoursType,
            tipo_usuario: userType,
            tipo_hora: type,
            horas_hora_inicial_texto: timeStartText,
            horas_hora_final_texto: timeEndText,
          };

          await api.post("/management/workinghours/create", formData);
          setSubmitting(false);

          localStorage.removeItem("@Jurisnet:workinghour");

          addToast({
            type: "success",
            title: "Cadastro atualizado!",
            description:
              "As informações do cadastro foram criadas com sucesso!",
          });
          toast.current?.show({
            severity: "success",
            summary: "Cadastro atualizado",
            detail: "As informações do cadastro foram atualizadas com sucesso.",
            life: 3000,
          });

          if (adjustment && adjustment === "true") {
            localStorage.removeItem("@Jurisnet:ajustevalores");
            history.goBack();
          } else {
            history.go(0);
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          addToast({
            type: "error",
            title: "Ocorreu um problema",
            description: `Tente repetir o cadastro ou contate o administrador. ${error.message}`,
          });
        }
      } else {
        try {
          const descriptionTextContent = (
            document.getElementById("horas_descricao") as HTMLInputElement
          ).value;
          if (
            descriptionTextContent.length > 0 &&
            descriptionTextContent
              .trim()
              .toLocaleLowerCase()
              .endsWith("descrição:")
          ) {
            toast.current?.show({
              severity: "error",
              summary: "Descrição incompleta",
              detail:
                'Preenchas as informações do campo Descrição com o detalhamento da descrição no final do campo, após o texto "Descrição: ".',
              life: 5000,
            });
            document.getElementById("horas_descricao")?.focus();
          }

          if (descriptionTextContent.length === 0) {
            toast.current?.show({
              severity: "error",
              summary: "Descrição não preenchida",
              detail: "Preenchas as informações do campo Descrição.",
              life: 5000,
            });
            document.getElementById("horas_descricao")?.focus();
          }

          const formData = {
            horas_cod_cliente: values.horas_cod_cliente || selectedCustomer,
            horas_cod_referencia: values.horas_cod_referencia,
            horas_cod_responsavel: values.horas_cod_responsavel
              ? values.horas_cod_responsavel
              : personInCharge,
            horas_descricao: values.horas_descricao || selectedDescription,
            horas_data: values.horas_data || workDate,
            horas_valor_hora: "",
            horas_documento: "",
            horas_hora_total: totalTime,
            valor_ajuste_nao_faturado: values.valor_ajuste_nao_faturado,
            horas_reg: values.horas_reg,
            horas_num_pasta: values.horas_num_pasta,
            horas_autor: values.horas_autor,
            horas_reu: values.horas_reu,
            horas_tipo: values.horas_tipo,
            tipo_usuario: userType,
            tipo_hora: type,
            horas_hora_inicial_texto: timeStartText,
            horas_hora_final_texto: timeEndText,
          };

          await api.put(`/management/workinghours/edit/${id}`, formData);
          setSubmitting(false);

          addToast({
            type: "success",
            title: "Cadastro atualizado",
            description:
              "As informações do cadastro foram atualizadas com sucesso.",
          });
          toast.current?.show({
            severity: "success",
            summary: "Cadastro atualizado",
            detail: "As informações do cadastro foram atualizadas com sucesso.",
            life: 3000,
          });

          history.goBack();
        } catch (error) {
          addToast({
            type: "error",
            title: "Ocorreu um problema",
            description: "Tente repetir o cadastro ou contate o administrador.",
          });
          toast.current?.show({
            severity: "error",
            summary: "Ocorreu um problema",
            detail: "Tente repetir o cadastro ou contate o administrador.",
            life: 3000,
          });
        }
      }
    })();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleDirectSearch = () => {
    if (folderNumber && folderNumber.length > 0) {
      fullSearch = false;
      localStorage.removeItem("@Jurisnet:foldernumber");
    } else {
      fullSearch = true;
    }
    if (fullSearch) {
      localStorage.removeItem("@Jurisnet:foldernumber");
    } else {
      localStorage.setItem("@Jurisnet:foldernumber", folderNumber);
    }
  };

  const updateInfo = () => {
    const judgingBody = localStorage
      .getItem("@Jurisnet:judgingbody")
      ?.replaceAll('"', "");
    const lawsuit = localStorage.getItem("@Jurisnet:lawsuit");
    let description = "";

    if (
      lawsuit &&
      lawsuit.length > 0 &&
      judgingBody &&
      judgingBody.length > 0
    ) {
      if (typeof lawsuit === "string") {
        const lawsuitData = JSON.parse(lawsuit);

        description += `- Autos: ${lawsuitData.proc_reg}\n- Autor(es): ${lawsuitData.proc_autor}\n`;
        description += `- Réu(s): ${lawsuitData.proc_reu}\n`;
        description += `- Origem: ${judgingBody} - ${lawsuitData.proc_orgao_cidade}/${lawsuitData.proc_orgao_uf}\n`;
        description += "- Descrição: ";

        const descriptionTextArea = document.getElementById("horas_descricao");
        if (descriptionTextArea) {
          descriptionTextArea.innerText = "";
          descriptionTextArea.innerText = description;
        }

        setSelectedDescription(description);
        setSelectedCustomer(Number(lawsuitData.proc_cod_cliente));
        const descriptionTextArea2 = document.getElementById("horas_descricao");
        if (descriptionTextArea2) {
          descriptionTextArea2.innerText = "";
          descriptionTextArea2.innerText = description;
        }
      }
    }
    localStorage.removeItem("@Jurisnet:foldernumber");
  };

  const handleDateValidation = useCallback(
    (date: string | undefined | null) => {
      const convertedDate = date?.split("/");
      if (convertedDate !== null && convertedDate !== undefined) {
        const newDate = `${convertedDate[1]}-${convertedDate[0]}-${convertedDate[2]}T00:00:00Z`;
        const inputTime = new Date(newDate);
        const now = new Date();

        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - inputTime.getTime();

        if (timeDiffInMs >= thirtyDaysInMs) {
          addToast({
            type: "error",
            title: "Ocorreu um problema",
            description:
              "Você não pode registrar horários com mais de 30 dias a partir da data atual.",
          });

          setWorkDate(null);

          return;
        }
        if (timeDiffInMs >= 7) {
          addToast({
            type: "info",
            title: "Atenção!",
            description:
              "Você está tentando cadastrar uma hora da semana anterior, sua hora será registrada no sistema, porém estará sujeita a análise.",
          });
        }
      }
    },
    [addToast]
  );

  const handleSelectedType = () => {
    if (folderNumber.length > 0) {
      setHoursType("processo");
    } else {
      setHoursType("");
    }
  };

  const searchHours = () => {
    history.push("/management/workinghour/search");
  };

  const renderSearch = (
    <>
      <div className="rightAlign">
        <Button
          icon="pi pi-search"
          className="p-button p-component p-button-secondary"
          type="button"
          onClick={searchHours}
          tooltip="Consultar horas"
          tooltipOptions={{ position: "left" }}
        />
      </div>
    </>
  );
  return (
    <Container>
      <Toast ref={toast} position="bottom-right" />
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>{isAddMode ? "Cadastrar " : "Editar "} horas</h2>
        </div>
      </header>

      <div className="card">
        <header id="workinghours">
          <h3>
            <div className="headerWrapper">
              <div className="leftAlign">Dados Cadastrais Obrigatórios</div>
              {renderSearch}
            </div>
          </h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={isAddMode ? validationSchema : validationSchema2}
          enableReinitialize={!isAddMode}
          initialValues={workingHoursData || initialValues}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <>
              <form id="formHour" name="formHour" onSubmit={handleSubmit}>
                <div className="fluid formgrid grid" id="main-card">
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="horas_cod_cliente">Cliente *</label>
                        <Dropdown
                          id="horas_cod_cliente"
                          name="horas_cod_cliente"
                          value={
                            isAddMode
                              ? selectedCustomer
                              : values.horas_cod_cliente
                          }
                          options={customersOptions}
                          onChange={(e) => {
                            setSelectedCustomer(e.value);
                            setFieldValue("horas_cod_cliente", e.value);
                            handleChange(e);
                          }}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                        <ErrorMessage name="horas_cod_cliente">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="tipo_usuario">Tipo Responsável</label>
                        <SelectButton
                          id="tipo_usuario"
                          value={userType}
                          options={userTypesOptions}
                          optionLabel="name"
                          optionValue="value"
                          onChange={(e) => setUserType(e.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="horas_cod_responsavel">
                          Responsável *
                        </label>
                        <Dropdown
                          id="horas_cod_responsavel"
                          name="horas_cod_responsavel"
                          value={personInCharge}
                          options={
                            userType === "adv"
                              ? attorneysOptions
                              : assistantsOptions
                          }
                          onChange={(e) => {
                            setPersonInCharge(e.value);
                            setFieldValue("horas_cod_responsavel", e.value);
                            handleChange(e);
                          }}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                        <ErrorMessage name="horas_cod_responsavel">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="horas_cod_referencia">Referência</label>
                        <Dropdown
                          id="horas_cod_referencia"
                          name="horas_cod_referencia"
                          value={
                            isAddMode
                              ? hourReference
                              : values.horas_cod_referencia
                          }
                          options={hourReferencesOptions}
                          onChange={(e) => {
                            setHourReference(e.target.value);
                            handleChange(e);
                          }}
                          className="inputfield w-full"
                          optionLabel="name"
                          optionValue="value"
                          filter
                          filterBy="name"
                          placeholder="Selecione uma opção"
                          emptyFilterMessage="Nenhum registro encontrado"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-8">
                        <label htmlFor="horas_descricao">Descrição</label>
                        <div className="col-12 md:col-12 p-inputgroup">
                          <InputTextarea
                            id="horas_descricao"
                            name="horas_descricao"
                            className="inputfield w-full display-linebreak"
                            value={
                              values.horas_descricao || selectedDescription
                            }
                            onChange={(e) => handleChange(e)}
                            rows={5}
                            cols={30}
                            placeholder="Informe a descrição ou realize a busca por critérios para preenchimento automático"
                          />
                          <Button
                            icon="pi pi-search"
                            iconPos="left"
                            className="p-button-secondary"
                            type="button"
                            onClick={(e) => op?.current?.toggle(e)}
                            aria-haspopup
                            aria-controls="overlay_panel"
                            tooltip="Selecionar critérios para busca"
                            tooltipOptions={{ position: "left" }}
                          />
                        </div>
                        <ErrorMessage name="horas_descricao">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <OverlayPanel
                        ref={op}
                        id="overlay-panel"
                        className="overlay-panel"
                        showCloseIcon
                        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                        style={{ width: "80%", height: "600px" }}
                        onHide={updateInfo}
                        onShow={handleDirectSearch}
                      >
                        <OverlaySearch />
                      </OverlayPanel>

                      {isAddMode || (!isDuplicate && !cloneWorkingHour) ? (
                        <div className="col-12 md:col-4">
                          <label htmlFor="proc_reg">Pasta nº</label>
                          <div className="col-12 md:col-12 p-inputgroup">
                            <InputText
                              id="horas_num_pasta"
                              name="horas_num_pasta"
                              className="inputfield w-full"
                              value={folderNumber}
                              onChange={(e) => {
                                setFolderNumber(e.target.value);
                                handleSelectedType();
                                handleChange(e);
                              }}
                              placeholder="Busca direta pelo nº da pasta"
                            />
                            <Button
                              id="search-btn"
                              icon="pi pi-search"
                              iconPos="left"
                              className="p-button-secondary"
                              type="button"
                              onClick={(e) => op?.current?.toggle(e)}
                              aria-haspopup
                              aria-controls="overlay_panel"
                              tooltip="Busca direta pelo nº da pasta"
                              tooltipOptions={{ position: "left" }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="horas_data">Data do trabalho *</label>
                        <Calendar
                          inputId="horas_data"
                          name="horas_data"
                          className="inputfield w-full"
                          value={workDate}
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleDateValidation(e.target.value);
                            setFieldValue("horas_data", e.target.value);
                            handleBlur(e);
                          }}
                          keepInvalid
                          showTime={false}
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="tipo_hora" className="p-mb-10">
                          <RadioButton
                            value="1"
                            id="tipo_hora"
                            name="type"
                            onChange={(e) => setType(e.value)}
                            checked={type === "1"}
                          />{" "}
                          Horas (Horário trabalhado)
                        </label>
                      </div>
                      <div className="col-12 md:col-3 mt-20">
                        <label htmlFor="horas_hora_inicial_texto">De:</label>
                        <br />
                        <InputMask
                          id="horas_hora_inicial_texto"
                          name="horas_hora_inicial_texto"
                          className="inputfield w-full"
                          mask="99:99"
                          autoClear={false}
                          value={timeStartText}
                          onChange={(e) => {
                            if (e.value) {
                              setTimeStartText(e.value);
                            }
                          }}
                          onBlur={handleBlur}
                          onFocus={() => setType("1")}
                        />
                        <ErrorMessage name="horas_hora_inicial_texto">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="horas_hora_final_texto">Até:</label>
                        <br />
                        <InputMask
                          id="horas_hora_final_texto"
                          name="horas_hora_final_texto"
                          className="inputfield w-full"
                          mask="99:99"
                          autoClear={false}
                          value={timeEndText}
                          onChange={(e) => {
                            if (e.value) {
                              setTimeEndText(e.value);
                            }
                          }}
                          onBlur={handleBlur}
                          onFocus={() => setType("1")}
                        />
                        <ErrorMessage name="horas_hora_final_texto">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-4">
                        <label htmlFor="horas_hora_total" className="p-mb-10">
                          <RadioButton
                            value="2"
                            id="tipo_hora"
                            name="type"
                            onChange={(e) => setType(e.value)}
                            checked={type === "2"}
                          />{" "}
                          Total de Horas
                        </label>
                        <br />
                        <InputMask
                          id="horas_hora_total"
                          mask="99:99"
                          autoClear={false}
                          value={totalTime}
                          onChange={(e) => {
                            if (e.value) {
                              setTotalTime(e.value);
                            }
                          }}
                          onBlur={handleBlur}
                          onFocus={() =>
                            timeEndText.length > 0 ? setType("2") : ""
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="horas_tipo">Tipo</label>
                        <SelectButton
                          id="horas_tipo"
                          value={isAddMode ? hoursType : values.horas_tipo}
                          options={hourTypesOptions}
                          optionLabel="name"
                          onChange={(e) => {
                            setHoursType(e.value);
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {adjustment !== "" ? (
                    <div className="col-12">
                      <div className="field grid">
                        <div className="col-12 md:col-4">
                          <label
                            htmlFor="valor_ajuste_nao_faturado"
                            className="p-mb-10"
                          >
                            Ajuste (Em duração/decimais)
                          </label>
                          <br />
                          <InputNumber
                            id="valor_ajuste_nao_faturado"
                            name="valor_ajuste_nao_faturado"
                            className="inputfield"
                            value={values.valor_ajuste_nao_faturado}
                            onValueChange={handleChange}
                            placeholder="0,00"
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            locale="pt-BR"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento
                      obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">
                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                      />
                      <Button
                        id="clearButton"
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="submit"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger p-mr-2"
                        type="button"
                        onClick={handleCancel}
                      />
                      <Button
                        icon="pi pi-search"
                        iconPos="left"
                        label="Consultar horas"
                        className="p-button p-component p-button-outlined p-button-info"
                        type="button"
                        onClick={searchHours}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default AddEditWorkingHours;
