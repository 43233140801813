/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useHistory, useParams } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import ReactDOM from 'react-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface IDeadlineData {
  prazo_proc_id:number;
  prazo_data: Date;
  prazo_ativo: boolean;
  prazo_hora: Date | undefined;
  prazo_cod_adv_resp: string;
  prazo_email: string;
  prazo_cod_ass_resp: string;
  prazo_providencia: string;
  prazo_descr_providencia: string;
}

interface ISelectedItems {
  name?: string;
  value: string
  key: number;
}

const AddEditDeadline:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentLawsuit, setCurrentLawsuit] = useState(0);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');
  const activeTab = params.get('active');
  // const [dateDeadline, setDateDeadline] = useState<Date | Date[] | undefined>(undefined);
  // const [timeDeadline, setTimeDeadline] = useState<Date | Date[] | undefined>(undefined);

  const [dateDeadline, setDateDeadline] = useState<string | Date | Date[] | null>(null);
  const [timeDeadline, setTimeDeadline] = useState<string | Date | Date[] | null>(null);

  // control tab changes when leaving/returning to page
  useEffect(() => {
    if (activeTab !== undefined || activeTab !== null) {
      setActiveIndex(Number(activeTab));
    }
    if (cod !== undefined || cod !== null) {
      setCurrentLawsuit(Number(cod));
    }
  }, [activeTab, cod]);

  const returnLawsuitURL = `/lawsuit/main/edit/${currentLawsuit}?active=${activeIndex}`;

  const initialValues: IDeadlineData = {
    prazo_proc_id: Number(cod),
    prazo_data: new Date(),
    prazo_ativo: false,
    prazo_hora: new Date(),
    prazo_cod_adv_resp: '',
    prazo_email: '',
    prazo_cod_ass_resp: '',
    prazo_providencia: '',
    prazo_descr_providencia: '',
  };

  const [selectedAttorneys, setSelectedAttorneys] = useState([{}]);
  const [attorneysOptions, setAttorneysOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get('management/attorneys/show/all').then((response) => {
        response.data.map((attorney:
        {
          advogado_id: number,
          advogado_nome: string
        }) => (
          attorneysOptions.push({
            name: attorney.advogado_nome,
            value: attorney.advogado_id.toString(),
            key: attorney.advogado_id,
          })
        ));
        attorneysOptions.splice(0, 1);
        setAttorneysOptions(attorneysOptions);
      });
    })();
  }, [attorneysOptions]);

  const [selectedAssistants, setSelectedAssistants] = useState('');
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);

  useEffect(() => {
    (async () => {
      await api.get('management/assistants/show/all').then((response) => {
        response.data.map((assistant:
        {
          administrador_id: number,
          administrador_nome: string
        }) => (
          assistantsOptions.push({
            name: assistant.administrador_nome,
            value: assistant.administrador_id.toString(),
            key: assistant.administrador_id,
          })
        ));
      });
      assistantsOptions.splice(0, 1);
      setAssistantsOptions(assistantsOptions);
    })();
  }, [assistantsOptions]);

  const validationSchema = Yup.object().shape({
    prazo_descr_providencia: Yup.string().required('Descrição obrigatória'),
  });

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  const handleOnSubmit = async (
    values: IDeadlineData,
    { setSubmitting }: FormikHelpers<IDeadlineData>,
  ) => {
    if (isAddMode) {
      try {
        const formData = {
          prazo_proc_id: Number(cod),
          prazo_data: dateDeadline,
          prazo_ativo: true,
          prazo_hora: timeDeadline,
          prazo_cod_adv_resp: selectedAttorneys,
          prazo_email: values.prazo_email,
          prazo_cod_ass_resp: selectedAssistants,
          prazo_providencia: values.prazo_providencia,
          prazo_descr_providencia: values.prazo_descr_providencia,
        };

        await api.post('/lawsuits/deadlines/create', formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        const formData = {
          prazo_proc_id: Number(cod),
          prazo_data: dateDeadline,
          prazo_ativo: true,
          prazo_hora: timeDeadline,
          prazo_cod_adv_resp: selectedAttorneys,
          prazo_email: values.prazo_email,
          prazo_cod_ass_resp: selectedAssistants,
          prazo_providencia: values.prazo_providencia,
          prazo_descr_providencia: values.prazo_descr_providencia,
        };

        await api.put(`/lawsuits/deadlines/edit/${id}`, formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  const attorneyPanelFooterTemplate = () => {
    const selectedItems = selectedAttorneys;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b>
        {' '}
        {length > 1 ? 'itens selecionados' : 'item selecionado'}
        .
      </div>
    );
  };

  const assistantPanelFooterTemplate = () => {
    const selectedItems = selectedAssistants;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b>
        {' '}
        {length > 1 ? 'itens selecionados' : 'item selecionado'}
        .
      </div>
    );
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            prazos do processo
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          // enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setValues,
            setFieldValue,
          }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              async function fetchData() {
                try {
                  if (!isAddMode) {
                    await api.get(`/lawsuits/deadlines/show/${id}`).then((response) => {
                      setValues(response.data);

                      const dateBR = new Date(response.data.prazo_data);
                      if (response.data.horas_data !== null) {
                        const formattedDate = new Date(dateBR.toISOString().slice(0, -1));
                        setDateDeadline(formattedDate);
                      }

                      const timeBR = new Date(response.data.prazo_hora);
                      const formattedTime = timeBR.toLocaleString('pt-BR', { timeZone: 'UTC' });
                      setTimeDeadline(new Date(formattedTime));

                      if (response.data.prazo_cod_adv_resp) {
                        const attorneysArray: string[] = response.data.prazo_cod_adv_resp.split(',');

                        const selected: ISelectedItems[] = [];
                        attorneysArray.forEach((attorneyId: string, index: number) => {
                          // const filteredAttorney = attorneysOptions
                          //   .filter((item: ISelectedItems) => (
                          //     item.value === attorneyId.toString()
                          //   ), []);
                          selected.push({
                            name: `${index}`,
                            value: attorneyId.toString().trim(),
                            key: Number(attorneyId.toString().trim()),
                          });
                        });

                        setSelectedAttorneys(attorneysArray);
                      }

                      if (response.data.prazo_cod_ass_resp) {
                        const assistantsArray = response.data.prazo_cod_ass_resp.split(',');
                        setSelectedAssistants(assistantsArray);
                      }
                    });
                  }
                } catch (e) {
                  addToast({
                    type: 'error',
                    title: 'Ocorreu um problema',
                    description:
                          'Não foi possível carregar as informações do cadastro selecionado.',
                  });
                }
                // }
              }
              ReactDOM.unstable_batchedUpdates(() => {
                fetchData();
              });
            }, [setFieldValue, setValues]);

            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="prazo_data">
                          Data
                        </label>
                        <Calendar
                          inputId="prazo_data"
                          name="prazo_data"
                          className="inputfield w-full"
                          value={
                            isAddMode
                              ? new Date()
                              : dateDeadline
                          }
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setDateDeadline(e.value);
                            }
                          }}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="prazo_hora">
                          Hora
                        </label>
                        <Calendar
                          inputId="prazo_hora"
                          name="prazo_hora"
                          className="inputfield w-full"
                          value={timeDeadline}
                          // onChange={(e) => setTimeDeadline(e.value)}
                          onChange={(e : CalendarChangeEvent) => {
                            if (e.value !== undefined && e.value !== null) {
                              setTimeDeadline(e.value);
                            }
                          }}
                          timeOnly
                          icon="pi pi-clock"
                          showIcon
                          showOnFocus={false}
                          showTime
                          hourFormat="24"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="prazo_providencia">
                          Providência
                        </label>
                        <InputText
                          id="prazo_providencia"
                          name="prazo_providencia"
                          className="inputfield w-full"
                          value={values.prazo_providencia}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-12">
                        <label htmlFor="prazo_descr_providencia">
                          Descrição da providência *
                        </label>
                        <InputTextarea
                          id="prazo_descr_providencia"
                          name="prazo_descr_providencia"
                          className="inputfield w-full"
                          value={values?.prazo_descr_providencia}
                          onChange={handleChange}
                          rows={5}
                          cols={30}
                        />
                        <ErrorMessage name="prazo_descr_providencia">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="prazo_cod_adv_resp">
                          Advogado responsável
                        </label>
                        <MultiSelect
                          id="prazo_cod_adv_resp"
                          name="prazo_cod_adv_resp"
                          value={selectedAttorneys}
                          options={attorneysOptions}
                          className="multiselect-custom w-full"
                          onChange={(e) => { setSelectedAttorneys(e.value); }}
                          optionLabel="name"
                          optionValue="value"
                          placeholder="Selecione o(s) advogado(s)"
                          filter
                          emptyFilterMessage="Nenhum registro encontrado"
                          panelFooterTemplate={attorneyPanelFooterTemplate}
                        />
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="prazo_cod_ass_resp">
                          Assistente
                        </label>
                        <MultiSelect
                          id="prazo_cod_ass_resp"
                          name="prazo_cod_ass_resp"
                          value={selectedAssistants}
                          options={assistantsOptions}
                          onChange={(e) => {
                            setSelectedAssistants(e.value);
                          }}
                          optionLabel="name"
                          optionValue="value"
                          placeholder="Selecione o(s) assistente(s)"
                          filter
                          className="multiselect-custom w-full"
                          panelFooterTemplate={assistantPanelFooterTemplate}
                        />
                      </div>

                      <div className="col-12 p-mt-20">

                        <header>
                          <h3>Enviar cópias de e-mail</h3>
                        </header>

                        <div className="field grid p-mt-20">
                          <div className="col-12 md:col-6">
                            <label htmlFor="copia_emails_advogados">
                              Enviar cópia para advogados desta lista
                            </label>
                            <MultiSelect
                              id="copia_emails_advogados"
                              name="copia_emails_advogados"
                              display="chip"
                              options={attorneysOptions}
                              onChange={(e) => {
                                setSelectedAttorneys(e.value);
                              }}
                              optionLabel="name"
                              optionValue="value"
                              placeholder="Selecione o(s) advogado(s)"
                              filter
                              className="multiselect-custom w-full"
                              panelFooterTemplate={attorneyPanelFooterTemplate}
                              selectedItemsLabel="name"
                            />
                          </div>
                          <div className="col-12 md:col-6">
                            <label htmlFor="copia_emails_assistentes">
                              Enviar cópia para assistentes desta lista
                            </label>
                            <MultiSelect
                              id="copia_emails_assistentes"
                              name="copia_emails_assistentes"
                              options={assistantsOptions}
                              onChange={(e) => {
                                setSelectedAssistants(e.value);
                              }}
                              optionLabel="name"
                              optionValue="value"
                              placeholder="Selecione o(s) assistente(s)"
                              filter
                              className="multiselect-custom w-full"
                              panelFooterTemplate={assistantPanelFooterTemplate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 md:col-12">
                        <label htmlFor="prazo_email">
                          Enviar cópia para outras pessoas (separe os emails por
                          ponto-e-vírgula &quot; ; &quot;)
                        </label>
                        <InputText
                          id="prazo_email"
                          name="prazo_email"
                          className="inputfield w-full"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prazo_email}
                        />
                      </div>

                      <div className="col-12 p-mt-20">
                        <div className="col-12">
                          Todos os campos marcados com * são de preenchimento obrigatório.
                        </div>
                        <header />
                        <div className="flex align-content-center justify-content-center field">

                          <Button
                            icon="pi pi-check"
                            iconPos="left"
                            label="Enviar dados"
                            className="p-mr-2"
                            type="submit"
                            disabled={!isValid || !dirty}
                          />
                          <Button
                            icon="pi pi-undo"
                            iconPos="left"
                            label="Limpar campos"
                            className="p-button-secondary p-mr-2"
                            type="button"
                            onClick={handleReset}
                          />
                          <Button
                            icon="pi pi-times"
                            iconPos="left"
                            label="Cancelar e voltar"
                            className="p-button-danger"
                            type="button"
                            onClick={handleCancel}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditDeadline;
