import React, {
  useEffect, useRef, useState,
} from 'react';

import PrimeReact from 'primereact/api';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { useHistory, useLocation } from 'react-router-dom';

import { format } from 'date-fns';
// import * as xls from 'excel4node';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import moment from 'moment';
import robotoRegular from '../../../../../utils/fonts/roboto-regular-normal-jspdf';

import { useToast } from '../../../../../hooks/toast';
import { Container } from './styles';
import api from '../../../../../services/api';

import CompanyLogo from '../../../../../assets/logo.png';

interface IAttorneys {
  advogado_id: number;
  advogado_nome: string;
  horas_hora_total: string;
  horas_hora_total_decimal: string;
}

type dataOptions = {
    [key: string]: string;
}

const HourAttorneySimplifiedReport: React.FC = () => {
  PrimeReact.ripple = true;

  const { addToast } = useToast();
  const location = useLocation();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;

  const [groupedHours, setGroupedHours] = useState<IAttorneys[]>([]);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  let initialSearchDate = '';
  let finalSearchDate = '';
  if (state) {
    initialSearchDate = moment(state?.proc_data_ajuiz_inicio).format('DD/MM/YYYY');
    finalSearchDate = moment(state?.proc_data_ajuiz_final).format('DD/MM/YYYY');
    if (state.dateFilterType === 'periodo' && state?.proc_data_ajuiz_final === undefined) {
      finalSearchDate = moment(state?.proc_data_ajuiz_inicio).clone().endOf('month').format('DD/MM/YYYY');
    }
  }

  useEffect(() => {
    (async () => {
      try {
        localStorage.setItem('@Jurisnet:invoice', 'false');
        await api.post('/reports/hour/simplified', state).then((response) => {
          const attorneys: {
            advogado_id: number; advogado_nome: string;
            horas_hora_total: string; horas_hora_total_decimal: string;
          }[] = [];

          const filteredIds: number[] = [];
          const totalDurations: string[] = [];

          response.data.forEach(async (data: IAttorneys, index: number) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let horas_total = '';
            let total_de_horas = '';
            let horas_decimal = '0';
            let total_h1 = 0;
            let total_m1 = 0;

            if (index === 0) {
              filteredIds.push(data.advogado_id);
            } else {
              const found = filteredIds.find((element) => element === data.advogado_id);
              if (!found) {
                filteredIds.push(data.advogado_id);
              } else {
                return;
              }
            }

            const filteredAttorney = response.data.filter((request:
              { advogado_id: number;
              }) => request.advogado_id === data.advogado_id);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filteredAttorney.forEach((filtered: any) => {
              if (!filtered.horas_hora_total) {
                horas_total = '0:00';
              } else {
                const horas_total_tmp = filtered.horas_hora_total.split(':');

                if (Number(horas_total_tmp[0]) >= 10) {
                  horas_total = `${horas_total_tmp[0]}:`;
                } else {
                  horas_total = `0${horas_total_tmp[0]}:`;
                }

                if (Number(horas_total_tmp[1]) > 0) {
                  if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                    horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
                  } else {
                    horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
                  }
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  horas_total += '00';
                }

                total_h1 += Number(horas_total_tmp[0]);

                if (Number(horas_total_tmp[1]) > 0) {
                  total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
                }

                if (Number(horas_total_tmp[0]) > 0) {
                  horas_decimal = (Number(horas_total_tmp[0])).toString();
                } else {
                  horas_decimal = '0';
                }

                const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

                if (minutos > 0) {
                  let temp = ((minutos * 100) / 60).toFixed(0);

                  if (Number(temp) < 10) {
                    temp = `0${temp}`;
                  }

                  horas_decimal += `.${temp}`;
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  horas_decimal += '.00';
                }
              }

              if (total_m1 >= 60) {
                total_h1 += 1;
                total_m1 -= 60;
              }

              if (total_h1 >= 10) {
                total_de_horas = `${total_h1}`;
              } else {
                total_de_horas = `0${total_h1}`;
              }

              if (total_m1 >= 10) {
                total_de_horas += `:${total_m1}`;
              } else {
                total_de_horas += `:0${total_m1}`;

                if (Number(horas_decimal) === 0) {
                  horas_decimal = '0.00';
                }
              }
            });

            totalDurations.push(total_de_horas);

            const totalHoursArray = total_de_horas.split(':');
            const decimal = `${totalHoursArray[0]}.${Math.round((Number(totalHoursArray[1]) / 60) * 100)}`;

            horas_decimal = Number(decimal).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
            });

            attorneys.push({
              advogado_id: data.advogado_id,
              advogado_nome: data.advogado_nome,
              horas_hora_total: `${total_de_horas}`,
              horas_hora_total_decimal: `${horas_decimal}`,
            });
          });

          setGroupedHours(attorneys);
        });

        setLoading(false);
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Sem resultados!',
          description:
                'A pesquisa não apresentou resultados. Listando todos os cadastros no sistema.',
        });
      }
    })();
  }, [addToast, state]);

  const exportPdf = () => {
  // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', [297, 210]);

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular');

    doc.setLanguage('pt-BR');
    doc.setDocumentProperties({
      title: 'Relatório de Horas',
      author: 'Pabst & Hadlich Advogados Associados',
      creator: 'JurisNet 2.0',
    });

    const totalPagesExp = '{total_pages_count_string}';

    autoTable(doc, {
      margin: [25, 0, 15, 15],
      html: '#table-groupedhours table',
      includeHiddenHtml: false,
      theme: 'grid',
      tableWidth: 180,
      columnStyles: {
        0: { cellWidth: 11, halign: 'right' },
        2: { cellWidth: 20, halign: 'right' },
        3: { cellWidth: 20, halign: 'right' },
      },
      headStyles: {
        fillColor: [53, 73, 133],
        textColor: [255, 255, 255],
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
        halign: 'center',
      },
      bodyStyles: {
        fontSize: 9,
        font: 'Roboto-Regular',
        fontStyle: 'normal',
      },
      didDrawPage(data) {
      // Header
        doc.addImage(CompanyLogo, 'PNG', data.settings.margin.left, 10, 47.66, 6.09);

        const { pageSize } = doc.internal;
        const fontSize = doc.getFontSize();
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        const header1 = 'Relatório de Horas';
        const header2 = `Período de ${initialSearchDate} até ${finalSearchDate}`;

        const hWidth1 = (doc.getStringUnitWidth(header1) * fontSize) / doc.internal.scaleFactor;
        // const hWidth2 = (doc.getStringUnitWidth(header2) * fontSize) / doc.internal.scaleFactor;

        // Calculate text's x coordinate
        const x1 = ((pageWidth - hWidth1) / 2) + 15;
        // const x2 = ((pageWidth - hWidth2) / 2) + 15;

        doc.setFontSize(14);
        doc.setTextColor(20);
        doc.text(header1, x1, 14);

        doc.setFontSize(8);
        doc.setTextColor(40);
        doc.text(header2, data.settings.margin.left, 21);

        doc.setLineWidth(0.5);
        doc.line(
          data.settings.margin.left, pageHeight - 15,
          data.settings.margin.right, pageHeight - 15,
        );
      },
    });

    // Footer
    const { pageSize } = doc.internal;
    const fontSize = doc.getFontSize();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const printedOn = new Date();
    const dateFormatted = format(
      printedOn,
      "dd/MM/yyyy 'às' HH:mm:ss'.'",
    );

    let str = `Página ${doc.getNumberOfPages()}`;
    if (typeof doc.putTotalPages === 'function') {
      str = `${str} de ${totalPagesExp} | Relatório impresso em ${dateFormatted} | JurisNet 2.0`;
    }

    doc.setFontSize(8);

    const pageCount = (doc.getStringUnitWidth(str) * fontSize) / doc.internal.scaleFactor;
    const x3 = (pageWidth - pageCount) / 2;
    doc.text(str, x3 + 50, pageHeight - 8);

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    const dateFormattedFile = format(
      new Date(),
      'dd-MM-yyyy',
    );
    doc.save(`Relatorio-de-horas-geral-simplificado${dateFormattedFile}.pdf`);
  };

  const exportExcel = () => {
    import('excel4node').then((xlsx) => {
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('Horas');

      const columnsHeaders = [
        'Núm.',
        'Advogado',
        'Total Horas',
        'Total Horas',
      ];

      const columnsContent: dataOptions[] = [{}];
      let index = 1;
      groupedHours.forEach((item) => {
        columnsContent.push({
          num: index.toString(),
          advogado: item.advogado_nome,
          horas: item.horas_hora_total,
          decimal: item.horas_hora_total_decimal,
        });
        index++;
      });

      worksheet.cell(1, 1).string('Pabst & Hadlich Advogados Associados');
      worksheet.cell(2, 1).string('Anotação de Horas Simplificada');
      worksheet.cell(3, 1).string(`Período: ${initialSearchDate} até ${finalSearchDate}`);

      let headerColumIndex = 1;
      columnsHeaders.forEach((heading) => {
        worksheet.cell(5, headerColumIndex++).string(heading);
      });

      let rowIndex = 5;
      columnsContent.forEach((item) => {
        let columnIndex = 1;
        Object.keys(item).forEach((columnName) => {
          worksheet.cell(rowIndex, columnIndex++).string(item[columnName]);
        });
        rowIndex++;
      });

      const formattedIniDate = initialSearchDate.replaceAll('/', '-');
      const formattedFinalDate = finalSearchDate.replaceAll('/', '-');
      const filename = `Relatorio-de-horas-geral-simplificado-${formattedIniDate}a${formattedFinalDate}`;

      workbook.writeToBuffer().then((buffer: BlobPart) => {
        import('file-saver').then((module) => {
          if (module && module.default) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
              type: EXCEL_TYPE,
            });

            module.default.saveAs(data,
              `${filename}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
          }
        });
      });
    });
  };

  const searchHours = () => {
    history.push('/report/hour/attorney/simplified/search');
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-danger mr-2"
        tooltip="Exportar para PDF / Imprimir"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        tooltip="Exportar para Excel"
        tooltipOptions={{ position: 'right' }}
      />
      <Button
        icon="pi pi-search"
        className="p-button-secondary p-mr-2"
        type="button"
        onClick={searchHours}
        tooltip="Nova consulta de horas"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const counterBodyTemplate = (rowData: any, props: any) => props.rowIndex + 1;

  return (
    <Container>
      <header>
        <div>
          <h3>Relatório de horas por profissional (Simplificado) - Modelo VIII</h3>
          <span>
            Período de
            {' '}
            <strong>
              {initialSearchDate}
              {' '}
              até
              {' '}
              {finalSearchDate}
              {' '}
            </strong>
          </span>
        </div>
      </header>

      <div className="datatable-responsive">
        <div className="card results-card" id="results-card">
          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-groupedhours table"
            id="table-groupedhours"
            ref={dt}
            loading={loading}
            value={groupedHours}
            editMode="row"
            dataKey="advogado_nome"
            header={header}
            stripedRows
            className="p-datatable-sm table-pendinghours"
            rows={groupedHours.length}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            responsiveLayout="scroll"
          >
            <Column key="row_number" field="row_number" header="Núm." alignHeader="center" body={counterBodyTemplate} align="center" style={{ width: '50px' }} />
            <Column field="advogado_nome" header="Advogado" alignHeader="center" align="left" style={{ width: '140px' }} />
            <Column field="horas_hora_total" header="Horas" alignHeader="center" align="center" style={{ width: '80px' }} />
            <Column field="horas_hora_total_decimal" header="Decimais" alignHeader="center" align="center" style={{ width: '80px' }} />
          </DataTable>

        </div>
      </div>

    </Container>
  );
};

export default HourAttorneySimplifiedReport;
