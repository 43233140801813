import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

interface IIndexerProperties {
  indice_id: number | null;
  indice_nome: string;
}

const Indexer: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyIndexer = {
    indice_id: null,
    indice_nome: '',
  };

  const [indexers, setIndexers] = useState<IIndexerProperties[]>([]);
  const [indexer, setIndexer] = useState(emptyIndexer);
  const [deleteIndexer, setDeleteIndexerDialog] = useState(false);
  const [deleteIndexersDialog, setDeleteIndexersDialog] = useState(false);
  const [selectedIndexers, setSelectedIndexers] = useState<IIndexerProperties[]>([]);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await api.get('/finance/indexers/show/all');
      setIndexers(response.data);
    })();
  }, []);

  const columns = [
    { field: 'indice_id', header: 'Código', sortable: true },
    { field: 'indice_nome', header: 'Descrição', sortable: true },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable
    />
  ));

  const reset = () => {
    setGlobalFilter('');
  // dt.current.reset();
  };

  const handleCreate = () => {
    history.push('/finance/indexer/create');
  };

  const hidedeleteIndexer = () => {
    setDeleteIndexerDialog(false);
  };

  const hidedeleteIndexersDialog = () => {
    setDeleteIndexersDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editIndexer = (rowData:any) => {
    history.push(`/finance/indexer/edit/${rowData.indice_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteIndexer = (indexer: React.SetStateAction<{
    indice_id: null; indice_nome: string;
  }>) => {
    setIndexer(indexer);
    setDeleteIndexerDialog(true);
  };

  const deleteIndexerDialog = () => {
    api.delete(`/finance/indexers/delete/${indexer.indice_id}`);

    setDeleteIndexerDialog(false);
    reset();

    const updatedRecords = indexers
      .filter((val) => val.indice_id !== indexer.indice_id);
    setIndexers(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editIndexer(rowData); }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain"
        onClick={() => { confirmDeleteIndexer(rowData); }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteIndexerFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteIndexer} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteIndexerDialog} />
    </>
  );
  const deleteIndexersDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteIndexersDialog} />
    </>
  );

  return (
    <Container>
      <header>
        <div>
          <h1>Indexadores</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-indexers table"
            id="table-indexers"
            ref={dt}
            value={indexers}
            editMode="row"
            dataKey="indice_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedIndexers}
            onSelectionChange={(e) => {
              const selIndexers = e.value as IIndexerProperties[];
              setSelectedIndexers(selIndexers);
            }}
            onRowDoubleClick={(e) => editIndexer(e.data)}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '120px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteIndexer} style={{ width: '450px' }} header="Confirmação" modal footer={deleteIndexerFooter} onHide={hidedeleteIndexer}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {indexer && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{indexer.indice_nome}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteIndexersDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteIndexersDialogFooter} onHide={hidedeleteIndexersDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {indexer
              && <span>Você tem certeza que deseja excluir os cadastros selecionados?</span>}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default Indexer;
