import React, {
  useEffect, useRef, useState,
} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';
import api from '../../services/api';

interface IAttorneyGroupProperties {
  grupo_id: number | null;
  grupo_nome: string;
  grupo_valor: string;
}

interface IAttorneyProperties {
  advogado_id: number | null;
  advogado_nome: string;
  advogado_login: string;
  advogado_senha: string;
  advogado_email: string;
  advogado_oab: string;
  advogado_categoria: number;
  advogado_ativo: boolean;
  advogado_nasc: Date;
  advogado_naturalidade: string;
  advogado_end_residencial: string;
  advogado_end_residencial_compl: string;
  advogado_fone_residencial: string;
  advogado_fone_celular: string;
  advogado_conjuge_nome: string;
  advogado_conjuge_nasc: Date;
  permissao_relatoriov: number;
  permissao_relatorioiv: number;
  sta_new_senha: boolean;
  cat_adv: number;
  advogado_valor: string;
  advogado_setor: string;
}

const Attorney: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const emptyAttorney = {
    advogado_id: null,
    advogado_nome: '',
    advogado_login: '',
    advogado_senha: '',
    advogado_email: '',
    advogado_oab: '',
    advogado_categoria: 3,
    advogado_ativo: true,
    advogado_nasc: new Date(0),
    advogado_naturalidade: '',
    advogado_end_residencial: '',
    advogado_end_residencial_compl: '',
    advogado_fone_residencial: '',
    advogado_fone_celular: '',
    advogado_conjuge_nome: '',
    advogado_conjuge_nasc: new Date(0),
    permissao_relatoriov: 0,
    permissao_relatorioiv: 0,
    sta_new_senha: false,
    cat_adv: 0,
    advogado_valor: '0',
    advogado_setor: '',
  };

  const [attorneys, setAttorneys] = useState<IAttorneyProperties[]>([]);
  const [attorney, setAttorney] = useState(emptyAttorney);
  const [deleteAttorneyDialog, setDeleteAttorneyDialog] = useState(false);
  const [deleteAttorneysDialog, setDeleteAttorneysDialog] = useState(false);
  const [selectedAttorneys, setSelectedAttorneys] = useState<IAttorneyProperties[]>([]);
  const [groups, setGroups] = useState<IAttorneyGroupProperties[]>([]);
  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await api.get('/management/attorneys/show/all').then((response) => {
        setAttorneys(response.data);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await api.get('/management/groups/show/all').then((response) => {
        setGroups(response.data);
      });
    })();
  }, []);

  const columns = [
    { field: 'advogado_id', header: 'Código', sortable: true },
    { field: 'advogado_setor', header: 'Setor', sortable: true },
    { field: 'cat_adv', header: 'Grupo', sortable: true },
    { field: 'advogado_valor', header: 'Valor hora', sortable: true },
    { field: 'advogado_nome', header: 'Nome', sortable: true },
    { field: 'advogado_login', header: 'Login', sortable: true },
    { field: 'advogado_email', header: 'E-mail', sortable: true },
    { field: 'permissao_relatorioiv', header: 'Relatório IV', sortable: true },
    { field: 'permissao_relatoriov', header: 'Relatório V', sortable: true },
  ];

  const groupBodyTemplate = (rowData: { cat_adv: number; }) => {
    if (groups !== undefined) {
      const filteredGroup = groups.filter((item) => (
        item.grupo_id === rowData.cat_adv
      ), [groups]);

      return filteredGroup[0]?.grupo_nome;
    }
    return '';
  };

  const reportIVBodyTemplate = (rowData: { permissao_relatorioiv: number; }) => {
    if (attorneys !== undefined) {
      const filteredReport = attorneys.filter((item) => (
        item.permissao_relatorioiv === rowData.permissao_relatorioiv
      ), []);

      if (filteredReport[0]?.permissao_relatorioiv === 1) {
        return (
          <Button
            type="button"
            icon="pi pi-check-square"
            className="p-button-rounded p-button-text p-button-plain p-button-success"
            tooltip="Visualização permitida"
            tooltipOptions={{ position: 'left' }}
          />
        );
      }
      return (
        <Button
          type="button"
          icon="pi pi-ban"
          className="p-button-rounded p-button-text p-button-plain p-button-danger"
          tooltip="Visualização não permitida"
          tooltipOptions={{ position: 'left' }}
        />
      );
    }
    return 0;
  };

  const reportVBodyTemplate = (rowData: { permissao_relatoriov: number; }) => {
    if (attorneys !== undefined) {
      const filteredReport = attorneys.filter((item) => (
        item.permissao_relatoriov === rowData.permissao_relatoriov
      ), []);

      if (filteredReport[0]?.permissao_relatoriov === 1) {
        return (
          <Button
            type="button"
            icon="pi pi-check-square"
            className="p-button-rounded p-button-text p-button-plain p-button-success"
            tooltip="Visualização permitida"
            tooltipOptions={{ position: 'left' }}
          />
        );
      }
      return (
        <Button
          type="button"
          icon="pi pi-ban"
          className="p-button-rounded p-button-text p-button-plain p-button-danger"
          tooltip="Visualização não permitida"
          tooltipOptions={{ position: 'left' }}
        />
      );
    }
    return 0;
  };

  const dynamicColumns = columns.map((col) => {
    if (col.field === 'cat_adv') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={groupBodyTemplate}
          sortable
        />
      );
    }
    if (col.field === 'permissao_relatorioiv') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={reportVBodyTemplate}
          sortable={false}
          headerStyle={{ textAlign: 'center', width: '105px' }}
          style={{ textAlign: 'center' }}
        />
      );
    }
    if (col.field === 'permissao_relatoriov') {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={reportIVBodyTemplate}
          sortable={false}
          headerStyle={{ textAlign: 'center', width: '105px' }}
          style={{ textAlign: 'center' }}
        />
      );
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
      />
    );
  });

  const handleCreate = () => {
    history.push('/management/attorney/create');
  };

  const hidedeleteAttorneyDialog = () => {
    setDeleteAttorneyDialog(false);
  };

  const hidedeleteAttorneysDialog = () => {
    setDeleteAttorneysDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editAttorney = (rowData:any) => {
    history.push(`/management/attorney/edit/${rowData.advogado_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteAttorney = (attorney: React.SetStateAction<{
    advogado_id: null; advogado_nome: string; advogado_login: string; advogado_senha: string;
    advogado_email: string; advogado_oab: string; advogado_categoria: number;
    advogado_ativo: boolean; advogado_nasc: Date; advogado_naturalidade: string;
    advogado_end_residencial: string; advogado_end_residencial_compl: string;
    advogado_fone_residencial: string; advogado_fone_celular: string;
    advogado_conjuge_nome: string; advogado_conjuge_nasc: Date; permissao_relatoriov: number;
    permissao_relatorioiv: number; sta_new_senha: boolean; cat_adv: number; advogado_valor: string;
    advogado_setor: string; }>) => {
    setAttorney(attorney);
    setDeleteAttorneyDialog(true);
  };

  const deleteAttorney = () => {
    api.delete(`management/attorneys/delete/${attorney.advogado_id}`);

    setDeleteAttorneyDialog(false);

    const updatedRecords = attorneys
      .filter((val) => val.advogado_id !== attorney.advogado_id);
    setAttorneys(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { editAttorney(rowData); }}
        tooltip="Alterar cadastro"
        tooltipOptions={{ position: 'left' }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { confirmDeleteAttorney(rowData); }}
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
      />
      <Button
        type="submit"
        icon={rowData.advogado_ativo === true ? 'pi pi-eye' : 'pi pi-eye-slash'}
        className={rowData.advogado_ativo === true ? 'p-button-rounded p-button-text p-button-plain p-button-success' : 'p-button-rounded p-button-text p-button-plain p-button-danger'}
        onClick={() => { changeVisibilityAttorney(rowData); }}
        tooltip={`Tornar cadastro advogado ${rowData.advogado_ativo === true ? 'inativo' : 'ativo'} no sistema`}
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  // const handleCreateUsers = () => {
  //   (async () => {
  //     await api.get('/management/attorneys/show/all').then((response) => {
  //       response.data.forEach((item: {
  //         advogado_email: string; advogado_nome: string;
  //         advogado_senha: string; advogado_login: string;
  //       }) => {
  //         // if (item.advogado_email) {
  //         const userFormData = {
  //           name: item.advogado_nome,
  //           login_name: item.advogado_login,
  //           password: '12345678', // item.advogado_senha,
  //           email: item.advogado_email.includes('@') ? item.advogado_email
  //           : `${item.advogado_login}@naoexiste.email`,
  //           active: true,
  //           user_level: 2,
  //         };

  //         api.post('/users', userFormData);
  //         // }
  //       });
  //     });
  //   })();
  //   addToast({
  //     type: 'success',
  //     title: 'Sincronização concluída!',
  //     description:
  //       'Importação foi realizada com sucesso!',
  //   });
  // };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Novo"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreate}
        tooltip="Novo cadastro"
        tooltipOptions={{ position: 'right' }}
      />
      {/* <Button
        type="button"
        label="Importar acessos para JN 2"
        icon="pi pi-plus"
        className="p-button-primary p-mr-2"
        onClick={handleCreateUsers}
        tooltip="Importa somente cadastros com e-mails"
        tooltipOptions={{ position: 'right' }}
      /> */}
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteAttorneyDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteAttorneyDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteAttorney} />
    </>
  );
  const deleteAttorneysDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteAttorneysDialog} />
      {/* <Button label="Sim" icon="pi pi-check" className="p-button-text"
      onClick={deleteselectedAttorneys} /> */}
    </>
  );

  const changeVisibilityAttorney = async (rowData: {
    advogado_id: number; advogado_ativo: boolean;
    advogado_email: string; advogado_login: string;
  }) => {
    await api.patch(`management/attorneys/patch/${rowData.advogado_id}/${rowData.advogado_ativo}`);
    const response = await api.get('/management/attorneys/show/all');
    setAttorneys(response.data);
    const formData = {
      active: rowData.advogado_ativo,
      login_name: rowData.advogado_login,
    };
    await api.patch('/users/patch/access', formData);
  };

  return (
    <Container>
      <header>
        <div>
          <h1>Advogados</h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-attorneys table"
            id="table-attorneys"
            ref={dt}
            value={attorneys}
            editMode="row"
            dataKey="advogado_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedAttorneys}
            onSelectionChange={(e) => {
              const selAttorneys = e.value as IAttorneyProperties[];
              setSelectedAttorneys(selAttorneys);
            }}
            onRowDoubleClick={(e) => editAttorney(e.data)}
          >
            {dynamicColumns}
            <Column body={actionBodyTemplate} align="right" style={{ width: '150px' }} />
          </DataTable>

        </div>

        <div>
          <Dialog visible={deleteAttorneyDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAttorneyDialogFooter} onHide={hidedeleteAttorneyDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {attorney && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{attorney.advogado_nome}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteAttorneysDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAttorneysDialogFooter} onHide={hidedeleteAttorneysDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {attorney
              && (
              <span>
                Você tem certeza que deseja excluir os cadastros selecionados?
              </span>
              )}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default Attorney;
