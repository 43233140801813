/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from './styles';

interface SearchData {
  proc_cod_cliente: number;
  sector: string | string[];
  inCharge: string;
  referenceId: number;
  description: string;
  billedHour: string,
  proc_tipo: string;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
}

interface IOptions {
  label: string;
  value: string;
  disabled: boolean;
  workingsector: string;
}

const SearchHourPercentageByCustomer:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const initialValues: SearchData = {
    proc_cod_cliente: 0,
    sector: ['all'],
    inCharge: 'global|0',
    referenceId: 0,
    description: '',
    billedHour: 'A',
    proc_tipo: 'todos',
    proc_data_ajuiz_inicio: new Date(),
    proc_data_ajuiz_final: new Date(),
  };

  const [customerId, setCustomerId] = useState('0');
  const [hourType, setHourType] = useState('todos');
  const [dateFilterType, setDateFilterType] = useState('data');

  const [
    initialRegistrationDate, setInitialRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);
  const [
    finalRegistrationDate, setFinalRegistrationDate,
  ] = useState<string | Date | Date[] | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attorneysOptions, setAttorneysOptions] = useState<IOptions[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [assistantsOptions, setAssistantsOptions] = useState([{}]);
  const [customersOptions, setCustomersOptions] = useState([{}]);
  const [sectorOptions, setSectorOptions] = useState([{}]);

  const [showDate, setShowDate] = useState(true);
  const [showPeriod, setShowPeriod] = useState(false);

  const validationSchema = null;

  const hourTypeOptions = [
    { name: 'Processo', value: 'processo', style: 'font-size: small;' },
    { name: 'Assessoria', value: 'assessoria', style: 'font-size: small;' },
    { name: 'Todos', value: 'todos', style: 'font-size: small;' },
  ];

  const dateFilterTypeOptions = [
    { name: 'Data', value: 'data', style: 'font-size: small;' },
    { name: 'Período', value: 'periodo', style: 'font-size: small;' },
  ];

  useEffect(() => {
    (async () => {
      await api.get('customers/show/all').then((response) => {
        response.data.map((customer:
        {
          cliente_id: number,
          cliente_nome:string
        }) => (
          customersOptions.push({
            name: customer.cliente_nome,
            value: customer.cliente_id,
          })
        ));
      });
      customersOptions.splice(0, 1);
      setCustomersOptions(customersOptions);
    })();
  }, [customersOptions]);

  useEffect(() => {
    (async () => {
      await api.get('/management/attorneys/show/inchargelawsuits/attorneys').then((response) => {
        if (response.data.length > 0) {
          sectorOptions.push({
            name: 'Todos',
            value: 'all',
          });
          response.data.map((sector: {
            setor_adv: string
          }) => (
            sectorOptions.push({
              name: sector.setor_adv,
              value: sector.setor_adv,
            })
          ));
        }
      });

      await api.get('/management/attorneys/show/inchargelawsuits/others').then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((sector: {
            setor_adm: string
          }) => {
            if (sectorOptions.indexOf(sector.setor_adm) === -1) {
              sectorOptions.push({
                name: sector.setor_adm,
                value: sector.setor_adm,
              });
            }
          });
        }
      });

      sectorOptions.splice(0, 1);
      setSectorOptions(sectorOptions);
    })();
  }, [sectorOptions]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      // const access = JSON.parse(sessionStorage.getItem('@Jurisnet:access') || '{}');
      const access = JSON.parse(localStorage.getItem('@Jurisnet:access') || '{}');

      let menu = '';
      if (access) {
        menu = access.menu;
      }

      const searchFormData = {
        proc_cod_cliente: customerId,
        sector: ['all'],
        inCharge: 'global|0',
        referenceId: 0,
        description: '',
        billedHour: 'A',
        proc_tipo: hourType,
        dateFilterType,
        proc_data_ajuiz_inicio: initialRegistrationDate,
        proc_data_ajuiz_final: finalRegistrationDate,
        menu,
      };

      localStorage.removeItem('@Jurisnet:selectedhour');

      history.push({
        pathname: '/report/hour/percentage',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };

  return (
    <Container>
      <div className="card">
        <header>
          <h3>Relatório de Horas e Percentuais por Cliente - Modelo IX</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-4 colum-spacer-right">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cliente">
                        Cliente
                      </label>
                      <Dropdown
                        id="proc_cliente"
                        name="proc_cliente"
                        options={customersOptions}
                        onChange={(e) => setCustomerId(e.target.value)}
                        value={customerId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Selecione uma opção"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_em_acordo">
                        Tipo
                      </label>
                      <SelectButton
                        id="proc_tipo"
                        value={hourType}
                        options={hourTypeOptions}
                        optionLabel="name"
                        onChange={(e) => setHourType(e.value)}
                        style={{ padding: '0.5rem 0.79rem 0 0' }}
                      />
                    </div>

                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_data_ajuiz_inicio">
                        Data de registro
                        { ' ' }
                        <Tooltip target=".infoText">
                          <strong>Selecione data específica ou período</strong>
                          <br />
                          <strong>Seleção data</strong>
                          <br />
                          <em>
                            Use para intervalos com seleção específica de datas
                          </em>
                          <br />
                          <br />
                          <strong>Seleção período</strong>

                          <br />
                          <em>
                            Para período inicial e final do mesmo mês,
                            <br />
                            selecione apenas o período inicial para que o intervalo
                            <br />
                            seja o primeiro
                            e último dia do mês selecionado
                          </em>
                        </Tooltip>
                        <i
                          className="infoText pi pi-info-circle"
                          data-pr-position="bottom"
                        />
                      </label>
                    </div>
                    <div className="col-12 md:col-12" style={{ marginBottom: '15px' }}>
                      <SelectButton
                        id="date_filter_type"
                        value={dateFilterType}
                        options={dateFilterTypeOptions}
                        optionLabel="name"
                        onChange={(e) => {
                          setDateFilterType(e.value);
                          if (e.value === 'data') {
                            setShowDate(true);
                            setShowPeriod(false);
                          } else {
                            setShowDate(false);
                            setShowPeriod(true);
                          }
                        }}
                        style={{ padding: '0.5rem 0.79rem 0 0' }}
                      />
                    </div>
                    {showPeriod ? (
                      <>
                        <div id="periodo_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio_mes_ano"
                            name="proc_data_ajuiz_inicio_mes_ano"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/9999"
                            dateFormat="mm/yy"
                            yearRange="1980:2030"
                            placeholder="Período inicial"
                            view="month"
                            yearNavigator
                          />
                        </div>
                        <div id="periodo_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final_mes_ano">
                            <Calendar
                              inputId="proc_data_ajuiz_final_mes_ano"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/9999"
                              dateFormat="mm/yy"
                              yearRange="1980:2030"
                              placeholder="Período final"
                              view="month"
                              yearNavigator
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                    {showDate ? (
                      <>
                        <div id="data_ini" className="col-12 md:col-6">
                          <Calendar
                            inputId="proc_data_ajuiz_inicio"
                            name="proc_data_ajuiz_inicio"
                            className="inputfield w-full"
                            value={null}
                            onChange={(e : CalendarChangeEvent) => {
                              if (e.value !== undefined && e.value !== null) {
                                setInitialRegistrationDate(e.value);
                              }
                            }}
                            keepInvalid
                            showIcon
                            showButtonBar
                            showOnFocus={false}
                            mask="99/99/9999"
                            dateFormat="dd/mm/yy"
                            yearRange="1980:2030"
                            placeholder="Data inicial"
                          />
                        </div>
                        <div id="data_fim" className="col-12 md:col-6">
                          <label htmlFor="proc_data_ajuiz_final">
                            <Calendar
                              inputId="proc_data_ajuiz_final"
                              name="proc_data_ajuiz_final"
                              className="inputfield w-full"
                              value={null}
                              onChange={(e : CalendarChangeEvent) => {
                                if (e.value !== undefined && e.value !== null) {
                                  setFinalRegistrationDate(e.value);
                                }
                              }}
                              keepInvalid
                              showIcon
                              showButtonBar
                              showOnFocus={false}
                              mask="99/99/9999"
                              dateFormat="dd/mm/yy"
                              yearRange="1980:2030"
                              placeholder="Data final"
                            />
                          </label>
                        </div>
                      </>
                    ) : null }
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchHourPercentageByCustomer;
