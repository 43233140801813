/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Formik, FormikHelpers,
} from 'formik';

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface FinancialIndexFormData {
  cod_it_financeiros_indices: string;
  per_indice_finaceito: Date;
  val_indice_finaceito: number;
}

const AddEditFinancialIndex:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;

  let period = '';
  if (isAddMode) {
    const periodDate = new Date();
    const currentYear = periodDate.getFullYear().toString();
    const currentMonth = (periodDate.getMonth() + 1).toString().padStart(2, '0');
    period = `${currentYear}-${currentMonth}`;
  }
  const [valuePeriod, setValuePeriod] = useState(period);
  const [valueIGPDI, setValueIGPDI] = useState(0.00);
  const [valueIGPM, setValueIGPM] = useState(0.00);
  const [valueINPC, setValueINPC] = useState(0.00);
  const [valueIPCA, setValueIPCA] = useState(0.00);
  const [valueIPCAE, setValueIPCAE] = useState(0.00);
  const [valuePOUP, setValuePOUP] = useState(0.00);
  const [valueSELIC, setValueSELIC] = useState(0.00);
  const [valueTR, setValueTR] = useState(0.00);

  const initialValues = {
    cod_it_financeiros_indices: '',
    per_indice_finaceito: new Date(),
    val_indice_finaceito: 0,
  };

  const handleOnSubmit = async (
    values: FinancialIndexFormData,
    { setSubmitting }: FormikHelpers<FinancialIndexFormData>,
  ) => {
    if (isAddMode) {
      try {
        // send custom data to api
        const formData = {
          period: valuePeriod,
          igpdi: valueIGPDI,
          igpm: valueIGPM,
          inpc: valueINPC,
          ipca: valueIPCA,
          ipcae: valueIPCAE,
          poup: valuePOUP,
          selic: valueSELIC,
          tr: valueTR,
        };
        await api.post('/finance/financialindexes/create', formData);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/finance/financialindex');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        const formData = {
          period: valuePeriod,
          igpdi: valueIGPDI,
          igpm: valueIGPM,
          inpc: valueINPC,
          ipca: valueIPCA,
          ipcae: valueIPCAE,
          poup: valuePOUP,
          selic: valueSELIC,
          tr: valueTR,
        };

        await api.put('/finance/financialindexes/edit/', formData);

        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
              'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push('/finance/financialindex');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            índices financeiros
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            // values,
            // touched,
            // errors,
            //  dirty,
            // isValid,
            // isSubmitting,
            // handleChange,
            // handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                if (!isAddMode) {
                  const urlId = id.replaceAll('-', '/');
                  const splitDate = urlId.split('/');

                  api.get(`/finance/financialindexes/show/${splitDate[0]}/${splitDate[1]}/${splitDate[2]}`).then((response) => {
                    setValuePeriod(response.data[0].per_indice_finaceito);
                    setValueIGPDI(response.data[0].igpdi);
                    setValueIGPM(response.data[0].igpm);
                    setValueINPC(response.data[0].inpc);
                    setValueIPCA(response.data[0].ipca);
                    setValueIPCAE(response.data[0].ipcae);
                    setValuePOUP(response.data[0].poup);
                    setValueSELIC(response.data[0].selic);
                    setValueTR(response.data[0].tr);

                    // setValues(response.data[0]);
                    setFieldValue('ipca', response.data[0].ipca);
                  });
                }
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              }
            }, [setValues, setFieldValue]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="tr">
                          Período
                        </label>
                        <InputText
                          id="period"
                          name="period"
                          className="inputfield w-full"
                          value={valuePeriod}
                          onChange={(e) => setValuePeriod(e.target.value)}
                        />
                      </div>
                    </div>

                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="igpdi">
                          IGP-DI
                        </label>
                        <InputNumber
                          id="igpdi"
                          name="igpdi"
                          className="inputfield w-full"
                          value={valueIGPDI}
                          onValueChange={(e) => setValueIGPDI(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="igpm">
                          IGP-M
                        </label>
                        <InputNumber
                          id="igpm"
                          name="igpm"
                          className="inputfield w-full"
                          value={valueIGPM}
                          onValueChange={(e) => setValueIGPM(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="inpc">
                          INPC
                        </label>
                        <InputNumber
                          id="inpc"
                          name="inpc"
                          className="inputfield w-full"
                          value={valueINPC}
                          onValueChange={(e) => setValueINPC(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="ipca">
                          IPCA
                        </label>
                        <InputNumber
                          id="ipca"
                          name="ipca"
                          className="inputfield w-full"
                          value={valueIPCA}
                          onValueChange={(e) => setValueIPCA(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-3">
                        <label htmlFor="ipcae">
                          IPCA-E
                        </label>
                        <InputNumber
                          id="ipcae"
                          name="ipcae"
                          className="inputfield w-full"
                          value={valueIPCAE}
                          onValueChange={(e) => setValueIPCAE(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                        />
                      </div>

                      <div className="col-12 md:col-3">
                        <label htmlFor="poup">
                          POUP.
                        </label>
                        <InputNumber
                          id="poup"
                          name="poup"
                          className="inputfield w-full"
                          value={valuePOUP}
                          onValueChange={(e) => setValuePOUP(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={4}
                          maxFractionDigits={4}
                        />
                      </div>

                      <div className="col-12 md:col-3">
                        <label htmlFor="selic">
                          SELIC
                        </label>
                        <InputNumber
                          id="selic"
                          name="selic"
                          className="inputfield w-full"
                          value={valueSELIC}
                          onValueChange={(e) => setValueSELIC(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={4}
                          maxFractionDigits={4}
                        />
                      </div>
                      <div className="col-12 md:col-3">
                        <label htmlFor="tr">
                          TR
                        </label>
                        <InputNumber
                          id="tr"
                          name="tr"
                          className="inputfield w-full"
                          value={valueTR}
                          onValueChange={(e) => setValueTR(e.value || 0)}
                          placeholder="0,00"
                          mode="decimal"
                          minFractionDigits={4}
                          maxFractionDigits={4}
                        />
                      </div>

                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditFinancialIndex;
