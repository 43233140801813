/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import {
  Formik, ErrorMessage, FormikHelpers,
} from 'formik';

import * as Yup from 'yup';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useHistory } from 'react-router-dom';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface ReportHourEmailFormData {
  dia_relatorio_horas_email: string,
  ema_relatorio_horas_email: string,
}

const EditReportHourEmail:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const initialValues: ReportHourEmailFormData = {
    dia_relatorio_horas_email: '',
    ema_relatorio_horas_email: '',
  };

  const validationSchema = Yup.object().shape({
    dia_relatorio_horas_email: Yup.string().required('Periodicidade obrigatória'),
    ema_relatorio_horas_email: Yup.string().required('E-mail obrigatório'),
  });

  const handleOnSubmit = async (
    values: ReportHourEmailFormData,
    { setSubmitting }: FormikHelpers<ReportHourEmailFormData>,
  ) => {
    try {
      await api.put('/management/reporthoursemails/edit/', values);
      setSubmitting(false);

      addToast({
        type: 'success',
        title: 'Cadastro atualizado!',
        description:
              'As informações do cadastro foram atualizadas com sucesso.',
      });

      // history.push('/lawsuit/reporthouremails');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
                'Tente repetir o cadastro ou contate o administrador.',
      });
    }
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            Editar configuração para Relatório de Horas E-mail
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            // setValues,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              try {
                api.get('/management/reporthoursemails/show/').then((response) => {
                  setFieldValue('dia_relatorio_horas_email', response.data[0].dia_relatorio_horas_email);
                  setFieldValue('ema_relatorio_horas_email', response.data[0].ema_relatorio_horas_email);
                });
              } catch (e) {
                addToast({
                  type: 'error',
                  title: 'Ocorreu um problema',
                  description:
                        'Não foi possível carregar as informações do cadastro selecionado.',
                });
              }
            }, [setFieldValue]);
            return (
              <form onSubmit={handleSubmit}>
                <div className="fluid formgrid grid">

                  <div className="col-12">
                    <div className="field grid">
                      <div className="col-12 md:col-6">
                        <label htmlFor="dia_relatorio_horas_email">
                          Periodicidade *
                        </label>
                        <InputText
                          id="dia_relatorio_horas_email"
                          name="dia_relatorio_horas_email"
                          className={` ${errors.dia_relatorio_horas_email && touched.dia_relatorio_horas_email ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dia_relatorio_horas_email}
                        />
                        <small id="dia_relatorio_horas_email-help" className="p-d-block">Número de dias (intervalo para envio).</small>
                        <ErrorMessage name="dia_relatorio_horas_email">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                      <div className="col-12 md:col-6">
                        <label htmlFor="ema_relatorio_horas_email">
                          E-mail(s) *
                        </label>
                        <InputText
                          id="ema_relatorio_horas_email"
                          name="ema_relatorio_horas_email"
                          className={` ${errors.ema_relatorio_horas_email && touched.ema_relatorio_horas_email ? 'inputfield w-full input-error' : 'inputfield w-full'}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ema_relatorio_horas_email}
                        />
                        <small id="ema_relatorio_horas_email-help" className="p-d-block">E-mails separados por vígula (,).</small>
                        <ErrorMessage name="ema_relatorio_horas_email">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 p-mt-20">
                    <div className="col-12">
                      Todos os campos marcados com * são de preenchimento obrigatório.
                    </div>
                    <header />
                    <div className="flex align-content-center justify-content-center field">

                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar dados"
                        className="p-mr-2"
                        type="submit"
                        disabled={!isValid || !dirty}
                      />
                      <Button
                        icon="pi pi-undo"
                        iconPos="left"
                        label="Limpar campos"
                        className="p-button-secondary p-mr-2"
                        type="button"
                        onClick={handleReset}
                      />
                      <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label="Cancelar e voltar"
                        className="p-button-danger"
                        type="button"
                        onClick={history.goBack}
                      />

                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

    </Container>

  );
};

export default EditReportHourEmail;
