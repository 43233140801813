import React, { useEffect, useState } from 'react';
import {
  Formik, FormikHelpers,
} from 'formik';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';

import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import {
  Container,
} from '../styles';

interface IPawnedGoods {
  bens_penh_proc_id: number;
  bens_penh_descricao: string;
  bens_penh_parte: number;
  bens_penh_data: Date | undefined;
  bens_penh_valor: number;
  bens_penh_depositario: string;
}

const AddEditPawnedGoods:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const isAddMode = !id;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const cod = params.get('cod');

  const returnLawsuitURL = `/lawsuit/main/edit/${cod}?active=2`;

  const initialValues: IPawnedGoods = {
    bens_penh_proc_id: Number(cod),
    bens_penh_descricao: '',
    bens_penh_parte: 0,
    bens_penh_data: undefined,
    bens_penh_valor: 0,
    bens_penh_depositario: '',
  };

  const partyOptions = [
    { name: 'Cliente', value: 1 },
    { name: 'Parte adversa', value: 2 },
  ];

  const [costsDepositsOptions, setCostsDepositsOptions] = useState([{}]);
  const [pawnedGoodsData, setPawnedGoodsData] = useState(null);
  const [pawnedDate, setPawnedDate] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        await api.get(`/lawsuits/financialitems/pawnedgoods/show/${id}`).then((response) => {
          setPawnedGoodsData(response.data);

          const pawnedDateBR = new Date(response.data.bens_penh_data);

          if (response.data.bens_penh_data !== null) {
            const pawnedDateISO = new Date(pawnedDateBR
              .toISOString()
              .slice(0, -1));
            if (pawnedDateISO.toString() === '01/01/1970') {
              setPawnedDate(undefined);
            }
            setPawnedDate(pawnedDateISO);
          }
        });
      }
    })();
  }, [id, isAddMode]);

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/costsdeposits/show/all').then((response) => {
        response.data.map(
          (costdeposit: {
            natureza_id: number,
            natureza_descricao:string
          }) => (
            costsDepositsOptions.push({
              name: costdeposit.natureza_descricao,
              value: costdeposit.natureza_id,
            })
          ),
        );
      });
      costsDepositsOptions.splice(0, 1);
      setCostsDepositsOptions(costsDepositsOptions);
    })();
  }, [costsDepositsOptions]);

  const handleOnSubmit = async (
    values: IPawnedGoods,
    { setSubmitting }: FormikHelpers<IPawnedGoods>,
  ) => {
    if (isAddMode) {
      try {
        const responseFinancialItems = await api.get(`/lawsuits/financialitems/show/filtered/${cod}`);

        if (responseFinancialItems.data.length === 0) {
          // add financial item to indicate requests exist
          await api.post('/lawsuits/financialitems/create', values);
        }

        await api.post('/lawsuits/financialitems/pawnedgoods/create', values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
            'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
              'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    } else {
      try {
        await api.put(`/lawsuits/financialitems/pawnedgoods/edit/${id}`, values);
        setSubmitting(false);

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description:
                'As informações do cadastro foram atualizadas com sucesso.',
        });

        history.push(returnLawsuitURL);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um problema',
          description:
                'Tente repetir o cadastro ou contate o administrador.',
        });
      }
    }
  };

  const handleCancel = () => {
    history.push(returnLawsuitURL);
  };

  return (
    <Container>
      <header>
        <div className="flex align-content-center justify-content-center">
          <h2>
            {isAddMode ? 'Cadastrar ' : 'Editar '}
            {' '}
            Bens Penhorados ou à Disposição da Justiça
          </h2>
        </div>
      </header>

      <div className="card">
        <header>
          <h3>Dados Cadastrais Obrigatórios</h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          enableReinitialize
          initialValues={pawnedGoodsData || initialValues}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid">
                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="bens_penh_descricao">
                        Bens penhorados
                      </label>
                      <InputTextarea
                        id="bens_penh_descricao"
                        name="bens_penh_descricao"
                        className="inputfield w-full"
                        value={values.bens_penh_descricao || ''}
                        onChange={handleChange}
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-3 lg:col-3">
                      <label htmlFor="bens_penh_parte">
                        Parte
                      </label>
                      <SelectButton
                        id="bens_penh_parte"
                        value={values.bens_penh_parte}
                        options={partyOptions}
                        optionLabel="name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="field grid">
                    <div className="col-12 md:col-3">
                      <label htmlFor="bens_penh_data">
                        Data
                        <Calendar
                          inputId="bens_penh_data"
                          name="bens_penh_data"
                          className="inputfield w-full"
                          value={
                                    isAddMode
                                      ? undefined
                                      : pawnedDate
                                  }
                          onChange={handleChange}
                          keepInvalid
                          showIcon
                          showButtonBar
                          showOnFocus={false}
                          mask="99/99/9999"
                          dateFormat="dd/mm/yy"
                          yearRange="1980:2030"
                        />
                      </label>
                    </div>

                    <div className="col-12 md:col-3">
                      <label htmlFor="bens_penh_valor">
                        Valor
                      </label>
                      <InputNumber
                        id="bens_penh_valor"
                        name="bens_penh_valor"
                        className="inputfield w-full"
                        value={values.bens_penh_valor}
                        onValueChange={handleChange}
                        placeholder="0,00"
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        locale="pt-BR"
                      />
                    </div>

                    <div className="col-12 md:col-3">
                      <label htmlFor="bens_penh_depositario">
                        Depositário
                      </label>
                      <InputText
                        id="bens_penh_depositario"
                        name="bens_penh_depositario"
                        className="inputfield w-full"
                        onChange={handleChange}
                        value={values.bens_penh_depositario}
                      />
                    </div>

                  </div>
                </div>

                <div className="col-12 p-mt-20">
                  <div className="col-12">
                    Todos os campos marcados com * são de preenchimento obrigatório.
                  </div>
                  <header />
                  <div className="flex align-content-center justify-content-center field">

                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Enviar dados"
                      className="p-mr-2"
                      type="submit"
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                    <Button
                      icon="pi pi-times"
                      iconPos="left"
                      label="Cancelar e voltar"
                      className="p-button-danger"
                      type="button"
                      onClick={handleCancel}
                    />

                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default AddEditPawnedGoods;
