import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { DataTable, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PrimeReact from 'primereact/api';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

interface IWorkingHours {
  horas_id: number;
  horas_cod_cliente: number;
  horas_cod_referencia: number;
  horas_cod_responsavel: number;
  horas_descricao: string;
  horas_data: Date | undefined;
  horas_hora_inicial: Date | undefined;
  horas_hora_final: Date | undefined;
  horas_valor_hora: number;
  horas_documento: string;
  horas_data_alteracao: Date | undefined;
  horas_hora_total: string;
  horas_documento_seq: number;
  horas_processo_id: number;
  horas_reg: string;
  horas_num_pasta: string;
  horas_autor: string;
  horas_reu: string;
  horas_fatu_de: Date | undefined;
  horas_fatu_ate: Date | undefined;
  horas_fatu_ajuste: number;
  num_seq: number;
  valor_ajuste_nao_faturado: number;
  tipo_usuario: string;
  tipo_horas: string;
  horas_hora_inicial_texto: String;
  horas_hora_final_texto: String;
  usuario_logado: number;
  advogado_nome?: string;
  administrador_nome?: string;
}

const WorkingHour: React.FC = () => {
  // active ripple effect
  PrimeReact.ripple = true;

  const loggedUser: number = sessionStorage.getItem('usuario_logado') ? Number(sessionStorage.getItem('usuario_logado')) : 0;

  const initialValues: IWorkingHours = {
    horas_id: 0,
    horas_cod_cliente: 0,
    horas_cod_referencia: 0,
    horas_cod_responsavel: 0,
    horas_descricao: '',
    horas_data: new Date(),
    horas_hora_inicial: new Date(),
    horas_hora_final: undefined,
    horas_valor_hora: 0,
    horas_documento: '',
    horas_data_alteracao: undefined,
    horas_hora_total: '00:00',
    horas_documento_seq: 0,
    horas_processo_id: 0,
    horas_reg: '',
    horas_num_pasta: '',
    horas_autor: '',
    horas_reu: '',
    horas_fatu_de: undefined,
    horas_fatu_ate: undefined,
    horas_fatu_ajuste: 0,
    num_seq: 0,
    valor_ajuste_nao_faturado: 0,
    tipo_usuario: 'adv',
    tipo_horas: 'normais',
    horas_hora_inicial_texto: '',
    horas_hora_final_texto: '',
    usuario_logado: loggedUser,
  };

  const [workingHours, setWorkingHours] = useState<IWorkingHours[]>([]);
  const [workingHour, setWorkingHour] = useState(initialValues);
  const [deleteHourDialog, setDeleteHourDialog] = useState(false);
  const [selectedHours, setSelectedHours] = useState<IWorkingHours[]>([]);
  const [latestUpdateDate, setLatestUpdateDate] = useState('');
  // para tabela
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);

  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);
  const [notifyAttorney, setNotifyAttorney] = useState<boolean>(false);
  const [otherEmails, setOtherEmails] = useState('');
  const [extraInformation, setExtraInformation] = useState('');

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = location.state as any;
  let choice = '';

  if (state) {
    choice = ` ${state.tipo_horas}`;
  }

  const [totalTime, setTotalTime] = useState('');
  const [totalDecimalTime, setTotalDecimalTime] = useState('');

  const convertDecimalToHour = useCallback((totalHour: string) => {
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!totalHour) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = totalHour.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = horas_total_tmp[0].toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);
        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    return total_de_horas;
  }, []);

  useEffect(() => {
    (async () => {
      await api.post('/management/workinghours/general/filterbytype', state).then((response) => {
        setWorkingHours(response.data);

        const maxUpdatedDate = new Date(
          Math.max(
            ...response.data.map((element:
              { horas_data_alteracao_original: string | number | Date;
              }) => new Date(element.horas_data_alteracao_original)),
          ),
        );
        setLatestUpdateDate(maxUpdatedDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' }));

        const durations: string[] = [];

        response.data.forEach((data: {
            horas_hora_total: string;
          }) => {
          let horas_total = '';
          let total_de_horas = '';
          let horas_decimal = '0';
          let total_h1 = 0;
          let total_m1 = 0;

          if (!data.horas_hora_total) {
            horas_total = '0:00';
          } else {
            const horas_total_tmp = data.horas_hora_total.split(':');

            if (Number(horas_total_tmp[0]) >= 10) {
              horas_total = `${horas_total_tmp[0]}:`;
            } else {
              horas_total = `0${horas_total_tmp[0]}:`;
            }

            if (Number(horas_total_tmp[1]) > 0) {
              if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
                horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
              } else {
                horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
              }
            } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
              horas_total += '00';
            }

            // const total_de_tmp = data.horas_data_alteracao.split(':');
            total_h1 += Number(horas_total_tmp[0]);

            if (Number(horas_total_tmp[1]) > 0) {
              total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
            }

            if (Number(horas_total_tmp[0]) > 0) {
              horas_decimal = (Number(horas_total_tmp[0])).toString();
            } else {
              horas_decimal = '0';
            }

            const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

            if (minutos > 0) {
              let temp = ((minutos * 100) / 60).toFixed(0);
              // let temp = ((minutos * 100) / 60).toString().replace(',', '');
              if (Number(temp) < 10) {
                temp = `0${temp}`;
              }

              horas_decimal += `.${temp}`;
            } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
              horas_decimal += '.00';
            }
          }

          if (total_m1 >= 60) {
            total_h1 += 1;
            total_m1 -= 60;
          }

          if (total_h1 >= 10) {
            total_de_horas = `${total_h1}`;
          } else {
            total_de_horas = `0${total_h1}`;
          }

          if (total_m1 >= 10) {
            total_de_horas += `:${total_m1}`;
          } else {
            total_de_horas += `:0${total_m1}`;

            if (Number(horas_decimal) === 0) {
              horas_decimal = '0.00';
            }
          }

          durations.push(total_de_horas);
        });

        const ms = durations.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
        const sum = ms.reduce((prev, cur) => prev + cur, 0);

        const d = moment.duration(sum, 'milliseconds');
        const hours = Math.floor(d.asHours());
        const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
        const time = `${hours}:${minutes}`;

        setTotalTime(time);

        const decimalHour = time.split(':')
          .map((val) => parseInt(val, 10))
          .reduce((
            previousValue, currentValue, index,
            // eslint-disable-next-line no-restricted-properties
          ) => previousValue + currentValue / Math.pow(60, index));

        setTotalDecimalTime(decimalHour.toFixed(2).replace('.', ','));
      });

      setLoading(false);
    })();
  }, [state]);

  const hourBodyTemplate = (rowData: {
    horas_hora_total: string;
  }) => {
    const currentDuration: string[] = [];
    let horas_total = '';
    let total_de_horas = '';
    let horas_decimal = '0';
    let total_h1 = 0;
    let total_m1 = 0;

    if (!rowData.horas_hora_total) {
      horas_total = '0:00';
    } else {
      const horas_total_tmp = rowData.horas_hora_total.split(':');

      if (Number(horas_total_tmp[0]) >= 10) {
        horas_total = `${horas_total_tmp[0]}:`;
      } else {
        horas_total = `0${horas_total_tmp[0]}:`;
      }

      if (Number(horas_total_tmp[1]) > 0) {
        if (((Number(horas_total_tmp[1]) / 60) * 100) >= 10) {
          horas_total += ((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0).toString();
        } else {
          horas_total += `0${((Number(horas_total_tmp[1]) / 60) * 100)}`;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_total += '00';
      }

      total_h1 += Number(horas_total_tmp[0]);

      if (Number(horas_total_tmp[1]) > 0) {
        total_m1 += Number(((Number(horas_total_tmp[1]) / 60) * 100).toFixed(0));
      }

      if (Number(horas_total_tmp[0]) > 0) {
        horas_decimal = (Number(horas_total_tmp[0])).toString();
      } else {
        horas_decimal = '0';
      }

      const minutos = ((Number(horas_total_tmp[1]) / 60) * 100);

      if (minutos > 0) {
        let temp = ((minutos * 100) / 60).toFixed(0);

        if (Number(temp) < 10) {
          temp = `0${temp}`;
        }

        horas_decimal += `.${temp}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        horas_decimal += '.00';
      }
    }

    if (total_m1 >= 60) {
      total_h1 += 1;
      total_m1 -= 60;
    }

    if (total_h1 >= 10) {
      total_de_horas = `${total_h1}`;
    } else {
      total_de_horas = `0${total_h1}`;
    }

    if (total_m1 >= 10) {
      total_de_horas += `:${total_m1}`;
    } else {
      total_de_horas += `:0${total_m1}`;

      if (Number(horas_decimal) === 0) {
        horas_decimal = '0.00';
      }
    }

    currentDuration.push(total_de_horas);

    const ms = currentDuration.map((d) => moment.duration(`${d}:00`).asSeconds() * 1000);
    const sum = ms.reduce((prev, cur) => prev + cur, 0);

    const d = moment.duration(sum, 'milliseconds');
    const hours = Math.floor(d.asHours());
    const minutes = (Math.floor(d.asMinutes()) - hours * 60).toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;

    return time;
  };

  const decimalBodyTemplate = (rowData: { horas_hora_total: string; }) => {
    const cleanDuration = convertDecimalToHour(rowData.horas_hora_total);

    const totalHoursArray = cleanDuration.split(':');
    const cleanMinutes = (Number(totalHoursArray[0]) * 60) + Number(totalHoursArray[1]);

    return (cleanMinutes / 60).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 1,
    });
  };

  const checkboxBodyTemplate = (rowData: { horas_id: number; }) => {
    if (workingHours !== undefined) {
      <Checkbox
        inputId={`${rowData.horas_id.toString()}`}
        name={`cb${rowData.horas_id.toString()}`}
        value={selectedHours}
        checked={false}
      />;
      rowData.horas_id.toString();
    }
  };

  const nameBodyTemplate = (rowData: {
    horas_cod_responsavel: number;
    advogado_nome: string;
    administrador_nome: string; }) => {
    if (workingHours !== undefined) {
      const filteredInCharge = workingHours.filter((item) => (
        item.horas_cod_responsavel === rowData.horas_cod_responsavel
      ), []);

      if (filteredInCharge.length > 0) {
        if (filteredInCharge[0]?.advogado_nome) {
          return filteredInCharge[0]?.advogado_nome;
        }
        return filteredInCharge[0]?.administrador_nome;
      }

      return '';
    }

    return rowData.advogado_nome;
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total de horas:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
        <Column footer={totalTime} footerStyle={{ textAlign: 'center' }} />
        <Column footer={totalDecimalTime} footerStyle={{ textAlign: 'center' }} />
        <Column colSpan={2} />
      </Row>
    </ColumnGroup>
  );

  const handleCreate = () => {
    history.push('/management/workinghour/create');
  };

  const hidedeleteHourDialog = () => {
    setDeleteHourDialog(false);
  };

  const editHour = (rowData: DataTableValue) => {
    history.push(`/management/workinghour/edit/${rowData.horas_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const confirmDeleteHour = (workingHour: React.SetStateAction<IWorkingHours>) => {
    setWorkingHour(workingHour);
    setDeleteHourDialog(true);
  };

  const deleteHour = () => {
    if (choice && choice === ' pendentes') {
      api.delete(`/management/workinghours/delete/${workingHour.horas_id}/true`);
    } else {
      api.delete(`/management/workinghours/delete/${workingHour.horas_id}/false`);
    }
    setDeleteHourDialog(false);

    const updatedRecords = workingHours
      .filter((val) => val.horas_id !== workingHour.horas_id);
    setWorkingHours(updatedRecords);

    addToast({
      type: 'success',
      title: 'Cadastro excluído!',
      description:
        'O cadastro selecionado foi excluído com sucesso!',
    });
  };

  const changeHourStatus = async (rowData: { horas_id: number; status_hora: number; }) => {
    await api.patch(`management/pendingworkinghours/patch/hourstatus/${rowData.horas_id}`);
    await api.post('/management/workinghours/general/filterbytype', state).then((response) => {
      setWorkingHours(response.data);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const duplicateHour = (rowData: any) => {
    localStorage.removeItem('@Jurisnet:workinghour');
    localStorage.setItem('@Jurisnet:workinghour', JSON.stringify(rowData));
    history.push('/management/workinghour/duplicate');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      {choice && choice === ' normais' ? (
        <>
          <Button
            type="button"
            icon="pi pi-pencil"
            tooltip="Alterar cadastro"
            tooltipOptions={{ position: 'left' }}
            className="p-button-rounded p-button-text p-button-plain p-mr-2"
            onClick={() => { editHour(rowData); }}
          />
          <Button
            type="button"
            icon="pi pi-clone"
            className="p-button-rounded p-button-text p-button-plain p-mr-2"
            onClick={() => { duplicateHour(rowData); }}
            tooltip="Duplicar este cadastro"
            tooltipOptions={{ position: 'left' }}
          />
        </>
      ) : null}
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip="Excluir cadastro"
        tooltipOptions={{ className: 'red-tooltip', position: 'left' }}
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { confirmDeleteHour(rowData); }}
      />
      {choice && choice === ' pendentes' ? (
        <Button
          type="button"
          icon={rowData.status_hora === 0 ? 'pi pi-stop-circle' : 'pi pi-thumbs-down'}
          className={rowData.status_hora === 0 ? 'p-button-rounded p-button-text p-button-plain p-button-danger' : 'p-button-rounded p-button-text p-button-plain p-button-info'}
          onClick={() => { changeHourStatus(rowData); }}
          tooltip={`Clique para ${rowData.status_hora === 0 ? 'aprovar' : 'reprovar'} o cadastro`}
          tooltipOptions={{ position: 'left' }}
        />
      ) : null }
    </>
  );

  const searchHours = () => {
    history.push('/management/workinghour/search');
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      {choice && choice === ' normais' ? (
        <Button
          type="button"
          label="Novo"
          icon="pi pi-plus"
          className="p-button-secondary p-mr-2"
          onClick={handleCreate}
          tooltip="Novo cadastro"
          tooltipOptions={{ position: 'right' }}
        />
      ) : null}
      <Button
        icon="pi pi-search"
        iconPos="left"
        label="Consultar horas"
        className="p-button p-component p-button-secondary p-mr-2"
        type="button"
        onClick={searchHours}
      />
      <span className="p-input-icon-left p-ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const deleteHourDialogFooter = (
    <>
      <Button type="button" label="Não" icon="pi pi-times" className="p-button-text" onClick={hidedeleteHourDialog} />
      <Button type="button" label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteHour} />
    </>
  );

  const handleNotificationEmails = () => {
    try {
      let hourID = '';

      if (selectedHours) {
        selectedHours.forEach((item: {horas_id: number}) => {
          if (hourID === '') {
            hourID = item.horas_id.toString();
          } else {
            hourID += `,${item.horas_id.toString()}`;
          }
        });
      }

      api.post('/management/workinghours/sendmail', {
        otherEmails,
        extraInformation,
        hourID,
        loggedUser,
        notifyCustomer,
        notifyAttorney,
      });

      addToast({
        type: 'success',
        title: 'E-mail(s) enviado(s) com sucesso',
        description: 'O(s) e-mails selecionados para envio foram processados com sucesso.',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar o email',
        description: 'Ocorreu um erro ao enviar o e-mail. Tente novamente.',
      });
    }
  };

  return (
    <Container>
      <header>
        <div>
          <h1>
            Horas cadastradas
            {choice}
          </h1>
        </div>
      </header>

      <div className="datatable-responsive">

        <div className="card">

          <Tooltip target=".export-buttons>button" position="bottom" />

          <DataTable
            tableClassName="table-workinghours table"
            id="table-workinghours"
            ref={dt}
            loading={loading}
            value={workingHours}
            editMode="row"
            dataKey="horas_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={workingHours.length}
            rowsPerPageOptions={[10, 20, 50, workingHours.length]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="checkbox"
            selection={selectedHours}
            onSelectionChange={(e) => {
              const selHours = e.value as IWorkingHours[];
              setSelectedHours(selHours);
            }}
            onRowDoubleClick={(e) => editHour(e.data)}
            footerColumnGroup={footerGroup}
          >
            <Column key="hora_data" field="hora_data" header="Data" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="cliente_nome" header="Cliente" alignHeader="center" align="left" style={{ width: '140px' }} />
            <Column field="horas_referencia_nome" header="Referência" alignHeader="center" align="left" style={{ width: '80px' }} />
            <Column field="advogado_nome" header="Responsável" alignHeader="center" body={nameBodyTemplate} align="left" style={{ width: '120px' }} />
            <Column field="horas_descricao" header="Descrição" alignHeader="center" align="left" />
            <Column field="horas_tipo" header="Tipo" alignHeader="center" align="left" style={{ width: '100px' }} />
            <Column field="horas_hora_total" header="Horas" alignHeader="center" body={hourBodyTemplate} align="center" style={{ width: '80px' }} />
            <Column field="horas_decimal" header="Decimais" alignHeader="center" body={decimalBodyTemplate} align="center" style={{ width: '80px' }} />
            <Column field="status" header="Status" alignHeader="center" align="left" style={{ width: '120px' }} hidden={choice !== ' pendentes'} />
            <Column key="horas_id" field="horas_id" header="Enviar Inf." alignHeader="center" body={checkboxBodyTemplate} selectionMode="multiple" sortable={false} align="center" style={{ width: '75px' }} hidden={choice !== ' normais'} />
            <Column body={actionBodyTemplate} align="right" style={{ width: '180px' }} />
          </DataTable>
          <div className="rigthDiv">
            <strong>Data última atualização:&nbsp;</strong>
            {latestUpdateDate}
          </div>

          {choice === ' normais' ? (
            <>
              <div className="col-12 p-mt-20">

                <header>
                  <h3>Enviar cópias de e-mail</h3>
                </header>

                <div className="field grid p-mt-20">
                  <div className="col-12 md:col-6">
                    <Checkbox
                      inputId="avisar_cliente"
                      name="avisar_cliente"
                      onChange={(e) => {
                        if (e.checked !== undefined) {
                          setNotifyCustomer(e.checked);
                        }
                      }}
                      checked={notifyCustomer}
                    />
                    <label htmlFor="avisar_cliente">
                      {' '}
                      Avisar cliente
                    </label>
                  </div>
                  <div className="col-12 md:col-6">
                    <Checkbox
                      inputId="avisar_advogado"
                      name="avisar_advogado"
                      onChange={(e) => {
                        if (e.checked !== undefined) {
                          setNotifyAttorney(e.checked);
                        }
                      }}
                      checked={notifyAttorney}
                    />
                    <label htmlFor="avisar_advogado">
                      {' '}
                      Avisar advogado
                    </label>
                  </div>
                </div>

                <div className="col-12 md:col-12">
                  <label htmlFor="copia_emails_outros">
                    Enviar cópia para e-mails adicionais (separe os emails por
                    ponto-e-vírgula &quot; ; &quot;)
                  </label>
                  <InputText
                    id="copia_emails_outros"
                    name="copia_emails_outros"
                    className="inputfield w-full"
                    defaultValue={otherEmails}
                    onChange={(e) => setOtherEmails(e.target.value)}
                  />
                </div>
                <div className="col-12 md:col-12">
                  <label htmlFor="emails_observacoes">
                    Observações
                  </label>
                  <InputTextarea
                    id="emails_observacoes"
                    name="emails_observacoes"
                    className="inputfield w-full"
                    value={extraInformation}
                    onChange={(e) => setExtraInformation(e.target.value)}
                    rows={5}
                    cols={30}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="field grid">
                  <div className="col-12 md:col-12">
                    <div className="flex align-content-center justify-content-center field">
                      <Button
                        icon="pi pi-check"
                        iconPos="left"
                        label="Enviar e-mail"
                        className="p-mr-2"
                        type="submit"
                        onClick={handleNotificationEmails}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </>
          ) : null }
        </div>

        <div>
          <Dialog visible={deleteHourDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteHourDialogFooter} onHide={hidedeleteHourDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {workingHour && (
              <span>
                Você tem certeza que deseja excluir
                <br />
                <br />
                <b>{workingHour.horas_descricao}</b>
                ?
              </span>
              )}
            </div>
          </Dialog>
        </div>

      </div>

    </Container>

  );
};

export default WorkingHour;
